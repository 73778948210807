import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import ButtonWithSVG from "../../Components/layouts/button_with_svg";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import Pagination from "../../Components/layouts/pagination";
import ProjectSpecificationTable from "./Project Specification/project_specification_table";

const PurchaseProjectSpecification = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  // const [quotationOwner, setQuotationOwner] = useState([]);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      `/projectSpecification/getProjectSpecification?page=${page}`
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);

          setTotalPages(Math.ceil(res.data.projectSpecificationsCount / 50));
        } else {
          Toast("error", "Error while getting project specification!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [page]);
  // useEffect(() => {
  //   performRequest("GET", "/leads/getAllLeadsOwners")
  //     .then((res) => setQuotationOwner(res.data))
  //     .catch((err) => console.error(err));
  // }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Project Specification</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>
                <div className="col-auto ms-auto d-print-none">
                  <div className="d-flex">
                    <ButtonWithSVG
                      href={"/project-specification/new"}
                      buttonText="New Project Specification"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 5l0 14" />
                          <path d="M5 12l14 0" />
                        </svg>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="container-xl">
              <div className="card">
                {data && data?.projectSpecificationsData && (
                  <ProjectSpecificationTable
                    PS={data?.projectSpecificationsData}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                )}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.projectSpecificationsData?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PurchaseProjectSpecification;
