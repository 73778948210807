import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import Pagination from "../../Components/layouts/pagination";
import BuyersTable from "./Buyers Management/buyers_table";
import { getStatesByCountry } from "../../Components/Functions/city_country_state";
import { useSelector } from "react-redux";
import ButtonWithSVG from "../../Components/layouts/button_with_svg";
import { FcInTransit } from "react-icons/fc";
import { BsJournalArrowUp } from "react-icons/bs";

function Longtermcontract() {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [favBuyer, setFavBuyer] = useState();
  let [filters, setFilters] = useState({
    name: "",
    buyerType: "",
    addressState: "",
    favouritesOnly: false,
    userId: user?.UserId,
  });
  useEffect(() => {
    filters = { ...filters, userId: user?.UserId };
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/buyerProfiles/getbuyerProfiles/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.buyerProfileData);
          setTotalPages(Math.ceil(res?.data?.buyerProfileCount / 50));
        } else {
          Toast("error", "Error while getting Buyers!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);
  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/favoriteBuyer/getAllFavoriteBuyer/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setFavBuyer(res?.data?.favoriteBuyers);
        } else {
          Toast("error", "Error while getting Buyers!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh]);
  useEffect(() => {
    getStatesByCountry()
      .then((res) => setStates(res))
      .catch((err) => console.error(err));
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcInTransit className="fs-2  me-1" /> Long term contract
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Connect and sale your produce to this
                  buyers.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/buyers-management/new"}>
                    <button className="btn btn-primary">
                      <BsJournalArrowUp className="me-2 fs-2" /> Add new LTC
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-1">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3>Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Search name:"
                              value={filters.name}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  name: e.target.value,
                                })
                              }
                              placeholder="search name..."
                              type="text"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search States:"
                              value={filters.addressState}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  addressState: e.target.value,
                                })
                              }
                            >
                              <option value="">Search Entire India</option>

                              {states &&
                                states?.map((state, index) => (
                                  <option key={index} value={state?.name}>
                                    {state?.name}
                                  </option>
                                ))}
                            </DynamicFilterSelect>
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search Buyer Type:"
                              value={filters.buyerType}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  buyerType: e.target.value,
                                })
                              }
                            >
                              <option value="">All Type</option>
                              <option value="isExporter">Exporter</option>
                              <option value="isWholesaler">Wholesaler</option>
                              <option value="isTrader">Trader</option>
                              <option value="isRetailer">Retailer</option>
                              <option value="isFoodProcessor">
                                Food Processor
                              </option>
                            </DynamicFilterSelect>
                          </div>
                          <div className="col-12">
                            <label class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={filters.favouritesOnly}
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    favouritesOnly: e.target.checked,
                                  });
                                }}
                                style={{
                                  textAlign: "center",
                                  height: "20px",
                                  width: "20px",
                                }}
                              />
                              <span class="form-check-label">
                                My Favorite Buyers
                              </span>
                            </label>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      name: "",
                                      addressState: "",
                                      buyerType: "",
                                      favouritesOnly: false,
                                      userId: "",
                                    });
                                    setPage(1);
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Search name:"
                            value={filters.name}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                name: e.target.value,
                              })
                            }
                            placeholder="search name..."
                            type="text"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterSelect
                            label="Search States:"
                            value={filters.addressState}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                addressState: e.target.value,
                              })
                            }
                          >
                            <option value="">Search Entire India</option>

                            {states &&
                              states?.map((state, index) => (
                                <option key={index} value={state?.name}>
                                  {state?.name}
                                </option>
                              ))}
                          </DynamicFilterSelect>
                        </div>
                        <div className="col-12">
                          <DynamicFilterSelect
                            label="Search Buyer Type:"
                            value={filters.buyerType}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                buyerType: e.target.value,
                              })
                            }
                          >
                            <option value="">All Type</option>
                            <option value="isExporter">Exporter</option>
                            <option value="isWholesaler">Wholesaler</option>
                            <option value="isTrader">Trader</option>
                            <option value="isRetailer">Retailer</option>
                            <option value="isFoodProcessor">
                              Food Processor
                            </option>
                          </DynamicFilterSelect>
                        </div>
                        <div className="col-12">
                          <label class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={filters.favouritesOnly}
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  favouritesOnly: e.target.checked,
                                });
                              }}
                              style={{
                                textAlign: "center",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                            <span class="form-check-label">
                              My Favorite Buyers
                            </span>
                          </label>
                        </div>

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    name: "",
                                    addressState: "",
                                    buyerType: "",
                                    favouritesOnly: false,
                                    userId: "",
                                  });
                                  setPage(1);
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {data && favBuyer && user && (
                  <BuyersTable
                    filters={filters}
                    buyers={data}
                    favBuyer={favBuyer}
                    refresh={refresh}
                    userId={user.UserId}
                    setRefresh={setRefresh}
                  />
                )}

                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.length || 0}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>

          <div className="page-body">
            <div className="container-xl"></div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Longtermcontract;
