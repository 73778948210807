import React, { useState } from "react";
import Footer from "../../Components/Footer/footer";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import { Form, Formik } from "formik";
import { forgotPasswordSchema } from "../../Schemas/user/user_forgot_password";
import CustomInput from "../../CustomComponent/custom_input";
import { useDispatch, useSelector } from "react-redux";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import { logout } from "../../redux/user/loginReducer";
import LoadingPage from "../../Components/layouts/loading";
import { ToastContainer } from "react-toastify";
const ResetPassword = () => {
  const user = useSelector((store) => store.login.user);
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest("PATCH", `/login/update/${user.UserId}`, {
      password: values.password,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Password reset successfully!");
          dispatch(logout());
        } else {
          Toast("error", "Error while password reset!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page page-wrapper">
      <div className="container-xl">
        <div className="page-center" style={{ minHeight: "85vh" }}>
          <div className="container container-normal ">
            <div className="row align-items-center g-4">
              <div className="col-lg d-none d-lg-block">
                <span>
                  {/* <img
                className="navbar-brand-image"
                src="https://www.kryzen.com/wp-content/uploads/2022/02/kryzen-logo-1.png"
                alt="Kryzen"
                style={{
                  width: "110px",
                  height: "50px",
                }}
              /> */}
                  <h1 className="fs-large m-0 p-0">
                    <span className="text-primary">α</span>lpha
                  </h1>
                  <p className="fs-5 ps-6 m-0 text-success">by KRYZEN</p>
                </span>
              </div>
              <div className="col-lg">
                <div className="container-tight">
                  <div className="text-center mb-4">
                    <span>
                      {/* <img
                className="navbar-brand-image"
                src="https://www.kryzen.com/wp-content/uploads/2022/02/kryzen-logo-1.png"
                alt="Kryzen"
                style={{
                  width: "110px",
                  height: "50px",
                }}
              /> */}
                      <h1 className="fs-large m-0 p-0">
                        <span className="text-primary">α</span>lpha
                      </h1>
                      <p className="fs-5 ps-6 m-0 text-success">by KRYZEN</p>
                    </span>
                  </div>
                  <div className="card card-md">
                    <div className="card-body">
                      <h2 className="h2 text-center mb-4">Reset Password</h2>

                      <Formik
                        initialValues={{ password: "", confirmPassword: "" }}
                        validationSchema={forgotPasswordSchema}
                        onSubmit={handelSubmit}
                      >
                        {(props) => (
                          <Form>
                            <div className="card-body">
                              <p className="text-muted mb-4">
                                Please enter and confirm your new password.
                              </p>
                              <div className="mb-3">
                                <label className="form-label">
                                  New Password
                                </label>
                                <div className="input-group input-group-flat">
                                  <CustomInput
                                    name="password"
                                    autoComplete="off"
                                    placeholder="Enter password"
                                    type={showPass ? "text" : "password"}
                                  />

                                  <span className="input-group-text">
                                    <span
                                      onClick={() => setShowPass(!showPass)}
                                      className="link-secondary"
                                      title="Show password"
                                      role="button"
                                      data-bs-toggle="tooltip"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                                {props.touched.password &&
                                  props.errors.password && (
                                    <p className="text-danger">
                                      {props.errors.password}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Confirm New Password
                                </label>
                                <div className="input-group input-group-flat">
                                  <CustomInput
                                    name="confirmPassword"
                                    placeholder="Confirm password"
                                    autoComplete="off"
                                    type={showConfirmPass ? "text" : "password"}
                                  />

                                  <span className="input-group-text">
                                    <span
                                      onClick={() =>
                                        setShowConfirmPass(!showConfirmPass)
                                      }
                                      className="link-secondary"
                                      title="Show password"
                                      role="button"
                                      data-bs-toggle="tooltip"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                                {props.touched.confirmPassword &&
                                  props.errors.confirmPassword && (
                                    <p className="text-danger">
                                      {props.errors.confirmPassword}
                                    </p>
                                  )}
                              </div>

                              <div className="form-footer">
                                <FormSubmitButton
                                  buttonText={"Reset Password"}
                                  width={"full"}
                                />
                              </div>
                            </div>
                            <div className=" text-muted mt-3">
                              Forget it,
                              <a href="/account-login">send me back</a> to the
                              sign in screen.
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Footer />
    </div>
  );
};

export default ResetPassword;
