import React from "react";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import Footer from "../../../Components/Footer/footer";
import ButtonWithSVG from "../../../Components/layouts/button_with_svg";
import KryzenTop from "./kryzen_top";
import Agreement from "./agreement";

const FarmAgreement = () => {
  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Farm Agreement</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>
                <div className="col-auto ms-auto d-print-none">
                  <div className="d-flex">
                    <ButtonWithSVG
                      href={"/farm-construction"}
                      buttonText="Back"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-left"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 12l14 0" />
                          <path d="M5 12l6 6" />
                          <path d="M5 12l6 -6" />
                        </svg>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="container-xl">
              <div className="card">
                <div className="m-4">
                  <KryzenTop />
                </div>
                <div className="m-4">
                  <Agreement />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default FarmAgreement;
