import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import ButtonWithSVG from "../../Components/layouts/button_with_svg";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import Pagination from "../../Components/layouts/pagination";
import CampaignTable from "./Marketing List/campaign_table";

const MarketingSystem = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    marketingList: "",
    threshold: "",
  });
  const [quotationOwner, setQuotationOwner] = useState([]);

  // useEffect(() => {
  //   setLoading(true);
  //   performRequest("GET", getFilteredURL("vendor/getVendor", page, filters))
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setData(res.data);
  //         setTotalPages(Math.ceil(res.data.vendorCount / 50));
  //       } else {
  //         Toast("error", "Error while getting Quotations!");
  //       }
  //     })
  //     .catch((err) => {
  //       Toast("error", "Server Error!");
  //       console.error(err);
  //     })
  //     .finally(() => setLoading(false));
  // }, [refresh, page]);

  // useEffect(() => {
  //   performRequest("GET", "/leads/getAllLeadsOwners")
  //     .then((res) => setQuotationOwner(res.data))
  //     .catch((err) => console.error(err));
  // }, []);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Marketing System</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>
                <div className="col-auto ms-auto d-print-none">
                  <div className="d-flex">
                    <ButtonWithSVG
                      href={"/campaign/new"}
                      buttonText="New Campaign"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 5l0 14" />
                          <path d="M5 12l14 0" />
                        </svg>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="container-xl">
              <div className="card">
                <div className="row g-3 m-2 mb-0 me-3">
                  <DynamicFilterInput
                    label="Start Date:"
                    value={filters.startdate}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        startdate: e.target.value,
                      })
                    }
                    type="date"
                  />
                  <DynamicFilterInput
                    label="End Date:"
                    value={filters.enddate}
                    onChange={(e) =>
                      setFilters({ ...filters, enddate: e.target.value })
                    }
                    type="date"
                  />
                  <DynamicFilterSelect
                    label="Search list:"
                    value={filters.marketingList}
                    onChange={(e) =>
                      setFilters({ ...filters, marketingList: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    <option>All</option>
                    <option>Quotation Received</option>
                    <option>Hydroponic Masterclass</option>
                    <option>Office Visit</option>
                    <option>Farm Visit</option>
                    <option>Meetup Attend</option>
                    <option>Farmtraining Done</option>
                    <option>Site Inspection Done</option>
                    <option>Project Done</option>
                    <option>Repeat Customer</option>
                    <option>Watsapp Message Sent</option>
                    <option>Email Funnel Added</option>
                    <option>Paid Customer</option>
                    <option>Organic Lead</option>
                  </DynamicFilterSelect>
                  <DynamicFilterSelect
                    label="Search threshold:"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        threshold: e.target.value,
                      })
                    }
                    value={filters.threshold}
                  >
                    <option value="">Select</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </DynamicFilterSelect>

                  <div className="col-md">
                    <div className="d-flex">
                      <button
                        onClick={() => setRefresh(!refresh)}
                        style={{
                          height: "28px",
                          marginRight: "5px",
                        }}
                        className="btn btn-primary mt-3"
                      >
                        Search
                      </button>

                      <button
                        onClick={() => {
                          setFilters({
                            startdate: "",
                            enddate: "",
                            contactname: "",
                            quotationtype: "",
                          });
                          setRefresh(!refresh);
                        }}
                        style={{ height: "28px" }}
                        className="btn btn-primary mt-3"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body border-bottom py-3"></div>
                {data && data?.vendorData && (
                  <CampaignTable vendor={data?.vendorData} />
                )}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.vendorData?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MarketingSystem;
