import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import ButtonWithSVG from "../../Components/layouts/button_with_svg";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import Pagination from "../../Components/layouts/pagination";
import ReimbursementTable from "./Reimbursement/all_reimbursement_table";
import { BsReceiptCutoff } from "react-icons/bs";
import { FcCalculator } from "react-icons/fc";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../Components/layouts/filter.select";

const ReimbursementManagement = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    generatedBy: "",
  });
  const [employee, setGeneratedBy] = useState();
  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setGeneratedBy(res?.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/reimbursement/getReimbursement/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data.reimbursementCount / 50));
        } else {
          Toast("error", "Error while getting POCDPI!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [page, refresh]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcCalculator className="fs-2  me-1" /> Reimbursement
                  Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Request reimbursements.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/reimbursement-management/new"}>
                    <button className="btn btn-primary">
                      <BsReceiptCutoff className="me-2 fs-2" /> Add New
                      Reimbursement
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-1">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3>Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Start Date:"
                              value={filters.startdate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  startdate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="End Date:"
                              value={filters.enddate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  enddate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="generated By:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  generatedBy: e.target.value,
                                })
                              }
                              value={filters.generatedBy}
                            >
                              <option value="">select all</option>

                              {employee &&
                                employee?.map((employee, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={employee.userName}
                                    >
                                      {employee.userName}
                                    </option>
                                  );
                                })}
                            </DynamicFilterSelect>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      startdate: "",
                                      enddate: "",
                                      generatedBy: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card p-3 mt-1">
                <h3>Reimbursement Policy</h3>
                <p>Recipts uploads are must for reimbursements.</p>
                <p>
                  Reimbursement above ₹2500 needs prior approval before
                  expanditure.
                </p>
                <p>
                  Reimbursement amount can not be exchanged, traded or
                  transferred to other employee or person.
                </p>
              </div>
                    </div>

                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                        <DynamicFilterInput
                              label="Start Date:"
                              value={filters.startdate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  startdate: e.target.value,
                                })
                              }
                              type="date"
                            />
                        </div>
                        <div className="col-12">
                        <DynamicFilterInput
                              label="End Date:"
                              value={filters.enddate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  enddate: e.target.value,
                                })
                              }
                              type="date"
                            />
                        </div>
                        <div className="col-12">
                        <DynamicFilterSelect
                              label="generated By:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  generatedBy: e.target.value,
                                })
                              }
                              value={filters.generatedBy}
                            >
                              <option value="">select all</option>

                              {employee &&
                                employee?.map((employee, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={employee.userName}
                                    >
                                      {employee.userName}
                                    </option>
                                  );
                                })}
                            </DynamicFilterSelect>
                        </div>
                    

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                            <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                            </div>
                            <div className="col-6">
                            <button
                                  onClick={() => {
                                    setFilters({
                                      startdate: "",
                                      enddate: "",
                                      generatedBy: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-3 mt-1">
                <h3>Reimbursement Policy</h3>
                <p>Recipts uploads are must for reimbursements.</p>
                <p>
                  Reimbursement above ₹2500 needs prior approval before
                  expanditure.
                </p>
                <p>
                  Reimbursement amount can not be exchanged, traded or
                  transferred to other employee or person.
                </p>
              </div>
            </div>
          
            <div className="col-md-9 col-sm-12 py-2">
            <div className="card">
                {data && data?.reimbursement && (
                  <ReimbursementTable
                    reimbursement={data?.reimbursement}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                )}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.reimbursement?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ReimbursementManagement;
