import React from "react";
import { useNavigate } from "react-router-dom";
import { getId } from "../../../Components/Functions/word.fun";
import { current } from "@reduxjs/toolkit";
const tableHead = [
  "Sr.",
  "Project",
  "Inspection Date",
  "Tentetive start date",
  "site manager",
  "Actions",
];
const SITable = ({ SI = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();

  const goToView = (id) => {
    id = getId(id);
    navigate(`/site-inspection/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {SI?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.siNumber}>
                <td>{Index}</td>
                {/* <td>{getId(item?.siNumber) || "N/A"}</td> */}
                <td>{item?.clientDetails?.clientName || "N/A"}</td>
                <td>{item?.newSiteInspection?.inspectionDate || "N/A"}</td>
                <td>{item?.newSiteInspection?.tentativeStartDate || "N/A"}</td>
                <td>{item?.newSiteInspection?.siteManagerName || "N/A"}</td>
                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.siNumber)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SITable;
