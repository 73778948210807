import React from "react";

const DynamicFilterSelect = ({ label, ...props }) => {
  return (
    <div className="col-md">
      {label}

      <select className="form-control mb-2" {...props} />
    </div>
  );
};

export default DynamicFilterSelect;
