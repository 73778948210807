import * as yup from "yup";

export const ClientProjectSchema = yup.object().shape({
  clientName: yup.string().required("Required"),
  clientEmail: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
  clientContactNumber: yup.string().required("Required"),
  businessName: yup.string(),
  businessGstNumber: yup.string(),
  progressStatus: yup.array().of(yup.string()),
  businessType: yup
    .string()
    .oneOf(
      [
        "proprietorship",
        "soleproprietorship",
        "onePersonCompany",
        "LLP",
        "partnership",
        "pvtLtd",
      ],
      "invalid"
    ),
  panNumber: yup.string(),
  aadharcardNumber: yup.string(),
  billingAddress: yup.string().required("Required"),
  farmAddress: yup.string().required("Required"),
  generatedBy: yup.string(),
  notes: yup.string(),
});
