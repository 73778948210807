import React, { useEffect, useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form, FieldArray } from "formik";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProjectSpecificationInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import { getId } from "../../../Components/Functions/word.fun";
import { ProjectSpecificationSchema } from "../../../Schemas/purchase/project_specification_schema";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import CustomInput from "../../../CustomComponent/custom_input";
// let tableHead = [
//   "item",
//   "quantity",
//   "units",
//   "gstPer",
//   "specification",
//   "vendor",
//   "cogs",
// ];
const EditProjectSpecification = () => {
  let userData = useSelector((store) => store.login.user);
  const [projectSpecificationInfo, setProjectSpecificationInfo] = useState(
    ProjectSpecificationInfo
  );
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const { id } = useParams();
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    performRequest("GET", "/poDcPi/getAllVendorList")
      .then((res) => setVendors(res.data))
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      `/projectSpecification/getProjectSpecificationById/${id}`
    )
      .then((res) => {
        if (res.status === 200) {
          setProjectSpecificationInfo(res.data);
        } else {
          Toast("error", "Error while getting project specification!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);
  const handelSubmit = (values, actions) => {
    values = {
      ...values,
      generatedBy: userData?.Name,
      aFramePercent: +values.aFramePercent || 0,
      flatBedPercent: +values.flatBedPercent || 0,
      growBagPercent: +values.growBagPercent || 0,
      dutchBucketPercent: +values.dutchBucketPercent || 0,
    };
    // let totalPercent =
    //   +values.aFramePercent +
    //   +values.flatBedPercent +
    //   +values.growBagPercent +
    //   +values.dutchBucketPercent;
    console.log(values);
    // if (totalPercent > 100) {
    //   Toast("error", "Total Percentage of Frames should be 100%");
    // } else if (totalPercent < 25) {
    //   Toast("error", "Total Percentage of Frames At Least 25%");
    // } else {
    //   setLoading(true);
    //   performRequest(
    //     "PATCH",
    //     `/projectSpecification/updateProjectSpecification/${id}`,
    //     values
    //   )
    //     .then((res) => {
    //       if (res.status === 200) {
    //         Toast("success", "project specification updated successfully!");
    //         navigate(`/project-specification`);
    //       } else {
    //         Toast("error", "Error while uploading");
    //       }
    //     })
    //     .catch((err) => {
    //       Toast("error", "Server Error");
    //       console.error("Error", err);
    //     })
    //     .finally(() => setLoading(false));
    // }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Project Specification</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Edit Project Specification</h2>
            </div>
            <Formik
              initialValues={projectSpecificationInfo}
              validationSchema={ProjectSpecificationSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Project
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="project">
                          <option value="">Select</option>
                          {projects &&
                            projects?.map((pro) => {
                              return (
                                <option
                                  key={pro.cpNumber}
                                  value={getId(pro.cpNumber)}
                                >
                                  {pro.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>
                        {props.touched.project && props.errors.project && (
                          <p className="text-danger">{props.errors.project}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Bay size
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="number" name="psBaySize">
                          <option value="">Select</option>
                          {[
                            8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56,
                            60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100, 104,
                            108, 112, 116, 120, 124, 128, 132, 136, 140, 144,
                          ].map((number) => (
                            <option key={number} value={number}>
                              {number} Mtr
                            </option>
                          ))}
                        </CustomSelect>
                        {props.touched.psBaySize && props.errors.psBaySize && (
                          <p className="text-danger">
                            {props.errors.psBaySize}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Span size
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="number" name="psSpanSize">
                          <option value="">Select</option>
                          {[
                            8, 12, 20, 28, 36, 44, 52, 60, 68, 76, 84, 92, 100,
                            108, 116, 124, 132, 140, 148, 156, 164, 172, 180,
                            188, 196, 204, 212, 220, 228, 236, 244, 252, 260,
                            268, 276,
                          ].map((number) => (
                            <option key={number} value={number}>
                              {number} Mtr
                            </option>
                          ))}
                        </CustomSelect>
                        {props.touched.psSpanSize &&
                          props.errors.psSpanSize && (
                            <p className="text-danger">
                              {props.errors.psSpanSize}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required">
                        Cea type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="ceaType">
                          <option value="">Select</option>
                          <option value="fanpad">FanPad</option>
                          <option value="nvph">NVPH</option>
                        </CustomSelect>
                        {props.touched.ceaType && props.errors.ceaType && (
                          <p className="text-danger">{props.errors.ceaType}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        A-Frame Percent
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="number" name="aFramePercent">
                          <option value="">Select</option>
                          <option value={0}>0%</option>
                          <option value={25}>25%</option>
                          <option value={50}>50%</option>
                          <option value={75}>75%</option>
                          <option value={100}>100%</option>
                        </CustomSelect>
                        {props.touched.aFramePercent &&
                          props.errors.aFramePercent && (
                            <p className="text-danger">
                              {props.errors.aFramePercent}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Flat Bed Percent
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="number" name="flatBedPercent">
                          <option value="">Select</option>
                          <option value={0}>0%</option>
                          <option value={25}>25%</option>
                          <option value={50}>50%</option>
                          <option value={75}>75%</option>
                          <option value={100}>100%</option>
                        </CustomSelect>
                        {props.touched.flatBedPercent &&
                          props.errors.flatBedPercent && (
                            <p className="text-danger">
                              {props.errors.flatBedPercent}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Grow Bag Percent
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="number" name="growBagPercent">
                          <option value="">Select</option>
                          <option value={0}>0%</option>
                          <option value={25}>25%</option>
                          <option value={50}>50%</option>
                          <option value={75}>75%</option>
                          <option value={100}>100%</option>
                        </CustomSelect>
                        {props.touched.growBagPercent &&
                          props.errors.growBagPercent && (
                            <p className="text-danger">
                              {props.errors.growBagPercent}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Dutch Bucket Percent
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="number" name="dutchBucketPercent">
                          <option value="">Select</option>
                          <option value={0}>0%</option>
                          <option value={25}>25%</option>
                          <option value={50}>50%</option>
                          <option value={75}>75%</option>
                          <option value={100}>100%</option>
                        </CustomSelect>
                        {props.touched.dutchBucketPercent &&
                          props.errors.dutchBucketPercent && (
                            <p className="text-danger">
                              {props.errors.dutchBucketPercent}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Hocky</label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="hocky"
                          type="checkbox"
                          width={"20px"}
                          height={"20px"}
                        />
                        {props.touched.hocky && props.errors.hocky && (
                          <p className="text-danger">{props.errors.hocky}</p>
                        )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Hocky Presense
                      </label>
                      <div className="w-100 col">
                        <label className="form-check-inline">
                          <CustomCheckbox
                            type="checkbox"
                            width={"15px"}
                            height={"15px"}
                            name="hockyPresense"
                            value="north"
                          />
                          <span className="form-check-label">North</span>
                        </label>
                        <label className="form-check-inline">
                          <CustomCheckbox
                            name="hockyPresense"
                            value="south"
                            type="checkbox"
                            width={"15px"}
                            height={"15px"}
                          />
                          <span className="form-check-label">South</span>
                        </label>
                        <label className="form-check-inline">
                          <CustomCheckbox
                            type="checkbox"
                            width={"15px"}
                            height={"15px"}
                            name="hockyPresense"
                            value="east"
                          />
                          <span className="form-check-label">East</span>
                        </label>
                        <label className="form-check-inline">
                          <CustomCheckbox
                            type="checkbox"
                            width={"15px"}
                            height={"15px"}
                            name="hockyPresense"
                            value="west"
                          />
                          <span className="form-check-label">West</span>
                        </label>
                        {props.touched.hockyPresense &&
                          props.errors.hockyPresense && (
                            <p className="text-danger">
                              {props.errors.hockyPresense}
                            </p>
                          )}{" "}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>

                      <div className="w-100 col">
                        <CustomTextArea type="text" name="notes" />

                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>

                    <hr />
                    <h3>Standard Material</h3>
                    <hr />
                    <FieldArray name="standardMaterial">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.standardMaterial?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {props.values?.standardMaterial[index].item}
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values?.standardMaterial[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values?.standardMaterial[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`standardMaterial[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`standardMaterial[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Fogger Assembly</h3>
                    <hr />

                    <FieldArray name="foggerAssembly">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.foggerAssembly?.map((value, index) => (
                            <div key={index}>
                              <div className="row g-3 align-items-center">
                                <label className="col-md form-label">
                                  {props.values?.foggerAssembly[index].item}
                                </label>
                                <div className="col-md">
                                  <div className="row g-3 align-items-center">
                                    <label className="col-md form-label">
                                      {
                                        props.values?.foggerAssembly[index]
                                          .quantity
                                      }
                                    </label>
                                    <label className="col-md form-label">
                                      {
                                        props.values?.foggerAssembly[index]
                                          .units
                                      }
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md">
                                  <div className="w-100 col">
                                    <CustomSelect
                                      type="text"
                                      name={`foggerAssembly[${index}].vendor`}
                                    >
                                      <option value="">Select</option>
                                      {vendors?.map((item) => {
                                        return (
                                          <option
                                            key={item.id}
                                            value={
                                              item.businessName ||
                                              item.contactPersonName
                                            }
                                          >
                                            {item.businessName ||
                                              item.contactPersonName}
                                          </option>
                                        );
                                      })}
                                    </CustomSelect>
                                  </div>
                                </div>
                                <div className="col-md">
                                  <div className="w-100 col">
                                    <CustomInput
                                      type={"text"}
                                      name={`foggerAssembly[${index}].cogs`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Seedling Station</h3>
                    <hr />

                    <FieldArray name="seedlingStation">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.seedlingStation?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {props.values?.seedlingStation[index].item}
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values?.seedlingStation[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values?.seedlingStation[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`seedlingStation[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`seedlingStation[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Air CirculationFans</h3>
                    <hr />

                    <FieldArray name="airCirculationFans">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.airCirculationFans?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values?.airCirculationFans[index]
                                        .item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values?.airCirculationFans[
                                            index
                                          ].quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values?.airCirculationFans[
                                            index
                                          ].units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`airCirculationFans[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`airCirculationFans[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>First Batch Starter</h3>
                    <hr />
                    <FieldArray name="firstBatchStarter">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.firstBatchStarter?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values?.firstBatchStarter[index]
                                        .item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values?.firstBatchStarter[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values?.firstBatchStarter[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`firstBatchStarter[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`firstBatchStarter[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Flatbed NFT System Specifications</h3>
                    <hr />
                    <FieldArray name="flatbedNFTSystemSpecifications">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.flatbedNFTSystemSpecifications?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values
                                        ?.flatbedNFTSystemSpecifications[index]
                                        .item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.flatbedNFTSystemSpecifications[
                                            index
                                          ].quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.flatbedNFTSystemSpecifications[
                                            index
                                          ].units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`flatbedNFTSystemSpecifications[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`flatbedNFTSystemSpecifications[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>A-Frame Nft System Specification</h3>
                    <hr />
                    <FieldArray name="aFrameNftSystemSpecification">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.aFrameNftSystemSpecification?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values
                                        ?.aFrameNftSystemSpecification[index]
                                        .item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.aFrameNftSystemSpecification[
                                            index
                                          ].quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.aFrameNftSystemSpecification[
                                            index
                                          ].units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`aFrameNftSystemSpecification[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`aFrameNftSystemSpecification[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Water Tank Assembly for A-Frame</h3>
                    <hr />
                    <FieldArray name="waterTankAssemblyforAFrame">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.waterTankAssemblyforAFrame?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values?.waterTankAssemblyforAFrame[
                                        index
                                      ].item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.waterTankAssemblyforAFrame[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.waterTankAssemblyforAFrame[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`waterTankAssemblyforAFrame[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`waterTankAssemblyforAFrame[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Plumbing Adhesive</h3>
                    <hr />
                    <FieldArray name="plumbingAdhesive">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.plumbingAdhesive?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {props.values?.plumbingAdhesive[index].item}
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values?.plumbingAdhesive[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values?.plumbingAdhesive[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`plumbingAdhesive[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`plumbingAdhesive[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Hand Book And Manual</h3>
                    <hr />
                    <FieldArray name="handBookAndManual">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.handBookAndManual?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values?.handBookAndManual[index]
                                        .item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values?.handBookAndManual[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values?.handBookAndManual[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`handBookAndManual[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`handBookAndManual[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3> A-Frame Block Specifications</h3>
                    <hr />
                    <FieldArray name="aFrameBlockSpecification">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.aFrameBlockSpecification?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values?.aFrameBlockSpecification[
                                        index
                                      ].item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.aFrameBlockSpecification[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.aFrameBlockSpecification[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`aFrameBlockSpecification[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`aFrameBlockSpecification[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Flatbed Block Specifications</h3>
                    <hr />
                    <FieldArray name="flatBedBlockSpecifications">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.flatBedBlockSpecifications?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values?.flatBedBlockSpecifications[
                                        index
                                      ].item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.flatBedBlockSpecifications[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.flatBedBlockSpecifications[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`flatBedBlockSpecifications[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`flatBedBlockSpecifications[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Grow Bags Block Specifications</h3>
                    <hr />
                    <FieldArray name="growBagsBlockSpecifications">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.growBagsBlockSpecifications?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values?.growBagsBlockSpecifications[
                                        index
                                      ].item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.growBagsBlockSpecifications[index]
                                            .quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.growBagsBlockSpecifications[index]
                                            .units
                                        }
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`growBagsBlockSpecifications[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`growBagsBlockSpecifications[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <hr />
                    <h3>Dutch Bucket Blocks Specifications</h3>
                    <hr />
                    <FieldArray name="dutchBucketBlockSpecification">
                      {({ push, remove }) => (
                        <div>
                          <div>
                            <div className="row g-3 align-items-center">
                              <div className="col-md ">
                                <label className="col-md form-label">
                                  Item
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    Quantity
                                  </label>
                                  <label className="col-md form-label">
                                    Units
                                  </label>
                                </div>
                              </div>

                              <div className="col-md">
                                <label className="form-label">Vendor</label>
                              </div>
                              <div className="col-md">
                                <label className=" form-label ">
                                  Cost (-gst)
                                </label>
                              </div>
                            </div>
                          </div>
                          {props.values?.dutchBucketBlockSpecification?.map(
                            (value, index) => (
                              <div key={index}>
                                <div className="row g-3 align-items-center">
                                  <label className="col-md form-label">
                                    {
                                      props.values
                                        ?.dutchBucketBlockSpecification[index]
                                        .item
                                    }
                                  </label>
                                  <div className="col-md">
                                    <div className="row g-3 align-items-center">
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.dutchBucketBlockSpecification[
                                            index
                                          ].quantity
                                        }
                                      </label>
                                      <label className="col-md form-label">
                                        {
                                          props.values
                                            ?.dutchBucketBlockSpecification[
                                            index
                                          ].units
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomSelect
                                        type="text"
                                        name={`dutchBucketBlockSpecification[${index}].vendor`}
                                      >
                                        <option value="">Select</option>
                                        {vendors?.map((item) => {
                                          return (
                                            <option
                                              key={item.id}
                                              value={
                                                item.businessName ||
                                                item.contactPersonName
                                              }
                                            >
                                              {item.businessName ||
                                                item.contactPersonName}
                                            </option>
                                          );
                                        })}
                                      </CustomSelect>
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <div className="w-100 col">
                                      <CustomInput
                                        type={"text"}
                                        name={`dutchBucketBlockSpecification[${index}].cogs`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Specification" />

                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default EditProjectSpecification;
