import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";

const ViewVendor = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (data) {
      if (data?.uploadPhoto) {
        getUplodedImage(setImageUrls, data?.uploadPhoto, "uploadPhoto");
      }
      if (data?.uploadAadharFront) {
        getUplodedImage(
          setImageUrls,
          data?.uploadAadharFront,
          "uploadAadharFront"
        );
      }
      if (data?.uploadAadharBack) {
        getUplodedImage(
          setImageUrls,
          data?.uploadAadharBack,
          "uploadAadharBack"
        );
      }

      if (data?.gstCertificate) {
        getUplodedImage(setImageUrls, data?.gstCertificate, "gstCertificate");
      }
      if (data?.vendorReg) {
        getUplodedImage(setImageUrls, data?.vendorReg, "vendorReg");
      }
      if (data?.vendorSign) {
        getUplodedImage(setImageUrls, data?.vendorSign, "vendorSign");
      }
      if (data?.vendorBankPassbook) {
        getUplodedImage(
          setImageUrls,
          data?.vendorBankPassbook,
          "vendorBankPassbook"
        );
      }
    }
  }, [data]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `vendor/getVendorById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Lead!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Vendor Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">View Vendor</h2>
            </div>

            <div className="card-body row">
              <div className="col-md border-end">
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Business name{" "}
                    </label>
                    <div className="w-100 col">
                      {(data?.businessName || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Contact person name{" "}
                    </label>
                    <div className="w-100 col">
                      {(data?.contactPersonName || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Phone number</label>
                    <div className="w-100 col">{data?.contactPersonPhone}</div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Alternative phone number
                    </label>
                    <div className="w-100 col">
                      {data?.alternateContactPersonPhone || "N/A"}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Business email
                    </label>
                    <div className="w-100 col">
                      {(data?.contactPersonEmail || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Reffered by</label>
                    <div className="w-100 col">{data?.referredBy || "N/A"}</div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Business Address
                    </label>
                    <div className="w-100 col">
                      {(data?.businessAddress || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">State</label>
                    <div className="w-100 col">
                      {(`${data?.state}` || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md">
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Gst number</label>
                    <div className="w-100 col">
                      {(data?.gstNumber || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Bank account name
                    </label>
                    <div className="w-100 col">
                      {(data?.bankAccountName || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Bank IFSC</label>
                    <div className="w-100 col">
                      {(data?.bankIFSC || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Business nature
                    </label>
                    <div className="w-100 col">
                      {(data?.businessNature || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Legal Constitution
                    </label>
                    <div className="w-100 col">
                      {(data?.legalConstitution || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Accepted payment mode
                    </label>
                    {data?.acceptedPaymentMode &&
                    typeof JSON.parse(data?.acceptedPaymentMode) ===
                      "string" ? (
                      <div className="w-100 col">
                        {(
                          JSON.parse(data?.acceptedPaymentMode) || "N/A"
                        ).toUpperCase()}
                      </div>
                    ) : (
                      <div className="w-100 col">
                        {(
                          (data?.acceptedPaymentMode &&
                            JSON.parse(data?.acceptedPaymentMode).join(", ")) ||
                          "N/A"
                        ).toUpperCase()}
                      </div>
                    )}
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Notes</label>
                    <div className="w-100 col">
                      {(data?.notes || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label  col-3">
                      GST certificate
                    </label>
                    <div className="w-100 col">
                      {imageUrls?.gstCertificate ? (
                        <img
                          src={imageUrls?.gstCertificate}
                          alt={"gst Certificate"}
                          style={{ width: "150px" }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label  col-3">
                      Vendor registration
                    </label>
                    <div className="w-100 col">
                      {imageUrls?.vendorReg ? (
                        <img
                          src={imageUrls?.vendorReg}
                          alt={"vendor Reg"}
                          style={{ width: "150px" }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                )}{" "}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label  col-3">
                      Vendor sign
                    </label>
                    <div className="w-100 col">
                      {imageUrls?.vendorSign ? (
                        <img
                          src={imageUrls?.vendorSign}
                          alt={"vendor Sign"}
                          style={{ width: "150px" }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                )}{" "}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label  col-3">
                      Vendor bank passbook
                    </label>
                    <div className="w-100 col">
                      {imageUrls?.vendorBankPassbook ? (
                        <img
                          src={imageUrls?.vendorBankPassbook}
                          alt={"vendor Bank Passbook"}
                          style={{ width: "150px" }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText="Edit Vendor"
                  href={`/purchase-vendor-management/edit-vendor/${id}`}
                />
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewVendor;
