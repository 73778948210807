import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { FcPhoneAndroid } from "react-icons/fc";

const ViewMobileApp = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    const strTime = `${hours}:${minutes}:${seconds} ${ampm}`;

    return `${day} ${month} ${year}, ${strTime}`;
  }

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/mobileApp/getAllMobilAppById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcPhoneAndroid />
                  Call Details
                </h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specification</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>Login Person name</td>
                          <td>
                            {(data?.loginPersonName || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Login Person email</td>
                          <td>{data?.loginPersonEmail || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Contact Number</td>
                          <td>{(data?.mobileNumber || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Call Duration</td>
                          <td>{(data?.duration || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Call Type</td>
                          <td>{data?.callType || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Call date & time</td>
                          <td>{formatDateTime(data?.callTime) || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Internet Connected</td>
                          <td>
                            {data?.isInternetConnected ? "Yes" : "No" || "N/A"}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewMobileApp;
