import React from "react";
import Footer from "../Components/Footer/footer";

const TermsAndConditions = () => {
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">Plant Wiki</h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Learn everything about plants you are
                  growing.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12 my-3">
              <h3>
                Overview of the Price Watcher RNN Model for Vegetable Price
                Prediction
              </h3>
              <p>
                In this article, I provide an overview of the RNN model built
                for forecasting different agriculture vegetable prices as part
                of the Price Watcher project. The primary objectives of this
                project were to design a model capable of not only predicting
                the next time trend but also generating a sequence of future
                price predictions. Additionally, the model integrates multiple
                driving time series along with a set of static (scalar) features
                as its inputs.
              </p>
              <p>
                Some of this things to remember before studing the subject. We
                are trying to predict the trend of vegetable comodity and not
                the pricing. Pricing is just an parameter to understand the
                trend if it is upwards or downwards. Current state of past data
                is not liquid or sufficient enough to predict the pin-point
                pricing. but it is sufficient to understand the trend based on
                the deviations.
              </p>
              <p>
                The datasets here considered are based on weekly low and highs,
                the dataset considerations are from 1st week of 2022. We have
                ommited the datasets before that to avoid fluctuations which was
                caused due to covid price change.
              </p>

              <p>
                The price factor is purely taken from climate, geography,
                culture and supply-demand. 2019 to 2021 price scales were purely
                based on availibility and geo-political situation which is
                unlikely to repeat in future. To keep datasets clean all entries
                from 2019 to 2021 are avoided.
              </p>
              <h3>Model Architecture</h3>
              <p>
                The model utilizes a standard sequence-to-sequence recurrent
                neural network architecture. Inputs consist of past values of
                the predicted time series (from year 2022 to till date), other
                driving time series values (optional), and timestamp embeddings
                (optional). If available, static features can also condition the
                prediction.
              </p>
              <p>
                To get maximum accuracy to train the algorithm average of week
                price is considered as base value of that week. Once algorithm
                is trained enough to predict the variation range we will
                consider weekly lows and weekly highs.
              </p>
              <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1723096324/1_dbqcwt.png" />

              <h3>Encoder</h3>
              <p>
                The encoder encodes time series inputs and their respective
                timestamp embeddings into a fixed-size vector representation. It
                also produces latent vectors for individual time steps, which
                are later used in the decoder's attention mechanism. A
                multi-layer unidirectional recurrent neural network is used,
                with residual connections in all layers except the first.
              </p>
              <p>
                To manage long input sequences that could cause GPU memory
                issues or slow down training, a 1D convolution with the same
                kernel size and stride is applied to the input sequence before
                feeding it to the RNN encoder. This convolution reduces the
                input size by a factor of <em>n</em>, where <em>n</em> is the
                convolution kernel size.
              </p>

              <p>
                Current encoder is working with Week data and on limited set of
                vegetables with averaging of price across all APMC markets in
                India. This is deleberately done for cost optimization and also
                in next architecture changes the quantum of vegetables with week
                low and highs along with APMC values will be optimized to create
                localize result.{" "}
              </p>

              <h3>Recurrent neural network</h3>
              <p>
                Recurrent neural networks (RNNs) are a class of artificial
                neural networks for sequential data processing. Unlike
                feedforward neural networks, which process data in a single
                pass, RNNs process data across multiple time steps, making them
                well-adapted for modelling and processing text, speech, and time
                series.The fundamental building block of an RNN is the recurrent
                unit. This unit maintains a hidden state, essentially a form of
                memory, which is updated at each time step based on the current
                input (in our case locally feed price of agricultural comodity)
                and the previous hidden state. This feedback loop allows the
                network to learn from past inputs and incorporate that knowledge
                into its current processing. This unique ability of RNN modeling
                increrases the accuracy of result based on data availibility.
                Early RNNs suffered from the vanishing gradient problem,
                limiting their ability to learn long-range dependencies. This
                was solved by the invention of Long Short-Term Memory (LSTM)
                networks in 1997, which became the standard architecture for
                RNN. With this new and evolved architecture we are able to guage
                the trend. but it comes with two limitations.
              </p>

              <h3>Limitations of RNN</h3>
              <p>
                Range based result:
                <br />
                RNN does not give pin pointed accurate result, but it works well
                with range. re-run of multiples gives more prominent range value
                which helps understanding the more accurate range.
              </p>
              <p>
                Limited duration trend gauging:
                <br /> RNN works well when prediction is generated for near
                future. To be accurate 1/20 of the data in future. That means if
                we have data of 20 years, we can accurately predict trend for
                next one year. currently holding data from 2022 gives us power
                only to get accurate graph plotting for next 4 to 5 weeks. As
                data will mount the duration of prediction will increase.
              </p>
              <h3>Gated recurrent unit</h3>
              <p>
                Gated recurrent units (GRUs) are a gating mechanism in recurrent
                neural networks, introduced in 2014 by Kyunghyun Cho et al. The
                GRU is like a long short-term memory (LSTM) with a gating
                mechanism to input or forget certain features, but lacks a
                context vector or output gate, resulting in fewer parameters
                than LSTM. GRU's performance on certain tasks of polyphonic
                music modeling, speech signal modeling and natural language
                processing was found to be similar to that of LSTM.GRUs showed
                that gating is indeed helpful in general, and Bengio's team came
                to no concrete conclusion on which of the two gating units was
                better.{" "}
                <a href="https://en.wikipedia.org/wiki/Gated_recurrent_unit">
                  Detailed Article
                </a>
              </p>
              <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1723096324/2_pzcyuf.png" />

              <h3>Context Layer</h3>
              <p>
                The context layer sits between the encoder and the decoder. It
                concatenates the encoder's final state with static features and
                embeddings, producing a fixed-size vector used as the initial
                state for the decoder.
              </p>

              <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1723096324/3_ercwye.png" />
              <h3>Decoder</h3>
              <p>
                The decoder is implemented as an autoregressive recurrent neural
                network with attention. At each step, the input is a
                concatenation of the previous sequence value and a timestamp
                embedding. Feeding timestamp embeddings helps the model learn
                patterns in seasonal data.
              </p>
              <p>
                At the first step, the decoder uses the context vector as the
                initial cell value and a concatenation of the initial sequence
                value and the first timestamp embedding as a cell input. The
                first layer emits an attention query, fed to the attention
                module, which outputs a state used as the cell state in the next
                step. Lower layers of the decoder do not use attention. The
                decoder's outputs are the raw predicted values, fed to the next
                step with a timestamp embedding.
              </p>
              <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1723096324/4_a5qucp.png" />
              <h3>Attention Mechanism</h3>
              <p>
                The attention mechanism allows the decoder to selectively access
                encoder information during decoding. It learns a weighting
                function that takes the previous cell state and a list of
                encoder outputs as input, outputting a scalar weight for each
                encoder output. A weighted sum of encoder outputs is
                concatenated with the query, followed by a nonlinear projection
                to the next cell state.
              </p>
              <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1723096324/5_dmu2fq.png" />
              <h3>Data Preparation</h3>
              <p>
                Data preparation involves ensuring that the span of time series
                features does not overlap with the targets and that the latest
                feature timestamp is just before the first target timestamp.
              </p>
              <p>
                {" "}
                Static features should be aggregated up to the last feature
                timestamp. Normalization of data to a suitable range, typically
                between -1 and 1, is also crucial. The chosen normalization
                scheme involves taking a log to remove potential skew, then
                computing a mean and standard deviation, with the network input
                being the z-score of the log.
              </p>

              <h3>Success of model</h3>
              <p>
                As with every machine learning model there are successes where
                the model makes a very good prediction and failures where the
                prediction is not so great. RNN model works very best when
                maximum data with additonal factors are considered to build up
                the deviation. Some of the factors considered to build the
                deviations are as follows.
              </p>
              <ol>
                <li>Diwali season</li>
                <li>Ramzan month</li>
                <li>Rain duration as well as intensity.</li>
                <li>Interest rates</li>
                <li>Oil prices (Crud vs Refined)</li>
                <li>DAP & UREA import quantity</li>
                <li>INR to USD</li>
                <li>Indonatia imports</li>
                <li>Brazil imports </li>
                <li>GBP to INR </li>
                <li>Argentina imports </li>
                <li>INR to AED </li>
                <li>Replacement factor </li>
                <li>Wedding season </li>
                <li>Tax filing duration</li>
                <li>Salary week </li>
                <li>Irrigation increment factor</li>
                <li>Road increment factor</li>
                <li>Electricity increment factor</li>
                <li>pesticide increment factor</li>
                <li>fertilzer increment factor</li>
                <li>School season</li>
                <li>Medical cretical weeks</li>
                <li>Holiday season</li>
                <li>Long weekend season</li>
                <li>Supermarkets increment factor</li>
                <li>Foodcoarts increment factor</li>
                <li>Population of country</li>
                <li>Per capita spending ratio</li>
                <li>Sell of automobiles</li>
              </ol>

              <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1723096324/6_jgp8wu.png" />
              <p>
                You can consider this logic like this. If RNN predicted price of
                'X' vegetable as Rs.100. On that week the average of week data
                comes to Rs.120, the RNN will consider this new actual data of
                Rs.120 and make change in the accuracy on all previous parameter
                which would have helped to get to the value of Rs.120. So
                iprovise on accuracy of the said range, continues data medeling
                is run from day one of data availibility, everytime when new
                ground data is fed into the system. And the same revised graph
                is displayed to get the understanding of trend.
              </p>

              <h3>Results</h3>
              <p>
                For illustration, the model was used to predict the prices of
                various vegetables for the next week, given data from the
                previous two months. An input sequence convolution layer reduced
                the encoder RNN input size. To enhance prediction accuracy, the
                model incorporated additional vegetable price data to learn
                potential correlations. Features included daily prices and
                volumes, with additional static inputs like historical price
                spreads and past 60-day means.
              </p>

              <h3>Conclusion</h3>
              <p>
                Recurrent neural networks are powerful tools for modeling
                sequential data. The model described here can be applied to
                various problems, from sales forecasting to energy consumption
                prediction. Its ability to condition predictions on multivariate
                input series and scalar inputs makes it flexible enough to
                incorporate multiple data sources, making it a robust solution
                for agricultural price forecasting in the Price Watcher project.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TermsAndConditions;
