import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import { useSelector } from "react-redux";
import LoadingPage from "../../../Components/layouts/loading";
import { priceWatcherInfo } from "../../../SchemaInfo/info";
import { PriceWatcherSchema } from "../../../Schemas/Price Watcher/create_price_watcher";

const AddPriceWatcher = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [plantList, setPlantList] = useState();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    performRequest("GET", "/agronomyWiki/getAllAgronomyWiki/")
      .then((res) => {
        if (res.status === 200) {
          setPlantList(res?.data?.data);
        } else {
          Toast("error", "Error while getting Data!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, []);

  const handelSubmit = (values) => {
    values = {
      ...values,
      plantId: +values.plantId,
    };
    performRequest("POST", "/priceWatcher/createPriceWatcher", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "created successfully!");
          navigate(`/buyers-price-watcher/`);
        } else {
          Toast("error", "Error while creating Batch!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Price Watcher</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Price Tracking</h2>
            </div>
            <Formik
              initialValues={priceWatcherInfo}
              validationSchema={PriceWatcherSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-lg-12 col-md-12">
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Plant name
                          </label>
                          <div className="w-100 col">
                            <CustomSelect type="text" name="plantId">
                              <option value="">Select</option>
                              {plantList &&
                                plantList?.map((plant) => {
                                  return (
                                    <option key={plant.id} value={plant.id}>
                                      {plant.name}
                                    </option>
                                  );
                                })}
                            </CustomSelect>
                            {props.touched.plantId && props.errors.plantId && (
                              <p className="text-danger">
                                {props.errors.plantId}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">Week</label>
                          <div className="w-100 col">
                            <CustomSelect name="week" type="text">
                              <option value="">Select</option>
                              {[...Array(52)].map((_, index) => (
                                <option key={index} value={index + 1}>
                                  Week {index + 1}
                                </option>
                              ))}
                            </CustomSelect>
                            {props.touched.week && props.errors.week && (
                              <p className="text-danger">{props.errors.week}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Week start date
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="weekStartDate" type="date" />
                            {props.touched.weekStartDate &&
                              props.errors.weekStartDate && (
                                <p className="text-danger">
                                  {props.errors.weekStartDate}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Week end date
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="weekEndDate" type="date" />
                            {props.touched.weekEndDate &&
                              props.errors.weekEndDate && (
                                <p className="text-danger">
                                  {props.errors.weekEndDate}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Weekly price
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="weeklyPrice" />

                            {props.touched.weeklyPrice &&
                              props.errors.weeklyPrice && (
                                <p className="text-danger">
                                  {props.errors.weeklyPrice}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Season
                          </label>
                          <div className="w-100 col">
                            <CustomSelect name="season" type="text">
                              <option value={" "}>Select</option>
                              <option value={"Winter (Shishir or Shita Ritu)"}>
                                Winter (Shishir or Shita Ritu)
                              </option>
                              <option value={"Spring Season (Vasant Ritu)"}>
                                Spring Season (Vasant Ritu)
                              </option>
                              <option value={"Summer (Grishma Ritu)"}>
                                Summer (Grishma Ritu)
                              </option>
                              <option value={"Monsoon (Varsha Ritu)"}>
                                Monsoon (Varsha Ritu)
                              </option>
                              <option value={"Autumn (Sharad Ritu)"}>
                                Autumn (Sharad Ritu)
                              </option>
                              <option value={"Pre-winter (Hemant Ritu)"}>
                                Pre-winter (Hemant Ritu)
                              </option>
                            </CustomSelect>
                            {props.touched.season && props.errors.season && (
                              <p className="text-danger">
                                {props.errors.season}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">Month</label>
                          <div className="w-100 col">
                            <CustomSelect name="month" type="text">
                              <option value="">Select</option>
                              {months.map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </CustomSelect>
                            {props.touched.month && props.errors.month && (
                              <p className="text-danger">
                                {props.errors.month}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">Year</label>
                          <div className="w-100 col">
                            <CustomSelect name="year" type="text">
                              <option value={" "}>Select</option>
                              <option value={"2019"}>2019</option>
                              <option value={"2020"}>2020</option>
                              <option value={"2021"}>2021</option>
                              <option value={"2022"}>2022</option>
                              <option value={"2023"}>2023</option>
                              <option value={"2024"}>2024</option>
                              <option value={"2025"}>2025</option>
                              <option value={"2026"}>2026</option>
                              <option value={"2027"}>2027</option>
                              <option value={"2028"}>2028</option>
                              <option value={"2029"}>2029</option>
                              <option value={"2030"}>2030</option>
                            </CustomSelect>
                            {props.touched.year && props.errors.year && (
                              <p className="text-danger">{props.errors.year}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Price Tracking" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default AddPriceWatcher;
