import * as yup from "yup";

export const InsentiveSchema = yup.object().shape({
  //
  product: yup.string().required("Required"),
  preGstAmount: yup.number().required("Required"),
  leadName: yup.string().required("Required"),
  paidStatus: yup.string(),
  incentiveDescription: yup.string().required("Required"),
});
