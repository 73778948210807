import React, { useEffect, useState } from "react";

import siteInspection from "../../../certificates/siteInspection.png";
const WorkOrderLocations = ({ quotationType = null }) => {
  const [hideScope, setHideScope] = useState("");

  useEffect(() => {
    if (quotationType === "hydroponics only") {
      setHideScope("CEA");
    }
    if (
      quotationType === "nvph" ||
      quotationType === "fanpad" ||
      quotationType === "nethouse"
    ) {
      setHideScope("Hydroponic");
    }
  }, [quotationType]);
  let serial = 1;

  return (
    <div>
      <h3 className="table-heading">Work Order Details & Locations:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th>Work Order</th>
            <th>Scope</th>
            <th>Duration</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serial++}.</td>
            <td>Site inspection</td>
            <td>Universal</td>
            <td>Week 1</td>
            <td>On-site</td>
          </tr>
          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Polyhouse foundation</td>
              <td>CEA</td>
              <td>Week 1</td>
              <td>On-site</td>
            </tr>
          )}
          <tr>
            <td>{serial++}.</td>
            <td>Procurement of materials</td>
            <td>Universal</td>
            <td>Week 1</td>
            <td>Factory</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Modification of materials</td>
            <td>Universal</td>
            <td>Week 1</td>
            <td>Factory</td>
          </tr>
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Procurement of box pipes for hydroponic types</td>

              <td>Hydroponic</td>
              <td>Week 2</td>
              <td>Factory</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Manufacturing of frames</td>
              <td>Hydroponic</td>
              <td>Week 2</td>
              <td>Factory</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Procurement & modification of NFT pipes / Grow Bags </td>
              <td>Hydroponic</td>
              <td>Week 2</td>
              <td>Factory</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>
                Procurement of filters,fittings, pipings, valves, gauges, pipe
                connectors.
              </td>
              <td>Hydroponic</td>
              <td>Week 3</td>
              <td>Factory</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Procurement of water tank material</td>
              <td>Hydroponic</td>
              <td>Week 3</td>
              <td>Factory</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Development of water tank structure.</td>
              <td>Hydroponic</td>
              <td>Week 3</td>
              <td>Factory</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Tanks and fittings procurement</td>
              <td>Hydroponic</td>
              <td>Week 3</td>
              <td>Factory</td>
            </tr>
          )}
          <tr>
            <td>{serial++}.</td>
            <td>Procurement of water pumps.</td>
            <td>Universal</td>
            <td>Week 3</td>
            <td>Factory</td>
          </tr>
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>
                Procurement of UPVC inlets/ outlets and net cups and foggers.
              </td>
              <td>Hydroponic</td>
              <td>Week 4</td>
              <td>Factory</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Development of frame foundations.</td>
              <td>Hydroponic</td>
              <td>Week 4</td>
              <td>On-site</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Reservoir tank set up.</td>
              <td>Hydroponic</td>
              <td>Week 5</td>
              <td>On-site</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Installation of hydroponic frames</td>
              <td>Hydroponic</td>
              <td>Week 5</td>
              <td>On-site</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Installation of NFT pipes</td>
              <td>Hydroponic</td>
              <td>Week 5</td>
              <td>On-site</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Plumbing work of NFT pipes</td>
              <td>Hydroponic</td>
              <td>Week 6</td>
              <td>On-site</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>
                Installation of tank units, fittings and connection to NFT
              </td>
              <td>Hydroponic</td>
              <td>Week 6</td>
              <td>On-site</td>
            </tr>
          )}
          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Fogger installation</td>

              <td>CEA</td>
              <td>Week 6</td>
              <td>On-site</td>
            </tr>
          )}
          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Electric fitting installation</td>
              <td>CEA</td>
              <td>Week 6</td>
              <td>On-site</td>
            </tr>
          )}
          <tr>
            <td>{serial++}.</td>
            <td>Plumbing work, sanitation checking and fixing.</td>
            <td>Universal</td>
            <td>Week 6</td>
            <td>On-site</td>
          </tr>
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Installation of mother tanks and fogger tanks.</td>
              <td>Hydroponic</td>
              <td>Week 7</td>
              <td>On-site</td>
            </tr>
          )}
          <tr>
            <td>{serial++}.</td>
            <td>Final connections of loose items. & quality analysis.</td>
            <td>Universal</td>
            <td>Week 7</td>
            <td>On-site</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Handover of project</td>
            <td>Universal</td>
            <td>Week 7</td>
            <td>On-site</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Software access</td>
            <td>Universal</td>
            <td>Week 7</td>
            <td>KBPL Office</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Employee training</td>
            <td>Universal</td>
            <td>Week 8</td>
            <td>KBPL Farm</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Agronomy support starts</td>
            <td>Universal</td>
            <td>Week 8</td>
            <td>KBPL Office</td>
          </tr>
        </tbody>
      </table>

      <p>
        <strong>Notes: </strong>
        Your project will be completed and handed over to you in stipulated time
        unless there is any delay in installments or in condition of force
        majeure or any delay in transport &amp; custom clearance of goods. You
        will be notified with revised timeline in this conditions. KBPL reserves
        all rights in changing the order of the work based on various
        conditions, available resources and manpower. KBPL has ongoing R&D to
        improve quality of its products and it reserves all rights to make
        changes in the system at any time.
      </p>
      <div style={{ width: "100%" }}>
        <img src={siteInspection} width={"100%"} alt="kryzenCertificates" />
      </div>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default WorkOrderLocations;
