import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import { useSelector } from "react-redux";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { ClientProjectInfo, LeadInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import { ClientProjectSchema } from "../../../Schemas/clients/client_project_schema";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";

const AddClientProject = () => {
  const store = useSelector((store) => store.login.user);

  const [leadsInfo, setLeadsInfo] = useState(LeadInfo);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handelSubmit = (values, actions) => {
    values = { ...values, generatedBy: store.Name };
    if (
      values.clientContactNumber == "0000000000" ||
      values.clientContactNumber == "1111111111" ||
      values.clientContactNumber == "2222222222" ||
      values.clientContactNumber == "3333333333" ||
      values.clientContactNumber == "4444444444" ||
      values.clientContactNumber == "5555555555" ||
      values.clientContactNumber == "6666666666" ||
      values.clientContactNumber == "7777777777" ||
      values.clientContactNumber == "8888888888" ||
      values.clientContactNumber == "9999999999" ||
      values.clientContactNumber == "1234567890" ||
      values.clientContactNumber == "9876543210" ||
      values.clientContactNumber == "0987654321"
    ) {
      Toast("error", "Invalid phone number");
    } else {
      setLoading(true);
      performRequest("POST", "/clientProject/createCp", values)
        .then((res) => {
          if (res.status === 201) {
            Toast("success", "Project created successfully!");
            navigate(`/client-projects`);
          } else if (res.data.error == "Client already exists") {
            Toast("error", "Client already exists with the same email");
          } else {
            Toast("error", "Error while creating project!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    }
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Client Projects</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Project</h2>
            </div>
            <Formik
              initialValues={ClientProjectInfo}
              validationSchema={ClientProjectSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Client name
                      </label>

                      <div className="w-100 col">
                        <CustomInput name="clientName" type="text" />
                        {props.touched.clientName &&
                          props.errors.clientName && (
                            <p className="text-danger">
                              {props.errors.clientName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required">
                        Client email
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="clientEmail" type="email" />
                        {props.touched.clientEmail &&
                          props.errors.clientEmail && (
                            <p className="text-danger">
                              {props.errors.clientEmail}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Client contact number
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="clientContactNumber" />
                        {props.touched.clientContactNumber &&
                          props.errors.clientContactNumber && (
                            <p className="text-danger">
                              {props.errors.clientContactNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Business name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="businessName" type="text" />
                        {props.touched.businessName &&
                          props.errors.businessName && (
                            <p className="text-danger">
                              {props.errors.businessName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Business gst number
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="businessGstNumber" />

                        {props.touched.businessGstNumber &&
                          props.errors.businessGstNumber && (
                            <p className="text-danger">
                              {props.errors.businessGstNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Business type,
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="businessType">
                          <option value="">Select</option>

                          <option value="proprietorship">Proprietorship</option>
                          <option value="soleproprietorship">
                            Sole Proprietorship
                          </option>
                          <option value="onePersonCompany">
                            One Person Company
                          </option>
                          <option value="LLP">LLP</option>
                          <option value="partnership">Partnership</option>
                          <option value="pvtLtd">Pvt Ltd</option>
                        </CustomSelect>
                        {props.touched.businessType &&
                          props.errors.businessType && (
                            <p className="text-danger">
                              {props.errors.businessType}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Pan number
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="panNumber" />
                        {props.touched.panNumber && props.errors.panNumber && (
                          <p className="text-danger">
                            {props.errors.panNumber}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Aadhar card number
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="aadharcardNumber" />
                        {props.touched.aadharcardNumber &&
                          props.errors.aadharcardNumber && (
                            <p className="text-danger">
                              {props.errors.aadharcardNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required ">
                        Billing address
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="billingAddress" />

                        {props.touched.billingAddress &&
                          props.errors.billingAddress && (
                            <p className="text-danger">
                              {props.errors.billingAddress}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Farm address
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="farmAddress" />

                        {props.touched.farmAddress &&
                          props.errors.farmAddress && (
                            <p className="text-danger">
                              {props.errors.farmAddress}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        progressStatus
                      </label>
                      <div className="w-100 col">
                        <label className="form-check form-check-inline">
                          <CustomCheckbox
                            type="checkbox"
                            width={"25px"}
                            name="progressStatus"
                            value="hydroponic masterclass"
                          />
                          <span>Hydroponic Masterclass</span>
                        </label>

                        <label className="form-check form-check-inline">
                          <CustomCheckbox
                            name="progressStatus"
                            value="office visit"
                            type="checkbox"
                            width={"25px"}
                          />
                          <span>Office Visit</span>
                        </label>
                        <label className="form-check form-check-inline">
                          <CustomCheckbox
                            type="checkbox"
                            width={"25px"}
                            name="progressStatus"
                            value="farm visit"
                          />
                          <span>Farm Visit</span>
                        </label>
                        <label className="form-check form-check-inline">
                          <CustomCheckbox
                            type="checkbox"
                            width={"25px"}
                            name="progressStatus"
                            value="site inspection"
                          />
                          <span>Site Inspection</span>
                        </label>

                        {props.touched.progressStatus &&
                          props.errors.progressStatus && (
                            <p className="text-danger">
                              {props.errors.progressStatus}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>

                      <div className="w-100 col">
                        <CustomTextArea name="notes" />
                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Project" />

                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default AddClientProject;
