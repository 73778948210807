import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FcAndroidOs, FcMindMap } from "react-icons/fc";
import { FcBusinessman } from "react-icons/fc";
import { FcCalendar } from "react-icons/fc";
import { FcCalculator } from "react-icons/fc";
import { FcClock } from "react-icons/fc";
import { FcFaq } from "react-icons/fc";
import { FcRatings } from "react-icons/fc";
import { FcGenealogy } from "react-icons/fc";
import { FcPuzzle } from "react-icons/fc";
import { FcSurvey } from "react-icons/fc";
import { FcPositiveDynamic } from "react-icons/fc";
import { FcAreaChart } from "react-icons/fc";
import { FcClearFilters } from "react-icons/fc";
import { FcCallTransfer } from "react-icons/fc";
import { FcDisapprove } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import { FcCustomerSupport } from "react-icons/fc";
import { FcFeedIn } from "react-icons/fc";
import { FcProcess } from "react-icons/fc";
import { FcPhone } from "react-icons/fc";
import { FcLandscape } from "react-icons/fc";
import { FcManager } from "react-icons/fc";
import { FcCircuit } from "react-icons/fc";
import { FcWorkflow } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import { FcPanorama } from "react-icons/fc";
import { FcReading } from "react-icons/fc";
import { FcCollect } from "react-icons/fc";
import { FcCurrencyExchange } from "react-icons/fc";
import { FcLineChart } from "react-icons/fc";
import { FcInspection } from "react-icons/fc";
import { FcCloseUpMode } from "react-icons/fc";
import { FcStackOfPhotos } from "react-icons/fc";
import { FcBiomass } from "react-icons/fc";
import { FcDam } from "react-icons/fc";
import { FcConferenceCall } from "react-icons/fc";
import { FcInTransit } from "react-icons/fc";
import { FcSalesPerformance } from "react-icons/fc";
import { FcReuse } from "react-icons/fc";
import { FcTemplate } from "react-icons/fc";
import { FcShop } from "react-icons/fc";
import { FcInvite } from "react-icons/fc";
import { FcRules } from "react-icons/fc";
import { FcCollaboration } from "react-icons/fc";
import { FcOrgUnit } from "react-icons/fc";
import { FcPortraitMode } from "react-icons/fc";
import { FcWikipedia } from "react-icons/fc";
import { FcSms } from "react-icons/fc";
import { FcDeployment } from "react-icons/fc";
import { FcPaid } from "react-icons/fc";
import { FcPackage } from "react-icons/fc";
import { FcPieChart } from "react-icons/fc";
import Scrollbar from "../../Pages/scroll";
import { IoLogoWhatsapp } from "react-icons/io";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";

const CheckShowNavBasedRole = (role, projectData) => {
  if (role === process.env.REACT_APP_ROLE_ADMIN_S) {
    return [
      "Internal",
      "Client Invoice",
      "SI-Feedback",
      "Farm Training",
      "Reimbursement Management",
      // "Campaign",
      "Quotation",
      "Sales",
      "Call Records",
      "Client",
      "Farm",
      "Buyers Connect",
      "Workforce",
      "Purchase",
      "Agronomy",
      "Resources",
      "Employee Management",
      "Leave Management",
      "Attendence Management",
      "Internal FAQ",
      "Quotation Matrix",
      "Custom Quotation",
      "Leads",
      "Leads FollowUp",
      "Leads-Filtered",
      "Cold Leads",
      "Enquiry",
      "Incentives",
      "Client Projects",
      "Site Inspection",
      "Handover",
      "Project Tracking",
      "Payment Tracking",
      "Expence Tracking",
      "Leads Tracking",
      "Client Feedback",
      "Nursery Management",
      "Farm Management",
      "Farm Agreement",
      "Water Management",
      "Buyers Management",
      "Nutrient Calculations",
      "Price Watcher",
      "Management",
      "Purchase Order",
      "Delivery Challan",
      "Vendor Management",
      "Project Specification",
      "Agronomy Wiki",
      "Farm Support",
      "Assets",
      "Inventory",
      "Task Management",
      "Mobile App",
      "Watsapp Logs",
      "Revenue Management",
      "Analytics",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_ADMIN) {
    return [
      "Internal",
      "Client Invoice",
      "SI-Feedback",
      "Farm Training",
      "Reimbursement Management",
      // "Campaign",
      "Quotation",
      "Sales",
      "Call Records",
      "Client",
      "Farm",
      "Buyers Connect",
      "Workforce",
      "Purchase",
      "Agronomy",
      "Resources",
      "Employee Management",
      "Leave Management",
      "Attendence Management",
      "Internal FAQ",
      "Quotation Matrix",
      "Custom Quotation",
      "Leads",
      "Leads FollowUp",
      "Leads-Filtered",
      "Cold Leads",
      "Enquiry",
      "Incentives",
      "Client Projects",
      "Site Inspection",
      "Handover",
      "Project Tracking",
      "Payment Tracking",
      "Expence Tracking",
      "Leads Tracking",
      "Client Feedback",
      "Nursery Management",
      "Farm Management",
      "Farm Agreement",
      "Water Management",
      "Buyers Management",
      "Nutrient Calculations",
      "Price Watcher",
      "Management",
      "Purchase Order",
      "Delivery Challan",
      "Vendor Management",
      "Project Specification",
      "Agronomy Wiki",
      "Farm Support",
      "Assets",
      "Inventory",
      "Task Management",
      "Revenue Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_PURCHASE) {
    return [
      "Internal",
      "Reimbursement Management",
      "Quotation",
      "Client",
      "Purchase",
      "Resources",
      "Leave Management",
      "Internal FAQ",
      "Quotation Matrix",
      "Custom Quotation",
      "Client Projects",
      "Site Inspection",
      "Handover",
      "Project Tracking",
      "Payment Tracking",
      "Expence Tracking",
      "Purchase Order",
      "Delivery Challan",
      "Vendor Management",
      "Project Specification",
      "Assets",
      "Inventory",
      "Task Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_SALLE) {
    return [
      "Internal",
      "Reimbursement Management",
      "Quotation",
      "Sales",
      "Call Records",
      "Leave Management",
      "Internal FAQ",
      "Custom Quotation",
      "Leads",
      "Leads FollowUp",
      "Leads-Filtered",
      "Farm Training",
      // "Incentives",
      "Leads Tracking",
      "Client Feedback",
      "Task Management",
      "Revenue Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_SALESAGENCY) {
    return [
      "Internal",
      "Sales",
      "Call Records",
      "Internal FAQ",
      "Leads",
      "Cold Leads",
      "Task Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_AGRONOMIST) {
    return [
      "Internal",
      "Reimbursement Management",
      "Farm",
      "Agronomy",
      "Resources",
      "Leave Management",
      "Buyers Management",
      "Price Watcher",
      "Internal FAQ",
      "Buyers Connect",
      "Nursery Management",
      "Farm Management",
      "Farm Agreement",
      "Water Management",
      "Nutrient Calculations",
      "Agronomy Wiki",
      "Farm Support",
      "Assets",
      "Inventory",
      "Task Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_OPS) {
    return [
      "Internal",
      "Sales",
      "Farm Training",
      "Reimbursement Management",
      "Client",
      "SI-Feedback",
      "Farm",
      "Resources",
      "Workforce",
      "Nursery Management",
      "Farm Management",
      "Water Management",
      "Nutrient Calculations",
      "Agronomy Wiki",
      "Farm Support",
      "Agronomy",
      "Buyers Connect",
      "Buyers Management",
      "Leave Management",
      "Internal FAQ",
      "Client Projects",
      "Site Inspection",
      "Handover",
      "Project Tracking",
      "Management",
      "Assets",
      "Inventory",
      "Task Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_INSPECTION) {
    return [
      "Internal",
      "Reimbursement Management",
      "Client",

      "Leave Management",

      "Internal FAQ",

      "Site Inspection",
      "Handover",
      "Task Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_MARKETING) {
    return [
      "Internal",
      "Reimbursement Management",
      "Buyers Connect",
      "Leave Management",

      "Internal FAQ",

      "Buyers Management",

      "Price Watcher",
      "Task Management",
    ];
  }

  if (role === process.env.REACT_APP_ROLE_CLIENT) {
    const projectsData = projectData?.projectTrackingData;
    // console.log(projectsData);
    const WorkOrder = projectsData?.[0]?.workOrderUpload;
    // console.log(WorkOrder);

    if (projectsData && WorkOrder) {
      // console.log(true);
      return [
        "Client",
        "Farm",
        "Buyers Connect",
        "Workforce",
        "Farm Support",
        "Agronomy",
        "Site Inspection",
        "Handover",
        "Project Tracking",
        "Payment Tracking",
        "Nursery Management",
        // "Farm Management",
        "Nutrient Calculations",
        "Water Management",
        "Buyers Management",
        "Management",
        "Agronomy Wiki",
        "Resources",
        "Assets",
        "Inventory",
        "Price Watcher",
      ];
    } else {
      // console.log(false);
      return ["Client", "Site Inspection"];
    }
  }

  if (role === process.env.REACT_APP_ROLE_ACCOUNTS) {
    return [
      "Quotation",
      "Purchase",
      "Internal FAQ",
      "Quotation Matrix",
      "Custom Quotation",
      "Client Projects",
      "Site Inspection",
      "Handover",
      "Project Tracking",
      "Purchase Order",
      "Delivery Challan",
      "Vendor Management",
      "Project Specification",
      "Client",
      "Payment Tracking",
      "Resources",
      "Assets",
      "Inventory",
      "Internal",
      "Reimbursement Management",
      "Leave Management",
      "Expence Tracking",
      "Farm Training",
      "Sales",
      "Task Management",
      "Employee Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_IT) {
    return [
      "Internal",
      "Reimbursement Management",
      "Leave Management",
      "Task Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_SSM) {
    return [
      "Internal",
      "Reimbursement Management",
      "Leave Management",
      "Task Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_SALES_MANAGER) {
    return [
      "Internal",
      "Reimbursement Management",
      "Quotation",
      "Sales",
      "Call Records",
      "Leave Management",
      "Internal FAQ",
      "Custom Quotation",
      "Leads",
      "Leads FollowUp",
      "Leads-Filtered",
      "Cold Leads",
      "Farm Training",
      // "Incentives",
      "Leads Tracking",
      "Client Feedback",
      "Enquiry",
      "Client",
      "Client Projects",
      "Site Inspection",
      "Project Tracking",
      "Farm",
      "Nursery Management",
      "Water Management",
      "Nutrient Calculations",
      "Buyers Connect",
      "Buyers Management",
      "Workforce",
      "Management",
      "Resources",
      "Assets",
      "Inventory",
      "Workforce",
      "Farm Support",
      "Agronomy",
      "Agronomy Wiki",
      "Task Management",
      "Revenue Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_CLIENT_FEEDBACK) {
    return [
      "Internal",
      "Reimbursement Management",
      "Quotation",
      "Sales",
      "Call Records",
      "Leave Management",
      "Internal FAQ",
      "Custom Quotation",
      "Leads",
      "Leads FollowUp",
      "Leads-Filtered",
      "Farm Training",
      // "Incentives",
      "Leads Tracking",
      "Client Feedback",
      "Task Management",
    ];
  }
  if (role === process.env.REACT_APP_ROLE_SITE_INSPECTION) {
    return ["Client", "Site Inspections"];
  } else {
    return [];
  }
};
const HeaderNavBar = () => {
  let role = useSelector((store) => store?.login?.user?.Role);
  let user = useSelector((store) => store?.login?.user);

  const [projectData, setProjectData] = useState(null);
  const [navBar, setNavBar] = useState([]);
  const location = useLocation();
  const endpoint = location.pathname;
  const [activeNavItem, setActiveNavItem] = useState("");

  useEffect(() => {
    if (role === process.env.REACT_APP_ROLE_CLIENT) {
      performRequest(
        "GET",
        `/projectTracking/getProjectTracking?name=${user?.Name}`
      )
        .then((res) => {
          if (res.status === 200) {
            setProjectData(res.data);
          } else {
            Toast("error", "Error while getting data!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        });
    }
  }, [role]);

  useEffect(() => {
    if (role) {
      setNavBar(CheckShowNavBasedRole(role, projectData));
    }
  }, [role, projectData]);

  useEffect(() => {
    if (role) {
      setNavBar(CheckShowNavBasedRole(role));
    }
  }, [role]);
  useEffect(() => {
    if (
      endpoint.includes("/employee-management") ||
      endpoint.includes("/reimbursement-management") ||
      endpoint.includes("/employee-leave-management") ||
      endpoint.includes("/employee-attendance") ||
      endpoint.includes("/internal-FAQ") ||
      endpoint.includes("/task-management") ||
      endpoint.includes("/mobile-app")
    ) {
      setActiveNavItem("Internal");
    } else if (
      endpoint.includes("/custom-quotation") ||
      endpoint.includes("/standard-quotation")
    ) {
      setActiveNavItem("Quotation");
    } else if (
      endpoint.includes("/farm-training") ||
      endpoint.includes("/leads") ||
      endpoint.includes("/enquiry") ||
      endpoint.includes("/revenue-management") ||
      endpoint.includes("/client-feedback") ||
      endpoint.includes("/call-records") ||
      endpoint.includes("/cold-leads") ||
      endpoint.includes("/sales-analytics")
    ) {
      setActiveNavItem("Sales");
    } else if (
      endpoint.includes("/client-invoice") ||
      endpoint.includes("/client-site") ||
      endpoint.includes("/client-project") ||
      endpoint.includes("/client-payment") ||
      endpoint.includes("/client-expense") ||
      endpoint.includes("/si-feedback-recieved") ||
      endpoint.includes("/site-inspection")
    ) {
      setActiveNavItem("Client");
    } else if (
      endpoint.includes("/farm") ||
      endpoint.includes("/nutrient-calculations")
    ) {
      setActiveNavItem("Farm");
    } else if (endpoint.includes("/buyers")) {
      setActiveNavItem("Buyers");
    } else if (endpoint.includes("/client-employee")) {
      setActiveNavItem("Workforce");
    } else if (
      endpoint.includes("/purchase") ||
      endpoint.includes("/project-specification") ||
      endpoint.includes("/delivery-challan")
    ) {
      setActiveNavItem("Purchase");
    } else if (endpoint.includes("/agronomy")) {
      setActiveNavItem("Agronomy");
    } else if (endpoint.includes("/resources")) {
      setActiveNavItem("Resources");
    } else {
      setActiveNavItem("");
    }
  }, [endpoint]);

  const closeNavbar = () => {
    const navbarMenu = document.getElementById("navbar-menu");
    if (navbarMenu && navbarMenu.classList.contains("show")) {
      const collapse = new window.bootstrap.Collapse(navbarMenu);
      collapse.hide();
    }
  };

  return (
    <header className="navbar-expand-lg">
      <div className="collapse navbar-collapse" id="navbar-menu">
        <div className="navbar">
          <div className="container-xl">
            <ul className="navbar-nav">
              {navBar.includes("Internal") && (
                <li
                  className={
                    activeNavItem === "Internal"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <SiFiles className="nav-link-title" /> */}
                    <FcMindMap className="fs-2 me-1" />
                    <span id="k-Internal" className="nav-link-title ms-1">
                      Internal
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-Internal").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Employee Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/employee-management"
                          >
                            <FcBusinessman className="fs-2  me-1" />
                            Employee Management
                          </NavLink>
                        )}
                        {navBar.includes("Leave Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/employee-leave-management"
                          >
                            {" "}
                            <FcCalendar className="fs-2  me-1" />
                            Leave Management
                          </NavLink>
                        )}
                        {navBar.includes("Reimbursement Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/reimbursement-management"
                          >
                            <FcCalculator className="fs-2  me-1" />
                            Reimbursement Management
                          </NavLink>
                        )}
                        {navBar.includes("Attendence Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/employee-attendance"
                          >
                            <FcClock className="fs-2  me-1" />
                            Attendence Management
                          </NavLink>
                        )}
                        {navBar.includes("Internal FAQ") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/internal-FAQ"
                          >
                            <FcFaq className="fs-2  me-1" />
                            Internal FAQ
                          </NavLink>
                        )}
                        {navBar.includes("Task Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/task-management"
                          >
                            <FcGenealogy className="fs-2  me-1" />
                            Task Management
                          </NavLink>
                        )}
                        {navBar.includes("Mobile App") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/android-app"
                          >
                            <FcAndroidOs className="fs-2  me-1" />
                            Android App
                          </NavLink>
                        )}
                        {navBar.includes("Watsapp Logs") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/watsapp-logs"
                          >
                            <IoLogoWhatsapp
                              className="fs-2  me-1"
                              style={{ color: "green" }}
                            />
                            Watsapp Logs
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Quotation") && (
                <li
                  className={
                    activeNavItem === "Quotation"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    <FcRatings className="fs-2  me-1" />
                    <span id="k-Quotation" className="nav-link-title ms-1">
                      Quotation
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-Quotation").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Quotation Matrix") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/standard-quotation"
                          >
                            <FcPuzzle className="fs-2  me-1" />
                            Quotation Matrix
                          </NavLink>
                        )}
                        {navBar.includes("Custom Quotation") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/custom-quotation"
                          >
                            <FcSurvey className="fs-2  me-1" />
                            Custom Quotation
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Sales") && (
                <li
                  className={
                    activeNavItem === "Sales"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <MdPointOfSale className="nav-link-title" /> */}
                    <FcPositiveDynamic className="fs-2  me-1" />
                    <span id="k-Sales" className="nav-link-title ms-1">
                      Sales
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-Sales").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Leads") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/leads"
                          >
                            {" "}
                            <FcAreaChart className="fs-2  me-1" />
                            Leads
                          </NavLink>
                        )}
                        {navBar.includes("Leads-Filtered") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/leads-filtered"
                          >
                            <FcClearFilters className="fs-2  me-1" />
                            Leads Filtered
                          </NavLink>
                        )}
                        {navBar.includes("Leads FollowUp") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/leads-followup"
                          >
                            <FcCallTransfer className="fs-2  me-1" />
                            Leads Followup
                          </NavLink>
                        )}
                        {navBar.includes("Cold Leads") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/cold-leads"
                          >
                            <FcDisapprove className="fs-2  me-1" />
                            Cold Leads
                          </NavLink>
                        )}
                        {navBar.includes("Enquiry") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/enquiry"
                          >
                            <FcCustomerSupport className="fs-2  me-1" />
                            Enquiry
                          </NavLink>
                        )}
                        {navBar.includes("Revenue Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/revenue-management"
                          >
                            <FcBullish className="fs-2  me-1" />
                            Revenue Management
                          </NavLink>
                        )}
                        {navBar.includes("Client Feedback") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-feedback"
                          >
                            <FcFeedIn className="fs-2  me-1" />
                            Client Feedback
                          </NavLink>
                        )}
                        {navBar.includes("Leads Tracking") && (
                          // lead track
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/leads-tracking"
                          >
                            <FcProcess className="fs-2  me-1" />
                            Leads Tracking
                          </NavLink>
                        )}
                        {navBar.includes("Call Records") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/call-records"
                          >
                            <FcPhone className="fs-2  me-1" />
                            Call Records
                          </NavLink>
                        )}
                        {navBar.includes("Farm Training") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/farm-training"
                          >
                            <FcLandscape className="fs-2  me-1" />
                            Farm Training
                          </NavLink>
                        )}
                        {navBar.includes("Analytics") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/sales-analytics"
                          >
                            <FcPieChart className="fs-2  me-1" />
                            Analytics
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Client") && (
                <li
                  className={
                    activeNavItem === "Client"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <FaPeopleArrows className="nav-link-title" /> */}
                    <span
                      id="k-ClientManagement"
                      className="nav-link-title ms-1"
                    >
                      <FcManager className="fs-2 me-1" />
                      Clients
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-ClientManagement").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Client Projects") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-projects"
                          >
                            {" "}
                            <FcWorkflow className="fs-2 me-1" />
                            Client Projects
                          </NavLink>
                        )}
                        {navBar.includes("Client Invoice") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-invoice"
                          >
                            <FcFinePrint className="fs-2 me-1" />
                            Client Invoices
                          </NavLink>
                        )}
                        {navBar.includes("Site Inspection") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-site-inspection"
                          >
                            <FcPanorama className="fs-2 me-1" />
                            Site Inspection
                          </NavLink>
                        )}
                        {navBar.includes("Site Inspections") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/site-inspection"
                          >
                            <FcPanorama className="fs-2 me-1" />
                            Site Inspection
                          </NavLink>
                        )}
                        {navBar.includes("Handover") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-project-handover"
                          >
                            <FcReading className="fs-2 me-1" />
                            Handover
                          </NavLink>
                        )}
                        {navBar.includes("Project Tracking") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-project-tracking"
                          >
                            <FcCollect className="fs-2 me-1" />
                            Project Tracking
                          </NavLink>
                        )}
                        {navBar.includes("Payment Tracking") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-payment-tracking"
                          >
                            <FcCurrencyExchange className="fs-2 me-1" />
                            Payment Tracking
                          </NavLink>
                        )}
                        {navBar.includes("Expence Tracking") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-expense-tracking"
                          >
                            <FcLineChart className="fs-2 me-1" />
                            Expence Tracking
                          </NavLink>
                        )}
                        {navBar.includes("SI-Feedback") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/si-feedback-recieved"
                          >
                            <FcInspection className="fs-2 me-1" />
                            SI-Feedback
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Farm") && (
                <li
                  className={
                    activeNavItem === "Farm"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <GiFarmer className="nav-link-title" /> */}
                    <span id="k-farm" className="nav-link-title ms-1">
                      <FcCloseUpMode className="fs-2 me-1" />
                      Farms
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-farm").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Nursery Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/farm-nursery-management"
                          >
                            <FcStackOfPhotos className="fs-2 me-1" />
                            Nursery Management
                          </NavLink>
                        )}
                        {navBar.includes("Farm Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/farm-operations-management"
                          >
                            <FcCircuit className="fs-2 me-1" />
                            Farm Management
                          </NavLink>
                        )}
                        {navBar.includes("Nutrient Calculations") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/nutrient-calculations"
                          >
                            <FcBiomass className="fs-2 me-1" />
                            Nutrient Calculations
                          </NavLink>
                        )}

                        {navBar.includes("Water Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/farm-water-management"
                          >
                            <FcDam className="fs-2 me-1" />
                            Water Management
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Buyers Connect") && (
                <li
                  className={
                    activeNavItem === "Buyers"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <BsFillCartCheckFill className="nav-link-title" /> */}
                    <span id="k-Buyers" className="nav-link-title ms-1">
                      <FcConferenceCall className="fs-2 me-1" />
                      Buyers Connect
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-Buyers").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Buyers Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/buyers-management"
                          >
                            <FcInTransit className="fs-2 me-1" />
                            Buyers Management
                          </NavLink>
                        )}

                        {/* {navBar.includes("Buyers Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/long-term-contract"
                          >
                            <FcInTransit className="fs-2 me-1" />
                            Long Term Contracts
                          </NavLink>
                        )} */}

                        {navBar.includes("Price Watcher") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/buyers-price-watcher"
                          >
                            <FcSalesPerformance className="fs-2 me-1" />
                            Price Watcher
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Workforce") && (
                <li
                  className={
                    activeNavItem === "Workforce"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <BsPersonBoundingBox className="nav-link-title" /> */}
                    <span id="k-Workforce" className="nav-link-title ms-1">
                      <FcReuse className="fs-2 me-1" />
                      Workforce
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-Workforce").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/client-employee-database"
                          >
                            <FcTemplate className="fs-2 me-1" />
                            Labour Management
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Purchase") && (
                <li
                  className={
                    activeNavItem === "Purchase"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <BiSolidPurchaseTag className="nav-link-title" /> */}
                    <span id="k-Purchase" className="nav-link-title ms-1">
                      <FcShop className="fs-2 me-1" />
                      Purchase
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-Purchase").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Purchase Order") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/purchase-order"
                          >
                            <FcInvite className="fs-2 me-1" />
                            Purchase Order
                          </NavLink>
                        )}
                        {navBar.includes("Delivery Challan") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/delivery-challan"
                          >
                            <FcRules className="fs-2 me-1" />
                            Delivery Challan
                          </NavLink>
                        )}
                        {navBar.includes("Vendor Management") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/purchase-vendor-management"
                          >
                            <FcCollaboration className="fs-2 me-1" />
                            Vendor Management
                          </NavLink>
                        )}
                        {navBar.includes("Project Specification") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/project-specification"
                          >
                            <FcOrgUnit className="fs-2 me-1" />
                            Project Specification
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Agronomy") && (
                <li
                  className={
                    activeNavItem === "Agronomy"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <PiTreePalmBold className="nav-link-title" /> */}
                    <span id="k-Agronomy" className="nav-link-title ms-1">
                      <FcPortraitMode className="fs-2 me-1" />
                      Agronomy
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-Agronomy").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Agronomy Wiki") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/agronomy-plants-wiki"
                          >
                            <FcWikipedia className="fs-2 me-1" />
                            Agronomy Wiki
                          </NavLink>
                        )}

                        {navBar.includes("Farm Support") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/agronomy-farm-support"
                          >
                            <FcSms className="fs-2 me-1" />
                            Farm Support
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {navBar.includes("Resources") && (
                <li
                  className={
                    activeNavItem === "Resources"
                      ? "nav-item active dropdown"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-base"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    role="button"
                    aria-expanded="false"
                  >
                    {/* <MdOutlineInventory className="nav-link-title" /> */}
                    <span id="k-Resources" className="nav-link-title ms-1">
                      <FcDeployment className="fs-2 me-1" />
                      Resources
                    </span>
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-columns">
                      <div
                        className="dropdown-menu-column"
                        onClick={() => {
                          document.getElementById("k-Resources").click();
                          closeNavbar();
                        }}
                      >
                        {navBar.includes("Assets") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/resources-assets"
                          >
                            <FcPaid className="fs-2 me-1" />
                            Assets
                          </NavLink>
                        )}
                        {navBar.includes("Inventory") && (
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            to="/resources-inventory"
                          >
                            <FcPackage className="fs-2 me-1" />
                            Inventory
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* <Scrollbar /> */}
    </header>
  );
};

export default HeaderNavBar;
