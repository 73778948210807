import React from "react";
import { useNavigate } from "react-router-dom";

import { BsArrowRightShort } from "react-icons/bs";

const tableHead = [
  "Sr.",
  "Farm",
  "Water cycle name",
  "Type of system",
  "Water source",
  "Actions",
];

const WaterManagementTable = ({ data = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/farm-water-management/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.farmName || "N/A"}</td>
                <td>{item?.cycleName || "N/A"}</td>
                <td>{item?.typeOfSystem || "N/A"}</td>
                <td>{item?.waterSource || "N/A"}</td>
                <td>
                  <button
                    className="btn btn-primary btn-sm px-3"
                    onClick={() => goToView(item?.id)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WaterManagementTable;
