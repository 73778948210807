import React from "react";
import { useNavigate } from "react-router-dom";

const tableHead = [
  "Sr.",
  "Name",
  "Contact Number",
  "Enroll Date",
  "Generated By",
  "Actions",
];

const FarmTrainingTable = ({ data, currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/farm-training/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.name || "N/A"}</td>
                <td>{item?.contactNumber || "N/A"}</td>
                <td>{item?.enrollDate || "N/A"}</td>
                <td>{item?.generatedBy || "N/A"}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    style={{ width: "50px", height: "25px" }}
                    onClick={() => goToView(item?.id)}
                  >
                    view
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FarmTrainingTable;
