import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { AgronomyWikiInfo } from "../../../SchemaInfo/info";
import { useSelector } from "react-redux";
import { AgronomyWikiSchema } from "../../../Schemas/Agronomy Wiki/agronomywiki_schema";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import { uploadImage } from "../../../CustomComponent/upload_Image";

const getId = (str) => {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  return parseInt(lastPart, 10);
};

const AddAgronomyWiki = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handelSubmit = (values, actions) => {
    values = {
      ...values,
      generatedBy: userData?.Name,
    };

    const formData = new FormData();

    for (let key in values) {
      formData.append(key, values[key]);
    }

    // loading true
    setLoading(true);
    performRequest("POST", "/agronomyWiki/createAgronomyWiki/", formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Article created successfully!");
          navigate(`/agronomy-plants-wiki`);
        } else {
          Toast("error", "Error while Creating");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Agronomy Wiki Tracking</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Create new article</h2>
            </div>
            <Formik
              initialValues={AgronomyWikiInfo}
              validationSchema={AgronomyWikiSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Main Photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="image"
                          type="file"
                          onChange={(e) =>
                            props.setFieldValue("image", e.target.files[0])
                          }
                          // onChange={(e) =>
                          //   uploadImage(props, "image", e.target.files[0])
                          // }
                        />
                        {props.touched.image && props.errors.image && (
                          <p className="text-danger">{props.errors.image}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="name" type="text" />
                        {props.touched.name && props.errors.name && (
                          <p className="text-danger">{props.errors.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3 ">
                        Article Type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="articleType">
                          <option value="">Select</option>
                          <option value="vegetative">Vegetative</option>
                          <option value="fruiting">Fruiting</option>
                        </CustomSelect>
                        {props.touched.articleType &&
                          props.errors.articleType && (
                            <p className="text-danger">
                              {props.errors.articleType}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Classification
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="classification">
                          <option value="">Select</option>
                          <option value="vegetable">Vegetable</option>
                          <option value="medicinal">Medicinal</option>
                          <option value="non edible">Non Edible</option>
                          <option value="fruit">Fruit</option>
                          <option value="seed">Seed</option>
                        </CustomSelect>
                        {props.touched.classification &&
                          props.errors.classification && (
                            <p className="text-danger">
                              {props.errors.classification}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Scientific Name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="scientificName" type="text" />
                        {props.touched.scientificName &&
                          props.errors.scientificName && (
                            <p className="text-danger">
                              {props.errors.scientificName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Description
                      </label>
                      <div className="w-100 col">
                        <CustomTextArea name="description" type="text" />
                        {props.touched.description &&
                          props.errors.description && (
                            <p className="text-danger">
                              {props.errors.description}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">Seed URL</label>
                      <div className="w-100 col">
                        <CustomInput name="seedUrl" type="text" />
                        {props.touched.seedUrl && props.errors.seedUrl && (
                          <p className="text-danger">{props.errors.seedUrl}</p>
                        )}
                      </div>
                    </div>
                    {/* <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Days To Pipe Transfer
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="daysToPipeTransfer" type="text" />
                        {props.touched.daysToPipeTransfer &&
                          props.errors.daysToPipeTransfer && (
                            <p className="text-danger">
                              {props.errors.daysToPipeTransfer}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Nursery Water Interval Days
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="nurseryWaterIntervalDays"
                          type="text"
                        />
                        {props.touched.nurseryWaterIntervalDays &&
                          props.errors.nurseryWaterIntervalDays && (
                            <p className="text-danger">
                              {props.errors.nurseryWaterIntervalDays}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Nursery Fertilizer Interval Days
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="nurseryFertilizerIntervalDays"
                          type="text"
                        />
                        {props.touched.nurseryFertilizerIntervalDays &&
                          props.errors.nurseryFertilizerIntervalDays && (
                            <p className="text-danger">
                              {props.errors.nurseryFertilizerIntervalDays}
                            </p>
                          )}
                      </div>
                    </div> */}
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Min Growing Temperature
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="minGrowingTemperature" type="text" />
                        {props.touched.minGrowingTemperature &&
                          props.errors.minGrowingTemperature && (
                            <p className="text-danger">
                              {props.errors.minGrowingTemperature}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Max Growing Temperature
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="maxGrowingTemperature" type="text" />
                        {props.touched.maxGrowingTemperature &&
                          props.errors.maxGrowingTemperature && (
                            <p className="text-danger">
                              {props.errors.maxGrowingTemperature}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Min Growing Humidity
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="minGrowingHumidity" type="text" />
                        {props.touched.minGrowingHumidity &&
                          props.errors.minGrowingHumidity && (
                            <p className="text-danger">
                              {props.errors.minGrowingHumidity}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Max Growing Humidity
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="maxGrowingHumidity" type="text" />
                        {props.touched.maxGrowingHumidity &&
                          props.errors.maxGrowingHumidity && (
                            <p className="text-danger">
                              {props.errors.maxGrowingHumidity}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Minimum PH
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="minPh" type="text" />
                        {props.touched.minPh && props.errors.minPh && (
                          <p className="text-danger">{props.errors.minPh}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Maximum PH
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="maxPh" type="text" />
                        {props.touched.maxPh && props.errors.maxPh && (
                          <p className="text-danger">{props.errors.maxPh}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Minimum TDS
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="minTds" type="text" />
                        {props.touched.minTds && props.errors.minTds && (
                          <p className="text-danger">{props.errors.minTds}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Maximum TDS
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="maxTds" type="text" />
                        {props.touched.maxTds && props.errors.maxTds && (
                          <p className="text-danger">{props.errors.maxTds}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Germination Days
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="seedGerminationDays" type="text" />
                        {props.touched.seedGerminationDays &&
                          props.errors.seedGerminationDays && (
                            <p className="text-danger">
                              {props.errors.seedGerminationDays}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Two leaves days
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="twoLeavesDays" type="text" />
                        {props.touched.twoLeavesDays &&
                          props.errors.twoLeavesDays && (
                            <p className="text-danger">
                              {props.errors.twoLeavesDays}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create New Article" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddAgronomyWiki;
