import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { extractDate } from "../../../Components/Functions/date.fun";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useState } from "react";
import { current } from "@reduxjs/toolkit";

import { BsArrowRightShort } from "react-icons/bs";

const tableHead = [
  "Sr.",
  "Created At",
  "Raised By",
  "Related To",
  "Status",
  "Actions",
];

const FarmQueryTable = ({ data = [], currentPage, itemsPerPage }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let userData = useSelector((store) => store.login.user);
  const userId = userData.UserId;
  const userRole = userData.Role;

  const isAdmin = [
    process.env.REACT_APP_ROLE_ADMIN_S,
    process.env.REACT_APP_ROLE_ADMIN,
    process.env.REACT_APP_ROLE_OPS,
  ].includes(userRole);

  const filteredData = Array.isArray(data)
    ? isAdmin
      ? data
      : data.filter((item) => item.generatedById == userId)
    : [];

  const goToView = (id) => {
    navigate(`/agronomy-farm-support/view/${id}`);
  };

  const assignToMe = (id) => {
    setLoading(true);
    performRequest("PATCH", `/farmSupport/updateQuery/${id}`, {
      assign: userId,
      assignedName: userData.Name,
      status: "being address",
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Assigned successfully!");
          navigate(`/agronomy-farm-support/view/${id}`);
        } else {
          Toast("error", "Error while Creating");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            const isAssigned = item.assign;
            return (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{extractDate(item.createdAt) || "N/A"}</td>
                <td>{item.generatedBy || "N/A"}</td>
                <td>{item.relatedTo || "N/A"}</td>
                <td>{item.status || "N/A"}</td>
                <td>
                  <button
                    className="btn btn-primary btn-sm px-3 me-2"
                    onClick={() => goToView(item.id)}
                  >
                    <BsArrowRightShort className="me-1 fs-3" /> View
                  </button>

                  {isAdmin && (
                    <button
                      className="btn btn-primary btn-sm px-3"
                      onClick={() => assignToMe(item.id)}
                      disabled={isAssigned}
                    >
                      {isAssigned ? "Assigned" : "Assign to me"}
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FarmQueryTable;
