import React, { useEffect, useState } from "react";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import Footer from "../../Components/Footer/footer";
import { Form, Formik } from "formik";
import kryzenLogo from "../../certificates/kryzenLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../../redux/user/loginReducer";
import CustomInput from "../../CustomComponent/custom_input";
import { userLoginSchema } from "../../Schemas/user/user_schemas";
import { useNavigate } from "react-router-dom";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import { FcAdvertising } from "react-icons/fc";

const UserLogin = () => {
  const store = useSelector((store) => store.login);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const showPassword = () => {
    setShowPass(!showPass);
  };
  const handelSubmit = async (values, actions) => {
    setLoading(true);
    performRequest("POST", "/login/login", values)
      .then((res) => {
        if (res.status === 200 && res.data.msg === "Login successful") {
          Toast("success", "Login successful");
          dispatch(loginSuccess(res.data));
        } else if (res.data.msg === "User not found. Please signup.") {
          Toast("error", "Please Check your email and try again");
        } else {
          Toast("error", "wrong credentials");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (store.isLoggedIn && store.userlog && store.userlog.firstLogin) {
      navigate("/");
    } else if (
      store.isLoggedIn &&
      (store?.user?.Role === process.env.REACT_APP_ROLE_CLIENT ||
        store?.user?.Role === process.env.REACT_APP_ROLE_D_CLIENT ||
        store?.user?.Role === process.env.REACT_APP_ROLE_SITE_INSPECTION)
    ) {
      navigate("/");
    } else if (
      store.isLoggedIn &&
      store.user &&
      store.user.Role == process.env.REACT_APP_ROLE_ADMIN_S
    ) {
      navigate("/");
    } else if (
      store.isLoggedIn &&
      store.user &&
      (store.user.Role == process.env.REACT_APP_ROLE_ADMIN ||
        store.user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
        store.user.Role == process.env.REACT_APP_ROLE_SALLE ||
        store.user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
        store.user.Role == process.env.REACT_APP_ROLE_OPS ||
        store.user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
        store.user.Role == process.env.REACT_APP_ROLE_MARKETING ||
        store.user.Role == process.env.REACT_APP_ROLE_IT ||
        store.user.Role == process.env.REACT_APP_ROLE_SSM ||
        store.user.Role == process.env.REACT_APP_ROLE_ACCOUNTS ||
        store.user.Role == process.env.REACT_APP_ROLE_SALES_MANAGER ||
        store.user.Role == process.env.REACT_APP_ROLE_CLIENT_FEEDBACK)
    ) {
      navigate("/attendance");
    } else if (
      store.isLoggedIn &&
      store?.user &&
      store?.user?.Role === process.env.REACT_APP_ROLE_INTERNAL
    ) {
      navigate("/i-dashboard");
    } else if (store.isLoggedIn) {
      navigate("/");
    }
  }, [store]);

  // useEffect(() => {
  //   if (store.isLoggedIn) {
  //     if (
  //       store?.user?.Role === process.env.REACT_APP_ROLE_CLIENT ||
  //       store?.user?.Role === process.env.REACT_APP_ROLE_D_CLIENT
  //     ) {
  //       navigate("/farm-construction");
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, [store, navigate]);
  // if (store.isLoggedIn) {
  //   if (
  //     store?.user?.Role === process.env.REACT_APP_ROLE_CLIENT ||
  //     store?.user?.Role === process.env.REACT_APP_ROLE_D_CLIENT
  //   ) {
  //     return <Navigate to="/" />;
  //   }
  //   return <Navigate to="/" />;
  // }
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page page-center">
      <div className="container container-tight py-4">
        <div className="text-center mt-4 mb-4">
          <span>
            {/* <img
                className="navbar-brand-image"
                src="https://www.kryzen.com/wp-content/uploads/2022/02/kryzen-logo-1.png"
                alt="Kryzen"
                style={{
                  width: "110px",
                  height: "50px",
                }}
              /> */}
            <h1 className="fs-large m-0 p-0">
              <span className="text-primary">α</span>lpha
            </h1>
            <p className="fs-5 ps-6 m-0 text-success">by KRYZEN</p>
          </span>
        </div>
        <div className="card card-md">
          <div className="card-body">
            <h2 className="text-center mb-1">Welcome to Kryzen Biotech.</h2>
            <p className="mb-3 text-center">
              Let's revamp the future of farming together!
            </p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={userLoginSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form id="k-user-login-form">
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <CustomInput
                      type="email"
                      name="email"
                      placeholder="your@email.com"
                      autoComplete="off"
                    />

                    {props.touched.email && props.errors.email && (
                      <p className="text-danger">{props.errors.email}</p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Password</label>
                    <div className="input-group input-group-flat">
                      <CustomInput
                        name="password"
                        autoComplete="off"
                        placeholder="Enter password"
                        type={showPass ? "text" : "password"}
                      />

                      <span className="input-group-text">
                        <span
                          onClick={showPassword}
                          className="link-secondary"
                          title="Show password"
                          role="button"
                          data-bs-toggle="tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                            <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    {props.touched.password && props.errors.password && (
                      <p className="text-danger">{props.errors.password}</p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label className="form-check">
                      <input type="checkbox" className="form-check-input" />
                      <span className="form-check-label">Remember me</span>
                    </label>
                  </div>
                  <div className="form-footer">
                    <FormSubmitButton buttonText={"Sign in"} width={"full"} />
                  </div>
                  <div className="my-3 mx-2">
                    <span>
                      <a href="/forgot-password">Forgot your password?</a>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div class="hr-text">or</div>
          <div className="card-body">
            <p className="p-0 m-0">
              {" "}
              <FcAdvertising /> New to Kryzen Client Portal?
            </p>
            <h3 className="p-0 m-0">Contact 9870-424-425 / SALES@KRYZEN.COM</h3>
          </div>
        </div>
        <p className="p-0 my-1 text-center">
          &copy; 2019-2024 Kryzen Biotech Private Limited.
        </p>
      </div>
    </div>
  );
};

export default UserLogin;
