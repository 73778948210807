import React, { useEffect, useState } from "react";
import {
  convertRupeesToWords,
  roundOffCost,
} from "../../../Components/Functions/amt.fun";

const QuotationAmounts = ({ allQData }) => {
  const [cost, setCost] = useState(0);
  const [unitcost, setUnitCost] = useState(0);
  const [gstcost, setGstCost] = useState(0);
  const [amtinwords, setAMTInWords] = useState("");

  const [showScope, setShowScope] = useState(false);
  const [hideScope, setHideScope] = useState("");

  const getQuotationFor = () => {
    let bag = "";

    if (allQData?.input_info?.quotationType == "fanpad") {
      bag += "FANPAD";
    } else if (allQData?.input_info?.quotationType == "nvph") {
      bag += "NVPH";
    } else if (allQData?.input_info?.quotationType == "nethouse") {
      bag += "NETHOUSE";
    } else if (
      allQData?.input_info?.quotationType == "fanpad with hydroponics"
    ) {
      bag += "FANPAD";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += `+ ${allQData?.input_info?.a_FramePercent}% A-FRAME`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += `+ ${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += `+ ${allQData?.input_info?.growBagPercent}% GROW-BAG`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += `+ ${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
      }
      bag += ` ${next}`;
    } else if (
      allQData?.input_info?.quotationType == "nethouse with hydroponics"
    ) {
      bag += "NETHOUSE";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
      }
      bag += ` ${next}`;
    } else if (allQData?.input_info?.quotationType == "nvph with hydroponics") {
      bag += "NVPH";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
      }
      bag += ` ${next}`;
    } else if (allQData?.input_info?.quotationType == "hydroponics only") {
      bag += "WITHOUT-CEA";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
      }
      bag += ` ${next}`;
    }

    return bag;
  };

  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "-2px",
    fontSize: "13px",
    borderCollapse: "collapse",
    padding: "0px 3px",
    paddingTop: "-2px",
  };
  useEffect(() => {
    // "fanpad",
    // "nvph",
    // "nethouse",
    // "fanpad with hydroponics",
    // "nvph with hydroponics",
    // "nethouse with hydroponics",
    // "hydroponics only",
    // "hydrobuild xs",
    // "hydrobuild mini",
    // "hydrobuild pro",
    let total = 0;
    if (allQData?.input_info?.overrideUnitCost > 0) {
      total += allQData?.input_info?.overrideUnitCost;
    } else {
      total = allQData?.quotationMatrixCost?.total_cost;
    }

    setCost(total);
    if (allQData?.input_info?.maxDiscount > 0) {
      let x = total + total * (allQData?.input_info?.maxDiscount / 100);
      setUnitCost(x / allQData?.area_in?.area_in_sqmeter);
    } else {
      setUnitCost(total / allQData?.area_in?.area_in_sqmeter);
    }
    setGstCost((total * 18) / 100);
    setAMTInWords(convertRupeesToWords(total + total * 0.18));
  }, [allQData]);

  useEffect(() => {
    if (allQData?.input_info?.quotationType === "hydroponics only") {
      setHideScope("CEA");
    }
    if (
      allQData?.input_info?.quotationType === "nvph" ||
      allQData?.input_info?.quotationType === "fanpad" ||
      allQData?.input_info?.quotationType === "nethouse"
    ) {
      setHideScope("Hydroponic");
    }
    if (
      allQData?.input_info?.growBagPercent > 0 ||
      allQData?.input_info?.dutchBucketPercent > 0
    ) {
      setShowScope(true);
    }
  }, [allQData]);
  let serial = 1;
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 0,
          padding: 0,
          marginBottom: "-10px",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        <div>
          <p>
            Business / Person name:
            {allQData?.input_info?.businessName ||
              allQData?.input_info?.contactPersonName}
            <br />
            Contact Person: {allQData?.input_info?.contactPersonName} <br />
            GSTIN: {allQData?.input_info?.gstNumber || "N/A"}
          </p>
        </div>
        <div>
          <p>
            {/* Quotation for: {getQuotationFor()} <br /> */}
            Quotation number: <b>{allQData?.quotationNumber}</b> <br />
            Date: {allQData?.input_info?.quotationDate} <br />
            Address: {allQData?.input_info?.address} <br />
            {/* Country of supply: India <br /> */}
          </p>
        </div>
      </div>

      <h3 style={{ textAlign: "center", margin: 0 }}>
        Quotation: Material + Installation
      </h3>
      <p style={{ textAlign: "center" }}>
        As Per “Development of Commercial Horticulture” of National Horticulture
        Board (NHB)
      </p>

      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "40%" }}>Particulars</th>
            <th style={{ width: "10%" }}>Size/Unit</th>
            <th style={{ width: "15%" }}>Rates</th>
            <th style={{ width: "5%" }}>Disc</th>
            <th style={{ width: "10%" }}>Revised</th>
            <th style={{ width: "15%" }}>Amount</th>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td> {getQuotationFor()}</td>

            <td>{allQData?.area_in?.area_in_sqmeter} SqM</td>
            <td>{roundOffCost(unitcost + (unitcost * 10) / 100)}</td>
            <td>10%</td>
            <td>{roundOffCost(unitcost)}</td>
            {allQData?.input_info?.maxDiscount > 0 && (
              <td>
                {" "}
                ₹
                {roundOffCost(
                  cost + cost * (allQData?.input_info?.maxDiscount / 100)
                )}
              </td>
            )}
            {allQData?.input_info?.maxDiscount == 0 && (
              <td> {roundOffCost(cost)}</td>
            )}
          </tr>

          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Retractable aluminium net</td>

              <td>{allQData?.area_in?.area_in_sqmeter} SqM</td>
              <td>{roundOffCost((unitcost * 1.1) / 100)}</td>
              <td>100%</td>
              <td>₹0.00</td>
              <td>₹0.00</td>
            </tr>
          )}
          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Ground cover weedmat</td>

              <td>{allQData?.area_in?.area_in_sqmeter} SqM</td>
              <td>{roundOffCost((unitcost * 1.2) / 100)}</td>
              <td>100%</td>
              <td>₹0.00</td>
              <td>₹0.00</td>
            </tr>
          )}
          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Secondary insect net</td>

              <td>{allQData?.area_in?.area_in_sqmeter} SqM</td>
              <td>{roundOffCost((unitcost * 0.9) / 100)} </td>
              <td>100%</td>
              <td>₹0.00</td>
              <td>₹0.00</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && showScope && (
            <tr style={style}>
              <td style={style}>{serial++}.</td>
              <td style={style}>Trellising (Growbag/Dutch Bucket)</td>

              <td style={style}>{allQData?.area_in?.area_in_sqmeter} SqM</td>
              <td style={style}>{roundOffCost((unitcost * 1.5) / 100)}</td>
              <td style={style}>100%</td>
              <td style={style}>₹0.00</td>
              <td style={style}>₹0.00</td>
            </tr>
          )}
          <tr>
            <td>{serial++}.</td>
            <td>Erection & commissioning</td>

            <td>{allQData?.area_in?.area_in_sqmeter} SqM</td>
            <td>{roundOffCost((unitcost * 8.5) / 100)}</td>
            <td>100%</td>
            <td>₹0.00</td>
            <td>₹0.00</td>
          </tr>
          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Timer controlled foggers</td>

              <td>1 Unit</td>
              <td>₹2500.00</td>
              <td>100%</td>
              <td>₹0.00</td>
              <td>₹0.00</td>
            </tr>
          )}
          {hideScope !== "Hydroponic" && (
            <tr>
              <td>{serial++}.</td>
              <td>Ultra violet light disinfection </td>

              <td>1 Unit</td>
              <td>₹12000.00</td>
              <td>100%</td>
              <td>₹0.00</td>
              <td>₹0.00</td>
            </tr>
          )}
          {/* <tr>
            <td>{serial++}.</td>
            <td>Electric control panel</td>

            <td>1 Unit</td>
            <td>₹9400.00</td>
            <td>100%</td>
            <td>₹0.00</td>
            <td>₹0.00</td>
          </tr> */}
          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Decontamination cabin</td>

              <td>1 Unit</td>
              <td>₹24000.00</td>
              <td>100%</td>
              <td>₹0.00</td>
              <td>₹0.00</td>
            </tr>
          )}
          {hideScope !== "CEA" && (
            <tr>
              <td>{serial++}.</td>
              <td>Mechanical geared vent opener</td>

              <td>4 Units</td>
              <td>₹2800.00</td>
              <td>100%</td>
              <td>₹0.00</td>
              <td>₹0.00</td>
            </tr>
          )}
          <tr>
            <td>{serial++}.</td>
            <td>
              <b>Offer 1: Virtual agronomy (6 Month)</b>
            </td>

            <td>{allQData?.area_in?.area_in_sqmeter} SqM</td>
            <td>₹60000.00</td>
            <td>100%</td>
            <td>₹0.00</td>
            <td>₹0.00</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>
              <b>Offer 2: Buyers connect </b>
            </td>

            <td>1 User</td>
            <td>₹799.00</td>
            <td>100%</td>
            <td>₹0.00</td>
            <td>₹0.00</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>
              <b>Offer 3: Masterclass login</b>
            </td>

            <td>3 Users</td>
            <td>₹2499.00</td>
            <td>100%</td>
            <td>₹0.00</td>
            <td>₹0.00</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>
              <b>Offer 4: NHB/NHM DPR</b>
            </td>

            <td>1 File</td>
            <td>₹3500.00</td>
            <td>100%</td>
            <td>₹0.00</td>
            <td>₹0.00</td>
          </tr>

          {/* <% end %> */}
        </tbody>
      </table>
      <table style={{ width: "100%", border: "none" }}>
        <tbody>
          <tr>
            <td style={{ border: "none", width: "70%" }}>
              <div style={{ paddingTop: "3px" }}>
                <b>
                  SAVE EXTRA FLAT ₹71796.00 BY AVAILING WITH LIMITED TIME
                  OFFERS(Already applied).
                </b>
                <br />
                {amtinwords && (
                  <div
                    style={{
                      paddingBottom: "8px",
                      margin: "auto",
                      marginTop: "opx",
                    }}
                  >
                    In Words: <b> {amtinwords}</b>
                  </div>
                )}
              </div>

              {/* <% if record.currency.inr? %>
          In Words:
          <b><%= record.currency.format_value_in_words(record.calculator.total_amount_without_buyback_inc_gst) %></b>
        <% end %> */}
            </td>
            <td
              style={{
                border: "none",
                width: "30%",
                paddingRight: "0px",
                paddingLeft: "0px",
              }}
            >
              <table style={{ width: "100%" }}>
                <tbody>
                  {allQData?.input_info?.maxDiscount > 0 && (
                    <>
                      {" "}
                      <tr>
                        <td style={{ width: "50%" }}>Amount</td>
                        <td style={{ width: "50%" }}>
                          ₹
                          {roundOffCost(
                            cost +
                              cost * (allQData?.input_info?.maxDiscount / 100)
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>Sales Discount</td>
                        <td style={{ width: "50%" }}>
                          {roundOffCost(allQData?.input_info?.maxDiscount)}%
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td style={{ width: "50%" }}>Disc. Amount</td>
                    <td style={{ width: "50%" }}>{roundOffCost(cost)}</td>
                  </tr>
                  <tr>
                    <td>GST 18%</td>
                    <td>{roundOffCost(gstcost)}</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>{roundOffCost(cost + gstcost)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default QuotationAmounts;
