import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { extractDate } from "../../../Components/Functions/date.fun";
import { current } from "@reduxjs/toolkit";
const tableHead = [
  "Sr.",
  "Lead Generate Date",
  "full name",
  "contact number",
  "lead owner",

  "interested In",
  "lead status",
  "Actions",
];

// function getTodayDate() {
//   const today = new Date();
//   const year = today.getFullYear();
//   let month = today.getMonth() + 1;
//   month = month < 10 ? "0" + month : month;
//   let day = today.getDate();
//   day = day < 10 ? "0" + day : day;
//   return `${year}-${month}-${day}`;
// }
// function getTomorrowDate() {
//   const today = new Date();
//   const tomorrow = new Date(today);
//   tomorrow.setDate(tomorrow.getDate() + 1);
//   const year = tomorrow.getFullYear();
//   let month = tomorrow.getMonth() + 1;
//   month = month < 10 ? "0" + month : month;
//   let day = tomorrow.getDate();
//   day = day < 10 ? "0" + day : day;
//   return `${year}-${month}-${day}`;
// }

// const getFollowUpDate = (followUp) => {
//   if (followUp.length > 0) {
//     const today = getTodayDate();
//     const tomorrow = getTomorrowDate();
//     for (let obj of followUp) {
//       if (obj.followUpDate === today) {
//         return "Today";
//       }
//       if (obj.followUpDate === tomorrow) {
//         return "Tomorrow";
//       }
//     }
//     return followUp[followUp.length - 1].followUpDate;
//   } else {
//     return "N/A";
//   }
// };
const getInterest = (item) => {
  if (item?.interestedIn && item?.interestedIn.length >= 1) {
    return item?.interestedIn.join(", ");
  }
  if (!item?.lead_status) {
    return "Not Attended";
  }
  return "N/A";
};
const ColdLeadsTable = ({ leads = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/leads/view-lead/${id}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {leads?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{extractDate(item?.createdAt) || "N/A"}</td>
                <td style={{ maxWidth: "200px" }}>
                  <span
                    className="cursor-pointer text-blue text-decoration-underline"
                    onClick={() => goToView(item.id)}
                  >
                    {item?.full_name || "N/A"}
                  </span>
                </td>
                <td>{item?.phone_number || "N/A"}</td>
                <td>{item?.lead_alloted_to || "N/A"}</td>

                <td style={{ maxWidth: "200px" }}>{getInterest(item)}</td>
                <td>{item?.lead_status || "N/A"}</td>

                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ColdLeadsTable;
