import React, { useEffect, useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form, FieldArray } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { DeliveryLineItemInfo } from "../../../SchemaInfo/info";
import { DeliveryChallanSchema } from "../../../Schemas/purchase/delivery_challan.schema";
import { getId } from "../../../Components/Functions/word.fun";

const EditDeliveyChallan = () => {
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [DeliveryChallanInfo, setDeliveryChallanInfo] = useState();
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);
  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest("PATCH", `/deliveryChallan/updateDc/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "DC Updated successfully!");
          navigate(`/delivery-challan/view/${id}`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/deliveryChallan/getDcById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setDeliveryChallanInfo(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  const checkTouched = (obj) => {
    if (obj.itemQuantity == "" && obj.itemUnit == "" && obj.particulars == "") {
      return false;
    } else {
      return true;
    }
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Delivery Challan</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Edit Delivery Challan</h2>
            </div>
            <Formik
              initialValues={DeliveryChallanInfo}
              validationSchema={DeliveryChallanSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Project
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="project">
                          <option value="">Select</option>
                          {projects &&
                            projects?.map((pro) => {
                              return (
                                <option
                                  key={pro.cpNumber}
                                  value={getId(pro.cpNumber)}
                                >
                                  {pro.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>

                        {props.touched.project && props.errors.project && (
                          <p className="text-danger">{props.errors.project}</p>
                        )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Country of supply
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="countryOfSupply">
                          <option value="">Select Country</option>
                          <option value="india">India</option>
                        </CustomSelect>

                        {props.touched.countryOfSupply &&
                          props.errors.countryOfSupply && (
                            <p className="text-danger">
                              {props.errors.countryOfSupply}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Place of supply
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="placeOfSupply" />

                        {props.touched.placeOfSupply &&
                          props.errors.placeOfSupply && (
                            <p className="text-danger">
                              {props.errors.placeOfSupply}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Challan date
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="date" name="challanDate" />

                        {props.touched.challanDate &&
                          props.errors.challanDate && (
                            <p className="text-danger">
                              {props.errors.challanDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Lr number</label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="lrNumber" />

                        {props.touched.lrNumber && props.errors.lrNumber && (
                          <p className="text-danger">{props.errors.lrNumber}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Eway bill number
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="ewayBillNumber" />

                        {props.touched.ewayBillNumber &&
                          props.errors.ewayBillNumber && (
                            <p className="text-danger">
                              {props.errors.ewayBillNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Transport name
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="transportName" />

                        {props.touched.transportName &&
                          props.errors.transportName && (
                            <p className="text-danger">
                              {props.errors.transportName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Vehicle number
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="vehicleNumber" />

                        {props.touched.vehicleNumber &&
                          props.errors.vehicleNumber && (
                            <p className="text-danger">
                              {props.errors.vehicleNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Driver name
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="driverName" />

                        {props.touched.driverName &&
                          props.errors.driverName && (
                            <p className="text-danger">
                              {props.errors.driverName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Driver phone number
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="driverPhoneNumber" />

                        {props.touched.driverPhoneNumber &&
                          props.errors.driverPhoneNumber && (
                            <p className="text-danger">
                              {props.errors.driverPhoneNumber}
                            </p>
                          )}
                      </div>
                    </div>

                    <hr />
                    <h3>Line Items :</h3>
                    <hr />
                    <FieldArray name="lineItems">
                      {({ push, remove }) => (
                        <div>
                          {props.values?.lineItems?.map((lineItem, index) => (
                            <div key={index}>
                              <div className="row g-3 align-items-center">
                                <div className="col-md">
                                  <label className=" form-label">
                                    Particulars:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type="text"
                                      name={`lineItems[${index}].particulars`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Item units
                                  </label>

                                  <div className="w-100 col">
                                    <CustomSelect
                                      type="text"
                                      name={`lineItems[${index}].itemUnit`}
                                    >
                                      <option value="">Select</option>
                                      <option value="nos">Nos</option>
                                      <option value="kg">Kg</option>
                                      <option value="grams">Grams</option>
                                      <option value="ltr">Litter</option>
                                      <option value="ml">ML</option>
                                      <option value="packs">Packs</option>
                                      <option value="sqm">SqM</option>
                                      <option value="sqft">SqFt</option>
                                    </CustomSelect>
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Item quantity
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type={"text"}
                                      name={`lineItems[${index}].itemQuantity`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <button
                                    className="col-3 btn btn-primary mt-4"
                                    style={{
                                      width: "70px",
                                      height: "30px",
                                    }}
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>

                              <hr />
                            </div>
                          ))}

                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              if (props.values.lineItems.length == 0) {
                                push(DeliveryLineItemInfo);
                              } else if (
                                props.values.lineItems.length > 0 &&
                                checkTouched(
                                  props.values.lineItems[
                                    props.values.lineItems.length - 1
                                  ]
                                )
                              ) {
                                push(DeliveryLineItemInfo);
                              } else {
                                Toast(
                                  "error",
                                  "Please fill at least one line item field."
                                );
                              }
                            }}
                          >
                            Add Line Item
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Delivery Challan" />

                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default EditDeliveyChallan;
