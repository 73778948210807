import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { extractDate } from "../../../Components/Functions/date.fun";

const ViewClientProject = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/clientProject/getCpById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Lead!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">View Client Project</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">
                {(data?.cpNumber || "N/A").toUpperCase()}
              </h2>
            </div>

            <div className="card-body row">
              <div className="col-md border-end">
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Name</label>
                    <div className="w-100 col">
                      {(data?.clientName || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Email</label>
                    <div className="w-100 col">
                      {(data?.clientEmail || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Client Phone number
                    </label>
                    <div className="w-100 col">
                      <div className="w-100 col">
                        {data?.clientContactNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Business name</label>
                    <div className="w-100 col">
                      {(data?.businessName || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Business type</label>
                    <div className="w-100 col">
                      {(data?.businessType || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Business gst number
                    </label>
                    <div className="w-100 col">
                      {data?.businessGstNumber || "N/A"}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Project date</label>
                    <div className="w-100 col">
                      {(data?.createdAt && extractDate(data?.createdAt)) ||
                        "N/A"}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md">
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Aadhar card number
                    </label>
                    <div className="w-100 col">
                      {(data?.aadharcardNumber || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Billing address
                    </label>
                    <div className="w-100 col">
                      {(`${data?.billingAddress}` || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}

                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Primary farm address
                    </label>
                    <div className="w-100 col">
                      {(data?.farmAddress || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Pan number</label>
                    <div className="w-100 col">
                      {(data?.panNumber || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Generated by</label>
                    <div className="w-100 col">
                      {(data?.generatedBy || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">
                      Progress Status
                    </label>

                    {
                      <div className="w-100 col">
                        {(
                          (data?.progressStatus &&
                            (data?.progressStatus).join(", ")) ||
                          "N/A"
                        ).toUpperCase()}
                      </div>
                    }
                  </div>
                )}
                {data && (
                  <div className="mb-3 row">
                    <label className="w-25 form-label col">Notes</label>
                    <div className="w-100 col">
                      {(data?.notes || "N/A").toUpperCase()}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText="Edit Client Project"
                  href={`/client-projects/edit/${id}`}
                />

                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewClientProject;
