import React from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";

const tableHead = [
  "Sr.",
  "Plant",
  "Plant Name",
  "Wholesale Price Low",
  "Wholesale Price High",
  "Retail Price Low",
  "Retail Price High",
  "Actions",
];

const PriceWatcherTable = ({
  data = [],
  agro = [],
  currentPage,
  itemsPerPage,
}) => {
  const navigate = useNavigate();

  function calculateDiscountedPrice(originalPrice, discount) {
    const discountedPrice = originalPrice * (1 - discount);
    return discountedPrice;
  }

  const goToView = (id) => {
    navigate(`/buyers-price-watcher/view/${id}`);
  };

  const getImageUrl = (plantId) => {
    const agroItem = agro.find((item) => item.id === plantId);
    return agroItem ? agroItem.image : null;
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>
                  {getImageUrl(item?.plantId) ? (
                    <img
                      src={getImageUrl(item?.plantId)}
                      alt="Plant"
                      width="30"
                    />
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>{item?.plantName || "N/A"}</td>
                <td>
                  ₹
                  {calculateDiscountedPrice(item?.weeklyPrice, 0.35)?.toFixed(
                    2
                  ) || "N/A"}
                </td>
                <td>
                  ₹
                  {calculateDiscountedPrice(item?.weeklyPrice, 0.15)?.toFixed(
                    2
                  ) || "N/A"}
                </td>
                <td>
                  ₹
                  {calculateDiscountedPrice(item?.weeklyPrice, 0.05)?.toFixed(
                    2
                  ) || "N/A"}
                </td>
                <td>₹{item?.weeklyPrice || "N/A"}</td>
                <td>
                  <button
                    className="btn btn-sm px-3 btn-primary"
                    onClick={() => goToView(item?.id)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PriceWatcherTable;
