import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useFormik } from "formik";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { BuyersManagementInfo } from "../../../SchemaInfo/info";
import { useSelector } from "react-redux";
import { BuyersCreateSchema } from "../../../Schemas/buyers/buyers_create_schema";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { FcInTransit } from "react-icons/fc";

const AddBuyer = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const navigate = useNavigate();

  const handelSubmit = (values) => {
    values = {
      ...values,
      generatedBy: userData?.Name,
    };
    const formData = new FormData();
    for (let key in values) {
      formData.append(key, values[key]);
    }
    performRequest("POST", "/buyerProfiles/createbuyerProfiles/", formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Buyer created successfully!");
          navigate(`/buyers-management`);
        } else {
          Toast("error", "Error while Creating");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  const indianStates = [
    "andhra pradesh",
    "arunachal pradesh",
    "assam",
    "bihar",
    "chhattisgarh",
    "goa",
    "gujarat",
    "haryana",
    "himachal pradesh",
    "jharkhand",
    "karnataka",
    "kerala",
    "madhya pradesh",
    "maharashtra",
    "manipur",
    "meghalaya",
    "mizoram",
    "nagaland",
    "odisha",
    "punjab",
    "rajasthan",
    "sikkim",
    "tamil nadu",
    "telangana",
    "tripura",
    "uttar pradesh",
    "uttarakhand",
    "west bengal",
    "andaman and nicobar islands",
    "chandigarh",
    "dadra and dagar daveli",
    "daman and diu",
    "lakshadweep",
    "delhi",
    "puducherry",
    "ladakh",
    "jammu and kashmir",
  ];

  const handleChange = (event) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      setShowInputs(true);
    } else {
      setShowInputs(false);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcInTransit className="fs-2  me-1" /> Buyers Details
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Add new buyer to portal.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>
          <div className="card">
            <Formik
              initialValues={BuyersManagementInfo}
              validationSchema={BuyersCreateSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">Photo</label>
                      <div className="w-100 col">
                        <CustomInput
                          name="photo"
                          type="file"
                          onChange={(e) =>
                            props.setFieldValue("photo", e.target.files[0])
                          }
                        />
                        {props.touched.photo && props.errors.photo && (
                          <p className="text-danger">{props.errors.photo}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="name" type="text" />
                        {props.touched.name && props.errors.name && (
                          <p className="text-danger">{props.errors.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">Email</label>
                      <div className="w-100 col">
                        <CustomInput name="email" type="text" />
                        {props.touched.email && props.errors.email && (
                          <p className="text-danger">{props.errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Phone number 1
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="phoneNumber1" type="text" />
                        {props.touched.phoneNumber1 &&
                          props.errors.phoneNumber1 && (
                            <p className="text-danger">
                              {props.errors.phoneNumber1}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Phone number 2
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="phoneNumber2" type="text" />
                        {props.touched.phoneNumber2 &&
                          props.errors.phoneNumber2 && (
                            <p className="text-danger">
                              {props.errors.phoneNumber2}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Address line 1
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="addressLine1" type="text" />
                        {props.touched.addressLine1 &&
                          props.errors.addressLine1 && (
                            <p className="text-danger">
                              {props.errors.addressLine1}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">Market</label>
                      <div className="w-100 col">
                        <CustomInput name="market" type="text" />
                        {props.touched.market && props.errors.market && (
                          <p className="text-danger">{props.errors.market}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">State</label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="addressState"
                          className="form-control"
                        >
                          <option value="">Select State</option>
                          {indianStates.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </CustomSelect>
                        {props.touched.addressState &&
                          props.errors.addressState && (
                            <p className="text-danger">
                              {props.errors.addressState}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">Pincode</label>
                      <div className="w-100 col">
                        <CustomInput name="addressPincode" type="text" />
                        {props.touched.addressPincode &&
                          props.errors.addressPincode && (
                            <p className="text-danger">
                              {props.errors.addressPincode}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Fassai Number
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="fssaiNumber" type="text" />
                        {props.touched.fssaiNumber &&
                          props.errors.fssaiNumber && (
                            <p className="text-danger">
                              {props.errors.fssaiNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Description
                      </label>
                      <div className="w-100 col">
                        <CustomTextArea name="description" type="text" />
                        {props.touched.description &&
                          props.errors.description && (
                            <p className="text-danger">
                              {props.errors.description}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        year established
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="yearEstablished" type="text" />
                        {props.touched.yearEstablished &&
                          props.errors.yearEstablished && (
                            <p className="text-danger">
                              {props.errors.yearEstablished}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Is Exporter
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="isExporter">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.isExporter &&
                          props.errors.isExporter && (
                            <p className="text-danger">
                              {props.errors.isExporter}
                            </p>
                          )}
                      </div>
                    </div>
                    {/* varifiedBadge */}
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Is Verified Badge
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="varifiedBadge">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.varifiedBadge &&
                          props.errors.varifiedBadge && (
                            <p className="text-danger">
                              {props.errors.varifiedBadge}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Is wholeseller
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="isWholesaler">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.isWholesaler &&
                          props.errors.isWholesaler && (
                            <p className="text-danger">
                              {props.errors.isWholesaler}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Is Retailer
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="isRetailer">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.isRetailer &&
                          props.errors.isRetailer && (
                            <p className="text-danger">
                              {props.errors.isRetailer}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Is Trader
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="isTrader">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.isTrader && props.errors.isTrader && (
                          <p className="text-danger">{props.errors.isTrader}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Is food processor
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="isFoodProcessor">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.isFoodProcessor &&
                          props.errors.isFoodProcessor && (
                            <p className="text-danger">
                              {props.errors.isFoodProcessor}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Deal perisheble vegetables
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          type="text"
                          name="dealPerishableVegetables"
                        >
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.dealPerishableVegetables &&
                          props.errors.dealPerishableVegetables && (
                            <p className="text-danger">
                              {props.errors.dealPerishableVegetables}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Deal exotic vegetables
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="dealExoticVegetables">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.dealExoticVegetables &&
                          props.errors.dealExoticVegetables && (
                            <p className="text-danger">
                              {props.errors.dealExoticVegetables}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Deal fruits and berries
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="dealFruitsAndBerries">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.dealFruitsAndBerries &&
                          props.errors.dealFruitsAndBerries && (
                            <p className="text-danger">
                              {props.errors.dealFruitsAndBerries}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Deal dry fruits
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="dealDryFruits">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.dealDryFruits &&
                          props.errors.dealDryFruits && (
                            <p className="text-danger">
                              {props.errors.dealDryFruits}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Deal grains
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="dealGrains">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.dealGrains &&
                          props.errors.dealGrains && (
                            <p className="text-danger">
                              {props.errors.dealGrains}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Buyers rating
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="buyerRating" type="text" />
                        {props.touched.buyerRating &&
                          props.errors.buyerRating && (
                            <p className="text-danger">
                              {props.errors.buyerRating}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Google map link
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="googleMapLink" type="text" />
                        {props.touched.googleMapLink &&
                          props.errors.googleMapLink && (
                            <p className="text-danger">
                              {props.errors.googleMapLink}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Credit score
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="creditScore" type="text" />
                        {props.touched.creditScore &&
                          props.errors.creditScore && (
                            <p className="text-danger">
                              {props.errors.creditScore}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Delivery road
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="deliveryRoad">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.deliveryRoad &&
                          props.errors.deliveryRoad && (
                            <p className="text-danger">
                              {props.errors.deliveryRoad}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Delivery rail
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="deliveryRail">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.deliveryRail &&
                          props.errors.deliveryRail && (
                            <p className="text-danger">
                              {props.errors.deliveryRail}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Delivery sea
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="deliverySea">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.deliverySea &&
                          props.errors.deliverySea && (
                            <p className="text-danger">
                              {props.errors.deliverySea}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Delivery cargo
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="deliveryCargo">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.deliveryCargo &&
                          props.errors.deliveryCargo && (
                            <p className="text-danger">
                              {props.errors.deliveryCargo}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Open on monday
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="openOnMonday">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.openOnMonday &&
                          props.errors.openOnMonday && (
                            <p className="text-danger">
                              {props.errors.openOnMonday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Operational hours monday
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="operationalHoursMonday"
                          type="text"
                        />
                        {props.touched.operationalHoursMonday &&
                          props.errors.operationalHoursMonday && (
                            <p className="text-danger">
                              {props.errors.operationalHoursMonday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Open on tuesday
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="openOnTuesday">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.openOnTuesday &&
                          props.errors.openOnTuesday && (
                            <p className="text-danger">
                              {props.errors.openOnTuesday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Operational hours tuesday
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="operationalHoursTuesday"
                          type="text"
                        />
                        {props.touched.operationalHoursTuesday &&
                          props.errors.operationalHoursTuesday && (
                            <p className="text-danger">
                              {props.errors.operationalHoursTuesday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Open on wednesday
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="openOnWednesday">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.openOnWednesday &&
                          props.errors.openOnWednesday && (
                            <p className="text-danger">
                              {props.errors.openOnWednesday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Operational hours wednesday
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="operationalHoursWednesday"
                          type="text"
                        />
                        {props.touched.operationalHoursWednesday &&
                          props.errors.operationalHoursWednesday && (
                            <p className="text-danger">
                              {props.errors.operationalHoursWednesday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Open on thursday
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="openOnThursday">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.openOnThursday &&
                          props.errors.openOnThursday && (
                            <p className="text-danger">
                              {props.errors.openOnThursday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Operational hours thursday
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="operationalHoursThursday"
                          type="text"
                        />
                        {props.touched.operationalHoursThursday &&
                          props.errors.operationalHoursThursday && (
                            <p className="text-danger">
                              {props.errors.operationalHoursThursday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Open on friday
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="openOnFriday">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.openOnFriday &&
                          props.errors.openOnFriday && (
                            <p className="text-danger">
                              {props.errors.openOnFriday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Operational hours friday
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="operationalHoursFriday"
                          type="text"
                        />
                        {props.touched.operationalHoursFriday &&
                          props.errors.operationalHoursFriday && (
                            <p className="text-danger">
                              {props.errors.operationalHoursFriday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Open on saturday
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="openOnSaturday">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.openOnSaturday &&
                          props.errors.openOnSaturday && (
                            <p className="text-danger">
                              {props.errors.openOnSaturday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Operational hours saturday
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="operationalHoursSaturday"
                          type="text"
                        />
                        {props.touched.operationalHoursSaturday &&
                          props.errors.operationalHoursSaturday && (
                            <p className="text-danger">
                              {props.errors.operationalHoursSaturday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Open on sunday
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="openOnSunday">
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </CustomSelect>
                        {props.touched.openOnSunday &&
                          props.errors.openOnSunday && (
                            <p className="text-danger">
                              {props.errors.openOnSunday}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Operational hours sunday
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="operationalHoursSunday"
                          type="text"
                        />
                        {props.touched.operationalHoursSunday &&
                          props.errors.operationalHoursSunday && (
                            <p className="text-danger">
                              {props.errors.operationalHoursSunday}
                            </p>
                          )}
                      </div>
                    </div>

                    {/* <div className=" row">
                      <label className="w-25 form-label  col-3">
                        Open for LTC
                      </label>
                      <div className="w-100 col">
                        <div class="mb-3">
                          <label class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="longtermcontract"
                              value="LTC"
                              // checked={formik.values.longtermcontract === 'LTC'}
                              onChange={handleChange}
                            />
                            <span class="form-check-label"></span>
                          </label>
                        </div>
                      </div>
                    </div> */}

                    {showInputs ? (
                      <div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Crop Name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="cropname" type="text" />
                            {/* {props.touched.operationalHoursSunday &&
                            props.errors.operationalHoursSunday && (
                              <p className="text-danger">
                                {props.errors.operationalHoursSunday}
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Crop Photo
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="cropname" type="text" />
                            {/* {props.touched.operationalHoursSunday &&
                            props.errors.operationalHoursSunday && (
                              <p className="text-danger">
                                {props.errors.operationalHoursSunday}
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Purchase Frequency
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="cropname" type="text" />
                            {/* {props.touched.operationalHoursSunday &&
                            props.errors.operationalHoursSunday && (
                              <p className="text-danger">
                                {props.errors.operationalHoursSunday}
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Pricing/kg
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="cropname" type="text" />
                            {/* {props.touched.operationalHoursSunday &&
                            props.errors.operationalHoursSunday && (
                              <p className="text-danger">
                                {props.errors.operationalHoursSunday}
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            LTC duration
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="cropname" type="text" />
                            {/* {props.touched.operationalHoursSunday &&
                            props.errors.operationalHoursSunday && (
                              <p className="text-danger">
                                {props.errors.operationalHoursSunday}
                              </p>
                            )} */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Buyer" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddBuyer;
