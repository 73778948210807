import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import ButtonWithSVG from "../../Components/layouts/button_with_svg";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import Pagination from "../../Components/layouts/pagination";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import LoadingPage from "../../Components/layouts/loading";
import TaskManagementTable from "./Task Management/task_table";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import { useSelector } from "react-redux";

import { BsPatchPlus } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";

const TaskManagement = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    status: "",
    assignTo: "",
  });
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [leadOwner, setLeadOwner] = useState();
  let userData = useSelector((store) => store.login.user);

  useEffect(() => {
    setLoading(true);
    const baseURL = getFilteredURL("/tasks/getTaskManagement/", page, filters);
    const url =
      userData.Role === process.env.REACT_APP_ROLE_ADMIN ||
      userData.Role === process.env.REACT_APP_ROLE_ADMIN_S ||
      userData.Role === process.env.REACT_APP_ROLE_SALES_MANAGER
        ? baseURL
        : `${baseURL}&assignTo=${userData.Name}`;

    performRequest("GET", url)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.taskManagementCount / 50));
        } else {
          Toast("error", "Error while getting Tasks!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page, userData.Role, userData.userName]);

  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcCalendar className="fs-2  me-1" /> Task Tracking
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Allot, manage, track and update all
                  tasks.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  {(userData?.Role === process.env.REACT_APP_ROLE_ADMIN_S ||
                    userData?.Role === process.env.REACT_APP_ROLE_ADMIN ||
                    userData?.Role ===
                      process.env.REACT_APP_ROLE_SALES_MANAGER) && (
                    <a href={"/task-management/new"}>
                      <button className="btn btn-primary">
                        <BsPatchPlus className="me-2 fs-2" /> Add New Task
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-1">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3>Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Start Date:"
                              value={filters.startdate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  startdate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="End Date:"
                              value={filters.enddate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  enddate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search by status:"
                              value={filters.status}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  status: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="pending">Pending</option>
                              <option value="completed">Completed</option>
                            </DynamicFilterSelect>
                            <DynamicFilterSelect
                              label="Search by assign:"
                              value={filters.assignTo}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  assignTo: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>

                              {leadOwner &&
                                leadOwner?.map((state, index) => (
                                  <option key={index} value={state?.userName}>
                                    {state?.userName}
                                  </option>
                                ))}
                            </DynamicFilterSelect>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      startdate: "",
                                      enddate: "",
                                      status: "",
                                      assignTo: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Start Date:"
                            value={filters.startdate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                startdate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="End Date:"
                            value={filters.enddate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                enddate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterSelect
                            label="Search by status:"
                            value={filters.status}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                status: e.target.value,
                              })
                            }
                          >
                            <option value="">Select</option>
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                          </DynamicFilterSelect>
                          <DynamicFilterSelect
                            label="Search by assign:"
                            value={filters.assignTo}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                assignTo: e.target.value,
                              })
                            }
                          >
                            <option value="">Select</option>

                            {leadOwner &&
                              leadOwner?.map((state, index) => (
                                <option key={index} value={state?.userName}>
                                  {state?.userName}
                                </option>
                              ))}
                          </DynamicFilterSelect>
                        </div>

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    startdate: "",
                                    enddate: "",
                                    status: "",
                                    assignTo: "",
                                  });
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {data && (
                  <TaskManagementTable
                    data={data?.taskManagementData}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                )}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.taskManagementData?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TaskManagement;
