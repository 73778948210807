import React, { useEffect } from "react";
import { roundOffCost } from "../../../Components/Functions/amt.fun";

const ProjectFinancials = ({ allQData }) => {
  let serial = 1;
  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "0px",
    padding: "0px",
    paddingLeft: "3px",
    fontSize: "17px",
    borderCollapse: "collapse",
  };
  useEffect(() => {
    const TR = document.getElementsByTagName("tr");
    const TH = document.getElementsByTagName("th");
    const TD = document.getElementsByTagName("td");
    const H3 = document.getElementsByTagName("h3");

    Array.from(TR).forEach((tr) => {
      Object.assign(tr.style, style);
    });
    Array.from(TH).forEach((th) => {
      Object.assign(th.style, style);
    });
    Array.from(TD).forEach((td) => {
      Object.assign(td.style, style);
    });
    Array.from(H3).forEach((h3) => {
      Object.assign(h3.style, {
        marginTop: "14px",
        marginBottom: "2px",
      });
    });
  }, []);
  return (
    <div>
      <h3 className="table-heading">Project Financials (Estimates): </h3>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <p>Result specifications</p>

              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Specification</th>
                    <th>Unit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{serial++}.</td>
                    <td>Project size in Sqft</td>
                    <td>{allQData?.area_in.area_in_sqft}</td>
                  </tr>
                  {/* <tr>
                    <td>{serial++}.</td>
                    <td>Possible dimensions</td>
                    {/* <%= record.calculator.possible_max_project_size_in_sqmtr.join(", ") %> }
                  </tr> */}
                  <tr>
                    <td>{serial++}</td>
                    <td>Project size in SqMtr</td>
                    <td>{allQData?.area_in.area_in_sqmeter}</td>
                  </tr>
                  <tr>
                    <td>{serial++}.</td>
                    <td>Maximum number of plants</td>
                    <td>{allQData?.number_of_plants.maximumNumberOfPlants}</td>
                  </tr>
                  {/* {allQData?.input_info.a_FramePercent > 0 ? (
                    <tr>
                      <td>{serial++}.</td>
                      <td>Number of plants (A Frame)</td>
                      <td>
                        {allQData?.number_of_plants.numberOfPlantsInA_Frame}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {allQData?.input_info.flatBedPercent > 0 ? (
                    <tr>
                      <td>{serial++}.</td>
                      <td>Number of plants (Flat-Bed)</td>
                      <td>
                        {allQData?.number_of_plants.numberOfPlantsInFlatBed}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {allQData?.input_info.growBagPercent > 0 ? (
                    <tr>
                      <td>{serial++}.</td>
                      <td>Number of plants (Grow Bag)</td>
                      <td>
                        {allQData?.number_of_plants.numberOfPlantsInGrowBag}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {allQData?.input_info.dutchBucketPercent > 0 ? (
                    <tr>
                      <td>{serial++}.</td>
                      <td>Number of plants (Dutch Bucket)</td>
                      <td>
                        {allQData?.number_of_plants.numberOfPlantsInDutchBucket}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )} */}
                  {/* <tr>
                    <td>{serial++}.</td>
                    <td>Plant count breakdown</td>
                    <td>{allQData?.number_of_plants.maximumNumberOfPlants}</td>
                  </tr> */}

                  <tr>
                    <td>{serial++}.</td>
                    <td>Fertigation tank</td>
                    <td>{allQData?.liquid_require.fertilizerTankCapacity}L</td>
                  </tr>
                  <tr>
                    <td>{serial++}.</td>
                    <td>Solution A+B</td>
                    <td>
                      {allQData?.liquid_require.solutionTankA_Capacity +
                        allQData?.liquid_require.solutionTankB_Capacity}
                      L
                    </td>
                  </tr>
                  {/* <tr>
                    <td>{serial++}.</td>
                    <td>Solution tank B</td>
                    <td>L</td>
                  </tr> */}
                  <tr>
                    <td>{serial++}.</td>
                    <td>Number of labours</td>
                    <td>{allQData?.labourCount}</td>
                  </tr>
                  <tr>
                    <td>{serial++}.</td>
                    <td>Electricity phase</td>
                    <td>{allQData?.electricityPhase} Phase</td>
                  </tr>
                  <tr>
                    <td>{serial++}.</td>
                    <td>UV treatment tube</td>

                    <td>{350 * allQData?.uvTreatmentTube}LPH</td>
                  </tr>
                </tbody>
              </table>
            </td>
            {allQData?.input_info?.quotationType !== "nethouse" &&
              allQData?.input_info?.quotationType !== "fanpad" &&
              allQData?.input_info?.quotationType !== "nvph" && (
                <td>
                  <p>Opex Specifications</p>

                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Specification</th>

                        <th>Monthly Exp</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{(serial = 1)}.</td>
                        <td>Seeds</td>

                        <td>
                          {roundOffCost(allQData?.opex_cost?.opex_seeds_cost)}
                        </td>
                      </tr>
                      <tr>
                        <td>{++serial}.</td>
                        <td>Grow media</td>

                        <td>
                          {roundOffCost(
                            allQData?.opex_cost?.opex_growmedia_cost
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>{++serial}.</td>
                        <td>Electricity</td>
                        {/* <td><%= record.calculator.electricity_units_per_cycle.k_to_comma_format(0) %>units</td> */}

                        {/* <td><%= record.calculator.electricity_units_per_month_expense.k_to_inr %></td> */}
                        <td>
                          {roundOffCost(
                            allQData?.opex_cost?.opex_electricity_cost
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{++serial}.</td>
                        <td>Solution A</td>
                        {/* <td><%= record.calculator.solution_a_per_cycle.k_to_comma_format(0) %>L</td> */}

                        <td>
                          {roundOffCost(
                            allQData?.opex_cost?.opex_solutionA_cost
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{++serial}.</td>
                        <td>Solution B</td>
                        {/* <td><%= record.calculator.solution_b_per_cycle.k_to_comma_format(0) %>L</td> */}

                        {/* <td><%= record.calculator.solution_b_per_month_expense.k_to_inr %></td> */}
                        <td>
                          {roundOffCost(
                            allQData?.opex_cost?.opex_solutionB_cost
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{++serial}.</td>
                        <td>Packaging</td>
                        {/* <td><%= record.calculator.packaging_material_per_cycle.k_to_comma_format(0) %>bags</td> */}

                        {/* <td><%= record.calculator.packaging_material_per_month_expense.k_to_inr %></td> */}
                        <td>
                          {roundOffCost(
                            allQData?.opex_cost?.opex_packaging_cost
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>{++serial}.</td>
                        <td>Labours</td>

                        <td>
                          {roundOffCost(allQData?.opex_cost?.opex_labour_cost)}
                        </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td>Total opex/month (tentative)</td>

                        <td>
                          {roundOffCost(allQData?.opex_cost?.opex_all_cost)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              )}
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default ProjectFinancials;
