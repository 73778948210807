import React from "react";
import signature from "../../../certificates/signature.jpg";
const RecipientSignature = () => {
  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "0px",
    padding: "0px",
    paddingLeft: "3px",
    fontSize: "12px",
    borderCollapse: "collapse",
  };
  return (
    <table style={{ ...style, width: "100%" }}>
      <tbody>
        <tr>
          <td
            style={{
              ...style,
              width: "70%",
              padding: "auto",
              paddingTop: "40px",
              paddingLeft: "20px",
            }}
          >
            <b> Recipient Signature</b>
          </td>
          <td style={style}>
            <img
              src={signature}
              width={"200px"}
              style={{ padding: "5px" }}
              alt="signature"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default RecipientSignature;
