import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import { useParams, useNavigate } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { useSelector } from "react-redux";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";
import { FcSms } from "react-icons/fc";
import { FaUserCircle } from "react-icons/fa";

const ViewQuery = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [showReply, setShowReply] = useState(false);
  const [reply, setReply] = useState("");
  const [imageUrls, setImageUrls] = useState({});
  const [mediaType, setMediaType] = useState("");
  let { id } = useParams();
  let userData = useSelector((store) => store.login.user);
  const userRole = userData.Role;

  const isAdmin = [
    process.env.REACT_APP_ROLE_ADMIN_S,
    process.env.REACT_APP_ROLE_ADMIN,
    process.env.REACT_APP_ROLE_OPS,
    process.env.REACT_APP_ROLE_SALES_MANAGER,
  ].includes(userRole);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/farmSupport/getFarmQueryById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    const fileType = data?.image?.split(".").pop().toLowerCase();
    if (data?.image) {
      getUplodedImage(setImageUrls, data?.image, "image").then((url) => {
        if (["mp4", "mov", "avi", "wmv", "flv"].includes(fileType)) {
          setMediaType("video");
        } else {
          setMediaType("image");
        }
      });
    }
  }, [data]);

  const handleAddResponse = () => {
    const updatedReplies = [
      ...(data.replies || []),
      {
        userId: user.UserId,
        name: user.Name,
        message: reply,
        createdAt: new Date(),
      },
    ];
    const values = { replies: updatedReplies };

    setLoading(true);
    performRequest("PATCH", `/farmSupport/updateQuery/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Response added successfully!");
          setData((prevData) => ({ ...prevData, replies: updatedReplies }));
          setReply("");
          setShowReply(false);
        } else {
          Toast("error", "Error while adding response");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
        setLoading(false);
      });
  };

  const markAsResolved = () => {
    setLoading(true);
    performRequest("PATCH", `/farmSupport/updateQuery/${id}`, {
      status: "resolved",
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Query marked as resolved!");
          navigate(`/agronomy-farm-support`);
        } else {
          Toast("error", "Error while Creating");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "red";
      case "being address":
        return "orange";
      case "resolved":
        return "green";
      default:
        return "text-muted";
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  // console.log(imageUrls.image);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcSms className="fs-2  me-1" /> Farm Support Tickets
                </h2>
                <h4 className="text-muted">View your query.</h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  {data?.status !== "resolved" ? (
                    data?.status === "being address" ? (
                      <button
                        onClick={markAsResolved}
                        className="btn btn-primary"
                      >
                        MARK AS RESOLVED
                      </button>
                    ) : (
                      <></>
                    )
                  ) : (
                    <button
                      onClick={markAsResolved}
                      className="btn btn-primary"
                      disabled
                    >
                      QUERY HAS BEEN RESOLVED
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specifications</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Client Name</td>
                        <td>{data?.farmName || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Client Location</td>
                        <td>{data?.projectAddress || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Client Email</td>
                        <td>{data?.clientEmail || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Client Contact Number</td>
                        <td>{data?.clientPhoneNumber || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Client Site Inspection Done</td>
                        <td>
                          {data?.siteInspectionDone === "yes" ? "Yes" : "No"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specifications</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    {data && (
                      <tbody>
                        <tr>
                          <td>Query Status</td>
                          <td>
                            <span
                              className={`badge bg-${getStatusColor(
                                data?.status
                              )}`}
                              style={{ color: "white" }}
                            >
                              {data?.status}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Query Related To</td>
                          <td>{data?.relatedTo || "N/A"}</td>
                        </tr>
                        <tr>
                          <td>Client Uploaded Media</td>
                          <td>
                            {mediaType === "image" && imageUrls ? (
                              <img
                                width="60%"
                                src={imageUrls?.image}
                                alt={"Queryimage"}
                              />
                            ) : mediaType === "video" && imageUrls ? (
                              <video width="30%" height={"50%"} controls>
                                <source
                                  src={imageUrls?.image}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Client message</td>
                          <td>{data?.message || "N/A"}</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>

                {data && (
                  <>
                    <div className="row p-3">
                      <label className="w-25 form-label col-3">Replies</label>
                      <div className="w-100 col">
                        {data?.replies && data.replies.length > 0
                          ? data.replies.map((reply, index) => (
                              <div key={index} className="card my-2">
                                <div className="card-body">
                                  <div className="d-flex">
                                    <FaUserCircle
                                      className="me-2"
                                      style={{ fontSize: "25px" }}
                                    />
                                    <h5 className="card-title">
                                      {reply?.name}
                                    </h5>
                                  </div>
                                  <p
                                    className="card-text"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    {reply?.message}
                                  </p>
                                  <p className="card-text">
                                    <small className="text-muted">
                                      {new Date(
                                        reply.createdAt
                                      ).toLocaleString()}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            ))
                          : "No replies yet"}
                      </div>
                    </div>

                    {showReply ? (
                      <div className="row p-3">
                        <label className="w-25 form-label col-3">
                          Your Reply
                        </label>
                        <div className="w-100 col">
                          <textarea
                            className="form-control"
                            value={reply}
                            onChange={(e) => setReply(e.target.value)}
                            rows="3"
                          ></textarea>
                          <button
                            onClick={handleAddResponse}
                            className="btn btn-primary mt-2"
                          >
                            Submit
                          </button>
                          <button
                            onClick={() => setShowReply(false)}
                            className="btn btn-secondary mt-2 ms-2"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="row p-3">
                        <div className="w-100 col">
                          <button
                            onClick={() => setShowReply(true)}
                            className="btn btn-primary"
                          >
                            Add Reply
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewQuery;
