import React from "react";
import { useNavigate } from "react-router-dom";
const tableHead = [
  "Sr.",
  "Client Name",
  "Rating",
  "Origin",
  "Feedback received",
  "Lead Owner",
  "Actions",
];
const ClientFeedbackTable = ({ feedback = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/client-feedback/new?id=${id}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {feedback?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.clientName || "N/A"}</td>
                <td>{item?.rating || "N/A"}</td>
                <td>{item?.origin || "N/A"}</td>
                <td>
                  {item?.feedBackStatus === "pending" ? "No" : "Yes" || "N/A"}
                </td>
                <td>{item?.leadOwner || "N/A"}</td>
                <td>
                  <button
                    style={{ width: "110px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    Take Feedback
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ClientFeedbackTable;
