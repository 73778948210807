import React, { useState } from "react";
import Footer from "../../Components/Footer/footer";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const handelSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div className="page page-wrapper">
      <div className="container-xl">
        <div className="page-center" style={{ minHeight: "85vh" }}>
          <div className="container container-normal ">
            <div className="row align-items-center g-4">
              <div className="col-lg d-none d-lg-block">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2022/11/DE/TV/YV/80947497/shade-net-house-500x500.png"
                  height="300"
                  className="d-block mx-auto w-50 h-50"
                  alt=""
                />
              </div>
              <div className="col-lg">
                <div className="container-tight">
                  <div className="text-center mb-4">
                    <span>
                      {/* <img
                className="navbar-brand-image"
                src="https://www.kryzen.com/wp-content/uploads/2022/02/kryzen-logo-1.png"
                alt="Kryzen"
                style={{
                  width: "110px",
                  height: "50px",
                }}
              /> */}
                      <h1 className="fs-large m-0 p-0">
                        <span className="text-primary">α</span>lpha
                      </h1>
                      <p className="fs-5 ps-6 m-0 text-success">by KRYZEN</p>
                    </span>
                  </div>
                  <div className="card card-md">
                    <div className="card-body">
                      <h2 className="card-title text-center mb-4">
                        Forgot Your password?
                      </h2>
                      <form onSubmit={handelSubmit}>
                        <div className="card-body">
                          <p className="text-muted mb-4">
                            Enter your email address and your password will be
                            reset and emailed to you.
                          </p>

                          <div className="mb-3">
                            <label className="form-label">Email address</label>
                            <input
                              name="email"
                              autoComplete="true"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              required
                              className="form-control"
                              placeholder="Enter email"
                            />
                          </div>
                          <div className="form-footer">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                <path d="M3 7l9 6l9 -6" />
                              </svg>
                              Send me new password
                            </button>
                            <div className="text-muted mt-3">
                              Forget it,{" "}
                              <a href="/account-login">send me back</a> to the
                              sign in screen.
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgetPassword;
