import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";

const ViewDeliveryChallan = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/deliveryChallan/getDcById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Delivery Challan</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">View Delivery Challan</h2>
            </div>

            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Challan date</label>
                  <div className="w-100 col">
                    {(data?.challanDate || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Country of supply
                  </label>
                  <div className="w-100 col">
                    {(data?.countryOfSupply || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Driver name</label>
                  <div className="w-100 col">
                    {(data?.driverName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Driver phone number
                  </label>
                  <div className="w-100 col">
                    {(data.driverPhoneNumber || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Eway bill number
                  </label>
                  <div className="w-100 col">
                    {(data?.ewayBillNumber || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Lr number</label>
                  <div className="w-100 col">
                    {(data?.lrNumber || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Place of supply
                  </label>
                  <div className="w-100 col">
                    {(data?.placeOfSupply || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Project</label>
                  <div className="w-100 col">
                    {(data?.project || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Transport name
                  </label>
                  <div className="w-100 col">
                    {(data?.transportName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Vehicle number
                  </label>
                  <div className="w-100 col">
                    {(data?.vehicleNumber || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              <hr />
              <h2>Line Items</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>PARTICULARS</th>
                        <th>Item Unit</th>
                        <th>Item Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.lineItems?.map((lineItem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {(lineItem?.particulars || "N/A").toUpperCase()}
                            </td>
                            <td>
                              {(lineItem?.itemUnit || "N/A").toUpperCase()}
                            </td>
                            <td>
                              {(lineItem?.itemQuantity || "N/A").toUpperCase()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText={"generate pdf"}
                  href={`/delivery-challan/preview/${id}`}
                />
                <SimpleButton
                  buttonText="Edit Delivery Challan"
                  href={`/delivery-challan/edit/${id}`}
                />

                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewDeliveryChallan;
