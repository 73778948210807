import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";

const ViewProjectSpecification = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      `/projectSpecification/getProjectSpecificationById/${id}`
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting project specification!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Project Specification</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">View Project Specification</h2>
            </div>
            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Project</label>
                  <div className="w-100 col">
                    {(data?.clientName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Client contact number
                  </label>
                  <div className="w-100 col">
                    {data?.clientContactNumber || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Cea type</label>
                  <div className="w-100 col">
                    {(data?.ceaType || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Farm address</label>
                  <div className="w-100 col">
                    {(data.farmAddress || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Bay size</label>
                  <div className="w-100 col">{data?.psBaySize || "N/A"}</div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Span size</label>
                  <div className="w-100 col">{data?.psSpanSize || "N/A"}</div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    A-Frame percent
                  </label>
                  <div className="w-100 col">
                    {data?.aFramePercent ? `${data?.aFramePercent} %` : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Flat-bed percent
                  </label>
                  <div className="w-100 col">
                    {data?.flatBedPercent ? `${data?.flatBedPercent} %` : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Grow-bag percent
                  </label>
                  <div className="w-100 col">
                    {data?.growBagPercent ? `${data?.growBagPercent} %` : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Dutch-bucket percent
                  </label>
                  <div className="w-100 col">
                    {data?.dutchBucketPercent
                      ? `${data?.dutchBucketPercent} %`
                      : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Hocky</label>
                  <div className="w-100 col">{data?.hocky ? `Yes` : "No"}</div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Hockey presence
                  </label>
                  <div className="w-100 col">
                    {data?.hockeyPresence?.length > 0
                      ? `${data?.hockeyPresence.join(", ").toUpperCase()}`
                      : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Height</label>
                  <div className="w-100 col">{data?.psHeight || "N/A"}</div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Total area (SqM)
                  </label>
                  <div className="w-100 col">
                    {data?.totalAreaSqM ? `${data?.totalAreaSqM} SqM` : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Total bays count
                  </label>
                  <div className="w-100 col">
                    {data?.totalBaysCount || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Total block count
                  </label>
                  <div className="w-100 col">
                    {data?.totalBlockCount || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Total span count
                  </label>
                  <div className="w-100 col">
                    {data?.totalSpanCount || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Total usable area (SqM)
                  </label>
                  <div className="w-100 col">
                    {data?.totalUsableAreaSqM
                      ? `${data?.totalUsableAreaSqM} SqM`
                      : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Usable bay length
                  </label>
                  <div className="w-100 col">
                    {data?.usableBayLength || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Usable span length
                  </label>
                  <div className="w-100 col">
                    {data?.usableSpanLength || "N/A"}
                  </div>
                </div>
              )}
              <hr />
              <h2>Standard Material</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.standardMaterial?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Fogger Assembly</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.foggerAssembly?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Seedling Station</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.seedlingStation?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Air Circulation Fans</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.airCirculationFans?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>First Batch Starter</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.firstBatchStarter?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>A-Frame Block Specification</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.aFrameBlockSpecification?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Flat-Bed Block Specifications</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.flatBedBlockSpecifications?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Grow Bags Block Specifications</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.growBagsBlockSpecifications?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Dutch Bucket Block Specification</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.dutchBucketBlockSpecification?.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{(item?.item || "N/A").toUpperCase()}</td>
                              <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                              <td>{item?.quantity || "N/A"}</td>
                              <td>{(item?.units || "N/A").toUpperCase()}</td>
                              <td>
                                {(item?.specification || "N/A").toUpperCase()}
                              </td>
                              <td>
                                {(item?.orderType || "N/A").toUpperCase()}
                              </td>
                              <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                              <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>A-Frame Nft System Specification</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.aFrameNftSystemSpecification?.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{(item?.item || "N/A").toUpperCase()}</td>
                              <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                              <td>{item?.quantity || "N/A"}</td>
                              <td>{(item?.units || "N/A").toUpperCase()}</td>
                              <td>
                                {(item?.specification || "N/A").toUpperCase()}
                              </td>
                              <td>
                                {(item?.orderType || "N/A").toUpperCase()}
                              </td>
                              <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                              <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Flatbed NFT System Specifications</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.flatbedNFTSystemSpecifications?.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{(item?.item || "N/A").toUpperCase()}</td>
                              <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                              <td>{item?.quantity || "N/A"}</td>
                              <td>{(item?.units || "N/A").toUpperCase()}</td>
                              <td>
                                {(item?.specification || "N/A").toUpperCase()}
                              </td>
                              <td>
                                {(item?.orderType || "N/A").toUpperCase()}
                              </td>
                              <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                              <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Water Tank Assembly for A-Frame</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.waterTankAssemblyforAFrame?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Plumbing Adhesive</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.plumbingAdhesive?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <hr />
              <h2>Handbook And Manual</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>item</th>
                        <th>hsn</th>
                        <th>Quantity</th>
                        <th>units</th>
                        <th>specification</th>
                        <th>orderType</th>
                        <th>vendor</th>
                        <th>cogs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.handbookAndManual?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{(item?.item || "N/A").toUpperCase()}</td>
                            <td>{(item?.hsn || "N/A").toUpperCase()}</td>
                            <td>{item?.quantity || "N/A"}</td>
                            <td>{(item?.units || "N/A").toUpperCase()}</td>
                            <td>
                              {(item?.specification || "N/A").toUpperCase()}
                            </td>
                            <td>{(item?.orderType || "N/A").toUpperCase()}</td>
                            <td>{(item?.vendor || "N/A").toUpperCase()}</td>
                            <td>{(item?.cogs || "N/A").toUpperCase()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText={"generate pdf"}
                  href={`/project-specification/preview/${id}`}
                />
                <SimpleButton
                  buttonText="Edit Project Specification"
                  href={`/project-specification/view/${id}`}
                />
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default ViewProjectSpecification;
