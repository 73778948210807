import React from "react";

const WhatsAppButton = ({ buttonText = "WhatsApp", ...props }) => {
  return (
 
   // <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
    <a
      className="btn mx-1 mt-2 btn-primary"
      style={{ color: "#FFFFFF", backgroundColor: "#10C245", borderRadius: 5 }}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-brand-whatsapp"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
        <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
      </svg>
      {buttonText}
    </a>
    // </div>
  );
};

export default WhatsAppButton;
