import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";
import { FcClock } from "react-icons/fc";

const ViewAttendance = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    if (data) {
      if (data?.photo) {
        getUplodedImage(setImageUrls, data?.photo, "photo");
      }
      if (data?.logoutPhoto) {
        getUplodedImage(setImageUrls, data?.logoutPhoto, "logoutPhoto");
      }
    }
  }, [data]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/attendance/getAttendanceById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Details!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcClock className="fs-2  me-1" /> Employee Attendance
                  Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Calculate & Check employee attendance
                </h4>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specs</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-secondary">Employee Name</td>
                        <td>{data?.userName || "N/A"}</td>
                      </tr>
                      <tr>
                        <td class="text-secondary">Attendance Date</td>
                        <td>{data?.date}</td>
                      </tr>
                      <tr>
                        <td class="text-secondary">Attendance Status</td>
                        <td>{data?.loginStatus || "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="card">
                        <table class="table table-vcenter card-table table-striped">
                          <thead>
                            <tr>
                              <th>Login Specs</th>
                              <th>Login Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && (
                              <tr>
                                <td class="text-secondary">Photo Login</td>
                                <td>
                                  {imageUrls?.photo ? (
                                    <img
                                      src={imageUrls?.photo}
                                      alt={data?.photo}
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Login Date</td>
                                <td>{data?.date || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Login Time</td>
                                <td>{data?.time || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Login IP</td>
                                <td>{data?.ipAddress || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Login Browser</td>
                                <td>{data?.browser || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Login OS</td>
                                <td>{data?.operatingSystem || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Login Lats</td>
                                <td>{data?.userLocation?.latitude || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Login Long</td>
                                <td>
                                  {data?.userLocation?.longitude || "N/A"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="card">
                        <table class="table table-vcenter card-table table-striped">
                          <thead>
                            <tr>
                              <th>Logout Specs</th>
                              <th>Logout Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && (
                              <tr>
                                <td class="text-secondary">Photo Logout</td>
                                <td>
                                  {imageUrls?.logoutPhoto ? (
                                    <img
                                      src={imageUrls?.logoutPhoto}
                                      alt={data?.logoutPhoto}
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Logout Date</td>
                                <td>{data?.logoutDate || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Logout Time</td>
                                <td>{data?.logoutTime || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Logout IP</td>
                                <td>{data?.logoutIpAddress || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Logout Browser</td>
                                <td>{data?.browser || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Logout Date</td>
                                <td>{data?.logoutDate || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Logout OS</td>
                                <td>{data?.logoutOperatingSystem || "N/A"}</td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Logout Lats</td>
                                <td>
                                  {data?.logoutuserLocation?.latitude || "N/A"}
                                </td>
                              </tr>
                            )}
                            {data && (
                              <tr>
                                <td class="text-secondary">Logout Logs</td>
                                <td>
                                  {data?.logoutuserLocation?.longitude || "N/A"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewAttendance;
