import { ToastContainer } from "react-toastify";
import CheckedLoggedIn from "./Kryzen Alpha App/Routes/checked_loggedIn";
import { Helmet } from "react-helmet";
function App() {
  return (
    <>
      <Helmet>
        <title>Kryzen Biotech Alpha</title>
        <meta
          name="description"
          content="We are India's number one hydroponic construction company."
        />
        <meta
          name="keywords"
          content="Hydroponic Farming, Hydroponic Services, Hydroponic Farming in India, Hydroponic, Hydroponic Farming in Pune, Hydroponic Farming in Maharastra, Hydroponic Services in India, Advanced Hydroponics Training in India, Commercial Hydroponics Farming in India, Radical Hydroponics Services in India, Natural and Organic Farming"
        />
      </Helmet>
      <CheckedLoggedIn />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      ></ToastContainer>
    </>
  );
}

export default App;
