import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { useSelector } from "react-redux";
import { FarmTrainingInfo } from "../../../SchemaInfo/info";
import { FarmTrainingSchema } from "../../../Schemas/sales/farm_training";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import { useEffect } from "react";

const getId = (str) => {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  return parseInt(lastPart, 10);
};

const EditFarmTraining = () => {
  const [farmTraining, setFarmTraining] = useState(FarmTrainingInfo);
  let { id } = useParams();
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest("PATCH", `/farmTraining/updateFarmTraining/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Training updated successfully!");
          navigate(`/farm-training/view/${id}`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/farmTraining/getFarmTrainingById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setFarmTraining(res.data);
        } else {
          Toast("error", "Error while getting data!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Farm Training</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Edit farm training</h2>
            </div>
            <Formik
              initialValues={farmTraining}
              validationSchema={FarmTrainingSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="name" type="text" />
                        {props.touched.name && props.errors.name && (
                          <p className="text-danger">{props.errors.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Email</label>
                      <div className="w-100 col">
                        <CustomInput name="email" type="text" />
                        {props.touched.email && props.errors.email && (
                          <p className="text-danger">{props.errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Contact number
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="contactNumber" type="text" />
                        {props.touched.contactNumber &&
                          props.errors.contactNumber && (
                            <p className="text-danger">
                              {props.errors.contactNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Enroll date
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="enrollDate" type="date" />
                        {props.touched.enrollDate &&
                          props.errors.enrollDate && (
                            <p className="text-danger">
                              {props.errors.enrollDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Farm training date
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="farmTrainingDate" type="date" />
                        {props.touched.farmTrainingDate &&
                          props.errors.farmTrainingDate && (
                            <p className="text-danger">
                              {props.errors.farmTrainingDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Amount paid
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="amountPaid" type="text" />
                        {props.touched.amountPaid &&
                          props.errors.amountPaid && (
                            <p className="text-danger">
                              {props.errors.amountPaid}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Payment mode
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="modeOfPayment" type="text">
                          <option value="">Select</option>
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="upi">UPI</option>
                          <option value="imps">IMPS</option>
                          <option value="rtgs">RTGS</option>
                          <option value="neft">NEFT</option>
                        </CustomSelect>
                        {props.touched.modeOfPayment &&
                          props.errors.modeOfPayment && (
                            <p className="text-danger">
                              {props.errors.modeOfPayment}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Food preference
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="foodPreference" type="text">
                          <option value="">Select</option>
                          <option value="vegetarian">Vegetarian</option>
                          <option value="non-vegetarian">Non-Vegetarian</option>
                        </CustomSelect>
                        {props.touched.foodPreference &&
                          props.errors.foodPreference && (
                            <p className="text-danger">
                              {props.errors.foodPreference}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Hotel booked
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="hotelBooked" type="text" />
                        {props.touched.hotelBooked &&
                          props.errors.hotelBooked && (
                            <p className="text-danger">
                              {props.errors.hotelBooked}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Upgrade request
                      </label>
                      <div className="w-100 col">
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                name="upgradeRequest"
                                width={"15px"}
                                height={"15px"}
                                value="ac room"
                              />
                            </label>
                            <span className="form-check-label">AC Room</span>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                name="upgradeRequest"
                                width={"15px"}
                                height={"15px"}
                                value="transportation hotel to site"
                                type="checkbox"
                              />
                            </label>
                            <span className="form-check-label">
                              Transportation hotel to site
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                width={"15px"}
                                height={"15px"}
                                name="upgradeRequest"
                                value="transportation direct to site"
                              />
                            </label>
                            <span className="form-check-label">
                              Transpostation direct to site
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                width={"15px"}
                                height={"15px"}
                                name="upgradeRequest"
                                value="single occupancy"
                              />
                            </label>
                            <span className="form-check-label">
                              Single occupancy
                            </span>
                          </div>
                        </div>
                        {props.touched.upgradeRequest &&
                          props.errors.upgradeRequest && (
                            <p className="text-danger">
                              {props.errors.upgradeRequest}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Client type
                      </label>
                      <div className="w-100 col">
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                name="clientType"
                                width={"15px"}
                                height={"15px"}
                                value="office visit done"
                              />
                            </label>
                            <span className="form-check-label">
                              Office visit done
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                name="clientType"
                                width={"15px"}
                                height={"15px"}
                                value="quotation recieved"
                                type="checkbox"
                              />
                            </label>
                            <span className="form-check-label">
                              Quotation recieved
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                width={"15px"}
                                height={"15px"}
                                name="clientType"
                                value="si done"
                              />
                            </label>
                            <span className="form-check-label">
                              Site inspection done
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                width={"15px"}
                                height={"15px"}
                                name="clientType"
                                value="already client"
                              />
                            </label>
                            <span className="form-check-label">
                              Already client
                            </span>
                          </div>
                        </div>
                        {props.touched.clientType &&
                          props.errors.clientType && (
                            <p className="text-danger">
                              {props.errors.clientType}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Farm trainig location
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="farmTrainingLocation" type="text" />
                        {props.touched.farmTrainingLocation &&
                          props.errors.farmTrainingLocation && (
                            <p className="text-danger">
                              {props.errors.farmTrainingLocation}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Farm trainig location url
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="farmTrainingLocationUrl"
                          type="text"
                        />
                        {props.touched.farmTrainingLocationUrl &&
                          props.errors.farmTrainingLocationUrl && (
                            <p className="text-danger">
                              {props.errors.farmTrainingLocationUrl}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Farm Training" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditFarmTraining;
