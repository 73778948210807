import React from "react";

const CompanyDescription = ({
  businessName = "",
  address = "",
  data = "",
  allQData = "",
}) => {
  const getQuotationFor = () => {
    let bag = "";

    if (allQData?.input_info?.quotationType == "fanpad") {
      bag += "FANPAD";
    } else if (allQData?.input_info?.quotationType == "nvph") {
      bag += "NVPH";
    } else if (allQData?.input_info?.quotationType == "nethouse") {
      bag += "NETHOUSE";
    } else if (
      allQData?.input_info?.quotationType == "fanpad with hydroponics"
    ) {
      bag += "FANPAD";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += `+ ${allQData?.input_info?.a_FramePercent}% A-FRAME`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += `+ ${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += `+ ${allQData?.input_info?.growBagPercent}% GROW-BAG`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += `+ ${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
      }
      bag += ` ${next}`;
    } else if (
      allQData?.input_info?.quotationType == "nethouse with hydroponics"
    ) {
      bag += "NETHOUSE";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
      }
      bag += ` ${next}`;
    } else if (allQData?.input_info?.quotationType == "nvph with hydroponics") {
      bag += "NVPH";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
      }
      bag += ` ${next}`;
    } else if (allQData?.input_info?.quotationType == "hydroponics only") {
      bag += "WITHOUT-CEA";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
      }
      bag += ` ${next}`;
    }

    return bag;
  };

  return (
    <div>
      <div
        style={{ borderTop: "1px solid #181818", marginBottom: "5px" }}
      ></div>
      <p>
        Dear {businessName},<br />
        {address}
      </p>
      <p>
        Thank you for showing interest in{" "}
        <span style={{ fontWeight: "bold", fontSize: "12px" }}>
          {getQuotationFor()}
        </span>{" "}
        by Kryzen Biotech Pvt Ltd (KBPL). As per our discussions, please find
        the detailed quotation below along with the project financials.
      </p>
      <h3>Company Profile:</h3>
      <p>
        Established in the year 2019, Kryzen Biotech Private Limited is a
        trusted name engaged in providing hydroponic training and Hydroponics
        services with automation for high tech farming. Kryzen Biotech is a
        Controlled Environment Agriculture (CEA) specialist company that
        combines sustainable technology and modern farming techniques, to create
        highly productive and economically profitable models for the new-age
        urban farmer. In a world where innovation meets sustainability, our
        journey begins. As a pioneering hydroponic farm developer company, we
        embark on a mission to revolutionize agriculture, redefine farming
        practices, and cultivate a greener future. Rooted in cutting-edge
        technology and a deep commitment to environmental stewardship, we
        harness the power of hydroponics to cultivate crops efficiently,
        sustainably, and with unparalleled precision. At the heart of our vision
        lies a dedication to reimagining the way we grow, harvest, and nourish
        our communities. Welcome to the forefront of agricultural innovation.
      </p>
    </div>
  );
};

export default CompanyDescription;
