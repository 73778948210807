import React from "react";
const Images = {
  aadharcardPhotoFront:
    "https://cdn.pixabay.com/photo/2022/11/09/00/44/aadhaar-card-7579588_1280.png",
  aadharcardPhotoBack:
    "https://i.pinimg.com/736x/30/7b/63/307b637a77b6bf02dd8b83768cfc2996.jpg",
  bankPassbook:
    "https://5.imimg.com/data5/ZX/DV/WM/GLADMIN-85845681/sbi-blank-passbook-500x500.jpg",
  residentialProof:
    "https://www.shutterstock.com/image-vector/indian-voter-id-card-vector-260nw-1754108780.jpg",

  offerLetterAcceptance:
    "https://www.ncertbooks.guru/wp-content/uploads/2021/02/Offer-Letter-Template.png",
  joiningLetterScan:
    "https://www.ncertbooks.guru/wp-content/uploads/2021/02/Letter-of-Appointment.png",

  pancardPhoto:
    "https://cdn.pixabay.com/photo/2022/11/09/00/45/pan-card-7579594_1280.png",
};
const PersonalDocuments = () => {
  return (
    <div className="col-9">
      <div className="row row-deck row-cards">
        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              height={"150px"}
              src={Images.aadharcardPhotoBack}
              className="card-img-top"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Aadhar Front</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              height={"150px"}
              src={Images.aadharcardPhotoFront}
              className="card-img-top"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Aadhar Back</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              height={"150px"}
              src={Images.bankPassbook}
              className="card-img-top"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Bank Passbook</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              height={"150px"}
              src={Images.joiningLetterScan}
              className="card-img-top"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Joining Letter</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              height={"150px"}
              src={Images.residentialProof}
              className="card-img-top"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Rsidential Proof</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDocuments;
