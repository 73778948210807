import * as yup from "yup";

export const FarmTrainingSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string(),
  contactNumber: yup.number().required("Required"),
  enrollDate: yup.string(),
  farmTrainingDate: yup.string().required("Required"),
  amountPaid: yup.number().required("Required"),
  modeOfPayment: yup.string().required("Required"),
  foodPreference: yup.string(),
  hotelBooked: yup.string().required("Required"),
  upgradeRequest: yup.array().of(yup.string()),
  clientType: yup.array().of(yup.string()),
  farmTrainingLocation: yup.string().required("Required"),
  farmTrainingLocationUrl: yup.string(),
  generatedBy: yup.string(),
});
