import React from "react";

const WaterStorage_Handling = ({ quotationType = null }) => {
  let serial = 1;
  return (
    <div>
      <h3 className="table-heading">Water Storage and Handling:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{serial++}.</td>
            <td>Reservoir tank</td>
            <td>
              Reservoir tank of Food-grade plastic, which will be a 2000 liter
              three-layer reservoir tank given by KBPL. And installation of
              reservoir tank will be provided by us.
            </td>
          </tr>

          <tr>
            <td>{serial++}.</td>
            <td>Reservoir tank dig</td>
            <td>
              While land levelling with help of JCB reservoir tank spot will be
              dug. Placement of reservoir tank is purely based of land slope and
              determined after primary inspection of KBPL team.
            </td>
          </tr>

          <tr>
            <td>{serial++}.</td>
            <td>ABC mother tanks</td>
            <td>
              ABC nutrient mother tanks with capacity of 75ltr - 100ltr each
              will be connected to reservoir tanks.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>4-Way foggers assembly</td>
            <td>
              65 Micron, LPD fitted, High pressure 4-way foggers, lateral
              piping, PVC headers, fittings, fogger motor, etc.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Fogger tank</td>
            <td>
              Fogger tank of 1000 ltr will be placed near the reservoir to
              simplify water top up on a regular interval during summer time.
            </td>
          </tr>

          <tr>
            <td>{serial++}.</td>
            <td>Reservoir tank capacity</td>
            <td>
              Reservoir tank will have a water capacity based on water
              availibility at source and system design.
            </td>
          </tr>

          <tr>
            <td>{serial++}.</td>
            <td>Water filter / water treatment</td>
            <td>
              In case of high TDS water, Water treatment installation may be
              required. You are free to obtain rain water harvesting or RO. KBPL
              will do the job of connecting it to the mainline system. Actual
              water treatment will be in scope of you and the company you are
              buying the unit from.
            </td>
          </tr>

          <tr>
            <td>{serial++}.</td>
            <td>Automation unit installation channels</td>
            <td>
              At reservoir level the option to add an automation unit will be
              added with help of filters, fittings, piping's, and check valves.
            </td>
          </tr>

          {quotationType === "fanpad" && (
            <>
              <tr>
                <td>{serial++}.</td>
                <td>Water tank for padding system</td>
                <td>
                  Water tank for padding system to drain water in multiples of
                  2000ltr as per project size.
                </td>
              </tr>
              <tr>
                <td>{serial++}.</td>
                <td>Fan Pad Water tank recycling and drainage</td>
                <td>
                  Water tank recycling and drainage system with recollection of
                  dropped water.
                </td>
              </tr>
              <tr>
                <td>{serial++}.</td>
                <td>4 way fogging system</td>
                <td>4 Way fogging system for humidity with all fittings.</td>
              </tr>
            </>
          )}

          <tr>
            <td>{serial++}.</td>
            <td>UV light assembly</td>
            <td>
              UV light treatment assembly is used to treat fungal infection,
              algae formation, and bacterial & pathogen growth.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WaterStorage_Handling;
