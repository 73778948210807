import React from "react";

const Agreement = () => {
  return (
    <div>
      <style>{`
        .custom-list {
          font-size:16px;
          list-style-type: none;
          padding-left: 0;
        }
        
        .custom-list > li {
          font-size:16px;
          counter-increment: list;
          margin-bottom: 20px; /* Add space between list items */
        }

      
        
        .custom-list > li:before {
          content: counter(list) ". ";
          display: inline-block;
          position: absolute;
          margin-right: 100%;
          right: 10px;
        }
        
        .custom-list ol {
          list-style-type: none;
          counter-reset: list2; /* Reset the counter for nested lists */
        }
        
        .custom-list ol > li {
          font-size:16px;
          counter-increment: list2;
          margin-bottom: 10px; /* Add space between nested list items */
        }

       
        
        .custom-list ol > li:before {
          content: counter(list) "." counter(list2) ". ";
          display: inline-block;
          position: absolute;
          margin-right: 100%;
          right: 10px;
        }
       
        
        .custom-list ol ol {
          list-style-type: none;
          font-size:16px;
          counter-reset: list3; /* Reset the counter for deeply nested lists */
        }
        
        .custom-list ol ol > li {
        
          counter-increment: list3;
          margin-bottom: 5px; /* Add space between deeply nested list items */
        }
        
        .custom-list ol ol > li:before {
          content: counter(list) "." counter(list2) "." counter(list3) ". ";
          display: inline-block;
          position: absolute;
          margin-right: 100%;
          right: 10px;
        }
body {
  font-size: 1em;
    font-family: lato
}
ol {
  display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  padding-inline-start: 40px;
}
ol li {
  display: block;
    position: relative;
    margin: 1em 0em;
}
ol ol {
  margin-block-start: 0px;
  margin-block-end: 0px;
}
        .custom-list li > span {
          display: block;
          margin-left: 40px; /* Add left margin to align text */
          text-indent: -40px; /* Adjust text indentation */
          padding-left: 40px; /* Add padding to align the second line */
        }
      `}</style>
      <div>
        <h1 className="text-center">HYDROPONIC FARM DEVELOPMENT AGREEMENT</h1>
        <p>
          This hydroponic farm development agreement (“Agreement”) is signed and
          executed at Pune on ________________ (“Effective Date”), by and
          between:
        </p>

        <p>
          Mr/Ms/Mrs V R Chaudhary & Sons having Aadhaar No. 7645875634235645 AND
          PAN No. billing address at #98, Basti, Lucknow, Utter Pradesh and farm
          address at #98, Basti, Lucknow, Utter Pradesh, (hereinafter referred
          as “Farmer” which term shall, unless repugnant to the context and
          meaning thereof, mean and include his/her legal heirs, successor,
          administrator and permitted assigns) being Party of the FIRST PART;
          Contact No. 9876543210, Email Id:- vrchaudhary@gmail.com
        </p>
        <p className="text-center">AND</p>
        <p>
          Kryzen Biotech Pvt. Ltd., a company incorporated under Companies Act,
          2013 having its Office at C-505, The Onyx, Kalate Business Park S.No.
          186, near Euro School, Wakad, Pune, Maharashtra 411057 (hereinafter
          referred to as the “Agri Infra Provider/ KBPL/ Company” which term
          shall, unless repugnant to the context and meaning thereof, mean and
          include its successors, administrator and permitted assigns) being
          Party of the SECOND PART;
        </p>
        <p>WHEREAS:</p>
        <ol>
          <li>
            The Farmer holds agricultural land admeasuring 5000 sq. ft. situated
            at #98, Basti, Lucknow, Utter Pradesh, and carries on farming
            activities thereon; and
          </li>
          <li>
            The Agri Infra Provider is carrying out agriculture-related
            construction activity with material to facilitate Vertical and/ or
            Horizontal farming based on specifications provided by the Farmer
            resulting in exponential growth with high-quality yield; and
          </li>
          <li>
            The Farmer is desirous of availing the services of the Agri Infra
            Provider and therefore approached the Agri Infra Provider; and
          </li>
          <li>
            The Agri Infra Provider has agreed to assist the Farmer by providing
            its Services on the terms and conditions as herein below mentioned.
          </li>
        </ol>
        <p>NOW, THEREFORE, THE PARTIES HEREBY AGREE AS FOLLOWS:</p>
      </div>
      <div style={{ margin: "20px" }}>
        <ol className="custom-list">
          <li>
            <u>Services</u>
            <ol>
              <li>
                In consideration of the Fees, KBPL shall apply its expertise and
                technology to develop a hydroponic farm as per the location and
                quality agreed upon mutually, and as per the specifications in
                the quotation.
              </li>
              <li>
                KBPL will develop a fully operational hydroponic farm setup in
                NVPH with A-Frame structure.
              </li>
              <li>
                KBPL shall provide the following additional services to the
                Farmer:
                <ol>
                  <li> Tracking of progress on ‘alpha.kryzen.com’</li>
                  <li> Farm management support</li>
                  <li> Agronomist support</li>
                  <li> Employee training support</li>
                  <li> Price tracking support</li>
                  <li> Buyers connect support</li>
                  <li> AMC support</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <u>Fees and Payment</u>
            <ol>
              <li>
                The Farmer shall pay to KBPL a Fees of ₹15,63,500.00 (including
                GST), in a phased manner as mentioned in quotation.
              </li>
              <li>
                The Fees shall be paid by electronic funds transfer (or as
                otherwise agreed) within 15 (fifteen) days of the receipt of
                invoice by the Farmer. Payment shall only be deemed to have been
                effected when KBPL’s account has been fully and irrevocably
                credited.
              </li>
              <li>
                Fees is exclusive of all taxes, duties, fees, interest or other
                charges of any nature, including but not limited to value added
                taxes, Goods and Services taxes, sales, transfer, turnover, use
                or any other taxes, import, export, customs or any other duties
                or fees, administrative fees, or any other similar charges.
              </li>
            </ol>
          </li>
          <li>
            <u> Farmer Covenants</u> <br />
            <span> The Farmer undertakes and covenants as under:</span>
            <ol>
              <li>
                The farmer agrees to provide:{" "}
                <ol>
                  <li> 5000 sq. ft. of levelled land with clear title.</li>
                  <li>
                    Wall compound or fencing or security mechanism to protect
                    labours and setup material from theft.
                  </li>
                  <li>
                    Basic land foundation material – levelling soil, stone grit
                    1, Grit 2, metal facilitates, cement or stones with JCB
                    (only if required for land).
                  </li>
                  <li>
                    Minimum 15,000 litres per day of water supply during
                    plumbing work.
                  </li>
                  <li>
                    Arrange for an alternative power supply through any other
                    source like DG set and shall bear the costs towards the
                    rentals and fuel, in case of unavailability of continuous
                    power supply.
                  </li>
                  <li>Security of the land, polyhouse and its contents.</li>
                  <li> Labour quarters for workers.</li>
                  <li>Clear and easy motor-able access road to the site.</li>
                </ol>
              </li>
              <li>
                Farmer shall be responsible to take adequate and necessary steps
                to get available water and electricity supply for the project.
              </li>
              <li>
                Grant access to the KBPL to utilise any natural/artificial or
                running / still water body (i.e. river, lake, pond, well, canals
                etc.) adjacent to or within the said land for the purpose of
                developing the said project and the Farmer shall have the
                preferential right and access to the same. The KBPL may utilize
                the said water body only after fulfilling the quantity of water
                required by a farmer for the operation of the project. The
                farmer agrees to give his free consent to that effect and shall
                not dispute the same at any time in the future.
              </li>
              <li>
                The Farmer shall provide 3 phase power supply for the fan pad
                and a single-phase power supply for NVPH through the local
                government/local electricity board at the project location for
                at least 12 hours per day & bear the entire cost of power supply
                during the tenure of the agreement.
              </li>
              <li>
                To arrange for an alternative power supply through any other
                source like DG set and shall bear the costs towards the rentals
                and fuel, in case of unavailability of power supply as specified
                above as well as in case of unavailability of basic power supply
                (single phase) beyond 12 hours per day at the Project
                location/Land.
              </li>
              <li>
                To arrange alternative water supply and bear the costs towards
                the alternative water supply arrangement in case of insufficient
                water supply for project setup.
              </li>
              <li>
                Shall also ensure the safety of the property, personnel and
                material deployed at the Project location.
              </li>
              <li>
                To be solely responsible for providing vacant possession of the
                land on which the project will be erected and shall keep KBPL
                indemnified against any claims and damages made in relation to
                the land. If any dispute arises regarding the land and thereby
                disrupts the functioning of the project, immediate relief must
                be given by the Farmer at his own cost to KBPL.
              </li>
              <li>
                To provide and facilitate basic land foundation material –
                bricks, stone, grit 1, grit 2, metal, cement or stones with JCB
                (only if required for land) from the local market. The
                payment/charges towards the same shall be become by the Farmer.
              </li>
              <li>
                Undertake all such precautions and measures to avoid all and any
                kind of safety hazards and may ensure the entire project at its
                own discretion during setup.
              </li>
              <li>
                The Farmer agrees and accepts that the responsibility of
                insurance of Agriculture infrastructure is on him and he may
                insure the same.
              </li>
            </ol>
          </li>
          <li>
            <u> KBPL Covenants</u>
            <br />
            <span>KBPL undertakes and covenants as under:</span>
            <ol>
              <li>
                Agree that the Farmer has the absolute and exclusive right to
                use the project area for the purpose of agriculture and shall
                not use the said project area for any purpose other than the
                purpose of this Agreement at any time during the tenure of this
                Agreement.
              </li>
              <li>
                Not sell or create any third party rights to the said land,
                during the tenure of this Agreement, which affects the said
                Agreement or the functioning of the said project.
              </li>
              <li>
                KBPL agrees that the Farmer shall be the sole beneficiary of all
                Government subsidies arising out of the said project.
              </li>
              <li>
                Agrees and accepts that the ownership of the crop arising from
                the vertical and or horizontal farming carried out on the
                Agriculture land shall belong to the Farmer.
              </li>
            </ol>
          </li>
          <li>
            <u>Term and Termination of Agreement</u>
            <ol>
              <li>
                This Agreement will commence on the Effective Date and continue
                to remain in force unless terminated in accordance with this
                Agreement.
              </li>
              <li>
                {" "}
                Either party may terminate this Agreement by written notice to
                the other, where the other party commits a breach of any term of
                this Agreement and (to the extent that it can be remedied) fails
                to remedy that breach within a period of 30 days after being
                notified in writing to do so
              </li>
              <li>
                On the termination of this Agreement for any reason:
                <ol>
                  <li>
                    each party retains its accrued rights in respect of any
                    breach by the other party; and
                  </li>
                  <li>
                    the Farmer must pay the Fees and any costs incurred by KBPL
                    (including material and equipment costs) in connection with
                    the Services provided up to the date of termination.
                  </li>
                </ol>
              </li>
              <li>
                Unless provided under this Agreement, any clauses which by their
                nature are intended to survive the termination of this Agreement
                will remain in force even after the termination of this
                Agreement.
              </li>
            </ol>
          </li>
          <li>
            <u> Confidential information</u>
            <ol>
              <li>
                Any information pertaining to either Party’s business or
                activities, to which the other Party is exposed as a result of
                the relationship contemplated by this Agreement shall be
                considered to be “Confidential Information”. No Party shall use
                or disclose any confidential information to any person or entity
                (other than those who need to know such information for the
                purposes of this Agreement and have signed a confidentiality
                undertaking), except as required by law, without the express
                written consent of the affected Party.
              </li>
            </ol>
          </li>
          <li>
            <u> Intellectual Property Right</u>{" "}
            <ol>
              <li>
                At all times, the Intellectual property associated with
                processes, and technology, used during the project setup shall
                be and remain the sole property of KBPL.
              </li>
              <li>
                This Agreement does not create in or transfer to any person, any
                ownership, license, rights, or interest in the Confidential
                Information or the pre-existing Intellectual Property of the
                other Party.
              </li>
              <li>
                The Farmer agrees to indemnify KBPL against any loss or damage
                on account of any misuse, or unauthorised disclosure of any
                Intellectual Property Rights of KBPL during, or after
                termination of this Agreement.
              </li>
              <li>
                For the purposes of this Agreement, Intellectual Property means
                all present and future rights conferred under statute, common
                law or equity, title and interests in and to the patents,
                trademarks, service marks, trade names, registered designs,
                copyrights, rights of privacy and publicity and other forms of
                intellectual property, know-how, inventions, formulae,
                confidential or secret processes, trade secrets, any other
                protected rights or assets and any licenses and permissions in
                connection therewith, in each and any part of the world and
                whether or not registered or registrable and for the full period
                thereof, and all extensions and renewals thereof, and all
                applications for registration in connection with the foregoing
                and “Intellectual Property Rights” shall mean all rights in
                respect of the Intellectual Property.
              </li>
              <li>
                KBPL and/ or its Affiliates are authorized by the Farmer to
                display their name/sign board in front of the Farm and display
                its logo and any product brand names. KBPL shall have all the
                rights to use the project site as its demo location for
                marketing & promotional activities in the region.
              </li>
            </ol>
          </li>
          <li>
            <u>Force Majeure</u>{" "}
            <ol>
              <li>
                If a party is prevented from or delayed in performing an
                obligation under this Agreement (other than an obligation to pay
                money) (“Affected Party”) by a Force Majeure Event, the
                obligation is suspended during, but for no longer than, the
                period such Force Majeure Event continues, provided provided,
                however that: (1) such Force Majeure Event is beyond the control
                of the Affected Party and could not be prevented by appropriate
                precautions; (2) the Affected Party is diligently attempting to
                recommence performance.
              </li>
              <li>
                'Force Majeure Event' shall include but not be limited to{" "}
                <ol>
                  <li>
                    acts of God, including earthquake, storm, flood, tempest,
                    fire, lightning, and other natural calamities;
                  </li>
                  <li>civil commotion, war, act of public enemy;</li>
                  <li>riots or terrorist attacks, sabotage, epidemic</li>
                  <li>strikes;</li>
                  <li>
                    unavailability, scarcity, shortage of any construction
                    materials, labour, fuel, power, water electricity etc.
                  </li>
                  <li>
                    the Promulgation of or any amendment in any Law or Policy of
                    the Government authority or Acquisition of Land by the
                    Government which prevents the construction and development
                    to proceed as agreed in this Agreement.
                  </li>
                </ol>
              </li>
              <li>
                The Affected Party shall immediately notify the other party of
                the happening of any Force Majeure Event.
              </li>
            </ol>
          </li>
          <li>
            <u>Demise of the Farmer</u>
            <ol>
              <li>
                In the event of the demise of the Farmer, KBPL shall continue to
                execute the terms of this agreement during the remaining period
                of the Agreement. The Farmer’s legal heirs shall have the right
                to continue the agreement, unless both the parties agree to end
                the Agreement by mutual consent.
              </li>
              <li>
                It shall be the duty of the Nominee(s)/ Legal Heir(s) to
                complete the legal formalities (i.e., obtain Succession /
                Heirship Certificate, Death Certificate etc.,) and provide an
                authenticated copy of the same to KBPL to claim and authenticate
                his/ her right as a legal representative of the Farmer.
              </li>
              <li>
                It shall be the duty of the Nominee(s) / Legal heir(s) to
                complete pending obligations under this Agreement, on the part
                of the Farmer and shall have a right to claim benefits arising
                out of this Agreement.
              </li>
            </ol>
          </li>
          <li>
            <u> Limitation of Liability, Representations and Indemnity</u>
            <ol>
              <li>
                The Service is provided on an "as is" and “as available” basis,
                without any representations, conditions, warranties or covenants
                whatsoever with respect to the Service, including without
                limitation, any express, statutory or implied representations,
                warranties or conditions of merchantability, merchantable
                quality, satisfactory quality or fitness for a particular
                purpose, or arising otherwise in law or from a course of dealing
                or usage of trade, all of which are expressly disclaimed and
                excluded.
              </li>
              <li>
                The Farmer agrees to limit any and all liability or claim for
                damages, or expense, arising from any breach, error, omission or
                negligence by KBPL in the course of performing services under
                the Agreement, to a sum not exceeding the Fees paid to KBPL
                during the three-month period preceding such breach, error,
                omission or negligence.
              </li>
              <li>
                Neither party is liable to the other party in contract, tort,
                negligence, or breach of statutory duty.
              </li>
              <li>
                All warranties, conditions and other terms implied by statute or
                common law are excluded from this Agreement unless otherwise
                stated in this Agreement, to the fullest extent permitted by
                law.
              </li>
              <li>
                Either Party shall indemnify the other party from and against,
                any loss incurred by such party arising out of or in connection
                with a breach by the indemnifying party of its obligations under
                this Agreement; and any unlawful, negligent, or wrongful act or
                omission of the indemnifying party in connection with this
                Agreement.
              </li>
              <li>
                Each Party represents and warrants that it has and will continue
                to comply with all applicable laws, regulations and procedures
                while performing its obligations under this Agreement.
              </li>
              <li>
                The Farmer represents and warrants that he is the sole owner or
                authorised in accordance with law to fulfill the obligations
                under this Agreement, and further indemnifies the Company, its
                directors, officials, and employees from and against any third
                party action arising as a result of the breach of this warranty.
              </li>
            </ol>
          </li>
          <li>
            <u> Dispute Resolution</u>{" "}
            <ol>
              <li>
                In the event of a dispute between the parties, the parties agree
                in good faith to use all reasonable endeavours to settle the
                dispute. If the parties are unable to settle the dispute in good
                faith, the Parties agree to submit the same for arbitration to a
                sole arbitrator, mutually appointed by the Parties. In the event
                that the Parties cannot agree upon a sole arbitrator, each Party
                shall appoint an arbitrator and the two arbitrators shall
                mutually appoint the third presiding arbitrator.
              </li>
              <li>
                The arbitration proceedings shall be conducted in accordance
                with the Arbitration and Conciliation Act, 1996, India.
                Arbitration proceedings shall be conducted in the English
                language and the venue and seat shall be Pune, Maharashtra. The
                award of the arbitration proceedings shall be final and binding
                on the Parties.
              </li>
            </ol>
          </li>
          <li>
            <u> Miscellaneous Provisions</u>{" "}
            <ol>
              <li>
                Exclusivity: KBPL shall not be obligated to offer any business
                opportunities or to conduct business exclusively with the Farmer
                by virtue of this agreement.
              </li>
              <li>
                Relationship: Nothing contained or implied in this Agreement
                constitutes a party as a partner, agent or legal representative
                of the other party for any purpose, or creates any partnership,
                employment, agency, license or trust, or creates a tenancy of
                the Farm or a licence for the exclusive occupation of the same.
                Neither party has the authority to bind the other party in any
                way.
              </li>
              <li>
                Entire Agreement and Amendment: This agreement represents the
                entire agreement between the parties, and supersedes all prior
                agreements, whether oral or written with respect to the subject
                matter hereof. The parties agree that any variation to this
                Agreement requires to be in writing and signed by all the
                Parties.
              </li>
              <li>
                Law: This Agreement will be governed by the laws of India, and
                the parties irrevocably submit to the non-exclusive jurisdiction
                of the courts of Pune.
              </li>
              <li>
                Assignment: Neither this agreement nor any rights or obligations
                hereunder are transferable or assignable by one party without
                the express prior written consent of the other party. Any
                assignment or transfer in violation of this agreement shall be
                null and void.
              </li>
              <li>
                Notice: Any notice or other communication required or permitted
                by this agreement to be given to any party shall be in writing
                and shall be deemed given if delivered personally or by
                commercial messenger or courier service, or mailed by registered
                or certified mail (return receipt requested), to the party at
                the address provided above or at such other address as the party
                may have previously specified by intimating the other party in
                advance in writing.
              </li>
              <li>
                No Publicity: Without prior written approval of KBPL, the Farmer
                agrees that it shall not make public disclosures relating to
                this agreement, including its existence, the terms and any
                reference to any other party about the project and of its
                Confidential Information.
              </li>
              <li>
                Waiver: Any failure by either party to enforce the other party’s
                strict performance of any provision of this Agreement will not
                constitute a waiver of its right to subsequently enforce such
                provision or any other provision of this Agreement.
              </li>
              <li>
                Severability: Although the restrictions, covenants contained in
                this Agreement are considered by the parties to be reasonable
                and valid, if any such restriction, covenant is found by a court
                of competent jurisdiction to be unenforceable, such provision
                will be modified, rewritten or interpreted to include as much of
                its nature and scope as will render it enforceable. If it cannot
                be so modified, rewritten or interpreted to be enforceable in
                any respect, it will not be given effect, and the remainder of
                the Agreement will be enforced as if such provision was not
                included.
              </li>
              <li>
                All parties execute this Agreement as per their wish and desire
                and without any coercion and pressure and the same will be
                binding on both the parties including their legal heirs,
                executors, administrators, and assignees or any person claiming
                through them or on their behalf.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Agreement;
