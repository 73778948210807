import React from "react";
import kryzenLogo from "../../../certificates/kryzenLogo.png";
const KryzenTop = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          justifyItems: "center",
          alignItems: "center",
          width: "100%",

          padding: "0 7px",
        }}
      >
        <div
          style={{
            border: "none",
            textAlign: "center",
          }}
        >
          <img width={"200px"} src={kryzenLogo} alt={kryzenLogo} />
        </div>
        <div
          style={{
            border: "none",
            textAlign: "center",
          }}
        >
          <p style={{ textAlign: "right" }}>
            <b
              style={{
                fontSize: "1.5em",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Kryzen Biotech Private Limited
            </b>
            <br />
            GSTIN: 27AAHCK7659R1ZF | CIN: U01100PN2019PTC186207
            <br />
            M-319, City Avenue, Wakad, Pune, Maharashtra 411057
            <br />
            #98, At Po. Degaon, Wai, District Satara, Maharashtra 412803
            <br />
            <a
              href="https://www.kryzen.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.kryzen.com
            </a>
            |
            <a
              href="tel:+919870424425"
              target="_blank"
              rel="noopener noreferrer"
            >
              +91-9870-424-425
            </a>{" "}
            |
            <a
              href="mailto:sales@kryzen.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              sales@kryzen.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default KryzenTop;
