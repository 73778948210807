import React, { useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { InventoryCreateInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { InventoryCreateSchema } from "../../../Schemas/resources/inventory_create_schema";
import { uploadImage } from "../../../CustomComponent/upload_Image";

import { FcPackage } from "react-icons/fc";
import { BsSave2 } from "react-icons/bs";

const AddInventory = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handelSubmit = (values, actions) => {
    values = {
      ...values,
      genratedBy: userData?.UserId,
    };
    setLoading(true);
    performRequest("POST", "/inventory/createInventory/", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Inventory created successfully!");
          navigate(`/resources-inventory`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcPackage className="fs-2  me-1" /> Track your Inventory
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Track & manage your inventory.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/resources-inventory"} className="btn btn-primary">
                    {" "}
                    <BsSave2 className="fs-3 me-2" /> Inventory Item List
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <p>
                  When you add new Inventory. Make sure you add bill of purchase
                  also do not forget to make sure make and specifications of
                  inventory is mentioned in bill. In case it is not mentioned in
                  bill add it seperately in Notes. This will help agronomy team
                  to help you manage your farm effectively.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={InventoryCreateInfo}
                  validationSchema={InventoryCreateSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Resource / Inventory name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="assetName" type="text" />
                            {props.touched.assetName &&
                              props.errors.assetName && (
                                <p className="text-danger">
                                  {props.errors.assetName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Company name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="companyName" type="text" />
                            {props.touched.companyName &&
                              props.errors.companyName && (
                                <p className="text-danger">
                                  {props.errors.companyName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Vendor
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="vendor" type="text" />
                            {props.touched.vendor && props.errors.vendor && (
                              <p className="text-danger">
                                {props.errors.vendor}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 ">
                            Refill frequency
                          </label>
                          <div className="w-100 col">
                            <div className="d-flex">
                              <CustomSelect name="refillFrequency" type="text">
                                <option value="">Select</option>
                                {[...Array(32).keys()].map((month) => (
                                  <option key={month} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </CustomSelect>
                              <CustomSelect
                                name="refillFrequencyType"
                                type="text"
                              >
                                <option value="">Select Type</option>
                                <option value="days">Days</option>
                                <option value="months">Months</option>
                                <option value="year">Year</option>
                              </CustomSelect>
                            </div>
                            {props.touched.refillFrequency &&
                              props.errors.refillFrequency && (
                                <p className="text-danger">
                                  {props.errors.refillFrequency}
                                </p>
                              )}
                            {props.touched.refillFrequencyType &&
                              props.errors.refillFrequencyType && (
                                <p className="text-danger">
                                  {props.errors.refillFrequencyType}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Payment amount
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="paymentAmount" type="text" />
                            {props.touched.paymentAmount &&
                              props.errors.paymentAmount && (
                                <p className="text-danger">
                                  {props.errors.paymentAmount}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 ">
                            Payment mode
                          </label>
                          <div className="w-100 col">
                            <CustomSelect name="paymentMode" type="text">
                              <option value="">Select</option>
                              <option value="cash">Cash</option>
                              <option value="cheque">Cheque</option>
                              <option value="upi">UPI</option>
                              <option value="imps">IMPS</option>
                              <option value="rtgs">RTGS</option>
                              <option value="neft">NEFT</option>
                            </CustomSelect>
                            {props.touched.paymentMode &&
                              props.errors.paymentMode && (
                                <p className="text-danger">
                                  {props.errors.paymentMode}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Upload bill
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="uploadBill"
                              type="file"
                              onChange={(e) =>
                                uploadImage(
                                  props,
                                  "uploadBill",
                                  e.target.files[0]
                                )
                              }
                            />
                            {props.touched.uploadBill &&
                              props.errors.uploadBill && (
                                <p className="text-danger">
                                  {props.errors.uploadBill}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 ">
                            Notes / Ref.no
                          </label>

                          <div className="w-100 col">
                            <CustomTextArea type="text" name="notes" />

                            {props.touched.notes && props.errors.notes && (
                              <p className="text-danger">
                                {props.errors.notes}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Create Inventory" />

                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddInventory;
