import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import { useSelector } from "react-redux";
import { ReimbursementInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";

import { BsReceiptCutoff } from "react-icons/bs";
import { FcCalculator } from "react-icons/fc";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import {
  getUplodedImage,
  uploadImage,
} from "../../../CustomComponent/upload_Image";
import { getId } from "../../../Components/Functions/word.fun";
import { ReimbursementSchema } from "../../../Schemas/employee/reimbursement";
import CustomRadioButton from "../../../CustomComponent/custom_radio";
const EditReimbursement = () => {
  const store = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [reimbursementInfo, setReimbursementInfo] = useState(ReimbursementInfo);
  const { id } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (reimbursementInfo) {
      if (reimbursementInfo?.addRecipt_bill) {
        getUplodedImage(
          setImageUrls,
          reimbursementInfo?.addRecipt_bill,
          "addRecipt_bill"
        );
      }
    }
  }, [reimbursementInfo]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/reimbursement/getReimbursementById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setReimbursementInfo(res.data);
        } else {
          Toast("error", "Error while getting Incentive Information!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  const handelSubmit = (values) => {
    values = {
      ...values,
      amount: +values.amount,
    };
    setLoading(true);
    performRequest("PATCH", `/reimbursement/updateReimbursement/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Reimbursement updated successfully!");
          navigate(`/reimbursement-management`);
        } else {
          Toast("error", "Error while updating reimbursement");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcCalculator className="fs-2  me-1" /> Reimbursement
                  Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Request reimbursements.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/reimbursement-management"}>
                    <button className="btn btn-primary">
                      <BsReceiptCutoff className="me-2 fs-2" /> View All
                      Reimbursement
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <h3>Reimbursement Policy</h3>
                <p>Recipts uploads are must for reimbursements.</p>
                <p>
                  Reimbursement above ₹2500 needs prior approval before
                  expanditure.
                </p>
                <p>
                  Reimbursement amount can not be exchanged, traded or
                  transferred to other employee or person.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={reimbursementInfo}
                  validationSchema={ReimbursementSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Select project
                          </label>

                          <div className="w-100 col">
                            <CustomSelect type="text" name="selectProject">
                              <option value="">Select</option>
                              {projects &&
                                projects?.map((pro) => {
                                  return (
                                    <option
                                      key={pro.cpNumber}
                                      value={getId(pro.cpNumber)}
                                    >
                                      {pro.clientName}
                                    </option>
                                  );
                                })}
                            </CustomSelect>
                            {props.touched.selectProject &&
                              props.errors.selectProject && (
                                <p className="text-danger">
                                  {props.errors.selectProject}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Amount
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="amount" />
                            {props.touched.amount && props.errors.amount && (
                              <p className="text-danger">
                                {props.errors.amount}
                              </p>
                            )}
                            <p className="text-muted">
                              Amount mentioned here should be reflected in KBPL
                              bank account.
                            </p>
                          </div>
                        </div>
                        {imageUrls?.addRecipt_bill && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3 required">
                              Recipt bill uploded
                            </label>
                            <div className="w-100 col">
                              <img
                                src={imageUrls?.addRecipt_bill}
                                alt={props?.values?.addRecipt_bill}
                              />
                            </div>
                          </div>
                        )}
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Recipt bill
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="addRecipt_bill"
                              type="file"
                              onChange={(e) =>
                                uploadImage(
                                  props,
                                  "addRecipt_bill",
                                  e.target.files[0]
                                )
                              }
                            />
                            {props.touched.addRecipt_bill &&
                              props.errors.addRecipt_bill && (
                                <p className="text-danger">
                                  {props.errors.addRecipt_bill}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Mode of payment
                          </label>

                          <div className="w-100 col">
                            <CustomSelect type="text" name="modeOfPayment">
                              <option value="">Select</option>
                              <option value="cash">Cash</option>
                              <option value="nft">NFT</option>
                              <option value="cheque">Cheque</option>
                            </CustomSelect>
                            {props.touched.modeOfPayment &&
                              props.errors.modeOfPayment && (
                                <p className="text-danger">
                                  {props.errors.modeOfPayment}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Reimbursements description
                          </label>

                          <div className="w-100 col">
                            <CustomTextArea
                              name="reimbursementsDescription"
                              rows={5}
                            />
                            {props.touched.reimbursementsDescription &&
                              props.errors.reimbursementsDescription && (
                                <p className="text-danger">
                                  {props.errors.reimbursementsDescription}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Paid status
                          </label>

                          <div className="w-100 col">
                            <div>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="paidStatus"
                                  value="pending"
                                  checked={props.values.paidStatus == "pending"}
                                />
                                <span className="form-check-label">
                                  Pending
                                </span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="paidStatus"
                                  value="aproved"
                                  checked={props.values.paidStatus == "aproved"}
                                />
                                <span className="form-check-label">
                                  Aproved
                                </span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="paidStatus"
                                  value="paid"
                                  checked={props.values.paidStatus == "paid"}
                                />
                                <span className="form-check-label">Paid</span>
                              </label>
                            </div>

                            {props.touched.paidStatus &&
                              props.errors.paidStatus && (
                                <p className="text-danger">
                                  {props.errors.paidStatus}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Save Reimbursement" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default EditReimbursement;
