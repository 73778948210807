import React from "react";
import StepRoutes from "../../../Routes/steps_routes";
import Footer from "../../../Components/Footer/footer";
import { MarketingList } from "../../../SchemaInfo/info";
import { MarketingListSchema } from "../../../Schemas/Marketing List/add_marketingList";
import { Formik, Form } from "formik";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import CustomSelect from "../../../CustomComponent/custom_select";
import CustomInput from "../../../CustomComponent/custom_input";

const CreateCampaign = () => {
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Marketing System</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Campaign</h2>
            </div>
            <Formik
              initialValues={MarketingList}
              validationSchema={MarketingListSchema}
              //   onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Select marketing list
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="MarketingList"
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option>All</option>
                          <option>Quotation Received</option>
                          <option>Hydroponic Masterclass</option>
                          <option>Office Visit</option>
                          <option>Farm Visit</option>
                          <option>Meetup Attend</option>
                          <option>Farmtraining Done</option>
                          <option>Site Inspection Done</option>
                          <option>Project Done</option>
                          <option>Repeat Customer</option>
                          <option>Watsapp Message Sent</option>
                          <option>Email Funnel Added</option>
                          <option>Paid Customer</option>
                          <option>Organic Lead</option>
                        </CustomSelect>
                        {props.touched.MarketingList &&
                          props.errors.MarketingList && (
                            <p className="text-danger">
                              {props.errors.MarketingList}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Select marketing campaign
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="MarketingCampaign"
                          className="form-control"
                        >
                          <option value="">Select</option>
                          {/* {indianStates.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))} */}
                        </CustomSelect>
                        {props.touched.MarketingCampaign &&
                          props.errors.MarketingCampaign && (
                            <p className="text-danger">
                              {props.errors.MarketingCampaign}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Select threshold
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="Thresholds"
                          className="form-control"
                        >
                          <option value="">Select</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </CustomSelect>
                        {props.touched.Thresholds &&
                          props.errors.Thresholds && (
                            <p className="text-danger">
                              {props.errors.Thresholds}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Schedule date
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="date" name="ScheduleDate" />
                        {props.touched.ScheduleDate &&
                          props.errors.ScheduleDate && (
                            <p className="text-danger">
                              {props.errors.ScheduleDate}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Campaign" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CreateCampaign;
