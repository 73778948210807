import * as yup from "yup";

export const ProjectSpecificationSchema = yup.object().shape({
  project: yup.string().required("Required"),
  ceaType: yup.string().required("Required"),
  psBaySize: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf(
      [
        8,
        12,
        16,
        20,
        24,
        28,
        32,
        36,
        40,
        44,
        48,
        52,
        56,
        60,
        64,
        64,
        68,
        72,
        76,
        80,
        84,
        88,
        92,
        96,
        100,
        104,
        108,
        112,
        116,
        120,
        124,
        128,
        132,
        136,
        140,
        144,
        null,
      ],
      "Invalid Type"
    )
    .required("Required"),
  psSpanSize: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf(
      [
        8,
        12,
        20,
        28,
        36,
        44,
        52,
        60,
        68,
        76,
        84,
        92,
        100,
        108,
        116,
        124,
        132,
        140,
        148,
        156,
        164,
        172,
        180,
        188,
        196,
        204,
        212,
        220,
        228,
        236,
        244,
        252,
        260,
        268,
        276,
        null,
      ],
      "Invalid Type"
    )
    .required("Required"),
  aFramePercent: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf([0, 25, 50, 75, 100, null], "Invalid Type"),
  flatBedPercent: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf([0, 25, 50, 75, 100, null], "Invalid Type"),
  growBagPercent: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf([0, 25, 50, 75, 100, null], "Invalid Type"),
  dutchBucketPercent: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf([0, 25, 50, 75, 100, null], "Invalid Type"),
  hocky: yup.boolean(),
  hockyPresense: yup.array().of(yup.string()),
  notes: yup.string(),
});
