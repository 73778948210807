import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";

const ViewPurchaseOrder = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/poDcPi/getPoDcPiById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Purchase Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">View Purchase Order</h2>
            </div>

            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Vendor</label>
                  <div className="w-100 col">
                    {(data?.vendor || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Vendor purchase order for
                  </label>
                  <div className="w-100 col">
                    {(data?.vendorPurchaseOrderFor || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Vendor purchase order date
                  </label>
                  <div className="w-100 col">
                    {(data?.vendorPurchaseOrderDate || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Project</label>
                  <div className="w-100 col">
                    {(data.project || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Country of supply
                  </label>
                  <div className="w-100 col">
                    {(data?.countryOfSupply || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Place of supply
                  </label>
                  <div className="w-100 col">
                    {(data?.placeOfSupply || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              <hr />
              <h2>Line Items</h2>
              <hr />
              {data &&
                data.lineItems?.map((lineItem, index) => {
                  return (
                    <div>
                      <h2>{++index}.</h2>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Particulars
                        </label>
                        <div className="w-100 col">
                          {(lineItem?.particulars || "N/A").toUpperCase()}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Item unit
                        </label>
                        <div className="w-100 col">
                          {(lineItem?.itemUnit || "N/A").toUpperCase()}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Item quantity
                        </label>
                        <div className="w-100 col">
                          {(lineItem?.itemQuantity || "N/A").toUpperCase()}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Item price:
                        </label>
                        <div className="w-100 col">
                          {(lineItem?.itemPrice || "N/A").toUpperCase()}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          GST rate (%)
                        </label>
                        <div className="w-100 col">
                          {(lineItem?.gstRate || "N/A").toUpperCase()}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Discount (%)
                        </label>
                        <div className="w-100 col">
                          {(lineItem?.discount || "N/A").toUpperCase()}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Delivery date
                        </label>
                        <div className="w-100 col">
                          {(lineItem?.deliveryDate || "N/A").toUpperCase()}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Total without gst
                        </label>
                        <div className="w-100 col">
                          {lineItem?.totalWithoutGst || "N/A"}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Total with gst
                        </label>
                        <div className="w-100 col">
                          {lineItem?.totalWithGst || "N/A"}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">Notes</label>
                        <div className="w-100 col">
                          {(lineItem?.notes || "N/A").toUpperCase()}
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText={"generate pdf"}
                  href={`/purchase-order/preview/${id}`}
                />
                <SimpleButton
                  buttonText="Edit Purchase Order"
                  href={`/purchase-order/edit/${id}`}
                />

                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewPurchaseOrder;
