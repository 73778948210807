import React, { useEffect } from "react";

const ElectricFittings = ({ data }) => {
  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "-2px",
    fontSize: "13px",
    borderCollapse: "collapse",
    padding: "0px 3px",
    paddingTop: "-2px",
  };
  useEffect(() => {
    const TR = document.getElementsByTagName("tr");
    const TH = document.getElementsByTagName("th");
    const TD = document.getElementsByTagName("td");
    const H3 = document.getElementsByTagName("h3");

    Array.from(TR).forEach((tr) => {
      Object.assign(tr.style, style);
    });
    Array.from(TH).forEach((th) => {
      Object.assign(th.style, style);
    });
    Array.from(TD).forEach((td) => {
      Object.assign(td.style, style);
    });
    Array.from(H3).forEach((h3) => {
      Object.assign(h3.style, {
        marginTop: "14px",
        marginBottom: "2px",
      });
    });
  }, []);
  let serial = 1;
  return (
    <div>
      <h3 className="table-heading">Electric Fittings:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{serial++}.</td>
            <td>Water pumps</td>
            <td>
              Multiple water pumps of different capacities to lift water from
              reservoir. Actual capacity to be determined during site
              inspection.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Fogger pump</td>
            <td>
              Fogger pump of 1.5/2hp (as per the area of land) to build adequate
              pressure on foggers to operate smoothly.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Digital / Analogue timers</td>
            <td>
              Digital / Analogue timer to maintain day-to-day operations at set
              intervals.
            </td>
          </tr>

          <tr>
            <td>{serial++}.</td>
            <td>Connection to DG / Solar panel</td>
            <td>
              Entire system connection to diesel generator / flex generator or
              solar panel (if made available by you).
            </td>
          </tr>
          {/* <% if record.quotation_type_fan_pad? %> */}
          <tr>
            <td>{serial++}.</td>
            <td>Temp &amp; Humidity sensor to CP (Applicable to Fan-Pad)</td>
            <td>
              Connect multiple temp and humidity sensors to the control panel.
            </td>
          </tr>
          {data?.quotationType !== "fanpad" &&
            data?.quotationType !== "nvph" &&
            data?.quotationType !== "nethouse" && (
              <tr>
                <td>{serial++}.</td>
                <td>Submersible pump</td>
                <td>3 Phase submersible pump connection to control panel.</td>
              </tr>
            )}
          <tr>
            <td>{serial++}.</td>
            <td>Exhaust fan (Applicable to Fan-Pad)</td>
            <td>Exhaust fan connection to control panel.</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Air circulation fan (Applicable to Fan-Pad)</td>
            <td>
              Connect air circulation fan to timer as well as sensor based
              module at control panel.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default ElectricFittings;
