import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { extractDate } from "../../../Components/Functions/date.fun";
import { BsArrowRightShort } from "react-icons/bs";
const tableHead = [
  "Sr.",

  "date",
  "time",
  "logout time",
  "name",
  "login Status",
  // "operatingSystem",
  // "logout operatingSystem",
  // "ipAddress",
  // "logout ipAddress",
  "Actions",
];

const AttendanceTable = ({ Attendance = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {Attendance?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>

                <td>
                  {item?.createdAt ? extractDate(item?.createdAt) : "N/A"}
                </td>
                <td>{item?.time || "N/A"}</td>
                <td>{item?.logoutTime || "N/A"}</td>
                <td>{item?.userName || "N/A"}</td>
                <td>{item?.loginStatus || "N/A"}</td>
                {/* <td>{item?.operatingSystem || "N/A"}</td> */}
                {/* <td>{item?.logoutOperatingSystem || "N/A"}</td> */}
                {/* <td>{item?.ipAddress || "N/A"}</td>
                <td>{item?.logoutIpAddress || "N/A"}</td> */}

                <td>
                  <button
                    className="btn btn-sm px-3 btn-primary"
                    onClick={() => navigate(`/employee-attendance/${item.id}`)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceTable;
