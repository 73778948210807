import React from "react";
import { useNavigate } from "react-router-dom";
const tableHead = [
  "Sr.",
  "Project",
  "Handover Date",
  "Site Manager",
  "Actions",
];
const ProjectHandoverTable = ({ PH = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();

  const goToView = (id) => {
    navigate(`/client-project-handover/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {PH?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.clientName || "N/A"}</td>
                <td>{item?.handOverDate || "N/A"}</td>
                <td>{item?.siteManagerName}</td>
                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectHandoverTable;
