import React from "react";

const AFrameSetup = () => {
  let serial = 1;
  return (
    <div>
      <h3>A-Frame-NFT Setup (Nutrient Film Technique):</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serial++}</td>
            <td>A shape stands</td>
            <td>
              7 layer A frame stand of square pipes. Made in three sizes and
              decided based on temprature and humidity to which size to choose
              from.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Pipe dimensions</td>
            <td>Double layer square pipe of dimension 100mm x 50mm.</td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>NFT Pipes, end caps and accessories</td>
            <td>
              20ft long square pipes, with holes in each pipe (or as per the
              final dimensions)
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Length of pipe</td>
            <td>
              Length of pipe is decided on available area, possible pathway and
              slope of land inside polyhouse. NFT pipe modifications are done at
              factory end as per primary available data from inspection team
              report.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>A frame levels</td>
            <td>
              Each A shape stand has 7 levels with a gap between two pipes. Gap
              between pipes determined by sunlight exposure in the region.
            </td>
          </tr>

          <tr>
            <td>{serial++}</td>
            <td>Frame structure height</td>
            <td>
              Each A shape frame has a height of up to 10ft including
              foundation. Height can vary from 9.5ft to 10.5ft based on slope of
              land and obtaining flow of water.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Distance between frames</td>
            <td>
              Two A shape frames will have up to 3 to 4 ft distance between each
              other. This distance may vary as per slope of land and required
              water flow.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Walking distance</td>
            <td>
              Approximately 3.5 to 4.5 ft. walking distance will be maintained.
              So one labour with a crate can move freely.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Inter pipe connectivity</td>
            <td>
              All NFT pipes will be connected with each other to control water
              flow, flexible pipes will have replaceable fittings for easy
              maintenance of the system.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Water outlet</td>
            <td>
              Outlet water is collected through a closed pvc pipe and fed back
              to the recirculation piping system.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Stand coloring</td>
            <td>
              All stands will be coated with two layers of white/black colour to
              avoid rusting and also reflect sunlight.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Pipe cleaning mechanism</td>
            <td>
              All square pipes have side slit and can be opened from top for
              cleaning.
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Nutrient flow</td>
            <td>
              All square pipes come with guidance lines for proper nutrient
              flow.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AFrameSetup;
