import React from "react";
import CommingSoon from "../Components/layouts/comming.soon";

const ContactUs = () => {
  return (
    <div>
      <CommingSoon />
    </div>
  );
};

export default ContactUs;
// 