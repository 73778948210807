import React from "react";

import iot from "../../../certificates/iot.jpg";
const SoftwareServices = () => {
  return (
    <div>
      <h3 className="table-heading">Software Services (Kryzen Ecosystem):</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td>Price watcher</td>
            <td>
              Track trend of different comodities across various markets in
              India and take informed decision about what to plant in order to
              achive maximum return on investment.
            </td>
          </tr>
          <tr>
            <td>02.</td>
            <td>Nursery management</td>
            <td>
              Get complete controlled and 2 way binding integration on nursery
              management to schedule, plan and execute nursery management.
            </td>
          </tr>
          <tr>
            <td>03.</td>
            <td>Farm management</td>
            <td>
              Manage entire farm operations wih proper book keeping and record
              management along with task management and resoirce allotment on
              one spot at farm management.
            </td>
          </tr>
          <tr>
            <td>04.</td>
            <td>Plant wiki</td>
            <td>
              Get complete details about plants you are planning to grow in your
              hydroponic system along with crucial details related germination
              to growing, harvesting to packaging.
            </td>
          </tr>
          <tr>
            <td>05.</td>
            <td>Work force management</td>
            <td>
              Manage all your work force, attendance and salary at one place.
              along with auto attendance system.
            </td>
          </tr>
          <tr>
            <td>06.</td>
            <td>Buyers connect</td>
            <td>
              Connect with your nearest buyers and sell your comodities to them
              at best pricing. We have 2000+ buyers across India registered on
              our platform.
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          display: "flex",

          justifyContent: "center",
          width: "100%",
          marginTop: "12px",
        }}
      >
        <img src={iot} width={"80%"} alt="kryzenCertificates" />
      </div>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default SoftwareServices;
