import React, { useEffect, useState } from "react";
import { FcLandscape } from "react-icons/fc";
import Footer from "../../Components/Footer/footer";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import StackedBarChart from "./Dashbourd Components/bar_chart_leads";
import LoadingPage from "../../Components/layouts/loading";
import EnquiryChart from "./Dashbourd Components/enquiry_chart";
import SalesWorkTracking from "./Dashbourd Components/sales_work_tracking";
import EmployeeWorkData from "./Dashbourd Components/employee_daily_work";
import { useSelector } from "react-redux";
import FarmConstructionAgreement from "../Farm Management/farm_construction_agreement";
import AttendanceChart from "./Dashbourd Components/attendance_chart";
import LineChart from "./Dashbourd Components/line_chart_tracking";
import Kryzen38Days from "../../certificates/Kryzen38Days.pdf";
import AttendanceTracking from "./Dashbourd Components/attendance_tracking";
import { useNavigate } from "react-router";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import { LeadInfo } from "../../SchemaInfo/info";
import SimpleButton from "../../Components/layouts/simple_button";
import DashboardLeaderBoard from "./Dashbourd Components/dashboard_leaderboard";
import { FcCustomerSupport } from "react-icons/fc";
import { FcCallback } from "react-icons/fc";
import { FcSignature } from "react-icons/fc";
import { FcWorkflow } from "react-icons/fc";
import { FcRules } from "react-icons/fc";

import { FcRatings } from "react-icons/fc";

const getTaskListDetails = (task = []) => {
  let totalTasks = task.length;
  let completedTasks = task?.filter((t) => t.isUpdated || t.isNotApplicable);

  return `${completedTasks.length}/${totalTasks}`;
};
const getTaskCompleteProgress = (task = []) => {
  let totalTasks = task.length;
  let completedTasks = task?.filter((t) => t.isUpdated || t.isNotApplicable);
  let width = (completedTasks.length / totalTasks) * 100 || 0;
  return `${width}%`;
};
const getTaskCompleteCount = (task = []) => {
  let completedTasks = task?.filter((t) => t.isUpdated || t.isNotApplicable);

  return completedTasks.length;
};
const getName = (lead) => {
  if (lead.fullName) {
    return lead.fullName;
  }
  if (lead.firstName && lead.lastName) {
    return `${lead.firstName} ${lead.lastName}`;
  }
  if (lead.firstName) {
    return lead.firstName;
  }
  if (lead.lastName) {
    return lead.lastName;
  }
  return "N/A";
};

const Dashbourd = () => {
  const months = [
    { name: "January", value: 0 },
    { name: "February", value: 1 },
    { name: "March", value: 2 },
    { name: "April", value: 3 },
    { name: "May", value: 4 },
    { name: "June", value: 5 },
    { name: "July", value: 6 },
    { name: "August", value: 7 },
    { name: "September", value: 8 },
    { name: "October", value: 9 },
    { name: "November", value: 10 },
    { name: "December", value: 11 },
  ];

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  const store = useSelector((store) => store.login.user);

  const [currentUser, setCurrentUser] = useState(store.Name);
  const [employee, setEmployee] = useState(
    store && store.Role === process.env.REACT_APP_ROLE_ADMIN_S
      ? "All"
      : store.Name
  );
  const [employeeName, setEmployeeName] = useState(
    (store && store.Role == process.env.REACT_APP_ROLE_ADMIN_S) ||
      store.Role == process.env.REACT_APP_ROLE_ADMIN
      ? "All"
      : store.Name
  );
  const [leadsFrom, setLeadsFrom] = useState(30);
  const [enquiryFrom, setEnquiryFrom] = useState(30);
  const [trackFrom, setTrackFrom] = useState(7);
  const [attendanceFrom, setAttendanceFrom] = useState(7);
  const [attendanceData, setAttendanceData] = useState();
  const [leaderboardTrackFrom, setLeaderboardTrackFrom] = useState(7);
  const [projectTrackingData, setProjectTrackingData] = useState();
  const [leadsData, setLeadsData] = useState();
  const [leaveData, setLeaveData] = useState();
  const [leadOwner, setLeadOwner] = useState();
  const [enquiryData, setEnquiryData] = useState();
  const [tableData, setTableCount] = useState();
  const [quotationData, setQuotationData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [todaysData, setTodaysData] = useState();
  const [employeeSalaryData, setEmployeeSalaryData] = useState();
  const [taskData, setTaskData] = useState();
  const [clientFeedback, setClientFeedback] = useState();

  const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];

  const navigate = useNavigate();

  const goToView = (id) => {
    navigate(`/client-feedback/new?id=${id}`);
  };

  const goToViewLeave = (userId) => {
    navigate(`employee-leave-management/view/${userId}`);
  };

  function formatDate(dateStr) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dateParts = dateStr.split("-");
    const year = dateParts[0];
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);

    return `${day} ${months[month]} ${year}`;
  }

  useEffect(() => {
    if (employeeName) {
      performRequest(
        "GET",
        `/employeeManagement/getAllEmployeeManagementSalary/?userName=${employeeName}`
      )
        .then((res) => {
          if (res.status === 200) {
            setEmployeeSalaryData(res?.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [employeeName]);

  useEffect(() => {
    if (currentUser) {
      performRequest(
        "GET",
        getFilteredURL("/allTablesCount/getTableCountByName/", 1, {
          generatedby: currentUser,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            setTodaysData(res?.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [currentUser]);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", "/allTablesCount/getAssignedFeedback")
      .then((res) => {
        if (res.status === 200) {
          setClientFeedback(res.data);
        } else {
          Toast("error", "Error while getting project Details!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  // console.log("Client feedback", clientFeedback);

  useEffect(() => {
    setLoading(true);

    performRequest("GET", "/allTablesCount/getCount")
      .then((res) => {
        if (res.status === 200) {
          setTableCount(res.data);
        } else {
          Toast("error", "Error while getting project Details!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);

    performRequest("GET", "/projectTracking/getAllProjectTracking")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data?.projectTrackingData
            .filter((project) =>
              project.taskList.some(
                (task) =>
                  task.taskName === "Handover" && task.isLocked === false
              )
            )
            .sort((pro1, pro2) => {
              return (
                getTaskCompleteCount(pro2.taskList) -
                getTaskCompleteCount(pro1.taskList)
              );
            });
          setProjectTrackingData(data);
        } else {
          Toast("error", "Error while getting project Details!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);

    performRequest("GET", "/marketingList/getAllMl/")
      .then((res) => {
        if (res.status === 200) {
          setEnquiryData(res?.data?.marketingListData);
        } else {
          Toast("error", "Error while getting Enquiries!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    performRequest("GET", `/leads/getallleads/`)
      .then((res) => {
        if (res.status === 201) {
          setLeadsData(res?.data?.leads);
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, [employee]);

  useEffect(() => {
    performRequest("GET", `/quotations/getQuotationsAll/`)
      .then((res) => {
        if (res.status === 200) {
          setQuotationData(res?.data?.quotation);
        } else {
          Toast("error", "Error while getting Quotations!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, [employee]);

  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (employeeName == "All") {
      performRequest("GET", `/attendance/getAllAttendance`)
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    } else {
      performRequest("GET", `/attendance/getAllAttendance?name=${employeeName}`)
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [employeeName]);

  useEffect(() => {
    performRequest("GET", `/leave/getDashboardLeaves/`)
      .then((res) => {
        if (res.status === 200) {
          setLeaveData(res.data.leave);
        } else {
          Toast("error", "Error while getting Attendance!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    performRequest("GET", `/tasks/getTaskManagement/`)
      .then((res) => {
        if (res.status === 200) {
          const currentDate = new Date();
          const filteredData = res.data.taskManagementData
            .filter((task) => task.assignTo === currentUser)
            .filter((task) => {
              const startDate = new Date(task.startDate);
              const frequency = parseInt(task.frequency, 10);
              const frequencyType = task.frequencyType;

              if (task.taskType === "one time") {
                if (task.status === "completed") {
                  const completedTime = new Date(task.completedTime);
                  return completedTime >= currentDate;
                } else {
                  return true;
                }
              } else if (task.taskType === "repeat task") {
                if (startDate > currentDate) {
                  return false;
                }

                let nextOccurrence = new Date(startDate);
                while (nextOccurrence <= currentDate) {
                  if (
                    nextOccurrence.toDateString() === currentDate.toDateString()
                  ) {
                    return true;
                  }
                  switch (frequencyType) {
                    case "days":
                      nextOccurrence.setDate(
                        nextOccurrence.getDate() + frequency
                      );
                      break;
                    case "months":
                      nextOccurrence.setMonth(
                        nextOccurrence.getMonth() + frequency
                      );
                      break;
                    case "years":
                      nextOccurrence.setFullYear(
                        nextOccurrence.getFullYear() + frequency
                      );
                      break;
                    default:
                      return false;
                  }
                }
                return false;
              }

              return false;
            });

          setTaskData(filteredData);
        } else {
          Toast("error", "Error while getting Tasks!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [currentUser]);

  const [modalData, setModalData] = useState();
  const AddAndAssignLead = (values) => {
    let lead = {
      ...LeadInfo,
      first_name: values.firstName || values.fullName || "",
      last_name: values.lastName || "",
      interestedIn: [],
      full_name: getName(values),
      services: "",
      planned_area: "",
      location_type: "",
      company_name: values.businessName || "",
      lead_genrated_from: "enquiry",
      lead_alloted_to: store.Name || "",
      preferred_contact_method: "",
      phone_number: values.mobileNumber || values?.whatsappNumber || "",
      lead_status: "",
      email: values.email || "",
      gst_number: values.gst_number || "",
      address: values.address1 || values.address2 || "",
      notes: values.notes || "",
    };

    setLoading(true);
    performRequest("POST", "/leads/createleads", lead)
      .then((res) => {
        if (res.status === 201) {
          setTimeout(() => {
            removeFromList(values.id);
          }, 500);
          setTimeout(() => {
            Toast("success", "Leads generated successfully!");
            navigate(`/leads/edit-lead/${res.data.id}`);
          }, 500);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  const removeFromList = (id) => {
    performRequest("DELETE", `/allTablesCount/moveEnquiry/${id}`)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Lead removed from enquiry list!");
        } else {
          Toast("error", "Error while Deleting");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  const handleCall = (phoneNumber) => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert("Phone number is not available!");
    }
  };

  const goToViewPendingFollwup = (id) => {
    navigate(`/leads-followup/view/${id}`);
  };

  const goToViewTaks = (id) => {
    navigate(`/task-management/view/${id}`);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const ClassNameReturnAttendance = () => {
    let className = "col-md-12 col-lg-12";
    if (
      store.Role == process.env.REACT_APP_ROLE_ADMIN ||
      store.Role == process.env.REACT_APP_ROLE_ADMIN_S
    ) {
      className = "col-md-12 col-lg-8";
    }
    return className;
  };

  const parseDate = (dateStr) => {
    return dateStr ? new Date(dateStr) : null;
  };

  // Sorting the leaveData by date in increasing order
  const sortedLeaveData = leaveData?.sort((a, b) => {
    const dateA = parseDate(a.leaveDate) || parseDate(a.leaveStartDate);
    const dateB = parseDate(b.leaveDate) || parseDate(b.leaveStartDate);
    return dateA - dateB;
  });

  return (
    <>
      {(store?.Role === process.env.REACT_APP_ROLE_CLIENT ||
        store?.Role === process.env.REACT_APP_ROLE_D_CLIENT ||
        store?.Role === process.env.REACT_APP_ROLE_SITE_INSPECTION) && (
        <FarmConstructionAgreement />
      )}

      {store?.Role !== process.env.REACT_APP_ROLE_CLIENT &&
        store?.Role !== process.env.REACT_APP_ROLE_D_CLIENT &&
        store?.Role !== process.env.REACT_APP_ROLE_SITE_INSPECTION && (
          <div className="page">
            <div className="page-wrapper">
              <div style={{ minHeight: "80vh" }}>
                <div className="page-body">
                  <div className="container-xxl">
                    <div className="row row-deck row-cards my-3">
                      {(store?.Role === process.env.REACT_APP_ROLE_SALLE ||
                        store?.Role ===
                          process.env.REACT_APP_ROLE_SALES_MANAGER ||
                        store?.Role ===
                          process.env.REACT_APP_ROLE_CLIENT_FEEDBACK) && (
                        <div className="col-lg-6 mb-2">
                          <div className="card card-sm">
                            <div className="card-header">
                              <h3 className="card-title">Todays followups</h3>
                            </div>

                            <div
                              className="card-table table-responsive"
                              style={{ maxHeight: "470px" }}
                            >
                              <table className="table table-vcenter">
                                <thead>
                                  <tr>
                                    <th>Full Name</th>
                                    <th>Filtered by</th>
                                    <th>Leads owner</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {todaysData?.pendingFollowupTodayData?.map(
                                    (followup) => (
                                      <tr key={followup.id}>
                                        <td>{followup?.full_name}</td>
                                        <td>{followup?.lead_alloted_to}</td>
                                        <td>{followup?.reAssignTo}</td>
                                        <td>
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                              goToViewPendingFollwup(
                                                followup?.id
                                              )
                                            }
                                          >
                                            View
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                      {(store?.Role === process.env.REACT_APP_ROLE_SALLE ||
                        store?.Role ===
                          process.env.REACT_APP_ROLE_SALES_MANAGER) && (
                        <div className="col-lg-6">
                          <div className="card card-sm">
                            <div className="card-header d-flex justify-content-between">
                              <h3 className="card-title">New enquiries</h3>
                              {/* <button
                                style={{
                                  width: "110px",
                                  height: "30px",
                                }}
                                className="btn btn-primary"
                              >
                                Assign enquiries
                              </button> */}
                            </div>

                            <div
                              className="card-table table-responsive"
                              style={{ maxHeight: "470px" }}
                            >
                              <table className="table table-vcenter">
                                <thead>
                                  <tr>
                                    <th>Full Name</th>
                                    <th>Contact Number</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {todaysData?.todaysEnquiry?.map((enquiry) => (
                                    <tr key={enquiry.id}>
                                      <td>{enquiry?.fullName || "N/A"}</td>
                                      <td>{enquiry?.mobileNumber}</td>
                                      <td colSpan="2">
                                        <button
                                          style={{
                                            width: "110px",
                                            height: "30px",
                                          }}
                                          className="btn btn-primary"
                                          data-bs-toggle="modal"
                                          data-bs-target="#modal-report"
                                          onClick={() => setModalData(enquiry)}
                                        >
                                          Assign To Me
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div
                                class="modal modal-blur fade"
                                id="modal-report"
                                tabindex="-1"
                                role="dialog"
                                aria-hidden="true"
                              >
                                <div
                                  class="modal-dialog modal-lg modal-dialog-centered"
                                  role="document"
                                >
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title">Filter lead</h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      {modalData && (
                                        <>
                                          <div className="mb-3 row">
                                            <label className="w-25 form-label col-3">
                                              Full name
                                            </label>
                                            <div className="w-100 col">
                                              {(
                                                modalData?.fullName ||
                                                modalData?.firstName ||
                                                "N/A"
                                              ).toUpperCase()}
                                            </div>
                                          </div>
                                          <div className="mb-3 row">
                                            <label className="w-25 form-label col-3">
                                              Address
                                            </label>
                                            <div className="w-100 col">
                                              {(
                                                modalData.address1 ||
                                                modalData.address2 ||
                                                "N/A"
                                              ).toUpperCase()}
                                            </div>
                                          </div>

                                          <div className="mb-3 row">
                                            <label className="w-25 form-label col-3">
                                              Email Address
                                            </label>
                                            <div className="w-100 col">
                                              {modalData?.email || "N/A"}
                                            </div>
                                          </div>
                                          <div className="mb-3 row">
                                            <label className="w-25 form-label col-3">
                                              Phone Number
                                            </label>
                                            <div className="w-100 col">
                                              {modalData?.mobileNumber ||
                                              modalData?.whatsappNumber ? (
                                                <span
                                                  onClick={() =>
                                                    handleCall(
                                                      modalData?.mobileNumber ||
                                                        modalData?.whatsappNumber
                                                    )
                                                  }
                                                  className="cursor-pointer text-blue text-decoration-underline"
                                                >
                                                  <svg
                                                    color="green"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    class="icon icon-tabler icons-tabler-outline icon-tabler-phone-outgoing"
                                                  >
                                                    <path
                                                      stroke="none"
                                                      d="M0 0h24v24H0z"
                                                      fill="none"
                                                    />
                                                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                                    <path d="M15 9l5 -5" />
                                                    <path d="M16 4l4 0l0 4" />
                                                  </svg>

                                                  {modalData?.mobileNumber ||
                                                    modalData?.whatsappNumber}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                          {/* <div className="mb-3 row d-lg-none d-md-none">
                    <label className="w-25 form-label col-3">Call Now</label>
                    <div className="w-100 col">
                      <SimpleButton buttonText="Call Now" />
                    </div>
                  </div> */}
                                          <div className="mb-3 row">
                                            <label className="w-25 form-label col-3">
                                              Lead Generated From
                                            </label>
                                            <div className="w-100 col">
                                              {(
                                                modalData?.origin || "N/A"
                                              ).toUpperCase()}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    <div class="modal-footer">
                                      <div>
                                        <SimpleButton
                                          buttonText="Not Connected"
                                          type="secondary"
                                          data-bs-dismiss="modal"
                                        />
                                      </div>

                                      <div class="ms-auto">
                                        <SimpleButton
                                          onClick={() =>
                                            AddAndAssignLead(modalData)
                                          }
                                          buttonText="Call Connected"
                                          data-bs-dismiss="modal"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {store?.Role ===
                        process.env.REACT_APP_ROLE_CLIENT_FEEDBACK && (
                        <div className="col-lg-6">
                          <div className="card card-sm">
                            <div className="card-header d-flex justify-content-between">
                              <h3 className="card-title">New Feedback</h3>
                              <button
                                style={{
                                  width: "110px",
                                  height: "30px",
                                }}
                                className="btn btn-primary"
                              >
                                Add Feedback
                              </button>
                            </div>

                            <div
                              className="card-table table-responsive"
                              style={{ maxHeight: "470px" }}
                            >
                              <table className="table table-vcenter">
                                <thead>
                                  <tr>
                                    <th>Client Name</th>
                                    <th>Contact Number</th>
                                    <th>Lead Owner</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {clientFeedback?.assignedFeedback
                                    ?.filter(
                                      (enquiry) =>
                                        enquiry?.feedBackStatus == "pending"
                                    )
                                    .map((enquiry) => (
                                      <tr key={enquiry.id}>
                                        <td>{enquiry?.clientName || "N/A"}</td>
                                        <td>
                                          {enquiry?.clientContactNumber ||
                                            "N/A"}
                                        </td>
                                        <td>{enquiry?.leadOwner || "N/A"}</td>
                                        <td>
                                          <button
                                            style={{
                                              width: "110px",
                                              height: "30px",
                                            }}
                                            className="btn btn-primary"
                                            onClick={() =>
                                              goToView(enquiry?.feedbackId)
                                            }
                                          >
                                            Take Feedback
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                      {store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                        store.Role !==
                          process.env.REACT_APP_ROLE_SALESAGENCY && (
                          <div className="row m-0 p-0">
                            <div className="col-sm-6 col-lg-2">
                              <div className="card bg-primary-lt card-sm">
                                <div className="card-body">
                                  <div className="row align-items-center">
                                    <div className="col-3">
                                      <span className="">
                                        {/* <IoDocumentTextOutline /> */}
                                        <FcRatings className="fs-large" />
                                      </span>
                                    </div>
                                    <div className="col">
                                      <div className="fw-bold">
                                        {(tableData && tableData?.quotations) ||
                                          0}{" "}
                                        Quotation
                                      </div>
                                      <div className="text-muted">
                                        {(tableData &&
                                          tableData?.quotationsToday) ||
                                          0}{" "}
                                        today
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {store.Role !==
                              process.env.REACT_APP_ROLE_PURCHASE && (
                              <div className="col-sm-6 col-lg-2">
                                <div className="card bg-primary-lt card-sm">
                                  <div className="card-body">
                                    <div className="row align-items-center">
                                      <div className="col-3">
                                        <span className="">
                                          {/* <IoCallOutline /> */}

                                          <FcCustomerSupport className="fs-large" />
                                        </span>
                                      </div>
                                      <div className="col">
                                        <div className="fw-bold">
                                          {" "}
                                          {(tableData &&
                                            tableData?.enquiryList) ||
                                            0}{" "}
                                          Enquiry
                                        </div>
                                        <div className="text-muted">
                                          {" "}
                                          {(tableData &&
                                            tableData?.enquiryListToday) ||
                                            0}{" "}
                                          today
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {store.Role !==
                              process.env.REACT_APP_ROLE_PURCHASE && (
                              <div className="col-sm-6 col-lg-2">
                                <div className="card bg-primary-lt card-sm">
                                  <div className="card-body">
                                    <div className="row align-items-center">
                                      <div className="col-3">
                                        <span className="">
                                          {/* <MdOutlineGroup /> */}
                                          <FcCallback className="fs-large" />
                                        </span>
                                      </div>
                                      <div className="col">
                                        <div className="fw-bold">
                                          {" "}
                                          {(tableData && tableData?.leads) ||
                                            0}{" "}
                                          Leads
                                        </div>
                                        <div className="text-muted">
                                          {" "}
                                          {(tableData &&
                                            tableData?.leadsToday) ||
                                            0}{" "}
                                          today
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-sm-6 col-lg-2">
                              <div className="card bg-primary-lt card-sm">
                                <div className="card-body">
                                  <div className="row align-items-center">
                                    <div className="col-3">
                                      <span className="">
                                        {/* <TbReportSearch /> */}

                                        <FcLandscape className="fs-large" />
                                      </span>
                                    </div>
                                    <div className="col">
                                      <div className="fw-bold">
                                        {(tableData &&
                                          tableData?.siteInspections) ||
                                          0}{" "}
                                        Site Insp.
                                      </div>
                                      <div className="text-muted">
                                        {(tableData &&
                                          tableData?.siteInspectionsToday) ||
                                          0}{" "}
                                        today
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-2">
                              <div className="card bg-primary-lt card-sm">
                                <div className="card-body">
                                  <div className="row align-items-center">
                                    <div className="col-3">
                                      <span className="">
                                        {/* <FaTasks /> */}
                                        <FcWorkflow className="fs-large" />
                                      </span>
                                    </div>
                                    <div className="col">
                                      <div className="fw-bold">
                                        {(tableData &&
                                          tableData?.clientProjects) ||
                                          0}{" "}
                                        Cl Project
                                      </div>
                                      <div className="text-muted">
                                        {" "}
                                        {(tableData &&
                                          tableData?.clientProjectsToday) ||
                                          0}{" "}
                                        today
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-2">
                              <div className="card bg-primary-lt card-sm">
                                <div className="card-body">
                                  <div className="row align-items-center">
                                    <div className="col-3">
                                      <span className="">
                                        <FcSignature className="fs-large" />
                                      </span>
                                    </div>
                                    <div className="col">
                                      <div className="fw-bold">
                                        {(tableData && tableData?.handOvers) ||
                                          0}{" "}
                                        Handovers
                                      </div>
                                      <div className="text-muted">
                                        {" "}
                                        {(tableData &&
                                          tableData?.handOversToday) ||
                                          0}{" "}
                                        today
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {(store.Role === process.env.REACT_APP_ROLE_SALLE ||
                        store.Role === process.env.REACT_APP_ROLE_OPS ||
                        store.Role === process.env.REACT_APP_ROLE_SALESAGENCY ||
                        store.Role === process.env.REACT_APP_ROLE_MARKETING ||
                        store.Role === process.env.REACT_APP_ROLE_ADMIN_S ||
                        store.Role ===
                          process.env.REACT_APP_ROLE_SALES_MANAGER ||
                        store.Role === process.env.REACT_APP_ROLE_ADMIN) &&
                        store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                        store.Role !==
                          process.env.REACT_APP_ROLE_INSPECTION && (
                          <>
                            {leadOwner && store && (
                              <EmployeeWorkData
                                leadOwner={leadOwner}
                                userData={store}
                              />
                            )}
                          </>
                        )}

                      {taskData?.length > 0 && (
                        <div className="col-12">
                          <div className="card card-sm">
                            <div className="card-header">
                              <h3 className="card-title">Today's Tasks</h3>
                            </div>
                            <div
                              className="card-table table-responsive"
                              style={{ maxHeight: "470px" }}
                            >
                              <table className="table table-vcenter">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Deadline</th>
                                    <th>Task Type</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {taskData?.map((task) => (
                                    <tr key={task.id}>
                                      <td>{task?.name}</td>
                                      <td>
                                        {task?.taskType === "one time" ? (
                                          task?.status === "pending" ? (
                                            <span
                                              className="badge bg-orange"
                                              style={{ color: "white" }}
                                            >
                                              Pending
                                            </span>
                                          ) : (
                                            <span
                                              className="badge bg-green"
                                              style={{ color: "white" }}
                                            >
                                              Completed
                                            </span>
                                          )
                                        ) : (
                                          <span
                                            className="badge bg-orange"
                                            style={{ color: "white" }}
                                          >
                                            Pending
                                          </span>
                                        )}
                                      </td>
                                      <td>{task?.deadline || "N/A"}</td>
                                      <td>{task?.taskType || "N/A"}</td>
                                      <td>
                                        <button
                                          className="btn btn-primary"
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                          }}
                                          onClick={() => goToViewTaks(task?.id)}
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}

                      {store.Role !== process.env.REACT_APP_ROLE_SALESAGENCY &&
                        store.Role !== process.env.REACT_APP_ROLE_INSPECTION &&
                        store.Role !== process.env.REACT_APP_ROLE_SALLE &&
                        store.Role !== process.env.REACT_APP_ROLE_IT && (
                          <div className="col-md-12 col-lg-4">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">Project Timeline</h3>
                              </div>
                              <div
                                className="card-table table-responsive"
                                style={{ maxHeight: "400px" }}
                              >
                                <table className="table table-vcenter">
                                  <thead>
                                    <tr>
                                      <th>Project</th>
                                      <th colSpan="2">Steps & Progress</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {projectTrackingData?.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="w-66">
                                            {item?.projectClientName || "N/A"}
                                          </td>
                                          <td>
                                            {getTaskListDetails(item?.taskList)}
                                          </td>
                                          <td className="w-33">
                                            <div className="progress progress-xs">
                                              <div
                                                className="progress-bar bg-primary"
                                                style={{
                                                  width:
                                                    getTaskCompleteProgress(
                                                      item.taskList
                                                    ),
                                                }}
                                              ></div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}

                      {store.Role !== process.env.REACT_APP_ROLE_INSPECTION &&
                        store.Role !== process.env.REACT_APP_ROLE_SALLE &&
                        store.Role !== process.env.REACT_APP_ROLE_IT && (
                          <div className="col-md-12 col-lg-8">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">Leads summary</h3>

                                <div className="ms-auto lh-1">
                                  <div className="dropdown">
                                    <a
                                      className="dropdown-toggle text-muted"
                                      href="/"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      Last {leadsFrom || 30} days
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <span
                                        onClick={() => setLeadsFrom(7)}
                                        className="dropdown-item"
                                      >
                                        Last 7 days
                                      </span>
                                      <span
                                        onClick={() => setLeadsFrom(30)}
                                        className="dropdown-item"
                                      >
                                        Last 30 days
                                      </span>
                                      <span
                                        onClick={() => setLeadsFrom(45)}
                                        className="dropdown-item"
                                      >
                                        Last 45 days
                                      </span>
                                      <span
                                        onClick={() => setLeadsFrom(60)}
                                        className="dropdown-item"
                                      >
                                        Last 2 months
                                      </span>
                                      <span
                                        onClick={() => setLeadsFrom(90)}
                                        className="dropdown-item"
                                      >
                                        Last 3 months
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {leadsData && leadsData.length > 0 && (
                                <StackedBarChart
                                  leadsData={leadsData}
                                  leadsFrom={leadsFrom}
                                />
                              )}
                            </div>
                          </div>
                        )}

                      {store.Role == process.env.REACT_APP_ROLE_ADMIN ||
                        (store.Role == process.env.REACT_APP_ROLE_ADMIN_S && (
                          <div className="col-md-12 col-lg-4">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">Employee Leaves</h3>
                              </div>
                              <div
                                className="card-table table-responsive"
                                style={{ maxHeight: "400px" }}
                              >
                                <table className="table table-vcenter">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Date</th>
                                      <th>Status</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {sortedLeaveData?.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{item?.userName || "N/A"}</td>
                                          <td>
                                            {item?.leaveDate !== ""
                                              ? formatDate(item?.leaveDate)
                                              : item?.leaveStartDate !== ""
                                              ? formatDate(item?.leaveStartDate)
                                              : "N/A"}
                                          </td>
                                          <td>{item?.leaveStatus}</td>
                                          <td>
                                            <button
                                              className="btn btn-primary"
                                              style={{
                                                width: "50px",
                                                height: "25px",
                                              }}
                                              onClick={() =>
                                                goToViewLeave(item?.userId)
                                              }
                                            >
                                              View
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ))}

                      {store.Role !== process.env.REACT_APP_ROLE_INSPECTION && (
                        <div className={ClassNameReturnAttendance()}>
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">Attendance summary</h3>
                              <div className="ms-auto">
                                <div className="d-flex">
                                  {(store.Role ===
                                    process.env.REACT_APP_ROLE_ADMIN ||
                                    store.Role ===
                                      process.env.REACT_APP_ROLE_ADMIN_S) && (
                                    <div className="me-2">
                                      <div className="dropdown">
                                        <a
                                          className="dropdown-toggle text-muted"
                                          href="/"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {employeeName}
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <span
                                            onClick={() =>
                                              setEmployeeName("All")
                                            }
                                            className="dropdown-item"
                                          >
                                            All
                                          </span>
                                          {leadOwner &&
                                            leadOwner.map((data, index) => (
                                              <span
                                                key={index}
                                                onClick={() =>
                                                  setEmployeeName(data.userName)
                                                }
                                                className="dropdown-item"
                                              >
                                                {data?.userName}
                                              </span>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="dropdown">
                                    <a
                                      className="dropdown-toggle text-muted"
                                      href="/"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {months[selectedMonth].name}
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      {months.map((month) => (
                                        <span
                                          key={month.value}
                                          onClick={() =>
                                            setSelectedMonth(month.value)
                                          }
                                          className="dropdown-item"
                                        >
                                          {month.name}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="dropdown ms-2 me-2">
                                    <a
                                      className="dropdown-toggle text-muted"
                                      href="/"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {selectedYear}
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      {years.map((year) => (
                                        <span
                                          key={year}
                                          onClick={() => setSelectedYear(year)}
                                          className="dropdown-item"
                                        >
                                          {year}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {leadOwner && attendanceData && (
                              <AttendanceTracking
                                leadOwner={leadOwner}
                                attendanceData={attendanceData?.Attendance}
                                attendanceFrom={selectedMonth}
                                selectedYear={selectedYear}
                                employeeSalary={employeeSalaryData}
                                employeeName={employeeName}
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {store.Role !== process.env.REACT_APP_ROLE_PURCHASE &&
                        store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                        store.Role !== process.env.REACT_APP_ROLE_INSPECTION &&
                        store.Role !== process.env.REACT_APP_ROLE_IT && (
                          <div className="col-12 col-lg-4">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">
                                  Sales leaderboard
                                </h3>
                              </div>
                              <DashboardLeaderBoard />
                            </div>
                          </div>
                        )}

                      {store.Role !== process.env.REACT_APP_ROLE_SALESAGENCY &&
                        store.Role !== process.env.REACT_APP_ROLE_PURCHASE &&
                        store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                        store.Role !== process.env.REACT_APP_ROLE_INSPECTION &&
                        store.Role !== process.env.REACT_APP_ROLE_IT && (
                          <div className="col-md-12 col-lg-8">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">Enquiry summary</h3>

                                <div className="ms-auto lh-1">
                                  <div className="dropdown">
                                    <a
                                      className="dropdown-toggle text-muted"
                                      href="/"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      Last {enquiryFrom || 30} days
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <span
                                        onClick={() => setEnquiryFrom(7)}
                                        className="dropdown-item"
                                      >
                                        Last 7 days
                                      </span>
                                      <span
                                        onClick={() => setEnquiryFrom(30)}
                                        className="dropdown-item"
                                      >
                                        Last 30 days
                                      </span>
                                      <span
                                        onClick={() => setEnquiryFrom(45)}
                                        className="dropdown-item"
                                      >
                                        Last 45 days
                                      </span>
                                      <span
                                        onClick={() => setEnquiryFrom(60)}
                                        className="dropdown-item"
                                      >
                                        Last 2 months
                                      </span>
                                      <span
                                        onClick={() => setEnquiryFrom(90)}
                                        className="dropdown-item"
                                      >
                                        Last 3 months
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {enquiryData && enquiryData.length > 0 && (
                                <EnquiryChart
                                  enquiryData={enquiryData}
                                  enquiryFrom={enquiryFrom}
                                />
                              )}
                            </div>
                          </div>
                        )}

                      {store.Role !== process.env.REACT_APP_ROLE_PURCHASE &&
                        store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                        store.Role !== process.env.REACT_APP_ROLE_SALLE &&
                        store.Role !== process.env.REACT_APP_ROLE_INSPECTION &&
                        store.Role !== process.env.REACT_APP_ROLE_IT &&
                        store.Role !==
                          process.env.REACT_APP_ROLE_CLIENT_FEEDBACK && (
                          <div className="col-md-12 col-lg-12">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">
                                  Lead owners summary
                                </h3>

                                <div className="ms-auto lh-1">
                                  <div className="dropdown">
                                    <a
                                      className="dropdown-toggle text-muted"
                                      href="/"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {trackFrom == 0
                                        ? "Today"
                                        : trackFrom == -1
                                        ? "Yesterday"
                                        : `Last ${trackFrom || 30} days`}
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <span
                                        onClick={() => setTrackFrom(0)}
                                        className="dropdown-item"
                                      >
                                        Today
                                      </span>
                                      <span
                                        onClick={() => setTrackFrom(-1)}
                                        className="dropdown-item"
                                      >
                                        Yesterday
                                      </span>
                                      <span
                                        onClick={() => setTrackFrom(7)}
                                        className="dropdown-item"
                                      >
                                        Last 7 days
                                      </span>
                                      <span
                                        onClick={() => setTrackFrom(30)}
                                        className="dropdown-item"
                                      >
                                        Last 30 days
                                      </span>
                                      <span
                                        onClick={() => setTrackFrom(45)}
                                        className="dropdown-item"
                                      >
                                        Last 45 days
                                      </span>
                                      <span
                                        onClick={() => setTrackFrom(60)}
                                        className="dropdown-item"
                                      >
                                        Last 2 months
                                      </span>
                                      <span
                                        onClick={() => setTrackFrom(90)}
                                        className="dropdown-item"
                                      >
                                        Last 3 months
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {leadOwner && leadsData && (
                                <SalesWorkTracking
                                  leadOwner={leadOwner}
                                  leadsData={leadsData}
                                  trackFrom={trackFrom}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      {store.Role !== process.env.REACT_APP_ROLE_PURCHASE &&
                        store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                        store.Role !== process.env.REACT_APP_ROLE_INSPECTION &&
                        store.Role !== process.env.REACT_APP_ROLE_IT && (
                          <div className="col-12">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">
                                  Sales daily updates
                                </h3>
                                <div className="ms-auto">
                                  <div className="d-flex">
                                    {store.Role !==
                                      process.env.REACT_APP_ROLE_SALLE &&
                                      store.Role !==
                                        process.env
                                          .REACT_APP_ROLE_SALESAGENCY && (
                                        <div className="me-2">
                                          <div className="dropdown">
                                            <a
                                              className="dropdown-toggle text-muted"
                                              href="/"
                                              data-bs-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              {employee}
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end">
                                              <span
                                                onClick={() =>
                                                  setEmployee("All")
                                                }
                                                className="dropdown-item"
                                              >
                                                All
                                              </span>
                                              {leadOwner &&
                                                leadOwner.map((data, index) => {
                                                  return (
                                                    <span
                                                      key={index}
                                                      onClick={() =>
                                                        setEmployee(
                                                          data.userName
                                                        )
                                                      }
                                                      className="dropdown-item"
                                                    >
                                                      {data?.userName}
                                                    </span>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    <div className="dropdown">
                                      <a
                                        className="dropdown-toggle text-muted"
                                        href="/"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Last {leadsFrom || 30} days
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-end">
                                        <span
                                          onClick={() => setLeadsFrom(7)}
                                          className="dropdown-item"
                                        >
                                          Last 7 days
                                        </span>
                                        <span
                                          onClick={() => setLeadsFrom(30)}
                                          className="dropdown-item"
                                        >
                                          Last 30 days
                                        </span>
                                        <span
                                          onClick={() => setLeadsFrom(45)}
                                          className="dropdown-item"
                                        >
                                          Last 45 days
                                        </span>
                                        <span
                                          onClick={() => setLeadsFrom(60)}
                                          className="dropdown-item"
                                        >
                                          Last 2 months
                                        </span>
                                        <span
                                          onClick={() => setLeadsFrom(90)}
                                          className="dropdown-item"
                                        >
                                          Last 3 months
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {leadsData &&
                                quotationData &&
                                quotationData.length > 0 &&
                                leadsData.length > 0 && (
                                  <LineChart
                                    leadsData={leadsData}
                                    quotationData={quotationData}
                                    leadsFrom={leadsFrom}
                                    employee={employee}
                                  />
                                )}
                            </div>
                          </div>
                        )}
                      {/* {store.Role !== process.env.REACT_APP_ROLE_PURCHASE &&
                        store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                        store.Role !==
                          process.env.REACT_APP_ROLE_INSPECTION && (
                          <div className="col-lg-6">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">
                                  Attendance summary
                                </h3>
                                {store?.Role !==
                                  process.env.REACT_APP_ROLE_SALLE &&
                                  store?.Role !==
                                    process.env.REACT_APP_ROLE_SALESAGENCY && (
                                    <div className="ms-auto me-3 lh-1">
                                      <div className="dropdown">
                                        <a
                                          className="dropdown-toggle text-muted"
                                          href="/"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {employeeName}
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          {leadOwner &&
                                            leadOwner.map((data, index) => {
                                              return (
                                                <span
                                                  key={index}
                                                  onClick={() =>
                                                    setEmployeeName(
                                                      data.userName
                                                    )
                                                  }
                                                  className="dropdown-item"
                                                >
                                                  {data?.userName}
                                                </span>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                <div
                                  className={
                                    store?.Role ==
                                      process.env.REACT_APP_ROLE_SALLE ||
                                    store?.Role ==
                                      process.env.REACT_APP_ROLE_SALESAGENCY
                                      ? "ms-auto lh-1"
                                      : "lh-1"
                                  }
                                >
                                  <div className="dropdown">
                                    <a
                                      className="dropdown-toggle text-muted"
                                      href="/"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {attendanceFrom === 0
                                        ? "Today"
                                        : `Last ${attendanceFrom || 7} days`}
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <span
                                        onClick={() => setAttendanceFrom(7)}
                                        className="dropdown-item"
                                      >
                                        Last 7 days
                                      </span>
                                      <span
                                        onClick={() => setAttendanceFrom(30)}
                                        className="dropdown-item"
                                      >
                                        Last 30 days
                                      </span>
                                      <span
                                        onClick={() => setAttendanceFrom(45)}
                                        className="dropdown-item"
                                      >
                                        Last 45 days
                                      </span>
                                      <span
                                        onClick={() => setAttendanceFrom(60)}
                                        className="dropdown-item"
                                      >
                                        Last 2 months
                                      </span>
                                      <span
                                        onClick={() => setAttendanceFrom(90)}
                                        className="dropdown-item"
                                      >
                                        Last 3 months
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {attendanceData &&
                                attendanceData?.Attendance &&
                                attendanceFrom && (
                                  <AttendanceChart
                                    attendanceFrom={attendanceFrom}
                                    attendance={attendanceData?.Attendance}
                                  />
                                )}
                            </div>
                          </div>
                        )}

                      <div
                        className="col-lg-6"
                        data-bs-toggle="modal"
                        data-bs-target="#modalScrollable"
                        style={{ cursor: "pointer" }}
                      >
                        <embed
                          src={`${Kryzen38Days}#toolbar=0`}
                          width="100%"
                          height="100%"
                          type="application/pdf"
                          data-bs-toggle="modal"
                          data-bs-target="#modalScrollable"
                        />
                      </div>

                      <div
                        className="modal fade"
                        id="modalScrollable"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalScrollableTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="modalScrollableTitle"
                              >
                                38 Rules of Rewiring for Kryzen
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <embed
                                src={`${Kryzen38Days}#toolbar=0`}
                                width="100%"
                                height="400px"
                                type="application/pdf"
                                style={{ border: "none" }}
                              />
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="col-lg-6 col-md-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">
                              <FcRules className="fs-2 me-1" /> Attendance
                              Policy
                            </h3>
                          </div>
                          <div className="card-body">
                            <ul>
                              <li className="m-1">
                                Your working hours start at 09:00AM and finish
                                at 06:00PM.
                              </li>
                              <li className="m-1">
                                You have a lunch break from 01:30PM to 02:30PM.
                              </li>
                              <li className="m-1">
                                You are expected to login at 09:00AM. In some
                                situations, you can redeem a grace period of 10
                                minutes.
                              </li>
                              <li className="m-1">
                                Any login after 09:10AM will be considered as
                                ‘Late Coming’.
                              </li>
                              <li className="m-1">
                                On 3 Late Comings, one day salary will be
                                deducted.
                              </li>
                              <li className="m-1">
                                You are expected to contribute 09 hours to work
                                every day.
                              </li>
                              <li className="m-1">
                                Logging off before 09 hours will be considered
                                as ‘Half Day’.
                              </li>
                              <li className="m-1">
                                If you do not log out, it will be considered as
                                a 'Half Day'.
                              </li>
                              <li className="m-1">
                                All salary calculations are automated based on
                                the above rules.
                              </li>

                              <li>
                                Not adhering to above attendance rules may
                                result in warning for disciplinary action.
                              </li>
                              <li className="m-1">
                                KBPL is an equal opportunity and unbiased
                                organization. The rules are applicable to
                                everyone unless otherwise stated in writing by
                                management.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">
                              <FcRules className="fs-2 me-1" /> Leave Policy
                            </h3>
                          </div>
                          <div className="card-body">
                            <ul>
                              <li className="m-1">
                                When you are in probation or in notice period;
                                all leaves will be unpaid.
                              </li>
                              <li className="m-1">
                                All confirmed employees are entitled to a total
                                of 15 leaves per calendar year devided in
                                quarters as Q1:3leaves, Q2:4leaves, Q3:4leaves,
                                Q4:4leaves.
                              </li>
                              {/* <li className="m-1">
                                Leave allocation is structured on a quarterly
                                basis as
                                <ul>
                                  <li>1st Quarter :- 3 leaves</li>
                                  <li>2nd Quarter :- 4 leaves</li>
                                  <li>3rd Quarter :- 4 leaves</li>
                                  <li>4th Quarter :- 4 leaves</li>
                                </ul>
                              </li> */}
                              <li className="m-1">
                                You must submit a leave application at least 3
                                days in advance for planned leaves using leave
                                management system.
                              </li>
                              <li className="m-1">
                                Not utilising the leave management system will
                                result in deduction of additional half day of
                                pay for total number of leaves taken.
                              </li>
                              <li className="m-1">
                                Approval of leaves is subject to the discretion
                                of the higher authority and the department
                                needs.
                              </li>
                              <li className="m-1">
                                You can choose to work on additional days in any
                                calendar year. And get comp-off against it.
                              </li>
                              <li className="m-1">
                                Comp off leaves are mandatory to apply in leave
                                management system.
                              </li>
                              <li className="m-1">
                                In a month you can not avail more then 3
                                comp-off leaves.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        )}
    </>
  );
};

export default Dashbourd;

// import React, { useState } from "react";
// import axios from "axios";

// const Dashbourd = () => {
//   const [file, setFile] = useState(null);

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleUpload = async () => {
//     if (!file) return;

//     try {
//       const response = await axios.post(
//         "http://localhost:4501/fileUploads/fileUpload/",
//         { fileName: file.name, contentType: file.type }
//       );
//       const signedUrl = response.data.url;

//       await axios.put(signedUrl, file, {
//         headers: {
//           "Content-Type": file.type,
//         },
//       });

//       alert("File uploaded successfully!");
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       alert("File upload failed!");
//     }
//   };

//   return (
//     <div>
//       <input type="file" onChange={handleFileChange} />
//       <button onClick={handleUpload}>Upload</button>
//     </div>
//   );
// };

// export default Dashbourd;
