import React, { useEffect, useState } from "react";
import { roundOffCost } from "../../../Components/Functions/amt.fun";
import QuotationAmount from "./quotation_amount";
function roundOff(cost) {
  cost = Math.round((cost * 100) / 100);
  return cost;
}
const getRevenue = (cal, plant, allQData) => {
  let harvest;
  if (plant?.grow_system == "nftA") {
    harvest =
      plant?.productionPerPlantMonth *
      allQData?.number_of_plants?.numberOfPlantsInA_Frame;
  }
  if (plant?.grow_system == "fb") {
    harvest =
      plant?.productionPerPlantMonth *
      allQData?.number_of_plants?.numberOfPlantsInFlatBed;
  }
  if (plant?.grow_system == "gb") {
    harvest =
      plant?.productionPerPlantMonth *
      allQData?.number_of_plants?.numberOfPlantsInGrowBag;
  }
  if (plant?.grow_system == "db") {
    harvest =
      plant?.productionPerPlantMonth *
      allQData?.number_of_plants?.numberOfPlantsInDutchBucket;
  }
  cal = roundOff(cal);
  harvest = roundOff(harvest);
  let final = roundOff(cal * harvest);
  return final;
};

const getHeading = (italianBasilArray, bellPaperArray) => {
  if (
    italianBasilArray &&
    bellPaperArray &&
    italianBasilArray.length > 0 &&
    bellPaperArray.length > 0
  ) {
    return "Case study: Italian Basil + Bell Papper";
  } else if (italianBasilArray && italianBasilArray.length > 0) {
    return "Case study: Italian Basil";
  } else if (bellPaperArray && bellPaperArray.length > 0) {
    return "Case study: Bell Papper";
  } else {
    return "Case study: N/A";
  }
};

const getGrowSystem = (grow_system) => {
  if (grow_system === "nftA") {
    return `NFT (A-Frame)`;
  }
  if (grow_system === "gb") {
    return `Grow Bag`;
  }
  if (grow_system === "db") {
    return `Dutch Bucket`;
  }

  if (grow_system === "fb") {
    return `Flat Bed`;
  }
};

const getTotalCost = (italianBasilArray, bellPaperArray, allQData) => {
  let tCost = 0;
  if (italianBasilArray?.length > 0) {
    italianBasilArray.map((plant) => {
      tCost += getRevenue(
        (plant?.min_price + plant?.max_price) / 2,
        plant,
        allQData
      );
    });
  }
  if (bellPaperArray?.length > 0) {
    bellPaperArray.map((plant) => {
      tCost += getRevenue(
        (plant?.min_price + plant?.max_price) / 2,
        plant,
        allQData
      );
    });
  }

  return tCost;
};

const getProductionCost = (allQData, totalCost, opexCost, qCost) => {
  let pc1 =
    (allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
    allQData?.opex_cost?.opex_labour_cost * 12 +
    (totalCost * 0.9 + opexCost) * 0.12 +
    allQData?.opex_cost?.opex_electricity_cost * 12 +
    (totalCost * 0.9 + opexCost) * 0.0012;
  let pc2 =
    (allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
    allQData?.opex_cost?.opex_labour_cost * 12 +
    (totalCost * 0.9 + opexCost) * 0.12 +
    allQData?.opex_cost?.opex_electricity_cost * 12 +
    (totalCost * 0.9 + opexCost) * 0.0012 +
    qCost * 0.005;

  let pc3 =
    (allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
    allQData?.opex_cost?.opex_labour_cost * 12 +
    (totalCost + opexCost) * 0.12 +
    allQData?.opex_cost?.opex_electricity_cost * 12 +
    (totalCost + opexCost) * 0.0012 +
    qCost * 0.01;
  let pc4 =
    (allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
    allQData?.opex_cost?.opex_labour_cost * 12 +
    (totalCost + opexCost) * 0.12 +
    allQData?.opex_cost?.opex_electricity_cost * 12 +
    (totalCost + opexCost) * 0.0012 +
    qCost * 0.015;
  let pc5 =
    (allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
    allQData?.opex_cost?.opex_labour_cost * 12 +
    (totalCost + opexCost) * 0.12 +
    allQData?.opex_cost?.opex_electricity_cost * 12 +
    (totalCost + opexCost) * 0.0012 +
    qCost * 0.02;

  return { pc1, pc2, pc3, pc4, pc5 };
};
const getTotalNetProfit = (allQData, totalCost, opexCost, qCost) => {
  let np1 =
    (totalCost * 0.9 + opexCost) * 12 -
    ((allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
      allQData?.opex_cost?.opex_labour_cost * 12 +
      (totalCost * 0.9 + opexCost) * 0.12 +
      allQData?.opex_cost?.opex_electricity_cost * 12 +
      (totalCost * 0.9 + opexCost) * 0.0012);
  let np2 =
    (totalCost * 0.9 + opexCost) * 12 -
    ((allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
      allQData?.opex_cost?.opex_labour_cost * 12 +
      (totalCost * 0.9 + opexCost) * 0.12 +
      allQData?.opex_cost?.opex_electricity_cost * 12 +
      (totalCost * 0.9 + opexCost) * 0.0012 +
      qCost * 0.005);
  let np3 =
    (totalCost + opexCost) * 12 -
    ((allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
      allQData?.opex_cost?.opex_labour_cost * 12 +
      (totalCost + opexCost) * 0.12 +
      allQData?.opex_cost?.opex_electricity_cost * 12 +
      (totalCost + opexCost) * 0.0012 +
      qCost * 0.01);

  let np4 =
    (totalCost + opexCost) * 12 -
    ((allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
      allQData?.opex_cost?.opex_labour_cost * 12 +
      (totalCost + opexCost) * 0.12 +
      allQData?.opex_cost?.opex_electricity_cost * 12 +
      (totalCost + opexCost) * 0.0012 +
      qCost * 0.015);
  let np5 =
    (totalCost + opexCost) * 12 -
    ((allQData?.opex_cost?.opex_growmedia_cost +
      allQData?.opex_cost?.opex_seeds_cost +
      allQData?.opex_cost?.opex_packaging_cost +
      allQData?.opex_cost?.opex_solutionA_cost +
      allQData?.opex_cost?.opex_solutionB_cost) *
      12 +
      allQData?.opex_cost?.opex_labour_cost * 12 +
      (totalCost + opexCost) * 0.12 +
      allQData?.opex_cost?.opex_electricity_cost * 12 +
      (totalCost + opexCost) * 0.0012 +
      qCost * 0.02);
  return { np1, np2, np3, np4, np5 };
};

let BreakEvenStatus = (amt) => {
  if (amt > 0) {
    return `+ VE`;
  } else {
    return `- VE`;
  }
};

const FinancialAnalysis = ({ data, allQData }) => {
  const [italianBasilArray, setItalianBasilArray] = useState([]);
  const [bellPaperArray, setBellPaperArray] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [qCost, setQuotationCost] = useState(0);
  const [opexCost, setOpexCost] = useState(0);
  const [pc1, setPc1] = useState(0);
  const [pc2, setPc2] = useState(0);
  const [pc3, setPc3] = useState(0);
  const [pc4, setPc4] = useState(0);
  const [pc5, setPc5] = useState(0);
  const [np1, setNp1] = useState(0);
  const [np2, setNp2] = useState(0);
  const [np3, setNp3] = useState(0);
  const [np4, setNp4] = useState(0);
  const [np5, setNp5] = useState(0);

  useEffect(() => {
    setQuotationCost(
      allQData?.input_info?.overrideUnitCost ||
        allQData?.quotationMatrixCost?.total_cost
    );
  }, [allQData]);
  useEffect(() => {
    let ftDataItalianBasil = allQData?.plant_grow?.updatedPlantsList?.filter(
      (item) => {
        return item?.plantName == "Italian Basil";
      }
    );
    let ftDataBellPapper = allQData?.plant_grow?.updatedPlantsList?.filter(
      (item) => {
        return item?.plantName == "Bell Papper";
      }
    );
    setBellPaperArray(ftDataBellPapper);
    setItalianBasilArray(ftDataItalianBasil);
  }, [allQData]);
  let serial = 1;
  let breakEvenCost = 0;
  let breakEvenCostStatus = 0;
  useEffect(() => {
    if (allQData && italianBasilArray && bellPaperArray) {
      setTotalCost(getTotalCost(italianBasilArray, bellPaperArray, allQData));
    }
    if (allQData) {
      setOpexCost(allQData?.opex_cost?.opex_all_cost);
    }
  }, [allQData, italianBasilArray, bellPaperArray]);

  useEffect(() => {
    if (allQData && totalCost && opexCost && qCost) {
      let value = getProductionCost(allQData, totalCost, opexCost, qCost);
      setPc1(value.pc1);
      setPc2(value.pc2);
      setPc3(value.pc3);
      setPc4(value.pc4);
      setPc5(value.pc5);
    }
  }, [allQData, totalCost, opexCost, qCost]);
  useEffect(() => {
    if (allQData && totalCost && opexCost && qCost) {
      let value = getTotalNetProfit(allQData, totalCost, opexCost, qCost);
      setNp1(value.np1);
      setNp2(value.np2);
      setNp3(value.np3);
      setNp4(value.np4);
      setNp5(value.np5);
    }
  }, [allQData, totalCost, opexCost, qCost]);

  return (
    <div>
      <h3 style={{ textAlign: "center", fontSize: "25px" }}>
        {getHeading(italianBasilArray, bellPaperArray)}
      </h3>
      <h3 style={{ textAlign: "center" }}>
        {italianBasilArray &&
          italianBasilArray.length > 0 &&
          italianBasilArray.map((plant, index) => {
            return (
              <span key={index}>
                {`${plant.plantName} (${getGrowSystem(
                  plant.grow_system
                )}) Gross Profit: ${roundOffCost(
                  getRevenue(
                    (plant?.min_price + plant?.max_price) / 2,
                    plant,
                    allQData
                  )
                )}`}
                <br />
              </span>
            );
          })}
        {bellPaperArray &&
          bellPaperArray.length > 0 &&
          bellPaperArray.map((plant, index) => {
            return (
              <span key={index}>
                {`${plant.plantName} (${getGrowSystem(
                  plant.grow_system
                )}) Gross Profit: ${roundOffCost(
                  getRevenue(
                    (plant?.min_price + plant?.max_price) / 2,
                    plant,
                    allQData
                  )
                )}`}
                <br />
              </span>
            );
          })}
        {italianBasilArray &&
          bellPaperArray &&
          allQData &&
          `Total Gross Profit: ${roundOffCost(
            getTotalCost(italianBasilArray, bellPaperArray, allQData)
          )}`}
      </h3>
      <h3></h3>
      {allQData && <QuotationAmount allQData={allQData} />}
      <h3>Capacity Utilization of Sales</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th style={{ width: "16%" }}>1st Year</th>
            <th style={{ width: "16%" }}>2nd Year</th>
            <th style={{ width: "16%" }}>3rd Year</th>
            <th style={{ width: "16%" }}>4th Year</th>
            <th style={{ width: "16%" }}>5th Year</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Capacity Utilization</td>
            <td>90%</td>
            <td>90%</td>
            <td>100%</td>
            <td>100%</td>
            <td>100%</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Sales / Receipts </td>
            <td>
              {totalCost &&
                opexCost &&
                qCost &&
                roundOffCost((totalCost * 0.9 + opexCost) * 12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                qCost &&
                roundOffCost((totalCost * 0.9 + opexCost) * 12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                qCost &&
                roundOffCost((totalCost + opexCost) * 12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                qCost &&
                roundOffCost((totalCost + opexCost) * 12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                qCost &&
                roundOffCost((totalCost + opexCost) * 12)}
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Projected Profit & Loss Account:</h3>
      <table style={{ width: "100%", fontSize: "14px" }}>
        <thead>
          <tr style={{ fontSize: "14px" }}>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th>1st Year</th>
            <th>2nd Year</th>
            <th>3rd Year</th>
            <th>4th Year</th>
            <th>5th Year</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Sales / Receipts</td>

            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost * 0.9 + opexCost) * 12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost * 0.9 + opexCost) * 12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 12)}
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <b>Manufacturing Expenses</b>
            </td>
            <td></td> <td></td>
            <td></td> <td></td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Raw Materials</td>

            <td>
              {roundOffCost(
                (allQData?.opex_cost?.opex_growmedia_cost +
                  allQData?.opex_cost?.opex_seeds_cost +
                  allQData?.opex_cost?.opex_packaging_cost +
                  allQData?.opex_cost?.opex_solutionA_cost +
                  allQData?.opex_cost?.opex_solutionB_cost) *
                  12
              )}
            </td>
            <td>
              {roundOffCost(
                (allQData?.opex_cost?.opex_growmedia_cost +
                  allQData?.opex_cost?.opex_seeds_cost +
                  allQData?.opex_cost?.opex_packaging_cost +
                  allQData?.opex_cost?.opex_solutionA_cost +
                  allQData?.opex_cost?.opex_solutionB_cost) *
                  12
              )}
            </td>
            <td>
              {roundOffCost(
                (allQData?.opex_cost?.opex_growmedia_cost +
                  allQData?.opex_cost?.opex_seeds_cost +
                  allQData?.opex_cost?.opex_packaging_cost +
                  allQData?.opex_cost?.opex_solutionA_cost +
                  allQData?.opex_cost?.opex_solutionB_cost) *
                  12
              )}
            </td>
            <td>
              {roundOffCost(
                (allQData?.opex_cost?.opex_growmedia_cost +
                  allQData?.opex_cost?.opex_seeds_cost +
                  allQData?.opex_cost?.opex_packaging_cost +
                  allQData?.opex_cost?.opex_solutionA_cost +
                  allQData?.opex_cost?.opex_solutionB_cost) *
                  12
              )}
            </td>
            <td>
              {roundOffCost(
                (allQData?.opex_cost?.opex_growmedia_cost +
                  allQData?.opex_cost?.opex_seeds_cost +
                  allQData?.opex_cost?.opex_packaging_cost +
                  allQData?.opex_cost?.opex_solutionA_cost +
                  allQData?.opex_cost?.opex_solutionB_cost) *
                  12
              )}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Wages</td>

            <td> {roundOffCost(allQData?.opex_cost?.opex_labour_cost * 12)}</td>
            <td>{roundOffCost(allQData?.opex_cost?.opex_labour_cost * 12)}</td>
            <td>{roundOffCost(allQData?.opex_cost?.opex_labour_cost * 12)}</td>
            <td>{roundOffCost(allQData?.opex_cost?.opex_labour_cost * 12)}</td>
            <td>{roundOffCost(allQData?.opex_cost?.opex_labour_cost * 12)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Repairs & Maintenance</td>

            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost * 0.9 + opexCost) * 0.12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost * 0.9 + opexCost) * 0.12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 0.12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 0.12)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 0.12)}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Power & Fuel</td>

            <td>
              {roundOffCost(allQData?.opex_cost?.opex_electricity_cost * 12)}
            </td>
            <td>
              {roundOffCost(allQData?.opex_cost?.opex_electricity_cost * 12)}
            </td>
            <td>
              {roundOffCost(allQData?.opex_cost?.opex_electricity_cost * 12)}
            </td>
            <td>
              {roundOffCost(allQData?.opex_cost?.opex_electricity_cost * 12)}
            </td>
            <td>
              {roundOffCost(allQData?.opex_cost?.opex_electricity_cost * 12)}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Other Expenses</td>

            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost * 0.9 + opexCost) * 0.0012)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost * 0.9 + opexCost) * 0.0012)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 0.0012)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 0.0012)}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost((totalCost + opexCost) * 0.0012)}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Depreciation</td>
            <td>0</td>
            <td> {qCost && roundOffCost(qCost * 0.005)}</td>
            <td> {qCost && roundOffCost(qCost * 0.01)}</td>
            <td> {qCost && roundOffCost(qCost * 0.015)}</td>
            <td> {qCost && roundOffCost(qCost * 0.02)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>
              <b>Production Cost</b>
            </td>

            <td> {pc1 && roundOffCost(pc1)}</td>
            <td>{pc2 && roundOffCost(pc2)}</td>
            <td> {pc3 && roundOffCost(pc3)}</td>
            <td>{pc4 && roundOffCost(pc4)}</td>
            <td>{pc5 && roundOffCost(pc5)}</td>
          </tr>

          <tr>
            <td>{++serial}</td>
            <td>
              <b>Cost Per Plant</b>
            </td>

            <td>
              {pc1 &&
                roundOffCost(
                  pc1 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {pc2 &&
                roundOffCost(
                  pc2 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {pc3 &&
                roundOffCost(
                  pc3 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {pc4 &&
                roundOffCost(
                  pc4 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {pc5 &&
                roundOffCost(
                  pc5 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Sales Per Plant</td>

            <td>
              {totalCost &&
                opexCost &&
                roundOffCost(
                  ((totalCost * 0.9 + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost(
                  ((totalCost * 0.9 + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost(
                  ((totalCost + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost(
                  ((totalCost + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                roundOffCost(
                  ((totalCost + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
          </tr>

          <tr>
            <td>{++serial}</td>
            <td>
              <b>Net Profit Per Plant</b>
            </td>

            <td>
              {totalCost &&
                opexCost &&
                pc1 &&
                roundOffCost(
                  ((totalCost * 0.9 + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants -
                    pc1 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                pc2 &&
                roundOffCost(
                  ((totalCost * 0.9 + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants -
                    pc2 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>

            <td>
              {totalCost &&
                opexCost &&
                pc3 &&
                roundOffCost(
                  ((totalCost + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants -
                    pc3 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                pc4 &&
                roundOffCost(
                  ((totalCost + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants -
                    pc4 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
            <td>
              {totalCost &&
                opexCost &&
                pc5 &&
                roundOffCost(
                  ((totalCost + opexCost) * 12) /
                    allQData?.number_of_plants.maximumNumberOfPlants -
                    pc5 / allQData?.number_of_plants.maximumNumberOfPlants
                )}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>
              <b>Total Net Profit</b>
            </td>

            <td>{np1 && roundOffCost(np1)}</td>
            <td>{np2 && roundOffCost(np2)}</td>
            <td>{np3 && roundOffCost(np3)}</td>
            <td>{np4 && roundOffCost(np4)}</td>
            <td>{np5 && roundOffCost(np5)}</td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
      {/* have to work after coustomar discussion */}
      {/* 
      <h3>PROJECTED BALANCE SHEET:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th>0 Year</th>
            <th>1st Year</th>
            <th>2nd Year</th>
            <th>3rd Year</th>
            <th>4th Year</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>
              <b>LIABILITIES :</b>
            </td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Promoters Capital</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Profit</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Term Loan</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Working Capital Loan</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Current Liabilities</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Sundry Creditors</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td></td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>
              <b>ASSETS :</b>
            </td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Gross Fixed Assets : </td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Less : Depreciation</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Net Fixed Assets</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Preliminary & Pre-Op. Expenses</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Current Assets</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Cash in Bank/Hand</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Total</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </tbody>
      </table> */}
      {/* <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div> */}
      <h3>CASH FLOW STATEMENT:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th>1st Year</th>
            <th>2nd Year</th>
            <th>3rd Year</th>
            <th>4th Year</th>
            <th>5th Year</th>
            <th>6th Year</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Net Profit</td>
            <td>0</td>
            <td>{roundOffCost(np1)}</td>
            <td>{roundOffCost(np2)}</td>
            <td>{roundOffCost(np3)}</td>
            <td>{roundOffCost(np4)}</td>
            <td>{roundOffCost(np5)}</td>
          </tr>

          <tr>
            <td></td>
            <td>
              <b>Total</b>
            </td>
            <td>0</td>
            <td>{roundOffCost(np1)}</td>
            <td>{roundOffCost(np1 + np2)}</td>
            <td>{roundOffCost(np1 + np2 + np3)}</td>
            <td>{roundOffCost(np1 + np2 + np3 + np4)}</td>
            <td>{roundOffCost(np1 + np2 + np3 + np4 + np5)}</td>
          </tr>

          <tr>
            <td>{++serial}</td>
            <td>Investement</td>
            <td>{roundOffCost(qCost)}</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>

          <tr>
            <td>{++serial}</td>
            <td>Total Assets</td>

            <td>{qCost && roundOffCost(qCost)}</td>
            <td>{qCost && roundOffCost(qCost)}</td>
            <td>{qCost && roundOffCost(qCost - qCost * 0.005)}</td>
            <td>
              {qCost && roundOffCost(qCost - qCost * 0.005 - qCost * 0.01)}
            </td>
            <td>
              {qCost &&
                roundOffCost(
                  qCost - qCost * 0.005 - qCost * 0.01 - qCost * 0.015
                )}
            </td>
            <td>
              {qCost &&
                roundOffCost(
                  qCost -
                    qCost * 0.005 -
                    qCost * 0.01 -
                    qCost * 0.015 -
                    qCost * 0.02
                )}
            </td>
          </tr>

          <tr>
            <td>{++serial}</td>
            <td>Total Valuation</td>

            <td>{qCost && roundOffCost(qCost)}</td>
            <td>{qCost && roundOffCost(qCost + np1)}</td>
            <td>{qCost && roundOffCost(np1 + np2 + qCost - qCost * 0.005)}</td>
            <td>
              {qCost &&
                roundOffCost(
                  np1 + np2 + np3 + qCost - qCost * 0.005 - qCost * 0.01
                )}
            </td>
            <td>
              {qCost &&
                roundOffCost(
                  np1 +
                    np2 +
                    np3 +
                    np4 +
                    qCost -
                    qCost * 0.005 -
                    qCost * 0.01 -
                    qCost * 0.015
                )}
            </td>
            <td>
              {qCost &&
                roundOffCost(
                  np1 +
                    np2 +
                    np3 +
                    np4 +
                    np5 +
                    qCost -
                    qCost * 0.005 -
                    qCost * 0.01 -
                    qCost * 0.015 -
                    qCost * 0.02
                )}
            </td>
          </tr>

          <tr>
            <td>{++serial}</td>
            <td>Total Cash flow</td>

            <td>{qCost && roundOffCost(qCost * -1)}</td>
            <td>{qCost && roundOffCost((qCost - np1) * -1)}</td>
            <td>{qCost && roundOffCost((qCost - np1 - np2) * -1)}</td>
            <td>{qCost && roundOffCost((qCost - np1 - np2 - np3) * -1)}</td>
            <td>
              {qCost && roundOffCost((qCost - np1 - np2 - np3 - np4) * -1)}
            </td>
            <td>
              {qCost &&
                roundOffCost((qCost - np1 - np2 - np3 - np4 - np5) * -1)}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
      <h3>BREAK EVEN : 1ST YEAR</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th style={{ width: "20%" }}>1st year : Q1</th>
            <th style={{ width: "20%" }}>1st year : Q2</th>
            <th style={{ width: "20%" }}>1st year : Q3</th>
            <th style={{ width: "20%" }}>1st year : Q4</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Production Cost</td>
            <td>{roundOffCost(pc1 / 4)}</td>
            <td>{roundOffCost(pc1 / 4)}</td>
            <td>{roundOffCost(pc1 / 4)}</td>
            <td>{roundOffCost(pc1 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Net Profit</td>
            <td>{roundOffCost(np1 / 4)}</td>
            <td>{roundOffCost(np1 / 4)}</td>
            <td>{roundOffCost(np1 / 4)}</td>
            <td>{roundOffCost(np1 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Asset Recovery</td>
            <td>
              {roundOffCost((breakEvenCost = qCost + pc1 / 4 - np1 / 4) * -1)}
            </td>
            <td> {roundOffCost((breakEvenCost += pc1 / 4 - np1 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc1 / 4 - np1 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc1 / 4 - np1 / 4) * -1)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Breakeven Status</td>
            <td>
              {qCost &&
                pc1 &&
                np1 &&
                BreakEvenStatus(
                  (breakEvenCostStatus = qCost + pc1 / 4 - np1 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc1 &&
                np1 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc1 / 4 - np1 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc1 &&
                np1 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc1 / 4 - np1 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc1 &&
                np1 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc1 / 4 - np1 / 4) * -1
                )}
            </td>
          </tr>
        </tbody>
      </table>
      <h3>BREAK EVEN : 2ND YEAR</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th style={{ width: "20%" }}>2nd year : Q1</th>
            <th style={{ width: "20%" }}>2nd year : Q2</th>
            <th style={{ width: "20%" }}>2nd year : Q3</th>
            <th style={{ width: "20%" }}>2nd year : Q4</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Production Cost</td>
            <td>{roundOffCost(pc2 / 4)}</td>
            <td>{roundOffCost(pc2 / 4)}</td>
            <td>{roundOffCost(pc2 / 4)}</td>
            <td>{roundOffCost(pc2 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Net Profit</td>
            <td>{roundOffCost(np2 / 4)}</td>
            <td>{roundOffCost(np2 / 4)}</td>
            <td>{roundOffCost(np2 / 4)}</td>
            <td>{roundOffCost(np2 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Asset Recovery</td>
            <td> {roundOffCost((breakEvenCost += pc2 / 4 - np2 / 4) * -1)}</td>
            <td>{roundOffCost((breakEvenCost += pc2 / 4 - np2 / 4) * -1)}</td>
            <td>{roundOffCost((breakEvenCost += pc2 / 4 - np2 / 4) * -1)}</td>
            <td>{roundOffCost((breakEvenCost += pc2 / 4 - np2 / 4) * -1)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Breakeven Status</td>
            <td>
              {qCost &&
                pc2 &&
                np2 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc2 / 4 - np2 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc2 &&
                np2 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc2 / 4 - np2 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc2 &&
                np2 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc2 / 4 - np2 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc2 &&
                np2 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc2 / 4 - np2 / 4) * -1
                )}
            </td>
          </tr>
        </tbody>
      </table>
      <h3>BREAK EVEN : 3RD YEAR</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th style={{ width: "20%" }}>3rd year : Q1</th>
            <th style={{ width: "20%" }}>3rd year : Q2</th>
            <th style={{ width: "20%" }}>3rd year : Q3</th>
            <th style={{ width: "20%" }}>3rd year : Q4</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Production Cost</td>
            <td>{roundOffCost(pc3 / 4)}</td>
            <td>{roundOffCost(pc3 / 4)}</td>
            <td>{roundOffCost(pc3 / 4)}</td>
            <td>{roundOffCost(pc3 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Net Profit</td>
            <td>{roundOffCost(np3 / 4)}</td>
            <td>{roundOffCost(np3 / 4)}</td>
            <td>{roundOffCost(np3 / 4)}</td>
            <td>{roundOffCost(np3 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Asset Recovery</td>
            <td> {roundOffCost((breakEvenCost += pc3 / 4 - np3 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc3 / 4 - np3 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc3 / 4 - np3 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc3 / 4 - np3 / 4) * -1)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Breakeven Status</td>
            <td>
              {qCost &&
                pc3 &&
                np3 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc3 / 4 - np3 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc3 &&
                np3 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc3 / 4 - np3 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc3 &&
                np3 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc3 / 4 - np3 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc3 &&
                np3 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc3 / 4 - np3 / 4) * -1
                )}
            </td>
          </tr>
        </tbody>
      </table>
      <h3>BREAK EVEN : 4TH YEAR</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th style={{ width: "20%" }}>4th year : Q1</th>
            <th style={{ width: "20%" }}>4th year : Q2</th>
            <th style={{ width: "20%" }}>4th year : Q3</th>
            <th style={{ width: "20%" }}>4th year : Q4</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Production Cost</td>
            <td>{roundOffCost(pc4 / 4)}</td>
            <td>{roundOffCost(pc4 / 4)}</td>
            <td>{roundOffCost(pc4 / 4)}</td>
            <td>{roundOffCost(pc4 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Net Profit</td>
            <td>{roundOffCost(np4 / 4)}</td>
            <td>{roundOffCost(np4 / 4)}</td>
            <td>{roundOffCost(np4 / 4)}</td>
            <td>{roundOffCost(np4 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Asset Recovery</td>
            <td> {roundOffCost((breakEvenCost += pc4 / 4 - np4 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc4 / 4 - np4 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc4 / 4 - np4 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc4 / 4 - np4 / 4) * -1)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Breakeven Status</td>
            <td>
              {qCost &&
                pc4 &&
                np4 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc4 / 4 - np4 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc4 &&
                np4 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc4 / 4 - np4 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc4 &&
                np4 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc4 / 4 - np4 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc4 &&
                np4 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc4 / 4 - np4 / 4) * -1
                )}
            </td>
          </tr>
        </tbody>
      </table>
      <h3>BREAK EVEN : 5TH YEAR</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "15%" }}>Particulars</th>
            <th style={{ width: "20%" }}>5th year : Q1</th>
            <th style={{ width: "20%" }}>5th year : Q2</th>
            <th style={{ width: "20%" }}>5th year : Q3</th>
            <th style={{ width: "20%" }}>5th year : Q4</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Production Cost</td>
            <td>{roundOffCost(pc5 / 4)}</td>
            <td>{roundOffCost(pc5 / 4)}</td>
            <td>{roundOffCost(pc5 / 4)}</td>
            <td>{roundOffCost(pc5 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Net Profit</td>
            <td>{roundOffCost(np5 / 4)}</td>
            <td>{roundOffCost(np5 / 4)}</td>
            <td>{roundOffCost(np5 / 4)}</td>
            <td>{roundOffCost(np5 / 4)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Asset Recovery</td>
            <td> {roundOffCost((breakEvenCost += pc5 / 4 - np5 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc5 / 4 - np5 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc5 / 4 - np5 / 4) * -1)}</td>
            <td> {roundOffCost((breakEvenCost += pc5 / 4 - np5 / 4) * -1)}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Breakeven Status</td>
            <td>
              {qCost &&
                pc5 &&
                np5 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc5 / 4 - np5 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc5 &&
                np5 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc5 / 4 - np5 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc5 &&
                np5 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc5 / 4 - np5 / 4) * -1
                )}
            </td>
            <td>
              {qCost &&
                pc5 &&
                np5 &&
                BreakEvenStatus(
                  (breakEvenCostStatus += pc5 / 4 - np5 / 4) * -1
                )}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};
export default FinancialAnalysis;
