import React from "react";

const SWOT = () => {
  return (
    <div>
      <h3>S.W.O.T.</h3>

      <p>
        As one of the early players in the region, we will have numerous
        strengths that help us to thrive in the marketplace. These strengths not
        only help it to protect the market share in existing markets but also
        help in penetrating new markets. Here is our S.W.O.T. (Strength,
        Weakness, Opportunity, and Threat) Analysis.
      </p>
      <h3>Strengths – Internal Strategic Factors</h3>
      <ol>
        <li>
          High level of customer satisfaction. We deeply cares more about our
          customers and creates hi-tech ways to retain their trust. We are using
          state of the art infrastructure to grow the most healthy and tasty
          food for our consumers.
        </li>
        <li>
          Strong distribution network. Over the years by actively researching
          and gathering contacts in the unorganized leafy vegetable market we
          have built a reliable distribution network that can reach the majority
          of its potential market.
        </li>
      </ol>
      <h3>Weaknesses – Internal Strategic Factors</h3>
      <ol>
        <li>
          Limited success outside core business. Even though we are experienced
          in the industry we will need few starting months to get understanding
          of the system.
        </li>
        <li>
          The high attrition rate in the workforce. compared to other
          industries, agriculture sector faces higher attrition. We are placing
          SOP's in place in order to make our job smooth.
        </li>
        <li>
          Need more investment in new technologies. Given the scale of expansion
          and different geographies we might needs to put more money in
          technology to integrate the processes across the board.
        </li>
      </ol>

      <h3>Opportunities – External Strategic Factors</h3>
      <ol>
        <li>
          Stable free cash flow provides opportunities to invest in adjacent
          product segments. With more cash in the bank, we can invest in new
          technologies as well as in new product segments. This should open a
          window of opportunity in other product categories.
        </li>
        <li>
          The new taxation policy can significantly impact the way of doing
          business and can open new opportunities to increase their
          profitability.
        </li>
        <li>
          The low inflation rate brings more stability to the market, enabling
          credit at a lower interest rate.
        </li>
        <li>
          Economic uptick and increase in customer spending on fast food and
          organic salad purchase is an opportunity to capture new customers and
          increase market share.
        </li>
        <li>
          The new technology provides an opportunity to practice differentiated
          pricing strategy in the new market. It will enable the firm to
          maintain its loyal customers with great service and lure new customers
          through other value-oriented propositions.
        </li>
      </ol>

      <h3>Threats - External Strategic Factors</h3>
      <ol>
        <li>
          Farming but it depends on a few basic factors like water and
          electricity availability. No regular supply of electricity can
          increase growing costs and can be a threat to profitability.
        </li>
      </ol>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default SWOT;
