import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import Pagination from "../../Components/layouts/pagination";
import LoadingPage from "../../Components/layouts/loading";
import MobileAppTable from "./Mobile App/mobile_app_table";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import StepRoutes from "../../Routes/steps_routes";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import { FcAndroidOs } from "react-icons/fc";

const MobileApp = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    number: "",
    callType: "",
  });

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/mobileApp/getAllMobilApp/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.mobileAppCount / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="page-header d-print-none">
          <div className="container-xl">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcAndroidOs /> Android App
                </h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="page-body">
          <div className="container-xxl">
            {/* <div className="row">
              <div className="col-md-3 col-sm-12 py-2">
                <div className="card p-4">
                  <div className="row">
                    <h3 className="">Filter result</h3>
                    <div className="col-12">
                     
                    </div>
                    <div className="col-12">
                      
                    </div>
                    <div className="col-12">
                    
                    </div>
              

                    <div className="col-12">
                      <div className="row mt-2">
                        <div className="col-6">
                       
                        </div>
                        <div className="col-6">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-sm-12 py-2">
                <div className="card">
                  {data && (
                    <MobileAppTable
                      data={data?.mobileAppData}
                      currentPage={page}
                      itemsPerPage={50}
                    />
                  )}

                  <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    pageDatalength={data?.mobileAppData?.length}
                    showPage={showPage}
                    setShowPage={setShowPage}
                  />
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-3 col-sm-12 py-2">
                <div className="card p-1">
                  <div
                    id="faq-1"
                    className="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div>
                      <div className="accordion-header m-0" role="tab">
                        <button
                          className="accordion-button fs-3 d-md-none"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-1-1"
                        >
                          <h3>Filter result</h3>
                        </button>
                      </div>

                      <div
                        id="faq-1-1"
                        className="accordion-collapse collapse d-md-none"
                        role="tabpanel"
                        data-bs-parent="#faq-1"
                      >
                        <div className="accordion-body pt-0">
                          <div className="row">
                            <div className="col-12">
                              <DynamicFilterInput
                                label="Start Date:"
                                value={filters.startdate}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    startdate: e.target.value,
                                  })
                                }
                                type="date"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="End Date:"
                                value={filters.enddate}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    enddate: e.target.value,
                                  })
                                }
                                type="date"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="Search number:"
                                placeholder="Enter number"
                                value={filters.number}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    number: e.target.value,
                                  })
                                }
                                type="text"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Filter by call type:"
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    callType: e.target.value,
                                  })
                                }
                                value={filters.callType}
                              >
                                <option value="">Select</option>
                                <option value="Incoming">Incoming</option>
                                <option value="Outgoing">Outgoing</option>
                                <option value="Missed">Missed</option>
                              </DynamicFilterSelect>
                            </div>

                            <div className="col-12">
                              <div className="row mt-2">
                                <div className="col-6">
                                  <button
                                    onClick={() => setRefresh(!refresh)}
                                    className="btn btn-success w-100"
                                  >
                                    Apply Filter
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    onClick={() => {
                                      setFilters({
                                        startdate: "",
                                        enddate: "",
                                        number: "",
                                        callType: "",
                                      });
                                      setRefresh(!refresh);
                                    }}
                                    className="btn btn-secondary w-100"
                                  >
                                    Reset Filter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-body d-none d-md-block">
                        <div className="row">
                          <h3>Filter result</h3>
                          <div className="col-12">
                          <DynamicFilterInput
                                label="Start Date:"
                                value={filters.startdate}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    startdate: e.target.value,
                                  })
                                }
                                type="date"
                              />
                          </div>
                          <div className="col-12">
                          <DynamicFilterInput
                                label="End Date:"
                                value={filters.enddate}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    enddate: e.target.value,
                                  })
                                }
                                type="date"
                              />
                          </div>
                          <div className="col-12">
                          <DynamicFilterInput
                                label="Search number:"
                                placeholder="Enter number"
                                value={filters.number}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    number: e.target.value,
                                  })
                                }
                                type="text"
                              />
                          </div>

                          <div className="col-12">
                              <DynamicFilterSelect
                                label="Filter by call type:"
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    callType: e.target.value,
                                  })
                                }
                                value={filters.callType}
                              >
                                <option value="">Select</option>
                                <option value="Incoming">Incoming</option>
                                <option value="Outgoing">Outgoing</option>
                                <option value="Missed">Missed</option>
                              </DynamicFilterSelect>
                            </div>
                         

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                              <button
                                    onClick={() => setRefresh(!refresh)}
                                    className="btn btn-success w-100"
                                  >
                                    Apply Filter
                                  </button>
                              </div>
                              <div className="col-6">
                              <button
                                    onClick={() => {
                                      setFilters({
                                        startdate: "",
                                        enddate: "",
                                        number: "",
                                        callType: "",
                                      });
                                      setRefresh(!refresh);
                                    }}
                                    className="btn btn-secondary w-100"
                                  >
                                    Reset Filter
                                  </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                  {data && (
                    <MobileAppTable
                      data={data?.mobileAppData}
                      currentPage={page}
                      itemsPerPage={50}
                    />
                  )}

                  <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    pageDatalength={data?.mobileAppData?.length}
                    showPage={showPage}
                    setShowPage={setShowPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MobileApp;
