import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { extractDate } from "../../../Components/Functions/date.fun";
import { BsArrowRightShort } from "react-icons/bs";
const tableHead = [
  "Sr.",
  "date",
  "Project",
  "Amount",
  "creator",
  // "description",
  "paid Status",
  "Actions",
];

const ReimbursementTable = ({
  reimbursement = [],
  currentPage,
  itemsPerPage,
}) => {
  const user = useSelector((store) => store.login.user);

  const navigate = useNavigate();
  const goToEdit = (id) => {
    navigate(`/reimbursement-management/edit/${id}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              if (item !== "Actions") {
                return <th key={index}>{item}</th>;
              }
              if (user.Role === process.env.REACT_APP_ROLE_ADMIN_S) {
                return <th key={index}>{item}</th>;
              }
            })}
          </tr>
        </thead>
        <tbody>
          {reimbursement?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>
                  {item?.createdAt ? extractDate(item?.createdAt) : "N/A"}
                </td>
                <td>{item?.clientName || "N/A"}</td>
                <td>{item?.amount ? Math.round(item?.amount) : "N/A"}</td>

                <td>{item?.generatedBy || "N/A"}</td>
                {/* <td>{item?.reimbursementsDescription || "N/A"}</td> */}
                <td>{item?.paidStatus || "pending"}</td>
                {(user.Role === process.env.REACT_APP_ROLE_ADMIN_S ||
                  user.Role === process.env.REACT_APP_ROLE_ACCOUNTS) && (
                  <td>
                    <button
                      className="btn btn-primary btn-sm px-3"
                      onClick={() => goToEdit(item.id)}
                    >
                      <BsArrowRightShort className="me-1" /> Edit
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReimbursementTable;
