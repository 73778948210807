import React from "react";
import { useNavigate } from "react-router-dom";

import { BsArrowRightShort } from "react-icons/bs";
const tableHead = [
  "Sr.",
  "Farm",
  "Batch name",
  "start date",
  "end date",
  "progress",
  "Actions",
];
const getScheduleProgress = (schedule) => {
  if (schedule && schedule.length > 0) {
    let count = 0;
    for (let i = 0; i < schedule.length; i++) {
      if (schedule[i].scheduleDone) {
        count += 1;
      }
    }
    return `${count}/30`;
  } else {
    return "Not Started";
  }
};
const NurseryBatchTable = ({ BatchData = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();

  const goToView = (id) => {
    navigate(`/farm-nursery-management/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {BatchData?.map((batch, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={batch?.id}>
                <td>{Index}</td>
                <td>{batch?.farmName || "N/A"}</td>
                <td>{batch?.batchName || "N/A"}</td>
                <td>{batch?.batchStartDate || "N/A"}</td>
                <td>{batch?.batchEndDate || "N/A"}</td>
                <td>{getScheduleProgress(batch?.schedule)}</td>
                <td>
                  <button
                    className="btn btn-primary btn-sm px-3"
                    onClick={() => goToView(batch.id)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NurseryBatchTable;
