import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { useSelector } from "react-redux";

import { FcDam } from "react-icons/fc";
import { BsWater } from "react-icons/bs";

const ViewWaterManagement = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/waterManagement/getWaterManagementById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  console.log(data);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcDam className="fs-2  me-1" /> Water Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Track & manage your water tanks.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card table-responsive">
                <table className="table table-striped table-vcenter">
                  <thead>
                    <tr>
                      <th>Specs</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && (
                      <tr>
                        <td>Project Name</td>
                        <td>{(data?.farmName || "N/A").toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Project Address</td>
                        <td>{(data?.projectAddress || "N/A").toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Contact</td>
                        <td>
                          {(data?.clientPhoneNumber || "N/A").toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Cycle Name</td>
                        <td>{(data?.cycleName || "N/A").toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>System Type</td>
                        <td>{(data?.typeOfSystem || "N/A").toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Tank Capacity</td>
                        <td>
                          {(data?.waterTankCapacity || "N/A").toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Start PH</td>
                        <td>{(data.startingPh || "N/A").toUpperCase()}</td>
                      </tr>
                    )}

                    {data && (
                      <tr>
                        <td>Start EC</td>
                        <td>{(data?.startingEc || "N/A").toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Water source</td>
                        <td>{(data?.waterSource || "N/A").toUpperCase()}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {data && (
                  <div className="table-responsive">
                    <table className="table table-striped table-vcenter">
                      <thead>
                        <tr>
                          <th>Sr no.</th>
                          <th>Reading Date</th>
                          <th>PH Reading</th>
                          <th>EC Reading</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.timeLines?.map((lineItem, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {(lineItem?.scheduleDate || "N/A").toUpperCase()}
                            </td>
                            <td>
                              {(lineItem?.phReading || "N/A").toUpperCase()}
                            </td>
                            <td>
                              {(lineItem?.ecReading || "N/A").toUpperCase()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton
                      buttonText="Add New Entry"
                      href={`/farm-water-management/edit/${id}`}
                    />
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewWaterManagement;
