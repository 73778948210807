import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { FieldArray, Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import { useSelector } from "react-redux";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import LoadingPage from "../../../Components/layouts/loading";
import { ProjectTrackingInfo } from "../../../SchemaInfo/info";
import { ProjectTrackingSchema } from "../../../Schemas/clients/client_project_tracking_schema";
import { getId } from "../../../Components/Functions/word.fun";
import { uploadImage } from "../../../CustomComponent/upload_Image";

export const LockPermission = (task, props, index) => {
  if (!task.completionDate && !task.scheduleDate && !task.responsibleStaff) {
    Toast(
      "error",
      `Please complete all details of "${task.taskName}" then lock task`
    );
  } else if (!task.completionDate) {
    Toast("error", `Please fill completion date of "${task.taskName}"`);
  } else if (!task.scheduleDate) {
    Toast("error", `Please fill schedule date of "${task.taskName}"`);
  } else if (!task.responsibleStaff) {
    Toast("error", `Please add responsible staff to "${task.taskName}"`);
  } else {
    props.setFieldValue(`taskList[${index}].isLocked`, true);
  }
};

const AddProjectTracking = () => {
  const store = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [resStaff, setResStaff] = useState([]);
  const [siteInspections, setSiteInspections] = useState([]);
  const [quotationNumber, setQuotationNumber] = useState();
  const [quotation, setQuotation] = useState(null);

  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    performRequest("GET", "/employeeManagement/getAllEmployeeManagement")
      .then((res) => setResStaff(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    performRequest("GET", "/siteInspection/getallSiteInspection")
      .then((res) => setSiteInspections(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (quotationNumber) {
      performRequest("GET", `/quotations/getQuotationsById/${quotationNumber}`)
        .then((res) => setQuotation(res.data))
        .catch((err) => console.error(err));
    }
  }, [quotationNumber]);

  const handleSubmit = (values) => {
    values = { ...values, generatedBy: store.Name };
    // console.log(values);
    setLoading(true);
    performRequest("POST", "/projectTracking/createProjectTracking", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Project tracking created successfully!");
          navigate(`/client-project-tracking`);
        } else if (res.data.error === "Project tracking already exists") {
          Toast("error", "Project tracking already exists");
        } else {
          Toast("error", "Error while creating Project tracking!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }
  const ProjectTrackingInfoAll = [
    {
      taskName: "Project Started",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Work Order",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "alpha.kryzen.com Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "1st Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Ready For Dispatch",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Ticket ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Stay Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Food Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Arrived ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level Checking",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Civil Material For Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "2nd Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Shadenet",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Digging Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Civil Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ];
  const NVPHAndFanpad = [
    {
      taskName: "CEA Manufacturing Started ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA material procured in factory",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Manufacturing Finished ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Fan Structure Civil Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Pad Structure Civil Foundation ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Structure Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Hocky Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Gutter Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Top Paper Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Side Vent Paper Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Entry Cabin Structure",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ];

  const Fanpad = [
    {
      taskName: "Fan Structure Civil Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Pad Structure Civil Foundation ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Fan Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Cellulose Pad Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Civil Material For Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ];

  const Aframe = [
    {
      taskName: "Weed Mat Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ];

  const Flatbed = [
    {
      taskName: "NFT Connector Plumbing work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ];

  const Growbags = [
    {
      taskName: "NFT Pipe Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ];

  const DutchBucket = [
    {
      taskName: "NFT to Resoirvoir Plumbing work ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ];

  const EndTasks = [
    {
      taskName: "NFT Waterflow Testing",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Resoirvoir Plumbing work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Cocopeat Decompression",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Filling",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Dripline work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag 4Way Drip Dispenser Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Trellising work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "QA of Project",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Labour training 7 Days Program",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Hydroponic SOP / Nutrient Chart",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },

    {
      taskName: "Hydroponic Starter Kit",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Management Software Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour return Tickets",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Project Handover",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ];

  const getValueFormik = (quotation) => {
    let valueFormik = {
      projectId: "",
      siteSelectionId: "",
      workOrderUpload: "",
      taskList: [...ProjectTrackingInfoAll],
      notes: "",
    };
    if (
      quotation?.input_info?.quotationType === "nvph" ||
      quotation?.input_info?.quotationType === "fanpad"
    ) {
      valueFormik.taskList = valueFormik.taskList.concat(NVPHAndFanpad);
    }
    if (quotation?.input_info?.quotationType === "fanpad") {
      valueFormik.taskList = valueFormik.taskList.concat(Fanpad);
    }
    if (quotation?.input_info?.a_FramePercent !== "") {
      valueFormik.taskList = valueFormik.taskList.concat(Aframe);
    }
    if (quotation?.input_info?.flatBedPercent !== "") {
      valueFormik.taskList = valueFormik.taskList.concat(Flatbed);
    }
    if (quotation?.input_info?.growBagPercent !== "") {
      valueFormik.taskList = valueFormik.taskList.concat(Growbags);
    }
    if (quotation?.input_info?.dutchBucketPercent !== "") {
      valueFormik.taskList = valueFormik.taskList.concat(DutchBucket);
    }
    valueFormik.taskList = valueFormik.taskList.concat(EndTasks);
    return valueFormik;
  };

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Project Tracking</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Project Tracking</h2>
            </div>
            <Formik
              initialValues={
                quotation ? getValueFormik(quotation) : ProjectTrackingInfo
              }
              enableReinitialize
              validationSchema={ProjectTrackingSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Project
                      </label>
                      <div className="w-100 col">
                        <select
                          name="projectId"
                          className="form-select"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value) {
                              let pro = JSON.parse(value);
                              props.setFieldValue(
                                "projectId",
                                getId(pro.cpNumber)
                              );
                            } else {
                              props.setFieldValue("projectId", "");
                            }
                          }}
                        >
                          <option value="">Select</option>
                          {projects &&
                            projects.map((pro) => (
                              <option
                                key={pro.cpNumber}
                                value={JSON.stringify(pro)}
                              >
                                {pro.clientName}
                              </option>
                            ))}
                        </select>
                        {props.touched.projectId && props.errors.projectId && (
                          <p className="text-danger">
                            {props.errors.projectId}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Site inspection
                      </label>
                      <div className="w-100 col">
                        <select
                          name="siteSelectionId"
                          className="form-select"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value) {
                              let si = JSON.parse(value);
                              props.setFieldValue(
                                "siteSelectionId",
                                getId(si.siNumber)
                              );
                              setQuotationNumber(getId(si.quotationNumber));
                            } else {
                              props.setFieldValue("siteSelectionId", "");
                              setQuotationNumber(""); // reset quotation number if needed
                            }
                          }}
                        >
                          <option value="">Select</option>
                          {siteInspections &&
                            siteInspections.map((si) => (
                              <option
                                key={si.siNumber}
                                value={JSON.stringify(si)}
                              >
                                {si.clientName}
                              </option>
                            ))}
                        </select>
                        {props.touched.siteSelectionId &&
                          props.errors.siteSelectionId && (
                            <p className="text-danger">
                              {props.errors.siteSelectionId}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Work order upload
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="workOrderUpload"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "workOrderUpload",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.workOrderUpload &&
                          props.errors.workOrderUpload && (
                            <p className="text-danger">
                              {props.errors.workOrderUpload}
                            </p>
                          )}
                      </div>
                    </div>
                    <hr />
                    <FieldArray name="taskList">
                      {({ push, remove }) =>
                        props.values.taskList &&
                        props.values.taskList.map((task, index) => (
                          <div
                            key={task.taskName}
                            className="row g-3 align-items-center"
                          >
                            <div className="col-lg">
                              <label className="form-label input-group-link">
                                {task.taskName} schedule on
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  disabled={task.isLocked}
                                  type="date"
                                  name={`taskList[${index}].scheduleDate`}
                                />
                              </div>
                            </div>
                            <div className="col-lg">
                              <label className="form-label input-group-link">
                                {task.taskName} completed on
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  disabled={task.isLocked}
                                  type="date"
                                  name={`taskList[${index}].completionDate`}
                                />
                              </div>
                            </div>
                            <div className="col-lg">
                              <label className="form-label input-group-link">
                                Responsible staff
                              </label>
                              <div className="w-100 col">
                                <CustomSelect
                                  type="text"
                                  disabled={task.isLocked}
                                  name={`taskList[${index}].responsibleStaff`}
                                >
                                  <option value="">Select</option>
                                  {resStaff &&
                                    resStaff.map((staff) => (
                                      <option
                                        key={staff.id}
                                        value={`${staff.firstName} ${staff.lastName}`}
                                      >
                                        {`${staff.firstName} ${staff.lastName}`}
                                      </option>
                                    ))}
                                </CustomSelect>
                              </div>
                            </div>
                            <div className="col-lg mt-5">
                              <button
                                disabled={task.isLocked}
                                className="col-3 btn btn-primary"
                                style={{
                                  width: "70px",
                                  height: "30px",
                                }}
                                type="button"
                                onClick={() => {
                                  LockPermission(task, props, index);
                                }}
                              >
                                Lock
                              </button>
                            </div>
                            <hr />
                          </div>
                        ))
                      }
                    </FieldArray>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>
                      <div className="w-100 col">
                        <CustomTextArea name="notes" rows="10" />
                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Project Tracking" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddProjectTracking;
