import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import Pagination from "../../Components/layouts/pagination";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import LoadingPage from "../../Components/layouts/loading";
import ClientFeedbackTable from "./Client Feedback/client_feedback_table";
const ClientFeedback = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [leadOwner, setLeadOwner] = useState([]);
  const [filters, setFilters] = useState({
    sortbystatus: "",
    startdate: "",
    enddate: "",
    leadname: "",
    leadowner: "",
    rating: "",
    obstacle: "",
    infoRcieved: "",
  });
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/clientFeedBack/getClientFeedBack", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.clientFeedbackCount / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  // console.log(data);
  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res.data))
      .catch((err) => console.error(err));
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Client Feedback</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="container-xxl">
              <div className="row">
                <div className="col-md-3 col-sm-12 py-2">
                  <div className="card p-1">
                    <div
                      id="faq-1"
                      className="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div>
                        <div className="accordion-header m-0" role="tab">
                          <button
                            className="accordion-button fs-3 d-md-none"
                            data-bs-toggle="collapse"
                            data-bs-target="#faq-1-1"
                          >
                            <h3>Filter result</h3>
                          </button>
                        </div>

                        <div
                          id="faq-1-1"
                          className="accordion-collapse collapse d-md-none"
                          role="tabpanel"
                          data-bs-parent="#faq-1"
                        >
                          <div className="accordion-body pt-0">
                            <div className="row">
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="Start Date:"
                                  value={filters.startdate}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      startdate: e.target.value,
                                    })
                                  }
                                  type="date"
                                />
                              </div>
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="End Date:"
                                  value={filters.enddate}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      enddate: e.target.value,
                                    })
                                  }
                                  type="date"
                                />
                              </div>
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="Search name /mobile:"
                                  value={filters.leadname}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      leadname: e.target.value,
                                    })
                                  }
                                  placeholder="search here..."
                                />
                              </div>
                              <div className="col-12">
                                <DynamicFilterSelect
                                  label="Sort by status:"
                                  value={filters.sortbystatus}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      sortbystatus: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">select status</option>
                                  <option value="pending">Pending</option>
                                  <option value="feedback recieved">
                                    Feedback Recieved
                                  </option>
                                </DynamicFilterSelect>
                              </div>
                              <div className="col-12">
                                <DynamicFilterSelect
                                  label="Search lead owner:"
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      leadowner: e.target.value,
                                    })
                                  }
                                  value={filters.leadowner}
                                >
                                  <option value="">select lead owner</option>
                                  {leadOwner &&
                                    leadOwner?.map((lead, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={lead?.userName}
                                        >
                                          {lead?.userName}
                                        </option>
                                      );
                                    })}
                                </DynamicFilterSelect>
                              </div>

                              <div className="col-12">
                                <DynamicFilterSelect
                                  label="Sort by rating:"
                                  value={filters.rating}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      rating: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">select rating</option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </DynamicFilterSelect>
                              </div>
                              <div className="col-12">
                                <DynamicFilterSelect
                                  label="Sort by obstacle:"
                                  value={filters.obstacle}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      obstacle: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">select obstacle</option>
                                  <option value="cost">Cost</option>
                                  <option value="information">
                                    Information
                                  </option>
                                  <option value="both">Both</option>
                                </DynamicFilterSelect>
                              </div>
                              <div className="col-12">
                                <DynamicFilterSelect
                                  label="Sort by Experience:"
                                  value={filters.infoRcieved}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      infoRcieved: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">select experience</option>
                                  <option value="full info">Full Info</option>
                                  <option value="paritial info">
                                    Paritial Info
                                  </option>
                                  <option value="more doubts">
                                    More Doubts
                                  </option>
                                </DynamicFilterSelect>
                              </div>

                              <div className="col-12">
                                <div className="row mt-2">
                                  <div className="col-6">
                                    <button
                                      onClick={() => setRefresh(!refresh)}
                                      className="btn btn-success w-100"
                                    >
                                      Apply Filter
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      onClick={() => {
                                        setFilters({
                                          sortbystatus: "",
                                          startdate: "",
                                          enddate: "",
                                          leadname: "",
                                          leadowner: "",
                                          rating: "",
                                          obstacle: "",
                                          infoRcieved: "",
                                        });
                                        setRefresh(!refresh);
                                      }}
                                      className="btn btn-secondary w-100"
                                    >
                                      Reset Filter
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-body d-none d-md-block">
                          <div className="row">
                            <h3>Filter result</h3>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="Start Date:"
                                value={filters.startdate}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    startdate: e.target.value,
                                  })
                                }
                                type="date"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="End Date:"
                                value={filters.enddate}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    enddate: e.target.value,
                                  })
                                }
                                type="date"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="Search name /mobile:"
                                value={filters.leadname}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    leadname: e.target.value,
                                  })
                                }
                                placeholder="search here..."
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Sort by status:"
                                value={filters.sortbystatus}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    sortbystatus: e.target.value,
                                  })
                                }
                              >
                                <option value="">select status</option>
                                <option value="pending">Pending</option>
                                <option value="feedback recieved">
                                  Feedback Recieved
                                </option>
                              </DynamicFilterSelect>
                            </div>
                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Search lead owner:"
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    leadowner: e.target.value,
                                  })
                                }
                                value={filters.leadowner}
                              >
                                <option value="">select lead owner</option>
                                {leadOwner &&
                                  leadOwner?.map((lead, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={lead?.userName}
                                      >
                                        {lead?.userName}
                                      </option>
                                    );
                                  })}
                              </DynamicFilterSelect>
                            </div>

                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Sort by rating:"
                                value={filters.rating}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    rating: e.target.value,
                                  })
                                }
                              >
                                <option value="">select rating</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </DynamicFilterSelect>
                            </div>
                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Sort by obstacle:"
                                value={filters.obstacle}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    obstacle: e.target.value,
                                  })
                                }
                              >
                                <option value="">select obstacle</option>
                                <option value="cost">Cost</option>
                                <option value="information">Information</option>
                                <option value="both">Both</option>
                              </DynamicFilterSelect>
                            </div>
                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Sort by Experience:"
                                value={filters.infoRcieved}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    infoRcieved: e.target.value,
                                  })
                                }
                              >
                                <option value="">select experience</option>
                                <option value="full info">Full Info</option>
                                <option value="paritial info">
                                  Paritial Info
                                </option>
                                <option value="more doubts">More Doubts</option>
                              </DynamicFilterSelect>
                            </div>

                            <div className="col-12">
                              <div className="row mt-2">
                                <div className="col-6">
                                  <button
                                    onClick={() => setRefresh(!refresh)}
                                    className="btn btn-success w-100"
                                  >
                                    Apply Filter
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    onClick={() => {
                                      setFilters({
                                        sortbystatus: "",
                                        startdate: "",
                                        enddate: "",
                                        leadname: "",
                                        leadowner: "",
                                        rating: "",
                                        obstacle: "",
                                        infoRcieved: "",
                                      });
                                      setRefresh(!refresh);
                                    }}
                                    className="btn btn-secondary w-100"
                                  >
                                    Reset Filter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9 col-sm-12 py-2">
                  <div className="card">
                    {data && (
                      <ClientFeedbackTable
                        feedback={data?.clientFeedbackData}
                        currentPage={page}
                        itemsPerPage={50}
                      />
                    )}
                    <Pagination
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      pageDatalength={data?.clientFeedbackData?.length}
                      showPage={showPage}
                      setShowPage={setShowPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ClientFeedback;
