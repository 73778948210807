import React from "react";
import { useNavigate } from "react-router-dom";
import { extractDate } from "../../../Components/Functions/date.fun";
import { getId } from "../../../Components/Functions/word.fun";
const tableHead = [
  "Sr.",
  "Client Name",
  "Email Id",
  "Phone Number",
  "generated by",
  "Actions",
];
const AllProjectsTable = ({ CP = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    id = getId(id);
    navigate(`/client-projects/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {CP?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.cpNumber}>
                <td>{Index}</td>
                {/* <td>{getId(item?.cpNumber) || "N/A"}</td> */}
                <td>{item?.clientName || "N/A"}</td>
                <td>{item?.clientEmail || "N/A"}</td>
                <td>{item?.clientContactNumber || "N/A"}</td>

                <td>{item?.generatedBy || "N/A"}</td>

                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.cpNumber)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllProjectsTable;
