import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { useEffect, useState } from "react";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import KryzenTop from "../Po-Dc-Pi-PDF-Tables/kryzen_Top";
import SignatureDetails from "../Po-Dc-Pi-PDF-Tables/signature";
import VendorDetails from "../Po-Dc-Pi-PDF-Tables/vendor_details";
import TermsAndConditions from "../Po-Dc-Pi-PDF-Tables/terms_conditions";
import VendorListItems from "../Po-Dc-Pi-PDF-Tables/vendor_list";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
const PreviewPurchaseOrder = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const printPDF = () => {
    let name = "printPDF";
    document.title = name;
    window.print();
  };
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/poDcPi/getPoDcPiById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div
            className="page-header d-print-none"
            style={{ borderBottom: "1px solid #181818", marginBottom: "20px" }}
          >
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">Purchase Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
              <div className="col-auto ms-auto d-print-none">
                <div className="d-flex">
                  <SimpleButton buttonText={"Save Pdf"} onClick={printPDF} />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body" style={{ color: "black" }}>
            <KryzenTop />
            {data && <VendorDetails data={data} />}
            {data && <VendorListItems data={data} />}
            <SignatureDetails />
            <TermsAndConditions />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default PreviewPurchaseOrder;
