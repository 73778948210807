import React from "react";

const SpecificationList = ({ data }) => {
  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "0px",
    padding: "0px",
    paddingLeft: "3px",
    fontSize: "13px",
    color: "black",
    borderCollapse: "collapse",
    textTransform: "uppercase",
  };
  let srNumber = 0;
  return (
    <table style={{ ...style, width: "100%" }}>
      <thead>
        <tr>
          <th style={{ ...style, textAlign: "center", width: "5%" }}>Sr.</th>
          <th style={{ ...style, textAlign: "center", width: "30%" }}>
            Material Name
          </th>
          <th style={{ ...style, textAlign: "center", width: "10%" }}>
            Quantity
          </th>
          <th style={{ ...style, textAlign: "center", width: "10%" }}>Unit</th>
          <th style={{ ...style, textAlign: "center", width: "25%" }}>
            Specification
          </th>
          <th style={{ ...style, textAlign: "center", width: "20%" }}>
            Vendor
          </th>
        </tr>
      </thead>

      <tbody>
        {data?.standardMaterial?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}

        {data?.foggerAssembly?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.seedlingStation?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.airCirculationFans?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.firstBatchStarter?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.aFrameBlockSpecification?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.flatBedBlockSpecifications?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.dutchBucketBlockSpecification?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.growBagsBlockSpecifications?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.aFrameNftSystemSpecification?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.flatbedNFTSystemSpecifications?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.waterTankAssemblyforAFrame?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.plumbingAdhesive?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
        {data?.handbookAndManual?.map((item, index) => {
          if (item.units !== "Program Count") {
            return (
              <tr key={item.item + index}>
                <td style={{ ...style, textAlign: "center" }}>{++srNumber}</td>
                <td style={style}>{item.item || "N/A"}</td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.quantity || "N/A"}
                </td>
                <td style={{ ...style, textAlign: "center" }}>
                  {item.units || "N/A"}
                </td>
                <td style={style}>{item.specification || "N/A"}</td>
                <td style={style}>{item.vendor || "N/A"}</td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default SpecificationList;
