// import { useEffect } from "react";
// import Cookies from "js-cookie";
// import { ToastContainer } from "react-toastify";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import AllRoutes from "./AllRoutes";
// import Head from "./privateHeader";
// import UserLogin from "../Pages/User/user_login";
// import Attendance from "../Pages/User/attendance";

// function CheckedLoggedIn() {
//   const navigate = useNavigate();
//   const loginState = useSelector((state) => state.login);
//   function getTime() {
//     const now = new Date();
//     let expireTime;

//     if (now.getHours() >= 20) {
//       expireTime = new Date(
//         now.getFullYear(),
//         now.getMonth(),
//         now.getDate() + 1,
//         8,
//         0,
//         0
//       );
//     } else {
//       expireTime = new Date(
//         now.getFullYear(),
//         now.getMonth(),
//         now.getDate(),
//         20,
//         0,
//         0
//       );
//     }

//     return expireTime;
//   }

//   function setCookiesOnTimeandRole(user) {
//     if (user) {
//       user = JSON.parse(user);
//     }
//     if (
//       user.Role == process.env.REACT_APP_ROLE_ADMIN_S ||
//       user.Role == process.env.REACT_APP_ROLE_ADMIN ||
//       user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
//       user.Role == process.env.REACT_APP_ROLE_SALLE ||
//       user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
//       user.Role == process.env.REACT_APP_ROLE_OPS ||
//       user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
//       user.Role == process.env.REACT_APP_ROLE_MARKETING
//     ) {
//       Cookies.set("user", JSON.stringify(user), { expires: getTime() });
//     } else {
//       Cookies.set("user", JSON.stringify(user), { expires: 7 });
//     }
//   }

//   function setCookiesOnTimeandRoleLog(user) {
//     if (user) {
//       user = JSON.parse(user);
//     }
//     if (
//       user.Role == process.env.REACT_APP_ROLE_ADMIN_S ||
//       user.Role == process.env.REACT_APP_ROLE_ADMIN ||
//       user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
//       user.Role == process.env.REACT_APP_ROLE_SALLE ||
//       user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
//       user.Role == process.env.REACT_APP_ROLE_OPS ||
//       user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
//       user.Role == process.env.REACT_APP_ROLE_MARKETING
//     ) {
//       Cookies.set("userlog", JSON.stringify(user), { expires: getTime() });
//     }
//   }

//   useEffect(() => {
//     const userCookie = Cookies.get("user") || null;
//     const userCookieLog = Cookies.get("userlog") || null;
//     const tablerTheme = localStorage.getItem("tablerTheme") || "";
//     let userData = "" || null;
//     let userDataLog = "" || null;

//     if (userCookie) {
//       userData = userCookie;
//     }
//     if (userCookieLog) {
//       userDataLog = userCookieLog;
//     }

//     localStorage.clear();
//     sessionStorage.clear();

//     const cookies = Cookies.get();
//     for (const cookie in cookies) {
//       Cookies.remove(cookie);
//     }

//     if (tablerTheme) {
//       localStorage.setItem("tablerTheme", tablerTheme);
//     }

//     if (userData) {
//       setCookiesOnTimeandRole(userData);
//     }
//     if (userDataLog) {
//       setCookiesOnTimeandRoleLog(userDataLog);
//     }
//   }, [loginState]);

// useEffect(() => {
//   if (
//     loginState.isLoggedIn &&
//     loginState.user &&
//     !(loginState.userlog && loginState.userlog.firstLogin) &&
//     (loginState.user.Role == process.env.REACT_APP_ROLE_ADMIN_S ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_ADMIN ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_SALLE ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_OPS ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_MARKETING)
//   ) {
//     navigate("/attendance");
//   }
// }, [loginState]);

//   useEffect(() => {
//     if (!loginState.isLoggedIn) {
//       navigate("/account-login");
//     }
//   }, [loginState]);

//   if (!loginState.isLoggedIn) {
//     return <UserLogin />;
//   }
//   if (
//     loginState.isLoggedIn &&
//     loginState.user &&
//     !(loginState.userlog && loginState.userlog.firstLogin) &&
//     (loginState.user.Role == process.env.REACT_APP_ROLE_ADMIN_S ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_ADMIN ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_SALLE ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_OPS ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
//       loginState.user.Role == process.env.REACT_APP_ROLE_MARKETING)
//   ) {
//     return <Attendance />;
//   }
//   return (
//     <>
//       {loginState.isLoggedIn && (
//         <>
//           {" "}
//           <Head />
//           <AllRoutes />
//           <ToastContainer
//             position="bottom-center"
//             autoClose={5000}
//             hideProgressBar={false}
//             newestOnTop={false}
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//             theme="dark"
//           />
//         </>
//       )}
//       {/* {!loginState && <RoutesWithoutLogin />} */}
//     </>
//   );
// }

// export default CheckedLoggedIn;

import { useEffect } from "react";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import AllRoutes from "./AllRoutes";
import Head from "./privateHeader";
import Attendance from "../Pages/User/attendance";
import { useNavigate } from "react-router";

function CheckedLoggedIn() {
  const navigate = useNavigate();
  // const loginState = useSelector((state) => state.login.isLoggedIn);
  // useEffect(() => {
  //   const userCookie = Cookies.get("user") || null;
  //   const tablerTheme = localStorage.getItem("tablerTheme") || "";
  //   let userData = "" || null;

  //   if (userCookie) {
  //     userData = userCookie;
  //   }

  //   localStorage.clear();
  //   sessionStorage.clear();

  //   const cookies = Cookies.get();
  //   for (const cookie in cookies) {
  //     Cookies.remove(cookie);
  //   }

  //   if (tablerTheme) {
  //     localStorage.setItem("tablerTheme", tablerTheme);
  //   }
  //   if (userData) {
  //     Cookies.set("user", userData);
  //   }
  // });

  const loginState = useSelector((state) => state.login);
  function getTime() {
    const now = new Date();
    let expireTime;

    if (now.getHours() >= 20) {
      expireTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        8,
        0,
        0
      );
    } else {
      expireTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        20,
        0,
        0
      );
    }

    return expireTime;
  }

  function setCookiesOnTimeandRole(user) {
    if (user) {
      user = JSON.parse(user);
    }
    if (
      user.Role == process.env.REACT_APP_ROLE_ADMIN_S ||
      user.Role == process.env.REACT_APP_ROLE_ADMIN ||
      user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
      user.Role == process.env.REACT_APP_ROLE_SALLE ||
      user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
      user.Role == process.env.REACT_APP_ROLE_OPS ||
      user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
      user.Role == process.env.REACT_APP_ROLE_MARKETING ||
      user.Role == process.env.REACT_APP_ROLE_IT ||
      user.Role == process.env.REACT_APP_ROLE_SSM ||
      user.Role == process.env.REACT_APP_ROLE_ACCOUNTS ||
      user.Role == process.env.REACT_APP_ROLE_SALES_MANAGER ||
      user.Role == process.env.REACT_APP_ROLE_CLIENT_FEEDBACK
    ) {
      Cookies.set("user", JSON.stringify(user), { expires: getTime() });
    } else {
      Cookies.set("user", JSON.stringify(user), { expires: 7 });
    }
  }

  function setCookiesOnTimeandRoleLog(user) {
    if (user) {
      user = JSON.parse(user);
    }
    if (
      user.Role == process.env.REACT_APP_ROLE_ADMIN_S ||
      user.Role == process.env.REACT_APP_ROLE_ADMIN ||
      user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
      user.Role == process.env.REACT_APP_ROLE_SALLE ||
      user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
      user.Role == process.env.REACT_APP_ROLE_OPS ||
      user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
      user.Role == process.env.REACT_APP_ROLE_MARKETING ||
      user.Role == process.env.REACT_APP_ROLE_IT ||
      user.Role == process.env.REACT_APP_ROLE_SSM ||
      user.Role == process.env.REACT_APP_ROLE_ACCOUNTS ||
      user.Role == process.env.REACT_APP_ROLE_SALES_MANAGER ||
      user.Role == process.env.REACT_APP_ROLE_CLIENT_FEEDBACK
    ) {
      Cookies.set("userlog", JSON.stringify(user), { expires: getTime() });
    }
  }

  useEffect(() => {
    const userCookie = Cookies.get("user") || null;
    const userCookieLog = Cookies.get("userlog") || null;
    const tablerTheme = localStorage.getItem("tablerTheme") || "";
    let userData = "" || null;
    let userDataLog = "" || null;

    if (userCookie) {
      userData = userCookie;
    }
    if (userCookieLog) {
      userDataLog = userCookieLog;
    }

    localStorage.clear();
    sessionStorage.clear();

    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }

    if (tablerTheme) {
      localStorage.setItem("tablerTheme", tablerTheme);
    }

    if (userData) {
      setCookiesOnTimeandRole(userData);
    }
    if (userDataLog) {
      setCookiesOnTimeandRoleLog(userDataLog);
    }
  }, [loginState]);

  useEffect(() => {
    if (
      loginState.isLoggedIn &&
      loginState.user &&
      !(loginState.userlog && loginState.userlog.firstLogin) &&
      (loginState.user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
        loginState.user.Role == process.env.REACT_APP_ROLE_ADMIN ||
        loginState.user.Role == process.env.REACT_APP_ROLE_SALLE ||
        loginState.user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
        loginState.user.Role == process.env.REACT_APP_ROLE_OPS ||
        loginState.user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
        loginState.user.Role == process.env.REACT_APP_ROLE_MARKETING ||
        loginState.user.Role == process.env.REACT_APP_ROLE_IT ||
        loginState.user.Role == process.env.REACT_APP_ROLE_SSM ||
        loginState.user.Role == process.env.REACT_APP_ROLE_ACCOUNTS ||
        loginState.user.Role == process.env.REACT_APP_ROLE_SALES_MANAGER ||
        loginState.user.Role == process.env.REACT_APP_ROLE_CLIENT_FEEDBACK)
    ) {
      navigate("/attendance");
    }
  }, [loginState]);
  if (
    loginState.isLoggedIn &&
    loginState.user &&
    !(loginState.userlog && loginState.userlog.firstLogin) &&
    (loginState.user.Role == process.env.REACT_APP_ROLE_PURCHASE ||
      loginState.user.Role == process.env.REACT_APP_ROLE_ADMIN ||
      loginState.user.Role == process.env.REACT_APP_ROLE_SALLE ||
      loginState.user.Role == process.env.REACT_APP_ROLE_AGRONOMIST ||
      loginState.user.Role == process.env.REACT_APP_ROLE_OPS ||
      loginState.user.Role == process.env.REACT_APP_ROLE_INSPECTION ||
      loginState.user.Role == process.env.REACT_APP_ROLE_MARKETING ||
      loginState.user.Role == process.env.REACT_APP_ROLE_IT ||
      loginState.user.Role == process.env.REACT_APP_ROLE_SSM ||
      loginState.user.Role == process.env.REACT_APP_ROLE_ACCOUNTS ||
      loginState.user.Role == process.env.REACT_APP_ROLE_SALES_MANAGER ||
      loginState.user.Role == process.env.REACT_APP_ROLE_CLIENT_FEEDBACK)
  ) {
    return <Attendance />;
  }
  return (
    <>
      {loginState?.isLoggedIn &&
        loginState?.user?.Role !== process.env.REACT_APP_ROLE_INTERNAL && (
          <Head />
        )}
      <AllRoutes />
    </>
  );
}

export default CheckedLoggedIn;
