import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import performRequest from "../api/request";
import { Toast } from "../CustomComponent/toast";
import { TbTriangleFilled } from "react-icons/tb";
import { TbTriangleInvertedFilled } from "react-icons/tb";

const Scrollbar = () => {
  const [data, setData] = useState();
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    performRequest("GET", "/priceWatcher/getScrollPriceWatcherData")
      .then((res) => {
        if (res.status === 200) {
          setData(res.data?.priceWatcherData);
        } else {
          Toast("error", "Error while getting Data!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, []);

  return (
    <>
      <div className="d-print-none">
        <Marquee
          style={{
            height: "20px",
            overflow: "hidden",
            cursor: "pointer",
          }}
          pauseOnHover={true}
          pauseOnClick={false}
          play={!isPaused}
        >
          {data?.map((item, index) => (
            <div key={index}>
              <div
                style={{
                  backgroundColor: "black",
                  display: "flex",
                  paddingLeft: "15px",
                }}
              >
                <p
                  style={{
                    fontWeight: 500,
                    marginTop: "15px",
                    color: "white",
                    marginRight: "8px",
                  }}
                >
                  {item.plantName}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: "15px",
                    color: item.comparisonResult == 0 ? "red" : "green",
                  }}
                >
                  {item.comparisonResult !== 0 ? (
                    <>
                      ₹{item.latestPrice}{" "}
                      <TbTriangleFilled
                        style={{ color: "green", marginBottom: "2px" }}
                      />
                    </>
                  ) : (
                    <>
                      ₹{item.latestPrice}{" "}
                      <TbTriangleInvertedFilled
                        style={{ color: "red", marginBottom: "2px" }}
                      />
                    </>
                  )}
                </p>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </>
  );
};

export default Scrollbar;
