import axios from "axios";
import performRequest from "../api/request";

// export const uploadImage = (props, key, img) => {
//   let formData = new FormData();
//   formData.append("files", img);

//   const headers = { "Content-Type": "multipart/form-data" };

//   performRequest("POST", "/fileUploads/fileUpload", formData, headers)
//     .then((res) => {
//       if (
//         res?.data?.data?.uploadSuccess.length > 0 &&
//         res?.data?.data?.uploadSuccess[0].path
//       ) {
//         props.setFieldValue(key, res?.data?.data?.uploadSuccess[0].path);
//       } else {
//         props.setFieldValue(key, "");
//       }
//     })
//     .catch((err) => console.error(err));
// };
function generateUniqueFilename(file) {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 15);
  const extension = file.name.split(".").pop();
  return `${timestamp}_${randomString}.${extension}`;
}
export const uploadImage = async (props, key, file) => {
  try {
    const uniqueFilename = generateUniqueFilename(file);

    function blobToFile(blob, filename) {
      return new File([blob], filename, {
        type: blob.type,
        lastModified: Date.now(),
      });
    }
    file = blobToFile(file, uniqueFilename);
    const res = await performRequest("POST", "/fileUploads/fileUpload", {
      fileName: file.name,
    });
    if (res?.data?.url) {
      let data = await axios.put(res?.data.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      if (data.status === 200) {
        props.setFieldValue(key, file.name);
        // getUplodedImage(props, key, file.name);
      } else {
        props.setFieldValue(key, "");
      }
    } else {
      props.setFieldValue(key, "");
    }
  } catch (error) {
    console.error("Error fetching signed URL:", error);
  }
};

export const getUplodedImage = async (setImageUrls, fileName, key) => {
  try {
    const res = await performRequest(
      "GET",
      `/fileUploads/getUploadedFile?filename=${fileName}`
    );
    if (res.status === 200) {
      setImageUrls((prev) => ({ ...prev, [key]: res.data.url }));
    }
  } catch (error) {
    console.error("Error fetching", error);
  }
};
