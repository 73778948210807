import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import performRequest from "../../../api/request";
import StepRoutes from "../../../Routes/steps_routes";
import { Form, Formik } from "formik";
import { QuotationSchema } from "../../../Schemas/quotation/quotation_schema";
import { Toast } from "../../../CustomComponent/toast";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { useSelector } from "react-redux";
import LoadingPage from "../../../Components/layouts/loading";

import { FcSurvey } from "react-icons/fc";

const EditQuotation = () => {
  const userData = useSelector((store) => store.login.user);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [quotationInfo, setQuotationInfo] = useState([]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/quotations/getQuotationsById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setQuotationInfo(res.data.input_info);
        } else {
          Toast("error", "Error while getting Quotations!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.err(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const handelSubmit = (values, actions) => {
    values = { ...values, maxDiscount: +values.maxDiscount || 0 };

    let totalPercent =
      +values.a_FramePercent +
      +values.flatBedPercent +
      +values.growBagPercent +
      +values.dutchBucketPercent;

    if (totalPercent > 100) {
      Toast("error", "Total Percentage of Frames should be 100%");
    } else if (
      values.quotationType !== "nethouse" &&
      values.quotationType !== "fanpad" &&
      values.quotationType !== "nvph" &&
      totalPercent < 25
    ) {
      Toast("error", "Total Percentage of Frames At Least 25%");
    } else if (values.maxDiscount > 6) {
      Toast("error", "Total discount should be less than 6%");
    } else if (
      values.phoneNumber == "0000000000" ||
      values.phoneNumber == "1111111111" ||
      values.phoneNumber == "2222222222" ||
      values.phoneNumber == "3333333333" ||
      values.phoneNumber == "4444444444" ||
      values.phoneNumber == "5555555555" ||
      values.phoneNumber == "6666666666" ||
      values.phoneNumber == "7777777777" ||
      values.phoneNumber == "8888888888" ||
      values.phoneNumber == "9999999999" ||
      values.phoneNumber == "1234567890" ||
      values.phoneNumber == "9876543210" ||
      values.phoneNumber == "0987654321"
    ) {
      Toast("error", "Invalid phone number");
    } else {
      setLoading(true);
      performRequest("PATCH", `/quotations/updateQuotations/${id}`, values)
        .then((res) => {
          if (res.status === 200) {
            Toast("success", "Quotation updated successfully!");
            navigate(`/custom-quotation/${id}`);
          } else {
            Toast("error", "Error while updating");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    }
  };
  if (quotationInfo?.finalQuotation) {
    Toast("error", "Quotation finalised!, Not available for Edit.");
    navigate("/custom-quotation");
  }
  if (loading) {
    return <LoadingPage />;
  }
  const style = {
    border: "1px solid #c4c4c4",
  };
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcSurvey className="fs-2  me-1" /> Edit quotations
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Edit previously sent quotation.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>SqFt.</th>
                        <th>Bay</th>
                        <th>Span</th>
                        <th>SqM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1034</td>
                        <td>8</td>
                        <td>12</td>
                        <td>96</td>
                      </tr>
                      <tr>
                        <td>1551</td>
                        <td>12</td>
                        <td>12</td>
                        <td>144</td>
                      </tr>
                      <tr>
                        <td>2067</td>
                        <td>16</td>
                        <td>12</td>
                        <td>192</td>
                      </tr>
                      <tr>
                        <td>2584</td>
                        <td>20</td>
                        <td>12</td>
                        <td>240</td>
                      </tr>
                      <tr>
                        <td>3101</td>
                        <td>24</td>
                        <td>12</td>
                        <td>288</td>
                      </tr>
                      <tr>
                        <td>3617</td>
                        <td>28</td>
                        <td>12</td>
                        <td>336</td>
                      </tr>
                      <tr>
                        <td>4134</td>
                        <td>32</td>
                        <td>12</td>
                        <td>384</td>
                      </tr>
                      <tr>
                        <td>4823</td>
                        <td>16</td>
                        <td>28</td>
                        <td>448</td>
                      </tr>
                      <tr>
                        <td>5167</td>
                        <td>24</td>
                        <td>20</td>
                        <td>480</td>
                      </tr>
                      <tr>
                        <td>6028</td>
                        <td>20</td>
                        <td>28</td>
                        <td>560</td>
                      </tr>
                      <tr>
                        <td>9989</td>
                        <td>116</td>
                        <td>8</td>
                        <td>928</td>
                      </tr>
                      <tr>
                        <td>10851</td>
                        <td>36</td>
                        <td>28</td>
                        <td>1008</td>
                      </tr>
                      <tr>
                        <td>11195</td>
                        <td>52</td>
                        <td>20</td>
                        <td>1040</td>
                      </tr>
                      <tr>
                        <td>12056</td>
                        <td>140</td>
                        <td>8</td>
                        <td>1120</td>
                      </tr>
                      <tr>
                        <td>16878</td>
                        <td>56</td>
                        <td>28</td>
                        <td>1568</td>
                      </tr>
                      <tr>
                        <td>20667</td>
                        <td>32</td>
                        <td>60</td>
                        <td>1920</td>
                      </tr>
                      <tr>
                        <td>26351</td>
                        <td>36</td>
                        <td>68</td>
                        <td>2448</td>
                      </tr>
                      <tr>
                        <td>33584</td>
                        <td>52</td>
                        <td>60</td>
                        <td>3120</td>
                      </tr>
                      <tr>
                        <td>34101</td>
                        <td>88</td>
                        <td>36</td>
                        <td>3168</td>
                      </tr>
                      <tr>
                        <td>42540</td>
                        <td>76</td>
                        <td>52</td>
                        <td>3952</td>
                      </tr>
                      <tr>
                        <td>44951</td>
                        <td>36</td>
                        <td>116</td>
                        <td>1 Acre</td>
                      </tr>
                      <tr>
                        <td>49601</td>
                        <td>128</td>
                        <td>36</td>
                        <td> 1 Acre</td>
                      </tr>
                      <tr>
                        <td>54251</td>
                        <td>36</td>
                        <td>140</td>
                        <td> 1.2 Acre</td>
                      </tr>
                      <tr>
                        <td>58729</td>
                        <td>44</td>
                        <td>124</td>
                        <td> 1.3 Acre</td>
                      </tr>
                      <tr>
                        <td>68718</td>
                        <td>76</td>
                        <td>84</td>
                        <td> 1.5 Acre</td>
                      </tr>
                      <tr>
                        <td>93001</td>
                        <td>144</td>
                        <td>60</td>
                        <td>2 Acre</td>
                      </tr>
                      <tr>
                        <td>105229</td>
                        <td>52</td>
                        <td>188</td>
                        <td>2.5 Acre</td>
                      </tr>
                      <tr>
                        <td>132096</td>
                        <td>52</td>
                        <td>236</td>
                        <td>3 Acre</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={quotationInfo}
                  validationSchema={QuotationSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Quotation date
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="quotationDate" type="date" />
                            {props.touched.quotationDate &&
                              props.errors.quotationDate && (
                                <p className="text-danger">
                                  {props.errors.quotationDate}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label">
                            Currency code
                          </label>
                          <div className="w-100 col">
                            <CustomSelect type="text" name="currencyCode">
                              <option value="">Select</option>
                              <option value="INR">INR</option>
                            </CustomSelect>
                            {props.touched.currencyCode &&
                              props.errors.currencyCode && (
                                <p className="text-danger">
                                  {props.errors.currencyCode}
                                </p>
                              )}
                          </div>
                        </div>
                        {/* <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Cost markup percent
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="number" name="costMarkupPercent">
                          <option value="">Select</option>
                          <option value={0}>0%</option>
                          <option value={25}>25%</option>
                          <option value={50}>50%</option>
                          <option value={75}>75%</option>
                          <option value={100}>100%</option>
                        </CustomSelect>
                        {props.touched.costMarkupPercent &&
                          props.errors.costMarkupPercent && (
                            <p className="text-danger">
                              {props.errors.costMarkupPercent}
                            </p>
                          )}
                      </div>
                    </div> */}
                        {userData?.Role ==
                          process.env.REACT_APP_ROLE_ADMIN_S && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3">
                              Override all cost
                            </label>
                            <div className="w-100 col">
                              <CustomInput
                                name="overrideUnitCost"
                                type="number"
                              />
                              {props.touched.overrideUnitCost &&
                                props.errors.overrideUnitCost && (
                                  <p className="text-danger">
                                    {props.errors.overrideUnitCost}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Bay size
                          </label>
                          <div className="w-100 col">
                            <CustomSelect type="number" name="bay_size">
                              <option value="">Select</option>
                              {[
                                8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52,
                                56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100,
                                104, 108, 112, 116, 120, 124, 128, 132, 136,
                                140, 144,
                              ].map((number) => (
                                <option key={number} value={number}>
                                  {number} Mtr
                                </option>
                              ))}
                            </CustomSelect>
                            {props.touched.bay_size &&
                              props.errors.bay_size && (
                                <p className="text-danger">
                                  {props.errors.bay_size}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Span size
                          </label>
                          <div className="w-100 col">
                            <CustomSelect type="number" name="span_size">
                              <option value="">Select</option>
                              {[
                                8, 12, 20, 28, 36, 44, 52, 60, 68, 76, 84, 92,
                                100, 108, 116, 124, 132, 140, 148, 156, 164,
                                172, 180, 188, 196, 204, 212, 220, 228, 236,
                                244, 252, 260, 268, 276,
                              ].map((number) => (
                                <option key={number} value={number}>
                                  {number} Mtr
                                </option>
                              ))}
                            </CustomSelect>
                            {props.touched.span_size &&
                              props.errors.span_size && (
                                <p className="text-danger">
                                  {props.errors.span_size}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required">
                            Quotation type
                          </label>
                          <div className="w-100 col">
                            <CustomSelect type="text" name="quotationType">
                              <option value="">Select</option>
                              <option value="fanpad">Only FanPad</option>
                              <option value="nvph">Only NVPH</option>
                              <option value="nethouse">Only NetHouse</option>
                              <option value="fanpad with hydroponics">
                                FanPad With Hydroponics
                              </option>
                              <option value="nvph with hydroponics">
                                NVPH With Hydroponics
                              </option>
                              <option value="nethouse with hydroponics">
                                NetHouse With Hydroponics
                              </option>
                              <option value="hydroponics only">
                                Hydroponics Only
                              </option>
                              {/*
                          <option value="hydrobuild xs">Hydrobuild XS</option>
                          <option value="hydrobuild mini">
                            Hydrobuild Mini
                          </option>
                          <option value="hydrobuild pro">Hydrobuild Pro</option> */}
                            </CustomSelect>
                            {props.touched.quotationType &&
                              props.errors.quotationType && (
                                <p className="text-danger">
                                  {props.errors.quotationType}
                                </p>
                              )}
                          </div>
                        </div>
                        {props.values.quotationType !== "nethouse" &&
                          props.values.quotationType !== "fanpad" &&
                          props.values.quotationType !== "nvph" && (
                            <div>
                              <div className="mb-3 row">
                                <label className="w-25 form-label col-3">
                                  A-Frame percent
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect
                                    type="number"
                                    name="a_FramePercent"
                                  >
                                    <option value="">Select</option>
                                    <option value={0}>0%</option>
                                    <option value={25}>25%</option>
                                    <option value={50}>50%</option>
                                    <option value={75}>75%</option>
                                    <option value={100}>100%</option>
                                  </CustomSelect>
                                  {props.touched.a_FramePercent &&
                                    props.errors.a_FramePercent && (
                                      <p className="text-danger">
                                        {props.errors.a_FramePercent}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="w-25 form-label col-3">
                                  Flat Bed percent
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect
                                    type="number"
                                    name="flatBedPercent"
                                  >
                                    <option value="">Select</option>
                                    <option value={0}>0%</option>
                                    <option value={25}>25%</option>
                                    <option value={50}>50%</option>
                                    <option value={75}>75%</option>
                                    <option value={100}>100%</option>
                                  </CustomSelect>
                                  {props.touched.flatBedPercent &&
                                    props.errors.flatBedPercent && (
                                      <p className="text-danger">
                                        {props.errors.flatBedPercent}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="w-25 form-label col-3">
                                  Grow Bag percent
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect
                                    type="number"
                                    name="growBagPercent"
                                  >
                                    <option value="">Select</option>
                                    <option value={0}>0%</option>
                                    <option value={25}>25%</option>
                                    <option value={50}>50%</option>
                                    <option value={75}>75%</option>
                                    <option value={100}>100%</option>
                                  </CustomSelect>
                                  {props.touched.growBagPercent &&
                                    props.errors.growBagPercent && (
                                      <p className="text-danger">
                                        {props.errors.growBagPercent}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="w-25 form-label col-3">
                                  Dutch Bucket percent
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect
                                    type="number"
                                    name="dutchBucketPercent"
                                  >
                                    <option value="">Select</option>
                                    <option value={0}>0%</option>
                                    <option value={25}>25%</option>
                                    <option value={50}>50%</option>
                                    <option value={75}>75%</option>
                                    <option value={100}>100%</option>
                                  </CustomSelect>
                                  {props.touched.dutchBucketPercent &&
                                    props.errors.dutchBucketPercent && (
                                      <p className="text-danger">
                                        {props.errors.dutchBucketPercent}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        {/* <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Farm type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="number" name="farmType">
                          <option value="">Select</option>
                          <option value="indoor">Indoor</option>
                          <option value="outdoor">Outdoor</option>
                        </CustomSelect>
                        {props.touched.farmType && props.errors.farmType && (
                          <p className="text-danger">{props.errors.farmType}</p>
                        )}
                      </div>
                    </div> */}
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Business name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="businessName" type="text" />
                            {props.touched.businessName &&
                              props.errors.businessName && (
                                <p className="text-danger">
                                  {props.errors.businessName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Contact person name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="contactPersonName" type="text" />
                            {props.touched.contactPersonName &&
                              props.errors.contactPersonName && (
                                <p className="text-danger">
                                  {props.errors.contactPersonName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Phone number
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="phoneNumber" type="text" />
                            {props.touched.phoneNumber &&
                              props.errors.phoneNumber && (
                                <p className="text-danger">
                                  {props.errors.phoneNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            WhatsApp number
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="whatsAppNumber" type="text" />
                            {props.touched.whatsAppNumber &&
                              props.errors.whatsAppNumber && (
                                <p className="text-danger">
                                  {props.errors.whatsAppNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Contact person email
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              type="email"
                              name="contactPersonEmail"
                              min={0}
                              max={35}
                            />
                            {props.touched.contactPersonEmail &&
                              props.errors.contactPersonEmail && (
                                <p className="text-danger">
                                  {props.errors.contactPersonEmail}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Gst number
                          </label>
                          <div className="w-100 col">
                            <CustomInput type="text" name="gstNumber" />

                            {props.touched.gstNumber &&
                              props.errors.gstNumber && (
                                <p className="text-danger">
                                  {props.errors.gstNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Address
                          </label>

                          <div className="w-100 col">
                            <CustomInput type="text" name="address" />

                            {props.touched.address && props.errors.address && (
                              <p className="text-danger">
                                {props.errors.address}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Show project financials
                          </label>
                          <div className="w-100 col">
                            <CustomCheckbox
                              name="showProjectFinancials"
                              type="checkbox"
                              width={"20px"}
                              height={"20px"}
                            />
                            {props.touched.showProjectFinancials &&
                              props.errors.showProjectFinancials && (
                                <p className="text-danger">
                                  {props.errors.showProjectFinancials}
                                </p>
                              )}
                          </div>
                        </div>
                        {userData?.Role ==
                          process.env.REACT_APP_ROLE_ADMIN_S && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3">
                              Final quotation
                            </label>
                            <div className="w-100 col">
                              <CustomCheckbox
                                name="finalQuotation"
                                type="checkbox"
                                width={"20px"}
                                height={"20px"}
                              />
                              {props.touched.finalQuotation &&
                                props.errors.finalQuotation && (
                                  <p className="text-danger">
                                    {props.errors.finalQuotation}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                        {userData?.Role ==
                          process.env.REACT_APP_ROLE_ADMIN_S && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3">
                              Max discount
                            </label>

                            <div className="w-100 col">
                              <CustomInput type="text" name="maxDiscount" />

                              {props.touched.maxDiscount &&
                                props.errors.maxDiscount && (
                                  <p className="text-danger">
                                    {props.errors.maxDiscount}
                                  </p>
                                )}
                            </div>
                          </div>
                        )}
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">Notes</label>

                          <div className="w-100 col">
                            <CustomInput type="text" name="notes" />
                            {props.touched.notes && props.errors.notes && (
                              <p className="text-danger">
                                {props.errors.notes}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Save Quotation" />

                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default EditQuotation;
