import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import { LeadsSchema } from "../../../Schemas/sales/leads_schema";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { FieldArray, Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import CustomSelect from "../../../CustomComponent/custom_select";
import { useSelector } from "react-redux";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { ClientFeedbackInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import { ClientFeedbackSchema } from "../../../Schemas/sales/client_feedback";

const AddClientFeedback = () => {
  const store = useSelector((store) => store.login.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [leadOwner, setLeadOwner] = useState([]);
  const [clientFeedBackInfo, setClientFeedbackInfo] =
    useState(ClientFeedbackInfo);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryId = searchParams.get("id");
    setLoading(true);
    performRequest("GET", `/clientFeedBack/getClientFeedBackById/${queryId}`)
      .then((res) => {
        if (res.status === 200) {
          setClientFeedbackInfo(res.data);
        } else {
          Toast("error", "Error while getting client feedback!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [location.search]);

  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res.data))
      .catch((err) => console.error(err));
  }, []);

  const handelSubmit = (values, actions) => {
    const searchParams = new URLSearchParams(location.search);
    const queryId = searchParams.get("id");
    values = {
      ...values,
      feedBackStatus: "feedback recieved",
    };
    setLoading(true);
    performRequest(
      "PATCH",
      `/clientFeedBack/updateClientFeedBack/${queryId}`,
      values
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Feedback updated successfully!");
          navigate(`/client-feedback`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Client Feedback</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Add Feedback</h2>
            </div>
            <Formik
              initialValues={clientFeedBackInfo}
              validationSchema={ClientFeedbackSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Client name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="clientName" type="text" disabled />
                        {props.touched.clientName &&
                          props.errors.clientName && (
                            <p className="text-danger">
                              {props.errors.clientName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Client contact
                      </label>

                      <div className="w-100 col">
                        <CustomInput
                          type="text"
                          name="clientContactNumber"
                          disabled
                        />

                        {props.touched.clientContactNumber &&
                          props.errors.clientContactNumber && (
                            <p className="text-danger">
                              {props.errors.clientContactNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Client email
                      </label>

                      <div className="w-100 col">
                        <CustomInput name="clientEmail" type="email" disabled />
                        {props.touched.clientEmail &&
                          props.errors.clientEmail && (
                            <p className="text-danger">
                              {props.errors.clientEmail}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Client origin
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="origin" disabled>
                          <option value="">Select</option>
                          <option value="quotation">Quotation</option>
                          <option value="leads">Leads</option>
                          <option value="graphy">Graphy</option>
                        </CustomSelect>
                        {props.touched.origin && props.errors.origin && (
                          <p className="text-danger">{props.errors.origin}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Feedback status
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          type="text"
                          name="feedBackStatus"
                          disabled
                        >
                          {" "}
                          <option value="">select</option>
                          <option value="pending">Pending</option>
                          <option value="feedback recieved">
                            Feedback Recieved
                          </option>
                        </CustomSelect>
                        {props.touched.feedBackStatus &&
                          props.errors.feedBackStatus && (
                            <p className="text-danger">
                              {props.errors.feedBackStatus}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Lead owner
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="leadOwner" disabled>
                          <option value="">Select</option>
                          {leadOwner &&
                            leadOwner?.map((lead, index) => {
                              return (
                                <option key={index} value={lead?.userName}>
                                  {lead?.userName}
                                </option>
                              );
                            })}
                          <option value="quotation">Quotation</option>
                          <option value="leads">Leads</option>
                          <option value="graphy">Graphy</option>
                        </CustomSelect>
                        {props.touched.leadOwner && props.errors.leadOwner && (
                          <p className="text-danger">
                            {props.errors.leadOwner}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Experience
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="experience">
                          <option value="">select</option>
                          <option value="full info">Full Info</option>
                          <option value="paritial info">Paritial Info</option>
                          <option value="more doubts">More Doubts</option>
                        </CustomSelect>

                        {props.touched.experience &&
                          props.errors.experience && (
                            <p className="text-danger">
                              {props.errors.experience}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Rating</label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="rating">
                          <option value="">Select</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </CustomSelect>
                        {props.touched.rating && props.errors.rating && (
                          <p className="text-danger">{props.errors.rating}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Obstracle</label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="obstracle">
                          <option value="">Select</option>
                          <option value="cost">Cost</option>
                          <option value="information">Information</option>
                          <option value="cost & information">Both</option>
                        </CustomSelect>
                        {props.touched.obstracle && props.errors.obstracle && (
                          <p className="text-danger">
                            {props.errors.obstracle}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Data insufficient
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="dataInsufficient"
                          type="checkbox"
                          width={"20px"}
                          height={"20px"}
                        />
                        {props.touched.dataInsufficient &&
                          props.errors.dataInsufficient && (
                            <p className="text-danger">
                              {props.errors.dataInsufficient}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Follow up</label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="followUp"
                          type="checkbox"
                          width={"20px"}
                          height={"20px"}
                        />
                        {props.touched.followUp && props.errors.followUp && (
                          <p className="text-danger">{props.errors.followUp}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Follow up date
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="date" name="followUpDate" />
                        {props.touched.followUpDate &&
                          props.errors.followUpDate && (
                            <p className="text-danger">
                              {props.errors.followUpDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Feedback</label>
                      <div className="w-100 col">
                        <CustomTextArea name="feedback" rows="5" />
                        {props.touched.feedback && props.errors.feedback && (
                          <p className="text-danger">{props.errors.feedback}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Feedback" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default AddClientFeedback;
