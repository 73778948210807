import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import { LeadsSchema } from "../../../Schemas/sales/leads_schema";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { FieldArray, Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import CustomSelect from "../../../CustomComponent/custom_select";
import { useSelector } from "react-redux";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { LeadInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import { uploadImage } from "../../../CustomComponent/upload_Image";

const AddLeads = () => {
  const store = useSelector((store) => store.login.user);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 30);
  const maxDateString = maxDate.toISOString().split("T")[0];

  const handelSubmit = (values, actions) => {
    if (
      values.phone_number === "0000000000" ||
      values.phone_number === "1111111111" ||
      values.phone_number === "2222222222" ||
      values.phone_number === "3333333333" ||
      values.phone_number === "4444444444" ||
      values.phone_number === "5555555555" ||
      values.phone_number === "6666666666" ||
      values.phone_number === "7777777777" ||
      values.phone_number === "8888888888" ||
      values.phone_number === "9999999999" ||
      values.phone_number === "1234567890" ||
      values.phone_number === "9876543210" ||
      values.phone_number === "0987654321"
    ) {
      Toast("error", "Invalid phone number");
    } else if (
      values.followUp &&
      values.followUp.length > 0 &&
      !values.followUp[values.followUp.length - 1].followUpDate
    ) {
      Toast("error", "Please Add followUp Date before Create");
    } else {
      if (values.followUp && values.followUp.length > 0) {
        values.followUp = values.followUp.map((followup) => {
          if (followup.followupTaken && !followup.followupTakenDate) {
            return {
              ...followup,
              followupTakenDate: new Date().toISOString().split("T")[0],
            };
          } else {
            return followup;
          }
        });
      }
      values = {
        ...values,
        reAssignTo: store?.Name,
        leadsFiltered: true,
        full_name: `${values.first_name} ${values.last_name}`,
        lead_alloted_to: store.Name,
      };
      setLoading(true);
      performRequest("POST", "/leads/createleads", values)
        .then((res) => {
          if (res.status === 201) {
            Toast("success", "Leads generated successfully!");
            setTimeout(() => {
              navigate(`/leads-filtered/view/${res.data.id}`);
            }, 1000);
          } else {
            Toast("error", "Error while uploading");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    }
  };
  const checkTouched = (obj) => {
    if (obj.followUp === "" && obj.followUpDate === "") {
      return false;
    } else {
      return true;
    }
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Leads</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Leads</h2>
            </div>
            <Formik
              initialValues={LeadInfo}
              validationSchema={LeadsSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        First name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="first_name" type="text" />
                        {props.touched.first_name &&
                          props.errors.first_name && (
                            <p className="text-danger">
                              {props.errors.first_name}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label">Last name</label>
                      <div className="w-100 col">
                        <CustomInput name="last_name" type="text" />
                        {props.touched.last_name && props.errors.last_name && (
                          <p className="text-danger">
                            {props.errors.last_name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Email</label>
                      <div className="w-100 col">
                        <CustomInput type="email" name="email" />
                        {props.touched.email && props.errors.email && (
                          <p className="text-danger">{props.errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Phone number
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="phone_number" type="text" />
                        {props.touched.phone_number &&
                          props.errors.phone_number && (
                            <p className="text-danger">
                              {props.errors.phone_number}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Address</label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="address" />

                        {props.touched.address && props.errors.address && (
                          <p className="text-danger">{props.errors.address}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Planned area
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="planned_area">
                          <option value="">Select</option>
                          <option value="1000-5000 sqft">
                            1000 - 5000 sqft
                          </option>
                          <option value="5000-10000 sqft">
                            5000 - 10000 sqft
                          </option>
                          <option value="10000-20000 sqft">
                            10000 - 20000 sqft
                          </option>
                          <option value="20000-40000 sqft">
                            20000 - 40000 sqft
                          </option>
                          <option value="1 acre">1 Acre</option>
                          <option value="2 acre">2 Acre</option>
                          <option value="3 acre">3 Acre</option>
                          <option value="4 acre">4 Acre</option>
                          <option value="5 acre">5 Acre</option>
                          <option value="6 acre">6 Acre</option>
                          <option value="7 acre">7 Acre</option>
                          <option value="8 acre">8 Acre</option>
                          <option value="9 acre">9 Acre</option>
                          <option value="10 acre">10 Acre</option>
                          <option value="10+ acre">10+ Acre</option>
                        </CustomSelect>
                        {props.touched.planned_area &&
                          props.errors.planned_area && (
                            <p className="text-danger">
                              {props.errors.planned_area}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Location type
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="location_type">
                          <option value="">Select</option>
                          <option value="farm_land">Farm Land</option>
                          <option value="n/a_plot">N/A Plot</option>
                          <option value="indoor">Indoor</option>
                          <option value="terrace">Terrace</option>
                        </CustomSelect>
                        {props.touched.location_type &&
                          props.errors.location_type && (
                            <p className="text-danger">
                              {props.errors.location_type}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Facilities
                      </label>

                      <div className="w-100 col">
                        <div>
                          <label className="form-check-inline">
                            <CustomCheckbox
                              type="checkbox"
                              name="facilities"
                              value="water Available"
                              width={"15px"}
                            />

                            <span className="form-check-label">
                              Water Available
                            </span>
                          </label>
                          <label className="form-check-inline">
                            <CustomCheckbox
                              type="checkbox"
                              name="facilities"
                              value="electricity available"
                              width={"15px"}
                            />

                            <span className="form-check-label">
                              Electricity Available
                            </span>
                          </label>
                        </div>
                        {props.touched.facilities &&
                          props.errors.facilities && (
                            <p className="text-danger">
                              {props.errors.facilities}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Company name
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="company_name" />

                        {props.touched.company_name &&
                          props.errors.company_name && (
                            <p className="text-danger">
                              {props.errors.company_name}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        GST Number
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="gst_number" />

                        {props.touched.gst_number &&
                          props.errors.gst_number && (
                            <p className="text-danger">
                              {props.errors.gst_number}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lead generated from
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="lead_genrated_from">
                          <option value="">Select</option>
                          <option value="myoperator">MyOperator</option>
                          <option value="hydroponic masterclass">
                            Hydroponic Masterclass
                          </option>
                          <option value="whatsapp">WhatsApp</option>
                          <option value="walkin">WalkIn</option>
                          <option value="enquiry">Enquiry</option>
                          <option value="repeat customer">
                            Repeat Customer
                          </option>
                          <option value="other">Other</option>
                        </CustomSelect>
                        {props.touched.lead_genrated_from &&
                          props.errors.lead_genrated_from && (
                            <p className="text-danger">
                              {props.errors.lead_genrated_from}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Interested in
                      </label>
                      <div className="w-100 col">
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                name="interestedIn"
                                width={"15px"}
                                height={"15px"}
                                value="hydroponic masterclass"
                              />
                            </label>
                            <span className="form-check-label">
                              Hydroponic Masterclass
                            </span>
                          </div>
                          {props.values.interestedIn &&
                            props.values.interestedIn.includes(
                              "hydroponic masterclass"
                            ) && (
                              <p>
                                More details on{" "}
                                <span className="text-blue">
                                  hydroponicmasterclass.com
                                </span>{" "}
                                website
                              </p>
                            )}
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                name="interestedIn"
                                width={"15px"}
                                height={"15px"}
                                value="hydroponic homekit"
                                type="checkbox"
                              />
                            </label>
                            <span className="form-check-label">
                              Hydroponic Homekit
                            </span>
                          </div>
                          {props.values.interestedIn &&
                            props.values.interestedIn.includes(
                              "hydroponic homekit"
                            ) && (
                              <p>
                                More details on
                                <span className="text-blue"> kryzen.com </span>
                                (Homekit tab)
                              </p>
                            )}
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                width={"15px"}
                                height={"15px"}
                                name="interestedIn"
                                value="hydroponic setup"
                              />
                            </label>
                            <span className="form-check-label">
                              Hydroponic Setup
                            </span>
                          </div>
                          {props.values.interestedIn &&
                            props.values.interestedIn.includes(
                              "hydroponic setup"
                            ) && (
                              <p>
                                Commercial hydroponic setup (Sales team will
                                arrange video call)
                              </p>
                            )}
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                width={"15px"}
                                height={"15px"}
                                name="interestedIn"
                                value="farm training"
                              />
                            </label>
                            <span className="form-check-label">
                              Farm Training
                            </span>
                          </div>
                          {props.values.interestedIn &&
                            props.values.interestedIn.includes(
                              "farm training"
                            ) && (
                              <p>
                                More details on
                                <span className="text-blue"> kryzen.com </span>
                                (farm training tab)
                              </p>
                            )}
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                width={"15px"}
                                height={"15px"}
                                name="interestedIn"
                                value="farm visit"
                              />
                            </label>
                            <span className="form-check-label">Farm Visit</span>
                          </div>
                          {props.values.interestedIn &&
                            props.values.interestedIn.includes(
                              "farm visit"
                            ) && (
                              <p>
                                Book Farm visit by calling
                                <span className="text-blue">
                                  {" "}
                                  +91-9870424425{" "}
                                </span>
                              </p>
                            )}
                        </div>
                        <div>
                          <div className="d-flex">
                            <label className="form-check-inline">
                              <CustomCheckbox
                                type="checkbox"
                                width={"15px"}
                                height={"15px"}
                                name="interestedIn"
                                value="association"
                              />
                            </label>
                            <span className="form-check-label">
                              Association
                            </span>
                          </div>
                          {props.values.interestedIn &&
                            props.values.interestedIn.includes(
                              "association"
                            ) && (
                              <p>
                                write your query to{" "}
                                <span className="text-blue">
                                  contact@kryzen.com
                                </span>
                              </p>
                            )}
                        </div>

                        {props.touched.interestedIn &&
                          props.errors.interestedIn && (
                            <p className="text-danger">
                              {props.errors.interestedIn}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Lead status
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="lead_status">
                          <option value="">Select</option>
                          <option value="cold">Cold (Not Interested)</option>
                          <option value="hot">Hot</option>
                          <option value="warm">Warm (May be)</option>
                        </CustomSelect>
                        {props.touched.lead_status &&
                          props.errors.lead_status && (
                            <p className="text-danger">
                              {props.errors.lead_status}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Upload call recording
                      </label>

                      <div className="w-100 col">
                        <CustomInput
                          name="callRecordingUpload"
                          type="file"
                          onChange={(e) => {
                            uploadImage(
                              props,
                              "callRecordingUpload",
                              e.target.files[0]
                            );
                          }}
                        />
                        {props.touched.callRecordingUpload &&
                          props.errors.callRecordingUpload && (
                            <p className="text-danger">
                              {props.errors.callRecordingUpload}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">Notes</label>
                      <div className="w-100 col">
                        <CustomTextArea type="textarea" name="notes" />

                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                    <hr />
                    <h3>Follow UP</h3>
                    <hr />

                    <FieldArray name="followUp">
                      {({ push, remove }) => (
                        <div>
                          {props.values.followUp?.map((followup, index) => (
                            <div key={index}>
                              <div className="row g-3 align-items-center">
                                <div className="col-md">
                                  <label className=" form-label required">
                                    Message
                                  </label>
                                  <div className="w-100 col">
                                    <CustomTextArea
                                      type="text"
                                      name={`followUp[${index}].followUp`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label required">
                                    Follwup date
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type="date"
                                      name={`followUp[${index}].followUpDate`}
                                      max={maxDateString}
                                      onChange={props.handleChange}
                                      value={
                                        props.values.followUp[index]
                                          .followUpDate
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md row">
                                  <div className="col-md">
                                    <label className=" form-label">
                                      Arranged video call
                                    </label>
                                    <div className="w-100 col">
                                      <CustomCheckbox
                                        type="checkbox"
                                        width={"25px"}
                                        height={"25px"}
                                        name={`followUp[${index}].videoCall`}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md">
                                    <label className=" form-label">
                                      Followup Done
                                    </label>
                                    <div className="w-100 col">
                                      <CustomCheckbox
                                        type="checkbox"
                                        width={"25px"}
                                        height={"25px"}
                                        name={`followUp[${index}].followupTaken`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))}

                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              const newFollowUpInfo = {
                                followUp: "",
                                followUpDate: "",
                                videoCall: false,
                                followupTaken: false,
                                followupTakenDate: "",
                                createdAt: new Date()
                                  .toISOString()
                                  .split("T")[0],
                                createdBy: store.Name,
                              };

                              if (props.values.followUp.length === 0) {
                                push(newFollowUpInfo);
                              } else if (
                                props.values.followUp.length > 0 &&
                                checkTouched(
                                  props.values.followUp[
                                    props.values.followUp.length - 1
                                  ]
                                )
                              ) {
                                push(newFollowUpInfo);
                              } else {
                                Toast(
                                  "error",
                                  "Please fill all follow up details."
                                );
                              }
                            }}
                          >
                            Add Follow Up
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Lead" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default AddLeads;
