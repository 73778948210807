import React, { useEffect, useState } from "react";
import Footer from "../../../Kryzen Alpha App/Components/Footer/footer";
import StepRoutes from "../../../Kryzen Alpha App/Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Kryzen Alpha App/Components/layouts/loading";
import { Toast } from "../../../Kryzen Alpha App/CustomComponent/toast";
import performRequest from "../../../Kryzen Alpha App/api/request";

const ViewSiteInsppectionFeedback = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/siteInspectionFeedback/getFeedbackById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          console.log(data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">
                  Site Inspection Feedback Tracking
                </h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">View Site Inspection Feedback</h2>
            </div>

            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    How was your experience regarding scheduling a site
                    inspection?
                  </label>
                  <div className="w-100 col">
                    {data?.schedulingExperience.toUpperCase() || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Did our agent reach the location on time?
                  </label>
                  <div className="w-100 col">
                    {data?.agentOnTime.toUpperCase() || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Did our agent answer all your queries?
                  </label>
                  <div className="w-100 col">
                    {data?.queriesAnswered.toUpperCase() || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Did our agent give you a fair idea about climate conditions?
                  </label>
                  <div className="w-100 col">
                    {data?.climateInfo.toUpperCase() || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Notes</label>
                  <div className="w-100 col">
                    {data?.notes.toUpperCase() || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Name</label>
                  <div className="w-100 col">
                    {data?.name.toUpperCase() || "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Contact Number
                  </label>
                  <div className="w-100 col">
                    {data?.contactNumber.toUpperCase() || "N/A"}
                  </div>
                </div>
              )}
            </div>
            {/* <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText="Edit Quotation Matrix"
                  href={`/edit/${id}`}
                />
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div> */}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewSiteInsppectionFeedback;
