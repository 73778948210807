import React, { useEffect, useState } from "react";
import { roundOffCost } from "../../../Components/Functions/amt.fun";

const ProduceTable = ({ allQData }) => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(allQData?.plant_grow.updatedPlantsList);
  }, [allQData]);

  function roundOff(cost) {
    cost = Math.round((cost * 100) / 100);
    return cost;
  }

  const style = {
    border: "1px solid #181818",
    textAlign: "left",

    borderCollapse: "collapse",
    padding: "2px",
  };

  const getRevenue = (cal, plant) => {
    let harvest;
    if (plant?.grow_system == "nftA") {
      harvest =
        plant?.productionPerPlantMonth *
        allQData?.number_of_plants?.numberOfPlantsInA_Frame;
    }
    if (plant?.grow_system == "fb") {
      harvest =
        plant?.productionPerPlantMonth *
        allQData?.number_of_plants?.numberOfPlantsInFlatBed;
    }
    if (plant?.grow_system == "gb") {
      harvest =
        plant?.productionPerPlantMonth *
        allQData?.number_of_plants?.numberOfPlantsInGrowBag;
    }
    if (plant?.grow_system == "db") {
      harvest =
        plant?.productionPerPlantMonth *
        allQData?.number_of_plants?.numberOfPlantsInDutchBucket;
    }
    cal = roundOff(cal);
    harvest = roundOff(harvest);
    let final = roundOffCost(cal * harvest);

    return final;
  };
  return (
    <div>
      <h3>Plants you can grow:</h3>

      <table style={{ width: "100%", ...style }}>
        <thead>
          <tr>
            <th style={{ width: "5%", ...style }}>Sr.</th>
            <th style={style}>Crop</th>
            <th style={style}>Plants</th>
            <th style={style}>System</th>
            <th style={style}>Harvest (Mo)</th>
            <th style={style}>Price (Min)</th>
            <th style={style}>Price (Max)</th>
            <th style={style}>Revenue (Min)</th>
            <th style={style}>Revenue (Max)</th>
            <th style={style}>Revenue (Avg)</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((plant, index) => {
              return (
                <tr style={style} key={index}>
                  <td style={style}>{++index}</td>
                  <td style={style}>{plant?.plantName}</td>

                  {plant?.grow_system === "nftA" && (
                    <td style={style}>
                      {allQData?.number_of_plants?.numberOfPlantsInA_Frame}
                    </td>
                  )}
                  {plant?.grow_system === "gb" && (
                    <td style={style}>
                      {allQData?.number_of_plants?.numberOfPlantsInGrowBag}
                    </td>
                  )}
                  {plant?.grow_system === "db" && (
                    <td style={style}>
                      {allQData?.number_of_plants?.numberOfPlantsInDutchBucket}
                    </td>
                  )}
                  {plant?.grow_system === "fb" && (
                    <td style={style}>
                      {allQData?.number_of_plants?.numberOfPlantsInFlatBed}
                    </td>
                  )}
                  {plant?.grow_system === "nftA" && (
                    <td style={style}>NFT (A-Frame)</td>
                  )}
                  {plant?.grow_system === "gb" && (
                    <td style={style}>Grow Bag</td>
                  )}
                  {plant?.grow_system === "db" && (
                    <td style={style}>Dutch Bucket</td>
                  )}
                  {plant?.grow_system === "fb" && (
                    <td style={style}>Flat Bed</td>
                  )}
                  {/* {plant?.grow_system === "nftA" && (
                    <td style={style}>NFT (A-Frame)</td>
                  )}
                  {plant?.grow_system === "nftA" && (
                    <td style={style}>NFT (A-Frame)</td>
                  )}
                  {plant?.grow_system === "nftA" && (
                    <td style={style}>NFT (A-Frame)</td>
                  )} */}

                  {plant?.grow_system === "nftA" && (
                    <td style={style}>
                      {roundOff(
                        plant?.productionPerPlantMonth *
                          allQData?.number_of_plants?.numberOfPlantsInA_Frame
                      )}
                      kg
                    </td>
                  )}
                  {plant?.grow_system === "fb" && (
                    <td style={style}>
                      {roundOff(
                        plant?.productionPerPlantMonth *
                          allQData?.number_of_plants?.numberOfPlantsInFlatBed
                      )}
                      kg
                    </td>
                  )}
                  {plant?.grow_system === "gb" && (
                    <td style={style}>
                      {roundOff(
                        plant?.productionPerPlantMonth *
                          allQData?.number_of_plants?.numberOfPlantsInGrowBag
                      )}
                      kg
                    </td>
                  )}
                  {plant?.grow_system === "db" && (
                    <td style={style}>
                      {roundOff(
                        plant?.productionPerPlantMonth *
                          allQData?.number_of_plants
                            ?.numberOfPlantsInDutchBucket
                      )}
                      kg
                    </td>
                  )}
                  <td style={style}>{roundOffCost(plant?.min_price)}</td>
                  <td style={style}>{roundOffCost(plant?.max_price)}</td>
                  <td style={style}>{getRevenue(plant?.min_price, plant)}</td>
                  <td style={style}>{getRevenue(plant?.max_price, plant)}</td>
                  <td style={style}>
                    {getRevenue(
                      (plant?.min_price + plant?.max_price) / 2,
                      plant
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
        {/* <% all_crops.each do |crop| %>
          <tr>
            <td><%= serial += 1; sprintf("%.2d", serial) %>.</td>
            <td><%= crop.name %></td>
            <td><%= crop.partial_number_of_plants %></td>
            <td><%= crop.system_name %></td>
            <td><%= crop.daily_harvest_size %>kg</td>
            <td><%= crop.min_price.k_to_inr %></td>
            <td><%= crop.max_price.k_to_inr %></td>
            <td><%= crop.revenue_per_month.k_to_inr %></td>
    <!--        <td><%##= crop.gross_profit_per_month(calculator.opex_per_month).k_to_inr %></td>-->
    <!--        <td><%##= crop.break_even_period(calculator) %> Months</td>-->
          </tr>
        <% end %> */}
      </table>
      <p>
        <strong>Terms & conditions:</strong>
        Total number of plants and tank capacity calculations are tentetive.
        Actual number is based on customization of project. Electricity cost is
        taken average of agriculture cost across India and it can vary based on
        your location. Harvest is considered daily. Opex specifications can vary
        as per your location. Pricing of crops is considered average collected
        from various sources from Price-Watcher. Plant growth estimates are
        based on ideal conditions and agronomy practices by a qualified
        agronomist. Agriculture in general is subject to infections, pests and
        other uncontrollable factors. KBPL is not responsible for any financial
        losses or gains from the above estimates. Above numbers are only
        estimates and actual results may vary as per the crop, location,
        weather, farm management, etc.
      </p>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default ProduceTable;
