import * as yup from "yup";

export const ClientPaymentSchema = yup.object().shape({
  projectSelection: yup.string().required("Required"),
  siteSelection: yup.string(),
  paymentDate: yup.date().required("Required"),
  paymentAmount: yup.number().required("Required"),
  paymentGenratedBy: yup.string(),
  paymentMode: yup.string().required("Required"),
  gstRate: yup.string().required("Required"),
  notes: yup.string(),
});
