import React from "react";
import {
  convertRupeesToWords,
  roundOffCost,
} from "../../../Components/Functions/amt.fun";

const InvoiceDetails = ({ data }) => {
  let finalAmount = 0;
  let finalAmountWithGST = 0;
  let gstAmt = 0;

  // Calculate totals for each line item
  data.lineItems &&
    data.lineItems.forEach((lineItem) => {
      const itemTotal = +lineItem.itemPrice * +lineItem.itemQuantity;
      const itemGST = (itemTotal * (lineItem.gstRate || 0)) / 100;
      finalAmount += itemTotal;
      gstAmt += itemGST;
      finalAmountWithGST += itemTotal + itemGST;
    });

  return (
    <div>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #181818",
        }}
      >
        <thead>
          <tr>
            <td
              style={{
                width: "5%",
                border: "1px solid #181818",
                textAlign: "center",
                fontWeight: "bolder",
              }}
            >
              Sr.
            </td>
            <td
              style={{
                width: "25%",
                textAlign: "center",
                border: "1px solid #181818",
                fontWeight: "bolder",
              }}
            >
              Particulars
            </td>
            <td
              style={{
                width: "5%",
                textAlign: "center",
                border: "1px solid #181818",
                fontWeight: "bolder",
              }}
            >
              Unit
            </td>
            <td
              style={{
                width: "10%",
                textAlign: "center",
                border: "1px solid #181818",
                fontWeight: "bolder",
              }}
            >
              Size/Qty
            </td>
            <td
              style={{
                width: "15%",
                textAlign: "center",
                border: "1px solid #181818",
                fontWeight: "bolder",
              }}
            >
              HSN
            </td>
            <td
              style={{
                width: "15%",
                textAlign: "center",
                border: "1px solid #181818",
                fontWeight: "bolder",
              }}
            >
              Amount
            </td>
            <td
              style={{
                width: "10%",
                textAlign: "center",
                border: "1px solid #181818",
                fontWeight: "bolder",
              }}
            >
              GST %
            </td>
            <td
              style={{
                width: "10%",
                textAlign: "center",
                border: "1px solid #181818",
                fontWeight: "bolder",
              }}
            >
              GST Amount
            </td>
            <td
              style={{
                width: "15%",
                textAlign: "center",
                border: "1px solid #181818",
                fontWeight: "bolder",
              }}
            >
              Total
            </td>
          </tr>
        </thead>

        <tbody>
          {data?.lineItems?.map((lineItem, index) => {
            const itemTotal = +lineItem.itemPrice * +lineItem.itemQuantity;
            const itemGST = (itemTotal * (lineItem.gstRate || 0)) / 100;
            const totalWithGST = itemTotal + itemGST;

            return (
              <tr key={index}>
                <td style={{ border: "1px solid #181818" }}>{index + 1}</td>
                <td style={{ border: "1px solid #181818" }}>
                  {lineItem?.particulars || "-"}
                </td>
                <td style={{ border: "1px solid #181818" }}>
                  {lineItem?.itemUnit || "-"}
                </td>
                <td style={{ border: "1px solid #181818" }}>
                  {lineItem?.itemQuantity || "-"}
                </td>
                <td style={{ border: "1px solid #181818" }}>
                  {lineItem?.HSN || "-"}
                </td>
                <td style={{ border: "1px solid #181818" }}>
                  {roundOffCost(itemTotal)}
                </td>
                <td style={{ border: "1px solid #181818" }}>
                  {`${lineItem?.gstRate}.0 %`}
                </td>
                <td style={{ border: "1px solid #181818" }}>
                  {roundOffCost(itemGST)}
                </td>
                <td style={{ border: "1px solid #181818" }}>
                  {roundOffCost(totalWithGST)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <table style={{ width: "100%", border: "none", marginTop: "2px" }}>
        <tbody>
          <tr style={{ border: "none" }}>
            <td
              style={{
                border: "none",
                width: "70%",
                verticalAlign: "top",
                padding: "3px",
              }}
            >
              <p>
                In Words:{" "}
                <b style={{ fontSize: "0.9rem" }}>
                  {convertRupeesToWords(finalAmountWithGST)}
                </b>
              </p>
            </td>
            <td
              style={{
                border: "none",
                width: "30%",
                verticalAlign: "top",
                padding: "0",
              }}
            >
              <table
                style={{
                  width: "99%",
                  borderCollapse: "collapse",
                  marginTop: "2px",
                  marginBottom: "2px",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #181818", padding: "3px" }}>
                      Amount
                    </td>
                    <td style={{ border: "1px solid #181818", padding: "3px" }}>
                      {roundOffCost(finalAmount)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #181818", padding: "3px" }}>
                      GST
                    </td>
                    <td style={{ border: "1px solid #181818", padding: "3px" }}>
                      {roundOffCost(gstAmt)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #181818", padding: "3px" }}>
                      Total
                    </td>
                    <td style={{ border: "1px solid #181818", padding: "3px" }}>
                      {roundOffCost(finalAmountWithGST)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceDetails;
