import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { Toast } from "../../../CustomComponent/toast";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import performRequest from "../../../api/request";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import { QuotationMatrixInfo } from "../../../SchemaInfo/info";
import { QuotationMatrixCreateSchema } from "../../../Schemas/quotation/create_quotation_matrix";
import { FcPuzzle } from "react-icons/fc";

const getId = (str) => {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  return parseInt(lastPart, 10);
};

const EditQuotationMatrix = () => {
  const [matrixInfo, setMatrixInfo] = useState(QuotationMatrixInfo);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState("");
  let { id } = useParams();
  const navigate = useNavigate();
  const handelSubmit = (values) => {
    setEditData(values);
    document.getElementById("open-modal").click();
  };
  const handelEdit = (values) => {
    setLoading(true);
    performRequest(
      "PATCH",
      `/quotationMatrix/updateQuotationMatrix/${id}`,
      values
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Quotation updated successfully!");
          navigate(`/standard-quotation/view/${id}`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/quotationMatrix/getQuotationMatrixById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setMatrixInfo(res.data);
        } else {
          Toast("error", "Error while getting client expense!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcPuzzle className="fs-2  me-1" /> Edit Quotation Matrix
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Base values that affect quotation
                  pricing.
                </h4>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <p>
                  Only Super admins and admins can make changes to quotation
                  matrix. This will directly affect the quotations we are
                  sending to clients.
                </p>
                <p>
                  If you are not authorized, do not make any changes to this.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={matrixInfo}
                  validationSchema={QuotationMatrixCreateSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Type
                          </label>
                          <div className="w-100 col">
                            <CustomSelect type="text" name="type" disabled>
                              <option value="">Select</option>
                              <option value="cea">CEA</option>
                              <option value="hydroponics">Hydroponics</option>
                              <option value="cea+hydroponics">
                                CEA + Hydroponics
                              </option>
                            </CustomSelect>
                            {props.touched.type && props.errors.type && (
                              <p className="text-danger">{props.errors.type}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Factor
                          </label>
                          <div className="w-100 col">
                            <CustomSelect type="text" name="factor" disabled>
                              <option value="">Select</option>
                              <option value="a-frame">A-Frame</option>
                              <option value="flatbed">Flatbed</option>
                              <option value="growbags">GrowBags</option>
                              <option value="dutchbucket">Dutchbucket</option>
                              <option value="nvph">nvph</option>
                              <option value="fanpad">Fanpad</option>
                              <option value="nethouse">Nethouse</option>
                              <option value="fanpad with hydroponic">
                                Fanpad with Hydroponic
                              </option>
                              <option value="nvph with hydroponic">
                                nvph with Hydroponic
                              </option>
                              <option value="nethouse with hydroponic">
                                Nethouse with Hydroponic
                              </option>
                              <option value="hydroponic only">
                                Hydroponic Only
                              </option>
                            </CustomSelect>
                            {props.touched.factor && props.errors.factor && (
                              <p className="text-danger">
                                {props.errors.factor}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            0 - 190 SqM Base Price
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="basePrice1to190" type="text" />
                            {props.touched.basePrice1to190 &&
                              props.errors.basePrice1to190 && (
                                <p className="text-danger">
                                  {props.errors.basePrice1to190}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            191 - 440 SqM Base Price
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="basePrice191to450" type="text" />
                            {props.touched.basePrice191to450 &&
                              props.errors.basePrice191to450 && (
                                <p className="text-danger">
                                  {props.errors.basePrice191to450}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            441 - 940 SqM Base Price
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="basePrice451to950" type="text" />
                            {props.touched.basePrice451to950 &&
                              props.errors.basePrice451to950 && (
                                <p className="text-danger">
                                  {props.errors.basePrice451to950}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            941 - 1940 SqM Base Price
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="basePrice951to1860"
                              type="text"
                            />
                            {props.touched.basePrice951to1860 &&
                              props.errors.basePrice951to1860 && (
                                <p className="text-danger">
                                  {props.errors.basePrice951to1860}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            1941 - 3940 SqM Base Price
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="basePrice1861to3720"
                              type="text"
                            />
                            {props.touched.basePrice1861to3720 &&
                              props.errors.basePrice1861to3720 && (
                                <p className="text-danger">
                                  {props.errors.basePrice1861to3720}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            3941 - 5940 SqM Base Price
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="basePrice3721to5999"
                              type="text"
                            />
                            {props.touched.basePrice3721to5999 &&
                              props.errors.basePrice3721to5999 && (
                                <p className="text-danger">
                                  {props.errors.basePrice3721to5999}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            5941 - 7940 SqM Base Price
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="basePrice6000to7999"
                              type="text"
                            />
                            {props.touched.basePrice6000to7999 &&
                              props.errors.basePrice6000to7999 && (
                                <p className="text-danger">
                                  {props.errors.basePrice6000to7999}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            7941 SqM Base Price
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="basePrice8000Plus" type="text" />
                            {props.touched.basePrice8000Plus &&
                              props.errors.basePrice8000Plus && (
                                <p className="text-danger">
                                  {props.errors.basePrice8000Plus}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Weedmat cost per SqM
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="sqMtrWeed" type="text" />
                            {props.touched.sqMtrWeed &&
                              props.errors.sqMtrWeed && (
                                <p className="text-danger">
                                  {props.errors.sqMtrWeed}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Markup Percent (GP)
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="markupPercentGP" type="text" />
                            {props.touched.markupPercentGP &&
                              props.errors.markupPercentGP && (
                                <p className="text-danger">
                                  {props.errors.markupPercentGP}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Save Quotation Matrix" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal modal-blur fade"
          id="modal-danger"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-sm modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <span
                id="open-modal"
                data-bs-toggle="modal"
                data-bs-target="#modal-danger"
              ></span>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-status bg-warning"></div>
              <div className="modal-body text-center py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon mb-2 text-warning icon-lg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <h3>Are you sure about edit Quotation Matrix?</h3>
                <div className="text-muted">
                  Do you really want to update base values? This will directly
                  affect on the final values of quotations.
                </div>
              </div>
              <div className="modal-footer">
                <div className="w-100">
                  <div className="row">
                    <div className="col">
                      <a href="#" className="btn w-100" data-bs-dismiss="modal">
                        Cancel
                      </a>
                    </div>
                    <div className="col">
                      <a
                        href="#"
                        className="btn btn-warning w-100"
                        data-bs-dismiss="modal"
                        onClick={() => handelEdit(editData)}
                      >
                        Save Values
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditQuotationMatrix;
