import React from "react";

const FanPadSpecifications = () => {
  return (
    <div>
      <h3>Fan & Pad Specifications:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td>Type of system</td>
            <td>Exhaust fan and cellulose padded polyhouse system</td>
          </tr>
          <tr>
            <td>02.</td>
            <td>Design style</td>
            <td>Tunnel design</td>
          </tr>
          <tr>
            <td>03.</td>
            <td>Construction area</td>
            <td>On Land / Terrace / Hard surface / Concrete foundation</td>
          </tr>
          <tr>
            <td>04.</td>
            <td>Bay size</td>
            <td>8m X 4m (Width of bay should be 35% of the desired length.)</td>
          </tr>
          <tr>
            <td>05.</td>
            <td>Ridge height</td>
            <td>5.5m</td>
          </tr>
          <tr>
            <td>06.</td>
            <td>Ridge vent</td>
            <td>Top open (Butterfly design)</td>
          </tr>
          <tr>
            <td>07.</td>
            <td>Gutter height</td>
            <td>4.2m</td>
          </tr>
          <tr>
            <td>08.</td>
            <td>Gutter slope</td>
            <td>Nearly 2%</td>
          </tr>
          <tr>
            <td>09.</td>
            <td>Structure material</td>
            <td>GI material (Galvanised Iron)</td>
          </tr>
          <tr>
            <td>10.</td>
            <td>Structure design</td>
            <td>
              The structural design will be sound enough to withstand wind speed
              minimum 120 km/hr. and minimum load of 25kg/m2.
            </td>
          </tr>
          <tr>
            <td>11.</td>
            <td>Structure</td>
            <td>
              Complete structure made of galvanized steel tubular pipes of
              equivalent section conforming ISO standards having thickness of
              2mm.
            </td>
          </tr>
          <tr>
            <td>12.</td>
            <td>Columns</td>
            <td>76mm OD (Outer Diameter), 2mm thickness</td>
          </tr>
          <tr>
            <td>13.</td>
            <td>Trusses</td>
            <td>Bottom &amp; top cord 60mm OD, 2mm thick</td>
          </tr>
          <tr>
            <td>14.</td>
            <td>Trusses member & purlin</td>
            <td>48mm/42mm OD, 2mm thickness</td>
          </tr>
          <tr>
            <td>15.</td>
            <td>Purlin member &amp; others</td>
            <td>33mm/25mm, 2mm thickness</td>
          </tr>
          <tr>
            <td>16.</td>
            <td>Foundations</td>
            <td>
              GI pipes of minimum 60mm OD 3mm thickness or more to Foundation
              depth of 75cm or more depending upon soil type and prevailing wind
              condition, grouted with cement concrete mixture of 1:2:4 using
              telescopic insertion of column.
            </td>
          </tr>
          <tr>
            <td>17.</td>
            <td>Foundation accessories</td>
            <td>
              All nuts &amp; bolts will be of high tensile strength and
              galvanized.
            </td>
          </tr>
          <tr>
            <td>18.</td>
            <td>Entrance room &amp; door</td>
            <td>
              One entrance room of size 4m x 3m x 2m (L x W x H) will be
              provided and covered with 200 micron UV stabilized transparent
              plastic film. Doors of size 2m width &amp; 2.5m height double leaf
              made in plastic/FRP sheets mounted in a suitable strong frame.
            </td>
          </tr>
          <tr>
            <td>19.</td>
            <td>Cladding material</td>
            <td>
              UV stabilized 200 micron transparent plastics films. Conforming
              ISO Standards (ISO 15827: 2009), multi-layered, anti-drip,
              anti-fog, anti-sulphur, diffused, clear and having minimum 85%
              level of light transmittance.
            </td>
          </tr>
          <tr>
            <td>20.</td>
            <td>Fixing of cladding material</td>
            <td>
              All ends/joints of plastic film will be fixed with a two way
              aluminium/GI profile with suitable locking arrangement along with
              curtain top.
            </td>
          </tr>
          <tr>
            <td>21.</td>
            <td>Spring insert</td>
            <td>
              Zig zag high carbon steel with spring action wire of 2-3 mm
              diameter must be inserted to fix the sheet into Aluminium
              profile/GI profile.
            </td>
          </tr>
          <tr>
            <td>22</td>
            <td>Shade net / side insect net / mono mono net</td>
            <td>
              Anti UV stabilized 50% shading net with manually operated
              mechanism for expanding and retracting. Size of the net will be
              equal to the floor area of the greenhouse.
            </td>
          </tr>
          <tr>
            <td>23.</td>
            <td>4-Way foggers assembly</td>
            <td>
              65 Micron, LPD fitted, High pressure 4-way foggers, lateral
              piping, PVC headers, fittings, High pressure 5.5 KG submersible
              pump, pressure gauge, Air release valve, sand filter etc.
            </td>
          </tr>
          <tr>
            <td>24.</td>
            <td>Fans</td>
            <td>
              50&quot; Exhaust fans (6 SS blades design) with AC motor 1.5HP.
              21000 CFM, with Auto opening shutters damper type. 415V, 50Hz.
            </td>
          </tr>
          <tr>
            <td>25.</td>
            <td>Cooling pads</td>
            <td>
              Cellulose cooling pad 4inch thickness &amp; 5ft height. Single pad
              containing area of 10sqFt.
            </td>
          </tr>
          <tr>
            <td>26.</td>
            <td>Submersible pump for pads</td>
            <td>
              Open well submersible 1HP 3 phase with available onsite warranty.
            </td>
          </tr>
          <tr>
            <td>27.</td>
            <td>Panel board</td>
            <td>Electric panel board for exhaust fan, ACF, and fogger.</td>
          </tr>
          <tr>
            <td>28.</td>
            <td>Filter flush system</td>
            <td>
              Leading kit with pipeline upto frame, bypass, filter flush system,
              etc.
            </td>
          </tr>
          <tr>
            <td>29.</td>
            <td>Cooling pad frame</td>
            <td>
              Frame for the above cooling pads with top water distribution and
              bottom gutter, Aluminium made.
            </td>
          </tr>
          <tr>
            <td>30.</td>
            <td>Temp. and humidity controller</td>
            <td>Microprocessor based temperature and humidity controller.</td>
          </tr>
          <tr>
            <td>31.</td>
            <td>Shade net closing and opening pulley</td>
            <td>
              Above air circulation fan shade net closing and opening pulley
              manual installation.
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default FanPadSpecifications;
