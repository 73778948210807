import React from "react";
import {
  convertRupeesToWords,
  roundOffCost,
} from "../../../Components/Functions/amt.fun";

const VendorListItems = ({ data }) => {
  let finalAmount = 0;
  let finalAmountWithGST = 0;
  let gstAmt = 0;

  data.lineItems &&
    data.lineItems.forEach((lineItem) => {
      finalAmountWithGST += lineItem?.totalWithGst;
      finalAmount += lineItem?.totalWithoutGst;
      if (lineItem?.gstRate) {
        gstAmt += (lineItem?.totalWithoutGst * lineItem?.gstRate) / 100;
      }
    });
  return (
    <div>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "25%" }}>Particulars</th>
            <th style={{ width: "15%" }}>Delivery</th>
            <th style={{ width: "10%" }}>Unit</th>
            <th style={{ width: "10%" }}>Qty</th>
            <th style={{ width: "15%" }}>Amount</th>
            <th style={{ width: "5%" }}>GST %</th>

            <th style={{ width: "15%" }}>Total</th>
          </tr>
        </thead>
      </table>
      {data?.lineItems?.map((lineItem, index) => {
        return (
          <div key={index}>
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <span
                style={{
                  width: "5%",
                  borderBottom: lineItem?.notes ? "1px solid #181818" : "",
                  borderLeft: "1px solid #181818",
                  borderTop: "1px solid #181818",
                  paddingLeft: "3px",
                }}
              >
                {++index}
              </span>
              <span
                style={{
                  width: "25%",
                  borderBottom: lineItem?.notes ? "1px solid #181818" : "",
                  borderLeft: "1px solid #181818",
                  borderTop: "1px solid #181818",
                  paddingLeft: "3px",
                }}
              >
                {lineItem?.particulars}
              </span>
              <span
                style={{
                  width: "15%",
                  borderBottom: lineItem?.notes ? "1px solid #181818" : "",
                  borderLeft: "1px solid #181818",
                  borderTop: "1px solid #181818",
                  paddingLeft: "3px",
                }}
              >
                {lineItem?.deliveryDate || "N/A"}
              </span>
              <span
                style={{
                  width: "10%",
                  borderBottom: lineItem?.notes ? "1px solid #181818" : "",
                  borderLeft: "1px solid #181818",
                  borderTop: "1px solid #181818",
                  paddingLeft: "3px",
                }}
              >
                {lineItem?.itemUnit.toUpperCase() || "N/A"}
              </span>
              <span
                style={{
                  width: "10%",
                  borderBottom: lineItem?.notes ? "1px solid #181818" : "",
                  borderLeft: "1px solid #181818",
                  borderTop: "1px solid #181818",
                  paddingLeft: "3px",
                }}
              >
                {lineItem?.itemQuantity}
              </span>
              <span
                style={{
                  width: "15%",
                  borderBottom: lineItem?.notes ? "1px solid #181818" : "",
                  borderLeft: "1px solid #181818",
                  borderTop: "1px solid #181818",
                  paddingLeft: "3px",
                }}
              >
                {roundOffCost(lineItem?.itemPrice)}
              </span>
              <span
                style={{
                  width: "5%",
                  borderBottom: lineItem?.notes ? "1px solid #181818" : "",
                  borderLeft: "1px solid #181818",
                  borderTop: "1px solid #181818",
                  paddingLeft: "3px",
                }}
              >
                {lineItem?.gstRate || "0"}
              </span>
              <span
                style={{
                  width: "15%",
                  borderBottom: lineItem?.notes ? "1px solid #181818" : "",
                  borderLeft: "1px solid #181818",
                  borderTop: "1px solid #181818",
                  borderRight: "1px solid #181818",
                  paddingLeft: "3px",
                }}
              >
                {roundOffCost(lineItem?.totalWithGst)}
              </span>
            </div>

            {lineItem?.notes && (
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  margin: "20px",
                  fontSize: "15px",
                  padding: "10px",
                  border: "1px solid black",
                }}
              >
                <h3>Notes:</h3>
                <p>
                  {lineItem?.notes
                    .trim()
                    .split("\n")
                    .map((line, index) => (
                      <b key={index}>
                        {line}
                        {index !== lineItem?.notes.split("\n").length - 1 && (
                          <br />
                        )}
                      </b>
                    ))}
                </p>
              </div>
            )}
          </div>
        );
      })}

      <table style={{ width: "100%", border: "none", fontSize: "0.8em" }}>
        <tbody>
          <tr style={{ border: "none" }}>
            <td style={{ border: "none", width: "70%" }}>
              In Words:
              <b>{convertRupeesToWords(finalAmountWithGST)}</b>
            </td>
            <td
              style={{
                border: "none",
                width: "30%",
                paddingRight: "0px",
                paddingLeft: "0px",
              }}
            >
              <table style={{ width: "100%", fontSize: "0.8em" }}>
                <tbody>
                  <tr>
                    <td>Amount</td>
                    <td>{roundOffCost(finalAmount)}</td>
                  </tr>
                  <tr>
                    <td>GST</td>
                    <td>{roundOffCost(gstAmt)}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Total</td>
                    <td style={{ width: "50%" }}>
                      {roundOffCost(finalAmountWithGST)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default VendorListItems;
