import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { taskManagementInfo } from "../../../SchemaInfo/info";
import { CreateTaskSchema } from "../../../Schemas/Task Management/create_task";
import { useEffect } from "react";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import CustomRadioButton from "../../../CustomComponent/custom_radio";
import { BsPatchPlus } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";

const EditTask = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [leadOwner, setLeadOwner] = useState();
  const [tasksschema, setTasksSchema] = useState(taskManagementInfo);
  let { id } = useParams();

  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);

  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest("PATCH", `/tasks/updateTaskManagement/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Task updated successfully!");
          navigate(`/task-management/view/${id}`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/tasks/getTaskManagementById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setTasksSchema(res.data);
        } else {
          Toast("error", "Error while getting client expense!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcCalendar className="fs-2  me-1" /> Edit Task
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Update & Track Task.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/task-management"}>
                    <button className="btn btn-primary">
                      <BsPatchPlus className="me-2 fs-2" /> View Task List
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <p>Only Super Admin and Admins can edit the task.</p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={tasksschema}
                  validationSchema={CreateTaskSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="name" type="text" />
                            {props.touched.name && props.errors.name && (
                              <p className="text-danger">{props.errors.name}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Task type
                          </label>
                          <div className="w-100 col">
                            <div>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="taskType"
                                  value="one time"
                                />
                                <span className="form-check-label">
                                  One Time
                                </span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="taskType"
                                  value="repeat task"
                                />
                                <span className="form-check-label">
                                  Repeat Task
                                </span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="taskType"
                              component="p"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        {props.values.taskType !== "repeat task" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label col-3">
                              Deadline
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="deadline" type="date" />
                              <ErrorMessage
                                name="deadline"
                                component="p"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        )}
                        {props.values.taskType === "repeat task" && (
                          <>
                            <div className="mb-3 row">
                              <label className="w-25 form-label required col-3">
                                Start date
                              </label>
                              <div className="w-100 col">
                                <CustomInput name="startDate" type="date" />
                                <ErrorMessage
                                  name="startDate"
                                  component="p"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Frequency
                              </label>
                              <div className="w-100 col">
                                <div className="d-flex">
                                  <CustomSelect
                                    className="me-2 form-select"
                                    name="frequency"
                                    type="text"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="">Select</option>
                                    {[...Array(32).keys()].map((month) => (
                                      <option key={month} value={month}>
                                        {month}
                                      </option>
                                    ))}
                                  </CustomSelect>
                                  <CustomSelect
                                    name="frequencyType"
                                    type="text"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="">Select Type</option>
                                    <option value="days">Days</option>
                                    <option value="months">Months</option>
                                    <option value="year">Year</option>
                                  </CustomSelect>
                                </div>
                                {props.touched.frequencyType &&
                                  props.errors.frequencyType && (
                                    <p className="text-danger">
                                      {props.errors.frequencyType}
                                    </p>
                                  )}
                                {props.touched.frequency &&
                                  props.errors.frequency && (
                                    <p className="text-danger">
                                      {props.errors.frequency}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 required">
                            Assign to
                          </label>
                          <div className="w-100 col">
                            <CustomSelect name="assignTo" type="text">
                              <option value="">Select</option>
                              {leadOwner &&
                                leadOwner?.map((pro) => {
                                  return (
                                    <option key={pro.id}>{pro.userName}</option>
                                  );
                                })}
                            </CustomSelect>
                            {props.touched.assignTo &&
                              props.errors.assignTo && (
                                <p className="text-danger">
                                  {props.errors.assignTo}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Description
                          </label>
                          <div className="w-100 col">
                            <CustomTextArea name="description" type="text" />
                            {props.touched.description &&
                              props.errors.description && (
                                <p className="text-danger">
                                  {props.errors.description}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Feedback
                          </label>
                          <div className="w-100 col">
                            <CustomTextArea name="feedback" type="text" />
                            {props.touched.feedback &&
                              props.errors.feedback && (
                                <p className="text-danger">
                                  {props.errors.feedback}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Save Task" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditTask;
