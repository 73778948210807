import React from "react";

import Footer from "../../Components/Footer/footer";

const FarmOperationsManagement = () => {
  return (
    <div className="page-wrapper">
      <div className="page-body">
        <div className="container-xxl d-flex flex-column justify-content-center">
          <div className="empty">
            <p className="empty-title">
              Order your hydrobuild to access farm management
            </p>
            <p className="empty-subtitle text-muted">
              Contact your account manager.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FarmOperationsManagement;
