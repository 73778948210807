import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const GaugeChart = ({ value, max, label }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartCtx = chartRef.current.getContext("2d");
    const gradient = chartCtx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#08308a");
    gradient.addColorStop(1, "#08308a");
    const config = {
      type: "doughnut",
      data: {
        labels: false,
        datasets: [
          {
            data: [value, max - value],
            backgroundColor: [gradient, "rgba(0, 0, 0, 0.1)"],
            borderWidth: 0,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: "85%", // This is the updated property to make the segments thinner
        rotation: -90,
        circumference: 180,
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: label,
            font: {
              size: 16,
            },
          },
        },
        animation: {
          animateRotate: true,
          animateScale: false,
        },
      },
    };

    const gaugeChart = new Chart(chartCtx, config);

    return () => {
      gaugeChart.destroy();
    };
  }, [value, max]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        marginTop: "-30px",
      }}
    >
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default GaugeChart;
