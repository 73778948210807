import React from "react";
import { BsCheck2Square, BsSlashCircle } from "react-icons/bs";

const SimpleButton = ({ type, buttonText, onClick, ...props }) => {
  buttonText = buttonText.toUpperCase();
  const handleCancel = () => {
    window.history.back();
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <a
      type="submit"
      className={`btn mx-1 mt-2 btn-${type || "primary"}`}
      onClick={
        buttonText === "CANCEL" || buttonText === "CLOSE"
          ? handleCancel
          : handleClick
      }
      {...props}
    >
      {buttonText === "CANCEL" || buttonText === "CLOSE" ? (
        <BsSlashCircle className="fs-2 me-2" />
      ) : (
        <BsCheck2Square className="fs-2 me-2" />
      )}

      {buttonText}
    </a>
  );
};

export default SimpleButton;
