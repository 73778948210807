import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { extractDate } from "../../../Components/Functions/date.fun";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
const tableHead = [
  "Sr.",
  "Lead Generate Date",
  "full name",
  "contact number",
  "filtered by",
  "lead owner",
  "lead generated from",
  "interested In",
  "lead status",
  "Reassign",
  "Actions",
];

const getInterest = (item) => {
  if (item?.interestedIn && item?.interestedIn.length >= 1) {
    return item?.interestedIn.join(", ");
  }
  if (!item?.lead_status) {
    return "Not Attended";
  }
  return "N/A";
};

function checkDateStatus(dateString) {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const differenceMilliseconds = currentDate - inputDate;
  const differenceDays = differenceMilliseconds / (1000 * 3600 * 24);

  if (differenceDays <= 1) {
    return "superhot";
  } else if (differenceDays > 1 && differenceDays <= 4) {
    return "hot";
  } else if (differenceDays > 4 && differenceDays <= 7) {
    return "warmer";
  } else if (differenceDays > 7 && differenceDays <= 10) {
    return "warm";
  } else if (differenceDays > 10 && differenceDays <= 13) {
    return "colder";
  } else {
    return "cold";
  }
}
const getBackgroundStyleByDate = (dateString) => {
  let style = {};
  if (checkDateStatus(dateString) === "superhot") {
    style = {
      backgroundColor: "rgb(12, 166, 120,0.1)",
    };
    return style;
  } else if (checkDateStatus(dateString) === "hot") {
    style = {
      backgroundColor: "rgba(0,255,0,0.1)",
    };
    return style;
  } else if (checkDateStatus(dateString) === "warmer") {
    style = {
      backgroundColor: "rgb(116, 184, 22,0.1)",
    };
    return style;
  } else if (checkDateStatus(dateString) === "warm") {
    style = {
      backgroundColor: "rgb(245, 159, 0,0.1)",
    };
    return style;
  } else if (checkDateStatus(dateString) === "colder") {
    style = {
      backgroundColor: "rgb(247, 103, 7,0.1)",
    };
    return style;
  } else {
    style = {
      backgroundColor: "rgba(255,0,0,0.1)",
    };
    return style;
  }
};

const FilteredLeadsTable = ({
  leads = [],
  ReassignLead,
  currentPage,
  itemsPerPage,
}) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((store) => store.login?.user);
  const location = useLocation();
  const pathSegments = location.pathname;
  const navigate = useNavigate();
  const goToView = (id) => {
    if (pathSegments && pathSegments.includes("followup")) {
      navigate(`/leads-followup/view/${id}`);
    } else {
      navigate(`/leads-filtered/view/${id}`);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {leads?.map((item, index) => {
            const adjustedIndex = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr
                key={item?.id}
                style={
                  item?.createdAt &&
                  getBackgroundStyleByDate(extractDate(item?.createdAt))
                }
              >
                <td>{adjustedIndex}</td>
                <td>{extractDate(item?.createdAt) || "N/A"}</td>
                <td style={{ maxWidth: "200px" }}>
                  <span
                    className="cursor-pointer text-blue text-decoration-underline"
                    onClick={() => goToView(item.id)}
                  >
                    {item?.full_name || "N/A"}
                  </span>
                </td>
                <td>{item?.phone_number || "N/A"}</td>
                <td>{item?.lead_alloted_to || "N/A"}</td>
                <td>{item?.reAssignTo || "N/A"}</td>
                <td>{item?.lead_genrated_from || "N/A"}</td>
                <td style={{ maxWidth: "200px" }}>{getInterest(item)}</td>
                <td>{item?.lead_status || "N/A"}</td>

                <td>
                  <button
                    disabled={item.reAssignTo}
                    style={{ width: "70px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => ReassignLead(item)}
                  >
                    Re Assign
                  </button>
                </td>

                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FilteredLeadsTable;
