import * as yup from "yup";

const today = new Date();
const maxDate = new Date(today);
maxDate.setDate(today.getDate() + 30);

export const followUpSchema = yup.object().shape({
  followUp: yup.string(),
  followUpDate: yup
    .date()
    .max(maxDate, `Follow-up date must be within 30 days from today`)
    .required("Follow-up date is required"),
  videoCall: yup.boolean(),
  followupTaken: yup.boolean(),
});

export const LeadsSchema = yup.object().shape({
  first_name: yup.string().required("Required"),
  last_name: yup.string(),
  full_name: yup.string(),
  services: yup.string(),
  planned_area: yup.string(),
  location_type: yup.string(),
  facilities: yup.array().of(yup.string()),
  interestedIn: yup.array().of(yup.string()),
  company_name: yup.string(),
  lead_genrated_from: yup.string(),
  lead_alloted_to: yup.string(),
  preferred_contact_method: yup.string(),
  lead_status: yup.string().required("Required"),
  phone_number: yup
    .string()
    // .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits and postive")
    .required("Required"),
  email: yup.string().email("Please enter a valid email"),
  gst_number: yup.string(),

  // .matches(
  //   /^([0-9]{2})([A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9]{1})$/,
  //   "GST number must be in the format 00AAAAA1234A0Z0"
  // ),
  address: yup.string(),
  notes: yup.string(),
  callRecordingUpload: yup.mixed(),
  followUp: yup.array().of(followUpSchema),
});
