import React, { useEffect, useState } from "react";
import Footer from "../../../Kryzen Alpha App/Components//Footer/footer";
import StepRoutes from "../../../Kryzen Alpha App/Routes/steps_routes";
import ButtonWithSVG from "../../../Kryzen Alpha App/Components/layouts/button_with_svg";
import performRequest from "../../../Kryzen Alpha App/api/request";
import { Toast } from "../../../Kryzen Alpha App/CustomComponent/toast";
import { useSelector } from "react-redux";
import DynamicFilterInput from "../../../Kryzen Alpha App/Components/layouts/filter.inpit";
import getFilteredURL from "../../../Kryzen Alpha App/Components/Functions/filters.getUrl";
import FarmQueryTable from "./Farm Support/farm_query_table";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import Pagination from "../../Components/layouts/pagination";
import { FcSms } from "react-icons/fc";
import { BsPassFill } from "react-icons/bs";

const AgronomyClientManagement = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    relatedTo: "",
    generatedBy: "",
    status: "",
    assign: "",
  });
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/farmSupport/getFarmQuery/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.QueryCont / 50));
        } else {
          Toast("error", "Error while getting Data!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcSms className="fs-2  me-1" /> Farm Support Tickets
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Report issues or raise concerns.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/agronomy-farm-support/new"}>
                    <button className="btn btn-primary">
                      <BsPassFill className="me-2 fs-2" /> Add New Query
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-4">
                <div className="row">
                  <h3 className="">Filter result</h3>
                  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {
                  <FarmQueryTable
                    data={data?.FarmQueries}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                }
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.FarmQueries?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-1">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3>Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search query related to:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  relatedTo: e.target.value,
                                })
                              }
                              value={filters.relatedTo}
                            >
                              <option value="">select all</option>
                              <option value="quotation">Quotation</option>
                              <option value="billing and payments">
                                Billing And Payments
                              </option>
                              <option value="dpr and subsidy">
                                DPR And Subsidy
                              </option>
                              <option value="construction">Construction</option>
                              <option value="maintainance">Maintainance</option>
                              <option value="agronomy">Agronomy</option>
                            </DynamicFilterSelect>
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search by status:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  status: e.target.value,
                                })
                              }
                              value={filters.status}
                            >
                              <option value="">select all</option>
                              <option value="pending">Pending</option>
                              <option value="being address">
                                Being Address
                              </option>
                              <option value="resolved">Resolved</option>
                            </DynamicFilterSelect>
                          </div>
                          <div className="col-12">
                            {process.env.REACT_APP_ROLE_ADMIN_S === user.Role ||
                            process.env.REACT_APP_ROLE_OPS === user.Role ||
                            process.env.REACT_APP_ROLE_ADMIN === user.Role ? (
                              <DynamicFilterSelect
                                label="Search by assign:"
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    assign: e.target.value,
                                  })
                                }
                                value={filters.assign}
                              >
                                <option value="">select all</option>
                                <option value={user.UserId}>{user.Name}</option>
                              </DynamicFilterSelect>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Search name:"
                              value={filters.generatedBy}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  generatedBy: e.target.value,
                                })
                              }
                              placeholder="search name..."
                            />
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      relatedTo: "",
                                      generatedBy: "",
                                      status: "",
                                      assign: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                          <DynamicFilterSelect
                            label="Search query related to:"
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                relatedTo: e.target.value,
                              })
                            }
                            value={filters.relatedTo}
                          >
                            <option value="">select all</option>
                            <option value="quotation">Quotation</option>
                            <option value="billing and payments">
                              Billing And Payments
                            </option>
                            <option value="dpr and subsidy">
                              DPR And Subsidy
                            </option>
                            <option value="construction">Construction</option>
                            <option value="maintainance">Maintainance</option>
                            <option value="agronomy">Agronomy</option>
                          </DynamicFilterSelect>
                        </div>
                        <div className="col-12">
                          <DynamicFilterSelect
                            label="Search by status:"
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                status: e.target.value,
                              })
                            }
                            value={filters.status}
                          >
                            <option value="">select all</option>
                            <option value="pending">Pending</option>
                            <option value="being address">Being Address</option>
                            <option value="resolved">Resolved</option>
                          </DynamicFilterSelect>
                        </div>
                        <div className="col-12">
                          {process.env.REACT_APP_ROLE_ADMIN_S === user.Role ||
                          process.env.REACT_APP_ROLE_OPS === user.Role ||
                          process.env.REACT_APP_ROLE_ADMIN === user.Role ? (
                            <DynamicFilterSelect
                              label="Search by assign:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  assign: e.target.value,
                                })
                              }
                              value={filters.assign}
                            >
                              <option value="">select all</option>
                              <option value={user.UserId}>{user.Name}</option>
                            </DynamicFilterSelect>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Search name:"
                            value={filters.generatedBy}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                generatedBy: e.target.value,
                              })
                            }
                            placeholder="search name..."
                          />
                        </div>

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    relatedTo: "",
                                    generatedBy: "",
                                    status: "",
                                    assign: "",
                                  });
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {
                  <FarmQueryTable
                    data={data?.FarmQueries}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                }
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.FarmQueries?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>

          <div className="page-body">
            <div className="container-xl"></div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AgronomyClientManagement;
