import React, { useEffect, useState } from "react";
import LoadingPage from "../../../Components/layouts/loading";
import StepRoutes from "../../../Routes/steps_routes";
import { FieldArray, Form, Formik } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { roundOffCost } from "../../../Components/Functions/amt.fun";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { ClientInvoiceSchema } from "../../../Schemas/clients/client_invoice_schema";
import { useNavigate, useParams } from "react-router-dom";
import {
  lineItemInvoiceInfo,
  ClientInvoiceInfo,
} from "../../../SchemaInfo/info";
import { useSelector } from "react-redux";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";

const EditClientInvoice = () => {
  let userData = useSelector((store) => store.login.user);
  const [isDropdown, setIsDropdown] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(ClientInvoiceInfo);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    performRequest("GET", "/clientProject/getCpName/")
      .then((res) => setProjects(res.data.clientProjectsData))
      .catch((err) => console.error(err));
  }, []);
  const handelSubmit = (values, actions) => {
    values = { ...values, generatedBy: userData.Name };
    let CompData = values?.lineItems?.map((item, index) => {
      return {
        ...item,
        totalWithGst:
          item.itemQuantity * item.itemPrice -
          (item.itemQuantity * item.itemPrice * item.discount) / 100 +
          ((item.itemQuantity * item.itemPrice -
            (item.itemQuantity * item.itemPrice * item.discount) / 100) *
            item.gstRate) /
            100,
        totalWithoutGst:
          item.itemQuantity * item.itemPrice -
          (item.itemQuantity * item.itemPrice * item.discount) / 100,
      };
    });
    values = { ...values, lineItems: CompData };

    setLoading(true);
    performRequest("PATCH", `/invoice/updateInvoice/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Invoice updated successfully!");
          navigate(`/client-invoice/view/${id}`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/invoice/getInvoiceById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const handleInputChange = (e, props) => {
    let { value } = e.target;
    if (value && value.length == 4) {
      performRequest("GET", `/quotations/getQuotationsById/${value}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.error) {
              setIsDropdown("Quotations Not Found");
              props.setFieldValue("clquotation", "");
            } else {
              setIsDropdown(res.data.quotationNumber);
            }
          } else {
            Toast("error", "Error while getting Quotations!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        });
      setIsDropdown(!isDropdown);
    }
  };
  const checkTouched = (obj) => {
    if (
      obj.deliveryDate == "" &&
      obj.gstRate == "" &&
      obj.itemPrice == "" &&
      obj.itemQuantity == "" &&
      obj.itemUnit == "" &&
      obj.notes == "" &&
      obj.particulars == ""
    ) {
      return false;
    } else {
      return true;
    }
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Client Invoice Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Edit Client Invoice</h2>
            </div>
            <Formik
              initialValues={data}
              validationSchema={ClientInvoiceSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Client project
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="clproject">
                          <option value="">Select</option>
                          {projects?.map((item, index) => {
                            return (
                              <option key={index} value={item.clientName}>
                                {item.clientName}
                              </option>
                            );
                          })}
                        </CustomSelect>

                        {props.touched.clproject && props.errors.clproject && (
                          <p className="text-danger">
                            {props.errors.clproject}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3  ">
                        Quotation
                      </label>

                      <div className="w-100 col dropdown">
                        <CustomInput
                          type={"text"}
                          className="form-control"
                          onChange={(e) => {
                            props.setFieldValue("clquotation", e.target.value);
                            handleInputChange(e, props);
                          }}
                          name="clquotation"
                        ></CustomInput>

                        {isDropdown && (
                          <span
                            onClick={() => {
                              if (
                                isDropdown !== "Quotations Not Found" &&
                                isDropdown !== ""
                              ) {
                                props.setFieldValue("clquotation", isDropdown);
                                setIsDropdown("");
                              }
                            }}
                            className="card cursor-pointer"
                          >
                            {isDropdown}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Invoice type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="invoiceType">
                          <option value="invoice">Invoice</option>
                          <option value="proforma invoice">
                            Proforma Invoice
                          </option>
                          <option value="quotation">Quotation</option>
                        </CustomSelect>

                        {props.touched.invoiceType &&
                          props.errors.invoiceType && (
                            <p className="text-danger">
                              {props.errors.invoiceType}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Invoice date
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="invoiceDate" type="date" />
                        {props.touched.invoiceDate &&
                          props.errors.invoiceDate && (
                            <p className="text-danger">
                              {props.errors.invoiceDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Total amount (inc) GST
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="totalAmountIncGst" type="text" />
                        {props.touched.totalAmountIncGst &&
                          props.errors.totalAmountIncGst && (
                            <p className="text-danger">
                              {props.errors.totalAmountIncGst}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Business name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="businessName" type="text" />
                        {props.touched.businessName &&
                          props.errors.businessName && (
                            <p className="text-danger">
                              {props.errors.businessName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Contact person name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="contactPersonName" type="text" />
                        {props.touched.contactPersonName &&
                          props.errors.contactPersonName && (
                            <p className="text-danger">
                              {props.errors.contactPersonName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Phone number
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="phoneNumber" type="text" />
                        {props.touched.phoneNumber &&
                          props.errors.phoneNumber && (
                            <p className="text-danger">
                              {props.errors.phoneNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        GST number
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="gstNumber" type="text" />
                        {props.touched.gstNumber && props.errors.gstNumber && (
                          <p className="text-danger">
                            {props.errors.gstNumber}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Address
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="address" type="text" />
                        {props.touched.address && props.errors.address && (
                          <p className="text-danger">{props.errors.address}</p>
                        )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Country of supply
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="countryOfSupply">
                          <option value="">Select Country</option>
                          <option value="india">India</option>
                        </CustomSelect>

                        {props.touched.countryOfSupply &&
                          props.errors.countryOfSupply && (
                            <p className="text-danger">
                              {props.errors.countryOfSupply}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Place of supply
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="placeOfSupply" type="text" />
                        {props.touched.placeOfSupply &&
                          props.errors.placeOfSupply && (
                            <p className="text-danger">
                              {props.errors.placeOfSupply}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Invoice for
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="invoiceFor" type="text" />
                        {props.touched.invoiceFor &&
                          props.errors.invoiceFor && (
                            <p className="text-danger">
                              {props.errors.invoiceFor}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Total GST</label>
                      <div className="w-100 col">
                        <CustomInput name="totalGst" type="text" />
                        {props.touched.totalGst && props.errors.totalGst && (
                          <p className="text-danger">{props.errors.totalGst}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>
                      <div className="w-100 col">
                        <CustomTextArea name="notes" type="text" />
                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>

                    <hr />
                    <h3>Line Items :</h3>
                    <hr />
                    <FieldArray name="lineItems">
                      {({ push, remove }) => (
                        <div>
                          {props.values.lineItems.map((lineItem, index) => (
                            <div key={index}>
                              <div className="row g-3 align-items-center">
                                <div className="col-md">
                                  <label className=" form-label">
                                    Particulars:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type="text"
                                      name={`lineItems[${index}].particulars`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">Units:</label>

                                  <div className="w-100 col">
                                    <CustomSelect
                                      type="text"
                                      name={`lineItems[${index}].itemUnit`}
                                    >
                                      <option value="">Select</option>
                                      <option value="nos">Nos</option>
                                      <option value="kg">Kg</option>
                                      <option value="grams">Grams</option>
                                      <option value="ltr">Litter</option>
                                      <option value="ml">ML</option>
                                      <option value="packs">Packs</option>
                                      <option value="sqm">SqM</option>
                                      <option value="sqft">SqFt</option>
                                    </CustomSelect>
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Quantity
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type={"text"}
                                      name={`lineItems[${index}].itemQuantity`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Item price
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type="text"
                                      name={`lineItems[${index}].itemPrice`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    GST (%)
                                  </label>
                                  <div className="w-100 col">
                                    <CustomSelect
                                      type="text"
                                      name={`lineItems[${index}].gstRate`}
                                    >
                                      <option value="">Select</option>
                                      <option value="0">0 %</option>
                                      <option value="5">5%</option>
                                      <option value="12">12%</option>
                                      <option value="18">18%</option>
                                      <option value="28">28%</option>
                                      <option value="100">100%</option>
                                    </CustomSelect>
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Discount
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type={"text"}
                                      name={`lineItems[${index}].discount`}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row g-3 align-items-center">
                                <div className="col-md">
                                  <label className=" form-label ">HSN</label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type={"text"}
                                      name={`lineItems[${index}].HSN`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Amount (excl GST)
                                  </label>
                                  <div className="w-100 col">
                                    {roundOffCost(
                                      lineItem.itemQuantity *
                                        lineItem.itemPrice -
                                        (lineItem.itemQuantity *
                                          lineItem.itemPrice *
                                          lineItem.discount) /
                                          100
                                    )}
                                  </div>
                                </div>
                                {/* add po */}
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Amount (Inc GST):
                                  </label>
                                  <div className="w-100 col">
                                    {roundOffCost(
                                      lineItem.itemQuantity *
                                        lineItem.itemPrice -
                                        (lineItem.itemQuantity *
                                          lineItem.itemPrice *
                                          lineItem.discount) /
                                          100 +
                                        ((lineItem.itemQuantity *
                                          lineItem.itemPrice -
                                          (lineItem.itemQuantity *
                                            lineItem.itemPrice *
                                            lineItem.discount) /
                                            100) *
                                          lineItem.gstRate) /
                                          100
                                    )}
                                  </div>
                                </div>
                                <div className="col-md">
                                  <button
                                    className="col-3 btn btn-primary"
                                    style={{
                                      width: "70px",
                                      height: "30px",
                                    }}
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))}

                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              if (props.values.lineItems.length == 0) {
                                push(lineItemInvoiceInfo);
                              } else if (
                                props.values.lineItems.length > 0 &&
                                checkTouched(
                                  props.values.lineItems[
                                    props.values.lineItems.length - 1
                                  ]
                                )
                              ) {
                                push(lineItemInvoiceInfo);
                              } else {
                                Toast(
                                  "error",
                                  "Please fill at least one line item field."
                                );
                              }
                            }}
                          >
                            Add Line Item
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Invoice" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default EditClientInvoice;
