import * as yup from "yup";

export const ClientProjectHandoverSchema = yup.object().shape({
  projectId: yup.number().required("Required"),
  handOverDate: yup.date(),
  siteSelectionId: yup.number().required("Required"),

  generatedBy: yup.string(),

  northSouthMeasurmentInMeter: yup.string(),
  northSouthMeasurmentPhoto: yup.mixed(),
  eastWestMeasurmentInMeter: yup.string(),
  eastWestMeasurmentPhoto: yup.mixed(),
  handOverCertificate: yup.mixed(),
  descriptionVideo: yup.string(),
  notes: yup.string(),
  overheadTankPhoto: yup.mixed(),
  aFramePhoto: yup.mixed(),
  netCupPhoto: yup.mixed(),
  aFrameFoundationPhoto: yup.mixed(),
  ventOpenerPhoto: yup.mixed(),
  aluminiumShadeNetPullyPhoto: yup.mixed(),
  exhaustFanPhoto: yup.mixed(),
  cellusePadPhoto: yup.mixed(),
  waterPumpPhoto: yup.mixed(),
  waterPumpDrainagePhoto: yup.mixed(),
  oxigenationPumpPhoto: yup.mixed(),
  uvLightPhoto: yup.mixed(),
  diskFilterPhoto: yup.mixed(),
  sandFilterPhoto: yup.mixed(),
  foggerAssemblyPhoto: yup.mixed(),
  customerPhotoWithSetUp: yup.mixed(),
  customerFeedbackVideo: yup.mixed(),
});
