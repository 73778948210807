import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import LoadingPage from "../../../Components/layouts/loading";
import {
  WaterManagement,
  TimeLineWaterManagementInfo,
} from "../../../SchemaInfo/info";
import { WaterManagementCreateSchema } from "../../../Schemas/Water Management/create_water_cycle";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import { Formik, Form, FieldArray } from "formik";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import Footer from "../../../Components/Footer/footer";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";

import { FcDam } from "react-icons/fc";
import { BsWater } from "react-icons/bs";

const EditWaterCycle = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [arrayLength, setArrayLength] = useState();
  const navigate = useNavigate();
  const [waterManagement, setWaterManagement] = useState(WaterManagement);
  let { id } = useParams();

  const handelSubmit = (values, actions) => {
    values = {
      ...values,
      generatedBy: userData?.Name,
    };
    setLoading(true);
    performRequest(
      "PATCH",
      `/waterManagement/updateWaterManagement/${id}`,
      values
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Water cycle updated successfully!");
          navigate(`/farm-water-management/view/${id}`);
        } else {
          Toast("error", "Error while Creating");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/waterManagement/getWaterManagementById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setWaterManagement(res.data);
          setArrayLength(res.data.timeLines.length);
        } else {
          Toast("error", "Error while getting client expense!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  const checkTouched = (obj) => {
    return !(
      obj.phReading === "" &&
      obj.ecReading === "" &&
      obj.scheduleDate === ""
    );
  };

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcDam className="fs-2  me-1" /> Water Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Track & manage your water tanks.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a
                    href={"/farm-water-management/new"}
                    className="btn btn-primary"
                  >
                    {" "}
                    <BsWater className="fs-3 me-2" /> Create water cycle
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <h3>Precautions for water reading:</h3>
                <ol>
                  <li>Always use same location to take the reading.</li>
                  <li>Use two different PH & EC meter to take the reading.</li>
                  <li>Always use well calibrated PH & EC meter.</li>
                  <li>
                    If you see sudden fluctuation in reading. Check PH & EC
                    meter for calibration.
                  </li>
                  <li>Do not miss your schedule.</li>
                  <li>⁠Take actions as suggested by agronomist.</li>
                  <li>
                    If you are stuck with progress or results, contact your
                    agronomist or relation manager.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={waterManagement}
                  validationSchema={WaterManagementCreateSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Water cycle name
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="cycleName"
                              type="text"
                              disabled
                            />
                            {props.touched.cycleName &&
                              props.errors.cycleName && (
                                <p className="text-danger">
                                  {props.errors.cycleName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3 ">
                            Type of system
                          </label>
                          <div className="w-100 col">
                            <CustomSelect
                              type="text"
                              name="typeOfSystem"
                              disabled
                            >
                              <option value="">Select</option>
                              <option value="a-frame">A-Frame</option>
                              <option value="flatBed">Flatbed</option>
                              <option value="growBags">Grow Bags</option>
                              <option value="dutchBucket">Dutch Bucket</option>
                              <option value="indoorHydroponic">
                                Indoor Hydroponic
                              </option>
                            </CustomSelect>
                            {props.touched.typeOfSystem &&
                              props.errors.typeOfSystem && (
                                <p className="text-danger">
                                  {props.errors.typeOfSystem}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3 ">
                            Water tank capacity
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="waterTankCapacity"
                              type="text"
                              disabled
                            />
                            {props.touched.waterTankCapacity &&
                              props.errors.waterTankCapacity && (
                                <p className="text-danger">
                                  {props.errors.waterTankCapacity}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Starting PH
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="startingPh"
                              type="text"
                              disabled
                            />
                            {props.touched.startingPh &&
                              props.errors.startingPh && (
                                <p className="text-danger">
                                  {props.errors.startingPh}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Starting EC
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="startingEc"
                              type="text"
                              disabled
                            />
                            {props.touched.startingEc &&
                              props.errors.startingEc && (
                                <p className="text-danger">
                                  {props.errors.startingEc}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3 ">
                            Water source
                          </label>
                          <div className="w-100 col">
                            <CustomSelect
                              type="text"
                              name="waterSource"
                              disabled
                            >
                              <option value="">Select</option>
                              <option value="borewell">Borewell</option>
                              <option value="well">Well</option>
                              <option value="municipalWater">
                                Municipal Water
                              </option>
                              <option value="pond">Pond</option>
                              <option value="rainHarvesting">
                                Rain Harvesting
                              </option>
                              <option value="tanker">Tanker</option>
                            </CustomSelect>
                            {props.touched.waterSource &&
                              props.errors.waterSource && (
                                <p className="text-danger">
                                  {props.errors.waterSource}
                                </p>
                              )}
                          </div>
                        </div>
                        <hr />
                        <FieldArray name="timeLines">
                          {({ push, remove }) => (
                            <div>
                              {props.values.timeLines.map((lineItem, index) => (
                                <div key={index}>
                                  <div className="row g-3 align-items-center">
                                    <div className="col-md">
                                      <label className="form-label required">
                                        PH Reading
                                      </label>
                                      <div className="w-100 col">
                                        <CustomInput
                                          type="text"
                                          name={`timeLines[${index}].phReading`}
                                          onChange={(e) => {
                                            const phReadingValue =
                                              e.target.value;
                                            props.setFieldValue(
                                              `timeLines[${index}].phReading`,
                                              phReadingValue
                                            );

                                            if (
                                              phReadingValue &&
                                              lineItem.ecReading
                                            ) {
                                              let date = new Date();
                                              props.setFieldValue(
                                                `timeLines[${index}].scheduleDate`,
                                                date.toISOString().split("T")[0]
                                              );
                                            } else {
                                              props.setFieldValue(
                                                `timeLines[${index}].scheduleDate`,
                                                ""
                                              );
                                            }
                                          }}
                                          disabled={index < arrayLength}
                                        />
                                        {props.touched.timeLines?.[index]
                                          ?.phReading &&
                                          props.errors.timeLines?.[index]
                                            ?.phReading && (
                                            <p className="text-danger">
                                              {
                                                props.errors.timeLines[index]
                                                  .phReading
                                              }
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md">
                                      <label className="form-label required">
                                        EC Reading
                                      </label>
                                      <div className="w-100 col">
                                        <CustomInput
                                          type="text"
                                          name={`timeLines[${index}].ecReading`}
                                          onChange={(e) => {
                                            const ecReadingValue =
                                              e.target.value;
                                            props.setFieldValue(
                                              `timeLines[${index}].ecReading`,
                                              ecReadingValue
                                            );

                                            if (
                                              ecReadingValue &&
                                              lineItem.phReading
                                            ) {
                                              let date = new Date();
                                              props.setFieldValue(
                                                `timeLines[${index}].scheduleDate`,
                                                date.toISOString().split("T")[0]
                                              );
                                            } else {
                                              props.setFieldValue(
                                                `timeLines[${index}].scheduleDate`,
                                                ""
                                              );
                                            }
                                          }}
                                          disabled={index < arrayLength}
                                        />
                                        {props.touched.timeLines?.[index]
                                          ?.ecReading &&
                                          props.errors.timeLines?.[index]
                                            ?.ecReading && (
                                            <p className="text-danger">
                                              {
                                                props.errors.timeLines[index]
                                                  .ecReading
                                              }
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md">
                                      <label className="form-label">
                                        Reading on
                                      </label>
                                      <div className="w-100 col">
                                        {lineItem.scheduleDate
                                          ? lineItem.scheduleDate
                                          : "---"}
                                      </div>
                                    </div>
                                    <div className="col-md">
                                      <button
                                        disabled={index < arrayLength}
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              ))}
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  if (props.values.timeLines.length === 0) {
                                    push(TimeLineWaterManagementInfo);
                                  } else if (
                                    props.values.timeLines.length > 0 &&
                                    checkTouched(
                                      props.values.timeLines[
                                        props.values.timeLines.length - 1
                                      ]
                                    )
                                  ) {
                                    push(TimeLineWaterManagementInfo);
                                  } else {
                                    Toast(
                                      "error",
                                      "Please fill at least one line item field."
                                    );
                                  }
                                }}
                              >
                                Add New PH / EC Entry
                              </button>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Save Water Cycle" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditWaterCycle;
