import React from "react";
import { BsSunriseFill, BsSunsetFill } from "react-icons/bs";

const FooterCopyright = () => {
  return (
    <footer className="footer footer-transparent d-print-none">
      <hr />
      <div className="container-xl">
        <div className="row text-center align-items-center">
          <div className="mb-3">
            <a
              href="?theme=dark"
              className="btn hide-theme-dark fs-5"
              title="Enable dark mode"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
            >
              <BsSunsetFill className="fs-2 me-2" /> Switch to Dark Mode
            </a>
            <a
              href="?theme=light"
              className="btn hide-theme-light  fs-5"
              title="Enable light mode"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
            >
              <BsSunriseFill className="fs-2 me-2" /> Switch to Light Mode
            </a>
          </div>
          <p>Copyright &copy; 2019 - 2024 Kryzen Biotech Pvt Ltd.</p>
          <p>
            CIN: U01100PN2019PTC186207 | GST: 27AAHCK7659R1ZF
            <br />
            Product of India, Developed for the world
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterCopyright;
