import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import StepRoutes from "../../Routes/steps_routes";
import InternalDocuments from "./Documents/internal_documents";
import PersonalDocuments from "./Documents/personal_documents";
import SimpleButton from "../../Components/layouts/simple_button";
import performRequest from "../../api/request";

const EmployeeDocuments = () => {
  const [switchDocs, setSwitchDocs] = useState("internal");

  useEffect(() => {
    performRequest("GET", "/employeeManagement/getEmployeeManagement")
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  }, []);
  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <h2 className="page-title">Documentation</h2>
              <h4 className="text-muted">
                <StepRoutes />
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl">
        <div className="card">
          <div className="card-header">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">
                  {switchDocs === "internal"
                    ? "Internal Documents"
                    : "Personal Documents"}
                </h2>
              </div>
            </div>
          </div>
          <div className="card-body" style={{ minHeight: "70vh" }}>
            <div className="row g-4">
              <div className="col-3 border p-3">
                <div className="list-group list-group-transparent mb-3">
                  <span
                    onClick={() => setSwitchDocs("internal")}
                    className={
                      switchDocs === "internal"
                        ? "list-group-item cursor-pointer list-group-item-action d-flex align-items-center active"
                        : "list-group-item cursor-pointer list-group-item-action d-flex align-items-center"
                    }
                  >
                    Internal Documents
                  </span>
                  <span
                    onClick={() => setSwitchDocs("personal")}
                    className={
                      switchDocs === "personal"
                        ? "list-group-item cursor-pointer list-group-item-action d-flex align-items-center active"
                        : "list-group-item cursor-pointer list-group-item-action d-flex align-items-center"
                    }
                  >
                    Personal Documents
                  </span>
                </div>
              </div>
              {switchDocs === "internal" ? (
                <InternalDocuments />
              ) : (
                <PersonalDocuments />
              )}
            </div>
          </div>
          <div className="card-footer">
            <div className="btn-list justify-content-end">
              <SimpleButton buttonText="close" type="secondary" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmployeeDocuments;
