import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { roundOffCost } from "../../../Components/Functions/amt.fun";
import { useSelector } from "react-redux";

const ViewPaymentTracking = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/paymentTracking/getPaymentTrackingById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Payment Info!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Payment Tracking</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">View Payment</h2>
            </div>
            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Project</label>
                  <div className="w-100 col">
                    {data?.projectClientName || "N/A".toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Client Contact
                  </label>
                  <div className="w-100 col">
                    {data?.projectClientContactNumber || "N/A".toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Payment Date</label>
                  <div className="w-100 col">{data?.paymentDate || "N/A"}</div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Payment amount (-GST)
                  </label>
                  <div className="w-100 col">
                    {data?.paymentAmount
                      ? roundOffCost(data?.paymentAmount)
                      : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">GST Rate</label>
                  <div className="w-100 col">
                    {data?.gstRate ? `${data?.gstRate} %` : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Payment amount (+GST)
                  </label>
                  <div className="w-100 col">
                    {data.paymentAmount
                      ? roundOffCost(
                          +data.paymentAmount +
                            +data.paymentAmount * +data.gstRate * 0.01
                        )
                      : roundOffCost(0)}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Payment mode</label>
                  <div className="w-100 col">
                    {(data?.paymentMode || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Notes</label>
                  <div className="w-100 col">
                    {(data?.notes || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                {user.Role !== process.env.REACT_APP_ROLE_CLIENT &&
                  user.Role !== process.env.REACT_APP_ROLE_D_CLIENT && (
                    <SimpleButton
                      buttonText="Create Invoice"
                      // href={`/client-payment-tracking/edit/${id}`}
                    />
                  )}
                {user.Role !== process.env.REACT_APP_ROLE_CLIENT &&
                  user.Role !== process.env.REACT_APP_ROLE_D_CLIENT && (
                    <SimpleButton
                      buttonText="Edit Payment"
                      href={`/client-payment-tracking/edit/${id}`}
                    />
                  )}
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewPaymentTracking;
