import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const tableHead = [
  "Sr.",
  "Person name",
  "Mobile Number",
  "Template",
  "Origin",
  "Generated By",
  "Template Send Date",
  "Actions",
];
const WatsappLogsTable = ({ data, currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/watsapp-logs/view/${id}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.name || "N/A"}</td>
                <td>{item?.contactNumber || "N/A"}</td>
                <td>{item?.whatsApptemplateName || "N/A"}</td>
                <td>{item?.origin || "N/A"}</td>
                <td>{item?.genratedBy || "N/A"}</td>
                <td>{item?.whatsApptemplateMessageSendDate || "N/A"}</td>
                <td>
                  <button
                    className="btn btn-sm px-3 btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    <BsArrowRightShort className="me-1" />
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default WatsappLogsTable;
