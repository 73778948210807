import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const tableHead = [
  "Sr.",
  "Person name",
  "Mobile Number",
  "Call Date & Time",
  // "Call Type",
  "Duration",
  "Actions",
];
const MobileAppTable = ({ data, currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/android-app/view/${id}`);
  };

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    const strTime = `${hours}:${minutes}:${seconds} ${ampm}`;

    return `${day} ${month} ${year}, ${strTime}`;
  }

  const returnClassname = (isInternet) => {
    if (isInternet) {
      return "badge bg-success me-1";
    } else {
      return "badge bg-danger me-1";
    }
  };

  const getRowBackgroundColor = (item) => {
    if (item?.callType === "Incoming") {
      return "rgba(0, 128, 0, 0.2)";
    } else if (item?.callType === "Outgoing") {
      return "rgba(145, 204, 240, 0.5)";
    } else {
      return "rgba(255, 255, 0, 0.2)";
    }
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr
                key={item?.id}
                style={{
                  backgroundColor: getRowBackgroundColor(item),
                }}
              >
                <td>{Index}</td>
                <td>{item?.loginPersonName || "N/A"}</td>
                <td>
                  <span
                    className={returnClassname(item?.isInternetConnected)}
                  ></span>
                  {item?.mobileNumber || "N/A"}
                </td>
                <td>{formatDateTime(item?.callTime) || "N/A"}</td>
                {/* <td>{item?.callType || "N/A"}</td> */}
                <td>{item?.duration || "N/A"}</td>
                <td>
                  <button
                    className="btn btn-sm px-3 btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    <BsArrowRightShort className="me-1" />
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default MobileAppTable;
