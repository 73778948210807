import React, { useEffect, useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useNavigate, useParams } from "react-router-dom";
import { ClientExpenseInfo, ClientPaymentInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { ClientExpenseSchema } from "../../../Schemas/clients/client_expense_schema";
import { uploadImage } from "../../../CustomComponent/upload_Image";
import { ClientPaymentSchema } from "../../../Schemas/clients/client_payment_tracking_schema";
import { roundOffCost } from "../../../Components/Functions/amt.fun";

const getId = (str) => {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  return parseInt(lastPart, 10);
};

const EditPaymentTracking = () => {
  const [loading, setLoading] = useState(false);

  const [clientPaymentInfo, setClientPaymentInfo] = useState(ClientPaymentInfo);
  let { id } = useParams();

  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  const [siteInspections, setSiteInspections] = useState([]);
  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    performRequest("GET", "/siteInspection/getallSiteInspection")
      .then((res) => setSiteInspections(res.data))
      .catch((err) => console.error(err));
  }, []);
  const handelSubmit = (values, actions) => {
    // loading true
    setLoading(true);
    performRequest(
      "PATCH",
      `/paymentTracking/updatePaymentTracking/${id}`,
      values
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Payment updated successfully!");
          navigate(`/client-payment-tracking/view/${id}`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/paymentTracking/getPaymentTrackingById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setClientPaymentInfo(res.data);
        } else {
          Toast("error", "Error while getting client expense!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Payment Tracking</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Edit Payment</h2>
            </div>

            <Formik
              initialValues={clientPaymentInfo}
              validationSchema={ClientPaymentSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Project</label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="projectSelection">
                          <option value="">Select</option>
                          {projects &&
                            projects?.map((pro) => {
                              return (
                                <option
                                  key={pro.cpNumber}
                                  value={getId(pro.cpNumber)}
                                >
                                  {pro.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>

                        {props.touched.projectSelection &&
                          props.errors.projectSelection && (
                            <p className="text-danger">
                              {props.errors.projectSelection}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Site inspection
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="siteSelection">
                          <option value="">Select</option>
                          {siteInspections &&
                            siteInspections?.map((si) => {
                              return (
                                <option
                                  key={si.siNumber}
                                  value={getId(si.siNumber)}
                                >
                                  {si.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>

                        {props.touched.siteSelection &&
                          props.errors.siteSelection && (
                            <p className="text-danger">
                              {props.errors.siteSelection}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Payment date
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="paymentDate" type="date" />
                        {props.touched.paymentDate &&
                          props.errors.paymentDate && (
                            <p className="text-danger">
                              {props.errors.paymentDate}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Payment amount (pre gst)
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="paymentAmount" type="text" />
                        {props.touched.paymentAmount &&
                          props.errors.paymentAmount && (
                            <p className="text-danger">
                              {props.errors.paymentAmount}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        GST Rate
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="gstRate" type="text">
                          <option value={0}>0%</option>
                          <option value={3}>3%</option>
                          <option value={5}>5%</option>
                          <option value={18}>18%</option>
                          <option value={23}>23%</option>
                        </CustomSelect>
                        {props.touched.gstRate && props.errors.gstRate && (
                          <p className="text-danger">{props.errors.gstRate}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Payment amount (with gst)
                      </label>

                      <div className="w-100 col">
                        {props.values.paymentAmount
                          ? roundOffCost(
                              +props.values.paymentAmount +
                                +props.values.paymentAmount *
                                  +props.values.gstRate *
                                  0.01
                            )
                          : roundOffCost(0)}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Payment mode
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="paymentMode" type="text">
                          <option value="">Select</option>
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="upi">UPI</option>
                          <option value="imps">IMPS</option>
                          <option value="rtgs">RTGS</option>
                          <option value="neft">NEFT</option>
                        </CustomSelect>
                        {props.touched.paymentMode &&
                          props.errors.paymentMode && (
                            <p className="text-danger">
                              {props.errors.paymentMode}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>

                      <div className="w-100 col">
                        <CustomTextArea type="text" name="notes" />

                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Payment Tracking" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default EditPaymentTracking;
