import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const getId = (str) => {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  return parseInt(lastPart, 10);
};

const ViewFarmTraining = () => {
  const user = useSelector((store) => store.login.user);
  const [data, setData] = useState();
  let { id } = useParams();
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/farmTraining/getFarmTrainingById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          console.log(data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Farm training</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header row g-2 align-items-center">
              <div className="col">
                <h2>View farm training</h2>
              </div>
              <div className="col-auto ms-auto d-print-none"></div>
            </div>
            <div className="card-body">
              {data && (
                <>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Name</label>
                    <div className="w-100 col">
                      {(data?.name || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Email</label>
                    <div className="w-100 col">
                      {(data?.email || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Contact number
                    </label>
                    <div className="w-100 col">
                      {(data?.contactNumber || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Enroll date</label>
                    <div className="w-100 col">
                      {(data?.enrollDate || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Farm training date
                    </label>
                    <div className="w-100 col">
                      {(data?.farmTrainingDate || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Amount paid</label>
                    <div className="w-100 col">{data?.amountPaid || "N/A"}</div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Mode of payment
                    </label>
                    <div className="w-100 col">
                      {(data?.modeOfPayment || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Food preference
                    </label>
                    <div className="w-100 col">
                      {(`${data?.foodPreference}` || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Hotel booked
                    </label>
                    <div className="w-100 col">
                      {(`${data?.hotelBooked}` || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Upgrade request
                    </label>
                    <div className="w-100 col">
                      {(data?.upgradeRequest &&
                        data.upgradeRequest.join(", ").toUpperCase()) ||
                        "N/A"}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Client type</label>
                    <div className="w-100 col">
                      {(data?.clientType &&
                        data.clientType.join(", ").toUpperCase()) ||
                        "N/A"}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Farm training location
                    </label>
                    <div className="w-100 col">
                      {(data?.farmTrainingLocation || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Farm training location URL
                    </label>
                    <div className="w-100 col">
                      {data?.farmTrainingLocationUrl ? (
                        <a href={`${data.farmTrainingLocationUrl}`}>
                          {data.farmTrainingLocationUrl}
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText="Edit Farm Training"
                  onClick={() => navigate(`/farm-training/edit/${id}`)}
                />
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewFarmTraining;
