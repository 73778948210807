import React from "react";

const FlatBedSetup = () => {
  let serial = 1;
  return (
    <div>
      <h3 className="table-heading">
        Flat Bed-NFT Setup (Nutrient Film Technique):
      </h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serial++}.</td>
            <td>Flatbed shape stands</td>
            <td>Single layer stand of square pipes.</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Pipe dimensions</td>
            <td>Double layer square pipe of dimension 100mm x 50mm.</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>NFT pipes, end caps and accessories</td>
            <td>
              20ft long square pipes, with holes in each pipe (or as per the
              final dimensions)
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Length of pipe</td>
            <td>
              Length of pipe is decided on available area, possible pathway and
              slope of land inside polyhouse. NFT pipe modifications are done at
              factory end as per primary available data from inspection team
              report.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Other accessories (pump, piping, plumbing, fittings etc)</td>
            <td>
              3 electric motors including foggers system, Sand filter, Keys, end
              caps, pipe connectors, etc.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>
              Electrical fittings, circuit breakers, wires, junction boxes etc
            </td>
            <td>
              All electrical work, electric motor connections will be done by
              KBPL.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Number of pipes on bed</td>
            <td>10 number of 20 ft long sqaure pipe on one bed.</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Walking distance</td>
            <td>
              Approximately 3.5 to 4.5 ft walking distance will be maintained.
              So personal with a crate can move freely.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Inter pipe connectivity</td>
            <td>
              All NFT pipes will be connected with each other using a flexible
              pipe with keys to control water flow, flexible pipes will have
              replaceable fittings for easy maintenance of the system.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Water outlet</td>
            <td>
              Outlet water is collected through a closed pvc pipe and fed back
              to the recirculation piping system.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Stand colouring</td>
            <td>
              All stands will be coated with two layers of white colour to avoid
              rusting and also reflect sunlight.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Net cups</td>
            <td>
              Procurement and delivery of 2/2.5in net cups on or before the day
              of handover.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Pipe cleaning mechanism</td>
            <td>
              All square pipes have side slit and can be opened from top for
              cleaning.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Nutrient flow</td>
            <td>
              All square pipes come with guidance lines for proper nutrient
              flow.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FlatBedSetup;
