import * as yup from "yup";
export const QuotationMatrixCreateSchema = yup.object().shape({
  type: yup.string().required("Required"),
  factor: yup.string().required("Required"),
  markup: yup.number(),
  basePrice1to190: yup.number().required("Required"),
  basePrice191to450: yup.number().required("Required"),
  basePrice451to950: yup.number().required("Required"),
  basePrice951to1860: yup.number().required("Required"),
  basePrice1861to3720: yup.number().required("Required"),
  basePrice3721to5999: yup.number().required("Required"),
  basePrice6000to7999: yup.number().required("Required"),
  basePrice8000Plus: yup.number().required("Required"),

  empIncentivePercentage1to190: yup.number(),
  empIncentivePercentage191to450: yup.number(),
  empIncentivePercentage451to950: yup.number(),
  empIncentivePercentage951to1860: yup.number(),
  empIncentivePercentage1861to3720: yup.number(),
  empIncentivePercentage3721to5999: yup.number(),
  empIncentivePercentage6000to7999: yup.number(),
  empIncentivePercentage8000Plus: yup.number(),

  sqMtrWeed: yup.number().required("Required"),
  markupPercentGP: yup.number().required("Required"),
  siteInspectionPerProject: yup.number(),
  officeAdminCostPerProject: yup.number(),
  marketingBudgetPerProject: yup.number(),
  rentSalaryPerProject: yup.number(),
  labourTicketMHGOAGJ: yup.number(),
  labourTicketRJMPTLCGKA: yup.number(),
  labourTicketPBHRUPJHODAPTNKL: yup.number(),
  maximumDiscount: yup.number(),
});
