import React from "react";
import { useNavigate } from "react-router-dom";

import { BsArrowRightShort } from "react-icons/bs";

const tableHead = [
  "Sr.",
  "Farm Name",
  "Resource Name",
  "Vendor",
  "Amount",
  "Refill Frequency",
  "Actions",
];

const InventoryTrackingTable = ({ EX = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/resources-inventory/view/${id}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {EX?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.farmName || "N/A"}</td>
                <td>{item?.assetName || "N/A"}</td>
                <td>{item?.vendor || "N/A"}</td>
                <td>{item?.paymentAmount || "N/A"}</td>

                <td>{item?.frequencyCycle || "N/A"}</td>

                <td>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => goToView(item.id)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryTrackingTable;
