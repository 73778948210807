import React, { useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AssetsCreateInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { AssetsCreateSchema } from "../../../Schemas/resources/assets_create_schema";
import { uploadImage } from "../../../CustomComponent/upload_Image";
import { FcSms } from "react-icons/fc";
import { BsPostcardHeartFill } from "react-icons/bs";

// const getId = (str) => {
//   const parts = str.split("/");
//   const lastPart = parts[parts.length - 1];
//   return parseInt(lastPart, 10);
// };

const AddAssets = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handelSubmit = (values, actions) => {
    values = {
      ...values,
      genratedBy: userData?.UserId,
    };

    setLoading(true);
    performRequest("POST", "/asset/createAsset/", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Asset created successfully!");
          navigate(`/resources-assets`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcSms className="fs-2  me-1" /> Add your assets
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Add new asset.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/resources-assets"} className="btn btn-primary">
                    {" "}
                    <BsPostcardHeartFill className="fs-3 me-2" /> Asset List
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <p>
                  When you add new assets. Make sure you add bill of purchase
                  also do not forget to make sure make and specifications of
                  assets is mentioned in bill. In case it is not mentioned in
                  bill add it seperately in Notes. This will help AMC and
                  agronomy team to understand what are the construction changes
                  have been done.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={AssetsCreateInfo}
                  validationSchema={AssetsCreateSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Resource / Asset name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="resourceName" type="text" />
                            {props.touched.resourceName &&
                              props.errors.resourceName && (
                                <p className="text-danger">
                                  {props.errors.resourceName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Company name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="companyName" type="text" />
                            {props.touched.companyName &&
                              props.errors.companyName && (
                                <p className="text-danger">
                                  {props.errors.companyName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Vendor
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="vendor" type="text" />
                            {props.touched.vendor && props.errors.vendor && (
                              <p className="text-danger">
                                {props.errors.vendor}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Deployment date
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="deploymentDate" type="date" />
                            {props.touched.deploymentDate &&
                              props.errors.deploymentDate && (
                                <p className="text-danger">
                                  {props.errors.deploymentDate}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 ">
                            Warranty period
                          </label>
                          <div className="w-100 col">
                            <CustomSelect name="warrantyPeriod" type="text">
                              <option value={" "}>Select</option>
                              <option value={0}>0 month</option>
                              <option value={1}>1 month</option>
                              <option value={2}>2 month</option>
                              <option value={3}>3 month</option>
                              <option value={4}>4 month</option>
                              <option value={5}>5 month</option>
                              <option value={6}>6 month</option>
                              <option value={7}>7 month</option>
                              <option value={8}>8 month</option>
                              <option value={9}>9 month</option>
                              <option value={10}>10 month</option>
                              <option value={11}>11 month</option>
                              <option value={12}>12 month</option>
                              <option value={13}>13 month</option>
                              <option value={14}>14 month</option>
                              <option value={15}>15 month</option>
                              <option value={16}>16 month</option>
                              <option value={17}>17 month</option>
                              <option value={18}>18 month</option>
                              <option value={19}>19 month</option>
                              <option value={20}>20 month</option>
                              <option value={21}>21 month</option>
                              <option value={22}>22 month</option>
                              <option value={23}>23 month</option>
                              <option value={24}>24 month</option>
                            </CustomSelect>
                            {props.touched.warrantyPeriod &&
                              props.errors.warrantyPeriod && (
                                <p className="text-danger">
                                  {props.errors.warrantyPeriod}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Payment amount
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="paymentAmount" type="text" />
                            {props.touched.paymentAmount &&
                              props.errors.paymentAmount && (
                                <p className="text-danger">
                                  {props.errors.paymentAmount}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 ">
                            Payment mode
                          </label>
                          <div className="w-100 col">
                            <CustomSelect name="paymentMode" type="text">
                              <option value="">Select</option>
                              <option value="cash">Cash</option>
                              <option value="cheque">Cheque</option>
                              <option value="upi">UPI</option>
                              <option value="imps">IMPS</option>
                              <option value="rtgs">RTGS</option>
                              <option value="neft">NEFT</option>
                            </CustomSelect>
                            {props.touched.paymentMode &&
                              props.errors.paymentMode && (
                                <p className="text-danger">
                                  {props.errors.paymentMode}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Upload bill
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="uploadBill"
                              type="file"
                              onChange={(e) =>
                                uploadImage(
                                  props,
                                  "uploadBill",
                                  e.target.files[0]
                                )
                              }
                            />
                            {props.touched.uploadBill &&
                              props.errors.uploadBill && (
                                <p className="text-danger">
                                  {props.errors.uploadBill}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Upload warranty card
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="uploadWarrantyCard"
                              type="file"
                              onChange={(e) =>
                                uploadImage(
                                  props,
                                  "uploadWarrantyCard",
                                  e.target.files[0]
                                )
                              }
                            />
                            {props.touched.uploadWarrantyCard &&
                              props.errors.uploadWarrantyCard && (
                                <p className="text-danger">
                                  {props.errors.uploadWarrantyCard}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3 ">
                            Notes / Ref.no
                          </label>

                          <div className="w-100 col">
                            <CustomTextArea type="text" name="notes" />
                            {props.touched.notes && props.errors.notes && (
                              <p className="text-danger">
                                {props.errors.notes}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Create Asset" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default AddAssets;
