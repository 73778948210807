import React from "react";

import hthd from "../../../certificates/hthd.png";
const UserDetails = ({ userInfo, area }) => {
  let serial = 1;
  return (
    <div>
      <h3>Quotation Details For:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serial++}</td>
            <td>Contact Details</td>
            <td>{`M/s. ${userInfo.contactPersonName}` || "N/A"}</td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Business Details</td>
            <td>
              Name:{`${userInfo?.businessName}` || "N/A"} GST:{" "}
              {`${userInfo?.gstNumber}` || "N/A"}
            </td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Site location</td>
            <td>{`${userInfo?.address}` || "N/A"}</td>
          </tr>
          <tr>
            <td>{serial++}</td>
            <td>Construction size</td>
            <td>
              {`Bay:${userInfo?.bay_size || "N/A"}Mtr, Span:${
                userInfo?.span_size || "N/A"
              }Mtr, Area: ${area?.area_in_sqmeter || "N/A"}SqMtr (${
                area?.area_in_sqft || "N/A"
              }SqFt)` || "N/A"}
            </td>
          </tr>

          {userInfo?.quotationType !== "hydroponics only" &&
            userInfo?.quotationType !== "fanpad" &&
            userInfo?.quotationType !== "nvph" && (
              <tr>
                <td>{serial++}</td>
                <td>Hydroponic Percentage</td>
                <td>
                  {`A-frame:${userInfo?.a_FramePercent || 0}%, Flat-bed:${
                    userInfo?.flatBedPercent || 0
                  }%, Grow bag:${
                    userInfo?.growBagPercent || 0
                  }%, Dutch Bucket:${userInfo?.dutchBucketPercent | 0}%` ||
                    "N/A"}
                </td>
              </tr>
            )}
        </tbody>
      </table>
      <div style={{ width: "100%" }}>
        <img src={hthd} width={"100%"} alt="kryzenCertificates" />
      </div>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default UserDetails;
