import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
// dashbourd
import Dashbourd from "../Pages/Dashbourd/dashbourd";
// internal
import EmployeeManagement from "../Pages/Internal/employee_management";
import EmployeeLeaveManagement from "../Pages/Internal/employee_leave_management";
import EmployeeDocuments from "../Pages/Internal/employee_documents";
import EmployeeAttendance from "../Pages/Internal/employee_attendance";
// page not found
import PageNotFound from "../Pages/PageNotFound/page_not_found";
// quotation
import StandardQuotation from "../Pages/Quatation/standard_quotation";
import CustomQuotation from "../Pages/Quatation/custom_quotation";
import ClientDemoPlanning from "../Pages/Quatation/client_demo_planning";
// sales
import AllLeads from "../Pages/Sales/all_leads";
import IncentiveManagement from "../Pages/Sales/incentive_management";
// client
import ClientSiteInspection from "../Pages/Client Management/client_site_inspection";
import ClientProjectHandover from "../Pages/Client Management/client_project_handover";
import ClientProjectTracking from "../Pages/Client Management/client_project_tracking";
import ClientPaymentTracking from "../Pages/Client Management/client_payment_tracking";
import ClientExpenceTracking from "../Pages/Client Management/client_expence_tracking";
// farm
import FarmNurseryManagement from "../Pages/Farm Management/farm_nursery_management";
import FarmWaterManagement from "../Pages/Farm Management/farm_water_management";
import FarmOperationsManagement from "../Pages/Farm Management/farm_operations_management";
// workforce
import ClientEmployeeDatabase from "../Pages/Workforce/client_employee_database";
// buyers
import BuyersManagement from "../Pages/Buyers/buyers_management";
import BuyersPriceWatcher from "../Pages/Buyers/buyers_price_watcher";
// purchase
import PurchaseContractManagement from "../Pages/Purchase/purchase_contract_management";
import PurchaseOrder from "../Pages/Purchase/purchase_order";
import PurchaseProjectSpecification from "../Pages/Purchase/purchase_project_specification";
import PurchaseVendorManagement from "../Pages/Purchase/purchase_vendor_management";
// agronomy
import AgronomyClientManagement from "../Pages/Agronomy/agronomy_client_management";
import AgronomyPlantsWiki from "../Pages/Agronomy/agronomy_plants_wiki";
// resources
import ResourcesAssets from "../Pages/Resources/resources_assets";
import ResourcesInventory from "../Pages/Resources/resources_inventory";
// user
import UserAccountSetting from "../Pages/User/user_account_setting";
import UserLogin from "../Pages/User/user_login";
import ForgetPassword from "../Pages/User/forget_password";
import ResetPassword from "../Pages/User/reset_password";
// employee sub-page
import AddEmployee from "../Pages/Internal/Employee Managemennt/add_employee";
import ViewEmployee from "../Pages/Internal/Employee Managemennt/view_employee";
import EditEmployee from "../Pages/Internal/Employee Managemennt/edit_employee";
// employee leaves sub-page
import AddEmployeeLeves from "../Pages/Internal/Employee Leave Management/add_leaves";
import EditLeaves from "../Pages/Internal/Employee Leave Management/edit_leaves";

// custom quotation sub route
import AddQuotation from "../Pages/Quatation/CustomQuatation/add_quotation";
import QuotationPreview from "../Pages/Quatation/CustomQuatation/view_quotation";
import EditQuotation from "../Pages/Quatation/CustomQuatation/edit_quotation";
import QuotationPrint from "../Pages/Quatation/CustomQuatation/preview_quotation";
// quotation matrix sub-route

// leads sub-route
import AddLeads from "../Pages/Sales/Leads/add_leads";
import EditLeads from "../Pages/Sales/Leads/edit_leads";
import ViewLeads from "../Pages/Sales/Leads/view_leads";
// vendor sub-route
import AddVendor from "../Pages/Purchase/Vendor Management/add_vendor";
import EditVendor from "../Pages/Purchase/Vendor Management/edit_vendor";
import ViewVendor from "../Pages/Purchase/Vendor Management/view_vendor";
// purchase order sub-route
import AddPurchaseOrder from "../Pages/Purchase/Purchase Order/add_purchase_order";
import EditPurchaseOrder from "../Pages/Purchase/Purchase Order/edit_purchase_order";
import ViewPurchaseOrder from "../Pages/Purchase/Purchase Order/view_purchase_order";
import PreviewPurchaseOrder from "../Pages/Purchase/Purchase Order/preview_purchase_order";
// delivery challan sub-route
import DeliveryChallan from "../Pages/Purchase/delivery_challan";
import AddDeliveyChallan from "../Pages/Purchase/Delivery Challan/add_delivey_challan";
import EditDeliveyChallan from "../Pages/Purchase/Delivery Challan/edit_delivery_challan";
import ViewDeliveryChallan from "../Pages/Purchase/Delivery Challan/view_delivery_challan";
import PreviewDeliveryChallan from "../Pages/Purchase/Delivery Challan/preview_delivery_challan";
import ClientProject from "../Pages/Client Management/client_projects";
import AddClientProject from "../Pages/Client Management/Client Projects/add_client_project";
import ViewClientProject from "../Pages/Client Management/Client Projects/view_client_project";
import EditClientProject from "../Pages/Client Management/Client Projects/edit_client_project";
import AddSiteInspection from "../Pages/Client Management/Site Inspection/add_site_inspection";
import EditClientSiteInspection from "../Pages/Client Management/Site Inspection/edit_site_inspection";
import ViewSiteInspection from "../Pages/Client Management/Site Inspection/view_site_inspection";
import SiteInspectionPreview from "../Pages/Client Management/Site Inspection/preview_site_inspection";
import AddProjectTracking from "../Pages/Client Management/Project Tracking/add_project_tracking";
import EditProjectTracking from "../Pages/Client Management/Project Tracking/edit_project_tracking";
import ViewProjectTracking from "../Pages/Client Management/Project Tracking/view_project_tracking";
import AddExpense from "../Pages/Client Management/Expense Tracking/add_expense";
import ViewExpenseTracking from "../Pages/Client Management/Expense Tracking/view_expense_tracking";
import EditExpenseTracking from "../Pages/Client Management/Expense Tracking/edit_expense_tracking";
import Enquiry from "../Pages/Sales/enquiry";
import ViewBuyer from "../Pages/Buyers/Buyers Management/view_buyer";
import InternalFAQ from "../Pages/Internal/internal_faq";
import EditPaymentTracking from "../Pages/Client Management/Payment Tracking/edit_payment_tracking";
import ViewPaymentTracking from "../Pages/Client Management/Payment Tracking/view_payment_tracking";
import AddPaymentTracking from "../Pages/Client Management/Payment Tracking/add_payment_tracking";
import EditProjectHandover from "../Pages/Client Management/Client Handover/edit_handover";
import AddProjectHandover from "../Pages/Client Management/Client Handover/add_handover";
import ViewHandover from "../Pages/Client Management/Client Handover/view_handover";
import ClientFeedback from "../Pages/Client Management/client_feedback";
import AddClientFeedback from "../Pages/Client Management/Client Feedback/add_client_feedback";
import AddIncentive from "../Pages/Sales/Incentive/add_incentive";
import EditIncentive from "../Pages/Sales/Incentive/edit_incentive";
import AddProjectSpecification from "../Pages/Purchase/Project Specification/add_project_specification";
import EditProjectSpecification from "../Pages/Purchase/Project Specification/edit_project_specification";
import ViewProjectSpecification from "../Pages/Purchase/Project Specification/view_project_specification";
import PreviewSpecification from "../Pages/Purchase/Project Specification/preview_specification";
import LeadsTracking from "../Pages/Sales/leads_tracking";
import FarmConstructionAgreement from "../Pages/Farm Management/farm_construction_agreement";
import FarmAgreement from "../Pages/Farm Management/Farm Agreement/farm_agreement";
import Quotation from "../Pages/Farm Management/Farm Agreement/quotation";
import ReimbursementManagement from "../Pages/Internal/reimbursement";
import AddReimbursement from "../Pages/Internal/Reimbursement/add_reimbursement";
import EditReimbursement from "../Pages/Internal/Reimbursement/edit_reimbursement";
import Attendance from "../Pages/User/attendance";
import CallRecords from "../Pages/Sales/call_records";
import ViewAttendance from "../Pages/Internal/Employee Attendance/view_attendance";
import ViewLeaves from "../Pages/Internal/Employee Leave Management/view_leaves";
import AddWorkforce from "../Pages/Workforce/Workforce Management/add_workforce";
import EditWorkforce from "../Pages/Workforce/Workforce Management/edit_workforce";
import ViewWorkforce from "../Pages/Workforce/Workforce Management/view_workforce";
import AddAssets from "../Pages/Resources/Assets Tracking/add_assets";
import EditAssets from "../Pages/Resources/Assets Tracking/edit_assets";
import ViewAssets from "../Pages/Resources/Assets Tracking/view_assets";
import EditInventory from "../Pages/Resources/Inventory Tracking/edit_inventory";
import ViewInventory from "../Pages/Resources/Inventory Tracking/view_inventory";
import AddInventory from "../Pages/Resources/Inventory Tracking/add_inventory";
import LeadsFollowup from "../Pages/Sales/leads-followup";
import PrivateRoutes from "./privateRoutes";
import Home from "../Pages Without Login/home";
import SIFeedback from "../Pages Without Login/si_feedback";
import ViewQuotationMatrix from "../Pages/Quatation/Quotation Matrix/view_quotation_matrix";
import EditQuotationMatrix from "../Pages/Quatation/Quotation Matrix/edit_quotation_matrix";
import LeadsFiltered from "../Pages/Sales/leads_filtered";
import AddAgronomyWiki from "../Pages/Agronomy/Agronomy Wiki/add_agronomywiki";
import ViewAgronomyWiki from "../Pages/Agronomy/Agronomy Wiki/view_agronomywiki";
import EditAgronomyWiki from "../Pages/Agronomy/Agronomy Wiki/edit_agronomywiki";
import AddBuyer from "../Pages/Buyers/Buyers Management/add_buyer";
import AddFarmQuery from "../Pages/Agronomy/Farm Support/add_query";
import ViewQuery from "../Pages/Agronomy/Farm Support/view_query";
import SiteInspectionAll from "../Pages Without Login/SI-Feedback/standard_feedback";
import ViewSiteInsppectionFeedback from "../Pages Without Login/SI-Feedback/view_si_feedback";

import AddClientInvoice from "../Pages/Client Management/Client Invoice/add_client_invoice";
import EditClientInvoice from "../Pages/Client Management/Client Invoice/edit_client_invoice";
import ViewClientInvoice from "../Pages/Client Management/Client Invoice/view_client_invoice";
import PreviewClientInvoice from "../Pages/Client Management/Client Invoice/preview_client_invoice";
import ClientInvoice from "../Pages/Client Management/client_invoice";
import ColdLeadsTracking from "../Pages/Sales/cold_leads";
import AttendanceLogout from "../Pages/User/attendance_logout";
import AddFarmTraining from "../Pages/Sales/Farm Training/add_farm_training";
import EditFarmTraining from "../Pages/Sales/Farm Training/edit_farm_training";
import ViewFarmTraining from "../Pages/Sales/Farm Training/view_farm_training";
import FarmTraining from "../Pages/Sales/farm_training";
import KryzenDashBoard from "../Pages/Dashbourd/kryzen_dashboard";
import NutrientCalculations from "../Pages/Farm Management/nutrient_calculations";
import CreateWaterCycle from "../Pages/Farm Management/Water Management/create_water_cycle";
import EditWaterCycle from "../Pages/Farm Management/Water Management/edit_water_cycle";
import ViewWaterManagement from "../Pages/Farm Management/Water Management/view_water_cycle";
import AddBatch from "../Pages/Farm Management/Nursery Management/add_batch";
import ViewBatch from "../Pages/Farm Management/Nursery Management/view_batch";
import EditBatch from "../Pages/Farm Management/Nursery Management/edit_batch";
import EditBuyer from "../Pages/Buyers/Buyers Management/edit_buyer";
import CreateCampaign from "../Pages/Internal/Marketing List/create_campaign";
import MarketingSystem from "../Pages/Internal/marketing_system";
import CreateTask from "../Pages/Internal/Task Management/create_task";
import EditTask from "../Pages/Internal/Task Management/edit_task";
import ViewTasks from "../Pages/Internal/Task Management/view_task";
import TaskManagement from "../Pages/Internal/task_management";
import AddPriceWatcher from "../Pages/Buyers/Price Watcher/add_price_watcher";
import ViewPriceWatcher from "../Pages/Buyers/Price Watcher/view_price_watcher";
import EditPriceWatcher from "../Pages/Buyers/Price Watcher/edit_price_watcher";
import TermsAndConditions from "../Pages Without Login/terms_&_conditions";
import ContactUs from "../Pages Without Login/contact_us";
import PrivacyPolicy from "../Pages Without Login/privacy_policy";
import ResearchRnn from "../Pages/Buyers/Price watcher components/research_rnn";
import MobileApp from "../Pages/Internal/mobile_app";
import ViewMobileApp from "../Pages/Internal/Mobile App/view_mobile_data";
import WatsappLog from "../Pages/Internal/watsapp_log";
import ViewWatsappLog from "../Pages/Internal/Watsapp Logs/view_watsapp_log";
import CreateSiteInspection from "../Pages/Client Management/SI Client Role/create_site_inspection";
import ClientSiteInspectionRole from "../Pages/Client Management/SI Client Role/site_insp";
import ViewSI from "../Pages/Client Management/SI Client Role/view_si";
import PreviewSI from "../Pages/Client Management/SI Client Role/preview_si";
import EditSI from "../Pages/Client Management/SI Client Role/edit_si";
import SalesDashboard from "../Pages/Sales/Sales Analysis/sales_analysis";
import Longtermcontract from "../Pages/Buyers/Longtermcontract";

const routes = [
  {
    path: "/i-dashboard",
    roles: [
      process.env.REACT_APP_ROLE_INTERNAL,
      process.env.REACT_APP_ROLE_ADMIN_S,
    ],
    ele: <KryzenDashBoard />,
  },
  {
    path: "/employee-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EmployeeManagement />,
  },
  {
    path: "/employee-leave-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <EmployeeLeaveManagement />,
  },
  {
    path: "/employee-documents",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <EmployeeDocuments />,
  },
  {
    path: "/employee-attendance",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <EmployeeAttendance />,
  },
  {
    path: "/employee-attendance/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <ViewAttendance />,
  },
  {
    path: "/internal-FAQ",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <InternalFAQ />,
  },
  {
    path: "/standard-quotation",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <StandardQuotation />,
  },
  {
    path: "/standard-quotation/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewQuotationMatrix />,
  },
  {
    path: "/custom-quotation",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <CustomQuotation />,
  },
  {
    path: "/client-demo-planning",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ClientDemoPlanning />,
  },
  {
    path: "/leads",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <AllLeads />,
  },
  {
    path: "/call-records",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <CallRecords />,
  },
  {
    path: "/enquiry",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <Enquiry />,
  },
  {
    path: "/revenue-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <IncentiveManagement />,
  },
  {
    path: "/reimbursement-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ReimbursementManagement />,
  },
  {
    path: "/reimbursement-management/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <AddReimbursement />,
  },
  {
    path: "/reimbursement-management/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <EditReimbursement />,
  },
  {
    path: "/revenue-management/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <AddIncentive />,
  },
  {
    path: "/revenue-management/edit/:id",
    roles: [process.env.REACT_APP_ROLE_ADMIN_S],
    ele: <EditIncentive />,
  },
  {
    path: "/client-site-inspection",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,

      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SITE_INSPECTION,
    ],
    ele: <ClientSiteInspection />,
  },
  {
    path: "/client-project-handover",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,

      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ClientProjectHandover />,
  },
  {
    path: "/client-project-tracking",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,

      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ClientProjectTracking />,
  },
  {
    path: "/client-projects",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ClientProject />,
  },
  {
    path: "/client-payment-tracking",
    roles: [
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_CLIENT,
    ],
    ele: <ClientPaymentTracking />,
  },
  {
    path: "/client-expense-tracking",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_PURCHASE,
    ],
    ele: <ClientExpenceTracking />,
  },

  {
    path: "/farm-nursery-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <FarmNurseryManagement />,
  },
  {
    path: "/farm-nursery-management/new-batch",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <AddBatch />,
  },
  {
    path: "/farm-nursery-management/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ViewBatch />,
  },
  {
    path: "/farm-nursery-management/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <EditBatch />,
  },
  {
    path: "/farm-water-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <FarmWaterManagement />,
  },
  {
    path: "/farm-water-management/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <CreateWaterCycle />,
  },
  {
    path: "/farm-water-management/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <EditWaterCycle />,
  },
  {
    path: "/farm-water-management/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ViewWaterManagement />,
  },
  {
    path: "/nutrient-calculations",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <NutrientCalculations />,
  },
  {
    path: "/farm-construction",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,

      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <FarmConstructionAgreement />,
  },

  {
    path: "/farm-construction/quotation",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <Quotation />,
  },
  {
    path: "/farm-construction/agreement",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,

      process.env.REACT_APP_ROLE_AGRONOMIST,

      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <FarmAgreement />,
  },
  {
    path: "/farm-operations-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <FarmOperationsManagement />,
  },

  // {
  //   path: "/buyers-produce-demand",
  //   roles: [
  //     process.env.REACT_APP_ROLE_ADMIN_S,

  //     process.env.REACT_APP_ROLE_MARKETING,

  //     process.env.REACT_APP_ROLE_CLIENT,
  //
  //   ],
  //   ele: <BuyersProduceDemand />,
  // },
  {
    path: "/buyers-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <BuyersManagement />,
  },

  {
    path: "/long-term-contract",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <Longtermcontract />,
  },

  {
    path: "/buyers-management/new",
    roles: [
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <AddBuyer />,
  },
  {
    path: "/buyers-management/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <EditBuyer />,
  },

  {
    path: "/buyers-management/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <ViewBuyer />,
  },
  {
    path: "/buyers-price-watcher",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,

      process.env.REACT_APP_ROLE_MARKETING,

      process.env.REACT_APP_ROLE_CLIENT,
      //
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <BuyersPriceWatcher />,
  },
  {
    path: "/buyers-price-watcher/new",
    roles: [process.env.REACT_APP_ROLE_ADMIN_S],
    ele: <AddPriceWatcher />,
  },
  {
    path: "/buyers-price-watcher/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,

      process.env.REACT_APP_ROLE_MARKETING,

      process.env.REACT_APP_ROLE_CLIENT,

      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <ViewPriceWatcher />,
  },
  {
    path: "/buyers-price-watcher/edit/:id",
    roles: [process.env.REACT_APP_ROLE_ADMIN_S],
    ele: <EditPriceWatcher />,
  },
  {
    path: "/client-employee-database",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ClientEmployeeDatabase />,
  },
  {
    path: "/client-employee-database/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <AddWorkforce />,
  },
  {
    path: "/client-employee-database/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <EditWorkforce />,
  },
  {
    path: "/client-employee-database/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ViewWorkforce />,
  },

  {
    path: "/purchase-order",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <PurchaseOrder />,
  },
  {
    path: "/delivery-challan",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <DeliveryChallan />,
  },
  {
    path: "/purchase-vendor-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <PurchaseVendorManagement />,
  },
  {
    path: "/purchase-contract-management",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <PurchaseContractManagement />,
  },
  {
    path: "/project-specification",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <PurchaseProjectSpecification />,
  },
  {
    path: "/project-specification/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddProjectSpecification />,
  },
  {
    path: "/project-specification/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditProjectSpecification />,
  },
  {
    path: "/project-specification/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewProjectSpecification />,
  },
  {
    path: "/project-specification/preview/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <PreviewSpecification />,
  },
  {
    path: "/agronomy-plants-wiki",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <AgronomyPlantsWiki />,
  },
  {
    path: "/agronomy-plants-wiki/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <AddAgronomyWiki />,
  },
  {
    path: "/agronomy-plants-wiki/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ViewAgronomyWiki />,
  },
  {
    path: "/agronomy-plants-wiki/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <EditAgronomyWiki />,
  },
  {
    path: "/agronomy-farm-support",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <AgronomyClientManagement />,
  },
  {
    path: "/agronomy-farm-support/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <AddFarmQuery />,
  },
  {
    path: "/agronomy-farm-support/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ViewQuery />,
  },
  {
    path: "/resources-assets",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ResourcesAssets />,
  },
  {
    path: "/resources-assets/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <AddAssets />,
  },
  {
    path: "/resources-assets/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <EditAssets />,
  },
  {
    path: "/resources-assets/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ViewAssets />,
  },

  {
    path: "/resources-inventory",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <ResourcesInventory />,
  },
  {
    path: "/resources-inventory/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <EditInventory />,
  },
  {
    path: "/resources-inventory/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <AddInventory />,
  },
  {
    path: "/resources-inventory/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT,
    ],
    ele: <ViewInventory />,
  },

  {
    path: "/employee-management/new-employee",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddEmployee />,
  },
  {
    path: "/employee-management/view-employee/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewEmployee />,
  },
  {
    path: "/employee-management/edit-employee/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditEmployee />,
  },

  {
    path: "/employee-leave-management/new-leave",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <AddEmployeeLeves />,
  },
  {
    path: "/employee-leave-management/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <EditLeaves />,
  },
  {
    path: "/employee-leave-management/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ViewLeaves />,
  },

  {
    path: "/attendance",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <Attendance />,
  },
  {
    path: "/logout-attendance",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <AttendanceLogout />,
  },

  {
    path: "/custom-quotation/new-quotation",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <AddQuotation />,
  },
  {
    path: "/custom-quotation/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <QuotationPreview />,
  },
  {
    path: "/custom-quotation/preview/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <QuotationPrint />,
  },
  {
    path: "/custom-quotation/edit-quotation/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <EditQuotation />,
  },
  {
    path: "/standard-quotation/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditQuotationMatrix />,
  },
  {
    path: "/leads/new-lead",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <AddLeads />,
  },
  {
    path: "/leads/view-lead/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ViewLeads />,
  },
  {
    path: "/leads/edit-lead/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <EditLeads />,
  },
  {
    path: "/purchase-vendor-management/new-vendor",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddVendor />,
  },
  {
    path: "/purchase-vendor-management/edit-vendor/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditVendor />,
  },
  {
    path: "/purchase-vendor-management/view-vendor/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewVendor />,
  },

  {
    path: "/purchase-order/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddPurchaseOrder />,
  },
  {
    path: "/purchase-order/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditPurchaseOrder />,
  },
  {
    path: "/purchase-order/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewPurchaseOrder />,
  },
  {
    path: "/purchase-order/preview/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <PreviewPurchaseOrder />,
  },

  {
    path: "delivery-challan/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddDeliveyChallan />,
  },
  {
    path: "delivery-challan/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewDeliveryChallan />,
  },
  {
    path: "delivery-challan/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditDeliveyChallan />,
  },
  {
    path: "delivery-challan/preview/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <PreviewDeliveryChallan />,
  },

  {
    path: "/client-projects/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddClientProject />,
  },
  {
    path: "/client-projects/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewClientProject />,
  },
  {
    path: "/client-projects/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditClientProject />,
  },

  {
    path: "/client-site-inspection/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SITE_INSPECTION,
      process.env.REACT_APP_ROLE_CLIENT,
    ],

    ele: <AddSiteInspection />,
  },
  {
    path: "/client-site-inspection/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SITE_INSPECTION,
    ],
    ele: <EditClientSiteInspection />,
  },
  {
    path: "/client-site-inspection/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,

      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SITE_INSPECTION,
    ],
    ele: <ViewSiteInspection />,
  },
  {
    path: "/client-site-inspection/preview/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,

      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SITE_INSPECTION,
    ],
    ele: <SiteInspectionPreview />,
  },

  {
    path: "/client-project-tracking/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddProjectTracking />,
  },
  {
    path: "/client-project-tracking/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditProjectTracking />,
  },
  {
    path: "/client-project-tracking/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,

      process.env.REACT_APP_ROLE_OPS,

      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewProjectTracking />,
  },

  {
    path: "/client-expense-tracking/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
    ],
    ele: <AddExpense />,
  },
  {
    path: "/client-expense-tracking/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
    ],
    ele: <ViewExpenseTracking />,
  },
  {
    path: "/client-expense-tracking/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
    ],
    ele: <EditExpenseTracking />,
  },
  {
    path: "/client-payment-tracking/new",
    roles: [
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_CLIENT,
    ],
    ele: <AddPaymentTracking />,
  },
  {
    path: "/client-payment-tracking/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_CLIENT,
    ],
    ele: <ViewPaymentTracking />,
  },
  {
    path: "/client-payment-tracking/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_CLIENT,
    ],
    ele: <EditPaymentTracking />,
  },
  {
    path: "/client-project-handover/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditProjectHandover />,
  },
  {
    path: "/client-project-handover/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddProjectHandover />,
  },
  {
    path: "/client-project-handover/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_CLIENT,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewHandover />,
  },
  {
    path: "/client-feedback",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ClientFeedback />,
  },
  {
    path: "/leads-tracking",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,

      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <LeadsTracking />,
  },
  {
    path: "/leads-filtered",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,

      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <LeadsFiltered />,
  },
  {
    path: "/leads-filtered/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,

      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ViewLeads />,
  },
  {
    path: "/leads-filtered/Edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,

      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <EditLeads />,
  },
  {
    path: "/leads-followup",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,

      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <LeadsFollowup />,
  },
  {
    path: "/leads-followup/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,

      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ViewLeads />,
  },
  {
    path: "/leads-followup/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,

      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <EditLeads />,
  },
  {
    path: "/client-feedback/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <AddClientFeedback />,
  },
  {
    path: "/si-feedback-recieved",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_OPS,
    ],
    ele: <SiteInspectionAll />,
  },
  {
    path: "/si-feedback-recieved/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_OPS,
    ],
    ele: <ViewSiteInsppectionFeedback />,
  },
  {
    path: "/client-invoice",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ClientInvoice />,
  },
  {
    path: "/client-invoice/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <AddClientInvoice />,
  },
  {
    path: "/client-invoice/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <ViewClientInvoice />,
  },
  {
    path: "/client-invoice/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <EditClientInvoice />,
  },
  {
    path: "/client-invoice/preview/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
    ],
    ele: <PreviewClientInvoice />,
  },
  {
    path: "/cold-leads",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ColdLeadsTracking />,
  },
  {
    path: "/farm-training/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <AddFarmTraining />,
  },
  {
    path: "/farm-training/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <EditFarmTraining />,
  },
  {
    path: "/farm-training/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ViewFarmTraining />,
  },
  {
    path: "/farm-training",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_SALESAGENCY,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <FarmTraining />,
  },
  {
    path: "/campaign",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <MarketingSystem />,
  },
  {
    path: "/campaign/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <CreateCampaign />,
  },
  {
    path: "/campaign/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
    ],
    ele: <CreateCampaign />,
  },
  {
    path: "/task-management/new",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <CreateTask />,
  },
  {
    path: "/task-management/edit/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
    ],
    ele: <EditTask />,
  },
  {
    path: "/task-management/view/:id",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <ViewTasks />,
  },
  {
    path: "/task-management/",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_PURCHASE,
      process.env.REACT_APP_ROLE_SALLE,
      process.env.REACT_APP_ROLE_ACCOUNTS,
      process.env.REACT_APP_ROLE_AGRONOMIST,
      process.env.REACT_APP_ROLE_OPS,
      process.env.REACT_APP_ROLE_INSPECTION,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_IT,
      process.env.REACT_APP_ROLE_SSM,
      process.env.REACT_APP_ROLE_SALES_MANAGER,
      process.env.REACT_APP_ROLE_CLIENT_FEEDBACK,
    ],
    ele: <TaskManagement />,
  },
  {
    path: "/buyers-price-watcher/research",
    roles: [
      process.env.REACT_APP_ROLE_ADMIN_S,
      process.env.REACT_APP_ROLE_MARKETING,
      process.env.REACT_APP_ROLE_ADMIN,
      process.env.REACT_APP_ROLE_CLIENT,
    ],
    ele: <ResearchRnn />,
  },
  {
    path: "/android-app",
    roles: [process.env.REACT_APP_ROLE_ADMIN_S],
    ele: <MobileApp />,
  },
  {
    path: "/android-app/view/:id",
    roles: [process.env.REACT_APP_ROLE_ADMIN_S],
    ele: <ViewMobileApp />,
  },
  {
    path: "/watsapp-logs",
    roles: [process.env.REACT_APP_ROLE_ADMIN_S],
    ele: <WatsappLog />,
  },
  {
    path: "/watsapp-logs/view/:id",
    roles: [process.env.REACT_APP_ROLE_ADMIN_S],
    ele: <ViewWatsappLog />,
  },
  {
    path: "/site-inspection/new",
    roles: [process.env.REACT_APP_ROLE_SITE_INSPECTION],
    ele: <CreateSiteInspection />,
  },
  {
    path: "/site-inspection",
    roles: [process.env.REACT_APP_ROLE_SITE_INSPECTION],
    ele: <ClientSiteInspectionRole />,
  },
  {
    path: "/site-inspection/view/:id",
    roles: [process.env.REACT_APP_ROLE_SITE_INSPECTION],
    ele: <ViewSI />,
  },
  {
    path: "/site-inspection/preview/:id",
    roles: [process.env.REACT_APP_ROLE_SITE_INSPECTION],
    ele: <PreviewSI />,
  },
  {
    path: "/site-inspection/edit/:id",
    roles: [process.env.REACT_APP_ROLE_SITE_INSPECTION],
    ele: <EditSI />,
  },
  {
    path: "/sales-analytics",
    roles: [process.env.REACT_APP_ROLE_ADMIN_S],
    ele: <SalesDashboard />,
  },
];

export default function AllRoutes() {
  let role = useSelector((store) => store?.login?.user?.Role);
  let user = useSelector((store) => store?.login?.user);
  // console.log(user);
  const loginState = useSelector((state) => state.login);
  const filteredRoutes = routes.filter((route) => route.roles.includes(role));
  return (
    <Routes>
      {filteredRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<PrivateRoutes>{route.ele}</PrivateRoutes>}
        />
      ))}
      <Route
        path="/account-setting"
        element={
          <PrivateRoutes>
            <UserAccountSetting />
          </PrivateRoutes>
        }
      />
      <Route path="/account-login" element={<UserLogin />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route
        path="/reset-password"
        element={
          <PrivateRoutes>
            <ResetPassword />
          </PrivateRoutes>
        }
      />

      <Route path="/si-feedback" element={<SIFeedback />} />
      <Route path="/terms&conditions" element={<TermsAndConditions />} />
      <Route path="/contact_us" element={<ContactUs />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/" element={<Home />} />
      {loginState?.isLoggedIn ? (
        <Route path="*" element={<Dashbourd />} />
      ) : (
        <Route path="*" element={<Home />} />
      )}
    </Routes>
  );
}
