import React from "react";

const AgronomySupport = () => {
  return (
    <div>
      <h3 className="table-heading">Agronomy Support:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td>Free agronomy support</td>
            <td>
              KBPL will provide free agronomy support on video and voice calls,
              on instant messaging apps, on emails.
            </td>
          </tr>
          <tr>
            <td>02.</td>
            <td>Paid agronomy support (additional service)</td>
            <td>
              KBPL will provide the agronomy support with in-person visits, on
              video and voice calls, on instant messaging apps, on emails. You
              will be bearing additional expenses related to food, stay, and
              travelling of agronomists.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AgronomySupport;
