import * as yup from "yup";
export const EmployeeSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  emailId: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
  dateOfBirth: yup
    .date()
    .min(new Date(1950, 0, 1), "Date of birth must be after January 1, 1950")
    .max(new Date(2010, 0, 1), "Date of birth must be before January 1, 2010")
    .required("Required"),
  photograph: yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File size is too large upload less than 2 mb",

  //   (value) => value && value.size >= 2 * 1024 * 1024
  // ),
  permanantAddress: yup
    .string()
    .min(10, "must be at least 10 characters")
    .required("Required"),
  residentialAddress: yup
    .string()
    .min(10, "must be at least 10 characters")
    .required("Required"),
  aadharcardNumber: yup
    .string()
    .matches(/^\d{12}$/, "Aadhar no. must be exactly 12 digits and postive")
    .required("Required"),
  aadharcardPhotoFront: yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File size is too large upload less than 2 mb",
  //   (value) => value && value.size >= 2 * 1024 * 1024
  // ),
  aadharcardPhotoBack: yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File size is too large upload less than 2 mb",
  //   (value) => value && value.size >= 2 * 1024 * 1024
  // ),
  pancardNumber: yup
    .string()
    .matches(
      /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
      "PAN number must be in the format ABCDE1234F"
    )
    .required("Required"),
  pancardPhoto: yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File size is too large upload less than 2 mb",
  //   (value) => value && value.size >= 2 * 1024 * 1024
  // ),
  contactNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits and postive")
    .required("Required"),
  emergencyContactNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits and postive")
    .required("Required"),
  emergencyContactName: yup.string().required("Required"),
  emergencyContactRelation: yup
    .string()
    .oneOf(
      ["father", "mother", "spouse", "sister", "brother", "friend", null],
      "Relation must be one of the given options"
    )
    .nullable()
    .required("Required"),
  previousOrganizationName: yup.string(),
  previousOrganizationLocation: yup.string(),
  totalYearsOfExperience: yup
    .number()
    .required("Required")
    .oneOf(
      [
        0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25,
      ],
      "Invalid Experience Years"
    ),
  qualification: yup
    .string()
    .oneOf(
      [
        "high_school",
        "associate_degree",
        "bachelor_degree",
        "master_degree",
        "phd",
        "vocational_certificate",
        "diploma",
        "professional_certification",
        "postgraduate_certificate",
        "technical_degree",
        "trade_school",
        "online_course",
        "apprenticeship",
        "military_training",
        "bootcamp",
        null,
      ],
      "Invalid qualification type"
    )
    .required("Required"),
  contractOfEmployment: yup
    .number()
    .required("Required")
    .oneOf([0, 1, 2, 3, 4], "Invalid Experience Years"),
  roleInOrganisation: yup
    .string()
    .required("Required")
    .oneOf(
      [
        "superAdmin",
        "purchaseStaff",
        "saleStaff",
        "agronomistStaff",
        "opsStaff",
        "inspectionStaff",
        "marketingStaff",
        "adminStaff",
        "labourStaff",
        "accountStaff",
        "ITStaff",
        "SSMStaff",
        "salesManager",
        "clientFeedback",
        null,
      ],
      "Invalid role value"
    ),
  workingPlace: yup
    .string()
    .min(10, "must be at least 10 characters")
    .required("Required")
    .default(
      "319, City Avenue Pune Bangalore, Highway, Wakad, Pimpri-Chinchwad, Pune, Maharashtra 411057"
    ),
  packageJoinedAt: yup
    .number()
    .min(0, "must be non-negative")
    .max(35, "must be less than or equal to 35")
    .required("Required"),

  offerLetterAcceptance: yup.mixed(),
  // .test(

  //   "fileSize",
  //   "File size is too large upload less than 2 mb",

  //   (value) => value && value.size >= 2 * 1024 * 1024
  // ),
  bankPassbook: yup.mixed(),
  // .test(

  //   "fileSize",
  //   "File size is too large upload less than 2 mb",

  //   (value) => value && value.size >= 2 * 1024 * 1024
  // ),
  residentialProof: yup.mixed(),
  // .test(

  //   "fileSize",
  //   "File size is too large upload less than 2 mb",

  //   (value) => value && value.size >= 2 * 1024 * 1024
  // ),
  joiningLetterScan: yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File size is too large upload less than 2 mb",

  //   (value) => value && value.size >= 2 * 1024 * 1024
  // ),

  reportingTo: yup.string().required("Required"),
  mobileGivenByOrganisation: yup
    .string()
    .oneOf(["yes", "no", null], "must be one of the given options")
    .nullable()
    .required("Required"),
  mobileId: yup.string(),
  laptopGivenByOrganisation: yup
    .string()
    .oneOf(["yes", "no", null], "must be one of the given options")
    .nullable()
    .required("Required"),
  laptopId: yup.string(),
  simCardGivenByOrganisation: yup
    .string()
    .oneOf(["yes", "no", null], "must be one of the given options")
    .nullable()
    .required("Required"),
  simCardNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile no. must be exactly 10 digits and postive"),
  hireViaAgencyOrDirect: yup
    .string()
    .oneOf(["agency", "direct", null], "must be one of the given options")
    .nullable()
    .required("Required"),
  agencyExpences_exlGST: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .required("Required")
    .default(0),
  salaryPerMonth: yup.string(),
  pfptDeduction: yup.string(),
  modeOfSalary: yup.string(),
});
