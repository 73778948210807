import React from "react";
import { useNavigate } from "react-router-dom";
import { roundOffCost } from "../../../Components/Functions/amt.fun";
import { getId } from "../../../Components/Functions/word.fun";
import { BsArrowRightShort } from "react-icons/bs";
const tableHead = [
  "Sr.",
  "Quotation Date",
  "Quotation Type",
  // "Area (SqMtr)",
  "Contact Name",
  "Amount ( - GST)",
  "Generated By",
  "",
];

const getQuotationFor = (data) => {
  let bag = "";

  if (data?.quotationType == "fanpad") {
    bag += "Fanpad";
  } else if (data?.quotationType == "nvph") {
    bag += "Nvph";
  } else if (data?.quotationType == "nethouse") {
    bag += "Nethouse";
  } else if (data?.quotationType == "fanpad with hydroponics") {
    bag += "Fanpad";
    let next = "";
    if (data?.a_FramePercent > 0) {
      next += `+ ${data?.a_FramePercent}% A-Frame`;
    }
    if (data?.flatBedPercent > 0) {
      next += `+ ${data?.flatBedPercent}% flatbed`;
    }
    if (data?.growBagPercent > 0) {
      next += `+ ${data?.growBagPercent}% Growbag`;
    }
    if (data?.dutchBucketPercent > 0) {
      next += `+ ${data?.dutchBucketPercent}% Dutchbucket`;
    }
    bag += ` ${next}`;
  } else if (data?.quotationType == "nethouse with hydroponics") {
    bag += "Nethouse";
    let next = "";
    if (data?.a_FramePercent > 0) {
      next += ` +${data?.a_FramePercent}% A-Frame`;
    }
    if (data?.flatBedPercent > 0) {
      next += ` +${data?.flatBedPercent}% flatbed`;
    }
    if (data?.growBagPercent > 0) {
      next += ` +${data?.growBagPercent}% Growbag`;
    }
    if (data?.dutchBucketPercent > 0) {
      next += ` +${data?.dutchBucketPercent}% Dutchbucket`;
    }
    bag += ` ${next}`;
  } else if (data?.quotationType == "nvph with hydroponics") {
    bag += "Nvph";
    let next = "";
    if (data?.a_FramePercent > 0) {
      next += ` +${data?.a_FramePercent}% A-Frame`;
    }
    if (data?.flatBedPercent > 0) {
      next += ` +${data?.flatBedPercent}% flatbed`;
    }
    if (data?.growBagPercent > 0) {
      next += ` +${data?.growBagPercent}% Growbag`;
    }
    if (data?.dutchBucketPercent > 0) {
      next += ` +${data?.dutchBucketPercent}% Dutchbucket`;
    }
    bag += ` ${next}`;
  } else if (data?.quotationType == "hydroponics only") {
    bag += "Without-Cea";
    let next = "";
    if (data?.a_FramePercent > 0) {
      next += ` +${data?.a_FramePercent}% A-Frame`;
    }
    if (data?.flatBedPercent > 0) {
      next += ` +${data?.flatBedPercent}% Flatbed`;
    }
    if (data?.growBagPercent > 0) {
      next += ` +${data?.growBagPercent}% Growbag`;
    }
    if (data?.dutchBucketPercent > 0) {
      next += ` +${data?.dutchBucketPercent}% Dutchbucket`;
    }
    bag += ` ${next}`;
  }

  return bag;
};
const getTotalAmt = (quotation) => {
  let total = 0;
  if (quotation?.input_info?.overrideUnitCost > 0) {
    total += quotation?.input_info?.overrideUnitCost;
  } else {
    total = quotation?.quotationMatrixCost?.total_cost;
  }
  total = roundOffCost(total);
  return total;
};

const AllQuotations = ({ quotations = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/custom-quotation/${getId(id)}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter" style={{ gap: "5px" }}>
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return (
                <th
                  style={{
                    width: item == "Generated By" ? "10%" : "",
                  }}
                  key={index}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {quotations?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.quotationNumber}>
                <td>{Index}</td>
                {/* <td>{getId(item?.quotationNumber) || "N/A"}</td> */}
                <td>{item?.input_info?.quotationDate || "N/A"}</td>

                <td>
                  {item?.area_in?.area_in_sqmeter || "N/A"} SqM
                  <br />
                  <span className="text-secondary">
                    {getQuotationFor(item?.input_info) || "N/A"}
                  </span>
                </td>
                <td>
                  {item?.input_info?.contactPersonName || "N/A"} <br />{" "}
                  <span className="text-secondary badge bg-cyan-lt">
                    {item?.input_info?.phoneNumber || "N/A"}
                  </span>
                </td>
                <td>{getTotalAmt(item) || "N/A"}</td>
                <td>{item?.input_info?.genrated_by || "N/A"}</td>

                <td>
                  <button
                    className="btn btn-primary px-3 btn-sm"
                    onClick={() => goToView(item?.quotationNumber)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllQuotations;
