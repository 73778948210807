import React from "react";

const MaterialBreakdownCEA = () => {
  return (
    <div>
      <h3 className="table-heading">
        Material Breakdown (For Exports Custom BoM):
      </h3>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>Sr.</th>
                    <th style={{ width: "65%" }}>Particulars</th>
                    <th style={{ width: "15%" }}>HSN</th>
                    <th style={{ width: "15%" }}>GST</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01.</td>
                    <td>GI 60OD X 2.0 X 1.0</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>02.</td>
                    <td>GI 76OD X 2.0 X 6.5</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>03.</td>
                    <td>GI 76OD X 2.0 X 6.5</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>04.</td>
                    <td>GI 76OD X 2.0 X 4.5</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>05.</td>
                    <td>GI 60OD X 2.0 X 8</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>06.</td>
                    <td>GI 60OD X 2.0 X 8</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>07.</td>
                    <td>GI 48OD X 2.0 X 4</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>08.</td>
                    <td>GI 60OD X 2.0 X 5.5</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>09.</td>
                    <td>GI 60OD X 2.0 X 2</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>10.</td>
                    <td>GI 42OD X 2.0 X 1</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>11.</td>
                    <td>GI 42OD X 2.0 X 4</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>12.</td>
                    <td>GI 42OD X 2.0 X 6</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>13.</td>
                    <td>GI 42OD X 2.0 X 4.5</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>14.</td>
                    <td>GI 33OD X 2.0 X 5</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>15.</td>
                    <td>GI 33OD X 2.0 X 4</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>16.</td>
                    <td>GI 33OD X 2.0 X 2</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>17.</td>
                    <td>GI 33OD X 2.0 X 1.5</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>18.</td>
                    <td>GI 33OD X 2.0 X 2</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>19.</td>
                    <td>GI 33OD X 2.0 X 2.5</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>20.</td>
                    <td>GI 33OD X 2.0 X 1</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>21.</td>
                    <td>GI 27OD X 2.0 X 6</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>22.</td>
                    <td>GI 27OD X 2.0 X 3</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>23.</td>
                    <td>GI 42OD X 2.0 X 5.2</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>24.</td>
                    <td>GI pipe 42OD X 2.0 X 4</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>25.</td>
                    <td>GI pipe 42OD X 2.0 X 6</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>26.</td>
                    <td>GI gutter</td>
                    <td>72104900</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>27.</td>
                    <td>Fixture pipe</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>28.</td>
                    <td>Angle bracket</td>
                    <td>72162100</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>29.</td>
                    <td>76OD full clamp</td>
                    <td>73084000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>30.</td>
                    <td>76OD half clamp</td>
                    <td>73084000</td>
                    <td>18%</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ width: "50%" }}>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>Sr.</th>
                    <th style={{ width: "65%" }}>Particulars</th>
                    <th style={{ width: "15%" }}>HSN</th>
                    <th style={{ width: "15%" }}>GST</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>31.</td>
                    <td>60OD full clamp</td>
                    <td>73084000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>32.</td>
                    <td>60OD half clamp</td>
                    <td>73084000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>33.</td>
                    <td>42OD full clamp</td>
                    <td>73084000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>34.</td>
                    <td>42OD half clamp</td>
                    <td>73084000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>35.</td>
                    <td>Bolts + Nuts</td>
                    <td>73181900</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>36.</td>
                    <td>Self screw</td>
                    <td>73181500</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>37.</td>
                    <td>GI profile</td>
                    <td>00007212</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>38.</td>
                    <td>Spring</td>
                    <td>00007212</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>39.</td>
                    <td>Shade net pully</td>
                    <td>00007212</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>40.</td>
                    <td>Shade net Joiner</td>
                    <td>00730630</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>41.</td>
                    <td>TUF nylon ropes</td>
                    <td>00730630</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>42.</td>
                    <td>Ring hooks</td>
                    <td>83023090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>43.</td>
                    <td>Curtain clamp 3/4</td>
                    <td>73084000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>44.</td>
                    <td>Universal joint</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>45.</td>
                    <td>Curtain pipe inset</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>46.</td>
                    <td>Curtain & flap inset</td>
                    <td>73063090</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>47.</td>
                    <td>FRP door</td>
                    <td>70191100</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>48.</td>
                    <td>Door section 12FT</td>
                    <td>76042910</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>49.</td>
                    <td>Door handle & hinge</td>
                    <td>87089900</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>50.</td>
                    <td>GI Wire 14G</td>
                    <td>00007214</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>51.</td>
                    <td>Binding wire</td>
                    <td>72179099</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>52.</td>
                    <td>G-Fab apron</td>
                    <td>39269099</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>53.</td>
                    <td>Polyfilm white</td>
                    <td>39209999</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>54.</td>
                    <td>Insect net</td>
                    <td>54077300</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>55.</td>
                    <td>Aluminium net</td>
                    <td>60059000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>56.</td>
                    <td>GI funnel</td>
                    <td>72104900</td>
                    <td>18%</td>
                  </tr>

                  <tr>
                    <td>57.</td>
                    <td>Fogger assembly</td>
                    <td>84249000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>58.</td>
                    <td>Fogger motor</td>
                    <td>84249000</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>59.</td>
                    <td>Air circulation fans(Applicable to Fan-Pad)</td>
                    <td>00841459</td>
                    <td>18%</td>
                  </tr>
                  <tr>
                    <td>60.</td>
                    <td>Connecting wire</td>
                    <td>72179099</td>
                    <td>18%</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default MaterialBreakdownCEA;
