import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";

import { BsCalendar2XFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";

const getLeavesTaken = (obj) => {
  if (obj.leaveType === "half day") {
    return 0.5;
  }
  if (obj.leaveType === "full day") {
    return 1;
  } else {
    const start = new Date(obj.leaveStartDate);
    const end = new Date(obj.leaveEndDate);
    return (end - start) / (1000 * 60 * 60 * 24) + 1;
  }
};

const ViewLeaves = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [leaveCount, setLeaveCount] = useState({ paid: 0, unpaid: 0 });

  let { id } = useParams();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/leave/getleavesById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const fetchedData = res.data;
          setData(fetchedData);
          const paidLeaves = fetchedData.leavesDetails
            .filter((leave) => leave.leaveReasonType === "sick")
            .reduce((total, leave) => total + getLeavesTaken(leave), 0);
          const unpaidLeaves = fetchedData.leavesDetails
            .filter((leave) => leave.leaveReasonType === "casual")
            .reduce((total, leave) => total + getLeavesTaken(leave), 0);

          setLeaveCount({ paid: paidLeaves, unpaid: unpaidLeaves });
        } else {
          Toast("error", "Error while getting leave details!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="container-xxl">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <h2 className="page-title mb-1">
                    <FcCalendar className="fs-2  me-1" /> View Leave
                  </h2>
                  <h4 className="text-muted">
                    {/* <StepRoutes /> */} Leaves status & updates
                  </h4>
                </div>
                <div className="col-md-4">
                  <div className="btn-list justify-content-end">
                    <a href={"/employee-leave-management"}>
                      <button className="btn btn-primary">
                        <BsCalendar2XFill className="me-2 fs-2" /> View All
                        Leaves
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card">
                {data && (
                  <div class="table-responsive">
                    <table class="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>Specs.</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-secondary">Account Name</td>
                          <td>{(data?.employeeName || "N/A").toUpperCase()}</td>
                        </tr>
                        <tr>
                          <td class="text-secondary">Employee Id</td>
                          <td>{(data?.userId || "N/A").toUpperCase()}</td>
                        </tr>
                        <tr>
                          <td class="text-secondary">Total Leaves</td>
                          <td>{data?.totalLeaves || "0"}</td>
                        </tr>
                        <tr>
                          <td class="text-secondary">Pending Leaves</td>
                          <td>{data?.pendingLeaves || "0"}</td>
                        </tr>
                        <tr>
                          <td class="text-secondary">Sick Leaves</td>
                          <td>{leaveCount.paid} (Obtained)</td>
                        </tr>
                        <tr>
                          <td class="text-secondary">Casual Leaves</td>
                          <td>{leaveCount.unpaid} (Obtained)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div className="table-responsive">
                  {data && (
                    <table className="table table-vcenter">
                      <thead>
                        <tr>
                          <th>Leave Date</th>
                          <th>Leave Start Date</th>
                          <th>Leave End Date</th>
                          <th>Leave Type</th>
                          <th>Leave Reason Type</th>
                          <th>Leave Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.leavesDetails?.map((task, index) => (
                          <tr key={index}>
                            <td>{task.leaveDate || "N/A"}</td>
                            <td>{task.leaveStartDate || "N/A"}</td>
                            <td>{task.leaveEndDate || "N/A"}</td>
                            <td>{task.leaveType?.toUpperCase() || "N/A"}</td>
                            <td>
                              {task.leaveReasonType?.toUpperCase() || "N/A"}
                            </td>
                            <td>
                              <span
                                className={`badge ${
                                  task.leaveStatus === "approved"
                                    ? "bg-green"
                                    : task.leaveStatus === "pending"
                                    ? "bg-blue"
                                    : task.leaveStatus === "rejected"
                                    ? "bg-red"
                                    : "bg-blue"
                                }`}
                                style={{ color: "white" }}
                              >
                                {task.leaveStatus?.toUpperCase() || "N/A"}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    {user.Role !== process.env.REACT_APP_ROLE_CLIENT &&
                      user.Role !== process.env.REACT_APP_ROLE_D_CLIENT && (
                        <SimpleButton
                          buttonText="Edit leave tracking"
                          href={`/employee-leave-management/edit/${id}`}
                        />
                      )}
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewLeaves;
