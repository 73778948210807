import * as yup from "yup";
export const lineItemInvoiceSchema = yup.object().shape({
  particulars: yup.string(),
  totalWithoutGst: yup.number(),
  totalWithGst: yup.number(),
  itemUnit: yup.string(),
  itemQuantity: yup.number(),
  discount: yup.number(),
  HSN: yup.string(),
  itemPrice: yup.number(),
  gstRate: yup.number(),
});

export const ClientInvoiceSchema = yup.object().shape({
  clproject: yup.string(),
  clquotation: yup.string(),
  invoiceType: yup.string(),
  invoiceDate: yup.date(),
  totalAmountIncGst: yup.number(),
  businessName: yup.string().required("Required"),
  contactPersonName: yup.string().required("Required"),
  phoneNumber: yup.number().required("Required"),
  gstNumber: yup.string(),
  address: yup.string().required("Required"),
  countryOfSupply: yup.string(),
  placeOfSupply: yup.string(),
  invoiceFor: yup.string(),
  totalGst: yup.number(),
  notes: yup.string(),
  generatedBy: yup.string(),
  pdfStatus: yup.boolean(),
  lineItems: yup.array().of(lineItemInvoiceSchema),
});
