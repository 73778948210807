import React, { useEffect, useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form } from "formik";
import { EmployeeSchema } from "../../../Schemas/employee/employee_schema";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import { useNavigate, useParams } from "react-router-dom";
import performRequest from "../../../api/request";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import { uploadImage } from "../../../CustomComponent/upload_Image";

import { FcBusinessman } from "react-icons/fc";

// const employeeInfo = {
//   firstName: "Suraj",
//   lastName: "Jadhav",

//   dateOfBirth: "2024-02-28",
//   contactNumber: "9876543212",
//   photograph:
//     "https://static-00.iconduck.com/assets.00/user-icon-2048x2048-ihoxz4vq.png",
//   permanantAddress: "home",
//   residentialAddress: "home,pune,432134",
//   aadharcardNumber: "6878354356787999",
//   aadharcardPhotoFront:
//     "https://cdn.pixabay.com/photo/2022/11/09/00/44/aadhaar-card-7579588_1280.png",
//   aadharcardPhotoBack:
//     "https://i.pinimg.com/736x/30/7b/63/307b637a77b6bf02dd8b83768cfc2996.jpg",

//   pancardNumber: "HQwrt43214Q",
//   pancardPhoto:
//     "https://cdn.pixabay.com/photo/2022/11/09/00/45/pan-card-7579594_1280.png",
//   emergencyContactNumber: "9876543212",
//   emergencyContactName: "5758777777",
//   emergencyContactRelation: "sister",

//   previousOrganizationName: "none",
//   previousOrganizationLocation: "none",

//   totalYearsOfExperience: 2,
//   qualification: "phd",
//   contractOfEmployment: "2 years",
//   roleInOrganisation: "client",
//   workingPlace:
//     "505, City Avenue Pune Bangalore, Highway, Wakad, Pimpri-Chinchwad, Pune, Maharashtra 411057",
//   packageJoinedAt: 15,
//   bankpassbookPhoto:
//     "https://5.imimg.com/data5/ZX/DV/WM/GLADMIN-85845681/sbi-blank-passbook-500x500.jpg",
//   residentialProofPhoto:
//     "https://www.shutterstock.com/image-vector/indian-voter-id-card-vector-260nw-1754108780.jpg",
//   offerLetterAcceptance:
//     "https://images.examples.com/wp-content/uploads/2020/07/Offer-Letter-Sample-Template.jpg",
//   joiningLetterScan:
//     "https://images.template.net/wp-content/uploads/2019/11/Freelancer-Appointment-Letter-Template.jpg",

//   reportingTo: "sales_staff",
//   mobileGivenByOrganisation: "no",
//   mobilId: "",

//   laptopGivenByOrganisation: "no",
//   laptopId: "",
//   simCardGivenByOrganisation: "no",
//   simCardNumber: "",
//   hireViaAgencyOrDirect: "agency",
//   agencyExpences_exlGST: "2000",
// };

const EditEmployee = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reportingTo, setReportingTo] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/employeeManagement/getEmployeeManagementById/${id}`)
      .then((res) => setEmployeeInfo(res.data))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);
  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setReportingTo(res.data))
      .catch((err) => console.error(err));
  }, []);
  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest(
      "PATCH",
      `/employeeManagement/updateEmployeeManagement/${id}`,
      values
      // headers
    )
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Employee data updated!");
          navigate(`/employee-management`);
        } else {
          Toast("error", "Error while uploading data");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">
                  <FcBusinessman className="fs-2  me-1" /> Employee details
                </h2>
                <h4 className="text-muted">
                  <p>Edit employment details</p>
                  {/* <StepRoutes /> */}
                </h4>
              </div>
            </div>
          </div>
          <div className="card">
            {employeeInfo && (
              <Formik
                initialValues={employeeInfo}
                validationSchema={EmployeeSchema}
                onSubmit={handelSubmit}
              >
                {(props) => (
                  <Form>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-6 col-sm-12 px-4">
                          <div className="row mb-2">
                            <label className="form-label required">
                              First name
                            </label>
                            <div className="w-100">
                              <CustomInput name="firstName" type="text" />
                              {props.touched.firstName &&
                                props.errors.firstName && (
                                  <p className="text-danger">
                                    {props.errors.firstName}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Last name
                            </label>
                            <div className="w-100">
                              <CustomInput name="lastName" type="text" />
                              {props.touched.lastName &&
                                props.errors.lastName && (
                                  <p className="text-danger">
                                    {props.errors.lastName}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Email address
                            </label>
                            <div className="w-100">
                              <CustomInput name="emailId" type="email" />
                              {props.touched.emailId &&
                                props.errors.emailId && (
                                  <p className="text-danger">
                                    {props.errors.emailId}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Date of birth
                            </label>
                            <div className="w-100">
                              <CustomInput name="dateOfBirth" type="date" />
                              {props.touched.dateOfBirth &&
                                props.errors.dateOfBirth && (
                                  <p className="text-danger">
                                    {props.errors.dateOfBirth}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Contact number
                            </label>
                            <div className="w-100">
                              <CustomInput name="contactNumber" type="text" />
                              {props.touched.contactNumber &&
                                props.errors.contactNumber && (
                                  <p className="text-danger">
                                    {props.errors.contactNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Aadhar card number
                            </label>
                            <div className="w-100">
                              <CustomInput
                                name="aadharcardNumber"
                                type="text"
                              />
                              {props.touched.aadharcardNumber &&
                                props.errors.aadharcardNumber && (
                                  <p className="text-danger">
                                    {props.errors.aadharcardNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Pan card number
                            </label>
                            <div className="w-100">
                              <CustomInput name="pancardNumber" type="text" />
                              {props.touched.pancardNumber &&
                                props.errors.pancardNumber && (
                                  <p className="text-danger">
                                    {props.errors.pancardNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Permanant address
                            </label>
                            <div className="w-100">
                              <CustomInput
                                name="permanantAddress"
                                type="text"
                              />
                              {props.touched.permanantAddress &&
                                props.errors.permanantAddress && (
                                  <p className="text-danger">
                                    {props.errors.permanantAddress}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Residential address
                            </label>
                            <div className="w-100">
                              <CustomInput
                                name="residentialAddress"
                                type="text"
                              />
                              {props.touched.residentialAddress &&
                                props.errors.residentialAddress && (
                                  <p className="text-danger">
                                    {props.errors.residentialAddress}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="w-25 form-label required">
                              Qualification
                            </label>
                            <div className="w-100">
                              <CustomSelect type="text" name="qualification">
                                <option value="">Select</option>
                                <option value="high_school">High School</option>
                                <option value="associate_degree">
                                  Associate Degree
                                </option>
                                <option value="bachelor_degree">
                                  Bachelor's Degree
                                </option>
                                <option value="master_degree">
                                  Master's Degree
                                </option>
                                <option value="phd">PhD</option>
                                <option value="vocational_certificate">
                                  Vocational Certificate
                                </option>
                                <option value="diploma">Diploma</option>
                                <option value="professional_certification">
                                  Professional Certification
                                </option>
                                <option value="postgraduate_certificate">
                                  Postgraduate Certificate
                                </option>
                                <option value="technical_degree">
                                  Technical Degree
                                </option>
                                <option value="trade_school">
                                  Trade School
                                </option>
                                <option value="online_course">
                                  Online Course
                                </option>
                                <option value="apprenticeship">
                                  Apprenticeship
                                </option>
                                <option value="military_training">
                                  Military Training
                                </option>
                                <option value="bootcamp">Bootcamp</option>
                              </CustomSelect>
                              {props.touched.qualification &&
                                props.errors.qualification && (
                                  <p className="text-danger">
                                    {props.errors.qualification}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Emergency contact name
                            </label>
                            <div className="w-100">
                              <CustomInput
                                name="emergencyContactName"
                                type="text"
                              />
                              {props.touched.emergencyContactName &&
                                props.errors.emergencyContactName && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactName}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Emergency contact number
                            </label>
                            <div className="w-100">
                              <CustomInput
                                name="emergencyContactNumber"
                                type="text"
                              />
                              {props.touched.emergencyContactNumber &&
                                props.errors.emergencyContactNumber && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactNumber}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Emergency contact relation
                            </label>
                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="emergencyContactRelation"
                              >
                                <option value="">Select</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="spouse">Spouse</option>
                                <option value="sister">Sister</option>
                                <option value="brother">Brother</option>
                                <option value="friend">Friend</option>
                              </CustomSelect>
                              {props.touched.emergencyContactRelation &&
                                props.errors.emergencyContactRelation && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactRelation}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 px-4">
                          <div className="row mb-2">
                            <label className="form-label required">
                              Salary per month
                            </label>
                            <div className="w-100">
                              <CustomInput type="text" name="salaryPerMonth" />
                              {props.touched.salaryPerMonth &&
                                props.errors.salaryPerMonth && (
                                  <p className="text-danger">
                                    {props.errors.salaryPerMonth}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              PF/PT deduction
                            </label>
                            <div className="w-100">
                              <CustomInput type="text" name="pfptDeduction" />
                              {props.touched.pfptDeduction &&
                                props.errors.pfptDeduction && (
                                  <p className="text-danger">
                                    {props.errors.pfptDeduction}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Mode of salary
                            </label>

                            <div className="w-100">
                              <CustomSelect type="text" name="modeOfSalary">
                                <option value="">Select</option>
                                <option value="cash">Cash</option>
                                <option value="nft">NFT</option>
                                <option value="cheque">Cheque</option>
                              </CustomSelect>
                              {props.touched.modeOfSalary &&
                                props.errors.modeOfSalary && (
                                  <p className="text-danger">
                                    {props.errors.modeOfSalary}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Hired via agency or direct
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="hireViaAgencyOrDirect"
                              >
                                <option value="">Select</option>
                                <option value="agency">Agency</option>
                                <option value="direct">Direct</option>
                              </CustomSelect>
                              {props.touched.hireViaAgencyOrDirect &&
                                props.errors.hireViaAgencyOrDirect && (
                                  <p className="text-danger">
                                    {props.errors.hireViaAgencyOrDirect}
                                  </p>
                                )}
                            </div>
                          </div>
                          {props.values.hireViaAgencyOrDirect === "agency" ? (
                            <div className="row mb-2">
                              <label className="form-label required">
                                Agency expense (exl GST)
                              </label>
                              <div className="w-100">
                                <CustomInput
                                  required
                                  name="agencyExpences_exlGST"
                                  type="text"
                                />
                                {props.touched.agencyExpences_exlGST &&
                                  props.errors.agencyExpences_exlGST && (
                                    <p className="text-danger">
                                      {props.errors.agencyExpences_exlGST}
                                    </p>
                                  )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="row mb-2">
                            <label className="form-label required">
                              Total years of experience
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="totalYearsOfExperience"
                              >
                                <option value=""></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                              </CustomSelect>
                              {props.touched.totalYearsOfExperience &&
                                props.errors.totalYearsOfExperience && (
                                  <p className="text-danger">
                                    {props.errors.totalYearsOfExperience}
                                  </p>
                                )}
                            </div>
                          </div>

                          {props.values.totalYearsOfExperience != 0 ? (
                            <div className="row mb-2">
                              <label className="form-label required">
                                Previous organization name
                              </label>
                              <div className="w-100">
                                <CustomInput
                                  required
                                  name="previousOrganizationName"
                                  type="text"
                                />
                                {props.touched.previousOrganizationName &&
                                  props.errors.previousOrganizationName && (
                                    <p className="text-danger">
                                      {props.errors.previousOrganizationName}
                                    </p>
                                  )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {props.values.totalYearsOfExperience != 0 ? (
                            <div className="row mb-2">
                              <label className="form-label required">
                                Previous organization location
                              </label>
                              <div className="w-100">
                                <CustomInput
                                  required
                                  name="previousOrganizationLocation"
                                  type="text"
                                />
                                {props.touched.previousOrganizationLocation &&
                                  props.errors.previousOrganizationLocation && (
                                    <p className="text-danger">
                                      {
                                        props.errors
                                          .previousOrganizationLocation
                                      }
                                    </p>
                                  )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="row mb-2">
                            <label className="form-label required">
                              Role in organization
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="roleInOrganisation"
                              >
                                <option value="">Select Role</option>
                                <option value="superAdmin">Super Admin</option>

                                <option value="adminStaff">Admin Staff</option>
                                <option value="purchaseStaff">
                                  Purchase Staff
                                </option>
                                <option value="saleStaff">Sales Staff</option>
                                <option value="agronomistStaff">
                                  Agronomist Staff
                                </option>
                                <option value="opsStaff">Ops Staff</option>
                                <option value="inspectionStaff">
                                  Inspection Staff
                                </option>
                                <option value="marketingStaff">
                                  Marketing Staff
                                </option>
                                <option value="labourStaff">
                                  Labour Staff
                                </option>
                                <option value="accountStaff">
                                  Account Staff
                                </option>
                                <option value="ITStaff">IT Staff</option>
                                <option value="SSMStaff">SSM Staff</option>
                                <option value="salesManager">
                                  Sales Manager
                                </option>
                                <option value="clientFeedback">
                                  Client Feedback
                                </option>
                              </CustomSelect>
                              {props.touched.roleInOrganisation &&
                                props.errors.roleInOrganisation && (
                                  <p className="text-danger">
                                    {props.errors.roleInOrganisation}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Reporting to
                            </label>

                            <div className="w-100">
                              <CustomSelect type="text" name="reportingTo">
                                <option value="">Select</option>
                                {reportingTo &&
                                  reportingTo.map((emp) => {
                                    return (
                                      <option
                                        key={emp?.userName}
                                        value={emp?.userName}
                                      >
                                        {emp?.userName}
                                      </option>
                                    );
                                  })}
                              </CustomSelect>
                              {props.touched.reportingTo &&
                                props.errors.reportingTo && (
                                  <p className="text-danger">
                                    {props.errors.reportingTo}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Package joined at
                            </label>
                            <div className="w-100">
                              <CustomInput type="text" name="packageJoinedAt" />
                              {props.touched.packageJoinedAt &&
                                props.errors.packageJoinedAt && (
                                  <p className="text-danger">
                                    {props.errors.packageJoinedAt}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Contract of employment
                            </label>
                            <div className="w-100">
                              <CustomSelect
                                name="contractOfEmployment"
                                type="text"
                              >
                                <option value="">Select Contract</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </CustomSelect>
                              {props.touched.contractOfEmployment &&
                                props.errors.contractOfEmployment && (
                                  <p className="text-danger">
                                    {props.errors.contractOfEmployment}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <label className="form-label required">
                              Laptop given by organisation
                            </label>
                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="laptopGivenByOrganisation"
                              >
                                <option value="">Select</option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </CustomSelect>
                              {props.touched.laptopGivenByOrganisation &&
                                props.errors.laptopGivenByOrganisation && (
                                  <p className="text-danger">
                                    {props.errors.laptopGivenByOrganisation}
                                  </p>
                                )}
                            </div>
                          </div>
                          {props.values.laptopGivenByOrganisation === "yes" ? (
                            <div className="row mb-2">
                              <label className="form-label required">
                                Laptop id:
                              </label>

                              <div className="w-100">
                                <CustomInput
                                  type="text"
                                  required
                                  name="laptopId"
                                />

                                {props.touched.laptopId &&
                                  props.errors.laptopId && (
                                    <p className="text-danger">
                                      {props.errors.laptopId}
                                    </p>
                                  )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row mb-2">
                            <label className="form-label required">
                              Mobile given by organisation
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="mobileGivenByOrganisation"
                              >
                                <option value="">Select</option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </CustomSelect>
                              {props.touched.mobileGivenByOrganisation &&
                                props.errors.mobileGivenByOrganisation && (
                                  <p className="text-danger">
                                    {props.errors.mobileGivenByOrganisation}
                                  </p>
                                )}
                            </div>
                          </div>
                          {props.values.mobileGivenByOrganisation === "yes" ? (
                            <div className="row mb-2">
                              <label className="form-label required">
                                Mobile id:
                              </label>

                              <div className="w-100">
                                <CustomInput
                                  type="text"
                                  required
                                  name="mobileId"
                                />

                                {props.touched.mobileId &&
                                  props.errors.mobileId && (
                                    <p className="text-danger">
                                      {props.errors.mobileId}
                                    </p>
                                  )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row mb-2">
                            <label className="form-label required">
                              SIM card given by organisation
                            </label>

                            <div className="w-100">
                              <CustomSelect
                                type="text"
                                name="simCardGivenByOrganisation"
                              >
                                <option value="">Select</option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </CustomSelect>
                              {props.touched.simCardGivenByOrganisation &&
                                props.errors.simCardGivenByOrganisation && (
                                  <p className="text-danger">
                                    {props.errors.simCardGivenByOrganisation}
                                  </p>
                                )}
                            </div>
                          </div>
                          {props.values.simCardGivenByOrganisation === "yes" ? (
                            <div className="row mb-2">
                              <label className="form-label required">
                                Sim Card Number:
                              </label>

                              <div className="w-100">
                                <CustomInput
                                  type="text"
                                  required
                                  name="simCardNumber"
                                />

                                {props.touched.simCardNumber &&
                                  props.errors.simCardNumber && (
                                    <p className="text-danger">
                                      {props.errors.simCardNumber}
                                    </p>
                                  )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row mb-2">
                            <label className="form-label required">
                              Working place
                            </label>
                            <div className="w-100">
                              <CustomInput name="workingPlace" type="text" />
                              {props.touched.workingPlace &&
                                props.errors.workingPlace && (
                                  <p className="text-danger">
                                    {props.errors.workingPlace}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row px-3">
                        <div className="col-sm-12 col-md-3">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">Photograph:</h3>
                            </div>
                            <div className="card-body">
                              <div
                                className="img-responsive img-responsive-21x9 card-img-bottom"
                                style={{
                                  backgroundImage: `url(${employeeInfo.photograph})`,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer ">
                              <CustomInput
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "photograph",
                                    e.target.files[0]
                                  )
                                }
                                name="photograph"
                                type="file"
                              />
                              {props.touched.photograph &&
                                props.errors.photograph && (
                                  <p className="text-danger">
                                    {props.errors.photograph}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">
                                Aadhar card front:{" "}
                              </h3>
                            </div>
                            <div className="card-body">
                              <div
                                className="img-responsive img-responsive-21x9 card-img-bottom"
                                style={{
                                  backgroundImage: `url(${employeeInfo.aadharcardPhotoFront})`,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer">
                              <CustomInput
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "aadharcardPhotoFront",
                                    e.target.files[0]
                                  )
                                }
                                name="aadharcardPhotoFront"
                                type="file"
                              />
                              {props.touched.aadharcardPhotoFront &&
                                props.errors.aadharcardPhotoFront && (
                                  <p className="text-danger">
                                    {props.errors.aadharcardPhotoFront}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">Aadhar card back:</h3>
                            </div>

                            <div className="card-body">
                              <div
                                className="img-responsive img-responsive-21x9 card-img-bottom"
                                style={{
                                  backgroundImage: `url(${employeeInfo.aadharcardPhotoBack})`,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer">
                              <CustomInput
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "aadharcardPhotoBack",
                                    e.target.files[0]
                                  )
                                }
                                name="aadharcardPhotoBack"
                                type="file"
                              />
                              {props.touched.aadharcardPhotoBack &&
                                props.errors.aadharcardPhotoBack && (
                                  <p className="text-danger">
                                    {props.errors.aadharcardPhotoBack}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">Pan card:</h3>
                            </div>

                            <div className="card-body">
                              <div
                                className="img-responsive img-responsive-21x9 card-img-bottom"
                                style={{
                                  backgroundImage: `url(${employeeInfo.pancardPhoto})`,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer">
                              <CustomInput
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "pancardPhoto",
                                    e.target.files[0]
                                  )
                                }
                                name="pancardPhoto"
                                type="file"
                              />
                              {props.touched.pancardPhoto &&
                                props.errors.pancardPhoto && (
                                  <p className="text-danger">
                                    {props.errors.pancardPhoto}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">
                                Offer letter acceptance:
                              </h3>
                            </div>

                            <div className="card-body">
                              <div
                                className="img-responsive img-responsive-21x9 card-img-bottom"
                                style={{
                                  backgroundImage: `url(${employeeInfo.offerLetterAcceptance})`,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer">
                              <CustomInput
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "offerLetterAcceptance",
                                    e.target.files[0]
                                  )
                                }
                                name="offerLetterAcceptance"
                                type="file"
                              />
                              {props.touched.offerLetterAcceptance &&
                                props.errors.offerLetterAcceptance && (
                                  <p className="text-danger">
                                    {props.errors.offerLetterAcceptance}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">
                                Joining letter scan:
                              </h3>
                            </div>

                            <div className="card-body">
                              <div
                                className="img-responsive img-responsive-21x9 card-img-bottom"
                                style={{
                                  backgroundImage: `url(${employeeInfo.joiningLetterScan})`,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer">
                              <CustomInput
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "joiningLetterScan",
                                    e.target.files[0]
                                  )
                                }
                                name="joiningLetterScan"
                                type="file"
                              />
                              {props.touched.joiningLetterScan &&
                                props.errors.joiningLetterScan && (
                                  <p className="text-danger">
                                    {props.errors.joiningLetterScan}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">Bank passbook:</h3>
                            </div>

                            <div className="card-body">
                              <div
                                className="img-responsive img-responsive-21x9 card-img-bottom"
                                style={{
                                  backgroundImage: `url(${employeeInfo.bankpassbookPhoto})`,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer">
                              <CustomInput
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "bankpassbookPhoto",
                                    e.target.files[0]
                                  )
                                }
                                name="bankpassbookPhoto"
                                type="file"
                              />
                              {props.touched.bankpassbookPhoto &&
                                props.errors.bankpassbookPhoto && (
                                  <p className="text-danger">
                                    {props.errors.bankpassbookPhoto}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="card-title">Residential proof:</h3>
                            </div>

                            <div className="card-body">
                              <div
                                className="img-responsive img-responsive-21x9 card-img-bottom"
                                style={{
                                  backgroundImage: `url(${employeeInfo.residentialProofPhoto})`,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer">
                              <CustomInput
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "residentialProofPhoto",
                                    e.target.files[0]
                                  )
                                }
                                name="residentialProofPhoto"
                                type="file"
                              />
                              {props.touched.residentialProofPhoto &&
                                props.errors.residentialProofPhoto && (
                                  <p className="text-danger">
                                    {props.errors.residentialProofPhoto}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="btn-list justify-content-end">
                        <FormSubmitButton buttonText="Save Employee Details" />
                        <SimpleButton buttonText="Cancel" type="secondary" />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditEmployee;
