import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { extractDate } from "../../../Components/Functions/date.fun";
import { useSelector } from "react-redux";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";
import { capitalizeFirstLetterOfEachWord } from "../../../Components/Functions/word.fun";
const ViewProjectTracking = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  let { id } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/projectTracking/getProjectTrackingById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (data) {
      if (data?.workOrderUpload) {
        getUplodedImage(setImageUrls, data?.workOrderUpload, "workOrderUpload");
      }
    }
  }, [data]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Project Tracking</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">view Project Tracking</h2>
            </div>
            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Date of Project Tracking
                  </label>
                  <div className="w-100 col">
                    {(extractDate(data?.createdAt) || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Project</label>
                  <div className="w-100 col">
                    {(data?.projectClientName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Site selection
                  </label>
                  <div className="w-100 col">
                    {(data?.siteClientName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Notes</label>
                  <div className="w-100 col">
                    {data.notes
                      ? data.notes
                          .trim()
                          .split("\n")
                          .map((line, index) => (
                            <span key={index}>
                              {line}
                              {index !== data.notes.split("\n").length - 1 && (
                                <br />
                              )}
                            </span>
                          ))
                      : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Work order upload
                  </label>
                  <div className="w-100 col">
                    {imageUrls?.workOrderUpload ? (
                      data?.workOrderUpload.endsWith(".pdf") ? (
                        <embed
                          src={imageUrls?.workOrderUpload}
                          width="70%"
                          height="375"
                          type="application/pdf"
                        />
                      ) : (
                        <img
                          width="70%"
                          src={imageUrls?.workOrderUpload}
                          alt={data?.workOrderUpload}
                        />
                      )
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              <hr />
              <h2>Time lines</h2>
              <hr />
              <div className="table-responsive">
                {data && (
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Task Name</th>
                        <th>Schedule Date</th>
                        <th>completion Date</th>
                        <th>responsible Staff</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.taskList?.map((task, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {capitalizeFirstLetterOfEachWord(
                                task?.taskName
                              ) || "N/A"}
                            </td>
                            <td>
                              {(task?.scheduleDate || "N/A").toUpperCase()}
                            </td>
                            <td>
                              {(task?.completionDate || "N/A").toUpperCase()}
                            </td>
                            <td>
                              {(task?.responsibleStaff || "N/A").toUpperCase()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                {user.Role !== process.env.REACT_APP_ROLE_CLIENT &&
                  user.Role !== process.env.REACT_APP_ROLE_D_CLIENT && (
                    <SimpleButton
                      buttonText="Edit Project Tracking"
                      href={`/client-project-tracking/edit/${id}`}
                    />
                  )}
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewProjectTracking;
