import React from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";

const tableHead = [
  "Sr.",
  "Employee Name",
  "Employee ID",
  "Total leaves",
  "Pending leaves",
  "Actions",
];

const LeavesTrackingTable = ({ EX = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/employee-leave-management/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {EX?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.employeeName || "N/A"}</td>
                <td>{item?.userId || "N/A"}</td>
                <td>{item?.totalLeaves || "N/A"}</td>
                <td>{item?.pendingLeaves || "N/A"}</td>

                <td>
                  <button
                    className="btn btn-sm px-3 btn-primary"
                    onClick={() => goToView(item.userId)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeavesTrackingTable;
