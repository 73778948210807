import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ReactStars from "react-stars";
import FormSubmitButton from "../Components/layouts/formSubmitButton";
import SimpleButton from "../Components/layouts/simple_button";
import performRequest from "../api/request";
import { Toast } from "../CustomComponent/toast";
import LoadingPage from "../Components/layouts/loading";
import feedbackImage from "../certificates/feedback.jpg";
import CustomInput from "../CustomComponent/custom_input";
import CustomSelect from "../CustomComponent/custom_select";
import CustomTextArea from "../CustomComponent/custom_textarea";
import StepRoutes from "../Routes/steps_routes";
import Footer from "../Components/Footer/footer";

const validationSchema = Yup.object().shape({
  schedulingExperience: Yup.number().required("Please rate your experience."),
  agentOnTime: Yup.string().required("Please select an option."),
  queriesAnswered: Yup.string().required("Please select an option."),
  climateInfo: Yup.string().required("Please select an option."),
  name: Yup.string().required("Please enter your name."),
  contactNumber: Yup.string().required("Please enter your contact number."),
  notes: Yup.string(),
});

const initialValues = {
  schedulingExperience: 0,
  agentOnTime: "",
  queriesAnswered: "",
  climateInfo: "",
  name: "",
  contactNumber: "",
  notes: "",
};

const FeedbackPage = () => {
  const userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values, actions) => {
    values = {
      ...values,
      generatedBy: userData?.Name,
    };

    setLoading(true);
    performRequest("POST", "/siteInspectionFeedback/createFeedback/", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Feedback submitted successfully!");
        } else {
          Toast("error", "Error while submitting feedback");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page page-center">
      <style>{`
        .container-tight {
          max-width: 600px;
          margin: auto;
        }
        .card-md {
          margin-top: 1.5rem;
        }
        .input-group-text {
          cursor: pointer;
        }
        .text-center {
          text-align: center;
        }
        .text-muted {
          color: #6c757d !important;
        }
        .form-group {
          margin-bottom: 1rem;
        }
        .form-label {
          display: block;
          margin-bottom: 0.5rem;
          font-weight: 600;
        }
        .form-footer {
          margin-top: 1rem;
        }
        .image-container {
          display: flex;
          justify-content: center;
        }
      `}</style>
      <div className="container container-normal py-4">
        <div className="row g-2 align-items-center">
          <div className="col">
            <h2 className="page-title mb-1">Site Inspection Feedback</h2>
            <h4 className="text-muted">
              <StepRoutes />
            </h4>
          </div>
        </div>
        <div className="row align-items-center g-4">
          <div className="col-lg d-none d-lg-block">
            <div className="image-container">
              <img
                src={feedbackImage}
                height="300"
                className="d-block mx-auto"
                alt="Feedback Illustration"
              />
            </div>
          </div>
          <div className="col-lg">
            <div className="container-tight">
              <div className="card card-md">
                <div className="card-body">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {(props) => (
                      <Form>
                        <div className="form-group mb-3">
                          <label
                            className="form-label"
                            htmlFor="schedulingExperience"
                          >
                            How was your experience regarding scheduling a site
                            inspection?
                          </label>
                          <Field name="schedulingExperience">
                            {({ field }) => (
                              <ReactStars
                                {...field}
                                count={5}
                                size={36}
                                half={false}
                                value={props.values.schedulingExperience}
                                onChange={(newValue) => {
                                  props.setFieldValue(
                                    "schedulingExperience",
                                    newValue
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="agentOnTime">
                            Did our agent reach the location on time?
                          </label>
                          <CustomSelect
                            as="select"
                            name="agentOnTime"
                            className="form-control"
                          >
                            <option value="">Select</option>
                            <option value="yes reached on time">
                              Yes, reached on time
                            </option>
                            <option value="reached before time">
                              Reached before time
                            </option>
                            <option value="delayed in reaching the site">
                              Delayed in reaching the site
                            </option>
                          </CustomSelect>
                          {props.touched.agentOnTime &&
                            props.errors.agentOnTime && (
                              <p className="text-danger">
                                {props.errors.agentOnTime}
                              </p>
                            )}
                        </div>
                        <div className="form-group mb-3">
                          <label
                            className="form-label"
                            htmlFor="queriesAnswered"
                          >
                            Did our agent answer all your queries?
                          </label>
                          <CustomSelect
                            as="select"
                            name="queriesAnswered"
                            className="form-control"
                          >
                            <option value="">Select an option</option>
                            <option value="yes answered all queries">
                              Yes, answered all queries
                            </option>
                            <option value="some queries are answered">
                              Some, queries are answered
                            </option>
                            <option value="no queries are not answered">
                              No, queries are not answered
                            </option>
                          </CustomSelect>
                          {props.touched.queriesAnswered &&
                            props.errors.queriesAnswered && (
                              <p className="text-danger">
                                {props.errors.queriesAnswered}
                              </p>
                            )}
                        </div>
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="climateInfo">
                            Did our agent give you a fair idea about climate
                            conditions?
                          </label>
                          <CustomSelect
                            as="select"
                            name="climateInfo"
                            className="form-control"
                          >
                            <option value="">Select an option</option>
                            <option value="yes provided detailed information">
                              Yes, provided detailed information
                            </option>
                            <option value="somewhat provided basic information">
                              Somewhat, provided basic information
                            </option>
                            <option value="no did not provide information">
                              No, did not provide information
                            </option>
                          </CustomSelect>
                          {props.touched.climateInfo &&
                            props.errors.climateInfo && (
                              <p className="text-danger">
                                {props.errors.climateInfo}
                              </p>
                            )}
                        </div>
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="notes">
                            Notes
                          </label>
                          <CustomTextArea
                            as="textarea"
                            name="notes"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="name">
                            Name
                          </label>
                          <CustomInput
                            name="name"
                            type="text"
                            className="form-control"
                          />
                          {props.touched.name && props.errors.name && (
                            <p className="text-danger">{props.errors.name}</p>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="contactNumber">
                            Contact Number
                          </label>
                          <CustomInput
                            name="contactNumber"
                            type="text"
                            className="form-control"
                          />
                          {props.touched.contactNumber &&
                            props.errors.contactNumber && (
                              <p className="text-danger">
                                {props.errors.contactNumber}
                              </p>
                            )}
                        </div>
                        <div className="form-footer">
                          <FormSubmitButton buttonText="Submit" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="text-center text-muted mt-3">
                Thank you for your feedback!
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FeedbackPage;
