import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { FcPhoneAndroid } from "react-icons/fc";

const ViewWatsappLog = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/whatsAppLogs/getWhatsAppLogsById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  function formatTimeToAMPM(time) {
    let [hours, minutes, seconds] = time.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  }

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">View Details</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specification</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>Name</td>
                          <td>{(data?.name || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Contact Number</td>
                          <td>
                            {(data?.contactNumber || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Template</td>
                          <td>
                            {(
                              data?.whatsApptemplateName || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Genrated By</td>
                          <td>{data?.genratedBy || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Origin</td>
                          <td>{data?.origin || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Message send status</td>
                          <td>
                            {data?.whatsApptemplateMessageSendStatus || "N/A"}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Message send date</td>
                          <td>
                            {data?.whatsApptemplateMessageSendDate || "N/A"}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Message send time</td>
                          <td>
                            {formatTimeToAMPM(
                              data?.whatsApptemplateMessageSendTime
                            ) || "N/A"}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewWatsappLog;
