import * as yup from "yup";

export const PriceWatcherSchema = yup.object().shape({
  plantName: yup.string(),
  plantId: yup.string(),
  week: yup.string(),
  weekStartDate: yup.date(),
  weekEndDate: yup.date(),
  weeklyPrice: yup.string(),
  month: yup.string(),
  year: yup.string(),
  season: yup.string(),
});
