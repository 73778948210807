import React, { useEffect } from "react";
import signature from "../../../certificates/signature.jpg";
const SignatureDetails = () => {
  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "0px",
    padding: "0px",
    paddingLeft: "3px",
    fontSize: "12px",
    borderCollapse: "collapse",
  };
  useEffect(() => {
    const TR = document.getElementsByTagName("tr");
    const TH = document.getElementsByTagName("th");
    const TD = document.getElementsByTagName("td");
    const H3 = document.getElementsByTagName("h3");

    Array.from(TR).forEach((tr) => {
      Object.assign(tr.style, style);
    });
    Array.from(TH).forEach((th) => {
      Object.assign(th.style, style);
    });
    Array.from(TD).forEach((td) => {
      Object.assign(td.style, style);
    });
    Array.from(H3).forEach((h3) => {
      Object.assign(h3.style, {
        marginTop: "14px",
        marginBottom: "2px",
      });
    });
  }, []);
  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td style={{ width: "70%" }}>
            <p>
              Please note This is only a purchase order and not a specification
              sheet. Some of the details are not given and expected to be
              applied at the time of execution as part of trade secret.
            </p>
            <p>
              For any query or questions connect with purchase manager. For
              grieviences call on +91-9870-424-425 or right to us on
              contact@kryzen.com
            </p>
          </td>
          <td>
            <img src={signature} width={"200px"} alt="signature" />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SignatureDetails;
