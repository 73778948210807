import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/user/loginReducer";
import { CamelCaseToFirstUpper } from "../Functions/word.fun";
import { useNavigate } from "react-router";
import LoginTimeCounter from "./login_time_counter";
import { getUplodedImage } from "../../CustomComponent/upload_Image";
import { FcEngineering, FcLock, FcSportsMode } from "react-icons/fc";
import performRequest from "../../api/request";
// import HeaderCrousel from "./header_crousel";
const HeaderMenu = () => {
  const navigate = useNavigate();
  const store = useSelector((store) => store.login.user);
  const loginState = useSelector((store) => store.login);
  const [loginTime, setLoginTime] = useState();
  const dispatch = useDispatch();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (store) {
      if (store?.UserPhoto) {
        getUplodedImage(setImageUrls, store?.UserPhoto, "UserPhoto");
      }
    }
  }, [store]);

  useEffect(() => {
    if (store.UserId) {
      performRequest("POST", `/attendance/getUserAttendance/`, {
        userId: store.UserId,
        date: new Date().toLocaleDateString(),
      })
        .then((res) => {
          if (res.status === 201) {
            setLoginTime(res.data?.attendance?.time);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [store, loginState.userlog]);
  const handelLogout = () => {
    dispatch(logout());
    navigate("/account-login");
  };
  const officeLogoutAttendance = () => {
    navigate("/logout-attendance");
  };
  const goToHome = () => {
    navigate("/");
  };
  return (
    <>
      <header className="navbar navbar-expand-lg d-print-none">
        <div className="container-xl">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
            aria-controls="navbar-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand  d-none-navbar-horizontal pe-0 pe-md-3">
            <span className="cursor-pointer" onClick={goToHome}>
              {/* <img
                className="navbar-brand-image"
                src="https://www.kryzen.com/wp-content/uploads/2022/02/kryzen-logo-1.png"
                alt="Kryzen"
                style={{
                  width: "110px",
                  height: "50px",
                }}
              /> */}
              <h1 className="fs-large m-0 p-0">
                <span className="text-primary">α</span>lpha
              </h1>
              <p className="fs-5 ps-6 m-0 text-success">by KRYZEN</p>
            </span>
          </h1>

          <div className="navbar-nav flex-row order-md-last">
            {loginState?.isLoggedIn && loginState?.userlog && loginTime && (
              <div className="nav-item me-2">
                <span className="d-flex lh-1 text-reset p-0 cursor-pointer">
                  {" "}
                  <div
                    className="d-xl-block px-2"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      <span className="ps-2">{loginTime}</span>
                    </div>
                    <div className="ms-2 mt-1 small text-muted">
                      <LoginTimeCounter loginTime={loginTime} />
                    </div>
                  </div>
                </span>
              </div>
            )}
            <div className="nav-item dropdown">
              <span
                className="nav-link d-flex lh-1 text-reset p-0 cursor-pointer"
                data-bs-toggle="dropdown"
                aria-label="Open user menu"
              >
                <span
                  className="avatar avatar-sm"
                  style={{
                    backgroundImage: imageUrls?.UserPhoto
                      ? `url(${imageUrls?.UserPhoto})`
                      : `url(https://i.pinimg.com/474x/0a/a8/58/0aa8581c2cb0aa948d63ce3ddad90c81.jpg)`,
                  }}
                ></span>{" "}
                <div className="d-none d-xl-block ps-2">
                  <div>{CamelCaseToFirstUpper(store?.Name)}</div>
                  <div className="mt-1 small text-muted">
                    {CamelCaseToFirstUpper(store?.Role)}
                  </div>
                </div>
              </span>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <a className="dropdown-item" href="/account-setting">
                  <FcEngineering className="fs-2 me-2" /> Account Settings
                </a>
                {loginState.isLoggedIn &&
                  loginState.user &&
                  loginState.userlog &&
                  loginState.userlog.firstLogin && (
                    <span
                      onClick={officeLogoutAttendance}
                      className="dropdown-item"
                    >
                      <FcSportsMode className="fs-2 me-2" /> Office Logout
                    </span>
                  )}
                <span onClick={handelLogout} className="dropdown-item">
                  <FcLock className="fs-2 me-2" /> System Logout
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderMenu;
