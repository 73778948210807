import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import StepRoutes from "../../Routes/steps_routes";
import WORK_ORDER from "../../../Kryzen Alpha App/certificates/WORK_ORDER.pdf";
import { useSelector } from "react-redux";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import { useNavigate } from "react-router";
import { getId } from "../../Components/Functions/word.fun";

const FarmConstructionAgreement = () => {
  const [clientDetails, setClientDetails] = useState("");
  const navigate = useNavigate();
  const [siteInspectionData, setSiteInspectionData] = useState("");
  const user = useSelector((store) => store.login.user);
  const [quotation, setQuotation] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeLines, setTimeLines] = useState("");

  useEffect(() => {
    if (user.Name) {
      setLoading(true);
      performRequest("GET", `/clientProject/getCp?name=${user.Name}`)
        .then((res) => {
          if (res.status === 200) {
            if (
              res?.data?.clientProjectsData &&
              res?.data?.clientProjectsData?.length > 0
            ) {
              setClientDetails(res?.data?.clientProjectsData[0]);
            }
          } else {
            Toast("error", "Error while getting Details!");
          }
          setLoading(false);
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [user.Name]);
  useEffect(() => {
    if (user.Name) {
      setLoading(true);
      performRequest(
        "GET",
        `/projectTracking/getProjectTracking?name=${user.Name}`
      )
        .then((res) => {
          if (res.status === 200) {
            if (res.data && res.data?.projectTrackingData?.length > 0) {
              setTimeLines(res.data?.projectTrackingData[0]);
            }
          } else {
            Toast("error", "Error while getting Details!");
          }
          setLoading(false);
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [clientDetails.clientName]);
  useEffect(() => {
    if (user.Name) {
      performRequest(
        "GET",
        `/siteInspection/getSiteInspection?name=${user.Name}`
      )
        .then((res) => {
          if (res.status === 200) {
            if (
              res.data?.siteInspectionData &&
              res.data.siteInspectionData?.length > 0
            ) {
              setSiteInspectionData(res.data.siteInspectionData[0]);
              setQuotation(res.data.siteInspectionData[0]?.quotationNumber);
            }
          } else {
            Toast("error", "Error while getting Leads!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [user.Name]);
  const goToAgrementPage = () => {
    navigate(`/farm-construction/agreement`);
  };
  const goToQuotationPage = () => {
    if (quotation) {
      let id = getId(quotation);
      navigate(`/farm-construction/quotation?id=${id}`);
    }
  };
  const goToDPRPage = () => {
    if (siteInspectionData && siteInspectionData.siNumber) {
      let id = getId(siteInspectionData.siNumber);
      navigate(`/client-site-inspection/preview/${id}`);
    }
  };
  const goToFarmSupport = () => {
    navigate(`/agronomy-farm-support`);
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Dashboard</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div>
            <div className="grid g-0">
              <div className="g-col-lg-4 g-col-md-12 g-col-12 border-end card">
                <div className="card-body">
                  <h2 className="page-title">
                    {clientDetails?.businessName || clientDetails?.clientName}
                  </h2>
                  <p className="mt-3">
                    GST:
                    <span className="fw-bold">
                      {" "}
                      {clientDetails?.businessGstNumber || "N/A"}
                    </span>
                  </p>

                  <p className="mt-3">
                    Pan No.:
                    <span className="fw-bold">
                      {" "}
                      {clientDetails?.panNumber || "N/A"}
                    </span>
                  </p>
                  <p className="mt-3">
                    Email:
                    <span className="fw-bold">
                      {" "}
                      {clientDetails?.clientEmail || "N/A"}
                    </span>
                  </p>
                  <p>
                    Contact No:
                    <span className="fw-bold">
                      {" "}
                      {clientDetails?.clientContactNumber}
                    </span>
                  </p>
                  <p>
                    Billing Address:
                    <span className="fw-bold">
                      {" "}
                      {clientDetails?.billingAddress}
                    </span>
                  </p>
                  <p>
                    Farm Address:
                    <span className="fw-bold">
                      {" "}
                      {clientDetails?.farmAddress}
                    </span>
                  </p>
                  <hr />

                  <h2 className="page-title mb-2">Documents</h2>
                  <div>
                    {quotation && (
                      <button className="btn m-1" onClick={goToQuotationPage}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-file-text"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <path d="M9 9l1 0" />
                          <path d="M9 13l6 0" />
                          <path d="M9 17l6 0" />
                        </svg>
                        Quotation
                      </button>
                    )}
                    <button className="btn m-1" onClick={goToAgrementPage}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-file-text"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                        <path d="M9 9l1 0" />
                        <path d="M9 13l6 0" />
                        <path d="M9 17l6 0" />
                      </svg>
                      Farm Constuction Agreement
                    </button>
                    {siteInspectionData && siteInspectionData.siNumber && (
                      <button onClick={goToDPRPage} className="btn m-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-file-text"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <path d="M9 9l1 0" />
                          <path d="M9 13l6 0" />
                          <path d="M9 17l6 0" />
                        </svg>
                        Project Report
                      </button>
                    )}
                    <a href={WORK_ORDER} download>
                      <button className="btn m-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-file-text"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <path d="M9 9l1 0" />
                          <path d="M9 13l6 0" />
                          <path d="M9 17l6 0" />
                        </svg>
                        Work Order
                      </button>
                    </a>
                  </div>
                  {/* <hr />
                  <a
                    href="mailto:contact@kryzen.com"
                    className="btn btn-outline-primary "
                  >
                    Have Questions? Mail to contact@kryzen.com
                  </a> */}
                </div>
              </div>
              <div className="g-col-lg-8 g-col-md-12 g-col-12 d-flex flex-column p-4 border-start card">
                {timeLines && (
                  <div className="text-center">
                    <iframe
                      className="col-lg-10 col-sm-10 m-auto mb-3"
                      width="100%"
                      src="https://www.youtube.com/embed/AbpAkoQv4CA?si=R6iYORQRj_HL-9C0"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                      style={{ minHeight: "50vh" }}
                    ></iframe>
                    <div className="card w-100 border-none">
                      <div className="card-status-top bg-green"></div>
                      <div className="card-header">
                        <h3 className="card-title">
                          Your Project Development Timeline
                        </h3>
                      </div>
                      <div className="list-group list-group-flush">
                        {timeLines?.taskList?.map((item, index) => {
                          return (
                            <div key={index} className="list-group-item">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={item?.isUpdated}
                                    disabled
                                  />
                                </div>
                                <div className="col text-truncate">
                                  <a
                                    href="#"
                                    disabled
                                    className="cursor-auto text-decoration-none text-reset d-block"
                                  >
                                    {item?.taskName}
                                  </a>
                                  <div className="d-block text-muted text-truncate mt-n1">
                                    {`Completed: ${
                                      item?.completionDate || "N/A"
                                    } by ${item?.responsibleStaff || "N/A"}`}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {!timeLines && (
                  <div className="text-center">
                    <iframe
                      className="col-lg-10 col-sm-10 m-auto"
                      width="100%"
                      src="https://www.youtube.com/embed/AbpAkoQv4CA?si=R6iYORQRj_HL-9C0"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                      style={{ minHeight: "50vh" }}
                    ></iframe>
                    <div className="card w-100 border-none text-center p-4 mt-4">
                      <h2>
                        Your project development either completed or not
                        initiated.
                      </h2>
                      <span className="fs-2">
                        To get any kind of support or query
                      </span>
                      <button
                        onClick={goToFarmSupport}
                        className="btn btn-primary mt-2 align-self-center"
                      >
                        VISIT FARM SUPPORT
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default FarmConstructionAgreement;
