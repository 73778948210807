import React, { useEffect, useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ClientExpenseInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { ClientExpenseSchema } from "../../../Schemas/clients/client_expense_schema";
import { uploadImage } from "../../../CustomComponent/upload_Image";
import { roundOffCost } from "../../../Components/Functions/amt.fun";

const getId = (str) => {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  return parseInt(lastPart, 10);
};

const AddExpense = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);

  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [resStaff, setResStaff] = useState([]);
  const [siteInspections, setSiteInspections] = useState([]);

  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    performRequest("GET", "/employeeManagement/getAllEmployeeManagement")
      .then((res) => setResStaff(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    performRequest("GET", "/poDcPi/getAllVendorList")
      .then((res) => setVendors(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    performRequest("GET", "/siteInspection/getallSiteInspection")
      .then((res) => setSiteInspections(res.data))
      .catch((err) => console.error(err));
  }, []);

  const handelSubmit = (values, actions) => {
    values = {
      ...values,
      generatedBy: userData?.Name,
    };
    setLoading(true);
    performRequest("POST", "/clientExpense/createClientExpense", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Expense created successfully!");
          navigate(`/client-expense-tracking`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Expense Tracking</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Expense</h2>
            </div>

            <Formik
              initialValues={ClientExpenseInfo}
              validationSchema={ClientExpenseSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Project
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="projectSelection">
                          <option value="">Select</option>
                          {projects &&
                            projects?.map((pro) => {
                              return (
                                <option
                                  key={pro.cpNumber}
                                  value={getId(pro.cpNumber)}
                                >
                                  {pro.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>
                        {props.touched.projectSelection &&
                          props.errors.projectSelection && (
                            <p className="text-danger">
                              {props.errors.projectSelection}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Site inspection
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="siteSelection">
                          <option value="">Select</option>
                          {siteInspections &&
                            siteInspections?.map((si) => {
                              return (
                                <option
                                  key={si.siNumber}
                                  value={getId(si.siNumber)}
                                >
                                  {si.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>

                        {props.touched.siteSelection &&
                          props.errors.siteSelection && (
                            <p className="text-danger">
                              {props.errors.siteSelection}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Vendor
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="vendor">
                          <option value="">Select</option>
                          {vendors &&
                            vendors?.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.businessName}
                                </option>
                              );
                            })}
                        </CustomSelect>
                        {props.touched.vendor && props.errors.vendor && (
                          <p className="text-danger">{props.errors.vendor}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Expense date
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="expenseDate" type="date" />
                        {props.touched.expenseDate &&
                          props.errors.expenseDate && (
                            <p className="text-danger">
                              {props.errors.expenseDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Expense type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="expenseType" type="text">
                          <option value="">Select</option>
                          <option value="direct expenses">
                            Direct Expenses
                          </option>
                          <option value="reimbursement">Reimbursement</option>
                        </CustomSelect>

                        {props.touched.expenseType &&
                          props.errors.expenseType && (
                            <p className="text-danger">
                              {props.errors.expenseType}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Expense amount (pre gst)
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="expenseAmount" type="text" />
                        {props.touched.expenseAmount &&
                          props.errors.expenseAmount && (
                            <p className="text-danger">
                              {props.errors.expenseAmount}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        GST Rate
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="gstAmount" type="text">
                          <option value={0}>0%</option>
                          <option value={3}>3%</option>
                          <option value={5}>5%</option>
                          <option value={12}>12%</option>
                          <option value={18}>18%</option>
                          <option value={23}>23%</option>
                        </CustomSelect>
                        {props.touched.gstAmount && props.errors.gstAmount && (
                          <p className="text-danger">
                            {props.errors.gstAmount}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Expense amount (with gst)
                      </label>
                      <div className="w-100 col">
                        {props.values.expenseAmount
                          ? roundOffCost(
                              +props.values.expenseAmount +
                                +props.values.expenseAmount *
                                  +props.values.gstAmount *
                                  0.01
                            )
                          : roundOffCost(0)}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Paid by
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="paidBy" type="text">
                          <option value="">Select</option>
                          {resStaff &&
                            resStaff?.map((staff) => {
                              return (
                                <option
                                  key={staff.id}
                                  value={`${staff?.firstName} ${staff?.lastName}`}
                                >
                                  {`${staff?.firstName} ${staff?.lastName}`}
                                </option>
                              );
                            })}
                        </CustomSelect>
                        {props.touched.paidBy && props.errors.paidBy && (
                          <p className="text-danger">{props.errors.paidBy}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Payment mode
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="paymentMode" type="text">
                          <option value="">Select</option>
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="upi">UPI</option>
                          <option value="imps">IMPS</option>
                          <option value="rtgs">RTGS</option>
                          <option value="neft">NEFT</option>
                        </CustomSelect>
                        {props.touched.paymentMode &&
                          props.errors.paymentMode && (
                            <p className="text-danger">
                              {props.errors.paymentMode}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Upload bill
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="uploadBill"
                          type="file"
                          onChange={(e) =>
                            uploadImage(props, "uploadBill", e.target.files[0])
                          }
                        />
                        {props.touched.uploadBill &&
                          props.errors.uploadBill && (
                            <p className="text-danger">
                              {props.errors.uploadBill}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Notes / Ref.no
                      </label>

                      <div className="w-100 col">
                        <CustomTextArea type="text" name="notes" />

                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Expense" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddExpense;
