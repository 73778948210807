import React from "react";
import { useNavigate } from "react-router-dom";
import { getId } from "../../../Components/Functions/word.fun";

const tableHead = [
  "Sr.",
  "Cl Project",
  "Cl Quotation",
  "Invoice Type",
  "Invoice Date",
  "Total Amount (inc) gst",
  "Business name",
  "Actions",
];
const ClientInvoiceTable = ({ data, currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    id = getId(id);
    navigate(`/client-invoice/view/${id}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const lineItems = item?.lineItems;
            const subtotalPrice = lineItems.reduce(
              (acc, item) => acc + item.itemPrice * item.itemQuantity,
              0
            );
            const totalGst = lineItems.reduce(
              (acc, item) =>
                acc + (item.totalWithGst - item.itemPrice * item.itemQuantity),
              0
            );
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.invoiceNumber}>
                <td>{Index}</td>
                {/* <td>{getId(item?.invoiceNumber)}</td> */}
                <td>{item?.clproject || "N/A"}</td>
                <td>{item?.clquotation || "N/A"}</td>
                <td>{item?.invoiceType || "N/A"}</td>
                <td>{item?.invoiceDate || "N/A"}</td>
                {/* <td>
                  ₹
                  {+subtotalPrice + +totalGst ||
                    item?.totalAmountIncGst?.toFixed(2) ||
                    "N/A"}
                </td> */}
                <td>
                  ₹
                  {(
                    +subtotalPrice + +totalGst || item?.totalAmountIncGst
                  )?.toFixed(2) || "N/A"}
                </td>
                <td>{item?.businessName || "N/A"}</td>
                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.invoiceNumber)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default ClientInvoiceTable;
