import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { useEffect, useState } from "react";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import KryzenTop from "../SI PDF Tables/kryzen_Top";
import SIReport from "../SI PDF Tables/si_report";
import SIDetails from "../SI PDF Tables/si_details";
import PossibleDimentions from "../SI PDF Tables/possible_dimentions";
import Products from "../SI PDF Tables/products";
import ProjectFinancials from "../SI PDF Tables/project_financials";
import { getId } from "../../../Components/Functions/word.fun";
import ProduceTable from "../SI PDF Tables/plants_can_grow";
import SWOT from "../SI PDF Tables/swot";
import Promotion from "../SI PDF Tables/company_promotion";
import FinancialAnalysis from "../SI PDF Tables/financial_analysis";
const PreviewSI = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [allQData, setAllQData] = useState();
  const printPDF = () => {
    let name = "project-report";
    document.title = name;

    window.print();
  };

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/siteInspection/getSiteInspectionById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting SI!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  useEffect(() => {
    data &&
      data.quotationNumber &&
      performRequest(
        "GET",
        `/quotations/getQuotationsById/${getId(data?.quotationNumber)}`
      )
        .then((res) => {
          setLoading(true);
          if (res.status === 200) {
            setAllQData(res.data);
          } else {
            Toast("error", "Error while getting Quotations!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
  }, [data]);

  const goBack = () => {
    window.history.back();
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div
            className="page-header d-print-none"
            style={{ borderBottom: "1px solid #181818", marginBottom: "20px" }}
          >
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title"> Site Inspection</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
              <div className="col-auto ms-auto d-print-none">
                <div className="d-flex">
                  <SimpleButton buttonText={"Save Pdf"} onClick={printPDF} />
                  <button class="btn mx-1 mt-2 btn-primary" onClick={goBack}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-left"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 12l14 0" />
                      <path d="M5 12l6 6" />
                      <path d="M5 12l6 -6" />
                    </svg>
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card-body"
            style={{ color: "black", fontSize: "17px" }}
          >
            {data && allQData && <KryzenTop data={data} allQData={allQData} />}
            {data && allQData && <SIReport allQData={allQData} data={data} />}
            {data && allQData && <SIDetails data={data} allQData={allQData} />}
            <PossibleDimentions />
            <Products />
            {allQData && <ProjectFinancials allQData={allQData} />}
            {data &&
              allQData &&
              allQData?.input_info?.quotationType !== "fanpad" &&
              allQData?.input_info?.quotationType !== "nvph" &&
              allQData?.input_info?.quotationType !== "nethouse" && (
                <ProduceTable allQData={allQData} />
              )}
            {data &&
              allQData &&
              allQData?.input_info?.quotationType !== "fanpad" &&
              allQData?.input_info?.quotationType !== "nvph" &&
              allQData?.input_info?.quotationType !== "nethouse" && (
                <FinancialAnalysis data={data} allQData={allQData} />
              )}

            <SWOT />
            {data && <Promotion data={data} />}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default PreviewSI;
