import React from "react";
import { useSelector } from "react-redux";
import Dashbourd from "../Pages/Dashbourd/dashbourd";
import Footer from "../Components/Footer/footer";
import { BsArrowRightCircleFill } from "react-icons/bs";
const Home = () => {
  const loginState = useSelector((state) => state.login.isLoggedIn);
  return (
    <>
      {loginState && <Dashbourd />}
      {!loginState && (
        <div className="page">
          {!loginState && (
            <header className="navbar navbar-expand-lg ">
              <div className="container-xl">
                <h1 className="navbar-brand  d-none-navbar-horizontal pe-0 pe-md-3">
                  <span className="cursor-pointer">
                    <h1 className="fs-large m-0 p-0">
                      <span className="text-primary">α</span>lpha
                    </h1>
                    <p className="fs-5 ps-6 m-0 text-success">by KRYZEN</p>
                  </span>
                </h1>
                <div className="navbar-nav flex-row order-md-last p-1">
                  <a className="btn px-4" href="/account-login">
                    Login To Portal
                  </a>
                </div>
              </div>
            </header>
          )}
          <div className="container-xxl">
            <div className="page-wrapper">
              <div className="page-header d-print-none">
                <div class="hero text-center pb-0">
                  <div class="container">
                    <div class="hero-gradient my-6">
                      <h1
                        class="hero-title aos-init aos-animate text-uppercase fs-large"
                        data-aos="zoom-y-out"
                      >
                        Develop beautiful farms with Kryzen
                      </h1>
                      <p
                        class="hero-description mt-4 aos-init aos-animate fs-1"
                        data-aos="zoom-y-out"
                        data-aos-delay="150"
                      >
                        Welcome to India's fastest growing commercial hydroponic
                        farm management system
                      </p>
                      <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722179251/serviceskryzen_iwfqji.png" />

                      <div className="row my-4 text-center text-uppercase">
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158664/9_wxz8an.png" />
                          <p>CROP Schedular</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722156157/4_lfuzzr.png" />
                          <p>Agronomy Wikipedia</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158664/8_zt9902.png" />
                          <p>Expence Tracker</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158664/7_rfxg96.png" />
                          <p>Asset Planning</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158663/6_grdz32.png" />
                          <p>Inventory & Stocking</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158667/10_carive.png" />
                          <p>Price Watcher</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158668/11_zlmqmi.png" />
                          <p>Staff & PAYROLL</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158714/12_ftmrgi.png" />
                          <p>Farm Support</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158715/13_wjfoue.png" />
                          <p>Client Planning</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158716/14_pttamx.png" />
                          <p>Seeds Purchase</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158718/15_z6dgkm.png" />
                          <p>Nutrient FORMULA</p>
                        </div>
                        <div className="col-lg-1 col-sm-2">
                          <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722158720/16_cz8bfc.png" />
                          <p>Buyers Connect</p>
                        </div>
                      </div>
                      <span class="status status-blue my-3">
                        <span class="status-dot status-dot-animated"></span>
                        Kryzen Alpha is exclusive kbpl client only platform.
                      </span>

                      {/* <a
                        className="btn btn-primary btn-lg"
                        href="/account-login"
                      >
                        LOGIN TO YOUR ACCOUNT
                      </a>
                      <p className="m-1 fs-3">
                        New to hydroponics? <br />
                        Call us 9870-424-425
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="m-6 p-4" />
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <iframe
                    className=""
                    width="100%"
                    src="https://www.youtube.com/embed/ZUH8kaLNC-Q?si=dgavR8D_SAVEdU8X"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ minHeight: "50vh" }}
                  ></iframe>
                </div>
                <div className="col-md-6">
                  <h1 className="fs-large my-6">
                    WE ROCKED THE STAGE OF <br />
                    <strong> SHARK TANK INDIA</strong>
                  </h1>
                  <p className="fs-1 my-5">
                    Learn to build commercially profitable hydroponic farm at
                    any location and manage it from anywhere in the world.
                  </p>
                  <p className="fs-1 my-5">
                    Automate your farm. Experience improved yields and lower
                    costs.
                  </p>
                  <a href="https://kryzen.com/request-quote/">
                    <button className="btn btn-lg btn-primary px-5">
                      <BsArrowRightCircleFill className="me-2" /> LEARN MORE
                    </button>
                  </a>
                </div>
              </div>
              <hr className="m-3 p-4" />
              <h1 className="my-2 text-center text-uppercase">
                Why Optin for Alpha?
              </h1>
              <div className="row  text-center">
                <div className="col-md-3">
                  <div className="card p-4 m-2">
                    <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722156155/1_imoh5g.png" />
                    <h3 className="my-2 text-uppercase">We are Tech First</h3>
                    <p>
                      We are seamlessly blending technology and farming
                      together. Making it easy for modern age adoption.
                    </p>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card p-4 m-2">
                    <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722156155/2_npqtw0.png" />
                    <h3 className="my-2 text-uppercase">
                      We are farmers First
                    </h3>
                    <p>
                      All things we build are from our own experience and
                      requirements. No useless stuff; things that we use
                      ourselves.
                    </p>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card p-4 m-2">
                    <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722156156/3_exlvdj.png" />
                    <h3 className="my-2 text-uppercase">
                      We are community first
                    </h3>
                    <p>
                      We are group of people reshaping the modern agriculture.
                      And everyone (including you) is part of community we have
                      built.
                    </p>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card p-4 m-2">
                    <img src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722156157/4_lfuzzr.png" />
                    <h3 className="my-2 text-uppercase">
                      We are innovator first
                    </h3>
                    <p>
                      We build things not only for today but for tommorrow as
                      well. Innovation is core of kryzen and everything we build
                      in it.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-6 p-4" />
              <h1 className="my-2 text-uppercase text-center">
                What you experience inside?
              </h1>
              <div className="row text-center">
                <div className="col-md-6">
                  <div className="card p-4 m-2">
                    <img
                      src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722150754/kryzen-nursary-management_hzxqiz.png"
                      className="card card-stacked"
                    />
                    <h2 className="my-2">Manage Your Farm</h2>
                    <p>
                      From batch planning to nursery management, alpha helps you
                      manage your day to day farm activity very effectivly.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card p-4 m-2">
                    <img
                      src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722150755/kryzen-nursary-planning_yggakg.png"
                      className="card card-stacked"
                    />
                    <h2 className="my-2">Manage Your Nursary</h2>
                    <p>
                      Don't just sow seeds, track there schedule and be on top
                      of the progress. Nursery planner gives you complete
                      schedule.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card p-4  m-2">
                    <img
                      src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722150754/hydroponic-nutrient-system_bxh8ho.png"
                      className="card card-stacked"
                    />
                    <h2 className="my-2">Accurate Nutrient Calculation</h2>
                    <p>
                      Don't overdo it or underdo it. Hydroponic nutrint
                      calculator will help you identify exact nutrients your
                      farm need.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card p-4 m-2">
                    <img
                      src="https://res.cloudinary.com/dph1m5tq7/image/upload/v1722150754/hydroponic-water-qwality_x0mz7e.png"
                      className="card card-stacked"
                    />
                    <h2 className="my-2">Track Farm Water</h2>
                    <p>
                      Let it be nutrients or PH of water. Tracking the changes
                      from source to what is available to plants made easy with
                      water tracking system.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-3 p-4" />
              <div className="row">
                <div className="col-md-12 my-8 text-center">
                  <img src="https://d502jbuhuh9wk.cloudfront.net/orgData/65dc4780e4b0b60e6be0bdc1/pages/assets/images/X2gZhbackedbyindustryleaders.png" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <p className="fw-bold">Climate Management</p>
                  <p>
                    IoT based micro climate analysis and auto management system
                    makes it easier to grow any plant anywhere with Kryzen farm
                    maangement system.
                  </p>
                </div>
                <div className="col-md-3 col-sm-12">
                  <p className="fw-bold">Fertigation Management</p>
                  <p>
                    With precise pH & EC calculation in realtime integration of
                    hydroBUILD & Alpha makes nutrient or fertigation management
                    seamlessly easy.
                  </p>
                </div>
                <div className="col-md-3 col-sm-12">
                  <p className="fw-bold">Agronomy Management</p>
                  <p>
                    Not just plant wikipedia but stay connected with agronomist
                    all the time, grow what you love and always have dedicated
                    support from the expert of the domain.
                  </p>
                </div>
                <div className="col-md-3 col-sm-12">
                  <p className="fw-bold">Everything Secure</p>
                  <p>
                    All your data and plans, secure with us. We use state of the
                    art encryption and data management services. So all your
                    research and project data is always safe with us.
                  </p>
                </div>
              </div>
              <hr className="m-3 p-4" />{" "}
              <h1 className="my-2 text-center text-uppercase">
                We are available at all this locations
              </h1>
              <div className="row">
                <div className="col-md-2 col-sm-6">
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Port Blair{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Adoni{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Amaravati{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Anantapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chandragiri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chittoor{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dowlaiswaram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Eluru{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Guntur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kadapa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kakinada{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kurnool{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Machilipatnam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nagarjunakoṇḍa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rajahmundry{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Srikakulam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tirupati{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Vijayawada{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Visakhapatnam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Vizianagaram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Yemmiganur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Itanagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dhuburi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dibrugarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dispur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Guwahati{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jorhat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nagaon{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sivasagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Silchar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tezpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tinsukia{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bihar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Barauni{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Begusarai{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bettiah{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhagalpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bihar Sharif{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bodh Gaya{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Buxar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chapra{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Darbhanga{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dehri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dinapur Nizamat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gaya{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hajipur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jamalpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Katihar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Madhubani{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Motihari{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Munger{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Muzaffarpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Patna{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Purnia{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pusa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Saharsa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Samastipur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sasaram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sitamarhi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Siwan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chandigarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chhattisgarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ambikapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhilai{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bilaspur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dhamtari{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Durg{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jagdalpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Raipur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rajnandgaon{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Daman{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Diu{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Silvassa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Delhi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Goa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Madgaon{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Panaji{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gujarat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ahmadabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Amreli{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bharuch{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhavnagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhuj{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dwarka{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gandhinagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Godhra{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jamnagar{" "}
                    </a>
                  </p>
                </div>
                <div className="col-md-2 col-sm-6">
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Junagadh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kandla{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Khambhat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kheda{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mahesana{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Morbi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nadiad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Navsari{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Okha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Palanpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Patan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Porbandar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rajkot{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Surat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Surendranagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Valsad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Veraval{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Haryana{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ambala{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhiwani{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chandigarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Faridabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Firozpur Jhirka{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gurugram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hansi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hisar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jind{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kaithal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Karnal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kurukshetra{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Panipat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pehowa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rewari{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rohtak{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sirsa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sonipat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bilaspur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chamba{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dalhousie{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dharmshala{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hamirpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kangra{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kullu{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mandi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nahan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shimla{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Una{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Anantnag{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Baramula{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Doda{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gulmarg{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jammu{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kathua{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Punch{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rajouri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Srinagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Udhampur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jharkhand{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bokaro{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chaibasa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Deoghar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dhanbad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dumka{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Giridih{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hazaribag{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jamshedpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jharia{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rajmahal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ranchi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Saraikela{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Badami{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ballari{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bengaluru{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Belagavi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhadravati{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bidar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chikkamagaluru{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chitradurga{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Davangere{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Halebid{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hassan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hubballi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dharwad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kalaburagi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kolar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Madikeri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mandya{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mangaluru{" "}
                    </a>
                  </p>
                </div>
                <div className="col-md-2 col-sm-6">
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mysuru{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Raichur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shivamogga{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shravanabelagola{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shrirangapattana{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tumakuru{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Vijayapura{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Alappuzha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Vatakara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Idukki{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kannur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kochi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kollam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kottayam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kozhikode{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mattancheri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Palakkad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Thalassery{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Thiruvananthapuram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Thrissur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ladakh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Leh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Balaghat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Barwani{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Betul{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bharhut{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhind{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhojpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhopal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Burhanpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chhatarpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chhindwara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Damoh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Datia{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dewas{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dhar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Guna{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gwalior{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hoshangabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Indore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Itarsi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jabalpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jhabua{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Khajuraho{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Khandwa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Khargone{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Maheshwar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mandla{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mandsaur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Morena{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Murwara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Narsimhapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Narsinghgarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Narwar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Neemuch{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nowgong{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Orchha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Panna{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Raisen{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rajgarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ratlam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rewa{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sarangpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Satna{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sehore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Seoni{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shahdol{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shajapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sheopur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shivpuri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ujjain{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Vidisha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ahmadnagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Akola{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Amravati{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Aurangabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhandara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhusawal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bid{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Buldhana{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chandrapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Daulatabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dhule{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jalgaon{" "}
                    </a>
                  </p>
                </div>
                <div className="col-md-2 col-sm-6">
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kalyan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Karli{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kolhapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mahabaleshwar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Malegaon{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Matheran{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mumbai{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nagpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nanded{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nashik{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Osmanabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pandharpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Parbhani{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pune{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ratnagiri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sangli{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Satara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sevagram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Solapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Thane{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ulhasnagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Vasai-Virar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Wardha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Yavatmal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Manipur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Imphal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Meghalaya{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Cherrapunji{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shillong{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mizoram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Aizawl{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Lunglei{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nagaland{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kohima{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mon{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Phek{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Wokha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Zunheboto{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Odisha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Balangir{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Baleshwar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Baripada{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhubaneshwar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Brahmapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Cuttack{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dhenkanal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kendujhar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Konark{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Koraput{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Paradip{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Phulabani{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Puri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sambalpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Udayagiri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Puducherry{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Karaikal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mahe{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Puducherry{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Yanam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Amritsar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Batala{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chandigarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Faridkot{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Firozpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gurdaspur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hoshiarpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jalandhar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kapurthala{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ludhiana{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nabha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Patiala{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rupnagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sangrur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rajasthan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Abu{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ajmer{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Alwar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Amer{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Barmer{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Beawar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bharatpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhilwara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bikaner{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bundi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chittaurgarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Churu{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dhaulpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dungarpur{" "}
                    </a>
                  </p>
                </div>
                <div className="col-md-2 col-sm-6">
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ganganagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hanumangarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jaipur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jaisalmer{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jalor{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jhalawar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jhunjhunu{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jodhpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kishangarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kota{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Merta{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nagaur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nathdwara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pali{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Phalodi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pushkar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sawai Madhopur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shahpura{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sikar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sirohi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tonk{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Udaipur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sikkim{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gangtok{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gyalshing{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Lachung{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mangan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Arcot{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chengalpattu{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chennai{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chidambaram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Coimbatore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Cuddalore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dharmapuri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dindigul{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Erode{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kanchipuram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kanniyakumari{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kodaikanal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kumbakonam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Madurai{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mamallapuram{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nagappattinam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nagercoil{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Palayamkottai{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pudukkottai{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rajapalayam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Salem{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Thanjavur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tiruchchirappalli{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tirunelveli{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tiruppur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Thoothukudi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Udhagamandalam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Vellore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Telangana{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hyderabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Karimnagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Khammam{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mahbubnagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nizamabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sangareddi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Warangal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tripura{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Agartala{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Agra{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Aligarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Amroha{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ayodhya{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Azamgarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bahraich{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ballia{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Banda{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bara Banki{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bareilly{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Basti{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bijnor{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bithur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Budaun{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bulandshahr{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Deoria{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Etah{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Etawah{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Faizabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Farrukhabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Fatehgarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Fatehpur{" "}
                    </a>
                  </p>
                </div>
                <div className="col-md-2 col-sm-6">
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ghaziabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ghazipur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gonda{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Gorakhpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hamirpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hardoi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hathras{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jalaun{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jaunpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jhansi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kannauj{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kanpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Lakhimpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Lalitpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Lucknow{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mainpuri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mathura{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Meerut{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mirzapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Vindhyachal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Moradabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Muzaffarnagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Partapgarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pilibhit{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Prayagraj{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rae Bareli{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Rampur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Saharanpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sambhal{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shahjahanpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sitapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Sultanpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tehri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Varanasi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Almora{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dehra Dun{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Haridwar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Mussoorie{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nainital{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Pithoragarh{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Alipore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Alipur Duar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Asansol{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Baharampur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bally{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Balurghat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bankura{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Baranagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Barasat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Barrackpore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Basirhat{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bhatpara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Bishnupur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Budge Budge{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Burdwan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Chandernagore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Darjeeling{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Diamond Harbour{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Dum Dum{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Durgapur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Halisahar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Haora{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Hugli{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Ingraj Bazar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Jalpaiguri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kalimpong{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kamarhati{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kanchrapara{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kharagpur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Cooch Behar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Kolkata{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Krishnanagar{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Malda{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Midnapore{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Murshidabad{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Nabadwip{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Palashi{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Panihati{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Purulia{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Raiganj{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Santipur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shantiniketan{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Shrirampur{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Siliguri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Siuri{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Tamluk{" "}
                    </a>
                  </p>
                  <p className="m-0 p-0">
                    <a href="https://kryzen.com/request-quote/">
                      {" "}
                      Hydroponic in Titagarh{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Home;

// import axios from "axios";
// import React, { useEffect, useState } from "react";

// function Home() {
//   const [signedUrl, setSignedUrl] = useState("");

//   const fetchSignedUrl = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;
//     try {
//       const response = await axios.post(
//         "https://alpha-dev-cvpmke743q-el.a.run.app/fileUploads/fileUpload/",
//         { fileName: file.name }
//       );

//       if (response?.data?.url) {
//         let data = await axios.put(response?.data.url, file, {
//           headers: {
//             "Content-Type": file.type,
//           },
//         });
//       }
//     } catch (error) {
//       console.error("Error fetching signed URL:", error);
//     }
//   };

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const response = await axios.get(
//           "https://alpha-dev-cvpmke743q-el.a.run.app/fileUploads/getUploadedFile?filename=Kryzen Application Development Assignment.pdf"
//         );
//         console.log(response);
//       } catch (error) {
//         console.error("Error fetching signed URL:", error);
//       }
//     }
//     fetchData();
//   }, []);

//   return (
//     <div>
//       <input type="file" onChange={fetchSignedUrl} />
//     </div>
//   );
// }

// export default Home;
