import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { extractDate } from "../../../Components/Functions/date.fun";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { LeadInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import SimpleButton from "../../../Components/layouts/simple_button";
const tableHead = ["Date", "full name", "origin", "mobile Number", "Actions"];
function checkDateStatus(dateString) {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const differenceMilliseconds = currentDate - inputDate;
  const differenceDays = differenceMilliseconds / (1000 * 3600 * 24);

  if (differenceDays <= 1) {
    return "superhot";
  } else if (differenceDays > 1 && differenceDays <= 4) {
    return "hot";
  } else if (differenceDays > 4 && differenceDays <= 7) {
    return "warmer";
  } else if (differenceDays > 7 && differenceDays <= 10) {
    return "warm";
  } else if (differenceDays > 10 && differenceDays <= 13) {
    return "colder";
  } else {
    return "cold";
  }
}
function getStatus(item) {
  if (
    item?.createdAt &&
    checkDateStatus(extractDate(item.createdAt)) === "superhot" &&
    item.interestLevel === "High"
  ) {
    return "superhot +1";
  } else if (
    item?.createdAt &&
    checkDateStatus(extractDate(item.createdAt)) === "hot" &&
    item.interestLevel === "High"
  ) {
    return "hot +1";
  } else if (
    item?.createdAt &&
    checkDateStatus(extractDate(item.createdAt)) === "warmer" &&
    item.interestLevel === "High"
  ) {
    return "warmer +1";
  } else if (
    item?.createdAt &&
    checkDateStatus(extractDate(item.createdAt)) === "warm" &&
    item.interestLevel === "High"
  ) {
    return "warm +1";
  } else {
    return item?.createdAt && checkDateStatus(extractDate(item.createdAt));
  }
}
const getClassByDate = (dateString) => {
  if (checkDateStatus(dateString) === "superhot") {
    return "badge bg-teal";
  } else if (checkDateStatus(dateString) === "hot") {
    return "badge bg-green";
  } else if (checkDateStatus(dateString) === "warmer") {
    return "badge bg-lime";
  } else if (checkDateStatus(dateString) === "warm") {
    return "badge bg-yellow";
  } else if (checkDateStatus(dateString) === "colder") {
    return "badge bg-orange";
  } else {
    return "badge bg-red";
  }
};

const getBackgroundStyleByDate = (dateString) => {
  let style = {};
  if (checkDateStatus(dateString) === "superhot") {
    style = {
      backgroundColor: "rgb(12, 166, 120,0.1)",
    };
    return style;
  } else if (checkDateStatus(dateString) === "hot") {
    style = {
      backgroundColor: "rgba(0,255,0,0.1)",
    };
    return style;
  } else if (checkDateStatus(dateString) === "warmer") {
    style = {
      backgroundColor: "rgb(116, 184, 22,0.1)",
    };
    return style;
  } else if (checkDateStatus(dateString) === "warm") {
    style = {
      backgroundColor: "rgb(245, 159, 0,0.1)",
    };
    return style;
  } else if (checkDateStatus(dateString) === "colder") {
    style = {
      backgroundColor: "rgb(247, 103, 7,0.1)",
    };
    return style;
  } else {
    style = {
      backgroundColor: "rgba(255,0,0,0.1)",
    };
    return style;
  }
};
const getName = (lead) => {
  if (lead.fullName) {
    return lead.fullName;
  }
  if (lead.firstName && lead.lastName) {
    return `${lead.firstName} ${lead.lastName}`;
  }
  if (lead.firstName) {
    return lead.firstName;
  }
  if (lead.lastName) {
    return lead.lastName;
  }
  return "N/A";
};
const EnquiryTable = ({ leads = [] }) => {
  const store = useSelector((store) => store.login.user);
  const navigate = useNavigate();
  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);
  const AddAndAssignLead = (values) => {
    let lead = {
      ...LeadInfo,
      first_name: values.firstName || values.fullName || "",
      last_name: values.lastName || "",
      interestedIn: [],
      full_name: getName(values),
      services: "",
      planned_area: "",
      location_type: "",
      company_name: values.businessName || "",
      lead_genrated_from: "enquiry",
      lead_alloted_to: store.Name || "",
      preferred_contact_method: "",
      phone_number: values.mobileNumber || values?.whatsappNumber || "",
      lead_status: "",
      email: values.email || "",
      gst_number: values.gst_number || "",
      address: values.address1 || values.address2 || "",
      notes: values.notes || "",
    };

    setLoading(true);
    performRequest("POST", "/leads/createleads", lead)
      .then((res) => {
        if (res.status === 201) {
          setTimeout(() => {
            removeFromList(values.id);
          }, 500);
          setTimeout(() => {
            Toast("success", "Leads generated successfully!");
            navigate(`/leads/edit-lead/${res.data.id}`);
          }, 1000);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  const removeFromList = (id) => {
    performRequest("DELETE", `/enquiryWebhook/moveEnquiry/${id}`)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Lead removed from enquiry list!");
        } else {
          Toast("error", "Error while Deleting");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  const handleCall = (phoneNumber) => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert("Phone number is not available!");
    }
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {leads?.map((item, index) => {
            return (
              <tr
                key={item?.id}
                style={
                  item?.createdAt &&
                  getBackgroundStyleByDate(extractDate(item?.createdAt))
                }
              >
                <td>
                  {(item?.createdAt && extractDate(item?.createdAt)) || "N/A"}
                </td>
                <td style={{ maxWidth: "200px" }}>{getName(item)}</td>
                <td>{item?.origin || "N/A"}</td>
                <td>{item?.mobileNumber || "N/A"}</td>

                <td colSpan="2">
                  <button
                    style={{ width: "110px", height: "30px" }}
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-report"
                    onClick={() => setModalData(item)}
                  >
                    Assign To Me
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        class="modal modal-blur fade"
        id="modal-report"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Filter lead</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {modalData && (
                <>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Full name</label>
                    <div className="w-100 col">
                      {(
                        modalData?.fullName ||
                        modalData?.firstName ||
                        "N/A"
                      ).toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Address</label>
                    <div className="w-100 col">
                      {(
                        modalData.address1 ||
                        modalData.address2 ||
                        "N/A"
                      ).toUpperCase()}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Email Address
                    </label>
                    <div className="w-100 col">{modalData?.email || "N/A"}</div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Phone Number
                    </label>
                    <div className="w-100 col">
                      {modalData?.mobileNumber || modalData?.whatsappNumber ? (
                        <span
                          onClick={() =>
                            handleCall(
                              modalData?.mobileNumber ||
                                modalData?.whatsappNumber
                            )
                          }
                          className="cursor-pointer text-blue text-decoration-underline"
                        >
                          <svg
                            color="green"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-phone-outgoing"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                            <path d="M15 9l5 -5" />
                            <path d="M16 4l4 0l0 4" />
                          </svg>

                          {modalData?.mobileNumber || modalData?.whatsappNumber}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div className="mb-3 row d-lg-none d-md-none">
                    <label className="w-25 form-label col-3">Call Now</label>
                    <div className="w-100 col">
                      <SimpleButton buttonText="Call Now" />
                    </div>
                  </div> */}
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Lead Generated From
                    </label>
                    <div className="w-100 col">
                      {(modalData?.origin || "N/A").toUpperCase()}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div class="modal-footer">
              <div>
                <SimpleButton
                  buttonText="Not Connected"
                  type="secondary"
                  data-bs-dismiss="modal"
                />
              </div>

              <div class="ms-auto">
                <SimpleButton
                  onClick={() => AddAndAssignLead(modalData)}
                  buttonText="Call Connected"
                  data-bs-dismiss="modal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryTable;
