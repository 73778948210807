import React from "react";
import { useNavigate } from "react-router-dom";

import { BsArrowRightShort } from "react-icons/bs";

const tableHead = [
  "Sr.",
  "type",
  "factor",
  "0-190",
  "191-440",
  "441-940",
  "941-1940",
  "1941-3940",
  "3941-5940",
  "5941-7940",
  "7941+",
  "weedmat",
  "markup",
  "Actions",
];

const QutationMatrixTable = ({ data }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/standard-quotation/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return (
              <tr key={item?.id}>
                <td>{++index}</td>
                <td>{item?.type || "N/A"}</td>
                <td>{item?.factor || "N/A"}</td>
                <td>{item?.basePrice1to190 || "N/A"}</td>
                <td>{item?.basePrice191to450 || "N/A"}</td>
                <td>{item?.basePrice451to950 || "N/A"}</td>
                <td>{item?.basePrice951to1860 || "N/A"}</td>
                <td>{item?.basePrice1861to3720 || "N/A"}</td>
                <td>{item?.basePrice3721to5999 || "N/A"}</td>
                <td>{item?.basePrice6000to7999 || "N/A"}</td>
                <td>{item?.basePrice8000Plus || "N/A"}</td>
                <td>{item?.sqMtrWeed || "N/A"}</td>
                <td>{item?.markupPercentGP || "N/A"}</td>

                <td>
                  <button
                    className="btn btn-primary btn-sm  px-3"
                    onClick={() => goToView(item?.id)}
                  >
                    <BsArrowRightShort className="me-1" /> View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default QutationMatrixTable;
