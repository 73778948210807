import React, { useEffect, useState } from "react";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import KryzenTop from "../../Quatation/PdfQuotationsTables/kryzen_Top";
import CompanyDescription from "../../Quatation/PdfQuotationsTables/company_description";
import UserDetails from "../../Quatation/PdfQuotationsTables/user_details";
import FanPadSpecifications from "../../Quatation/PdfQuotationsTables/fan_pad_specifications";
import NetHouseSpecifications from "../../Quatation/PdfQuotationsTables/net_house_specifications";
import NVPHSpecifications from "../../Quatation/PdfQuotationsTables/nvph_specifications";
import FlatBedSetup from "../../Quatation/PdfQuotationsTables/flat_bed_setup";
import AFrameSetup from "../../Quatation/PdfQuotationsTables/a_frame_setup";
import GrowBagSetup from "../../Quatation/PdfQuotationsTables/grow_bag_setup";
import DutchBucketSetup from "../../Quatation/PdfQuotationsTables/dutch_bucket_setup";
import WaterStorage_Handling from "../../Quatation/PdfQuotationsTables/water_storage_and_handling";
import ElectricFittings from "../../Quatation/PdfQuotationsTables/electric_fittings";
import EmployeeTrainingProgramme from "../../Quatation/PdfQuotationsTables/employee_training_programme";
import AgronomySupport from "../../Quatation/PdfQuotationsTables/agronomy_support";
import TrackingOfProgress from "../../Quatation/PdfQuotationsTables/tracking_of_progress";
import ScopeOfWork from "../../Quatation/PdfQuotationsTables/scope_of_work";
import CustomerResponsibility from "../../Quatation/PdfQuotationsTables/customer_responsibility";
import CivilMaterials from "../../Quatation/PdfQuotationsTables/civil_materials";
import SoftwareServices from "../../Quatation/PdfQuotationsTables/software_services";
import MaterialBreakdown from "../../Quatation/PdfQuotationsTables/material_breakdown";
import MaterialBreakdownCEA from "../../Quatation/PdfQuotationsTables/material_breakdown_CEA";
import WorkOrderLocations from "../../Quatation/PdfQuotationsTables/work_order_locations";
import WarrentyOnMaterial from "../../Quatation/PdfQuotationsTables/warrenty_on_material";
import CompanyPromotion from "../../Quatation/PdfQuotationsTables/company_promotion";
import ProjectFinancials from "../../Quatation/PdfQuotationsTables/project_financials";
import ProduceBreakevenTable from "../../Quatation/PdfQuotationsTables/produce_breakeven_table";
import QuotationAmounts from "../../Quatation/PdfQuotationsTables/quotation_amounts";
import PaymentBreakdown from "../../Quatation/PdfQuotationsTables/payment_breakdown";
import PaymentDetails from "../../Quatation/PdfQuotationsTables/payment_details";
import Footer from "../../../Components/Footer/footer";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import LoadingPage from "../../../Components/layouts/loading";
import { useLocation, useNavigate, useParams } from "react-router";
import ButtonWithSVG from "../../../Components/layouts/button_with_svg";
import { FcSurvey } from "react-icons/fc";

const Quotation = () => {
  const { id } = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [allQData, setAllQData] = useState([]);
  const getQuotationFor = () => {
    let bag = "";

    if (allQData?.input_info?.quotationType == "fanpad") {
      bag += "Fanpad";
    } else if (allQData?.input_info?.quotationType == "nvph") {
      bag += "Nvph";
    } else if (allQData?.input_info?.quotationType == "nethouse") {
      bag += "Nethouse";
    } else if (
      allQData?.input_info?.quotationType == "fanpad with hydroponics"
    ) {
      bag += "Fanpad";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += `+(${allQData?.input_info?.a_FramePercent})A-Frame`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += `+(${allQData?.input_info?.flatBedPercent})Flatbed`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += `+(${allQData?.input_info?.growBagPercent})Growbag`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += `+(${allQData?.input_info?.dutchBucketPercent})Dutchbucket`;
      }
      bag += `${next}`;
    } else if (
      allQData?.input_info?.quotationType == "nethouse with hydroponics"
    ) {
      bag += "Nethouse";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += `+(${allQData?.input_info?.a_FramePercent})A-Frame`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += `+(${allQData?.input_info?.flatBedPercent})Flatbed`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += `+(${allQData?.input_info?.growBagPercent})Growbag`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += `+(${allQData?.input_info?.dutchBucketPercent})Dutchbucket`;
      }
      bag += `${next}`;
    } else if (allQData?.input_info?.quotationType == "nvph with hydroponics") {
      bag += "Nvph";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += `+(${allQData?.input_info?.a_FramePercent})A-Frame`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += `+(${allQData?.input_info?.flatBedPercent})Flatbed`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += `+(${allQData?.input_info?.growBagPercent})Growbag`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += `+(${allQData?.input_info?.dutchBucketPercent})Dutchbucket`;
      }
      bag += `${next}`;
    } else if (allQData?.input_info?.quotationType == "hydroponics only") {
      bag += "WithoutCEA";
      let next = "";
      if (allQData?.input_info?.a_FramePercent > 0) {
        next += `+(${allQData?.input_info?.a_FramePercent})A-Frame`;
      }
      if (allQData?.input_info?.flatBedPercent > 0) {
        next += `+(${allQData?.input_info?.flatBedPercent})Flatbed`;
      }
      if (allQData?.input_info?.growBagPercent > 0) {
        next += `+(${allQData?.input_info?.growBagPercent})Growbag`;
      }
      if (allQData?.input_info?.dutchBucketPercent > 0) {
        next += `+(${allQData?.input_info?.dutchBucketPercent})Dutchbucket`;
      }
      bag += `${next}`;
    }

    return bag;
  };
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    let id = searchParams.get("id");
    if (id) {
      setLoading(true);
      performRequest("GET", `/quotations/getQuotationsById/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setdata(res.data?.input_info);
            setAllQData(res.data);
          } else {
            Toast("error", "Error while getting Quotations!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [location]);
  function QuotationNumber() {
    let idString = id?.toString();
    let zerosNeeded = 4 - idString?.length;
    let paddedId = "0".repeat(zerosNeeded) + idString;
    return paddedId;
  }
  function GetArea() {
    let area = data?.bay_size * data?.span_size;
    return area;
  }

  function GetArea() {
    let area = data?.bay_size * data?.span_size;
    return area;
  }

  function QuotationNumber() {
    let idString = id?.toString();
    let zerosNeeded = 4 - idString?.length;
    let paddedId = "0".repeat(zerosNeeded) + idString;
    return paddedId;
  }
  function joinWithDash(inputString) {
    var wordsArray = inputString.split(" ");
    var joinedString = wordsArray.join("-");
    return joinedString;
  }
  const printPDF = () => {
    let name = joinWithDash(
      `${QuotationNumber()}-${GetArea()}SqM-${
        data?.businessName || data?.contactPersonName
      }-${getQuotationFor()}.pdf`
    );
    document.title = name;
    window.print();
  };
  const goBack = () => {
    navigate("/farm-construction");
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">Custom Quotations</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>

              <div className="col-auto ms-auto d-print-none">
                <div className="d-flex">
                  <SimpleButton buttonText={"Save Pdf"} onClick={printPDF} />

                  <button class="btn mx-1 mt-2 btn-primary" onClick={goBack}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-left"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 12l14 0" />
                      <path d="M5 12l6 6" />
                      <path d="M5 12l6 -6" />
                    </svg>
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 py-2">
              <div className="card row p-2">
                <div className="col-md-12 col-sm-12">
                  <div className="card-body" id="pdf-content">
                    <KryzenTop />
                    {data && (
                      <CompanyDescription
                        allQData={allQData}
                        businessName={
                          data?.businessName || data?.contactPersonName
                        }
                        address={data?.address}
                        data={data}
                        quotationType={data?.quotationType}
                      />
                    )}
                    {data && (
                      <UserDetails userInfo={data} area={allQData?.area_in} />
                    )}
                    {data && data?.quotationType === "fanpad" && (
                      <FanPadSpecifications />
                    )}

                    {data &&
                      data?.quotationType === "fanpad with hydroponics" && (
                        <FanPadSpecifications />
                      )}
                    {data && data?.quotationType === "nethouse" && (
                      <NetHouseSpecifications />
                    )}
                    {data &&
                      data?.quotationType === "nethouse with hydroponics" && (
                        <NetHouseSpecifications />
                      )}
                    {data && data?.quotationType === "nvph" && (
                      <NVPHSpecifications />
                    )}
                    {data &&
                      data?.quotationType === "nvph with hydroponics" && (
                        <NVPHSpecifications />
                      )}
                    {/* percent wise setup */}
                    {data?.quotationType !== "fanpad" &&
                      data?.quotationType !== "nvph" &&
                      data?.quotationType !== "nethouse" && (
                        <>
                          {data && data?.flatBedPercent > 0 && <FlatBedSetup />}
                          {data && data?.a_FramePercent > 0 && <AFrameSetup />}
                          {data && data?.growBagPercent > 0 && <GrowBagSetup />}
                          {data && data?.dutchBucketPercent > 0 && (
                            <DutchBucketSetup />
                          )}
                          <div
                            className="html2pdf__page-break"
                            style={{
                              pageBreakAfter: "always",
                              display: "block",
                              clear: "both",
                            }}
                          ></div>
                        </>
                      )}

                    {data && (
                      <WaterStorage_Handling
                        quotationType={data?.quotationType}
                      />
                    )}
                    {data && <ElectricFittings data={data} />}
                    {data?.quotationType !== "fanpad" &&
                      data?.quotationType !== "nvph" &&
                      data?.quotationType !== "nethouse" && (
                        <>
                          {" "}
                          <EmployeeTrainingProgramme />
                          <AgronomySupport />
                        </>
                      )}
                    <TrackingOfProgress />

                    {data && (
                      <ScopeOfWork quotationType={data?.quotationType} />
                    )}
                    <CustomerResponsibility />
                    <CivilMaterials />
                    {/* working panding */}
                    {/* <AfterSalesServices_AMC /> */}
                    {data?.quotationType !== "fanpad" &&
                      data?.quotationType !== "nvph" &&
                      data?.quotationType !== "nethouse" && (
                        <SoftwareServices />
                      )}
                    {data &&
                      data?.quotationType !== "hydroponics only" &&
                      data?.quotationType !== "fanpad" &&
                      data?.quotationType !== "nvph" &&
                      data?.quotationType !== "nethouse" && (
                        <MaterialBreakdown />
                      )}
                    {(data?.quotationType == "fanpad" ||
                      data?.quotationType == "nvph" ||
                      data?.quotationType == "nethouse") && (
                      <MaterialBreakdownCEA />
                    )}
                    {data && (
                      <WorkOrderLocations quotationType={data?.quotationType} />
                    )}
                    <WarrentyOnMaterial />
                    <CompanyPromotion />
                    {data && data?.showProjectFinancials && (
                      <>
                        {" "}
                        {data && data?.showProjectFinancials && allQData && (
                          <ProjectFinancials allQData={allQData} />
                        )}
                        {allQData &&
                          allQData?.plant_grow?.updatedPlantsList && (
                            <ProduceBreakevenTable allQData={allQData} />
                          )}
                        <div
                          className="html2pdf__page-break"
                          style={{
                            pageBreakAfter: "always",
                            display: "block",
                            clear: "both",
                          }}
                        ></div>
                      </>
                    )}

                    <KryzenTop />
                    {allQData && <QuotationAmounts allQData={allQData} />}
                    {allQData && <PaymentBreakdown allQData={allQData} />}
                    {data && (
                      <PaymentDetails finalQuotation={data?.finalQuotation} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Quotation;
