import React from "react";

const PossibleDimentions = () => {
  return (
    <div>
      <style>
        {`
          .dimension-setup-table tr td {
            text-align: center;
          }

          .dimension-setup-table tr td.selected-cell {
            background-color: rgb(12, 166, 120);
            color: white;
            font-weight: bold;
          }
        `}
      </style>
      <h3 className="table-heading">Possible Dimensions of Setup (mtr):</h3>

      {/* <style>
      table.dimension-setup-table tr td {
        text-align: center;
      }
    
      table.dimension-setup-table tr td.selected-cell {
        background-color: rgb(12, 166, 120);
        color: white;
        font-weight: bold;
      }
    </style> */}

      <table style={{ width: "100%" }} className="dimension-setup-table">
        <thead>
          <tr>
            <th title="Field #1">Length</th>
            <th title="Field #2">X Width</th>
            <th title="Field #2">X Width</th>
            <th title="Field #2">X Width</th>
            <th title="Field #2">X Width</th>
            <th title="Field #2">X Width</th>
            <th title="Field #2">X Width</th>
            <th title="Field #2">X Width</th>
            <th title="Field #2">X Width</th>
            <th title="Field #2">X Width</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="selected-cell">20mtr X</td>
            <td>20mtr</td>
            <td>24mtr</td>
            <td className="selected-cell">28mtr</td>
            <td>32mtr</td>
            <td>36mtr</td>
            <td>40mtr</td>
            <td>44mtr</td>
            <td>48mtr</td>
            <td className="selected-cell">52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">28mtr X</td>
            <td className="selected-cell">20mtr</td>
            <td>24mtr</td>
            <td>28mtr</td>
            <td>32mtr</td>
            <td className="selected-cell">36mtr</td>
            <td>40mtr</td>
            <td>44mtr</td>
            <td>48mtr</td>
            <td>52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">36mtr X</td>
            <td>20mtr</td>
            <td>24mtr</td>
            <td className="selected-cell">28mtr</td>
            <td>32mtr</td>
            <td>36mtr</td>
            <td>40mtr</td>
            <td className="selected-cell">44mtr</td>
            <td>48mtr</td>
            <td>52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">44mtr X</td>
            <td>20mtr</td>
            <td className="selected-cell">24mtr</td>
            <td>28mtr</td>
            <td>32mtr</td>
            <td className="selected-cell">36mtr</td>
            <td>40mtr</td>
            <td className="selected-cell">44mtr</td>
            <td className="selected-cell">48mtr</td>
            <td>52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">52mtr X</td>
            <td className="selected-cell">20mtr</td>
            <td>24mtr</td>
            <td>28mtr</td>
            <td>32mtr</td>
            <td>36mtr</td>
            <td className="selected-cell">40mtr</td>
            <td>44mtr</td>
            <td>48mtr</td>
            <td>52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">60mtr X</td>
            <td className="selected-cell">20mtr</td>
            <td>24mtr</td>
            <td>28mtr</td>
            <td className="selected-cell">32mtr</td>
            <td className="selected-cell">36mtr</td>
            <td>40mtr</td>
            <td>44mtr</td>
            <td>48mtr</td>
            <td className="selected-cell">52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">68mtr X</td>
            <td>20mtr</td>
            <td className="selected-cell">24mtr</td>
            <td>28mtr</td>
            <td>32mtr</td>
            <td>36mtr</td>
            <td>40mtr</td>
            <td className="selected-cell">44mtr</td>
            <td>48mtr</td>
            <td>52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">76mtr X</td>
            <td className="selected-cell">20mtr</td>
            <td>24mtr</td>
            <td>28mtr</td>
            <td>32mtr</td>
            <td>36mtr</td>
            <td className="selected-cell">40mtr</td>
            <td>44mtr</td>
            <td>48mtr</td>
            <td className="selected-cell">52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">84mtr X</td>
            <td>20mtr</td>
            <td className="selected-cell">24mtr</td>
            <td>28mtr</td>
            <td>32mtr</td>
            <td className="selected-cell">36mtr</td>
            <td>40mtr</td>
            <td>44mtr</td>
            <td className="selected-cell">48mtr</td>
            <td>52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">92mtr X</td>
            <td>20mtr</td>
            <td>24mtr</td>
            <td className="selected-cell">28mtr</td>
            <td>32mtr</td>
            <td>36mtr</td>
            <td>40mtr</td>
            <td className="selected-cell">44mtr</td>
            <td>48mtr</td>
            <td>52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">100mtr X</td>
            <td className="selected-cell">20mtr</td>
            <td>24mtr</td>
            <td>28mtr</td>
            <td>32mtr</td>
            <td>36mtr</td>
            <td className="selected-cell">40mtr</td>
            <td>44mtr</td>
            <td>48mtr</td>
            <td>52mtr</td>
          </tr>
          <tr>
            <td className="selected-cell">108mtr X</td>
            <td>20mtr</td>
            <td>24mtr</td>
            <td className="selected-cell">28mtr</td>
            <td>32mtr</td>
            <td>36mtr</td>
            <td>40mtr</td>
            <td>44mtr</td>
            <td>48mtr</td>
            <td>52mtr</td>
          </tr>
        </tbody>
      </table>
      <ol>
        <li>
          Bold letter sizes are suggested (but not mandatory) polyhouse
          dimensions.
        </li>
        <li>
          You can understand the required dimensions of land from the above
          table.
        </li>
        <li>
          From the length column pick available length of farm land and multiply
          it with width to understand area in sq mtr to understand area required
          for setup.
        </li>
        <li>
          We can modify the base size and design as per available land area.
        </li>
      </ol>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default PossibleDimentions;
