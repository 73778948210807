import React, { useEffect, useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useNavigate, useParams } from "react-router-dom";
import { WorkforceCreateInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import { WorkforceUpdateSchema } from "../../../Schemas/workforce/workforce_update_schema";
import { uploadImage } from "../../../CustomComponent/upload_Image";
import { FcTemplate } from "react-icons/fc";
import { BsJournalArrowUp } from "react-icons/bs";

const EditWorkforce = () => {
  const [loading, setLoading] = useState(false);
  const [workForceInfo, setWorkForceInfo] = useState(WorkforceCreateInfo);
  let { id } = useParams();

  const navigate = useNavigate();
  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest("PATCH", `/workForce/updateWorkForce/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Workforce updated successfully!");
          navigate(`/client-employee-database/view/${id}`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/workForce/getWorkForceById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setWorkForceInfo(res.data);
        } else {
          Toast("error", "Error while getting workforce info!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcTemplate className="fs-2  me-1" /> Add new workforce staff
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Add new workforce to portal.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/client-employee-database/new"}>
                    <button className="btn btn-primary">
                      <BsJournalArrowUp className="me-2 fs-2" /> Add New Staff
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <Formik
                  initialValues={workForceInfo}
                  validationSchema={WorkforceUpdateSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            First name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="firstName" type="text" />
                            {props.touched.firstName &&
                              props.errors.firstName && (
                                <p className="text-danger">
                                  {props.errors.firstName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Last name
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="lastName" type="text" />
                            {props.touched.lastName &&
                              props.errors.lastName && (
                                <p className="text-danger">
                                  {props.errors.lastName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Email address
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="emailAddress" type="text" />
                            {props.touched.emailAddress &&
                              props.errors.emailAddress && (
                                <p className="text-danger">
                                  {props.errors.emailAddress}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Date of joining
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="dateOfJoining" type="date" />
                            {props.touched.dateOfJoining &&
                              props.errors.dateOfJoining && (
                                <p className="text-danger">
                                  {props.errors.dateOfJoining}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Date of birth
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="dateOfBirth" type="date" />
                            {props.touched.dateOfBirth &&
                              props.errors.dateOfBirth && (
                                <p className="text-danger">
                                  {props.errors.dateOfBirth}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Contact number
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="contactNumber" type="text" />
                            {props.touched.contactNumber &&
                              props.errors.contactNumber && (
                                <p className="text-danger">
                                  {props.errors.contactNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Aadhar number
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="aadharNumber" type="text" />
                            {props.touched.aadharNumber &&
                              props.errors.aadharNumber && (
                                <p className="text-danger">
                                  {props.errors.aadharNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            PAN number
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="panNumber" type="text" />
                            {props.touched.panNumber &&
                              props.errors.panNumber && (
                                <p className="text-danger">
                                  {props.errors.panNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Permenant address
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="permanentAddress" type="text" />
                            {props.touched.permanentAddress &&
                              props.errors.permanentAddress && (
                                <p className="text-danger">
                                  {props.errors.permanentAddress}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Emergency contact name
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="emergencyContactName"
                              type="text"
                            />
                            {props.touched.emergencyContactName &&
                              props.errors.emergencyContactName && (
                                <p className="text-danger">
                                  {props.errors.emergencyContactName}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Emergency contact number
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="emergencyConatactNumber"
                              type="text"
                            />
                            {props.touched.emergencyConatactNumber &&
                              props.errors.emergencyConatactNumber && (
                                <p className="text-danger">
                                  {props.errors.emergencyConatactNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Emergency contact relation
                          </label>
                          <div className="w-100 col">
                            <CustomSelect
                              name="emergencyContactRelation"
                              type="text"
                            >
                              <option value={" "}>Select</option>
                              <option value={"father"}>Father</option>
                              <option value={"mother"}>Mother</option>
                              <option value={"spouse"}>Spouse</option>
                              <option value={"sister"}>Sister</option>
                              <option value={"brother"}>Brother</option>
                              <option value={"friend"}>Friend</option>
                            </CustomSelect>
                            {props.touched.emergencyContactRelation &&
                              props.errors.emergencyContactRelation && (
                                <p className="text-danger">
                                  {props.errors.emergencyContactRelation}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Upload photo
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="uploadPhoto"
                              type="file"
                              onChange={(e) =>
                                uploadImage(
                                  props,
                                  "uploadPhoto",
                                  e.target.files[0]
                                )
                              }
                            />
                            {props.touched.uploadPhoto &&
                              props.errors.uploadPhoto && (
                                <p className="text-danger">
                                  {props.errors.uploadPhoto}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Upload aadhar front
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="uploadAadharFront"
                              type="file"
                              onChange={(e) =>
                                uploadImage(
                                  props,
                                  "uploadAadharFront",
                                  e.target.files[0]
                                )
                              }
                            />
                            {props.touched.uploadAadharFront &&
                              props.errors.uploadAadharFront && (
                                <p className="text-danger">
                                  {props.errors.uploadAadharFront}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label  col-3">
                            Upload aadhar back
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="uploadAadharBack"
                              type="file"
                              onChange={(e) =>
                                uploadImage(
                                  props,
                                  "uploadAadharBack",
                                  e.target.files[0]
                                )
                              }
                            />
                            {props.touched.uploadAadharBack &&
                              props.errors.uploadAadharBack && (
                                <p className="text-danger">
                                  {props.errors.uploadAadharBack}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Salary
                          </label>
                          <div className="w-100 col">
                            <CustomInput name="salary" type="text" />
                            {props.touched.salary && props.errors.salary && (
                              <p className="text-danger">
                                {props.errors.salary}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Salary Frequency
                          </label>
                          <div className="w-100 col">
                            <div className="d-flex">
                              <CustomSelect
                                name="salaryFrequency"
                                type="text"
                                style={{ width: "10%" }}
                              >
                                <option value="">Select</option>
                                {[...Array(32).keys()].map((month) => (
                                  <option key={month} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </CustomSelect>
                              <CustomSelect
                                name="salaryFrequencyType"
                                type="text"
                                style={{ width: "10%" }}
                              >
                                <option value="">Select Type</option>
                                <option value="days">Days</option>
                                <option value="months">Months</option>
                                <option value="year">Year</option>
                              </CustomSelect>
                            </div>
                            {props.touched.salaryFrequency &&
                              props.errors.salaryFrequency && (
                                <p className="text-danger">
                                  {props.errors.salaryFrequency}
                                </p>
                              )}
                            {props.touched.salaryFrequencyType &&
                              props.errors.salaryFrequencyType && (
                                <p className="text-danger">
                                  {props.errors.salaryFrequencyType}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Save Workforce" />

                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default EditWorkforce;
