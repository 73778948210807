import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./user/loginReducer";
import paginationFilterSlice from "./Pagination/filtersReducer";
import { thunk } from "redux-thunk";
import leadsFilteredSlice from "./Filtered leads/filteredLeadReducer";
import followupLeadsSlice from "./Filtered leads/followupLeadReducer";
import enquiriesSlice from "./Filtered leads/enquiriesReducer";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    filters: paginationFilterSlice,
    leadFilteredfilters: leadsFilteredSlice,
    followupLeadsFilters: followupLeadsSlice,
    enquiriesFilters: enquiriesSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
