import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FcInTransit } from "react-icons/fc";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ViewBuyer = () => {
  const user = useSelector((store) => store.login.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [facilities, setFacilities] = useState();
  let { id } = useParams();

  const handleCall = (phoneNumber) => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert("Phone number is not available!");
    }
  };

  const query = useQuery();
  const paramValue = query.get("favorite");

  useEffect(() => {
    setLoading(true);
    if (paramValue) {
      performRequest("GET", `/favoriteBuyer/getFavoriteBuyerById/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
          } else {
            Toast("error", "Error while getting Lead!");
          }
          setLoading(false);
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    } else {
      performRequest("GET", `/buyerProfiles/getbuyerProfilesById/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
          } else {
            Toast("error", "Error while getting Lead!");
          }
          setLoading(false);
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcInTransit className="fs-2  me-1" /> Buyers Details
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Details of {data?.name}.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <p>
                  <strong>Disclaimer:</strong> Kryzen Biotech Private Limited is
                  not directly or indirectly part of transaction happening
                  between you and this buyer.
                </p>
                <p>
                  Always send small qualitity of produce to start the
                  transaction.
                </p>
                <p>
                  Tyy to work with advance payment or payment on
                  dispatch/delivery.
                </p>
                <p>
                  Avoid credit period, unless you have transacted for more then
                  month together.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specifications</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>Name of Buyer</td>
                          <td>{(data?.name || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Photo of Buyer</td>
                          <td>
                            {data?.photo ? (
                              <img
                                src={data?.photo}
                                alt={data?.name}
                                style={{ width: "150px" }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Email of Buyer</td>
                          <td>{(data?.email || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Phone Number of Buyer</td>
                          <td>{(data?.phoneNumber1 || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Alternative Phone Number of Buyer</td>
                          <td>{(data?.phoneNumber2 || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Address of Buyer</td>
                          <td>{(data?.addressLine1 || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Market of Buyer</td>
                          <td>{(data?.market || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>State of Buyer</td>
                          <td>{(data?.addressState || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Pincode of Buyer</td>
                          <td>
                            {(data?.addressPincode || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Fssai of Buyer</td>
                          <td>{data?.fssaiNumber.toUpperCase() || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Active Since of Buyer</td>
                          <td>{data?.yearEstablished || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Buyer Type</td>
                          <td>
                            {data?.isExporter ? (
                              <span className="badge me-1">Exporter</span>
                            ) : (
                              ""
                            )}
                            {data?.isWholesaler ? (
                              <span className="badge badge-green me-1">
                                Wholesaler
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.isRetailer ? (
                              <span className="badge badge-green me-1">
                                Retailer
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.isTrader ? (
                              <span className="badge badge-green me-1">
                                Trader
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.isFoodProcessor ? (
                              <span className="badge badge-green me-1">
                                Food Processor
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Deals In</td>
                          <td>
                            {data?.dealPerishableVegetables ? (
                              <span className="badge badge-green me-1">
                                Perishable Items
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.dealExoticVegetables ? (
                              <span className="badge badge-green me-1">
                                Exotic Vegetables
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.dealFruitsAndBerries ? (
                              <span className="badge badge-green me-1">
                                Fruites & Berries
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.dealDryFruits ? (
                              <span className="badge badge-green me-1">
                                Dry Fruites
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.dealGrains ? (
                              <span className="badge badge-green me-1">
                                Grains
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Buyers rating</td>
                          <td>
                            {data?.buyerRating ? data?.buyerRating / 2 : "N/A"}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Google map link</td>
                          <td>
                            {data?.googleMapLink ? (
                              <a href={data?.googleMapLink}>
                                {data?.googleMapLink}
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Credit score</td>
                          <td>{(data?.creditScore || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Delivery option</td>
                          <td>
                            {data?.deliveryRoad ? (
                              <span className="badge badge-green me-1">
                                By Road
                              </span>
                            ) : (
                              ""
                            )}

                            {data?.deliveryRail ? (
                              <span className="badge badge-green me-1">
                                By Rail
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.deliverySea ? (
                              <span className="badge badge-green me-1">
                                By Sea
                              </span>
                            ) : (
                              ""
                            )}

                            {data?.deliveryCargo ? (
                              <span className="badge badge-green me-1">
                                By Cargo
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Operations on Monday</td>
                          <td>
                            {data?.openOnMonday ? (
                              <span className="badge badge-green me-1">
                                Operational
                              </span>
                            ) : (
                              "Closed"
                            )}{" "}
                            {(
                              data?.operationalHoursMonday || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Operations on Tuesday</td>
                          <td>
                            {data?.openOnTuesday ? (
                              <span className="badge badge-green me-1">
                                Operational
                              </span>
                            ) : (
                              "Closed"
                            )}{" "}
                            {(
                              data?.operationalHoursTuesday || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Operations on Wednesday</td>
                          <td>
                            {data?.openOnWednesday ? (
                              <span className="badge badge-green me-1">
                                Operational
                              </span>
                            ) : (
                              "Closed"
                            )}{" "}
                            {(
                              data?.operationalHoursWednesday || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Operations on Thursday</td>
                          <td>
                            {data?.openOnThursday ? (
                              <span className="badge badge-green me-1">
                                Operational
                              </span>
                            ) : (
                              "Closed"
                            )}{" "}
                            {(
                              data?.operationalHoursThursday || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Operations on Friday</td>
                          <td>
                            {data?.openOnFriday ? (
                              <span className="badge badge-green me-1">
                                Operational
                              </span>
                            ) : (
                              "Closed"
                            )}{" "}
                            {(
                              data?.operationalHoursFriday || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Operations on Saturday</td>
                          <td>
                            {data?.openOnSaturday ? (
                              <span className="badge badge-green me-1">
                                Operational
                              </span>
                            ) : (
                              "Closed"
                            )}{" "}
                            {(
                              data?.operationalHoursSaturday || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Operations on Sunday</td>
                          <td>
                            {data?.openOnSunday ? (
                              <span className="badge badge-green me-1">
                                Operational
                              </span>
                            ) : (
                              "Closed"
                            )}{" "}
                            {(
                              data?.operationalHoursSunday || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <div className="d-lg-none d-md-none">
                      <SimpleButton
                        buttonText="Call Now"
                        onClick={() =>
                          handleCall(data?.phoneNumber1 || data?.phoneNumber2)
                        }
                      />
                    </div>

                    {(user.Role === process.env.REACT_APP_ROLE_AGRONOMIST ||
                      user.Role === process.env.REACT_APP_ROLE_ADMIN_S) && (
                      <SimpleButton
                        buttonText="Edit Buyer"
                        onClick={() => {
                          if (paramValue) {
                            navigate(`/buyers-management/edit/${data.favId}`);
                          } else {
                            navigate(`/buyers-management/edit/${id}`);
                          }
                        }}
                      />
                    )}
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewBuyer;
