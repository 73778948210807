import React from "react";

const Products = () => {
  return (
    <div>
      <h3>Products:</h3>

      <p>
        Hydroponics is the production of vegetables in state-of-the-art,
        temperature-controlled, multi-span tunnels. The structures being created
        are multi-spans and temperature-controlled fans.
      </p>

      <p>The advantages of growing in high-tech poly-house are</p>

      <ol>
        <li>The faster, longer growth</li>
        <li>Harvest labour reduced by 80%</li>
        <li>Reduced harvest time</li>
        <li>Provide optimum temperature for plant growth</li>
        <li>Yearlong production</li>
        <li>Zero climate dependency</li>
        <li>Better yield per plant</li>
        <li>Continues market availability</li>
        <li>Scope of vertical farming</li>
      </ol>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default Products;
