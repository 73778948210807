import React, { useEffect, useState } from "react";
import { roundOffCost } from "../../../Components/Functions/amt.fun";
const getQuotationFor = (allQData) => {
  let bag = "";

  if (allQData?.input_info?.quotationType == "fanpad") {
    bag += "FANPAD";
  } else if (allQData?.input_info?.quotationType == "nvph") {
    bag += "NVPH";
  } else if (allQData?.input_info?.quotationType == "nethouse") {
    bag += "NETHOUSE";
  } else if (allQData?.input_info?.quotationType == "fanpad with hydroponics") {
    bag += "FANPAD";
    let next = "";
    if (allQData?.input_info?.a_FramePercent > 0) {
      next += `+ ${allQData?.input_info?.a_FramePercent}% A-FRAME`;
    }
    if (allQData?.input_info?.flatBedPercent > 0) {
      next += `+ ${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
    }
    if (allQData?.input_info?.growBagPercent > 0) {
      next += `+ ${allQData?.input_info?.growBagPercent}% GROW-BAG`;
    }
    if (allQData?.input_info?.dutchBucketPercent > 0) {
      next += `+ ${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
    }
    bag += ` ${next}`;
  } else if (
    allQData?.input_info?.quotationType == "nethouse with hydroponics"
  ) {
    bag += "NETHOUSE";
    let next = "";
    if (allQData?.input_info?.a_FramePercent > 0) {
      next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
    }
    if (allQData?.input_info?.flatBedPercent > 0) {
      next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
    }
    if (allQData?.input_info?.growBagPercent > 0) {
      next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
    }
    if (allQData?.input_info?.dutchBucketPercent > 0) {
      next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
    }
    bag += ` ${next}`;
  } else if (allQData?.input_info?.quotationType == "nvph with hydroponics") {
    bag += "NVPH";
    let next = "";
    if (allQData?.input_info?.a_FramePercent > 0) {
      next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
    }
    if (allQData?.input_info?.flatBedPercent > 0) {
      next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
    }
    if (allQData?.input_info?.growBagPercent > 0) {
      next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
    }
    if (allQData?.input_info?.dutchBucketPercent > 0) {
      next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
    }
    bag += ` ${next}`;
  } else if (allQData?.input_info?.quotationType == "hydroponics only") {
    bag += "WITHOUT-CEA";
    let next = "";
    if (allQData?.input_info?.a_FramePercent > 0) {
      next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
    }
    if (allQData?.input_info?.flatBedPercent > 0) {
      next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
    }
    if (allQData?.input_info?.growBagPercent > 0) {
      next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
    }
    if (allQData?.input_info?.dutchBucketPercent > 0) {
      next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
    }
    bag += ` ${next}`;
  }

  return bag;
};
const QuotationAmount = ({ allQData }) => {
  const [cost, setCost] = useState(0);
  const [unitcost, setUnitCost] = useState(0);
  useEffect(() => {
    let total = 0;
    if (allQData?.input_info?.overrideUnitCost > 0) {
      total += allQData?.input_info?.overrideUnitCost;
    } else {
      total = allQData?.quotationMatrixCost?.total_cost;
    }

    setCost(total);
    if (allQData?.input_info?.maxDiscount > 0) {
      let x = total + total * (allQData?.input_info?.maxDiscount / 100);
      setUnitCost(x / allQData?.area_in?.area_in_sqmeter);
    } else {
      setUnitCost(total / allQData?.area_in?.area_in_sqmeter);
    }
  }, [allQData]);
  return (
    <div>
      <h3>Quotation Amount ({allQData?.quotationNumber})</h3>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "40%" }}>Particulars</th>
            <th style={{ width: "10%" }}>Size/Unit</th>
            <th style={{ width: "15%" }}>Rates</th>
            <th style={{ width: "5%" }}>Disc</th>
            <th style={{ width: "10%" }}>Revised</th>
            <th style={{ width: "15%" }}>Amount</th>
          </tr>
          <tr>
            <td>1</td>
            <td> {allQData && getQuotationFor(allQData)}</td>

            <td>{allQData?.area_in?.area_in_sqmeter} SqM</td>
            <td>{roundOffCost(unitcost + (unitcost * 10) / 100)}</td>
            <td>10%</td>
            <td>{roundOffCost(unitcost)}</td>
            {allQData?.input_info?.maxDiscount > 0 && (
              <td>
                ₹
                {roundOffCost(
                  cost + cost * (allQData?.input_info?.maxDiscount / 100)
                )}
              </td>
            )}
            {allQData?.input_info?.maxDiscount == 0 && (
              <td> {roundOffCost(cost)}</td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default QuotationAmount;
