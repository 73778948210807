import * as yup from "yup";
export const BuyersCreateSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string(),
  description: yup.string(),
  yearEstablished: yup.string(),
  fssaiNumber: yup.string(),
  varifiedBadge: yup.string(),
  isWholesaler: yup.string(),
  isRetailer: yup.string(),
  isTrader: yup.string(),
  isExporter: yup.string(),
  isFoodProcessor: yup.string(),
  dealPerishableVegetables: yup.string(),
  dealExoticVegetables: yup.string(),
  dealFruitsAndBerries: yup.string(),
  dealDryFruits: yup.string(),
  dealGrains: yup.string(),
  buyerRating: yup.string(),
  creditScore: yup.string(),
  deliveryRoad: yup.string(),
  deliveryRail: yup.string(),
  deliverySea: yup.string(),
  deliveryCargo: yup.string(),
  phoneNumber1: yup.number(),
  phoneNumber2: yup.number(),
  addressLine1: yup.string(),
  addressState: yup.string(),
  addressPincode: yup.number(),
  openOnMonday: yup.string(),
  openOnTuesday: yup.string(),
  openOnWednesday: yup.string(),
  openOnThursday: yup.string(),
  openOnFriday: yup.string(),
  openOnSaturday: yup.string(),
  openOnSunday: yup.string(),
  operationalHoursMonday: yup.string(),
  operationalHoursTuesday: yup.string(),
  operationalHoursWednesday: yup.string(),
  operationalHoursThursday: yup.string(),
  operationalHoursFriday: yup.string(),
  operationalHoursSaturday: yup.string(),
  operationalHoursSunday: yup.string(),
  googleMapLink: yup.string(),
  photo: yup.string(),
  market: yup.string(),
  report: yup.array().of(yup.string()),
});

export const BuyersManagementInfo = {
  report: [],
};
