import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { CamelCaseToFirstUpper } from "../../../Components/Functions/word.fun";
import { BsArrowRightCircleFill, BsCartPlus } from "react-icons/bs";

const getHeartButtonWithUserId = (
  filters,
  favWiki,
  userId,
  Wiki,
  addToFavorite,
  removeFromFavorite
) => {
  let checkFav = [];
  if (!Wiki.favouriteOnly) {
    checkFav = favWiki.filter((item) => {
      if (item.favId == Wiki.id && item.userId == userId) {
        return true;
      }
    });
  }
  if (Wiki.userId && Wiki.userId == userId) {
    checkFav.push(Wiki);
  }

  if (checkFav && checkFav.length > 0) {
    return (
      <FaHeart
        className="text-danger"
        style={{ cursor: "pointer", fontSize: "1.3rem" }}
        onClick={(e) => removeFromFavorite(e, Wiki, favWiki, userId)}
      />
    );
  } else {
    return (
      <FaRegHeart
        className="text-muted"
        style={{ cursor: "pointer", fontSize: "1.3rem" }}
        onClick={(e) => addToFavorite(e, Wiki, userId)}
      />
    );
  }
};

const clampStyle = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const AgronomyWikiTable = ({
  Wikis,
  favWiki,
  userId,
  refresh,
  setRefresh,
  filters,
}) => {
  const navigate = useNavigate();
  const [id, setId] = useState();

  const addToFavorite = async (event, Wiki, userId) => {
    event.stopPropagation();
    const values = {
      ...Wiki,
      userId: userId,
      favId: Wiki.id,
    };
    try {
      const res = await performRequest(
        "POST",
        `/favouriteWiki/createFavoriteWiki`,
        values
      );
      if (res.status === 201) {
        Toast("success", "Favorite status updated!");
        setRefresh(!refresh);
      } else {
        Toast("error", "Error while updating favorite status");
      }
    } catch (err) {
      Toast("error", "Server Error!");
      console.error(err);
    }
  };
  const removeFromFavorite = async (event, Wiki, favWiki, userId) => {
    event.stopPropagation();

    let favId = "";
    if (!Wiki.favouriteOnly) {
      favWiki.filter((item) => {
        if (item.favId == Wiki.id && item.userId == userId) {
          favId = item.id;
        }
      });
    }
    if (Wiki.userId && Wiki.userId == userId) {
      favId = Wiki.id;
    }

    if (favId) {
      try {
        const res = await performRequest(
          "DELETE",
          `/favouriteWiki/removeFavouriteWiki/${favId}`
        );

        if (res.status === 200) {
          Toast("success", "Removed from favorites!");
          setRefresh(!refresh);
        } else {
          Toast("error", "Error while updating favorite status");
        }
      } catch (err) {
        Toast("error", "Server Error!");
        console.error(err);
      }
    }
  };
  const handleCardClick = (Wiki) => {
    if (Wiki.favouriteOnly == false) {
      navigate(`/agronomy-plants-wiki/view/${Wiki.id}`);
    } else {
      navigate(`/agronomy-plants-wiki/view/${Wiki.favId}/?favorite=true`);
    }
  };

  return (
    <>
      <div class="table-responsive overflow-hidden">
        <table class="table table-vcenter card-table table-striped">
          <thead>
            <tr>
              <th>Fav</th>
              <th>Plant Name</th>
              <th className="w-33 d-none d-md-table-cell">Description</th>
              <th>Start Batch</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {Wikis?.map((wiki) => (
              <tr key={wiki?.id}>
                <td>
                  {getHeartButtonWithUserId(
                    filters,
                    favWiki,
                    userId,
                    wiki,
                    addToFavorite,
                    removeFromFavorite
                  )}
                </td>
                <td>
                  <div className="row">
                    <div className="col-md-2">
                      <img
                        src={wiki?.image}
                        alt={wiki?.name}
                        className="avatar img-fluid"
                      />
                    </div>
                    <div className="col-auto">
                      {wiki?.name?.toUpperCase()} <br />
                      <span className="text-secondary">
                        {CamelCaseToFirstUpper(wiki?.articleType)}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="d-none d-md-table-cell">
                  <p className="text-muted m-0" style={clampStyle}>
                    {wiki?.description}
                  </p>
                </td>
                <td>
                  <a
                    href="/farm-nursery-management/new-batch"
                    className="btn btn-success p-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setId(wiki.id);
                    }}
                  >
                    Start Batch
                  </a>
                </td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleCardClick(wiki)}
                  >
                    <BsArrowRightCircleFill />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AgronomyWikiTable;
