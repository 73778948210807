import React from "react";

const GrowBagSetup = () => {
  let serial = 1;
  return (
    <div>
      <h3 className="table-heading">Grow Bag Setup:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serial++}.</td>
            <td>Grow bags</td>
            <td>Duel layer grow bags with green/black outer colour.</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Bag length</td>
            <td>
              10 / 16 / 20 / 24 / 28 cm long bags (bag length is calculated
              after site inspection)
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Drip pipe</td>
            <td>
              16mm strong tensile drip pipes for watering the plants inside
              growbags.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Other accessories</td>
            <td>Pump, piping, plumbing, fittings etc</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Walking distance</td>
            <td>
              Approximately 3.5 to 4.5 ft. walking distance will be maintained.
              So one labour with a crate can move freely.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GrowBagSetup;
