import React from "react";
import hb from "../../../certificates/hb.jpg";
import peyush from "../../../certificates/peyush.jpg";
import kryzencertificates from "../../../certificates/kryzencertificates.png";
const CompanyPromotion = () => {
  return (
    <div>
      <h3 className="table-heading">Certified developers:</h3>
      <p>
        Kryzen Biotech strives to create the most productive models for new-age
        urban farmers. We have combine sustainable technology and modern farming
        techniques in order to achieve high yields, while also being mindful of
        our environment's sustainability needs.
      </p>
      {/* <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ margin: "0px", width: "20%" }}>
              <h3
                style={{
                  textAlign: "center",
                  margin: "0px",
                }}
              >
                ISO 9001:2015
              </h3>
            </td>
            <td style={{ margin: "0px", width: "20%", textAlign: "center" }}>
              <h3 style={{ textAlign: "center", margin: "0px" }}>
                {" "}
                ISO 14001:2015
              </h3>
            </td>
            <td style={{ margin: "0px", width: "20%", textAlign: "center" }}>
              <h3 style={{ margin: "0px", textAlign: "center" }}>
                {" "}
                ISO 22000:2018
              </h3>
            </td>
            <td style={{ margin: "0px", width: "20%", textAlign: "center" }}>
              {" "}
              <h3 style={{ margin: "0px", textAlign: "center" }}> GMP</h3>
            </td>
            <td style={{ margin: "0px", width: "20%", textAlign: "center" }}>
              {" "}
              <h3 style={{ margin: "0px", textAlign: "center" }}> HACCP</h3>
            </td>
          </tr>
        </tbody>
      </table> */}

      <div
        style={{
          display: "flex",

          justifyContent: "center",
          width: "100%",
          marginTop: "12px",
        }}
      >
        <img src={hb} width={"80%"} alt="kryzenCertificates" />
      </div>

      <div
        style={{
          display: "flex",

          justifyContent: "center",
          width: "100%",
          marginTop: "12px",
        }}
      >
        <img src={peyush} width={"80%"} alt="kryzenCertificates" />
      </div>
      <div
        style={{
          display: "flex",

          justifyContent: "center",
          width: "100%",
          marginTop: "12px",
        }}
      >
        <img src={kryzencertificates} width={"80%"} alt="kryzenCertificates" />
      </div>
      {/* <td style={{ textAlign: "center" }}>
              <img
                src={Quality_management_system}
                width={"100%"}
                alt="Quality_management_system"
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <img
                src={Environmental_management_system}
                width={"100%"}
                alt="Environmental_management_system"
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <img
                src={Food_safety_management_system}
                width={"100%"}
                alt="Food_safety_management_system"
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <img src={Good_manufacturing_pratice} width={"100%"} alt="" />
            </td>
            <td style={{ textAlign: "center" }}>
              <img src={HACCP} width={"100%"} alt="" />
            </td> */}

      <h3 className="table-heading">
        We are fastest growing hydroponic farm developer in India:
      </h3>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ width: "20%" }}>
              <h2
                style={{
                  fontSize: "1.5em",
                  margin: "0px",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                20,00,000+
              </h2>
              <p
                style={{
                  margin: "0px",
                  fontWeight: "bold",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                SqFt area developed
              </p>
            </td>
            <td style={{ width: "20%" }}>
              <h2
                style={{
                  fontSize: "1.5em",
                  margin: "0px",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                48,000+
              </h2>
              <p
                style={{
                  margin: "0px",
                  fontWeight: "bold",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                People trained
              </p>
            </td>
            <td style={{ width: "20%" }}>
              <h2
                style={{
                  fontSize: "1.5em",
                  margin: "0px",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                9,000+
              </h2>
              <p
                style={{
                  margin: "0px",
                  fontWeight: "bold",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                Tonnage yield
              </p>
            </td>
            <td style={{ width: "20%" }}>
              <h2
                style={{
                  fontSize: "1.5em",
                  margin: "0px",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                135+
              </h2>
              <p
                style={{
                  margin: "0px",
                  fontWeight: "bold",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                Team size
              </p>
            </td>
            <td style={{ width: "20%" }}>
              <h2
                style={{
                  fontSize: "1.5em",
                  margin: "0px",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                45+
              </h2>
              <p
                style={{
                  margin: "0px",
                  fontWeight: "bold",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                Projects delivered
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default CompanyPromotion;
