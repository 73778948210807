import React from "react";
import { useNavigate } from "react-router-dom";
import { getId } from "../../../Components/Functions/word.fun";
const tableHead = ["Sr.", "Date", "Business Name", "Craeted At", "Actions"];
const DeliveryChallanTable = ({
  deliveries = [],
  currentPage,
  itemsPerPage,
}) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    id = getId(id);
    navigate(`/delivery-challan/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {deliveries?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.dcNumber}>
                <td>{Index}</td>
                {/* <td>{getId(item?.dcNumber)}</td> */}
                <td>{item?.challanDate || "N/A"}</td>
                <td>{item?.contactPersonName || "N/A"}</td>
                <td>{item?.createdAt || "N/A"}</td>
                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.dcNumber)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DeliveryChallanTable;
