import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { extractDate } from "../../../Components/Functions/date.fun";
import { useSelector } from "react-redux";

import { FcStackOfPhotos } from "react-icons/fc";
import { FaLeaf } from "react-icons/fa6";

const getScheduleBadge = (schedule) => {
  if (schedule === "Watering") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>{" "}
      </p>
    );
  }
  if (schedule === "Watering + fertilizers 19:19:19 (1gm/ltr)") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>
        <span className="badge bg-orange text-white m-1">
          Fertilizers NPK(19-19-19) (1gm/ltr)
        </span>
      </p>
    );
  }
  if (schedule === "Watering + fertilizers 19:19:19 (1.5gm/ltr)") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>
        <span className="badge bg-orange text-white m-1">
          Fertilizers NPK(19-19-19) (1.5gm/ltr)
        </span>
      </p>
    );
  }
  if (schedule === "Watering + neem oil sprey (1ml/ltr)") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>
        <span className="badge bg-purple text-white m-1">
          Neem oil sprey (3000PPM) (1ml/ltr)
        </span>
      </p>
    );
  }
  if (schedule === "Watering + chelated ferrous (1gm/ltr)") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>
        <span className="badge bg-purple text-white m-1">
          Chelated ferrous (1ml/ltr)
        </span>
      </p>
    );
  } else {
    return schedule;
  }
};
const getBg = (date) => {
  const dNow = new Date().toISOString().split("T")[0];
  if (date === dNow) {
    return "bg-blue-lt";
  } else {
    return "";
  }
};
const ViewBatch = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/nurseryManagement/getNurseryManagementById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting batch details!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcStackOfPhotos className="fs-2  me-1" />
                  Nursery Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} View nursery details.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a
                    href={"/farm-nursery-management/"}
                    className="btn btn-primary"
                  >
                    {" "}
                    <FaLeaf className="fs-3 me-2" /> All Batches
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card table-responsive">
                <table className="table table-striped table-vcenter">
                  <thead>
                    <tr>
                      <th>Specs</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && (
                      <tr>
                        <td>Project Name</td>
                        <td>{(data?.farmName || "N/A").toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Project Address</td>
                        <td>{(data?.projectAddress || "N/A").toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Contact</td>
                        <td>
                          {(data?.clientPhoneNumber || "N/A").toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Batch</td>
                        <td>
                          {(
                            extractDate(data?.batchName) || "N/A"
                          ).toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Crop</td>
                        <td>
                          {(data?.agronomyWiki?.name || "N/A").toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td>Plant Count</td>
                        <td>{data.numberOfplants || "N/A"}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div className="table-responsive">
                  {data && (
                    <table className="table table-vcenter">
                      <thead>
                        <tr>
                          <th>Day</th>
                          <th>schedule on</th>
                          <th>Schedule work</th>
                          {/* <th style={{ width: "30%" }}>Actions</th> */}
                          <th>Mark work done</th>
                          <th>Work done date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.schedule?.map((schedule, index) => {
                          return (
                            <tr key={index} className={getBg(schedule?.date)}>
                              <td>{++index}</td>
                              <td>{(schedule?.date).toUpperCase()}</td>
                              <td>
                                {schedule.schedule
                                  ? getScheduleBadge(schedule.schedule)
                                  : "---"}{" "}
                                {(schedule?.actions).toUpperCase()}
                              </td>
                              <td>
                                {(schedule?.scheduleDone
                                  ? "YES"
                                  : "NO"
                                ).toUpperCase()}
                              </td>
                              <td>
                                {(
                                  schedule?.scheduleDoneDate || "N/A"
                                ).toUpperCase()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton
                      buttonText="Edit batch"
                      href={`/farm-nursery-management/edit/${id}`}
                    />
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewBatch;
