import React, { useState } from "react";
import Footer from "../../Components/Footer/footer";
import { FcBiomass } from "react-icons/fc";
import { CiCalculator1 } from "react-icons/ci";

const NutrientCalculations = () => {
  const [motherTank, setMotherTank] = useState();
  const [systemType, setSystemType] = useState("Select");

  const [tankA, setTankA] = useState(1094.7);
  const [tankB, setTankB] = useState(529.7);
  const [tankA1, setTankA1] = useState(1354.8);
  const [tankB1, setTankB1] = useState(492.3);
  const [tankB2, setTankB2] = useState(95.0);

  const handleCalculate = () => {
    let factor = motherTank / 1000;
    if (systemType === "Fruiting") {
      setTankA(factor * (1094.7 * 0.4));
      setTankB(factor * (529.7 * 0.6));
      setTankA1(factor * (1354.8 * 0.4));
      setTankB1(factor * (492.3 * 0.6));
      setTankB2(factor * (95.0 * 0.6));
    } else if (systemType === "Vegetative") {
      setTankA(factor * 1094.7);
      setTankB(factor * 529.7);
      setTankA1(factor * 1354.8);
      setTankB1(factor * 492.3);
      setTankB2(factor * 95.0);
    }
    setMotherTank("");
    setSystemType("Select");
  };

  const resetTable = () => {
    setTankA(1094.7);
    setTankB(529.7);
    setTankA1(1354.8);
    setTankB1(492.3);
    setTankB2(95.0);
    setMotherTank("");
    setSystemType("Select");
  };

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcBiomass className="fs-2 me-1" /> Nutrient Calculations
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Hydroponic Nutrient Calculations Per
                  Kilolitre.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <td className="py-1">Element</td>
                        <td className="py-1">Target PPM</td>
                        <td className="py-1">Calcium Nitrate</td>
                        <td className="py-1">NPK 19-19-19</td>
                        <td className="py-1">NPK 13-0-45</td>
                        <td className="py-1">Epsom Salt</td>
                        <td className="py-1">Micronutrients</td>
                        <td className="py-1">Total</td>
                        <td className="py-1">Shortfall/ Adds</td>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "13px" }}>
                      <tr>
                        <td className="py-1">Nitrogen</td>
                        <td className="py-1">150</td>
                        <td className="py-1">84.84</td>
                        <td className="py-1">29.13</td>
                        <td className="py-1">88.06</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">202.04</td>
                        <td className="py-1">52.04</td>
                      </tr>
                      <tr>
                        <td className="py-1">Phosphorus</td>
                        <td className="py-1">41</td>
                        <td className="py-1">0</td>
                        <td className="py-1">41</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">41.00</td>
                        <td className="py-1">0</td>
                      </tr>
                      <tr>
                        <td className="py-1">Potassium</td>
                        <td className="py-1">210</td>
                        <td className="py-1">0</td>
                        <td className="py-1">52.71</td>
                        <td className="py-1">210</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">262.71</td>
                        <td className="py-1">52.72</td>
                      </tr>
                      <tr>
                        <td className="py-1">Calcium</td>
                        <td className="py-1">104</td>
                        <td className="py-1">104</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">104.00</td>
                        <td className="py-1">0</td>
                      </tr>
                      <tr>
                        <td className="py-1">Magnesium</td>
                        <td className="py-1">24</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">24</td>
                        <td className="py-1">0</td>
                        <td className="py-1">23.88</td>
                        <td className="py-1">-0.12</td>
                      </tr>
                      <tr>
                        <td className="py-1">Sulfur</td>
                        <td className="py-1">32</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">32</td>
                        <td className="py-1">0</td>
                        <td className="py-1">32.00</td>
                        <td className="py-1">0</td>
                      </tr>
                      <tr>
                        <td className="py-1">Ferrous</td>
                        <td className="py-1">1</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.21</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.95</td>
                        <td className="py-1">1.16</td>
                        <td className="py-1">0</td>
                      </tr>
                      <tr>
                        <td className="py-1">Boron</td>
                        <td className="py-1">0.25</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.48</td>
                        <td className="py-1">0.48</td>
                        <td className="py-1">0.23</td>
                      </tr>
                      <tr>
                        <td className="py-1">Manganese</td>
                        <td className="py-1">0.13</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.11</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.48</td>
                        <td className="py-1">0.58</td>
                        <td className="py-1">0.45</td>
                      </tr>
                      <tr>
                        <td className="py-1">Zink</td>
                        <td className="py-1">0.16</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.5</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">2.38</td>
                        <td className="py-1">2.43</td>
                        <td className="py-1">2.27</td>
                      </tr>
                      <tr>
                        <td className="py-1">Copper</td>
                        <td className="py-1">0.023</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.01</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.25</td>
                        <td className="py-1">0.50</td>
                        <td className="py-1">0.23</td>
                      </tr>
                      <tr>
                        <td className="py-1">Molybdenum</td>
                        <td className="py-1">0.024</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.01</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0</td>
                        <td className="py-1">0.01</td>
                        <td className="py-1">-0.01</td>
                      </tr>
                      <tr style={{ fontWeight: "bold" }}>
                        <td className="py-1">In Grams per KL.</td>
                        <td className="py-1">-</td>
                        <td className="py-1">{tankA.toFixed(2)}g/KL</td>
                        <td className="py-1">{tankB.toFixed(2)}g/KL</td>
                        <td className="py-1">{tankA1.toFixed(2)}g/KL</td>
                        <td className="py-1">{tankB1.toFixed(2)}g/KL</td>
                        <td className="py-1">{tankB2.toFixed(2)}g/KL</td>
                        <td className="py-1">-</td>
                        <td className="py-1">-</td>
                      </tr>
                      <tr>
                        <td className="py-1">Add to:</td>
                        <td className="py-1"></td>
                        <td className="py-1">TANK A</td>
                        <td className="py-1">TANK B</td>
                        <td className="py-1">TANK A</td>
                        <td className="py-1">TANK B</td>
                        <td className="py-1">TANK B</td>
                        <td className="py-1"></td>
                        <td className="py-1"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleCalculate();
                    }}
                  >
                    <div className="mb-3">
                      <label className="form-label required">
                        Mother Tank Capacity
                      </label>
                      <input
                        className="form-control"
                        value={motherTank}
                        onChange={(e) =>
                          setMotherTank(parseFloat(e.target.value))
                        }
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label required">
                        Type of System
                      </label>
                      <select
                        className="form-select"
                        value={systemType}
                        onChange={(e) => setSystemType(e.target.value)}
                        required
                      >
                        <option value="Select">Select</option>
                        <option value="Vegetative">Vegetative</option>
                        <option value="Fruiting">Fruiting</option>
                      </select>
                    </div>

                    <button type="submit" className="btn btn-primary me-2">
                      <CiCalculator1 className="me-1" />
                      CALCULATE
                    </button>
                    <button
                      type="button"
                      onClick={resetTable}
                      className="btn btn-muted"
                    >
                      RESET
                    </button>
                  </form>
                </div>
                <div className="my-3 ms-2">
                  <ul className="text-muted">
                    <li>Water PPM: 200 to 400</li>
                    <li>Targeted PPM: 562.587</li>
                    <li>Vegetative Crops: Above Calculations. (562.587)</li>
                    <li>
                      Fruiting Crops: Above Calculations + 40% of A + 60% of B.
                      (987.621)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NutrientCalculations;
