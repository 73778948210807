import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const generateDateLabels = (days) => {
  const labels = [];
  const dates = [];
  const today = new Date();
  for (let i = days; i >= 0; i--) {
    const date = new Date(today);

    date.setDate(today.getDate() - i);
    labels.push(date.toISOString().split("T")[0]);
    dates.push(date);
  }

  return { labels, dates };
};

const countKryzenEnquiryByDate = (enquiry, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = enquiry.filter((data) => {
      const createdAt = new Date(data.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        data.origin === "Kryzen Website"
      );
    }).length;

    return count;
  });
};
const countJustDialEnquiryByDate = (enquiry, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = enquiry.filter((data) => {
      const createdAt = new Date(data.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        data.origin === "Just Dial"
      );
    }).length;

    return count;
  });
};
const countFacebookAdsEnquiryByDate = (enquiry, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = enquiry.filter((data) => {
      const createdAt = new Date(data.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        data.origin === "Facebook Ads"
      );
    }).length;

    return count;
  });
};
const countHydroponicMasterclassEnquiryByDate = (enquiry, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = enquiry.filter((data) => {
      const createdAt = new Date(data.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        data.origin === "Hydroponic Masterclass"
      );
    }).length;

    return count;
  });
};
const countIndiaMartEnquiryByDate = (enquiry, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    const count = enquiry.filter((data) => {
      const createdAt = new Date(data.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        data.origin === "India Mart"
      );
    }).length;

    return count;
  });
};
const countCheerioEnquiryByDate = (enquiry, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    const count = enquiry.filter((data) => {
      const createdAt = new Date(data.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        data.origin === "Cheerio"
      );
    }).length;

    return count;
  });
};

const EnquiryChart = ({ enquiryData, enquiryFrom = 30 }) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  /*{
    Facebook Ads
    Just Dial
    Kryzen Website
    Hydroponic Masterclass
    India Mart
    Cheerio
}*/
  useEffect(() => {
    const { labels, dates } = generateDateLabels(enquiryFrom);
    const kryzenEnquiryCount = countKryzenEnquiryByDate(enquiryData, dates);
    const justDialEnquiryCount = countJustDialEnquiryByDate(enquiryData, dates);
    const facebookAdsEnquiryCount = countFacebookAdsEnquiryByDate(
      enquiryData,
      dates
    );
    const hydroponicMasterclassEnquiryCount =
      countHydroponicMasterclassEnquiryByDate(enquiryData, dates);
    const indiaMartEnquiryCount = countIndiaMartEnquiryByDate(
      enquiryData,
      dates
    );
    const cheerioEnquiryCount = countCheerioEnquiryByDate(enquiryData, dates);

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Kryzen Website",
          data: kryzenEnquiryCount,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
        {
          label: "Just Dial",
          data: justDialEnquiryCount,
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
        {
          label: "Facebook Ads",
          data: facebookAdsEnquiryCount,
          backgroundColor: "rgba(255, 206, 86, 0.5)",
          borderColor: "rgba(255, 206, 86, 1)",
          borderWidth: 1,
        },

        {
          label: "India Mart",
          data: indiaMartEnquiryCount,
          backgroundColor: "rgba(153, 102, 255, 0.5)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
        },
        {
          label: "Hydroponic Masterclass",
          data: hydroponicMasterclassEnquiryCount,
          backgroundColor: "rgba(255, 159, 64, 0.5)",
          borderColor: "rgba(255, 159, 64, 1)",
          borderWidth: 1,
        },
        {
          label: "WhatsApp",
          data: cheerioEnquiryCount,
          backgroundColor: "rgba(200, 200, 114, 0.5)",
          borderColor: "rgba(200, 200, 114, 1)",
          borderWidth: 1,
        },
      ],
    };

    setChartData(data);
  }, [enquiryData, enquiryFrom]);

  const options = {
    plugins: {
      tooltip: {
        theme: "dark",
      },
      legend: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 0,
        },
        title: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
          borderDash: [4],
        },
        ticks: {
          padding: 4,
        },
        title: {
          display: false,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 1,
        barThickness: "flex",
        maxBarThickness: 10,
      },
    },
    animation: {
      duration: 0,
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default EnquiryChart;
