import React, { useEffect, useState } from "react";
import Footer from "../../../Kryzen Alpha App/Components//Footer/footer";
import StepRoutes from "../../../Kryzen Alpha App/Routes/steps_routes";
import ButtonWithSVG from "../../../Kryzen Alpha App/Components/layouts/button_with_svg";
import performRequest from "../../../Kryzen Alpha App/api/request";
import { Toast } from "../../../Kryzen Alpha App/CustomComponent/toast";
import { useSelector } from "react-redux";
import DynamicFilterInput from "../../../Kryzen Alpha App/Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../../Kryzen Alpha App/Components/layouts/filter.select";
import getFilteredURL from "../../../Kryzen Alpha App/Components/Functions/filters.getUrl";
import Pagination from "../../Components/layouts/pagination";
import PriceWatcherTable from "./Price Watcher/price_watcher_table";

const BuyersPriceWatcher = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [agro, setAgro] = useState();
  let [filters, setFilters] = useState({
    plantName: "",
  });

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/priceWatcher/getPriceWatcher/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data?.priceWatcherData);
          setTotalPages(Math.ceil(res.data?.priceWatcherCount / 50));
        } else {
          Toast("error", "Error while getting Data!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  // console.log(data);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/priceWatcher/getAgronomyImage/", page)
    )
      .then((res) => {
        if (res.status === 200) {
          setAgro(res.data?.agronomyWiki);
        } else {
          Toast("error", "Error while getting Data!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  // console.log(data);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Price Watcher</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>
                <div className="col-auto ms-auto d-print-none">
                  {process.env.REACT_APP_ROLE_ADMIN === user.Role ||
                  process.env.REACT_APP_ROLE_ADMIN_S === user.Role ? (
                    <div className="d-flex">
                      <ButtonWithSVG
                        href={"/buyers-price-watcher/new"}
                        buttonText="Create New Price Tacking"
                        svg={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 5l0 14" />
                            <path d="M5 12l14 0" />
                          </svg>
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="container-xl">
              <div className="card">
                {/* {
                  <div className="row g-3 m-2 mb-0 me-3">
                    <DynamicFilterInput
                      label="Search name:"
                      value={filters.plantName}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          plantName: e.target.value,
                        })
                      }
                      placeholder="search name..."
                      type="text"
                    />
                    <div className="col-md">
                      <div className="d-flex">
                        <button
                          onClick={() => setRefresh(!refresh)}
                          style={{
                            height: "28px",
                            marginRight: "5px",
                          }}
                          className="btn btn-primary mt-3"
                        >
                          Search
                        </button>

                        <button
                          onClick={() => {
                            setFilters({
                              plantName: "",
                            });
                            setPage(1);
                            setRefresh(!refresh);
                          }}
                          style={{ height: "28px" }}
                          className="btn btn-primary mt-3"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                } */}
                {data && (
                  <PriceWatcherTable
                    data={data}
                    agro={agro}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                )}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.priceWatcherData?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default BuyersPriceWatcher;
