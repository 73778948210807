import React, { useEffect, useState } from "react";
import Footer from "../../../Kryzen Alpha App/Components/Footer/footer";
import ButtonWithSVG from "../../../Kryzen Alpha App/Components/layouts/button_with_svg";
import performRequest from "../../../Kryzen Alpha App/api/request";
import { Toast } from "../../../Kryzen Alpha App/CustomComponent/toast";
import AgronomyWikiTable from "./Agronomy Wiki/agronomywiki_table";
import { useSelector } from "react-redux";
import DynamicFilterInput from "../../../Kryzen Alpha App/Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../../Kryzen Alpha App/Components/layouts/filter.select";
import getFilteredURL from "../../../Kryzen Alpha App/Components/Functions/filters.getUrl";
import Pagination from "../../Components/layouts/pagination";
import { FcWikipedia } from "react-icons/fc";
import { BsBoxArrowInDownRight } from "react-icons/bs";

const AgronomyPlantsWiki = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    name: "",
    articleType: "",
    classification: "",
    favouritesOnly: false,
    userId: user?.UserId,
  });
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [favWiki, setFavWiki] = useState();

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/agronomyWiki/getAgronomyWiki/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data?.agronomyWiki);
          setTotalPages(Math.ceil(res.data?.agronomyCount / 50));
        } else {
          Toast("error", "Error while getting Data!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/favouriteWiki/getAllFavoriteWiki/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setFavWiki(res?.data?.favoriteWiki);
        } else {
          Toast("error", "Error while getting Wikis!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcWikipedia className="fs-2 me-1" /> Plant Wiki
                </h2>
                <h4 className="text-muted">
                  Learn everything about plants you are growing.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/agronomy-plants-wiki/new"}>
                    <button className="btn btn-primary">
                      <BsBoxArrowInDownRight className="me-2 fs-2" /> Add New
                      Plant
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-1">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3>Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Search name:"
                              value={filters.name}
                              onChange={(e) =>
                                setFilters({ ...filters, name: e.target.value })
                              }
                              placeholder="search name..."
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search Article Type:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  articleType: e.target.value,
                                })
                              }
                              value={filters.articleType}
                            >
                              <option value="">select all</option>
                              <option value="fruiting">Fruiting</option>
                              <option value="vegetative">Vegetative</option>
                            </DynamicFilterSelect>
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search Classification:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  classification: e.target.value,
                                })
                              }
                              value={filters.classification}
                            >
                              <option value="">select all</option>
                              <option value="vegetable">Vegetable</option>
                              <option value="medicinal">Medicinal</option>
                              <option value="non edible">Non Edible</option>
                              <option value="fruit">Fruit</option>
                              <option value="seed">Seed</option>
                            </DynamicFilterSelect>
                          </div>
                          <div className="col-12">
                            <label className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={filters.favouritesOnly}
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    favouritesOnly: e.target.checked,
                                  });
                                }}
                                style={{
                                  textAlign: "center",
                                  height: "20px",
                                  width: "20px",
                                }}
                              />
                              <span className="form-check-label">
                                My Favorite Plants
                              </span>
                            </label>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      name: "",
                                      articleType: "",
                                      classification: "",
                                      favouritesOnly: false,
                                      userId: "",
                                    });
                                    setPage(1);
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Search name:"
                            value={filters.name}
                            onChange={(e) =>
                              setFilters({ ...filters, name: e.target.value })
                            }
                            placeholder="search name..."
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterSelect
                            label="Search Article Type:"
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                articleType: e.target.value,
                              })
                            }
                            value={filters.articleType}
                          >
                            <option value="">select all</option>
                            <option value="fruiting">Fruiting</option>
                            <option value="vegetative">Vegetative</option>
                          </DynamicFilterSelect>
                        </div>
                        <div className="col-12">
                          <DynamicFilterSelect
                            label="Search Classification:"
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                classification: e.target.value,
                              })
                            }
                            value={filters.classification}
                          >
                            <option value="">select all</option>
                            <option value="vegetable">Vegetable</option>
                            <option value="medicinal">Medicinal</option>
                            <option value="non edible">Non Edible</option>
                            <option value="fruit">Fruit</option>
                            <option value="seed">Seed</option>
                          </DynamicFilterSelect>
                        </div>
                        <div className="col-12">
                          <label className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={filters.favouritesOnly}
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  favouritesOnly: e.target.checked,
                                });
                              }}
                              style={{
                                textAlign: "center",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                            <span className="form-check-label">
                              My Favorite Plants
                            </span>
                          </label>
                        </div>

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    name: "",
                                    articleType: "",
                                    classification: "",
                                    favouritesOnly: false,
                                    userId: "",
                                  });
                                  setPage(1);
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {data && favWiki && user && (
                  <AgronomyWikiTable
                    filters={filters}
                    Wikis={data}
                    favWiki={favWiki}
                    refresh={refresh}
                    userId={user.UserId}
                    setRefresh={setRefresh}
                  />
                )}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.agronomyWiki?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AgronomyPlantsWiki;
