import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BsCheck2Circle,
  BsClockHistory,
  BsArrowRightShort,
} from "react-icons/bs";

const tableHead = ["Sr.", "Status", "Name", "Deadline", "Assign To", "Actions"];

const TaskManagementTable = ({ data = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/task-management/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>
                  {item?.status == "pending" ? (
                    <span
                      className="badge bg-orange"
                      style={{ color: "white" }}
                    >
                      <BsClockHistory className="fs-2" />
                    </span>
                  ) : (
                    (
                      <span
                        className="badge bg-green"
                        style={{ color: "white" }}
                      >
                        <BsCheck2Circle className="fs-2" />
                      </span>
                    ) || "N/A"
                  )}
                </td>
                <td>
                  {item?.name || "N/A"} <br />{" "}
                  <span className="text-secondary">
                    {item?.taskType || "N/A"}
                  </span>
                </td>
                <td>{item?.deadline || "N/A"}</td>
                <td>{item?.assignTo || "N/A"}</td>

                <td>
                  <button
                    className="btn btn-sm px-3 btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    <BsArrowRightShort className="me-1" />
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TaskManagementTable;
