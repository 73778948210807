import React from "react";

const DutchBucketSetup = () => {
  let serial = 1;
  return (
    <div>
      <h3 className="table-heading">Dutch Bucket Setup:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serial++}.</td>
            <td>Dutch buckets</td>
            <td>Virgin original food-grade plastic dutch buckets.</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Dutch bucket size</td>
            <td>3 litre</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Leca balls</td>
            <td>Hydroton brown coloured balls of 16mm to 30mm size</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Drip pipe</td>
            <td>
              16mm strong tensile drip pipes for watering the plants inside
              growbags.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Other accessories</td>
            <td>Pump, piping, plumbing, gauge, fittings etc</td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Walking distance</td>
            <td>
              Approximately 3.5 to 4.5 ft. walking distance will be maintained.
              So one labour with a crate can move freely.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DutchBucketSetup;
