import * as yup from "yup";

export const InventoryUpdateSchema = yup.object().shape({
  assetName: yup.string(),
  companyName: yup.string(),
  vendor: yup.string(),
  refillFrequency: yup.number(),
  refillFrequencyType: yup.string(),
  frequencyCycle: yup.string(),
  paymentAmount: yup.number(),
  paymentMode: yup.string(),
  uploadBill: yup.mixed(),
  notes: yup.string(),
});
