import React from "react";
import CommingSoon from "../Components/layouts/comming.soon";

const PrivacyPolicy = () => {
  return (
    <div>
      <CommingSoon />
    </div>
  );
};

export default PrivacyPolicy;
