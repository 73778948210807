import React, { useEffect, useState } from "react";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";

const getTaskListDetails = (task = []) => {
  let totalTasks = task.length;
  let completedTasks = task?.filter((t) => t.isUpdated || t.isNotApplicable);

  return `${completedTasks.length}/${totalTasks}`;
};

const getProjectDate = (item) => {
  if (item.taskList) {
    return `Started (${getTaskListDetails(item?.taskList)})`;
  } else {
    return item?.newSiteInspection?.tentativeStartDate || "N/A";
  }
};

const ProjectTrackingTable = () => {
  const [projectTrackingData, setProjectTrackingData] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      performRequest("GET", "/siteInspection/siteInspectionList")
        .then((res) => {
          if (res.status === 200) {
            setProjectTrackingData(res.data);
          } else {
            Toast("error", "Error while getting Leads!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        });
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    performRequest("GET", "/siteInspection/siteInspectionList")
      .then((res) => {
        if (res.status === 200) {
          setProjectTrackingData(res.data);
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, []);

  return (
    <>
      <style>
        {`
          .scroll-container {
            max-height: 470px;
            min-height: 470px;
            overflow: hidden;
            position: relative;
          }
          .scroll-content {
            display: block;
            animation: scroll 150s linear infinite;
          }

          @keyframes scroll {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(-100%);
            }
          }
        `}
      </style>
      <div className="scroll-container">
        <table className="table card-table table-vcenter fw-bold">
          <tbody
            className="scroll-content fw-bold"
            style={{
              fontSize: "1.6em",
            }}
          >
            <tr>
              <th>Project</th>
              <th>Start Date</th>
            </tr>

            {projectTrackingData?.map((item, index) => (
              <tr key={index}>
                <td>
                  {item?.clientDetails?.clientName ||
                    item?.clientDetails?.businessName ||
                    "N/A"}
                </td>
                <td>{getProjectDate(item)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProjectTrackingTable;
