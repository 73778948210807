import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const generateDateLabels = (days) => {
  const labels = [];
  const dates = [];
  const today = new Date();
  for (let i = days; i >= 0; i--) {
    const date = new Date(today);

    date.setDate(today.getDate() - i);
    labels.push(date.toISOString().split("T")[0]);
    dates.push(date);
  }

  return { labels, dates };
};

const countLeadsByDate = (leads, dates, employee) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = leads.filter((lead) => {
      const createdAt = new Date(lead.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        (employee === "All" || lead.lead_alloted_to === employee)
      );
    }).length;

    return count;
  });
};

const countQuotationByDate = (quotations, dates, employee) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = quotations.filter((quotation) => {
      const createdAt = new Date(quotation.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        (employee === "All" || quotation.input_info.genrated_by === employee)
      );
    }).length;
    return count;
  });
};

const countAssignedLeadsByDate = (leads, dates, employee) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = leads.filter((lead) => {
      const updatedAt = new Date(lead.updatedAt);
      return (
        updatedAt >= startOfDay &&
        updatedAt <= endOfDay &&
        lead.lead_alloted_to !== lead.reAssignTo &&
        (employee === "All" || lead.reAssignTo === employee)
      );
    }).length;
    return count;
  });
};

const countPendingFollowupLeadsByDate = (leads, dates, employee) => {
  return dates.map((date) => {
    const dt = formatDate(date);
    const count = leads.filter((lead) => {
      return (
        Array.isArray(lead?.followUp) &&
        lead?.followUp.some((followUp) => {
          return (
            followUp?.followUpDate === dt &&
            !followUp?.followupTaken &&
            (employee === "All" || followUp.createdBy === employee)
          );
        })
      );
    }).length;
    return count;
  });
};

const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const LineChart = ({ leadsData, quotationData, leadsFrom = 30, employee }) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const { labels, dates } = generateDateLabels(leadsFrom);
    const leadCounts = countLeadsByDate(leadsData, dates, employee);
    const AssignLeadCounts = countAssignedLeadsByDate(
      leadsData,
      dates,
      employee
    );
    const followupPending = countPendingFollowupLeadsByDate(
      leadsData,
      dates,
      employee
    );
    const quotationCounts = countQuotationByDate(
      quotationData,
      dates,
      employee
    );
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Quotations Created",
          data: quotationCounts,
          backgroundColor: "#FF00FF",
          borderColor: "#FF00FF",

          borderWidth: 1,
          tension: 0.5,
        },
        {
          label: "Leads Created",
          data: leadCounts,
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          borderColor: "rgba(54, 162, 235, 1)",

          borderWidth: 1,
          tension: 0.5,
        },
        {
          label: "Leads Assigned",
          data: AssignLeadCounts,
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          borderColor: "rgba(75, 192, 192, 1)",

          borderWidth: 1,
          tension: 0.5,
        },
        {
          label: "Pending Followup",
          data: followupPending,
          backgroundColor: "rgba(153, 102, 255, 0.5)",
          borderColor: "rgba(153, 102, 255, 1)",

          borderWidth: 1,
          tension: 0.5,
        },
      ],
    };

    setChartData(data);
  }, [leadsData, leadsFrom]);

  const options = {
    plugins: {
      tooltip: {
        theme: "dark",
      },
      legend: {
        display: true,
      },
    },
    responsive: true,
    layout: {
      padding: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        stacked: true,
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.6)",
          borderColor: "rgba(200, 200, 200, 0.6)",
        },
        ticks: {
          padding: 10,
          color: "#000",
        },
        title: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.6)",
          borderColor: "rgba(200, 200, 200, 0.6)",
        },
        ticks: {
          padding: 10,
          color: "#000",
        },
      },
    },
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderWidth: 1,
    animation: {
      duration: 1000,
      easing: "easeOutQuart",
      loop: false,
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
