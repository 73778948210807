import React from "react";
import kryzenLogo from "../../../certificates/kryzenLogo.png";
import { useEffect } from "react";
const KryzenTop = ({ data, allQData }) => {
  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "0px",
    padding: "0px",
    paddingLeft: "3px",
    fontSize: "17px",
    borderCollapse: "collapse",
  };

  useEffect(() => {
    const TR = document.getElementsByTagName("tr");
    const TH = document.getElementsByTagName("th");
    const TD = document.getElementsByTagName("td");
    const H3 = document.getElementsByTagName("h3");

    Array.from(TR).forEach((tr) => {
      Object.assign(tr.style, style);
    });
    Array.from(TH).forEach((th) => {
      Object.assign(th.style, style);
    });
    Array.from(TD).forEach((td) => {
      Object.assign(td.style, style);
    });
    Array.from(H3).forEach((h3) => {
      Object.assign(h3.style, {
        marginTop: "14px",
        marginBottom: "2px",
      });
    });
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "90vh",
        justifyContent: "center",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <div>
          <div
            style={{
              border: "none",
              textAlign: "center",
            }}
          >
            <img
              width={"200px"}
              style={{ paddingTop: "auto" }}
              src={kryzenLogo}
              alt={kryzenLogo}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              border: "none",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            <p style={{ textAlign: "center", fontSize: "20px" }}>
              <br />
              <b>
                Site Inspection For Client:{" "}
                {data?.clientDetails?.businessName ||
                  data?.clientDetails.clientName ||
                  "N/A"}
              </b>

              <br />
              <b>
                Farm Address: {data?.newSiteInspection?.siteAddress || "N/A"}
              </b>
            </p>
          </div>
        </div>
        <br />
        <div>
          <div
            style={{
              border: "none",
              textAlign: "center",
            }}
          >
            <p style={{ textAlign: "center" }}>
              <br />
              <b
                style={{
                  fontSize: "1em",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                Technology partner
              </b>
              <br />
              <b
                style={{
                  fontSize: "1.5em",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                Kryzen Biotech Private Limited
              </b>
              <br />
              GSTIN: 27AAHCK7659R1ZF | CIN: U01100PN2019PTC186207
              <hr />
              M-319, City Avenue, Wakad, Pune, Maharashtra 411057
              <br />
              #98, At Po. Degaon, Wai, District Satara, Maharashtra 412803
              <br />
              <a href="https://www.kryzen.com">www.kryzen.com</a> |
              <a href="tel:+919870424425">+91-9870-424-425</a> |
              <a href="mailto:sales@kryzen.com"> sales@kryzen.com</a>
            </p>
          </div>
          <div
            className="html2pdf__page-break"
            style={{
              pageBreakAfter: "always",
              display: "block",
              clear: "both",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default KryzenTop;
