import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const generateDateLabels = (days) => {
  const labels = [];
  const dates = [];
  const today = new Date();
  for (let i = days; i >= 0; i--) {
    const date = new Date(today);

    date.setDate(today.getDate() - i);
    labels.push(date.toISOString().split("T")[0]);
    dates.push(date);
  }

  return { labels, dates };
};

const countLeadsByDate = (leads, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = leads.filter((lead) => {
      const createdAt = new Date(lead.createdAt);
      return createdAt >= startOfDay && createdAt <= endOfDay;
    }).length;

    return count;
  });
};
const countFilteredLeadsByDate = (leads, dates) => {
  return dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = leads.filter((lead) => {
      const updatedAt = new Date(lead.updatedAt);
      return (
        updatedAt >= startOfDay &&
        updatedAt <= endOfDay &&
        lead.leadsFiltered &&
        lead.lead_status !== "cold"
      );
    }).length;

    return count;
  });
};
const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const countFollowupTakenLeadsByDate = (leads, dates) => {
  return dates.map((date) => {
    let dt = formatDate(date);
    let count = leads.filter((lead) => {
      return (
        Array.isArray(lead?.followUp) &&
        lead?.followUp?.some((followUp) => {
          return followUp?.followUpDate === dt && followUp?.followupTaken;
        })
      );
    }).length;
    return count;
  });
};
const StackedBarChart = ({ leadsData, leadsFrom = 30 }) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const { labels, dates } = generateDateLabels(leadsFrom);
    const leadCounts = countLeadsByDate(leadsData, dates);
    const filteredLeadCounts = countFilteredLeadsByDate(leadsData, dates);
    const followupTaken = countFollowupTakenLeadsByDate(leadsData, dates);

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Leads Created",
          data: leadCounts,
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
        {
          label: "Leads Filtered",
          data: filteredLeadCounts,
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
        {
          label: "Follow Up",
          data: followupTaken,
          backgroundColor: "rgba(153, 102, 255, 0.5)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
        },
      ],
    };

    setChartData(data);
  }, [leadsData, leadsFrom]);

  const options = {
    plugins: {
      tooltip: {
        theme: "dark",
      },
      legend: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 0,
        },
        title: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
          borderDash: [4],
        },
        ticks: {
          padding: 4,
        },
        title: {
          display: false,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 1,
        barThickness: "flex",
        maxBarThickness: 10,
      },
    },
    animation: {
      duration: 0,
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default StackedBarChart;
