import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { roundOffCost } from "../../../Components/Functions/amt.fun";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";

const ViewExpense = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  const navigate = useNavigate();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (data) {
      if (data?.uploadBill) {
        getUplodedImage(setImageUrls, data?.uploadBill, "uploadBill");
      }
    }
  }, [data]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/clientExpense/getClientExpenseById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Expense Tracking</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">View Expense</h2>
            </div>

            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Project</label>
                  <div className="w-100 col">
                    {data?.projectClientName || "N/A".toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Site holder</label>
                  <div className="w-100 col">
                    {(data?.siteClientName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Vendor</label>
                  <div className="w-100 col">
                    {(data?.vendorName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Expense Date</label>
                  <div className="w-100 col">{data?.expenseDate || "N/A"}</div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Expense type</label>
                  <div className="w-100 col">
                    {(data?.expenseType || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Expense amount (-GST)
                  </label>
                  <div className="w-100 col">
                    {data?.expenseAmount
                      ? roundOffCost(data?.expenseAmount)
                      : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">GST Rate</label>
                  <div className="w-100 col">
                    {data?.gstAmount ? `${data?.gstAmount} %` : "N/A"}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">GST amount</label>
                  <div className="w-100 col">
                    {data?.expenseAmount
                      ? roundOffCost(
                          +data?.expenseAmount * +data?.gstAmount * 0.01
                        )
                      : roundOffCost(0)}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    {" "}
                    Expense amount (+GST)
                  </label>
                  <div className="w-100 col">
                    {data.expenseAmount
                      ? roundOffCost(
                          +data.expenseAmount +
                            +data.expenseAmount * +data.gstAmount * 0.01
                        )
                      : roundOffCost(0)}
                  </div>
                </div>
              )}

              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Payment mode</label>
                  <div className="w-100 col">
                    {(data?.paymentMode || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Paid by</label>
                  <div className="w-100 col">
                    {(data?.paidBy || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Uploaded bill</label>
                  <div className="w-100 col">
                    {imageUrls?.uploadBill ? (
                      <img
                        width="70%"
                        src={imageUrls?.uploadBill}
                        alt={imageUrls?.uploadBill}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Notes</label>
                  <div className="w-100 col">
                    {(data?.notes || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText="Edit Expense"
                  href={`/client-expense-tracking/edit/${id}`}
                />
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewExpense;
