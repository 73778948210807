import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import LoadingPage from "../../../Components/layouts/loading";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import { ClientProjectHandoverInfo } from "../../../SchemaInfo/info";
import { getId } from "../../../Components/Functions/word.fun";
import { ClientProjectHandoverSchema } from "../../../Schemas/clients/client_project_handover_schema";
import { uploadImage } from "../../../CustomComponent/upload_Image";

const EditProjectHandover = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectHandoverInfo, setProjectHandoverInfo] = useState(
    ClientProjectHandoverInfo
  );
  const { id } = useParams();
  const [siteInspections, setSiteInspections] = useState([]);
  useEffect(() => {
    performRequest("GET", "/siteInspection/getallSiteInspection")
      .then((res) => setSiteInspections(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/handOver/getHandOverById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setProjectHandoverInfo(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  const navigate = useNavigate();
  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest("PATCH", `/handOver/updateHandOver/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Handover updated successfully!");
          navigate(`/client-project-handover`);
        } else {
          Toast("error", "Error while updating handover!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Project Handover</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Edit Handover</h2>
            </div>
            <Formik
              initialValues={projectHandoverInfo}
              validationSchema={ClientProjectHandoverSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Project
                      </label>

                      <div className="w-100 col">
                        <CustomSelect name="projectId" type="number">
                          <option value="">Select project</option>
                          {projects &&
                            projects?.map((pro) => {
                              return (
                                <option
                                  key={pro.cpNumber}
                                  value={getId(pro.cpNumber)}
                                >
                                  {pro.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>
                        {props.touched.projectId && props.errors.projectId && (
                          <p className="text-danger">
                            {props.errors.projectId}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Site inspection
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="siteSelectionId">
                          <option value="">Select</option>
                          {siteInspections &&
                            siteInspections?.map((si) => {
                              return (
                                <option
                                  key={si.siNumber}
                                  value={getId(si.siNumber)}
                                >
                                  {si.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>

                        {props.touched.siteSelectionId &&
                          props.errors.siteSelectionId && (
                            <p className="text-danger">
                              {props.errors.siteSelectionId}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label ">Handover date</label>
                      <div className="w-100 col">
                        <CustomInput name="handOverDate" type="date" />
                        {props.touched.handOverDate &&
                          props.errors.handOverDate && (
                            <p className="text-danger">
                              {props.errors.handOverDate}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        North south measurment in meter
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="northSouthMeasurmentInMeter"
                          type="text"
                        />
                        {props.touched.northSouthMeasurmentInMeter &&
                          props.errors.northSouthMeasurmentInMeter && (
                            <p className="text-danger">
                              {props.errors.northSouthMeasurmentInMeter}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        North south measurment photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="northSouthMeasurmentPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "northSouthMeasurmentPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.northSouthMeasurmentPhoto &&
                          props.errors.northSouthMeasurmentPhoto && (
                            <p className="text-danger">
                              {props.errors.northSouthMeasurmentPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        East west measurment in meter
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="eastWestMeasurmentInMeter"
                          type="text"
                        />
                        {props.touched.eastWestMeasurmentInMeter &&
                          props.errors.eastWestMeasurmentInMeter && (
                            <p className="text-danger">
                              {props.errors.eastWestMeasurmentInMeter}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        East west measurment photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="eastWestMeasurmentPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "eastWestMeasurmentPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.eastWestMeasurmentPhoto &&
                          props.errors.eastWestMeasurmentPhoto && (
                            <p className="text-danger">
                              {props.errors.eastWestMeasurmentPhoto}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Description video
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          type={"file"}
                          name="descriptionVideo"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "descriptionVideo",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.descriptionVideo &&
                          props.errors.descriptionVideo && (
                            <p className="text-danger">
                              {props.errors.descriptionVideo}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Tank photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="overheadTankPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "overheadTankPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.overheadTankPhoto &&
                          props.errors.overheadTankPhoto && (
                            <p className="text-danger">
                              {props.errors.overheadTankPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        A-Frame photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="aFramePhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(props, "aFramePhoto", e.target.files[0])
                          }
                        />
                        {props.touched.aFramePhoto &&
                          props.errors.aFramePhoto && (
                            <p className="text-danger">
                              {props.errors.aFramePhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Net-cup photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="netCupPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(props, "netCupPhoto", e.target.files[0])
                          }
                        />
                        {props.touched.netCupPhoto &&
                          props.errors.netCupPhoto && (
                            <p className="text-danger">
                              {props.errors.netCupPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        A-Frame foundation photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="aFrameFoundationPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "aFrameFoundationPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.aFrameFoundationPhoto &&
                          props.errors.aFrameFoundationPhoto && (
                            <p className="text-danger">
                              {props.errors.aFrameFoundationPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Vent opener photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="ventOpenerPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "ventOpenerPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.ventOpenerPhoto &&
                          props.errors.ventOpenerPhoto && (
                            <p className="text-danger">
                              {props.errors.ventOpenerPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Aluminium shade net fully photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="aluminiumShadeNetPullyPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "aluminiumShadeNetPullyPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.aluminiumShadeNetPullyPhoto &&
                          props.errors.aluminiumShadeNetPullyPhoto && (
                            <p className="text-danger">
                              {props.errors.aluminiumShadeNetPullyPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Exhaust fan photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="exhaustFanPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "exhaustFanPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.exhaustFanPhoto &&
                          props.errors.exhaustFanPhoto && (
                            <p className="text-danger">
                              {props.errors.exhaustFanPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        East-West measurment photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="eastWestMeasurmentPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "eastWestMeasurmentPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.eastWestMeasurmentPhoto &&
                          props.errors.eastWestMeasurmentPhoto && (
                            <p className="text-danger">
                              {props.errors.eastWestMeasurmentPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Celluse pad photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="cellusePadPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "cellusePadPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.cellusePadPhoto &&
                          props.errors.cellusePadPhoto && (
                            <p className="text-danger">
                              {props.errors.cellusePadPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water pump photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="waterPumpPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "waterPumpPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.waterPumpPhoto &&
                          props.errors.waterPumpPhoto && (
                            <p className="text-danger">
                              {props.errors.waterPumpPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water pump drainage photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="waterPumpDrainagePhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "waterPumpDrainagePhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.waterPumpDrainagePhoto &&
                          props.errors.waterPumpDrainagePhoto && (
                            <p className="text-danger">
                              {props.errors.waterPumpDrainagePhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Oxigenation pump photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="oxigenationPumpPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "oxigenationPumpPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.oxigenationPumpPhoto &&
                          props.errors.oxigenationPumpPhoto && (
                            <p className="text-danger">
                              {props.errors.oxigenationPumpPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        UV light photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="uvLightPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "uvLightPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.uvLightPhoto &&
                          props.errors.uvLightPhoto && (
                            <p className="text-danger">
                              {props.errors.uvLightPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Disk filter photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="diskFilterPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "diskFilterPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.diskFilterPhoto &&
                          props.errors.diskFilterPhoto && (
                            <p className="text-danger">
                              {props.errors.diskFilterPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Sand filter photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="sandFilterPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "sandFilterPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.sandFilterPhoto &&
                          props.errors.sandFilterPhoto && (
                            <p className="text-danger">
                              {props.errors.sandFilterPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Fogger assembly photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="foggerAssemblyPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "foggerAssemblyPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.foggerAssemblyPhoto &&
                          props.errors.foggerAssemblyPhoto && (
                            <p className="text-danger">
                              {props.errors.foggerAssemblyPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Customer photo with setup
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="customerPhotoWithSetUp"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "customerPhotoWithSetUp",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.customerPhotoWithSetUp &&
                          props.errors.customerPhotoWithSetUp && (
                            <p className="text-danger">
                              {props.errors.customerPhotoWithSetUp}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Handover certificate
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="handOverCertificate"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "handOverCertificate",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.handOverCertificate &&
                          props.errors.handOverCertificate && (
                            <p className="text-danger">
                              {props.errors.handOverCertificate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Customer feedback video
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="customerFeedbackVideo"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "customerFeedbackVideo",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.customerFeedbackVideo &&
                          props.errors.customerFeedbackVideo && (
                            <p className="text-danger">
                              {props.errors.customerFeedbackVideo}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>
                      <div className="w-100 col">
                        <CustomTextArea
                          name="notes"
                          type="textarea"
                          rows={"10"}
                        ></CustomTextArea>
                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Handover" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default EditProjectHandover;
