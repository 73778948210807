import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { FieldArray, Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import { useSelector } from "react-redux";
import LoadingPage from "../../../Components/layouts/loading";
import { nurseryBatchInfo } from "../../../SchemaInfo/info";
import { nurseryBatchSchema } from "../../../Schemas/Farm support/nursery_management_schema";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";

import { FcStackOfPhotos } from "react-icons/fc";
import { FaLeaf } from "react-icons/fa6";

const getSchedule = (day) => {
  if (day === 1) {
    return "";
  }
  if (day === 2) {
    return "Cover seed packets";
  }
  if (day === 3) {
    return "Keep covered";
  }
  if (day === 4) {
    return "";
  }
  if (day === 5) {
    return "check for sprouting";
  }
  if (day === 6) {
    return "Spread the trays";
  }
  if (day === 7) {
    return "Calculate germination rate and add new seeds if needed.";
  }
  if (day === 16) {
    return "Watering + neem oil sprey (1ml/ltr)";
  }
  if (day === 25) {
    return "Watering + chelated ferrous (1gm/ltr)";
  }
  if (day === 28) {
    return "Watering + fertilizers 19:19:19 (1.5gm/ltr)";
  }
  if (day === 16) {
    return "Watering + neem oil sprey (1ml/ltr)";
  } else {
    if (day % 3 === 1 && day > 7) {
      return "Watering + fertilizers 19:19:19 (1gm/ltr)";
    } else {
      return "Watering";
    }
  }
};

const getActions = (day) => {
  if (day === 1) {
    return "Wash trays with H2O2 or 5ml phDown in 10ltr water.";
  }
  if (day === 15) {
    return "Check plant have 2 leaves. If not connect with agronomist.";
  }
  if (day === 21) {
    return "Below tray see if you can see the roots of plants.";
  }
  if (day === 29) {
    return "Try to pull the plant by holding at stem.";
  }
  if (day === 30) {
    return "Plant are ready to transfer in the system.";
  } else {
    return "";
  }
};
const generateDateLabels = (startdate) => {
  const schedule = [];
  for (let i = 0; i < 30; i++) {
    const date = new Date(startdate);
    date.setDate(date.getDate() + i);

    schedule.push({
      date: date.toISOString().split("T")[0],
      schedule: getSchedule(i + 1),
      actions: getActions(i + 1),
      scheduleDone: false,
      scheduleDoneDate: "",
    });
  }

  return schedule;
};

const getScheduleBadge = (schedule) => {
  if (schedule === "Watering") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>{" "}
      </p>
    );
  }
  if (schedule === "Watering + fertilizers 19:19:19 (1gm/ltr)") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>
        <span className="badge bg-orange text-white m-1">
          Fertilizers NPK(19-19-19) (1gm/ltr)
        </span>
      </p>
    );
  }
  if (schedule === "Watering + fertilizers 19:19:19 (1.5gm/ltr)") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>
        <span className="badge bg-orange text-white m-1">
          Fertilizers NPK(19-19-19) (1.5gm/ltr)
        </span>
      </p>
    );
  }
  if (schedule === "Watering + neem oil sprey (1ml/ltr)") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>
        <span className="badge bg-purple text-white m-1">
          Neem oil sprey (3000PPM) (1ml/ltr)
        </span>
      </p>
    );
  }
  if (schedule === "Watering + chelated ferrous (1gm/ltr)") {
    return (
      <p className="m-0 p-0">
        <span className="badge bg-azure text-white m-1">Watering</span>
        <span className="badge bg-purple text-white m-1">
          Chelated ferrous (1ml/ltr)
        </span>
      </p>
    );
  } else {
    return schedule;
  }
};

const getBg = (date) => {
  const dNow = new Date().toISOString().split("T")[0];
  if (date === dNow) {
    return "bg-blue-lt";
  } else {
    return "";
  }
};
const EditBatch = () => {
  const store = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [nurseryBatchData, setNurseryBatchData] = useState(nurseryBatchInfo);
  const navigate = useNavigate();
  const { id } = useParams();
  const [plantList, setPlantList] = useState();

  useEffect(() => {
    performRequest("GET", `/agronomyWiki/getAllAgronomyWiki/`)
      .then((res) => {
        if (res.status === 200) {
          setPlantList(res?.data?.data);
        } else {
          Toast("error", "Error while getting Data!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/nurseryManagement/getNurseryManagementById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setNurseryBatchData(res.data);
        } else {
          Toast("error", "Error while getting batch details!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  const handelSubmit = (values) => {
    const date = new Date(values.batchStartDate);
    date.setDate(date.getDate() + 29);
    values = {
      ...values,
      generatedBy: store.UserId,
      cropName: +values.cropName,
      batchEndDate: date.toISOString().split("T")[0],
    };
    setLoading(true);
    performRequest(
      "PATCH",
      `/nurseryManagement/updateNurseryManagement/${id}`,
      values
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Batch deatils updated successfully!");
          navigate(`/farm-nursery-management/view/${id}`);
        } else {
          Toast("error", "Error while updating Batch!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcStackOfPhotos className="fs-2  me-1" />
                  Nursery Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Edit batch of nursery.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a
                    href={"/farm-nursery-management/"}
                    className="btn btn-primary"
                  >
                    {" "}
                    <FaLeaf className="fs-3 me-2" /> All Batches
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <h2>Precautions for nursery:</h2>
                <ol>
                  <li>Always use new low ec or buffered cocopeat.</li>
                  <li>
                    ⁠Use new seeds and read truthful label for expiry of seeds.
                  </li>
                  <li>
                    ⁠Wash trays thoroughly with hydrogen paraoxide for higher
                    germination.
                  </li>
                  <li>
                    Always plant 10% extra seeds then your required plant count.
                  </li>
                  <li>Do not miss your schedule.</li>
                  <li>⁠Take actions as suggested.</li>
                  <li>
                    If you are stuck with progress or results, contact your
                    agronomist or relation manager.{" "}
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={nurseryBatchData}
                  validationSchema={nurseryBatchSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Batch name
                              </label>
                              <div className="w-100 col">
                                <CustomInput name="batchName" disabled />

                                {props.touched.batchName &&
                                  props.errors.batchName && (
                                    <p className="text-danger">
                                      {props.errors.batchName}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Crop name
                              </label>
                              <div className="w-100 col">
                                <CustomSelect
                                  type="text"
                                  name="cropName"
                                  disabled
                                >
                                  <option value="">Select</option>
                                  {plantList &&
                                    plantList?.map((plant) => {
                                      return (
                                        <option key={plant.id} value={plant.id}>
                                          {plant.name}
                                        </option>
                                      );
                                    })}
                                </CustomSelect>

                                {props.touched.cropName &&
                                  props.errors.cropName && (
                                    <p className="text-danger">
                                      {props.errors.cropName}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Number of plants
                              </label>
                              <div className="w-100 col">
                                <CustomInput name="numberOfplants" disabled />

                                {props.touched.numberOfplants &&
                                  props.errors.numberOfplants && (
                                    <p className="text-danger">
                                      {props.errors.numberOfplants}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label col-3 required">
                                Batch start date
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  disabled
                                  name="batchStartDate"
                                  type={"date"}
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "batchStartDate",
                                      e.target.value
                                    );
                                    props.setFieldValue(
                                      "schedule",
                                      generateDateLabels(e.target.value)
                                    );
                                  }}
                                />

                                {props.touched.batchStartDate &&
                                  props.errors.batchStartDate && (
                                    <p className="text-danger">
                                      {props.errors.batchStartDate}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {props.values.batchName &&
                          props.values.cropName &&
                          props.values.numberOfplants &&
                          props.values.batchStartDate && (
                            <div className="card table-responsive">
                              <FieldArray name="schedule">
                                {({ push, remove }) => (
                                  <table className="table table-vcenter">
                                    <thead>
                                      <tr>
                                        <th>Day</th>
                                        <th>schedule on</th>
                                        <th>Schedule work</th>
                                        {/* <th>Actions</th> */}
                                        <th>Mark work done</th>
                                        <th>Work done date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {props.values?.schedule?.map(
                                        (schedule, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              className={getBg(schedule.date)}
                                            >
                                              <td>{index + 1}</td>
                                              <td>{schedule.date}</td>
                                              <td>
                                                {schedule.schedule
                                                  ? getScheduleBadge(
                                                      schedule.schedule
                                                    )
                                                  : "---"}
                                                {schedule.actions || ""}
                                              </td>
                                              <td></td>
                                              <td>
                                                <CustomCheckbox
                                                  disabled={
                                                    nurseryBatchData?.schedule[
                                                      index
                                                    ].scheduleDone
                                                  }
                                                  type="checkbox"
                                                  width={"25px"}
                                                  height={"25px"}
                                                  name={`schedule[${index}].scheduleDone`}
                                                  onChange={(e) => {
                                                    props.setFieldValue(
                                                      `schedule[${index}].scheduleDone`,
                                                      !schedule.scheduleDone
                                                    );
                                                    let date = new Date();
                                                    props.setFieldValue(
                                                      `schedule[${index}].scheduleDoneDate`,
                                                      !schedule.scheduleDone
                                                        ? date
                                                            .toISOString()
                                                            .split("T")[0]
                                                        : ""
                                                    );
                                                  }}
                                                ></CustomCheckbox>
                                              </td>
                                              <td>
                                                {schedule.scheduleDoneDate ||
                                                  "---"}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                )}
                              </FieldArray>
                            </div>
                          )}
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="save Batch" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default EditBatch;
