import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart,
  LineElement,
  LinearScale,
  PointElement,
} from "chart.js";
Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

const secondsToHours = (seconds) => {
  return (seconds / 3600).toFixed(2);
};
const extractChartData = (key, data) => {
  const labels = data?.daily?.time.filter((_, index) => index % 7 === 0) || [];
  const filteredData =
    data?.daily?.[key].filter((_, index) => index % 7 === 0) || [];

  if (key === "daylight_duration" || key === "sunshine_duration") {
    return {
      labels: labels,
      datasets: [
        {
          label: key,
          data: filteredData,
          borderColor:
            key === "daylight_duration"
              ? "rgba(255,205,86,1)"
              : "rgba(54,162,235,1)",
          backgroundColor:
            key === "daylight_duration"
              ? "rgba(255,205,86,0.2)"
              : "rgba(54,162,235,0.2)",
          fill: false,
          yAxisID: key === "daylight_duration" ? "y-axis-1" : "y-axis-2",
        },
      ],
    };
  }
  if (
    key === "relative_humidity_2m_min" ||
    key === "relative_humidity_2m_max"
  ) {
    return {
      labels: labels,
      datasets: [
        {
          label: key,
          data: filteredData,
          borderColor:
            key === "relative_humidity_2m_max"
              ? "rgba(255,205,86,1)"
              : "rgba(54,162,235,1)",
          backgroundColor:
            key === "relative_humidity_2m_max"
              ? "rgba(255,205,86,0.2)"
              : "rgba(54,162,235,0.2)",
          fill: false,
          yAxisID: key === "relative_humidity_2m_max" ? "y-axis-3" : "y-axis-4",
        },
      ],
    };
  }
  if (key === "rain_sum") {
    return {
      labels: labels,
      datasets: [
        {
          label: key,
          data: filteredData,
          borderColor: "#34A853",
          backgroundColor: "#34A853",
          fill: false,
          yAxisID: "y-axis-5",
        },
      ],
    };
  }

  return {
    labels: labels,
    datasets: [
      {
        label: key,
        data: filteredData,
        borderColor:
          key === "temperature_2m_max"
            ? "rgba(75,192,192,1)"
            : "rgba(255,99,132,1)",
        backgroundColor:
          key === "temperature_2m_max"
            ? "rgba(75,192,192,0.2)"
            : "rgba(255,99,132,0.2)",
        fill: false,
        yAxisID: key === "temperature_2m_max" ? "y-axis-6" : "y-axis-7",
      },
    ],
  };
};

// const options = {
//   scales: {
//     x: {
//       title: {
//         display: true,
//         text: "Date",
//       },
//     },
//     yAxes: [
//       {
//         id: "y-axis-1",
//         type: "linear",
//         position: "left",
//         ticks: {
//           callback: function (value) {
//             return value + (value === "0" ? "h" : " hrs");
//           },
//         },
//         scaleLabel: {
//           display: true,
//           labelString: "Duration (hrs)",
//         },
//       },
//       {
//         id: "y-axis-2",
//         type: "linear",
//         position: "right",
//         ticks: {
//           callback: function (value) {
//             return value + "°C";
//           },
//         },
//         scaleLabel: {
//           display: true,
//           labelString: "Temperature (°C)",
//         },
//       },
//     ],
//   },
// };

const options = {
  scales: {
    x: {
      title: {
        display: true,
        text: "Date",
      },
    },
    y: [
      {
        id: "y-axis-1",
        type: "linear",
        position: "left",
        ticks: {
          callback: function (value) {
            return value + (value === "0" ? "h" : " hrs");
          },
        },
        scaleLabel: {
          display: true,
          labelString: "Duration (hrs)",
        },
      },
      {
        id: "y-axis-2",
        type: "linear",
        position: "left",
        ticks: {
          callback: function (value) {
            return value + (value === "0" ? "h" : " hrs");
          },
        },
        scaleLabel: {
          display: true,
          labelString: "Duration (hrs)",
        },
      },
      {
        id: "y-axis-3",
        type: "linear",
        position: "left",
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
        scaleLabel: {
          display: true,
          labelString: "Humidity (%)",
        },
      },
      {
        id: "y-axis-4",
        type: "linear",
        position: "left",
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
        scaleLabel: {
          display: true,
          labelString: "Humidity (%)",
        },
      },
      {
        id: "y-axis-5",
        type: "linear",
        position: "right",
        ticks: {
          callback: function (value) {
            return value + "mm";
          },
        },
        scaleLabel: {
          display: true,
          labelString: "Rain (mm)",
        },
      },
      {
        id: "y-axis-6",
        type: "linear",
        position: "left",
        ticks: {
          callback: function (value) {
            return value + "°C";
          },
        },
        scaleLabel: {
          display: true,
          labelString: "Temperature (°C)",
        },
      },
      {
        id: "y-axis-7",
        type: "linear",
        position: "left",
        ticks: {
          callback: function (value) {
            return value + "°C";
          },
        },
        scaleLabel: {
          display: true,
          labelString: "Temperature (°C)",
        },
      },
    ],
  },
};

function getDateRange(dateString) {
  // Parse the given date string
  const date = new Date(dateString);

  // Calculate the start date: date - 5 days - 1 year
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() - 5);
  startDate.setFullYear(startDate.getFullYear() - 1);

  // Calculate the end date: date - 5 days
  const endDate = new Date(date);
  endDate.setDate(endDate.getDate() - 5);

  // Format dates as YYYY-MM-DD strings
  const formatDate = (d) => {
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return { startDate: formatDate(startDate), endDate: formatDate(endDate) };
}

const ClimateGraphs = ({ SIData }) => {
  const [data, setData] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [humidityData, setHumidityData] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    if (SIData?.land?.googleMapsLocationLink) {
      let [latitude, longitude] = SIData?.land?.googleMapsLocationLink
        .trim()
        .split(",")
        .map(Number);
      setLatitude(latitude);
      setLongitude(longitude);
    }
    if (SIData?.createdAt) {
      let dates = getDateRange(SIData?.createdAt);
      setStartDate(dates?.startDate);
      setEndDate(dates?.endDate);
    }
  }, [SIData]);
  useEffect(() => {
    if ((latitude && longitude && startDate, endDate)) {
      let apiUrl = `https://archive-api.open-meteo.com/v1/archive?latitude=${latitude}&longitude=${longitude}&start_date=${startDate}&end_date=${endDate}&daily=temperature_2m_max,temperature_2m_min,daylight_duration,sunshine_duration,rain_sum&timezone=GMT`;
      axios
        .get(apiUrl)
        .then((res) => setData(res.data))
        .catch((err) => console.error(err));
    }
  }, [latitude, longitude, startDate, endDate]);
  useEffect(() => {
    if ((latitude && longitude && startDate, endDate)) {
      let apiUrl = `https://archive-api.open-meteo.com/v1/archive?latitude=${latitude}&longitude=${longitude}&start_date=${startDate}&end_date=${endDate}&&daily=relative_humidity_2m_max,relative_humidity_2m_min`;
      axios
        .get(apiUrl)
        .then((res) => setHumidityData(res.data))
        .catch((err) => console.error(err));
    }
  }, [latitude, longitude, startDate, endDate]);

  return (
    <>
      {data && (
        <div>
          <h3>Daily Weather Data:</h3>
          {data?.daily?.temperature_2m_max &&
            data?.daily?.temperature_2m_max.length > 10 && (
              <div>
                <div>
                  <h3>Max Temperature (°C)</h3>
                  <div
                    style={{
                      display: "flex",
                      height: "350px",
                      width: "95%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Line
                      data={extractChartData("temperature_2m_max", data)}
                      options={options}
                    />
                  </div>
                </div>
              </div>
            )}

          {data?.daily?.temperature_2m_min &&
            data?.daily?.temperature_2m_min.length > 10 && (
              <div>
                <h3>Min Temperature (°C)</h3>
                <div
                  style={{
                    display: "flex",
                    height: "350px",
                    width: "95%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Line
                    data={extractChartData("temperature_2m_min", data)}
                    options={options}
                  />
                </div>
                <br />
              </div>
            )}
          <div
            className="html2pdf__page-break"
            style={{
              pageBreakAfter: "always",
              display: "block",
              clear: "both",
            }}
          ></div>
          {data?.daily?.daylight_duration &&
            data?.daily?.daylight_duration.length > 10 && (
              <div>
                <h3>Daylight Duration (hrs)</h3>
                <div
                  style={{
                    display: "flex",
                    height: "350px",
                    width: "95%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Line
                    data={extractChartData("daylight_duration", data)}
                    options={options}
                  />
                </div>
              </div>
            )}
          {data?.daily?.sunshine_duration &&
            data?.daily?.sunshine_duration.length > 10 && (
              <div>
                <h3>Sunshine Duration (hrs)</h3>
                <div
                  style={{
                    display: "flex",
                    height: "350px",
                    width: "95%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Line
                    data={extractChartData("sunshine_duration", data)}
                    options={options}
                  />
                </div>
              </div>
            )}
          <div
            className="html2pdf__page-break"
            style={{
              pageBreakAfter: "always",
              display: "block",
              clear: "both",
            }}
          ></div>
          {data?.daily?.rain_sum && data?.daily?.rain_sum.length > 10 && (
            <div>
              <h3>Rain Sum (mm)</h3>
              <div
                style={{
                  display: "flex",
                  height: "350px",
                  width: "95%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Line
                  data={extractChartData("rain_sum", data)}
                  options={options}
                />
              </div>
            </div>
          )}
          {humidityData?.daily?.relative_humidity_2m_min &&
            humidityData?.daily?.relative_humidity_2m_min.length > 10 && (
              <div
                className="html2pdf__page-break"
                style={{
                  pageBreakAfter: "always",
                  display: "block",
                  clear: "both",
                }}
              ></div>
            )}

          {humidityData?.daily?.relative_humidity_2m_min &&
            humidityData?.daily?.relative_humidity_2m_min.length > 10 && (
              <div>
                <h3>Relative Humidity Min (%)</h3>
                <div
                  style={{
                    display: "flex",
                    height: "350px",
                    width: "95%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Line
                    data={extractChartData(
                      "relative_humidity_2m_min",
                      humidityData
                    )}
                    options={options}
                  />
                </div>
              </div>
            )}

          {humidityData?.daily?.relative_humidity_2m_max &&
            humidityData?.daily?.relative_humidity_2m_max.length > 10 && (
              <div>
                <h3>Relative Humidity Max (%)</h3>
                <div
                  style={{
                    display: "flex",
                    height: "350px",
                    width: "95%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Line
                    data={extractChartData(
                      "relative_humidity_2m_max",
                      humidityData
                    )}
                    options={options}
                  />
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default ClimateGraphs;
