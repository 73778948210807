import React from "react";
import { useNavigate } from "react-router-dom";
const tableHead = [
  "Sr.",
  "Project",
  "Cea Type",
  "Bay * Span",
  "Hydroponic Type",
  "hocky available",
  "Actions",
];
const getHydroType = (item) => {
  let bag = [];
  if (item.aFramePercent > 0) {
    bag.push(`A-FRAME(${item.aFramePercent}%)`);
  }
  if (item.flatBedPercent > 0) {
    bag.push(`FB(${item.flatBedPercent}%)`);
  }
  if (item.growBagPercent > 0) {
    bag.push(`GB(${item.growBagPercent}%)`);
  }
  if (item.dutchBucketPercent > 0) {
    bag.push(`DB(${item.dutchBucketPercent}%)`);
  }
  return bag.join("+ ");
};
const ProjectSpecificationTable = ({ PS = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/project-specification/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {PS?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.clientName || "N/A"}</td>
                <td>{item?.ceaType || "N/A"}</td>
                <td>
                  {item?.baySize && item?.baySize
                    ? `${item?.baySize} * ${item?.spanSize}`
                    : "N/A"}
                </td>
                <td>{getHydroType(item)}</td>
                <td>{item?.hocky ? "Yes" : "No"}</td>
                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default ProjectSpecificationTable;
