import React, { useState, useEffect } from "react";

const LoginTimeCounter = ({ loginTime }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const parseTime = (time) => {
      const [timePart, period] = time.split(" ");
      const [hours, minutes, seconds] = timePart.split(":");
      let hrs = parseInt(hours, 10);
      const mins = parseInt(minutes, 10);
      const secs = parseInt(seconds, 10);

      if (period === "PM" && hrs !== 12) {
        hrs += 12;
      } else if (period === "AM" && hrs === 12) {
        hrs = 0;
      }

      const date = new Date();
      date.setHours(hrs, mins, secs, 0);
      return date;
    };

    const loginDate = parseTime(loginTime);

    const now = new Date();
    const initialDiffInSeconds = Math.floor((now - loginDate) / 1000);
    setElapsedTime(initialDiffInSeconds);

    const intervalId = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [loginTime]);

  const formatTime = (seconds) => {
    const absSeconds = Math.abs(seconds);
    const hours = Math.floor(absSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((absSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (absSeconds % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${secs}`;
  };

  return (
    <span>
      <span className="badge bg-green text-green-fg tag-status badge-blink badge-empty mx-1 "></span>
      {formatTime(elapsedTime)}
    </span>
  );
};

export default LoginTimeCounter;
