import * as yup from "yup";
export const lineItemSchema = yup.object().shape({
  particulars: yup.string(),
  totalWithoutGst: yup.string(),
  totalWithGst: yup.string(),
  itemUnit: yup.string(),
  itemQuantity: yup.string(),
  discount: yup.string(),
  itemPrice: yup.string(),
  gstRate: yup.string(),
  deliveryDate: yup.string(),
  notes: yup.string(),
});

export const PurchaseOrderSchema = yup.object().shape({
  project: yup.string().required("Required"),
  vendor: yup.string().required("Required"),
  pdfStatus: yup.boolean(),
  countryOfSupply: yup.string(),
  placeOfSupply: yup.string(),
  generatedBy: yup.string(),
  vendorPurchaseOrderDate: yup.date(),
  vendorPurchaseOrderFor: yup.string(),
  lineItems: yup.array().of(lineItemSchema),
});
