import * as yup from "yup";

export const WorkforceCreateSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string(),
  emailAddress: yup.string(),
  dateOfJoining: yup.date(),
  dateOfBirth: yup.date(),
  contactNumber: yup.string(),
  aadharNumber: yup.string(),
  panNumber: yup.string(),
  permanentAddress: yup.string(),
  emergencyContactName: yup.string(),
  emergencyContactNumber: yup.string(),
  emergencyContactRelation: yup.string(),
  uploadPhoto: yup.mixed(),
  uploadAadharFront: yup.mixed(),
  uploadAadharBack: yup.mixed(),
  salary: yup.string(),
  salaryFrequency: yup.string(),
  salaryFrequencyType: yup.string(),
});
