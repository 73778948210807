import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { useSelector } from "react-redux";
import { capitalizeFirstLetterOfEachWord } from "../../../Components/Functions/word.fun";

import { FcWikipedia } from "react-icons/fc";
import { BsBoxArrowInDownRight } from "react-icons/bs";

const ViewAgronomyWiki = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/agronomyWiki/getAgronomyWikiById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcWikipedia className="fs-2  me-1" /> Plant Wiki
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Learn everything about plants you are
                  growing.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/agronomy-plants-wiki"}>
                    <button className="btn btn-primary">
                      <BsBoxArrowInDownRight className="me-2 fs-2" /> All Plants
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 my-3">
              {data && (
                <div className="card p-4">
                  <div className="row">
                    <div className="col-12">
                      <p className="text-center mt-4">
                        {data?.image ? (
                          <img
                            width="75%"
                            src={data?.image}
                            alt={data?.image}
                          />
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>

                    <div className="col-12 text-center">
                      <h3 className="p-0 m-0">
                        {capitalizeFirstLetterOfEachWord(data?.name) || "N/A"}
                      </h3>
                      <p className="p-0 m-0">
                        {capitalizeFirstLetterOfEachWord(
                          data?.scientificName
                        ) || "N/A"}
                      </p>
                    </div>

                    <div className="col-12 my-4">
                      <div className="row mt-2">
                        <div className="col-6">
                          <a href={data?.seedUrl}>
                            {" "}
                            <button className="btn btn-success w-100">
                              Purchase Seed
                            </button>
                          </a>
                        </div>
                        <div className="col-6">
                          <a href="/farm-nursery-management/new-batch">
                            <button className="btn btn-outline w-100">
                              Start Batch
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-2">
                      <div class="table-responsive card">
                        <table class="table table-vcenter card-table table-striped">
                          <thead>
                            <tr>
                              <th>Spec</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Article Type</td>
                              <td>
                                {capitalizeFirstLetterOfEachWord(
                                  data?.articleType
                                ) || "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td>Classification</td>
                              <td>
                                {capitalizeFirstLetterOfEachWord(
                                  data?.classification
                                ) || "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <td>Sub-Category</td>
                              <td>
                                {capitalizeFirstLetterOfEachWord(
                                  data?.subCategory
                                ) || "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td>pH Range</td>
                              <td>
                                {data?.minPh || "N/A"} to {data?.maxPh || "N/A"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>TDS</td>
                              <td>{data?.maxTds || "N/A"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-9 col-sm-12 my-3">
              <div className="card p-4">
                <h3>Description</h3>
                <p>
                  {capitalizeFirstLetterOfEachWord(data?.description) || "N/A"}
                </p>
                <hr />
                <h3>Growing Temparature</h3>
                <p>
                  The {capitalizeFirstLetterOfEachWord(data?.name) || "N/A"}{" "}
                  plant, belonging to the
                  {capitalizeFirstLetterOfEachWord(data?.articleType) ||
                    "N/A"}{" "}
                  category, flourishes best within specific environmental
                  parameters. For optimal growth, the temperature should be
                  maintained between {data?.minGrowingTemperature || "N/A"}°C
                  and {data?.maxGrowingTemperature || "N/A"}°C.
                </p>
                <hr />
                <h3>Growing Humidity</h3>
                <p>
                  The {capitalizeFirstLetterOfEachWord(data?.name) || "N/A"}{" "}
                  plant, belonging to the
                  {capitalizeFirstLetterOfEachWord(data?.articleType) ||
                    "N/A"}{" "}
                  category, The plant requires a humidity range of{" "}
                  {data?.minGrowingHumidity || "N/A"}% to{" "}
                  {data?.maxGrowingHumidity || "N/A"}% to thrive. These
                  conditions ensure the plant achieves its best{" "}
                  {capitalizeFirstLetterOfEachWord(data?.articleType) || "N/A"}{" "}
                  growth and health, making it crucial for gardeners and
                  cultivators to monitor and maintain these parameters for
                  successful cultivation.
                </p>
                <hr />
                <h3>Seedling Precautions</h3>
                <p>
                  When selecting seeds for planting, it is crucial to opt for
                  high-quality seeds to ensure successful growth and
                  development. Begin by choosing seeds from a reputable source,
                  as this guarantees that the seeds have been properly stored
                  and handled, thereby maintaining their quality. It is also
                  important to check the packaging dates and germination rates;
                  fresher seeds generally have higher germination rates, which
                  increases the likelihood of successful sprouting.
                  Additionally, examine the seeds for any signs of disease or
                  physical damage, as healthy, undamaged seeds are essential for
                  robust plant development. By prioritizing high-quality seeds,
                  gardeners and cultivators can set a solid foundation for a
                  thriving and productive garden.
                </p>
                <hr />
                <h3>Germination Temperature</h3>
                <p>
                  Effective temperature control is essential for successful seed
                  germination, as the optimal temperature range varies by plant
                  species. For most plants, maintaining a temperature between
                  10°C and 40°C is ideal for promoting germination. Ensuring the
                  correct temperature is critical, as temperatures outside this
                  range can inhibit or delay the germination process. For indoor
                  growing, utilizing seedling heat mats or heat lamps can help
                  regulate and maintain the necessary warmth, providing a
                  consistent and controlled environment for the seeds. These
                  tools are especially useful in achieving and sustaining the
                  optimal temperature range, thereby enhancing the chances of
                  successful germination and healthy seedling development. By
                  carefully managing temperature, gardeners and cultivators can
                  create the best possible conditions for their seeds to thrive.
                </p>
                <hr />
                <h3>Germination moisture</h3>
                <p>
                  Maintaining consistent moisture in the germination medium is
                  crucial for seed sprouting. It is important to keep the medium
                  moist but not waterlogged, as excessive water can drown the
                  seeds and hinder their development. Using a spray bottle for
                  watering is recommended to gently moisten the medium without
                  disturbing the delicate seeds. The frequency of watering
                  should be adjusted based on the surrounding temperature. If
                  the temperature is above 30°C, watering twice a day is
                  necessary to prevent the medium from drying out. Conversely,
                  if the temperature is below 30°C, watering once a day is
                  usually sufficient to maintain the required moisture levels.
                  By carefully managing the moisture and adjusting the watering
                  frequency according to the temperature, gardeners can create
                  optimal conditions for seed germination.
                </p>
                <hr />
                <h3>Cocopeat Options</h3>
                <p>
                  Cocopeat is availabile in multiple options like bufferent,
                  washed, high EC, low EC. For commercial usage we always go for
                  High EC cocopeat, mainly for two reasons, low cost of
                  fertilizers to operate, also low cost of purchase and storage.
                </p>
                <p>
                  High EC cocopeat is widely used for growbags or growslabs.
                  High EC cocopeat needs to be buffered before using for
                  germination.
                </p>
                <hr />
                <h3>Cocopeat Buffering for germination</h3>
                <p>
                  Buffering cocopeat (coco coir) for germination is essential to
                  ensure it is free from salts and has a balanced pH, making it
                  suitable for seed germination and plant growth. Buffered
                  cocopeat provides a stable and nutrient-rich growing medium
                  for seed germination, promoting healthy root development and
                  vigorous plant growth. Here is a step-by-step guide to buffer
                  cocopeat for germination:
                </p>
                <h4>Materials Needed:</h4>
                <ol>
                  <li>Cocopeat block or loose cocopeat.</li>
                  <li>Calcium nitrate (Ca(NO₃)₂).</li>
                  <li>Magnesium sulfate (MgSO₄)</li>
                  <li>Water</li>
                  <li>A large container or bucket</li>
                  <li>pH meter or pH test strips</li>
                  <li>EC meter (optional)</li>
                </ol>
                <h4>Steps:</h4>
                <ol>
                  <li>
                    <h4>Hydrate the Cocopeat:</h4>
                    <ol>
                      <li>
                        Break the cocopeat block into smaller pieces if
                        necessary.
                      </li>
                      <li>
                        Place the cocopeat in a large container or bucket.
                      </li>
                      <li>
                        Add water to the container to hydrate the cocopeat. Use
                        5-6 times the volume of water to the volume of cocopeat.
                      </li>
                      <li>
                        Let the cocopeat soak for a few hours until it fully
                        expands and loosens.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>Drain Excess Water:</h4>
                    <ol>
                      <li>
                        After the cocopeat has fully expanded, drain off the
                        excess water. This step helps remove some of the soluble
                        salts naturally present in the cocopeat.
                      </li>
                    </ol>
                  </li>
                  <h4>Prepare Buffering Solution:</h4>
                  <ol>
                    <li>
                      Prepare a buffering solution using calcium nitrate and
                      magnesium sulfate. A common buffering solution is 150-200
                      ppm (parts per million) of calcium nitrate and 50-100 ppm
                      of magnesium sulfate in water.
                    </li>
                    <li>
                      To prepare the solution, dissolve 2 grams of calcium
                      nitrate and 1 gram of magnesium sulfate in 1 liter of
                      water.
                    </li>
                  </ol>
                  <li>
                    <h4>Soak the Cocopeat in Buffering Solution:</h4>
                    <ol>
                      <li>
                        Pour the prepared buffering solution over the hydrated
                        cocopeat. Ensure the cocopeat is fully submerged in the
                        solution.
                      </li>
                      <li>
                        Let the cocopeat soak in the buffering solution for 8-24
                        hours. This process helps displace excess sodium and
                        potassium ions, replacing them with calcium and
                        magnesium ions.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>Rinse the Cocopeat:</h4>
                    <ol>
                      <li>
                        After the soaking period, drain the buffering solution.
                      </li>
                      <li>
                        Rinse the cocopeat thoroughly with fresh water to remove
                        any remaining salts. Repeat the rinsing process 2-3
                        times, using fresh water each time.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>Check pH and EC Levels:</h4>
                    <ol>
                      <li>
                        Check the pH of the cocopeat using a pH meter or pH test
                        strips. The ideal pH range for cocopeat is 5.5-6.5. If
                        the pH is outside this range, you may need to adjust it
                        using pH up or pH down solutions.
                      </li>
                      <li>
                        If you have an EC meter, measure the electrical
                        conductivity (EC) of the final rinse water. The EC
                        should be below 0.5 mS/cm (millisiemens per centimeter)
                        to ensure low salt content.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>Dry the Cocopeat (Optional):</h4>
                    <ol>
                      <li>
                        After rinsing, allow the cocopeat to drain and dry
                        slightly before use. It should be moist but not
                        waterlogged when used for germination.
                      </li>
                    </ol>
                  </li>
                </ol>{" "}
                <hr />
                <h3>Hydroponic Farm Operations</h3>
                <h4>Daily Tasks</h4>
                <ol>
                  <li>
                    <strong>System Check:</strong>
                    <ol>
                      <li>
                        Inspect all hydroponic systems for proper functioning.
                      </li>
                      <li>
                        Check water levels in reservoirs and refill if
                        necessary.
                      </li>
                      <li>
                        Ensure pumps, timers, and aeration systems are working
                        correctly.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Nutrient Solution Management:</strong>
                    <ol>
                      <li>
                        Monitor and adjust the nutrient solution's pH and EC
                        (Electrical Conductivity) levels.
                      </li>
                      <li>
                        Add nutrients or pH adjusters as needed to maintain
                        optimal conditions.
                      </li>
                      <li>
                        Stir or circulate the nutrient solution to ensure
                        uniform distribution.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Plant Monitoring:</strong>
                    <ol>
                      <li>
                        Check plants for signs of stress, disease, or pest
                        infestations.
                      </li>
                      <li>
                        Remove any dead or yellowing leaves to maintain plant
                        health.
                      </li>
                      <li>
                        Ensure that plants are receiving adequate light and are
                        properly spaced.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Environmental Control:</strong>
                    <ol>
                      <li>
                        Monitor temperature and humidity levels in the growing
                        area.
                      </li>
                      <li>
                        Adjust ventilation, heating, or cooling systems to
                        maintain optimal conditions.
                      </li>
                      <li>
                        Ensure grow lights are functioning and adjust light
                        schedules as necessary.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Irrigation:</strong>
                    <ol>
                      <li>
                        Ensure the irrigation system is working properly and
                        delivering water uniformly.
                      </li>
                      <li>
                        Adjust the frequency and duration of irrigation based on
                        plant needs and environmental conditions.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Harvesting:</strong>
                    <ol>
                      <li>Harvest mature crops as needed.</li>
                      <li>
                        Handle harvested produce carefully to avoid damage.
                      </li>
                      <li>Clean and sanitize harvesting tools regularly.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Data Logging:</strong>
                    <ol>
                      <li>
                        Record data on plant growth, nutrient levels, pH, EC,
                        temperature, humidity, and other relevant parameters.
                      </li>
                      <li>
                        Use this data to track plant performance and make
                        informed decisions.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h4>Weekly Tasks</h4>
                <ol>
                  <li>
                    <strong>System Maintenance:</strong>
                    <ol>
                      <li>
                        Clean and sanitize reservoirs, trays, and other system
                        components.
                      </li>
                      <li>
                        Check for and repair any leaks or blockages in the
                        system.
                      </li>
                      <li>Inspect and clean filters and nozzles.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Nutrient Solution Replacement:</strong>
                    <ol>
                      <li>
                        Replace the nutrient solution entirely if necessary to
                        prevent nutrient imbalances or build-up of salts.
                      </li>
                      <li>
                        Ensure the new solution is properly mixed and adjusted
                        before use.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Plant Pruning and Training:</strong>
                    <ol>
                      <li>
                        Prune plants to remove excess foliage and improve air
                        circulation.
                      </li>
                      <li>
                        Train plants to grow in the desired direction using
                        stakes, ties, or trellises.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Pest and Disease Management:</strong>
                    <ol>
                      <li>
                        Conduct a thorough inspection for pests and diseases.
                      </li>
                      <li>
                        Implement integrated pest management (IPM) strategies if
                        needed.
                      </li>
                      <li>
                        Apply organic or chemical treatments as necessary,
                        following safety guidelines.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h4>Monthly Tasks</h4>
                <ol>
                  <li>
                    <strong>Deep Cleaning:</strong>
                    <ol>
                      <li>
                        Perform a deep cleaning of the entire hydroponic system.
                      </li>
                      <li>
                        Sanitize all surfaces and equipment to prevent
                        contamination.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>System Calibration:</strong>
                    <ol>
                      <li>
                        Calibrate pH and EC meters to ensure accurate readings.
                      </li>
                      <li>
                        Check and calibrate other sensors and monitoring
                        equipment.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Inventory Management:</strong>
                    <ol>
                      <li>
                        Take stock of nutrients, seeds, growing media, and other
                        supplies.
                      </li>
                      <li>
                        Order additional supplies as needed to avoid running
                        out.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Plant Rotation and Planning:</strong>
                    <ol>
                      <li>Plan for future plantings and crop rotations.</li>
                      <li>
                        Prepare seedlings for transplanting into the main
                        system.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h4>Seasonal Tasks</h4>
                <ol>
                  <li>
                    <strong>System Upgrades:</strong>
                    <ol>
                      <li>
                        Consider upgrading or expanding the hydroponic system
                        based on production needs.
                      </li>
                      <li>
                        Implement new technologies or techniques to improve
                        efficiency.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Training and Education:</strong>
                    <ol>
                      <li>
                        Stay updated on the latest hydroponic farming practices.
                      </li>
                      <li>
                        Train staff or attend workshops to enhance knowledge and
                        skills.
                      </li>
                    </ol>
                  </li>
                </ol>
                <hr />
                <h3>Harvest Management for Hydroponic Products</h3>
                <h4>Planning the Harvest</h4>
                <ol>
                  <li>
                    <strong>Monitor Plant Maturity:</strong>
                    <ol>
                      <li>
                        Regularly check the growth and development of plants.
                      </li>
                      <li>
                        Use visual cues and maturity indicators specific to each
                        crop to determine the optimal harvest time.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Scheduling:</strong>
                    <ol>
                      <li>Plan harvest times to ensure consistent supply.</li>
                      <li>
                        Schedule harvests during the coolest part of the day to
                        maintain produce quality.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h4>Harvesting Techniques</h4>
                <ol>
                  <li>
                    <strong>Tools and Equipment:</strong>
                    <ol>
                      <li>
                        Use clean and sanitized tools such as scissors, knives,
                        or harvesters.
                      </li>
                      <li>
                        Wear gloves to minimize contamination and damage to the
                        produce.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Harvest Method:</strong>
                    <ol>
                      <li>
                        Carefully cut or pick the produce without damaging the
                        plant.
                      </li>
                      <li>
                        Handle produce gently to avoid bruising or mechanical
                        injury.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Immediate Post-Harvest Handling:</strong>
                    <ol>
                      <li>
                        Place harvested produce in clean, food-grade containers.
                      </li>
                      <li>
                        Avoid piling produce too high to prevent compression
                        damage.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h4>Post-Harvest Treatment</h4>
                <ol>
                  <li>
                    <strong>Cleaning:</strong>
                    <ol>
                      <li>
                        Rinse produce with clean water to remove any debris or
                        residual nutrients.
                      </li>
                      <li>
                        Use a gentle spray to avoid damaging delicate leaves or
                        fruits.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Cooling:</strong>
                    <ol>
                      <li>
                        Immediately cool the produce to slow down respiration
                        and extend shelf life.
                      </li>
                      <li>
                        Use methods such as hydro-cooling (cooling with water),
                        air cooling, or refrigeration.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Drying:</strong>
                    <ol>
                      <li>
                        Ensure that leafy greens and herbs are dried properly
                        after washing to prevent mold and decay.
                      </li>
                      <li>Use fans or gentle air circulation for drying.</li>
                    </ol>
                  </li>
                </ol>
                <h4>Packaging</h4>
                <ol>
                  <li>
                    <strong>Packaging Materials:</strong>
                    <ol>
                      <li>
                        Use appropriate packaging materials such as plastic
                        bags, clamshell containers, or crates.
                      </li>
                      <li>
                        Ensure that packaging is breathable for products that
                        need air circulation.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Labeling:</strong>
                    <ol>
                      <li>
                        Label the packaging with relevant information such as
                        the harvest date, type of produce, and farm name.
                      </li>
                      <li>Include any necessary storage instructions.</li>
                    </ol>
                  </li>
                </ol>
                <h4>Storage and Transportation</h4>
                <ol>
                  <li>
                    <strong>Storage Conditions:</strong>
                    <ol>
                      <li>
                        Store produce at the recommended temperature and
                        humidity levels specific to each crop.
                      </li>
                      <li>
                        Avoid exposure to ethylene-producing fruits (like apples
                        and bananas) which can accelerate ripening and spoilage
                        of some produce.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Transportation:</strong>
                    <ol>
                      <li>
                        Transport produce in refrigerated vehicles if necessary
                        to maintain the cold chain.
                      </li>
                      <li>
                        Ensure that produce is handled carefully during loading
                        and unloading to prevent damage.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h4>Record Keeping</h4>
                <ol>
                  <li>
                    <strong>Harvest Records:</strong>
                    <ol>
                      <li>
                        Maintain detailed records of harvest dates, quantities,
                        and any observations about crop quality.
                      </li>
                      <li>
                        Use this data to improve future harvest planning and
                        management.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Post-Harvest Analysis:</strong>
                    <ol>
                      <li>
                        Analyze post-harvest data to identify any issues with
                        quality or shelf life.
                      </li>
                      <li>
                        Implement improvements based on analysis to enhance
                        future harvests.
                      </li>
                    </ol>
                  </li>
                </ol>
                {/* <div className="card-body">
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Days To Pipe Transfer
                      </label>
                      <div className="w-100 col">
                        {data?.daysToPipeTransfer.toUpperCase() ||
                          "N/A".toUpperCase()}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nursery Water Interval Days
                      </label>
                      <div className="w-100 col">
                        {data?.nurseryWaterIntervalDays.toUpperCase() ||
                          "N/A".toUpperCase()}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nursery Fertilizer Interval Days
                      </label>
                      <div className="w-100 col">
                        {data?.nurseryFertilizerIntervalDays.toUpperCase() ||
                          "N/A".toUpperCase()}
                      </div>
                    </div>
                  )}

                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Germination Days
                      </label>
                      <div className="w-100 col">
                        {data?.seedGerminationDays || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Two leaves days
                      </label>
                      <div className="w-100 col">
                        {data?.twoLeavesDays || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Vegitative Days
                      </label>
                      <div className="w-100 col">
                        {data?.vegetativeDays || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        General serving size
                      </label>
                      <div className="w-100 col">
                        {data?.generalServingSize || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Can Prune</label>
                      <div className="w-100 col">
                        {data?.canPrune ? "YES" : "NO" || "N/A".toUpperCase()}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Difficulty to grow in winter
                      </label>
                      <div className="w-100 col">
                        {capitalizeFirstLetterOfEachWord(
                          data?.difficultyToGrowInWinter
                        ) || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Diffiulty to grow in spring
                      </label>
                      <div className="w-100 col">
                        {capitalizeFirstLetterOfEachWord(
                          data?.difficultyToGrowInSpring
                        ) || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Difficulty to grow in summer
                      </label>
                      <div className="w-100 col">
                        {capitalizeFirstLetterOfEachWord(
                          data?.difficultyToGrowInSummer
                        ) || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Difficulty to grow in monsoon
                      </label>
                      <div className="w-100 col">
                        {capitalizeFirstLetterOfEachWord(
                          data?.difficultyToGrowInMonsoon
                        ) || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Difficulty to grow in autumn
                      </label>
                      <div className="w-100 col">
                        {capitalizeFirstLetterOfEachWord(
                          data?.difficultyToGrowInAutumn
                        ) || "N/A"}
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Difficulty to grow in prewinter
                      </label>
                      <div className="w-100 col">
                        {capitalizeFirstLetterOfEachWord(
                          data?.difficultyToGrowInPrewinter
                        ) || "N/A"}
                      </div>
                    </div>
                  )}
                </div> */}
                {(user.Role === process.env.REACT_APP_ROLE_AGRONOMIST ||
                  user.Role === process.env.REACT_APP_ROLE_ADMIN_S ||
                  user.Role === process.env.REACT_APP_ROLE_OPS) && (
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <SimpleButton
                        buttonText="Edit Article"
                        href={`/agronomy-plants-wiki/edit/${id}`}
                      />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewAgronomyWiki;
