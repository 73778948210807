import * as yup from "yup";
export const VendorSchema = yup.object().shape({
  businessName: yup.string().required("Required"),
  businessAddress: yup.string().required("Required"),
  state: yup.string().required("Required"),
  businessNature: yup.string(),
  notes: yup.string(),
  legalConstitution: yup.string(),
  contactPersonName: yup.string().required("Required"),
  contactPersonPhone: yup.string().required("Required"),
  alternateContactPersonPhone: yup.string(),
  contactPersonEmail: yup.string(),
  gstNumber: yup.string(),
  panNumber: yup.string(),
  bankName: yup.string(),
  bankAccountName: yup.string(),
  generatedBy: yup.string(),
  bankIFSC: yup.string(),
  referredBy: yup.string(),
  acceptedPaymentMode: yup.array().of(yup.string()),
  vendorStatus: yup.string(),
  paymentTerm: yup.string(),
  gstCertificate: yup.mixed(),
  vendorReg: yup.mixed(),
  vendorSign: yup.mixed(),
  vendorBankPassbook: yup.mixed(),
});
