import * as yup from "yup";

export const ClientFeedbackSchema = yup.object().shape({
  // feedback
  feedBackStatus: yup
    .string()
    .oneOf(["pending", "feedback recieved"], "invalid type"),
  clientName: yup.string(),
  dataInsufficient: yup.boolean(),
  clientContactNumber: yup.string(),
  clientEmail: yup.string(),
  origin: yup.string(),
  leadOwner: yup.string(),
  feedback: yup.string(),
  experience: yup
    .string()
    .oneOf(["full info", "paritial info", "more doubts"], "invalid type")
    .required("Required"),
  rating: yup.number().required("Required"),
  obstracle: yup
    .string()
    .oneOf(["cost", "information", "cost & information"], "invalid type")
    .required("Required"),
  followUp: yup.boolean(),
  followUpDate: yup.string(),
});
