import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <b>Terms & Conditions:</b>
      <ol style={{ fontSize: "12px" }}>
        <li>
          This purchase order is subject to the conditions of order. No
          variations or alterations or additions to these conditions will be
          binding on KBPL, notwithstanding that such Variations, alterations,
          additions are contained in your acceptance of this proposal, except
          when they are specifically accepted in writing by KBPL.
        </li>
        <li>This purchase order will be considered as work order.</li>
        <li>
          Upon receipt of mutually agreeable purchase / work order; vendor /
          contractor shall begin furnishing the services according to the
          specification and requirements mentioned in the above purchase / work
          order.
        </li>
        <li>
          KBPL is not responsible for missing, damaged, loss and theft of
          materials before delivery of goods.
        </li>
        <li>
          Any delay in work/ work stopped due to on-site related issues, vendor
          / contractor will have to bear the additonal expenses.
        </li>
        <li>
          All services rendered by the vendor / contractor hereafter shall be
          performed in accordance with an industry standard, as applicable to
          the region or area where the work is to be performed and as per KBPL
          order or agreed condition of service.
        </li>
        <li>
          The vendor / contractor and KBPL will follow the payment conditions as
          mentioned or agreed mutually during quotation or finalization phase or
          as per service rendered.
        </li>
        <li>
          The vendor / contractor is responsible to finish the work as per work
          order or provide the material as per purchase / work order.
        </li>
        <li>
          The vendor / contractor shall strictly adhere to the delivery
          instructions provided during finalization of purchase order / work
          order / communicated via email or any other elecrtronic media.
        </li>
        <li>
          Any modification in purchase / work order or change in service should
          be communicated in advance.
        </li>
        <li>
          The vendor / contractor shall deliver the good on specified delivery
          date mentioned in purchase / work order. Delivery date and time is
          mentioned as Year-Month-Date and Time in 24Hrs format. Any delay after
          promised date of delivery may attract debit.
        </li>
        <li>
          Any payment made in advance will be considered as a work order even if
          the agreement is not signed and returned by the vendor / contractor.
        </li>
        <li>
          In case the material gets damaged in transport or found damaged on
          arrivall should be reworked by the vendor / contractor.
        </li>
        <li>
          The vendor / Contractor is responsible of payment of GST / TDS in the
          same calender month of payment and invoice.
        </li>
        <li>
          The vendor / contractor shall be available to answer all questions and
          queries raised by officer or employees of KBPL.
        </li>
        <li>
          Any contention to Law will be subject to Pune Jurisdiction only.
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
