import React, { useEffect, useState } from "react";

import Footer from "../../Components/Footer/footer";
import ButtonWithSVG from "../../Components/layouts/button_with_svg";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import Pagination from "../../Components/layouts/pagination";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import LoadingPage from "../../Components/layouts/loading";

import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setReduxFilters,
} from "../../redux/Filtered leads/filteredLeadReducer";
import FilteredLeadsTable from "./Leads Filtered/filtered_leads_table";
const LeadsFiltered = () => {
  const user = useSelector((store) => store?.login?.user);
  const store = useSelector((store) => store.leadFilteredfilters);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [leadOwner, setLeadOwner] = useState([]);
  const [filters, setFilters] = useState(store.filters);
  const [page, setCurrPage] = useState(store.currentPage);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL(
        "/leads/getFilteredLeads",
        store.currentPage,
        store.filters
      )
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.leadCount / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, store.currentPage]);
  const ReassignLead = (values) => {
    let leadData = {
      reAssignTo: user?.Name,
      leadsFiltered: true,
    };

    setLoading(true);
    performRequest("PATCH", `/leads/updateleads/${values.id}`, leadData)
      .then((res) => {
        if (res.status === 200) {
          Toast(
            "success",
            `The lead has been successfully assigned to ${user?.Name}!`
          );
          setRefresh(!refresh);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    setCurrPage(store.currentPage);
    setShowPage(Math.floor((store.currentPage - 1) / 5) * 5 + 1);
  }, [store.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Leads Filtered</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>

                {user?.Role !== process.env.REACT_APP_ROLE_SALESAGENCY && (
                  <div className="col-auto ms-auto d-print-none">
                    <div className="d-flex">
                      <ButtonWithSVG
                        href={"/leads/new-lead"}
                        buttonText="New Lead"
                        svg={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 5l0 14" />
                            <path d="M5 12l14 0" />
                          </svg>
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="container-xxl">
              <div className="row">
                <div className="col-md-3 col-sm-12 py-2">
                  <div className="card p-1">
                    <div
                      id="faq-1"
                      className="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div>
                        <div className="accordion-header m-0" role="tab">
                          <button
                            className="accordion-button fs-3 d-md-none"
                            data-bs-toggle="collapse"
                            data-bs-target="#faq-1-1"
                          >
                            <h3>Filter result</h3>
                          </button>
                        </div>

                        <div
                          id="faq-1-1"
                          className="accordion-collapse collapse d-md-none"
                          role="tabpanel"
                          data-bs-parent="#faq-1"
                        >
                          <div className="accordion-body pt-0">
                            <div className="row">
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="Start Date:"
                                  value={filters.startdate}
                                  onChange={(e) => {
                                    setFilters({
                                      ...filters,
                                      startdate: e.target.value,
                                    });
                                    dispatch(
                                      setReduxFilters({
                                        ...filters,
                                        startdate: e.target.value,
                                      })
                                    );
                                  }}
                                  type="date"
                                />
                              </div>
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="End Date:"
                                  value={filters.enddate}
                                  onChange={(e) => {
                                    setFilters({
                                      ...filters,
                                      enddate: e.target.value,
                                    });
                                    dispatch(
                                      setReduxFilters({
                                        ...filters,
                                        enddate: e.target.value,
                                      })
                                    );
                                  }}
                                  type="date"
                                />
                              </div>
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="Search name /mobile:"
                                  value={filters.leadname}
                                  onChange={(e) => {
                                    setFilters({
                                      ...filters,
                                      leadname: e.target.value,
                                    });
                                    dispatch(
                                      setReduxFilters({
                                        ...filters,
                                        leadname: e.target.value,
                                      })
                                    );
                                  }}
                                  placeholder="search here..."
                                />
                              </div>

                              <div className="col-12">
                                <DynamicFilterSelect
                                  label="Search lead owner:"
                                  onChange={(e) => {
                                    setFilters({
                                      ...filters,
                                      leadowner: e.target.value,
                                    });
                                    dispatch(
                                      setReduxFilters({
                                        ...filters,
                                        leadowner: e.target.value,
                                      })
                                    );
                                  }}
                                  value={filters.leadowner}
                                >
                                  <option value="">select all</option>
                                  {leadOwner &&
                                    leadOwner?.map((lead, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={lead?.userName}
                                        >
                                          {lead?.userName}
                                        </option>
                                      );
                                    })}
                                </DynamicFilterSelect>
                              </div>

                              <div className="col-12">
                                <div className="row mt-2">
                                  <div className="col-6">
                                    <button
                                      onClick={() => {
                                        dispatch(setCurrentPage(1));
                                        setRefresh(!refresh);
                                      }}
                                      className="btn btn-success w-100"
                                    >
                                      Apply Filter
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      onClick={() => {
                                        dispatch(
                                          setReduxFilters({
                                            startdate: "",
                                            enddate: "",
                                            leadname: "",
                                            leadowner: "",
                                          })
                                        );
                                        setFilters({
                                          startdate: "",
                                          enddate: "",
                                          leadname: "",
                                          leadowner: "",
                                        });

                                        setRefresh(!refresh);
                                      }}
                                      className="btn btn-secondary w-100"
                                    >
                                      Reset Filter
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-body d-none d-md-block">
                          <div className="row">
                            <h3>Filter result</h3>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="Start Date:"
                                value={filters.startdate}
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    startdate: e.target.value,
                                  });
                                  dispatch(
                                    setReduxFilters({
                                      ...filters,
                                      startdate: e.target.value,
                                    })
                                  );
                                }}
                                type="date"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="End Date:"
                                value={filters.enddate}
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    enddate: e.target.value,
                                  });
                                  dispatch(
                                    setReduxFilters({
                                      ...filters,
                                      enddate: e.target.value,
                                    })
                                  );
                                }}
                                type="date"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="Search name /mobile:"
                                value={filters.leadname}
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    leadname: e.target.value,
                                  });
                                  dispatch(
                                    setReduxFilters({
                                      ...filters,
                                      leadname: e.target.value,
                                    })
                                  );
                                }}
                                placeholder="search here..."
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterSelect
                                label="Search lead owner:"
                                onChange={(e) => {
                                  setFilters({
                                    ...filters,
                                    leadowner: e.target.value,
                                  });
                                  dispatch(
                                    setReduxFilters({
                                      ...filters,
                                      leadowner: e.target.value,
                                    })
                                  );
                                }}
                                value={filters.leadowner}
                              >
                                <option value="">select all</option>
                                {leadOwner &&
                                  leadOwner?.map((lead, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={lead?.userName}
                                      >
                                        {lead?.userName}
                                      </option>
                                    );
                                  })}
                              </DynamicFilterSelect>
                            </div>

                            <div className="col-12">
                              <label className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={filters.favouritesOnly}
                                  onChange={(e) => {
                                    setFilters({
                                      ...filters,
                                      favouritesOnly: e.target.checked,
                                    });
                                  }}
                                  style={{
                                    textAlign: "center",
                                    height: "20px",
                                    width: "20px",
                                  }}
                                />
                                <span className="form-check-label">
                                  My Favorite Plants
                                </span>
                              </label>
                            </div>

                            <div className="col-12">
                              <div className="row mt-2">
                                <div className="col-6">
                                  <button
                                    onClick={() => {
                                      dispatch(setCurrentPage(1));
                                      setRefresh(!refresh);
                                    }}
                                    className="btn btn-success w-100"
                                  >
                                    Apply Filter
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    onClick={() => {
                                      dispatch(
                                        setReduxFilters({
                                          startdate: "",
                                          enddate: "",
                                          leadname: "",
                                          leadowner: "",
                                        })
                                      );
                                      setFilters({
                                        startdate: "",
                                        enddate: "",
                                        leadname: "",
                                        leadowner: "",
                                      });

                                      setRefresh(!refresh);
                                    }}
                                    className="btn btn-secondary w-100"
                                  >
                                    Reset Filter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9 col-sm-12 py-2">
                  <div className="card">
                    {data && (
                      <FilteredLeadsTable
                        leads={data?.leads}
                        ReassignLead={ReassignLead}
                        currentPage={page}
                        itemsPerPage={50}
                      />
                    )}
                    <Pagination
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      pageDatalength={data?.leave?.length}
                      showPage={showPage}
                      setShowPage={setShowPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LeadsFiltered;
