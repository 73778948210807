import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import Pagination from "../../Components/layouts/pagination";
import AttendanceTable from "./Employee Attendance/attendance_table";
import AttendanceChart from "../Dashbourd/Dashbourd Components/attendance_chart";
import { useSelector } from "react-redux";

import { FcClock } from "react-icons/fc";

const EmployeeAttendance = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    name: "",
  });
  const store = useSelector((store) => store.login.user);
  const [attendanceFrom, setAttendanceFrom] = useState(7);
  const [attendanceData, setAttendanceData] = useState();
  const [employeeName, setEmployeeName] = useState(store.Name);
  const [leadOwner, setLeadOwner] = useState();
  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (employeeName) {
      performRequest("GET", `/attendance/getAllAttendance?name=${employeeName}`)
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [employeeName]);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/attendance/getAttendance", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data.AttendanceCount / 50));
        } else {
          Toast("error", "Error while getting Attendance!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcClock className="fs-2  me-1" /> Employee Attendance
                  Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Calculate & check employee attendance
                </h4>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-1">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3>Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Start Date:"
                              value={filters.startdate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  startdate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="End Date:"
                              value={filters.enddate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  enddate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Search name:"
                              value={filters.name}
                              onChange={(e) =>
                                setFilters({ ...filters, name: e.target.value })
                              }
                              placeholder="search name..."
                            />
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      startdate: "",
                                      enddate: "",
                                      leadname: "",
                                      vendor: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Start Date:"
                            value={filters.startdate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                startdate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="End Date:"
                            value={filters.enddate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                enddate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Search name:"
                            value={filters.name}
                            onChange={(e) =>
                              setFilters({ ...filters, name: e.target.value })
                            }
                            placeholder="search name..."
                          />
                        </div>

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    startdate: "",
                                    enddate: "",
                                    leadname: "",
                                    vendor: "",
                                  });
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {store.Role !== process.env.REACT_APP_ROLE_PURCHASE &&
                  store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                  store.Role !== process.env.REACT_APP_ROLE_INSPECTION && (
                    <div className="col-12 p-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Attendance summary</h3>
                          {store?.Role !== process.env.REACT_APP_ROLE_SALLE &&
                            store?.Role !==
                              process.env.REACT_APP_ROLE_SALESAGENCY && (
                              <div className="ms-auto me-3 lh-1">
                                <div className="dropdown">
                                  <a
                                    className="dropdown-toggle text-muted"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {employeeName}
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    {leadOwner &&
                                      leadOwner.map((data, index) => {
                                        return (
                                          <span
                                            key={index}
                                            onClick={() =>
                                              setEmployeeName(data.userName)
                                            }
                                            className="dropdown-item"
                                          >
                                            {data?.userName}
                                          </span>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            )}
                          <div
                            className={
                              store?.Role == process.env.REACT_APP_ROLE_SALLE ||
                              store?.Role ==
                                process.env.REACT_APP_ROLE_SALESAGENCY
                                ? "ms-auto lh-1"
                                : "lh-1"
                            }
                          >
                            <div className="dropdown">
                              <a
                                className="dropdown-toggle text-muted"
                                href="/"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {attendanceFrom === 0
                                  ? "Today"
                                  : `Last ${attendanceFrom || 7} days`}
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <span
                                  onClick={() => setAttendanceFrom(7)}
                                  className="dropdown-item"
                                >
                                  Last 7 days
                                </span>
                                <span
                                  onClick={() => setAttendanceFrom(30)}
                                  className="dropdown-item"
                                >
                                  Last 30 days
                                </span>
                                <span
                                  onClick={() => setAttendanceFrom(45)}
                                  className="dropdown-item"
                                >
                                  Last 45 days
                                </span>
                                <span
                                  onClick={() => setAttendanceFrom(60)}
                                  className="dropdown-item"
                                >
                                  Last 2 months
                                </span>
                                <span
                                  onClick={() => setAttendanceFrom(90)}
                                  className="dropdown-item"
                                >
                                  Last 3 months
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {attendanceData &&
                          attendanceData?.Attendance &&
                          attendanceFrom && (
                            <AttendanceChart
                              attendanceFrom={attendanceFrom}
                              attendance={attendanceData?.Attendance}
                            />
                          )}
                      </div>
                    </div>
                  )}

                {data && data?.Attendance && (
                  <AttendanceTable
                    Attendance={data?.Attendance}
                    currentPage={page}
                    itemsPerPage={20}
                  />
                )}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.Attendance?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmployeeAttendance;
