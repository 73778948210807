import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { format } from "date-fns";

const formatDate = (dateStr) => format(new Date(dateStr), "dd MMMM yyyy");

const leaveData = [
  {
    id: 1,
    date: "26th January 2024",
    day: "Friday",
    occasion: "Republic Day",
    holidayType: "Full Day",
  },
  {
    id: 2,
    date: "25th March 2024",
    day: "Monday",
    occasion: "Dhulivandan",
    holidayType: "Full Day",
  },
  {
    id: 3,
    date: "9th April 2024",
    day: "Tuesday",
    occasion: "Gudi Padwa",
    holidayType: "Full Day",
  },
  {
    id: 4,
    date: "14th April 2024",
    day: "Sunday",
    occasion: "Dr. Babasaheb Ambedkar Jayanti",
    holidayType: "Full Day",
  },
  {
    id: 5,
    date: "1st May 2024",
    day: "Wednesday",
    occasion: "Maharashtra Day",
    holidayType: "Full Day",
  },
  {
    id: 6,
    date: "15th August 2024",
    day: "Thursday",
    occasion: "Independence Day",
    holidayType: "Full Day",
  },
  {
    id: 7,
    date: "7th September 2024",
    day: "Saturday",
    occasion: "Ganesh Chaturthi",
    holidayType: "Full Day",
  },
  {
    id: 8,
    date: "2nd October 2024",
    day: "Wednesday",
    occasion: "Mahatma Gandhi Jayanti",
    holidayType: "Full Day",
  },
  {
    id: 9,
    date: "12th October 2024",
    day: "Saturday",
    occasion: "Dashera",
    holidayType: "Full Day",
  },
  {
    id: 10,
    date: "1st November 2024",
    day: "Friday",
    occasion: "Diwali (Laxmi Pujan)",
    holidayType: "Full Day",
  },
  {
    id: 11,
    date: "3rd November 2024",
    day: "Sunday",
    occasion: "Bhaubij",
    holidayType: "Full Day",
  },
  {
    id: 12,
    date: "25th December 2024",
    day: "Wednesday",
    occasion: "Christmas",
    holidayType: "Full Day",
  },
];

const countWorkingDaysWithoutSundays = (month, year) => {
  let count = 0;
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const holidays = leaveData
    .map((leave) => new Date(leave.date))
    .filter(
      (holiday) =>
        holiday.getMonth() === month && holiday.getFullYear() === year
    );

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, month, day);
    const isSunday = date.getDay() === 0;
    const isHoliday = holidays.some(
      (holiday) =>
        holiday.getDate() === date.getDate() &&
        holiday.getMonth() === date.getMonth() &&
        holiday.getFullYear() === date.getFullYear()
    );

    if (!isSunday && !isHoliday) {
      count++;
    }
  }
  return count;
};

const getTodaysSalary = (
  salary,
  loginTime,
  logoutTime,
  deduction,
  month,
  year,
  loginStatus
) => {
  if (loginStatus === "non-attended") return 0;

  const parseTime = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes, seconds] = time.split(":").map(Number);
    if (modifier === "PM" && hours !== 12) hours += 12;
    if (modifier === "AM" && hours === 12) hours = 0;
    return { hours, minutes, seconds };
  };

  const dailySalary = salary / countWorkingDaysWithoutSundays(month, year);
  if (!logoutTime) return dailySalary / 2;

  const login = parseTime(loginTime);
  const logout = parseTime(logoutTime);

  const loginDate = new Date(
    0,
    0,
    0,
    login.hours,
    login.minutes,
    login.seconds
  );
  const logoutDate = new Date(
    0,
    0,
    0,
    logout.hours,
    logout.minutes,
    logout.seconds
  );
  const workedHours = Math.floor((logoutDate - loginDate) / (1000 * 60 * 60));

  return workedHours >= 9 ? dailySalary : dailySalary / 2;
};

const calculateTotalSalary = (
  attendanceData,
  salary,
  deduction,
  month,
  year
) => {
  let totalSalary = 0;
  let lateCount = 0;

  attendanceData.forEach((data) => {
    if (["late", "2nd half", "late 2nd half"].includes(data.loginStatus))
      lateCount++;

    totalSalary += getTodaysSalary(
      salary,
      data.time,
      data.logoutTime || "",
      deduction,
      month,
      year,
      data.loginStatus
    );
  });

  const lateDeductions = Math.floor(lateCount / 3);
  totalSalary -=
    lateDeductions * (salary / countWorkingDaysWithoutSundays(month, year));
  return totalSalary > 0 ? totalSalary - deduction : totalSalary;
};

const generateDateLabels = (days) => {
  const dates = [];
  const today = new Date();
  for (let i = days; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    dates.push(date);
  }
  return { dates };
};

const AttendanceTracking = ({
  leadOwner,
  attendanceData = [],
  attendanceFrom,
  selectedYear,
  employeeSalary,
  employeeName,
}) => {
  const [dates, setDates] = useState([]);
  const store = useSelector((store) => store.login.user);
  const navigate = useNavigate();

  useEffect(() => {
    const { dates } = generateDateLabels(attendanceFrom);
    setDates(dates);
  }, [attendanceFrom]);

  const filteredAttendanceData = useMemo(() => {
    return attendanceData.filter((data) => {
      const dataDate = new Date(data.createdAt);
      return (
        dataDate.getMonth() === attendanceFrom &&
        dataDate.getFullYear() === selectedYear
      );
    });
  }, [attendanceData, attendanceFrom, selectedYear]);

  const totalSalary = useMemo(() => {
    if (
      store.Role === process.env.REACT_APP_ROLE_ADMIN ||
      store.Role === process.env.REACT_APP_ROLE_ADMIN_S
    ) {
      return calculateTotalSalary(
        filteredAttendanceData,
        employeeSalary?.salaryPerMonth,
        employeeSalary?.pfptDeduction,
        attendanceFrom,
        selectedYear
      );
    }
    return 0;
  }, [
    filteredAttendanceData,
    employeeSalary,
    attendanceFrom,
    selectedYear,
    store.Role,
  ]);

  const getRowBackgroundColor = (data) => {
    const { loginStatus, time, logoutTime } = data;
    const workedHours = (() => {
      if (!logoutTime) return 0;

      const parseTime = (timeString) => {
        const [time, modifier] = timeString.split(" ");
        let [hours, minutes, seconds] = time.split(":").map(Number);
        if (modifier === "PM" && hours !== 12) hours += 12;
        if (modifier === "AM" && hours === 12) hours = 0;
        return { hours, minutes, seconds };
      };

      const login = parseTime(time);
      const logout = parseTime(logoutTime);

      const loginDate = new Date(
        0,
        0,
        0,
        login.hours,
        login.minutes,
        login.seconds
      );
      const logoutDate = new Date(
        0,
        0,
        0,
        logout.hours,
        logout.minutes,
        logout.seconds
      );

      return Math.floor((logoutDate - loginDate) / (1000 * 60 * 60));
    })();

    if (loginStatus === "intime" && workedHours >= 9) {
      return "rgba(0, 128, 0, 0.2)";
    } else if (loginStatus === "grace period" && workedHours >= 9) {
      return "rgba(255, 255, 0, 0.2)";
    } else if (
      ["intime", "grace period", "2nd half"].includes(loginStatus) &&
      workedHours < 9
    ) {
      return "rgba(221, 184, 84, 0.4)";
    } else if (
      ["late", "late 2nd half", "non-attended"].includes(loginStatus)
    ) {
      return "rgba(224, 109, 109, 0.3)";
    }
    return "transparent";
  };

  const goToViewAttendance = (id) => navigate(`/employee-attendance/${id}`);

  return (
    <div className="card-table table-responsive" style={{ maxHeight: "470px" }}>
      <table className="table table-vcenter">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Login Time</th>
            <th>Logout Time</th>
            <th>Total Time</th>
            {employeeName !== "All" &&
              store.Role === process.env.REACT_APP_ROLE_ADMIN_S && (
                <th>Salary</th>
              )}
            {(store.Role === process.env.REACT_APP_ROLE_ADMIN ||
              store.Role === process.env.REACT_APP_ROLE_ADMIN_S) && (
              <th>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {leadOwner &&
            filteredAttendanceData.map((data, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: getRowBackgroundColor(data),
                }}
              >
                <td>{data?.userName || "N/A"}</td>
                <td>{formatDate(data?.createdAt) || "N/A"}</td>
                <td>{data?.time || "N/A"}</td>
                <td>{data?.logoutTime || "N/A"}</td>
                <td>
                  {data?.logoutTime && data.logoutTime !== ""
                    ? (() => {
                        const parseTime = (timeString) => {
                          const [time, modifier] = timeString.split(" ");
                          let [hours, minutes, seconds] = time.split(":");
                          if (hours === "12") hours = "00";
                          if (modifier === "PM")
                            hours = parseInt(hours, 10) + 12;
                          return new Date(1970, 0, 1, hours, minutes, seconds);
                        };

                        const logoutTime = parseTime(data.logoutTime);
                        const currentTime = parseTime(data.time);
                        const differenceInMilliseconds =
                          logoutTime - currentTime;

                        const hours = Math.floor(
                          (differenceInMilliseconds / (1000 * 60 * 60)) % 24
                        );
                        const minutes = Math.floor(
                          (differenceInMilliseconds / (1000 * 60)) % 60
                        );
                        const seconds = Math.floor(
                          (differenceInMilliseconds / 1000) % 60
                        );

                        const formatTimeUnit = (unit) =>
                          unit.toString().padStart(2, "0");
                        return `${formatTimeUnit(hours)}:${formatTimeUnit(
                          minutes
                        )}:${formatTimeUnit(seconds)}`;
                      })()
                    : "N/A"}
                </td>
                {employeeName !== "All" &&
                  store.Role === process.env.REACT_APP_ROLE_ADMIN_S && (
                    <td>
                      {getTodaysSalary(
                        employeeSalary?.salaryPerMonth,
                        data.time,
                        data.logoutTime,
                        employeeSalary?.pfptDeduction,
                        attendanceFrom,
                        selectedYear,
                        data.loginStatus
                      ).toFixed(2)}
                    </td>
                  )}
                {(store.Role === process.env.REACT_APP_ROLE_ADMIN ||
                  store.Role === process.env.REACT_APP_ROLE_ADMIN_S) && (
                  <td>
                    <button
                      className="btn btn-primary"
                      style={{ width: "50px", height: "25px" }}
                      onClick={() => goToViewAttendance(data?.id)}
                    >
                      View
                    </button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
        {employeeName !== "All" &&
          store.Role === process.env.REACT_APP_ROLE_ADMIN_S && (
            <tfoot>
              <tr>
                <th colSpan="6" className="text-end text-muted">
                  Total Salary (inc PF/PT)
                </th>
                <th className="text-muted text-end">
                  {totalSalary?.toFixed(2)}
                </th>
              </tr>
            </tfoot>
          )}
      </table>
    </div>
  );
};

export default AttendanceTracking;
