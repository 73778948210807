import * as yup from "yup";

export const AssetsCreateSchema = yup.object().shape({
  resourceName: yup.string().required("Required"),
  companyName: yup.string(),
  vendor: yup.string(),
  deploymentDate: yup.date(),
  warrantyPeriod: yup.number(),
  paymentAmount: yup.number(),
  paymentMode: yup.string(),
  uploadBill: yup.mixed(),
  uploadWarrantyCard: yup.mixed(),
  notes: yup.string(),
});
