import React from "react";

const CivilMaterials = () => {
  return (
    <div>
      <h3 className="table-heading">Civil Materials:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td>Levelling of the land</td>
            <td>
              Area should be leveled for laying of hydroponic piping, pathway
              area inside polyhouse.
            </td>
          </tr>
          <tr>
            <td>02.</td>
            <td>Reservoir tank dig</td>
            <td>
              Tank dig with JCB and PCC civil material for reservoir tank
              foundation.
            </td>
          </tr>
          <tr>
            <td>03.</td>
            <td>Foundation for polyhouse</td>
            <td>Civil material required for polyhouse polling foundation.</td>
          </tr>
          <tr>
            <td>04.</td>
            <td>Foundation for tanks</td>
            <td>
              Civil material required for tanks foundation. (Cement, Sand &
              Stones)
            </td>
          </tr>
          <tr>
            <td>05.</td>
            <td>Foundation for frames</td>
            <td>
              Civil material required for A shape frames foundation. (Cement and
              sand)
            </td>
          </tr>
          <tr>
            <td>06.</td>
            <td>Miscellaneous work</td>
            <td>
              Civil material required for any miscellaneous work like support
              for pipes, motors, boards and pannels.
            </td>
          </tr>
          <tr>
            <td>07.</td>
            <td>Cost of civil material</td>
            <td>
              From our past experience, cost of civil material is between 25,000
              INR to 50,000 INR (based on your location). In case additional
              wall work or foundation work is required; the cost may go upto
              1,00,000 INR
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default CivilMaterials;
