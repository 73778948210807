import React from "react";

const CustomerResponsibility = () => {
  return (
    <div>
      <h3 className="table-heading">Customer Responsibility:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td>Power connection</td>
            <td>
              Single phase/three phase power connection with specified wattage
              rating to be brought to the control panel. Customer should have
              their own Main control panel with necessary circuit breakers and
              change over (for generator) to feed the regulated uninterrupted
              power connection to the control panel
            </td>
          </tr>
          <tr>
            <td>02.</td>
            <td>DG connection</td>
            <td>
              Diesel generator of compatible power is required, if the
              electricity is not available 24/7.
            </td>
          </tr>
          <tr>
            <td>03.</td>
            <td>Worker convenience</td>
            <td>
              Clean and sleep-able accommodation for workers. If the
              accommodation is not at the site then travel till the site and
              back to be arranged by the customer. Customer should also arrange
              for clean drinking water, cooking facility, and local travel
              assistance for running errands.
            </td>
          </tr>
          <tr>
            <td>04.</td>
            <td>Storage facility</td>
            <td>
              Secure facility is required at site to store material till
              installation is in progress. Security of the premise; any theft or
              lifting of material is customer responsibility.
            </td>
          </tr>
          <tr>
            <td>05.</td>
            <td>Water connection</td>
            <td>
              Required quantity water connection till water tanks on 24/7 basis.
              If water quality is not good then RO water setup is required.
            </td>
          </tr>
          <tr>
            <td>06.</td>
            <td>Civil materials</td>
            <td>
              Civil materials required for the foundation of polyhouses, tanks
              and frames.(cement, sand and stone)
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default CustomerResponsibility;
