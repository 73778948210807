import React from "react";

const NotesFooter = () => {
  return (
    <div>
      <b>Terms & Conditions:</b>
      <ol style={{ fontSize: "0.8rem" }}>
        <li>
          All prices shown here are in <b>INR</b>.
        </li>
        <li>Work will start after advance payment.</li>
        <li>Advance will be considered as work order.</li>
        <li>
          Invoice rates are according to current rates of steel and pvc raw
          materials.
        </li>
        <li>Transportation of goods and labour ticket charges are extra.</li>
        <li>Any extra services will be charged additionally.</li>
      </ol>
    </div>
  );
};

export default NotesFooter;
