import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const cookie = Cookies.get("user") || null;
let userData = "";
if (cookie) {
  userData = JSON.parse(cookie);
}
export const followupLeadsSlice = createSlice({
  name: "followupLeadsReducer",
  initialState: {
    currentPage: 1,
    filters: {

      startdate: "",
      enddate: "",
      leadname: "",

      leadowner: userData?.Name || "",
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setReduxFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setCurrentPage, setReduxFilters } = followupLeadsSlice.actions;
export default followupLeadsSlice.reducer;
