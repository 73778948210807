import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { getId } from "../../../Components/Functions/word.fun";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import WhatsAppButton from "../../../Components/layouts/whatsApp_button";
import { useSelector } from "react-redux";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";

const ViewSiteInspection = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((store) => store.login.user);
  const [data, setData] = useState();
  const [imageUrls, setImageUrls] = useState({});
  let { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/siteInspection/getSiteInspectionById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting SI!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (data) {
      if (data?.water?.waterTankLocationPhoto) {
        getUplodedImage(
          setImageUrls,
          data.water.waterTankLocationPhoto,
          "waterTankLocationPhoto"
        );
      }
      if (data?.water?.waterReportPhoto) {
        getUplodedImage(
          setImageUrls,
          data.water.waterReportPhoto,
          "waterReportPhoto"
        );
      }
      if (data?.sunlight?.entryTofarmPhoto) {
        getUplodedImage(
          setImageUrls,
          data.sunlight.entryTofarmPhoto,
          "entryTofarmPhoto"
        );
      }
      if (data?.land?.obstaclesTowardsSkyPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.land?.obstaclesTowardsSkyPhoto,
          "obstaclesTowardsSkyPhoto"
        );
      }
      if (data?.sunlight?.luxReadingPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.sunlight?.luxReadingPhoto,
          "luxReadingPhoto"
        );
      }
      if (data?.sunlight?.luxDeffusionSheetPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.sunlight?.luxDeffusionSheetPhoto,
          "luxDeffusionSheetPhoto"
        );
      }
      if (data?.sunlight?.luxWovenPolyPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.sunlight?.luxWovenPolyPhoto,
          "luxWovenPolyPhoto"
        );
      }
      if (data?.sunlight?.luxUvOpenPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.sunlight?.luxUvOpenPhoto,
          "luxUvOpenPhoto"
        );
      }
      if (data?.climate?.temperatureReadingPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.climate?.temperatureReadingPhoto,
          "temperatureReadingPhoto"
        );
      }
      if (data?.climate?.humidityReadingPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.climate?.humidityReadingPhoto,
          "humidityReadingPhoto"
        );
      }
      if (data?.dimensions?.northSouthMeasurmentPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.dimensions?.northSouthMeasurmentPhoto,
          "northSouthMeasurmentPhoto"
        );
      }
      if (data?.dimensions?.eastWestMeasurmentPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.dimensions?.eastWestMeasurmentPhoto,
          "eastWestMeasurmentPhoto"
        );
      }
      if (data?.dimensions?.layoutPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.dimensions?.layoutPhoto,
          "layoutPhoto"
        );
      }
      if (data?.additionDetails?.descriptionVideo) {
        getUplodedImage(
          setImageUrls,
          data?.additionDetails?.descriptionVideo,
          "descriptionVideo"
        );
      }
    }
  }, [data]);

  const generatePDF = () => {
    if (!data?.quotationNumber) {
      alert(
        "For this site inspection quotation doesn't exist! Please add quotation and then generate DPR"
      );
    } else {
      navigate(`/client-site-inspection/preview/${id}`);
    }
  };

  function formatCamelCaseToWords(camelCaseString) {
    const formattedString = camelCaseString.replace(/([A-Z])/g, " $1").trim();
    const capitalizedString = formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return capitalizedString;
  }

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Site Inspection</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">
                {data?.siNumber || "View Site Inspection"}
              </h2>
            </div>

            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Project</label>
                  <div className="w-100 col">
                    {(
                      data?.clientDetails?.clientName || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Quotation</label>
                  <div className="w-100 col">
                    {data?.quotationNumber ? (
                      <a
                        href={`/custom-quotation/${getId(
                          data?.quotationNumber
                        )}`}
                      >
                        {data?.quotationNumber}
                      </a>
                    ) : (
                      "Not Available"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Inspection date
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.inspectionDate || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Tentative start date
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.tentativeStartDate ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Site address</label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.siteAddress || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Site pincode</label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.sitePinCode || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Site manager name
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.siteManagerName ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Site manager phone
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.siteManagerPhone ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Looking For Government Support
                  </label>
                  <div className="w-100 col">
                    {data?.lookingForGovernmentSupport ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Project owner gender
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.projectOwnerGender || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Location</label>
                  <div className="w-100 col">
                    {(data?.location || "Not Available").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Cast</label>
                  <div className="w-100 col">
                    {(data?.cast || "Not Available").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Number of staff
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.numberOfStaff || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Type of Site</label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.newSiteInspection?.typeOfSite
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Soil type</label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.newSiteInspection?.soilType
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Total available area unit
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.totalAvailableAreaUnit ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Total available area number
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.totalAvailableAreaNumber ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Land ownership
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.landOwnership || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Land leveling</label>
                  <div className="w-100 col">
                    {(
                      data?.newSiteInspection?.landLeveling || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Natural slope of land
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.newSiteInspection?.naturalSlopeOfLand
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              <hr />
              <h2>Land</h2>
              <hr />
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Land has tree</label>
                  <div className="w-100 col">
                    {data?.land?.landHasTree ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Land has stones
                  </label>
                  <div className="w-100 col">
                    {data?.land?.landHasStones ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Land has pond</label>
                  <div className="w-100 col">
                    {data?.land?.landHasPond ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Land has mud patch
                  </label>
                  <div className="w-100 col">
                    {data?.land?.landHasMudPatch ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Land has hill lock
                  </label>
                  <div className="w-100 col">
                    {data?.land?.landHasHillock ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Land fencing type
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.land?.landFencingType || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Land fencing height in feet
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.land?.landFencingHeightInFeet || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Whats on north
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(data?.land?.whatsOnNorth) ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Whats on east</label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(data?.land?.whatsOnEast) ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Whats on south
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(data?.land?.whatsOnSouth) ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Whats on west</label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(data?.land?.whatsOnWest) ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Obstacles towards sky
                  </label>
                  <div className="w-100 col">
                    {data?.land?.obstaclesTowardsSky ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Obstacles towards sky photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.obstaclesTowardsSkyPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.obstaclesTowardsSkyPhoto}
                        alt="obstacles towards sky photo"
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Latitude & longitude
                  </label>
                  <div className="w-100 col">
                    {data?.land?.googleMapsLocationLink ||
                      "Not Available".toUpperCase()}
                  </div>
                </div>
              )}
              <hr />
              <h2>Water</h2>
              <hr />
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water source type
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.water?.waterSourceType || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water tank capacity in litre
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.water?.waterTankCapacityInLitre || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water availability frequency
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.water?.waterAvailabilityFrequency
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Position of water tank
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.water?.positionOfWaterTank || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water tank location photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.waterTankLocationPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.waterTankLocationPhoto}
                        alt={"water tank location photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water Ph reading
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.water?.waterPhReading || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water Ec reading
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.water?.waterEcReading || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water report photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.waterReportPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.waterReportPhoto}
                        alt={"water report photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              <hr />
              <h2>Sunlight</h2>
              <hr />
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Entry to farm site
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.sunlight?.entryToFarmSite || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Entry to farm photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.entryTofarmPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.entryTofarmPhoto}
                        alt={"entry to farm photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Lux reading</label>
                  <div className="w-100 col">
                    {(
                      data?.sunlight?.luxReading || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Lux reading photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.luxReadingPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.luxReadingPhoto}
                        alt={"lux reading photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Lux deffusion sheet
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.sunlight?.luxDeffusionSheet || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Lux deffusion sheet photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.luxDeffusionSheetPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.luxDeffusionSheetPhoto}
                        alt={"lux deffusion sheet photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Lux woven poly
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.sunlight?.luxWovenPoly || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Lux woven poly photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.luxWovenPolyPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.luxWovenPolyPhoto}
                        alt={"lux woven poly photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Lux Uv open</label>
                  <div className="w-100 col">
                    {(
                      data?.sunlight?.luxUvOpen || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Lux Uv open photo
                  </label>
                  <div className="w-100 col">
                    {console.log(imageUrls.luxUvOpenPhoto)}
                    {imageUrls.luxUvOpenPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.luxUvOpenPhoto}
                        alt={"lux uv open photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              <hr />
              <h2>Climate</h2>
              <hr />
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Temperature reading
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.climate?.temperatureReading || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Temperature reading photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.temperatureReadingPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.temperatureReadingPhoto}
                        alt={"temperature reading photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Humidity reading
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.climate?.humidityReading || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Humidity reading photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.humidityReadingPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.humidityReadingPhoto}
                        alt={"humidity reading photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Climate hottest month
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.climate?.climateHottestMonth || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Climate coldest month
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.climate?.climateColdestMonth || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              <hr />
              <h2>Dimensions</h2>
              <hr />
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    North south measurment in meter
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.dimensions?.northSouthMeasurmentInMeter ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    North south measurment photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.northSouthMeasurmentPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.northSouthMeasurmentPhoto}
                        alt={"north South Measurment Photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    East west measurment in meter
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.dimensions?.eastWestMeasurmentInMeter ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    East west measurment photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.eastWestMeasurmentPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.eastWestMeasurmentPhoto}
                        alt={"east West Measurment Photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Layout photo</label>
                  <div className="w-100 col">
                    {console.log(imageUrls.layoutPhoto)}
                    {imageUrls.layoutPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.layoutPhoto}
                        alt={"layout Photo"}
                      />
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              <hr />
              <h2>Facilities</h2>
              <hr />
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Facility to store items
                  </label>
                  <div className="w-100 col">
                    {data?.facilities?.facilityToStoreItems ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Facility to stay
                  </label>
                  <div className="w-100 col">
                    {data?.facilities?.facilityToStay ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Facility has bathroom
                  </label>
                  <div className="w-100 col">
                    {data?.facilities?.facilityHasBathroom ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Facility has drinking water
                  </label>
                  <div className="w-100 col">
                    {data?.facilities?.facilityHasDrinkingWater ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Facility has kitchen space
                  </label>
                  <div className="w-100 col">
                    {data?.facilities?.facilityHasKitchenSpace ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Facility has fuel for cooking
                  </label>
                  <div className="w-100 col">
                    {data?.facilities?.facilityHasFuelForCooking ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Transport option
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.facilities?.transportOption
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Nearest tar road
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.nearestTarRoad || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Nearest market
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.nearestMarket || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Nearest medical facility
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.nearestMedicalFacility ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Nearest stay facility
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.nearestStayFacility || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Nearest bus stand
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.nearestBusStand || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Nearest railway station
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.nearestRailwayStation || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Nearest airport
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.nearestAirport || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Reguler mode of transportation
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.facilities?.regulerModeOfTransportation
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Vehicle avilable for emergency
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.vehicleAvilableForEmergency ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Electricity connection available
                  </label>
                  <div className="w-100 col">
                    {data?.facilities?.electricityConnectionAvailable ? (
                      <FaCheckCircle fontSize={"20px"} color="#34A853" />
                    ) : (
                      <FaCircleXmark fontSize={"20px"} color="#D80001" />
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Electricity meter type
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.electricityMeterType || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Electricity meter phase type
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.facilities?.electricityMeterPhaseType
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Electricity outage frequency
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.electricityOutageFrequency ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Electricity unit price
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.electricityUnitPrice || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Electricity pricing model
                  </label>
                  <div className="w-100 col">
                    {(
                      data?.facilities?.electricityPricingModel ||
                      "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Electricity usage
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.facilities?.electricityUsage
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Internet connectivity
                  </label>
                  <div className="w-100 col">
                    {(
                      formatCamelCaseToWords(
                        data?.facilities?.internetConnectivity
                      ) || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
              <hr />
              <h2>Additional Details</h2>
              <hr />
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Description video
                  </label>
                  <div className="w-100 col">
                    {imageUrls.descriptionVideo ? (
                      <video width="70%" controls>
                        <source
                          src={imageUrls.descriptionVideo}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      "Not Available".toUpperCase()
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Notes</label>
                  <div className="w-100 col">
                    {(
                      data?.additionDetails?.notes || "Not Available"
                    ).toUpperCase()}
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                {user.Role !== process.env.REACT_APP_ROLE_CLIENT &&
                  user.Role !== process.env.REACT_APP_ROLE_D_CLIENT && (
                    <WhatsAppButton buttonText="SEND UPDATE" />
                  )}
                <SimpleButton
                  buttonText={"generate pdf"}
                  onClick={generatePDF}
                />
                {user.Role !== process.env.REACT_APP_ROLE_CLIENT &&
                  user.Role !== process.env.REACT_APP_ROLE_D_CLIENT && (
                    <SimpleButton
                      buttonText="Edit Site Inspection"
                      href={`/client-site-inspection/edit/${id}`}
                    />
                  )}

                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewSiteInspection;
