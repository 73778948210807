import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const tableHead = [
  "Sr.",
  "Client Name",
  "Sell Product",
  "Amount (-GST)",
  "creator",
  "description",
  "Actions",
];
const getProduct = (pro) => {
  if (pro === "onlycea") {
    return "Any Only CEA (NVPH, Nethouse, Fanpad) / Only Hydroponics sale";
  }
  if (pro === "hydro1860") {
    return "Any CEA + Any Hydroponics - 1SqM to 1860SqM";
  }
  if (pro === "hydro4046") {
    return "Any CEA + Any Hydroponics - 1861SqM to 4046SqM";
  }
  if (pro === "hydro202304") {
    return "Any CEA + Any Hydroponics - 4046SqM to 20234SqM";
  }
  if (pro === "hydro20234+") {
    return "Any CEA + Any Hydroponics - 20234SqM +";
  }
  if (pro === "allother") {
    return "All other sales excluding CEA and Hydroponics";
  }
};
const IncentiveTable = ({ incentives = [], currentPage, itemsPerPage }) => {
  const user = useSelector((store) => store.login.user);

  const navigate = useNavigate();
  const goToEdit = (id) => {
    navigate(`/revenue-management/edit/${id}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              if (item !== "Actions") {
                return <th key={index}>{item}</th>;
              }
              if (user.Role === process.env.REACT_APP_ROLE_ADMIN_S) {
                return <th key={index}>{item}</th>;
              }
            })}
          </tr>
        </thead>
        <tbody>
          {incentives?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.leadName || "N/A"}</td>
                <td>{item?.product ? getProduct(item?.product) : "N/A"}</td>
                <td>
                  {item?.preGstAmount ? Math.round(item?.preGstAmount) : "N/A"}
                </td>
                <td>{item?.generatedBy || "N/A"}</td>
                <td>{item?.incentiveDescription || "N/A"}</td>
                {user.Role === process.env.REACT_APP_ROLE_ADMIN_S && (
                  <td>
                    <button
                      style={{ width: "60px", height: "30px" }}
                      className="btn btn-primary"
                      onClick={() => goToEdit(item.id)}
                    >
                      Edit
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IncentiveTable;
