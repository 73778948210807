import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";

import { FcSms } from "react-icons/fc";

const ViewAssets = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (data) {
      if (data?.uploadBill) {
        getUplodedImage(setImageUrls, data?.uploadBill, "uploadBill");
      }
      if (data?.uploadWarrantyCard) {
        getUplodedImage(
          setImageUrls,
          data?.uploadWarrantyCard,
          "uploadWarrantyCard"
        );
      }
    }
  }, [data]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/asset/getAssetById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcSms className="fs-2  me-1" /> Add your assets
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Add new asset.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specifications</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>Client Name</td>
                          <td>{(data?.farmName || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Client Address</td>
                          <td>
                            {(data?.projectAddress || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Client Contact</td>
                          <td>
                            {(data?.clientPhoneNumber || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specifications</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>Resource name</td>
                          <td>{(data?.resourceName || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Resource company name</td>
                          <td>{(data?.companyName || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Resource vendor name</td>
                          <td>{(data?.vendor || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Deployment Date</td>
                          <td>
                            {(data?.deploymentDate || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Warranty period</td>
                          <td>
                            {(data?.warrantyPeriod || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Payment amount</td>
                          <td>
                            {(data?.paymentAmount || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Payment mode</td>
                          <td>{(data?.paymentMode || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Uploaded bill</td>
                          <td>
                            {imageUrls?.uploadBill ? (
                              <img
                                width="60%"
                                src={imageUrls?.uploadBill}
                                alt={data?.uploadBill}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Uploaded warranty card</td>
                          <td>
                            {imageUrls?.uploadWarrantyCard ? (
                              <img
                                width="60%"
                                src={imageUrls?.uploadWarrantyCard}
                                alt={data?.uploadWarrantyCard}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Notes</td>
                          <td>{(data?.notes || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton
                      buttonText="Edit Asset"
                      href={`/resources-assets/edit/${id}`}
                    />
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewAssets;
