import React, { useEffect, useState } from "react";
import StepRoutes from "../../../Routes/steps_routes";
import Footer from "../../../Components/Footer/footer";
import CompanyPromotion from "../PdfQuotationsTables/company_promotion";
import ElectricFittings from "../PdfQuotationsTables/electric_fittings";
import performRequest from "../../../api/request";
import { useNavigate, useParams } from "react-router-dom";
import CompanyDescription from "../PdfQuotationsTables/company_description";
import NVPHSpecifications from "../PdfQuotationsTables/nvph_specifications";
import FanPadSpecifications from "../PdfQuotationsTables/fan_pad_specifications";
import AFrameSetup from "../PdfQuotationsTables/a_frame_setup";
import FlatBedSetup from "../PdfQuotationsTables/flat_bed_setup";
import GrowBagSetup from "../PdfQuotationsTables/grow_bag_setup";
import DutchBucketSetup from "../PdfQuotationsTables/dutch_bucket_setup";
import WaterStorage_Handling from "../PdfQuotationsTables/water_storage_and_handling";
import EmployeeTrainingProgramme from "../PdfQuotationsTables/employee_training_programme";
import AgronomySupport from "../PdfQuotationsTables/agronomy_support";
import ScopeOfWork from "../PdfQuotationsTables/scope_of_work";
import CustomerResponsibility from "../PdfQuotationsTables/customer_responsibility";
import CivilMaterials from "../PdfQuotationsTables/civil_materials";
import SoftwareServices from "../PdfQuotationsTables/software_services";
import MaterialBreakdown from "../PdfQuotationsTables/material_breakdown";
import WorkOrderLocations from "../PdfQuotationsTables/work_order_locations";
import WarrentyOnMaterial from "../PdfQuotationsTables/warrenty_on_material";
import TrackingOfProgress from "../PdfQuotationsTables/tracking_of_progress";
import NetHouseSpecifications from "../PdfQuotationsTables/net_house_specifications";
import PaymentDetails from "../PdfQuotationsTables/payment_details";
import SimpleButton from "../../../Components/layouts/simple_button";
import { Toast } from "../../../CustomComponent/toast";
import KryzenTop from "../PdfQuotationsTables/kryzen_Top";
import ProjectFinancials from "../PdfQuotationsTables/project_financials";
import QuotationAmounts from "../PdfQuotationsTables/quotation_amounts";
import PaymentBreakdown from "../PdfQuotationsTables/payment_breakdown";
import ProduceBreakevenTable from "../PdfQuotationsTables/produce_breakeven_table";
import UserDetails from "../PdfQuotationsTables/user_details";
import MaterialBreakdownCEA from "../PdfQuotationsTables/material_breakdown_CEA";
import LoadingPage from "../../../Components/layouts/loading";

import { FcSurvey } from "react-icons/fc";

const QuotationPreview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [allQData, setAllQData] = useState([]);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/quotations/getQuotationsById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setdata(res.data?.input_info);
          setAllQData(res.data);
        } else {
          Toast("error", "Error while getting Quotations!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  function generatePDF() {
    setLoading(true);
    performRequest("PATCH", `/quotations/updateQuotations/${id}`, {
      pdfStatus: true,
    })
      .then((res) => {
        if (res.status === 200) {
          setRefresh(!refresh);
          Toast("success", "PDF generated successfully!");
        } else {
          Toast("error", "Error while generating PDF!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  // function DownloadPDF() {
  //   const element = document.getElementById("pdf-content");

  //   html2pdf()
  //     .from(element)
  //     .set({
  //       margin: [5, 5],
  //       fontSize: 12,
  //       filename: `${QuotationNumber()}-${GetArea()}SqM-${
  //         data?.businessName || data?.contactPersonName
  //       }.pdf`,
  //       html2canvas: { scale: 2 },
  //     })
  //     .toPdf()
  //     .get("pdf")
  //     .then(function (pdf) {
  //       var totalPages = pdf.internal.getNumberOfPages();
  //       for (let i = 1; i <= totalPages; i++) {
  //         pdf.setPage(i);
  //       }
  //     })
  //     .save();
  // }

  const redirectPreview = () => {
    navigate(`/custom-quotation/preview/${id}`);
  };

  function handelEdit() {
    navigate(`/custom-quotation/edit-quotation/${id}`);
  }
  const duplicateQuotation = () => {
    navigate(`/custom-quotation/new-quotation?duplicate=${id}`);
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcSurvey className="fs-2  me-1" /> Custom quotations
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Custom quotation for{" "}
                  {data?.businessName || data?.contactPersonName}
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body" id="pdf-content">
              <KryzenTop />
              {data && (
                <CompanyDescription
                  allQData={allQData}
                  businessName={data?.businessName || data?.contactPersonName}
                  address={data?.address}
                  data={data}
                  quotationType={data?.quotationType}
                />
              )}
              {data && <UserDetails userInfo={data} area={allQData?.area_in} />}
              {data && data?.quotationType === "fanpad" && (
                <FanPadSpecifications />
              )}

              {data && data?.quotationType === "fanpad with hydroponics" && (
                <FanPadSpecifications />
              )}
              {data && data?.quotationType === "nethouse" && (
                <NetHouseSpecifications />
              )}
              {data && data?.quotationType === "nethouse with hydroponics" && (
                <NetHouseSpecifications />
              )}
              {data && data?.quotationType === "nvph" && <NVPHSpecifications />}
              {data && data?.quotationType === "nvph with hydroponics" && (
                <NVPHSpecifications />
              )}
              {/* percent wise setup */}
              {data?.quotationType !== "fanpad" &&
                data?.quotationType !== "nvph" &&
                data?.quotationType !== "nethouse" && (
                  <>
                    {data && data?.flatBedPercent > 0 && <FlatBedSetup />}
                    {data && data?.a_FramePercent > 0 && <AFrameSetup />}
                    {data && data?.growBagPercent > 0 && <GrowBagSetup />}
                    {data && data?.dutchBucketPercent > 0 && (
                      <DutchBucketSetup />
                    )}
                    <div
                      className="html2pdf__page-break"
                      style={{
                        pageBreakAfter: "always",
                        display: "block",
                        clear: "both",
                      }}
                    ></div>
                  </>
                )}

              {data && (
                <WaterStorage_Handling quotationType={data?.quotationType} />
              )}
              {data && <ElectricFittings data={data} />}
              {data?.quotationType !== "fanpad" &&
                data?.quotationType !== "nvph" &&
                data?.quotationType !== "nethouse" && (
                  <>
                    <EmployeeTrainingProgramme />
                    <AgronomySupport />
                  </>
                )}
              <TrackingOfProgress />

              {data && <ScopeOfWork quotationType={data?.quotationType} />}
              <CustomerResponsibility />
              <CivilMaterials />
              {/* working panding */}
              {/* <AfterSalesServices_AMC /> */}
              {data?.quotationType !== "fanpad" &&
                data?.quotationType !== "nvph" &&
                data?.quotationType !== "nethouse" && <SoftwareServices />}
              {data &&
                data?.quotationType !== "hydroponics only" &&
                data?.quotationType !== "fanpad" &&
                data?.quotationType !== "nvph" &&
                data?.quotationType !== "nethouse" && <MaterialBreakdown />}
              {(data?.quotationType == "fanpad" ||
                data?.quotationType == "nvph" ||
                data?.quotationType == "nethouse") && <MaterialBreakdownCEA />}
              {data && (
                <WorkOrderLocations quotationType={data?.quotationType} />
              )}
              <WarrentyOnMaterial />
              <CompanyPromotion />
              {data && data?.showProjectFinancials && (
                <>
                  {data && data?.showProjectFinancials && allQData && (
                    <ProjectFinancials allQData={allQData} />
                  )}
                  {allQData && allQData?.plant_grow?.updatedPlantsList && (
                    <ProduceBreakevenTable allQData={allQData} />
                  )}
                  <div
                    className="html2pdf__page-break"
                    style={{
                      pageBreakAfter: "always",
                      display: "block",
                      clear: "both",
                    }}
                  ></div>
                </>
              )}

              <KryzenTop />
              {allQData && <QuotationAmounts allQData={allQData} />}
              {allQData && <PaymentBreakdown allQData={allQData} />}
              {data && <PaymentDetails finalQuotation={data?.finalQuotation} />}
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                {!allQData?.input_info?.pdfStatus && (
                  <SimpleButton
                    buttonText="Genarate PDF"
                    onClick={generatePDF}
                  />
                )}
                {/* {allQData?.input_info?.pdfStatus && (
                  <SimpleButton
                    buttonText="Download PDF"
                    onClick={DownloadPDF}
                    // onClick={redirectPreview}
                  />
                )} */}
                {allQData?.input_info?.pdfStatus && (
                  <SimpleButton
                    buttonText="Print PDF"
                    href={`/custom-quotation/preview/${id}`}
                  >
                    Print PDF
                  </SimpleButton>
                )}

                <button
                  disabled={data?.finalQuotation}
                  className={`btn mx-1 mt-2 btn-primary`}
                  onClick={handelEdit}
                >
                  EDIT QUOTATION
                </button>
                <SimpleButton
                  buttonText="Duplicate"
                  onClick={duplicateQuotation}
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default QuotationPreview;
