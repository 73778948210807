import React, { useEffect, useState } from "react";

const generateDateLabels = (days) => {
  const dates = [];
  const today = new Date();
  if (days === -1) {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    dates.push(yesterday);
  } else {
    for (let i = days; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date);
    }
  }
  return {
    dates,
  };
};

const countLeadsByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates?.forEach((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    const count = leads.filter((lead) => {
      const createdAt = new Date(lead.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        lead.lead_alloted_to === owner
      );
    }).length;

    allCount += count;
  });
  return allCount;
};

const countFilteredLeadsByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates.forEach((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = leads.filter((lead) => {
      const updatedAt = new Date(lead.updatedAt);
      return (
        updatedAt >= startOfDay &&
        updatedAt <= endOfDay &&
        lead.reAssignTo === owner &&
        lead.reAssignTo !== lead.lead_alloted_to
      );
    }).length;
    allCount += count;
  });
  return allCount;
};

const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const countFollowupTakenLeadsByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates.forEach((date) => {
    let dt = formatDate(date);
    let count = leads.filter((lead) => {
      if (lead.followUp && lead.followUp.length > 0) {
        return lead.followUp.some(
          (followUp) =>
            followUp.followUpDate === dt &&
            followUp.followupTaken &&
            lead.reAssignTo === owner
        );
      }
    }).length;
    allCount += count;
  });
  return allCount;
};

const countFollowupCreatedByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates.forEach((date) => {
    let dt = formatDate(date);
    let count = leads.filter((lead) => {
      if (lead.followUp && lead.followUp.length > 0) {
        return lead.followUp.some(
          (followUp) => followUp.createdAt === dt && lead.reAssignTo === owner
        );
      }
    }).length;
    allCount += count;
  });
  return allCount;
};

const countFollowupPendingLeadsByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates.forEach((date) => {
    let dt = formatDate(date);
    let count = leads.filter((lead) => {
      if (
        lead.followUp &&
        lead.followUp.length > 0 &&
        lead.lead_status !== "cold"
      ) {
        return lead.followUp.some(
          (followUp) =>
            followUp.followUpDate === dt &&
            !followUp.followupTaken &&
            lead.reAssignTo === owner
        );
      }
    }).length;
    allCount += count;
  });
  return allCount;
};

const SalesWorkTracking = ({ leadOwner, leadsData, trackFrom = 30 }) => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const { dates } = generateDateLabels(trackFrom);
    setDates(dates);
  }, [trackFrom]);

  // Filter leadOwner to include only users with at least one lead created
  const filteredLeadOwner = leadOwner?.filter(
    (data) => countLeadsByDate(leadsData, dates, data?.userName) > 0
  );

  return (
    <div className="card-table table-responsive" style={{ maxHeight: "470px" }}>
      <table className="table table-vcenter">
        <thead>
          <tr>
            <th>Name</th>
            <th>Leads Created</th>
            <th>Re-Assigned</th>
            <th>Followups Taken</th>
            <th>Followups Created</th>
            <th>Followups Pending</th>
          </tr>
        </thead>
        <tbody>
          {dates &&
            filteredLeadOwner &&
            filteredLeadOwner.map((data, index) => (
              <tr key={index}>
                <td>{data?.userName || "N/A"}</td>
                <td>{countLeadsByDate(leadsData, dates, data?.userName)}</td>
                <td>
                  {countFilteredLeadsByDate(leadsData, dates, data?.userName)}
                </td>
                <td>
                  {countFollowupTakenLeadsByDate(
                    leadsData,
                    dates,
                    data?.userName
                  )}
                </td>
                <td>
                  {countFollowupCreatedByDate(leadsData, dates, data?.userName)}
                </td>
                <td>
                  {countFollowupPendingLeadsByDate(
                    leadsData,
                    dates,
                    data?.userName
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesWorkTracking;
