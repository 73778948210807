const today = new Date();

export const followUpInfo = {
  followUpDate: "",
  followUp: "",
  videoCall: false,
  followupTaken: false,
  createdAt: new Date().toISOString().split("T")[0],
  createdBy: "",
  followUpTakenDate: "",
};
export const LeadInfo = {
  first_name: "",
  last_name: "",
  interestedIn: [],
  full_name: "",
  services: "",
  planned_area: "",
  location_type: "",
  facilities: [],
  company_name: "",
  lead_genrated_from: "",
  lead_alloted_to: "",
  preferred_contact_method: "",
  phone_number: "",
  lead_status: "",
  email: "",
  gst_number: "",
  address: "",
  followUp: [],
  notes: "",
  callRecordingUpload: "",
};
export const userDetails = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  contactNumber: "",
  permanentAddress: "",
  residentialAddress: "",
  emergencyContactNumber: "",
  emergencyContactName: "",
  photograph: "",
  emergencyContactRelation: "",
};
export const EmployeeInfo = {
  firstName: "",
  lastName: "",
  emailId: "",
  dateOfBirth: "",
  photograph: "",
  contactNumber: "",
  permanantAddress: "",
  residentialAddress: "",
  aadharcardNumber: "",
  aadharcardPhotoFront: "",
  aadharcardPhotoBack: "",
  pancardNumber: "",
  pancardPhoto: "",
  emergencyContactNumber: "",
  emergencyContactName: "",
  emergencyContactRelation: "",
  previousOrganizationName: "",
  previousOrganizationLocation: "",
  totalYearsOfExperience: "",
  qualification: "",
  contractOfEmployment: "",
  roleInOrganisation: "",
  workingPlace:
    "319, City Avenue Pune Bangalore, Highway, Wakad, Pimpri-Chinchwad, Pune, Maharashtra 411057",
  packageJoinedAt: "",
  offerLetterAcceptance: "",
  joiningLetterScan: "",
  reportingTo: "",
  bankpassbookPhoto: "",
  residentialProofPhoto: "",
  mobileGivenByOrganisation: "",
  laptopGivenByOrganisation: "",
  simCardGivenByOrganisation: "",
  mobileId: "",
  laptopId: "",
  simCardNumber: "",
  hireViaAgencyOrDirect: "",
  agencyExpences_exlGST: "",
  salaryPerMonth: "",
  pfptDeduction: "",
  modeOfSalary: "",
};
export const QuotationInfo = {
  quotationDate: today.toISOString().substr(0, 10),
  costMarkupPercent: "",
  overrideUnitCost: "",
  bay_size: "",
  span_size: "",
  quotationType: "",
  a_FramePercent: "",
  flatBedPercent: "",
  growBagPercent: "",
  genrated_by: "",
  dutchBucketPercent: "",
  farmType: "outdoor",
  businessName: "",
  contactPersonName: "",
  phoneNumber: "",
  whatsAppNumber: "",
  contactPersonEmail: "",
  gstNumber: "",
  address: "",
  currencyCode: "INR",
  maxDiscount: "",
  showProjectFinancials: true,
  finalQuotation: false,
  notes: "",
};
export const qtnType = [
  "fanpad",
  "nvph",
  "nethouse",
  "fanpad with hydroponics",
  "nvph with hydroponics",
  "nethouse with hydroponics",
  "hydroponics only",
];
export const EmployeeLeavesInfo = {
  leaveDate: "",
  leaveType: "",
  leaveStatus: "pending",
  leaveStartDate: "",
  leaveEndDate: "",
  leaveReason: "",
  leaveReasonType: "",
};

export const VendorInfo = {
  businessName: "",
  businessAddress: "",
  state: "",
  businessNature: "",
  generatedBy: "",
  notes: "",
  legalConstitution: "",
  contactPersonName: "",
  contactPersonPhone: "",
  alternateContactPersonPhone: "",
  contactPersonEmail: "",
  gstNumber: "",
  panNumber: "",
  bankName: "",
  paymentTerm: "",
  bankAccountName: "",
  bankIFSC: "",
  referredBy: "",
  acceptedPaymentMode: [],
  vendorStatus: "active",
  gstCertificate: "",
  vendorReg: "",
  vendorSign: "",
  vendorBankPassbook: "",
};
export const lineItemInfo = {
  particulars: "",
  itemUnit: "",
  totalWithoutGst: "",
  totalWithGst: "",
  itemQuantity: "",
  discount: "",
  itemPrice: "",
  gstRate: "",
  deliveryDate: "",
  notes: "",
};
export const PurchaseOrderInfo = {
  project: "",
  vendor: "",
  countryOfSupply: "india",
  placeOfSupply: "",
  generatedBy: "",
  pdfStatus: false,
  vendorPurchaseOrderDate: "",
  vendorPurchaseOrderFor: "",
  lineItems: [],
};
export const DeliveryLineItemInfo = {
  particulars: "",
  itemUnit: "",
  itemQuantity: "",
};
export const DeliveryChallanInfo = {
  project: "",
  pdfStatus: false,
  countryOfSupply: "india",
  placeOfSupply: "",
  lrNumber: "",
  ewayBillNumber: "",
  transportName: "",
  vehicleNumber: "",
  driverName: "",
  driverPhoneNumber: "",
  generatedBy: "",
  challanDate: "",
  lineItems: [],
};
export const ClientProjectInfo = {
  clientName: "",
  clientEmail: "",
  clientContactNumber: "",
  progressStatus: [],
  businessName: "",
  businessGstNumber: "",
  businessType: "",
  panNumber: "",
  aadharcardNumber: "",
  billingAddress: "",
  farmAddress: "",
  generatedBy: "",
  notes: "",
};
export const ClientSiteInspenctionInfo = {
  projectId: "",
  inspectionDate: today.toISOString().substr(0, 10),
  tentativeStartDate: "",
  generatedBy: "",
  siteAddress: "",
  sitePinCode: "",
  siteManagerName: "",
  quotationNumber: "",
  siteManagerPhone: "",
  numberOfStaff: "",
  typeOfSite: "",
  soilType: "",
  totalAvailableAreaUnit: "",
  totalAvailableAreaNumber: "",
  landOwnership: "",
  landLeveling: "",
  naturalSlopeOfLand: "",
  landHasTree: false,
  landHasStones: false,
  landHasPond: false,
  landHasMudPatch: false,
  landHasHillock: false,
  landFencingType: "",
  landFencingHeightInFeet: "",
  whatsOnNorth: "",
  whatsOnEast: "",
  whatsOnSouth: "",
  whatsOnWest: "",
  obstaclesTowardsSky: false,
  obstaclesTowardsSkyPhoto: "",
  googleMapsLocationLink: "",
  waterSourceType: "",
  waterTankCapacityInLitre: "",
  waterAvailabilityFrequency: "",
  positionOfWaterTank: "",
  waterTankLocationPhoto: "",
  waterPhReading: "",
  waterEcReading: "",
  waterReportPhoto: "",
  entryToFarmSite: "",
  entryTofarmPhoto: "",
  luxReading: "",
  luxReadingPhoto: "",
  luxDeffusionSheet: "",
  luxDeffusionSheetPhoto: "",
  luxWovenPoly: "",
  luxWovenPolyPhoto: "",
  luxUvOpen: "",
  luxUvOpenPhoto: "",
  temperatureReading: "",
  temperatureReadingPhoto: "",
  humidityReading: "",
  humidityReadingPhoto: "",
  climateHottestMonth: "",
  climateColdestMonth: "",
  northSouthMeasurmentInMeter: "",
  northSouthMeasurmentPhoto: "",
  eastWestMeasurmentInMeter: "",
  eastWestMeasurmentPhoto: "",
  layoutPhoto: "",
  facilityToStoreItems: false,
  facilityToStay: false,
  facilityHasBathroom: false,
  facilityHasDrinkingWater: false,
  facilityHasKitchenSpace: false,
  facilityHasFuelForCooking: false,
  transportOption: "",
  nearestTarRoad: "",
  nearestMarket: "",
  nearestMedicalFacility: "",
  nearestStayFacility: "",
  nearestBusStand: "",
  nearestRailwayStation: "",
  nearestAirport: "",
  regulerModeOfTransportation: "",
  vehicleAvilableForEmergency: "",
  electricityConnectionAvailable: false,
  electricityMeterType: "",
  electricityMeterPhaseType: "",
  electricityOutageFrequency: "",
  electricityUnitPrice: "",
  electricityPricingModel: "",
  electricityUsage: "",
  internetConnectivity: "",
  descriptionVideo: "",
  notes: "",
  salesRelationshipManager: "",
  lookingForGovernmentSupport: "",
  projectOwnerGender: "",
  location: "",
  cast: "",
};
export const ProjectTrackingInfo = {
  projectId: "",
  siteSelectionId: "",
  workOrderUpload: "",
  taskList: [
    {
      taskName: "Project Started",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },

    {
      taskName: "Work Order",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "alpha.kryzen.com Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "1st Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Manufacturing Started ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA material procured in factory",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Manufacturing Finished ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Ready For Dispatch",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Ticket ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Stay Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Food Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Arrived ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level Checking",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Civil Material For Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Fan Structure Civil Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Pad Structure Civil Foundation ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "2nd Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Structure Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Hocky Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Gutter Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Fan Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Cellulose Pad Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Top Paper Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Shadenet",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Side Vent Paper Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Entry Cabin Structure",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Digging Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Civil Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Civil Material For A Frame",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Weed Mat Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT Connector Plumbing work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT Pipe Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT to Resoirvoir Plumbing work ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT Waterflow Testing",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Resoirvoir Plumbing work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Cocopeat Decompression",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Filling",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Dripline work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag 4Way Drip Dispenser Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Trellising work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "QA of Project",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Project Handover",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Labour training 7 Days Program",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Hydroponic SOP / Nutrient Chart",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },

    {
      taskName: "Hydroponic Starter Kit",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Management Software Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour return Tickets",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Agronomy Support Start",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ],
  notes: "",
};
export const ProjectTrackingInfoNVPH = {
  projectId: "",
  siteSelectionId: "",
  workOrderUpload: "",
  taskList: [
    {
      taskName: "Project Started",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Work Order",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "alpha.kryzen.com Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "1st Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Manufacturing Started ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA material procured in factory",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Manufacturing Finished ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Ready For Dispatch",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Ticket ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Stay Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Food Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Arrived ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level Checking",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Civil Material For Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "2nd Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Structure Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Hocky Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Gutter Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Top Paper Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Shadenet",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Side Vent Paper Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Entry Cabin Structure",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Digging Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Civil Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Weed Mat Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT Waterflow Testing",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Resoirvoir Plumbing work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Cocopeat Decompression",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Filling",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Dripline work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag 4Way Drip Dispenser Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Trellising work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "QA of Project",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Project Handover",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Labour training 7 Days Program",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Hydroponic SOP / Nutrient Chart",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },

    {
      taskName: "Hydroponic Starter Kit",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Management Software Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour return Tickets",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Agronomy Support Start",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ],
  notes: "",
};
export const ProjectTrackingInfoFanpad = {
  projectId: "",
  siteSelectionId: "",
  workOrderUpload: "",
  taskList: [
    {
      taskName: "Project Started",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },

    {
      taskName: "Work Order",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "alpha.kryzen.com Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "1st Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Manufacturing Started ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA material procured in factory",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Manufacturing Finished ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Ready For Dispatch",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Ticket ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Stay Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Food Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Arrived ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level Checking",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Civil Material For Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Fan Structure Civil Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Pad Structure Civil Foundation ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "2nd Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Structure Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Hocky Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "CEA Gutter Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Fan Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Cellulose Pad Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Top Paper Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Shadenet",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Side Vent Paper Fitting",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Entry Cabin Structure",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Digging Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Civil Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Civil Material For A Frame",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Weed Mat Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Resoirvoir Plumbing work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Cocopeat Decompression",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Filling",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Dripline work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag 4Way Drip Dispenser Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Trellising work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "QA of Project",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Project Handover",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Labour training 7 Days Program",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Hydroponic SOP / Nutrient Chart",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },

    {
      taskName: "Hydroponic Starter Kit",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Management Software Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour return Tickets",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Agronomy Support Start",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ],
  notes: "",
};
export const ProjectTrackingInfoAframe = {
  projectId: "",
  siteSelectionId: "",
  workOrderUpload: "",
  taskList: [
    {
      taskName: "Project Started",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },

    {
      taskName: "Work Order",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "alpha.kryzen.com Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "1st Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Ticket ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Stay Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Food Arrangement",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour Arrived ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Land Level Checking",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Civil Material For Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "2nd Batch Flow",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Polyhouse Shadenet",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Digging Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Water Tank Civil Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Foundation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "A Frame Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Weed Mat Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT Connector Plumbing work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT Pipe Installation",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT to Resoirvoir Plumbing work ",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "NFT Waterflow Testing",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Resoirvoir Plumbing work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Cocopeat Decompression",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Growbag Filling",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Dripline work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag 4Way Drip Dispenser Work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Grow Bag Trellising work",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "QA of Project",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Project Handover",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Labour training 7 Days Program",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Hydroponic SOP / Nutrient Chart",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },

    {
      taskName: "Hydroponic Starter Kit",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Farm Management Software Demo",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Labour return Tickets",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
    {
      taskName: "Agronomy Support Start",
      scheduleDate: "",
      completionDate: "",
      responsibleStaff: "",
      isLocked: false,
      isNotApplicable: false,
      isProjectFinal: false,
      isUpdated: false,
    },
  ],
  notes: "",
};
export const ClientExpenseInfo = {
  projectSelection: "",
  siteSelection: "",
  vendor: "",
  expenseDate: "",
  expenseType: "",
  uploadBill: "",
  expenseAmount: "",
  gstAmount: 0,
  paidBy: "",
  paymentMode: "",
  notes: "",
};
export const ClientPaymentInfo = {
  projectSelection: "",
  siteSelection: "",
  paymentDate: "",
  paymentAmount: "",
  paymentGenratedBy: "",
  paymentMode: "",
  gstRate: 0,
  notes: "",
};
export const ClientProjectHandoverInfo = {
  projectId: "",
  handOverDate: "",
  siteSelectionId: "",
  generatedBy: "",
  northSouthMeasurmentInMeter: "",
  northSouthMeasurmentPhoto: "",
  eastWestMeasurmentInMeter: "",
  eastWestMeasurmentPhoto: "",
  handOverCertificate: "",
  descriptionVideo: "",
  notes: "",
  overheadTankPhoto: "",
  aFramePhoto: "",
  netCupPhoto: "",
  aFrameFoundationPhoto: "",
  ventOpenerPhoto: "",
  aluminiumShadeNetPullyPhoto: "",
  exhaustFanPhoto: "",
  cellusePadPhoto: "",
  waterPumpPhoto: "",
  waterPumpDrainagePhoto: "",
  oxigenationPumpPhoto: "",
  uvLightPhoto: "",
  diskFilterPhoto: "",
  sandFilterPhoto: "",
  foggerAssemblyPhoto: "",
  customerPhotoWithSetUp: "",
  customerFeedbackVideo: "",
};
export const ClientFeedbackInfo = {
  feedBackStatus: "",
  clientName: "",
  clientContactNumber: "",
  clientEmail: "",
  origin: "",
  dataInsufficient: false,
  leadOwner: "",
  feedback: "",
  experience: "",
  rating: "",
  obstracle: "",
  followUp: false,
  followUpDate: "",
};
export const InsentiveInfo = {
  leadName: "",
  product: "",
  preGstAmount: "",
  paidStatus: "pending",
  incentiveDescription: "",
};
export const ProjectSpecificationInfo = {
  project: "",
  ceaType: "",
  psBaySize: "",
  psSpanSize: "",
  aFramePercent: "",
  flatBedPercent: "",
  growBagPercent: "",
  dutchBucketPercent: "",
  hocky: true,
  notes: "",
  hockyPresense: ["north", "south", "east", "west"],
  standardMaterial: [
    {
      item: "Kryzen Biotech Board",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Filter",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Kryzen Biotech Board Legs",
      hsn: "",
      quantity: 2,
      units: "Nos.",
      gstPer: 18,
      specification: "3 Ft - 1.6mm - Sqaure Tube GI",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Zip tie",
      hsn: "",
      quantity: 5,
      units: "Packets",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "UV Light CUV-135",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Binding Wire",
      hsn: "",
      quantity: 2,
      units: "Kg",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Water Inlet Valve",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "GI Wire",
      hsn: "",
      quantity: 2,
      units: "Kg",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  foggerAssembly: [
    {
      item: "Number of Foggers",
      hsn: "",
      quantity: 20,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger lines",
      hsn: "",
      quantity: 4,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger Tank",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger Saddle Piece",
      hsn: "",
      quantity: 4,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger Assembly Block each36mtr",
      hsn: "",
      quantity: 1,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger Latteral Pipe",
      hsn: "",
      quantity: 1,
      units: "Mtrs",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger Motor",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "16 mm Drill BIT",
      hsn: "",
      quantity: 2,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger Mainline Elbow",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger End Caps",
      hsn: "",
      quantity: 4,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger mainline pipe",
      hsn: "",
      quantity: 21,
      units: "Mtrs.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "FilteFogger Mainline End Cap",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Fogger Timer",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  seedlingStation: [
    {
      item: "Number of Stations",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "10ft X 4ft",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Pump",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Timer",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Number of Tanks",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "100Ltr Tank",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  airCirculationFans: [
    {
      item: "Total Number of Fans",
      hsn: "",
      quantity: 2,
      units: "Nos.",
      gstPer: 18,
      specification: "100Ltr Tank",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Total Fan Wire (Mtr)",
      hsn: "",
      quantity: 40,
      units: "Mtr",
      gstPer: 18,
      specification: "3 Core Duel Insulation",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  firstBatchStarter: [
    {
      item: "Nursery Black Pro Trays",
      hsn: "",
      quantity: 150,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Cocopeat Block",
      hsn: "",
      quantity: 2,
      units: "Nos.",
      gstPer: 18,
      specification: "Compact Cocopeat Block of 5kg",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Total Number of Fans",
      hsn: "",
      quantity: 2,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Solution A",
      hsn: "",
      quantity: 10,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Solution B",
      hsn: "",
      quantity: 10,
      units: "Ltrs",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "PH Down / PH Up",
      hsn: "",
      quantity: 5,
      units: "Ltrs",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  flatbedNFTSystemSpecifications: [
    {
      item: "Flatbed Columns 6mtr",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Total Flatbed Blocks 6mtr",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "NFT pipes 6mtr",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "100mm X 50mm",
      orderType: "",
    },
    {
      item: "Saddle Piece Valve",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "",
      orderType: "",
    },
    {
      item: "Outlet Pipe Spouts to Drainage",
      hsn: "",
      quantity: "",
      units: "Mtrs.",
      gstPer: 18,
      specifications: "",
      orderType: "",
    },
    {
      item: "Underground Inlet Pipe",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: '(2" Pvc Pipe 20Ft)',
      orderType: "",
    },
    {
      item: "Flatbed Column 4mtr",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Total Flatbed Blocks 4mtr",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "NFT pipes 4mtr",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "100mm X 50mm",
      orderType: "",
    },
    {
      item: "NFT Inlet threded T",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "(1/2 upvc Treaded Tee)",
      orderType: "",
    },
    {
      item: "NFT Inlet 1/2 pipe",
      hsn: "",
      quantity: "",
      units: "Mtrs.",
      gstPer: 18,
      specifications: "",
      orderType: "",
    },
    {
      item: "NFT Water Tank",
      hsn: "",
      quantity: "",
      units: "Ltrs.",
      gstPer: 18,
      specifications: "Total water tank capacity",
      orderType: "",
    },
    {
      item: "Underground Drainage Pipe",
      hsn: "",
      quantity: "",
      units: "Mtrs.",
      gstPer: 18,
      specifications: '(3" Pvc Drainage Pipe) Mtr',
      orderType: "",
    },
    {
      item: "Total Number of Flatbed",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Manufactured as per standard design",
      orderType: "",
    },
    {
      item: "total Flatbed Blocks",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Streight Connector",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Reducer : 1/2 = 1/4",
      orderType: "",
    },
    {
      item: 'Saddle Piece 1/2"',
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "",
      orderType: "",
    },
    {
      item: "6mm RO Pipe",
      hsn: "",
      quantity: "",
      units: "Mtrs.",
      gstPer: 18,
      specifications: "6 MM LateraL white (1/4 inch)",
      orderType: "",
    },
    {
      item: "Water Centrifugal Motors",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "1.5hp Above Tank Copper",
      orderType: "",
    },
    {
      item: "Underground Drainage T",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Foodgrade uPVC",
      orderType: "",
    },
    {
      item: "NFT End caps",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Foodgrade uPVC",
      orderType: "",
    },
    {
      item: "NFT Spouts",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Foodgrade uPVC",
      orderType: "",
    },
    {
      item: "NFT Connector RO-T",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Foodgrade uPVC",
      orderType: "",
    },
    {
      item: "NFT RO-Key",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Foodgrade uPVC",
      orderType: "",
    },
    {
      item: "NFT Out-T",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Foodgrade uPVC",
      orderType: "",
    },
    {
      item: "Analogue Timer",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Foodgrade uPVC",
      orderType: "",
    },
    {
      item: "NFT Inlet T",
      hsn: "",
      quantity: "",
      units: "Nos.",
      gstPer: 18,
      specifications: "Foodgrade uPVC",
      orderType: "",
    },
  ],
  aFrameNftSystemSpecification: [
    {
      item: "A frame Columns 6mtr",
      hsn: "",
      quantity: 3,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Total A Frame Blocks 6mtr",
      hsn: "",
      quantity: 24,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT pipes 6mtr",
      hsn: "",
      quantity: 2016,
      units: "Nos.",
      gstPer: 18,
      specification: "100mm X 50mm",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "A frame Bottom Fastner",
      hsn: "",
      quantity: 576,
      units: "Nos.",
      gstPer: 18,
      specification: "(10 x 125 Fastner)",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Saddle Piece Valve",
      hsn: "",
      quantity: 24,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Outlet Pipe Spouts to Drainage",
      hsn: "",
      quantity: 6,
      units: "Mtrs.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Underground Inlet Pipe",
      hsn: "",
      quantity: 0,
      units: "Nos.",
      gstPer: 18,
      specification: '(2" Pvc Pipe 20Ft)',
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "A frame Column 4mtr",
      hsn: "",
      quantity: 0,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Total A Frame Blocks 4mtr",
      hsn: "",
      quantity: 0,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT pipes 4mtr",
      hsn: "",
      quantity: 0,
      units: "Nos.",
      gstPer: 18,
      specification: "100mm X 50mm",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT Inlet threded T",
      hsn: "",
      quantity: 168,
      units: "Nos.",
      gstPer: 18,
      specification: "(1/2 upvc Treaded Tee)",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT Inlet 1/2 pipe",
      hsn: "",
      quantity: 65.76,
      units: "Mtrs.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT Water Tank",
      hsn: "",
      quantity: 6000,
      units: "Ltrs.",
      gstPer: 18,
      specification: "Total water tank capacity",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Underground Drainage Pipe",
      hsn: "",
      quantity: 69,
      units: "Mtrs.",
      gstPer: 18,
      specification: '(3" Pvc Drainage Pipe) Mtr',
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Total Number of A-Frames",
      hsn: "",
      quantity: 144,
      units: "Nos.",
      gstPer: 18,
      specification: "Manufactured as per standard design",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "total A-Frame Blocks",
      hsn: "",
      quantity: 24,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Streight Connector",
      hsn: "",
      quantity: 168,
      units: "Nos.",
      gstPer: 18,
      specification: "Reducer : 1/2 = 1/4",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: 'Saddle Piece 1/2"',
      hsn: "",
      quantity: 24,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "6mm RO Pipe",
      hsn: "",
      quantity: 201.6,
      units: "Mtrs.",
      gstPer: 18,
      specification: "6 MM LateraL white (1/4 inch)",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Water Centrifugal Motors",
      hsn: "",
      quantity: 2,
      units: "Nos.",
      gstPer: 18,
      specification: "1.5hp Above Tank Copper",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Underground Drainage T",
      hsn: "",
      quantity: 3,
      units: "Nos.",
      gstPer: 18,
      specification: "Foodgrade uPVC",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT End caps",
      hsn: "",
      quantity: 336,
      units: "Nos.",
      gstPer: 18,
      specification: "Foodgrade uPVC",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT Spouts",
      hsn: "",
      quantity: 336,
      units: "Nos.",
      gstPer: 18,
      specification: "Foodgrade uPVC",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT Connector RO-T",
      hsn: "",
      quantity: 168,
      units: "Nos.",
      gstPer: 18,
      specification: "Foodgrade uPVC",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT RO-Key",
      hsn: "",
      quantity: 336,
      units: "Nos.",
      gstPer: 18,
      specification: "Foodgrade uPVC",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT Out-T",
      hsn: "",
      quantity: 336,
      units: "Nos.",
      gstPer: 18,
      specification: "Foodgrade uPVC",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Analogue Timer",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "Foodgrade uPVC",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "NFT Inlet T",
      hsn: "",
      quantity: 3,
      units: "Nos.",
      gstPer: 18,
      specification: "Foodgrade uPVC",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  waterTankAssemblyforAFrame: [
    {
      item: 'Hose Pipe Clip 1"',
      hsn: "",
      quantity: 4,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: 'Hose Pipe 1"',
      hsn: "",
      quantity: 2,
      units: "Nos.",
      gstPer: 18,
      specification: "10ft long pipe",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Hose Nipple 1inch X 1inch",
      hsn: "",
      quantity: 2,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Oxygen Stone",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Oxygen Tube",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Oxygen Pump",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "10ft long Oxygen pipe tube",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Oxygen Pump Timer",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  plumbingAdhesive: [
    {
      item: "PVC Adhesive Solution",
      hsn: "",
      quantity: 1,
      units: "Ltrs",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "UPVC Adhesive Solution",
      hsn: "",
      quantity: 1,
      units: "Ltrs",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Silicon Injection Tube",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Teflon Tape",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  handBookAndManual: [
    {
      item: "Printed Manual of Items",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Warrenty Manual",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "pH Meter",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "EC Meter",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "PH Stripes",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Temprature Clock",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Software Logins",
      hsn: "",
      quantity: 1,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "HM Codes / Labour Training",
      hsn: "",
      quantity: 3,
      units: "Nos.",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  aFrameBlockSpecification: [
    {
      item: "Total Blocks Available of A Frame",
      hsn: "",
      quantity: 10,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "A frames single line",
      hsn: "",
      quantity: 2.85,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Adjusted A Frame Length",
      hsn: "",
      quantity: 21,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "A frame rows",
      hsn: "",
      quantity: 8,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "A Frame complete Usable length",
      hsn: "",
      quantity: 14,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "A Frame Access Length",
      hsn: "",
      quantity: 6,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "A frame Unusable length",
      hsn: "",
      quantity: 0,
      units: "Program Count",
      gstPer: 18,
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  flatBedBlockSpecifications: [
    {
      item: "Total Blocks Available of Flatbed",
      hsn: "",
      quantity: 0,
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "A frames single line",
      hsn: "",
      quantity: 2.857142857,
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Adjusted Flatbed Length",
      hsn: "",
      quantity: 21,
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Flatbed rows",
      hsn: "",
      quantity: 8,
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Flatbed complete Usable length",
      hsn: "",
      quantity: 14,
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Flatbed Access Length",
      hsn: "",
      quantity: 6,
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Flatbed Unusable length",
      hsn: "",
      quantity: 0,
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
  ],
  growBagsBlockSpecifications: [
    {
      item: "Total Blocks Available of Growbags",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Growbags single line",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Adjusted Growbags Length",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Growbags rows",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Growbags complete Usable length",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Growbags Access Length",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    {
      item: "Growbags Unusable length",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specification: "",
      orderType: "",
      vendor: "",
      cogs: "",
    },
    // Add more items as needed
  ],
  dutchBucketBlockSpecification: [
    {
      item: "Total Blocks Available of Dutch Bucket",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Dutch Bucket single line",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Adjusted Dutch Bucket Length",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Dutch Bucket rows",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Dutch Bucket complete Usable length",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Dutch Bucket Access Length",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
    {
      item: "Dutch Bucket Unusable length",
      hsn: "",
      quantity: "",
      units: "Program Count",
      gstPer: "",
      specifications: "",
      orderType: "",
    },
  ],
};

export const BuyersReportInfo = {
  report: [],
};

export const WorkforceCreateInfo = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  dateOfJoining: "",
  dateOfBirth: "",
  contactNumber: "",
  aadharNumber: "",
  panNumber: "",
  permanentAddress: "",
  emergencyContactName: "",
  emergencyContactNumber: "",
  emergencyContactRelation: "",
  uploadPhoto: "",
  uploadAadharFront: "",
  uploadAadharBack: "",
  salary: "",
  salaryFrequency: "",
  salaryFrequencyType: "",
};

export const AssetsCreateInfo = {
  resourceName: "",
  companyName: "",
  vendor: "",
  deploymentDate: "",
  warrantyPeriod: "",
  paymentAmount: "",
  paymentMode: "",
  uploadBill: "",
  uploadWarrantyCard: "",
  notes: "",
};

export const InventoryCreateInfo = {
  assetName: "",
  companyName: "",
  vendor: "",
  refillFrequency: "",
  refillFrequencyType: "",
  frequencyCycle: "",
  paymentAmount: "",
  paymentMode: "",
  uploadBill: "",
  notes: "",
};
export const ReimbursementInfo = {
  amount: "",
  addRecipt_bill: "",
  selectProject: "",
  reimbursementsDescription: "",
  generatedBy: "",
  paidStatus: "pending",
  incentiveDescription: "",
  modeOfPayment: "",
};

export const QuotationMatrixInfo = {
  type: "",
  factor: "",
  markup: "",
  basePrice1to190: "",
  basePrice191to450: "",
  basePrice451to950: "",
  basePrice951to1860: "",
  basePrice1861to3720: "",
  basePrice3721to5999: "",
  basePrice6000to7999: "",
  basePrice8000Plus: "",

  empIncentivePercentage1to190: "",
  empIncentivePercentage191to450: "",
  empIncentivePercentage451to950: "",
  empIncentivePercentage951to1860: "",
  empIncentivePercentage1861to3720: "",
  empIncentivePercentage3721to5999: "",
  empIncentivePercentage6000to7999: "",
  empIncentivePercentage8000Plus: "",

  sqMtrWeed: "",
  markupPercentGP: "",
  siteInspectionPerProject: "",
  officeAdminCostPerProject: "",
  marketingBudgetPerProject: "",
  rentSalaryPerProject: "",
  labourTicketMHGOAGJ: "",
  labourTicketRJMPTLCGKA: "",
  labourTicketPBHRUPJHODAPTNKL: "",
  maximumDiscount: "",
};

export const SiteInspectionFeedbackInfo = {
  schedulingExperience: "",
  agentOnTime: "",
  queriesAnswered: "",
  landSurvey: "",
  climateInfo: "",
  notes: "",
};

export const AgronomyWikiInfo = {
  // image: "",
  // name: "",
  // articleType: "",
  // classification: "",
  // scientificName: "",
  // description: "",
  // seedUrl: "",
  // daysToPipeTransfer: "",
  // nurseryWaterIntervalDays: "",
  // nurseryFertilizerIntervalDays: "",
  // minGrowingTemperature: "",
  // maxGrowingTemperature: "",
  // minGrowingHumidity: "",
  // maxGrowingHumidity: "",
  // waterPhRequirement: "",
  // waterTdsRequirement: "",
  // germinationDays: "",
  // seedlingDays: "",
  // vegetativeDays: "",
  // floweringDays: "",
  // canPrune: false,
  // possiblePestAttacks: "",
  // possiblePestControls: "",
  // possibleDiseases: "",
  // possibleDiseaseControls: "",
  name: "",
  articleType: "",
  classification: "",
  subCategory: "",
  scientificName: "",
  description: "",
  seedUrl: "",
  seedlingDuration: "",
  minGrowingTemperature: "",
  maxGrowingTemperature: "",
  minGrowingHumidity: "",
  maxGrowingHumidity: "",
  minPh: "",
  maxPh: "",
  minTds: "",
  maxTds: "",
  seedGerminationDays: "",
  twoLeavesDays: "",
  canPrune: false,
  generalServingSize: "",
  image: "",
  proneToPowderyMildew: false,
  proneToDownyMildew: false,
  proneToFusariumWilt: false,
  proneToAnthracnose: false,
  proneToRootRot: false,
  proneToLeafSpot: false,
  proneToBotrytisRot: false,
  proneToBacterialLeafSpot: false,
  proneToMosaicVirus: false,
  proneToLeafCurleVirus: false,
  proneToClubRoot: false,
  productionPerPlantPerCycle: false,
  difficultyToGrowInWinter: "",
  difficultyToGrowInSpring: "",
  difficultyToGrowInSummer: "",
  difficultyToGrowInMonsoon: "",
  difficultyToGrowInAutumn: "",
  difficultyToGrowInPrewinter: "",
  favouriteOnly: false,
};

export const BuyersManagementInfo = {
  photo: "",
  name: "",
  email: "",
  description: "",
  yearEstablished: "",
  fssaiNumber: "",
  varifiedBadge: false,
  isWholesaler: false,
  isRetailer: false,
  isTrader: false,
  isExporter: false,
  isFoodProcessor: false,
  dealPerishableVegetables: false,
  dealExoticVegetables: false,
  dealFruitsAndBerries: false,
  dealDryFruits: false,
  dealGrains: false,
  buyerRating: "",
  creditScore: "",
  deliveryRoad: false,
  deliveryRail: false,
  deliverySea: false,
  deliveryCargo: false,
  phoneNumber1: "",
  phoneNumber2: "",
  addressLine1: "",
  addressState: "",
  addressPincode: "",
  openOnMonday: false,
  openOnTuesday: false,
  openOnWednesday: false,
  openOnThursday: false,
  openOnFriday: false,
  openOnSaturday: false,
  openOnSunday: false,
  operationalHoursMonday: "",
  operationalHoursTuesday: "",
  operationalHoursWednesday: "",
  operationalHoursThursday: "",
  operationalHoursFriday: "",
  operationalHoursSaturday: "",
  operationalHoursSunday: "",
  googleMapLink: "",
  market: "",
  report: [],
};

export const FarmSupportInfo = {
  queryRaised: "",
  relatedTo: "",
  image: "",
  message: "",
  generatedBy: "",
  assignedName: "",
};

export const lineItemInvoiceInfo = {
  particulars: "",
  totalWithoutGst: "",
  totalWithGst: "",
  itemUnit: "",
  itemQuantity: "",
  discount: "",
  HSN: "",
  itemPrice: "",
  gstRate: "",
};

export const ClientInvoiceInfo = {
  clproject: "",
  clquotation: "",
  invoiceType: "",
  invoiceDate: "",
  totalAmountIncGst: "0.0",
  businessName: "",
  contactPersonName: "",
  phoneNumber: "",
  gstNumber: "",
  address: "",
  countryOfSupply: "india",
  placeOfSupply: "",
  invoiceFor: "",
  totalGst: "0.0",
  notes: "",
  generatedBy: "",
  pdfStatus: false,
  lineItems: [],
};

export const FarmTrainingInfo = {
  name: "",
  email: "",
  contactNumber: "",
  enrollDate: "",
  farmTrainingDate: "",
  amountPaid: "",
  modeOfPayment: "",
  foodPreference: "",
  hotelBooked: "",
  upgradeRequest: [],
  clientType: [],
  farmTrainingLocation: "",
  farmTrainingLocationUrl: "",
  generatedBy: "",
};

export const MarketingList = {
  MarketingList: "",
  MarketingCampaign: "",
  ScheduleDate: "",
  Thresholds: "",
};

export const WaterManagement = {
  cycleName: "",
  typeOfSystem: "",
  waterTankCapacity: "",
  startingPh: "",
  startingEc: "",
  waterSource: "",
  timeLines: [],
};

export const TimeLineWaterManagementInfo = {
  phReading: "",
  ecReading: "",
  scheduleDate: "",
};
export const scheduleInfo = {
  date: "",
  schedule: "",
  actions: "",
  scheduleDone: false,
  scheduleDoneDate: "",
};
export const nurseryBatchInfo = {
  batchName: "",
  cropName: "",
  numberOfplants: "",
  batchStartDate: "",
  batchEndDate: "",
  schedule: [],
  notes: "",
  generatedBy: "",
};

export const taskManagementInfo = {
  name: "",
  description: "",
  assignTo: "",
  deadline: "",
  status: "",
  feedback: "",
  taskType: "",
  startDate: "",
  frequency: "",
  frequencyType: "",
};

export const priceWatcherInfo = {
  plantName: "",
  plantId: "",
  week: "",
  weekStartDate: "",
  weekEndDate: "",
  weeklyPrice: "",
  month: "",
  year: "",
  season: "",
};
