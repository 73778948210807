import React, { useEffect, useState } from "react";
import signature from "../../../certificates/signature.jpg";
import QrCode from "../../../certificates/QrCode.png";
import { roundOffCost } from "../../../Components/Functions/amt.fun";
const PaymentBreakdown = ({ allQData }) => {
  const [cost, setCost] = useState(0);
  const [gstcost, setGstCost] = useState(0);

  useEffect(() => {
    let total = 0;
    if (allQData?.input_info?.overrideUnitCost > 0) {
      total += allQData?.input_info?.overrideUnitCost;
    } else {
      total = allQData?.quotationMatrixCost?.total_cost;
    }
    setCost(total);

    setGstCost((total * 18) / 100);
  }, [allQData]);
  return (
    <div>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ width: "42%" }}>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Payment Schedule</th>

                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Site inspection & project report</td>

                    <td>{roundOffCost(50000)}</td>
                  </tr>
                  <tr>
                    <td>Work order & advance (10%)</td>

                    <td>{roundOffCost((cost + gstcost) * 0.1 - 50000)}</td>
                  </tr>
                  <tr>
                    <td>
                      On 1<sup>st</sup> vehical dispatch (50%)
                    </td>

                    <td>{roundOffCost((cost + gstcost) * 0.5)}</td>
                  </tr>

                  <tr>
                    <td>
                      On 2<sup>nd</sup> vehical dispatch (40%)
                    </td>

                    <td>{roundOffCost((cost + gstcost) * 0.4)}</td>
                  </tr>
                  <tr>
                    <td>Total (100%)</td>

                    <td>{roundOffCost(cost + gstcost)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ width: "42%" }}>
              <p>
                <b>Payment Details</b>
                <br />
                Name: <b>KRYZEN BIOTECH PRIVATE LIMITED</b>
                <br />
                Bank: <b>ICICI BANK</b>
                <br />
                Account No.: <b>016905015915</b>
                <br />
                IFSC: <b>ICIC0007565</b>
                <br />
                Book your site inspection by paying via UPI / Debit Card /
                Credit Card / Net Banking / Wallets / NBFC.
                <br />
                {/* <a href="https://razorpay.me/@kryzen" target="_blank">
                  https://razorpay.me/@kryzen
                </a> */}
              </p>
            </td>
            <td style={{ width: "16%" }}>
              <p style={{ textAlign: "center" }}>
                Site Inspection + Payment Getway Fee
                <img width={"140px"} src={QrCode} alt="kryzen QrCode" />
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          border: "none",
          marginTop: "0.2em",
        }}
      >
        <span style={{ width: "50%", border: "none" }}></span>
        <span style={{ textAlign: "right", width: "40%", border: "none" }}>
          <img width={"50%"} src={signature} alt="kryzen signature" />
        </span>
      </div>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default PaymentBreakdown;
