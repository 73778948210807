import React, { useEffect, useRef, useState } from "react";
import UAParser from "ua-parser-js";
import { WebcamCapture } from "./webcam";
import SimpleButton from "../../Components/layouts/simple_button";
import { useDispatch, useSelector } from "react-redux";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import { useNavigate } from "react-router";
import axios from "axios";
import { userLoginLog } from "../../redux/user/loginReducer";
import Cookies from "js-cookie";
import { getUplodedImage } from "../../CustomComponent/upload_Image";
const cookielog = Cookies.get("userlog") || null;
let userDatalog = null;
if (cookielog) {
  userDatalog = JSON.parse(cookielog);
}
function parseUserAgent(userAgentString) {
  const parser = new UAParser();
  const userAgentData = parser.setUA(userAgentString).getResult();

  const browser = userAgentData.browser.name;
  const browserVersion = userAgentData.browser.version;
  const operatingSystem = userAgentData.os.name;
  const renderingEngine = userAgentData.engine.name;
  const compatibility =
    userAgentData.engine.name + "/" + userAgentData.engine.version;

  return {
    browser: browser,
    version: browserVersion,
    operatingSystem: operatingSystem,
    renderingEngine: renderingEngine,
    compatibility: compatibility,
  };
}
function generateRandomSixDigitNumber() {
  return Math.floor(100000 + Math.random() * 900000);
}

const getLoginStatus = (time) => {
  const convertToMinutes = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes, seconds] = time
      .split(":")
      .map((part) => parseInt(part, 10));

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  const currentTime = convertToMinutes(time);
  const startTime = convertToMinutes("8:00:00 AM");
  const endTime = convertToMinutes("9:00:00 AM");
  const graceStartTime = convertToMinutes("9:00:00 AM");
  const graceEndTime = convertToMinutes("9:10:00 AM");
  const lateStartTime = convertToMinutes("9:10:00 AM");
  const lateEndTime = convertToMinutes("1:00:00 PM");
  const secondHalfStartTime = convertToMinutes("1:00:00 PM");
  const secondHalfEndTime = convertToMinutes("2:05:00 PM");
  const lateSecondHalfStartTime = convertToMinutes("2:05:00 PM");
  const lateSecondHalfEndTime = convertToMinutes("3:00:00 PM");

  if (currentTime >= startTime && currentTime <= endTime) {
    return "intime";
  } else if (currentTime > graceStartTime && currentTime <= graceEndTime) {
    return "grace period";
  } else if (currentTime > graceEndTime && currentTime <= lateEndTime) {
    return "late";
  } else if (
    currentTime > secondHalfStartTime &&
    currentTime <= secondHalfEndTime
  ) {
    return "2nd half";
  } else if (
    currentTime > lateSecondHalfStartTime &&
    currentTime <= lateSecondHalfEndTime
  ) {
    return "late 2nd half";
  } else {
    return "non-attended";
  }
};
const getTime = () => {
  const date = new Date();
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
  });
  return formattedTime;
};
const getDate = () => {
  const date = new Date();
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
  return formattedDate;
};
const Attendance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const store = useSelector((store) => store.login);
  const user = useSelector((store) => store?.login?.user);
  const [userLocation, setUserLocation] = useState(null);
  const [img, setImg] = useState(null);
  const [resetOTP, setResetOTP] = useState(null);
  const [prevOtp, setPrevOTP] = useState(null);
  const [os, setOS] = useState(navigator.platform);
  const [ip, setIp] = useState("");
  const [checkAttendance, setCheckAttendance] = useState("");
  const [userAgent, setUserAgent] = useState(navigator.userAgent);
  const inputRefs = useRef([]);
  const [employeeData, setEmployeeData] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState({
    date: getDate(),
    time: getTime(),
  });
  const initialOtpState = Array(6).fill("");
  const [otp, setOtp] = useState(initialOtpState);
  const [imageUrls, setImageUrls] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (img) {
      getUplodedImage(setImageUrls, img, "img");
    }
  }, [img]);
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if the current one is not the last
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const newOtp = [...otp];

      if (!newOtp[index]) {
        if (index > 0) {
          newOtp[index - 1] = "";
          inputRefs.current[index - 1].focus();
        }
      } else {
        newOtp[index] = "";
      }

      setOtp(newOtp);
    }
  };

  useEffect(() => {
    if (user?.UserId) {
      performRequest("GET", `/login/getUserById/${user?.UserId}`)
        .then((res) => {
          setEmployeeData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!checkAttendance && user.UserId) {
      performRequest("POST", `/attendance/getUserAttendance/`, {
        userId: user.UserId,
        date: currentDateTime.date,
      })
        .then((res) => {
          if (res.data && res.data.msg === "Attendance already taken") {
            Toast("success", `Attendance already taken! for ${user.Name}`);
            dispatch(
              userLoginLog({
                ...store?.userlog,
                userId: user?.UserId,
                Role: user?.Role,
                firstLogin: true,
              })
            );
            navigate("/");
          } else {
            setCheckAttendance("Not Attended");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user, checkAttendance]);

  const handleotpSend = () => {
    if (checkAttendance === "Not Attended") {
      setOtp(initialOtpState);
      if (user && employeeData && employeeData?.contactNumber && !otpSent) {
        let sendOtp = generateRandomSixDigitNumber();
        setPrevOTP(sendOtp);
        performRequest("POST", `/sendOtp/createOtp/`, {
          otp: sendOtp,
          mobileNumber: employeeData.contactNumber,
          employeeName: user.Name,
        })
          .then((res) => {
            Toast(
              "success",
              `Otp sent on your whatsApp ${employeeData?.contactNumber} successfully`
            );
            setOtpSent(true);
          })
          .catch((err) => {
            Toast(
              "error",
              `Error while sent otp on whatsApp ${employeeData?.contactNumber}!`
            );
            console.error(err);
          });
      } else if (
        user &&
        employeeData &&
        !employeeData?.contactNumber &&
        !otpSent
      ) {
        Toast(
          "error",
          `Mobile number not register for user ${user?.Name}! please contact with team.`
        );
      }
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setUserLocation({ latitude, longitude });
    });
  }, []);

  useEffect(() => {
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        const ip = data.ip;
        setIp(ip);
      });
  }, []);

  const downloadImage = async (image) => {
    try {
      const response = await axios.get(image, { responseType: "blob" });
      return response.data;
    } catch (error) {
      console.error("Error downloading the image:", error);
      return null;
    }
  };

  // const uploadImage = async (image) => {
  //   let formData = new FormData();
  //   const blob = await downloadImage(image);
  //   formData.append("files", blob);
  //   const headers = { "Content-Type": "multipart/form-data" };
  //   performRequest("POST", "/fileUploads/fileUpload", formData, headers)
  //     .then((res) => {
  //       if (
  //         res?.data?.data?.uploadSuccess.length > 0 &&
  //         res?.data?.data?.uploadSuccess[0].path
  //       ) {
  //         setImg(res?.data?.data?.uploadSuccess[0].path);
  //       }
  //     })
  //     .catch((err) => console.error(err));
  // };
  const uploadImage = async (image) => {
    const file0 = await downloadImage(image);

    // Function to convert Blob to File
    function blobToFile(blob, filename) {
      return new File([blob], filename, {
        type: blob.type,
        lastModified: Date.now(),
      });
    }

    // Function to generate a unique filename
    function generateUniqueFilename(baseName, extension) {
      const timestamp = Date.now();
      const randomString = Math.random().toString(36).substring(2, 15);
      return `${baseName}_${timestamp}_${randomString}.${extension}`;
    }
    // Convert the Blob to a File with a unique name
    const uniqueFilename = generateUniqueFilename("example", "jpg");
    const file = blobToFile(file0, uniqueFilename);

    try {
      const res = await performRequest("POST", "/fileUploads/fileUpload", {
        fileName: file.name,
      });
      if (res?.data?.url) {
        let data = await axios.put(res?.data.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        if (data.status === 200) {
          setImg(file.name);
          // getUplodedImage(props, key, file.name);
        } else {
          // props.setFieldValue(key, "");
        }
      } else {
        // props.setFieldValue(key, "");
      }
    } catch (error) {
      console.error("Error fetching signed URL:", error);
    }
  };

  // useEffect(() => {
  //   fetch(`https://ipinfo.io/${ip}/json`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const wifiName = data.hostname;
  //       console.log(data);
  //       // Use the Wi-Fi network name
  //     });
  // }, [ip]);

  const handelSubmit = () => {
    if (prevOtp == otp.join("")) {
      alert("OTP Matched!");

      let data = {
        photo: img,
        time: currentDateTime.time,
        date: currentDateTime.date,
        operatingSystem: os,
        firstLogin: "true",
        ipAddress: ip,
        loginType: "login",
        browser: parseUserAgent(userAgent).browser,
        userLocation: userLocation,
        userId: user.UserId,
        userName: user.Name,
        loginStatus: getLoginStatus(currentDateTime.time),
      };
      performRequest("POST", `/attendance/createAttendance/`, data)
        .then((res) => {
          if (res.data.msg && res.data.msg === "Attendance already taken") {
            Toast("success", `Your Attendance has already been taken!`);

            dispatch(
              userLoginLog({
                ...store?.userlog,
                userId: user?.UserId,
                Role: user?.Role,
                firstLogin: true,
              })
            );
            navigate("/");
          } else {
            dispatch(
              userLoginLog({
                userId: store?.user?.UserId,
                Role: store?.user?.Role,
                firstLogin: true,
                loginTime: new Date().getTime(),
              })
            );
            Toast("success", `Your Attendance has been created!`);
            navigate("/");
          }
        })
        .catch((err) => {
          Toast("error", `Server error!`);
          console.error(err);
        });
    } else {
      alert("OTP does not matched! Please try again");
      setOtp(initialOtpState);
      inputRefs.current[0].focus();
    }
  };

  const getDisabledStatus = () => {
    if (!otp.includes("") && img) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!store?.isLoggedIn) {
      navigate("/account-login");
    } else if (store.isLoggedIn && store.userlog && store.userlog.firstLogin) {
      navigate("/");
    }
  }, [store]);

  const handleLogout = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
    localStorage.clear();
    sessionStorage.clear();

    window.location.href = "/account-login";
  };

  return (
    <div className="page page-center">
      <style>
        {` .otp-input {
            width: 40px;
            height: 40px;
            text-align: center;
            font-size: 18px;
            margin: 5px;
            border: 1px solid #ccc;
            border-radius: 5px;
            display: inline-block;
            box-sizing: border-box;
          }
          `}
      </style>
      <div className="container py-4 ">
        <div className="card">
          <h2 className="h2 text-center mt-4">Attendance Dashboard</h2>
          <div className="card-body">
            <div className="container-tight">
              {img ? (
                <div className="text-center">
                  <div className=" text-center">
                    <img src={imageUrls?.img} alt={"User Img"} />
                  </div>
                  <SimpleButton
                    onClick={() => setImg(null)}
                    buttonText={img ? "Retake" : "Capture photo"}
                  />
                </div>
              ) : (
                <WebcamCapture img={img} uploadImage={uploadImage} />
              )}

              <div className="mb-2">
                <label className="form-label">
                  Enter OTP
                  <span className="form-label-description">
                    <span
                      className="text-blue cursor-pointer"
                      onClick={() => {
                        setOtpSent(false);
                        setResetOTP(!resetOTP);
                      }}
                    >
                      Resend OTP
                    </span>
                  </span>
                </label>

                <div className="container">
                  <div className="d-flex">
                    {otp.map((value, index) => (
                      <div className="col-md-2" key={index}>
                        <input
                          type="text"
                          className="form-control otp-input"
                          maxLength="1"
                          pattern="[0-9]"
                          required
                          value={value}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h3>
                  LoggedIn Date & Time:{" "}
                  <span>
                    {currentDateTime.date} , {currentDateTime.time}
                  </span>
                </h3>
              </div>
              <div>
                <h3>
                  Ip Address & Operating System:{" "}
                  <span>
                    {ip} , {os}
                  </span>
                </h3>
              </div>
            </div>
            <div className="text-center">
              <button
                className="btn btn-primary me-2"
                onClick={handleotpSend}
                disabled={isDisabled}
              >
                Send OTP
              </button>
              <button className="btn btn-primary me-2" onClick={handleLogout}>
                Logout
              </button>
              <button
                disabled={getDisabledStatus()}
                onClick={handelSubmit}
                className="btn btn-primary initialism"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
