import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement
);

const generateLabels = (data) => {
  return data?.map((item) => {
    const weekStartDate = new Date(item.weekStartDate);
    const options = { month: "short", year: "numeric" };
    return weekStartDate.toLocaleDateString("en-US", options);
  });
};

const formatDateToYearMonthDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const sortDataByDate = (data) => {
  if (!Array.isArray(data)) {
    console.error("Expected an array but received:", data);
    return [];
  }

  return data.sort(
    (a, b) => new Date(a.weekStartDate) - new Date(b.weekStartDate)
  );
};

const getPriceForWeek = (data, weekIndex) => {
  const record = data[weekIndex];
  return record ? +record.weeklyPrice : null;
};

const generateFutureLabels = (lastDate, numWeeks) => {
  const futureLabels = [];
  const currentDate = new Date(lastDate);

  for (let i = 1; i <= numWeeks; i++) {
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + i * 7);
    futureLabels.push(
      futureDate.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      })
    );
  }

  return futureLabels;
};

const replaceNullsWithPreviousValue = (data) => {
  let lastKnownValue = null;
  return data?.map((value) => {
    if (value !== null) {
      lastKnownValue = value;
      return value;
    } else {
      return lastKnownValue;
    }
  });
};

const PriceWatcheLineChart = ({
  plantPriceData,
  dataFrom,
  chartDataFuture = [],
}) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    let sortedData = sortDataByDate(plantPriceData);

    const currentDate = new Date();

    if (dataFrom === 6) {
      const sixMonthsAgo = new Date(currentDate);
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      sixMonthsAgo.setDate(1);
      sortedData = sortedData?.filter(
        (item) =>
          new Date(item.weekStartDate) >= sixMonthsAgo &&
          new Date(item.weekStartDate) <= currentDate
      );
    } else if ([1, 2, 3].includes(dataFrom)) {
      const startYear = currentDate.getFullYear() - dataFrom + 1;
      sortedData = sortedData?.filter((item) => {
        const itemDate = new Date(item.weekStartDate);
        return (
          itemDate.getFullYear() >= startYear &&
          itemDate.getFullYear() <= currentDate.getFullYear()
        );
      });
    }

    const filteredData = sortedData ?? [];
    const labels = generateLabels(filteredData);

    let retailPriceHigh = filteredData?.map((_, index) =>
      getPriceForWeek(filteredData, index)
    );

    retailPriceHigh = replaceNullsWithPreviousValue(retailPriceHigh);

    const predictedPrices = replaceNullsWithPreviousValue(chartDataFuture);
    const lastDate =
      filteredData.length > 0
        ? filteredData[filteredData.length - 1].weekEndDate
        : new Date();
    const futureLabels = generateFutureLabels(lastDate, predictedPrices.length);
    const lastKnownPrice = retailPriceHigh[retailPriceHigh.length - 1];

    const data = {
      labels: [...labels, ...futureLabels],
      datasets: [
        {
          label: "APMC AVG PRICE",
          data: [
            ...retailPriceHigh,
            lastKnownPrice,
            ...Array(predictedPrices.length).fill(null),
          ],
          backgroundColor: "white",
          borderColor: "rgba(11, 144, 242, 0.8)",
          borderWidth: 2.5,
          tension: 0.8,
          pointRadius: 0,
          pointHoverRadius: 0,
        },
        {
          label: "Predicted Retail Price High",
          data: [
            ...Array(retailPriceHigh.length).fill(null),
            lastKnownPrice,
            ...predictedPrices,
          ],
          backgroundColor: "white",
          borderColor: "rgba(231, 126, 17, 0.8)",
          borderWidth: 4,
          borderDash: [5, 5],
          tension: 0.3,
          pointRadius: 0,
          pointHoverRadius: 0,
        },
      ],
    };

    setChartData(data);
  }, [plantPriceData, dataFrom, chartDataFuture]);

  const options = {
    plugins: {
      tooltip: {
        theme: "dark",
      },
      legend: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        type: "category",
        stacked: true,
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.6)",
          borderColor: "rgba(200, 200, 200, 0.6)",
        },
        ticks: {
          padding: 10,
          color: "#000",
        },
      },
      y: {
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.6)",
          borderColor: "rgba(200, 200, 200, 0.6)",
        },
        ticks: {
          padding: 10,
          color: "#000",
        },
      },
    },
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderWidth: 1,
    point: {
      radius: 0,
    },
    animation: {
      duration: 1000,
      easing: "easeOutQuart",
      loop: false,
    },
  };

  return <Line data={chartData} options={options} />;
};

export default PriceWatcheLineChart;
