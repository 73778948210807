import React from "react";
import signature from "../../../certificates/signature.jpg";
import { useEffect } from "react";
const PaymentInfo = () => {
  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "2px",
    padding: "0px",
    paddingLeft: "3px",
    fontSize: "12px",
    borderCollapse: "collapse",
  };
  useEffect(() => {
    const TR = document.getElementsByTagName("tr");
    const TH = document.getElementsByTagName("th");
    const TD = document.getElementsByTagName("td");
    const H3 = document.getElementsByTagName("h3");

    Array.from(TR).forEach((tr) => {
      Object.assign(tr.style, style);
    });
    Array.from(TH).forEach((th) => {
      Object.assign(th.style, style);
    });
    Array.from(TD).forEach((td) => {
      Object.assign(td.style, style);
    });
    Array.from(H3).forEach((h3) => {
      Object.assign(h3.style, {
        marginTop: "14px",
        marginBottom: "2px",
      });
    });
  }, []);
  return (
    <table style={{ width: "100%", marginTop: "2px" }}>
      <tbody>
        <tr>
          <td style={{ width: "70%" }}>
            <p
              style={{
                fontSize: "0.8rem",
                borderBottom: "1px solid gray",
                marginBottom: "2px",
              }}
            >
              <b>Payment Details</b>
              <br />
              Account Name: <b>KRYZEN BIOTECH PRIVATE LIMITED</b>
              <br />
              Bank Name: <b>ICICI BANK</b>
              <br />
              Account Number: <b>016905015915</b>
              <br />
              IFSC Code: <b>ICIC0007565</b>
            </p>
            <p style={{ fontSize: "0.8rem" }}>
              Book Your Site Inspection by Paying Via UPI/ QR/ Debit Card/
              Credit Card/ Wallet or Net Banking.
              <br />
              <a href="https:/razorpay.me/@kryzen">
                https:/razorpay.me/@kryzen
              </a>
            </p>
          </td>
          <td>
            <img src={signature} width={"200px"} alt="signature" />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PaymentInfo;
