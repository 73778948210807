import React from "react";

import signature from "../../../certificates/signature.jpg";
import kryzencertificates from "../../../certificates/kryzencertificates.png";
const Promotion = ({ data }) => {
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>About Kryzen Biotech Pvt. Ltd.</h2>

      <h3>Company Profile:</h3>
      <p>
        Established in the year 2019, Kryzen Biotech Private Limited is a
        trusted name engaged in providing hydroponic training and Hydroponics
        services with automation for high tech farming. Kryzen Biotech is a
        Controlled Environment Agriculture (CEA) specialist company that
        combines sustainable technology and modern farming techniques, to create
        highly productive and economically profitable models for the new-age
        urban farmer.
      </p>

      <div
        style={{
          display: "flex",

          justifyContent: "center",
          width: "100%",
          marginTop: "12px",
        }}
      >
        <img src={kryzencertificates} width={"80%"} alt="kryzenCertificates" />
      </div>

      <p>
        The project report was completed under the guidance of Kryzen Biotech
        Pvt. Ltd. and its external associates.
      </p>

      <p>
        Disclaimer from Kryzen Biotech Pvt. Ltd.: This document has been
        prepared by Kryzen Biotech Pvt. Ltd. for{" "}
        {data &&
          (data?.clientDetails?.businessName ||
            data?.clientDetails?.clientName)}
        . The views expressed and the conclusions arrived at in this document,
        including financial, are based on the data and information provided by
        various Government Departments and agencies, overall policy and approach
        of the State Government, discussions with the stakeholders at various
        levels as also the observations recorded by KBPL during the study. Being
        only advisory in nature, such views and conclusions do not represent or
        reflect, in any way, the policy or views of KBPL.
      </p>
      <p></p>

      <div style={{ width: "100%", border: "none", display: "flex" }}>
        <div style={{ width: "70%", border: "none" }}></div>
        <div style={{ width: "30%", border: "none" }}>
          <img width="100%" src={signature} alt="signature" />
        </div>
      </div>
    </div>
  );
};

export default Promotion;
