import React, { useEffect, useState } from "react";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";

const KryzenDetails = () => {
  const [tableData, setTableCount] = useState();
  useEffect(() => {
    const interval = setInterval(() => {
      performRequest("GET", "/allTablesCount/getCount")
        .then((res) => {
          if (res.status === 200) {
            setTableCount(res.data);
          } else {
            Toast("error", "Error while getting project Details!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    performRequest("GET", "/allTablesCount/getCount")
      .then((res) => {
        if (res.status === 200) {
          setTableCount(res.data);
        } else {
          Toast("error", "Error while getting project Details!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, []);
  return (
    <>
      <div className="col-lg-3">
        <div className="card card-sm">
          <div className="card-body bg-green-lt">
            <div className="align-items-center text-center">
              <div className="col">
                <div
                  className="fw-bolder"
                  style={{
                    fontSize: "7em",
                  }}
                >
                  52780
                </div>
                <div
                  className="fw-semibold"
                  style={{
                    fontSize: "2.3em",
                  }}
                >
                  MASTERCLASS USERS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-9 ">
        <div className="row row-deck row-cards">
          <div className="col-6 col-lg-2">
            <div className="card card-sm">
              <div className="card-body bg-orange-lt">
                <div className="row align-items-center">
                  <div className="col">
                    <div
                      className="text-center fw-bold"
                      style={{
                        fontSize: "4em",
                      }}
                    >
                      {(tableData && tableData?.quotations) || 0}
                    </div>
                    <div className="fs-1 text-center fw-bold">QUOTATION</div>
                    <hr className="my-3" />
                    <div className="text-muted text-center">
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {(tableData && tableData?.quotationsToday) || 0}
                      </span>
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {" "}
                        TODAY
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-2">
            <div className="card card-sm">
              <div className="card-body bg-orange-lt">
                <div className="row align-items-center">
                  <div className="col">
                    <div
                      className="text-center fw-bold"
                      style={{
                        fontSize: "4em",
                      }}
                    >
                      {(tableData && tableData?.enquiryList) || 0}
                    </div>
                    <div className="fs-1 text-center fw-bold">ENQUIRIES</div>
                    <hr className="my-3" />{" "}
                    <div className="text-muted text-center">
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {(tableData && tableData?.enquiryListToday) || 0}
                      </span>
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {" "}
                        TODAY
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-2">
            <div className="card card-sm">
              <div className="card-body bg-orange-lt">
                <div className="row align-items-center">
                  <div className="col">
                    <div
                      className="text-center fw-bold"
                      style={{
                        fontSize: "4em",
                      }}
                    >
                      {(tableData && tableData?.leads) || 0}
                    </div>
                    <div className="fs-1 text-center fw-bold">LEADS</div>
                    <hr className="my-3" />{" "}
                    <div className="text-muted text-center">
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {(tableData && tableData?.leadsToday) || 0}
                      </span>
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {" "}
                        TODAY
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="card card-sm">
              <div className="card-body bg-orange-lt">
                <div className="row align-items-center">
                  <div className="col">
                    <div
                      className="text-center fw-bold"
                      style={{
                        fontSize: "4em",
                      }}
                    >
                      {(tableData &&
                        tableData?.siteInspections &&
                        tableData?.clientProjects &&
                        tableData.clientProjects + tableData.siteInspections) ||
                        0}
                    </div>
                    <div className="fs-1 text-center fw-bold">INSPECTION</div>
                    <hr className="my-3" />
                    <div className="text-muted text-center">
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {(tableData && tableData?.siteInspectionsToday) || 0}
                      </span>
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {" "}
                        TODAY
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="card card-sm ">
              <div className="card-body bg-orange-lt">
                <div className="row align-items-center">
                  <div className="col">
                    <div
                      className="text-center fw-bold"
                      style={{
                        fontSize: "4em",
                      }}
                    >
                      {(tableData && tableData?.clientProjects) || 0}
                    </div>
                    <div className="fs-1 text-center fw-bold">FARMS</div>
                    <hr className="my-3" />{" "}
                    <div className="text-muted text-center">
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {(tableData && tableData?.clientProjectsToday) || 0}
                      </span>
                      <span
                        className="fw-bold"
                        style={{
                          fontSize: "2em",
                        }}
                      >
                        {" "}
                        TODAY
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-2">
            <div className="card card-sm">
              <div className="card-body bg-danger-lt">
                <div className="row align-items-center">
                  <div className="col">
                    <div
                      className="text-center fw-bolder"
                      style={{
                        fontSize: "4.5em",
                      }}
                    >
                      {(tableData && tableData?.pendingFollowupToday) || 0}
                    </div>
                    <div className="fs-1 text-center fw-bolder">
                      PENDING FOLLOWUPS
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KryzenDetails;
