import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { FarmSupportInfo } from "../../../SchemaInfo/info";
import { useSelector } from "react-redux";
import { CreateQuerySchema } from "../../../Schemas/Farm support/create_query_schema";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { uploadImage } from "../../../CustomComponent/upload_Image";
import { FcSms } from "react-icons/fc";

const getId = (str) => {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  return parseInt(lastPart, 10);
};

const AddFarmQuery = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values, actions) => {
    values = {
      ...values,
      generatedBy: userData?.Name,
      generatedById: userData?.UserId,
    };

    // loading true
    setLoading(true);
    performRequest("POST", "/farmSupport/createQuery/", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Query raised successfully!");
          navigate(`/agronomy-farm-support/view/${res.data.id}`);
        } else {
          Toast("error", "Error while Creating");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcSms className="fs-2  me-1" /> Farm Support Tickets
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Raise a new query.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <h3>How your query is resolved?</h3>
                <p>
                  Once you raise a query. It is allotted to department based on
                  the relation option you choose.
                </p>
                <p>
                  You get reply on a query, which can be tracked here by viewing
                  your query.
                </p>
                <p>
                  The agent or department working to resolve your query might
                  give you a call to understand or give solution to your query.
                  In case you want to receive call on some other contact number
                  which is not listed with us. include that as well in message.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <Formik
                  initialValues={FarmSupportInfo}
                  validationSchema={CreateQuerySchema}
                  onSubmit={handleSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body">
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3 ">
                            Query related to
                          </label>
                          <div className="w-100 col">
                            <CustomSelect type="text" name="relatedTo">
                              <option value="">Select</option>
                              <option value="quotation">Quotation</option>
                              <option value="billing and payments">
                                Billing And Payments
                              </option>
                              <option value="dpr and subsidy">
                                DPR and Subsidy
                              </option>
                              <option value="construction">Construction</option>
                              <option value="maintainance">Maintainance</option>
                              <option value="agronomy">Agronomy</option>
                            </CustomSelect>
                            {props.touched.relatedTo &&
                              props.errors.relatedTo && (
                                <p className="text-danger">
                                  {props.errors.relatedTo}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Upload image/video for reference
                          </label>
                          <div className="w-100 col">
                            <CustomInput
                              name="image"
                              type="file"
                              onChange={(e) =>
                                uploadImage(props, "image", e.target.files[0])
                              }
                            />
                            {props.touched.image && props.errors.image && (
                              <p className="text-danger">
                                {props.errors.image}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Message
                          </label>
                          <div className="w-100 col">
                            <CustomTextArea
                              name="message"
                              type="text"
                              rows="5"
                              cols="200"
                              placeholder="Type your message here..."
                            />
                            <br />
                            <p>
                              Please describe your query as detailed as
                              possible.
                            </p>
                            {props.touched.message && props.errors.message && (
                              <p className="text-danger">
                                {props.errors.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Raise Query" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddFarmQuery;
