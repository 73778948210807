import React from "react";
import { BsBoxArrowInRight } from "react-icons/bs";

const FormSubmitButton = ({ buttonText, width, ...props }) => {
  buttonText = buttonText.toUpperCase();
  if (width === "full") {
    return (
      <button {...props} type="submit" className="mx-1 btn btn-primary w-100">
        <BsBoxArrowInRight className="fs-2 me-2" />
        {buttonText}
      </button>
    );
  }
  return (
    <button type="submit" {...props} className=" btn mx-1 mt-2  btn-primary">
      <BsBoxArrowInRight className="fs-2 me-2" />
      {buttonText}
    </button>
  );
};

export default FormSubmitButton;
