import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";
// import { roundOffCost } from "../../../Components/Functions/amt.fun";
import { FcTemplate } from "react-icons/fc";
import { BsJournalArrowUp } from "react-icons/bs";

const ViewWorkforce = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (data) {
      if (data?.uploadPhoto) {
        getUplodedImage(setImageUrls, data?.uploadPhoto, "uploadPhoto");
      }
      if (data?.uploadAadharFront) {
        getUplodedImage(
          setImageUrls,
          data?.uploadAadharFront,
          "uploadAadharFront"
        );
      }
      if (data?.uploadAadharBack) {
        getUplodedImage(
          setImageUrls,
          data?.uploadAadharBack,
          "uploadAadharBack"
        );
      }
    }
  }, [data]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/workForce/getWorkForceById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcTemplate className="fs-2  me-1" /> View workforce staff
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Add new workforce to portal.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specification</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>First name</td>
                          <td>{(data?.firstName || "N/A").toUpperCase()}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Last name</td>
                          <td>{(data?.lastName || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Email</td>
                          <td>{(data?.emailAddress || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Date of joining</td>
                          <td>
                            {(data?.dateOfJoining || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Date of birth</td>
                          <td>{(data?.dateOfBirth || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Contact Number</td>
                          <td>
                            {(data?.contactNumber || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Aadhar Number</td>
                          <td>{(data?.aadharNumber || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Pan Card Number</td>
                          <td>{(data?.panNumber || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Permanent Address</td>
                          <td>
                            {(data?.permanentAddress || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Emergency contact name</td>
                          <td>
                            {(
                              data?.emergencyContactName || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Emergency contact number</td>
                          <td>
                            {(
                              data?.emergencyContactNumber || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Emergency contact relation</td>
                          <td>
                            {(
                              data?.emergencyContactRelation || "N/A"
                            ).toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Photo</td>
                          <td>
                            {imageUrls?.uploadPhoto ? (
                              <img
                                width="25%"
                                src={imageUrls?.uploadPhoto}
                                alt={"upload Photo"}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Aadhar Front</td>
                          <td>
                            {imageUrls?.uploadAadharFront ? (
                              <img
                                width="25%"
                                src={imageUrls?.uploadAadharFront}
                                alt={"upload Aadhar Front"}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Aadhar Back</td>
                          <td>
                            {imageUrls?.uploadAadharBack ? (
                              <img
                                width="25%"
                                src={imageUrls?.uploadAadharBack}
                                alt={"upload Aadhar Front"}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Salary</td>
                          <td>{(data?.salary || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Salary frequency</td>
                          <td>
                            {(data?.salaryFrequency || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Salary frequency type</td>
                          <td>
                            {(data?.salaryFrequencyType || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton
                      buttonText="Edit Workforce"
                      href={`/client-employee-database/edit/${id}`}
                    />
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewWorkforce;
