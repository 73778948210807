import * as yup from "yup";

export const taskSchema = yup.object().shape({
  taskName: yup.string(),
  scheduleDate: yup.date(),
  completionDate: yup.date(),
  isLocked: yup.boolean(),
  isUpdated: yup.boolean(),
  isProjectFinal: yup.boolean(),
  responsibleStaff: yup.string(),
});
export const ProjectTrackingSchema = yup.object().shape({
  projectId: yup.string().required("Required"),
  siteSelectionId: yup.string().required("Required"),
  workOrderUpload: yup.string(),
  taskList: yup.array().of(taskSchema),
  notes: yup.string(),
});
