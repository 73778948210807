import React from "react";

const SupplierRecipientInfo = ({ data }) => {
  const countryOfSupply = data?.countryOfSupply;
  const capitalizedCountry = countryOfSupply
    ? countryOfSupply.charAt(0).toUpperCase() + countryOfSupply.slice(1)
    : "";
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>
        {data?.invoiceType?.toUpperCase()}
      </h1>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: "50%" }}>
          <p>
            Business Firm/ Person Name:{" "}
            <b>{data?.businessName || data?.contactPersonName}</b> <br />
            Contact Person: <b>{data?.contactPersonName}</b>
            <br /> Phone: <b>{data?.phoneNumber}</b>
            <br /> Address: <b>{data?.address}</b>
            <br />
            GSTIN: <b>{data?.gstNumber}</b>{" "}
          </p>
        </div>
        <div style={{ margin: "auto" }}>
          <p>
            Invoice For: <b>{data?.invoiceFor}</b> <br />
            Invoice Number: <b>{data?.invoiceNumber}</b> <br />
            Date: <b>{data?.invoiceDate}</b> <br />
            Country of Supply: <b>{capitalizedCountry}</b> <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default SupplierRecipientInfo;
