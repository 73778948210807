import React from "react";
import { useNavigate } from "react-router";

const ButtonWithSVG = ({ href, buttonText, svg }) => {
  const navigate = useNavigate();
  buttonText = buttonText.toUpperCase();
  const handelClick = () => {
    if (buttonText.toLowerCase() === "back") {
      window.history.back();
    } else {
      navigate(href);
    }
  };
  return (
    <button onClick={handelClick} className="btn btn-primary">
      {svg}
      {buttonText}
    </button>
  );
};

export default ButtonWithSVG;
