import React, { useEffect, useState } from "react";
import SimpleButton from "../../Components/layouts/simple_button";
import Footer from "../../Components/Footer/footer";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import { Form, Formik } from "formik";
import { accountSettingSchema } from "../../Schemas/user/user_account_schema";
import CustomInput from "../../CustomComponent/custom_input";
import CustomSelect from "../../CustomComponent/custom_select";
import StepRoutes from "../../Routes/steps_routes";
import {
  getUplodedImage,
  uploadImage,
} from "../../CustomComponent/upload_Image";
import { useSelector } from "react-redux";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import { userDetails } from "../../SchemaInfo/info";

const UserAccountSetting = () => {
  const [userInfo, setUserInfo] = useState(userDetails);
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (userInfo) {
      if (userInfo?.photograph) {
        getUplodedImage(setImageUrls, userInfo?.photograph, "photograph");
      }
    }
  }, [userInfo]);

  const handelSubmit = (values, actions) => {
    values.firstName = values.firstName.trim();
    values.lastName = values.lastName.trim();
    if (values.firstName && values.lastName) {
      values = {
        ...values,
        userName: `${values.firstName} ${values.lastName}`,
      };
    } else if (values.firstName) {
      values = { ...values, userName: values.firstName };
    } else if (values.lastName) {
      values = { ...values, userName: values.lastName };
    }

    setLoading(true);
    performRequest("PATCH", `/login/update/${user.UserId}`, {
      contactNumber: values.contactNumber,
      dateOfBirth: values.dateOfBirth,
      emergencyContactName: values.emergencyContactName,
      emergencyContactNumber: values.emergencyContactNumber,
      emergencyContactRelation: values.emergencyContactRelation,
      firstName: values.firstName,
      permanentAddress: values.permanentAddress,
      photograph: values.photograph,
      lastName: values.lastName,
      residentialAddress: values.residentialAddress,
      userName: values.userName,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Details Updated successfully!");
          setRefresh(!refresh);
        } else {
          Toast("error", "Error while Updating!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user.UserId) {
      setLoading(true);
      performRequest("GET", `/login/getUserById/${user.UserId}`)
        .then((res) => {
          if (res.status === 200) {
            setUserInfo({
              ...res.data,
              firstName: res.data.firstName
                ? res.data.firstName
                : res.data.userName,
            });
          } else {
            Toast("error", "Error while getting Details!");
          }
          setLoading(false);
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [user.UserId, refresh]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">User Account</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h2 className="page-title">Account Settings</h2>
            </div>

            <div className="card">
              <div className="row g-0">
                <div className="col-3 d-none d-lg-block border-end">
                  <div className="card-body">
                    <h4 className="subheader">Account settings</h4>
                    <div className="list-group list-group-transparent">
                      <a
                        href="./account-setting"
                        className="list-group-item list-group-item-action d-flex align-items-cactive"
                      >
                        My Account
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col d-flex flex-column">
                  <Formik
                    initialValues={userInfo}
                    validationSchema={accountSettingSchema}
                    onSubmit={handelSubmit}
                  >
                    {(props) => (
                      <Form>
                        <div className="card-body">
                          <h2 className="mb-4">My Account</h2>
                          <h3 className="card-title">Profile Details</h3>
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span
                                className="avatar avatar-xl"
                                style={{
                                  backgroundImage: imageUrls?.photograph
                                    ? `url(${imageUrls?.photograph})`
                                    : `url(https://i.pinimg.com/474x/0a/a8/58/0aa8581c2cb0aa948d63ce3ddad90c81.jpg)`,
                                }}
                              ></span>
                            </div>
                            <div className="col-auto">
                              <CustomInput
                                name="photograph"
                                type="file"
                                onChange={(e) =>
                                  uploadImage(
                                    props,
                                    "photograph",
                                    e.target.files[0]
                                  )
                                }
                              />
                            </div>
                          </div>

                          <h3 className="card-title mt-4">Profile</h3>
                          <div className="grid g-3 my-2">
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">First name</label>
                              <CustomInput
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                autoComplete="off"
                              />{" "}
                              {props.touched.firstName &&
                                props.errors.firstName && (
                                  <p className="text-danger">
                                    {props.errors.firstName}
                                  </p>
                                )}
                            </div>
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">Last name</label>
                              <CustomInput
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                autoComplete="off"
                              />{" "}
                              {props.touched.lastName &&
                                props.errors.lastName && (
                                  <p className="text-danger">
                                    {props.errors.lastName}
                                  </p>
                                )}
                            </div>
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Date of birth
                              </label>
                              <CustomInput
                                type="date"
                                name="dateOfBirth"
                                autoComplete="off"
                              />{" "}
                              {props.touched.dateOfBirth &&
                                props.errors.dateOfBirth && (
                                  <p className="text-danger">
                                    {props.errors.dateOfBirth}
                                  </p>
                                )}
                            </div>
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Permanent address
                              </label>
                              <CustomInput
                                type="text"
                                name="permanentAddress"
                                placeholder="your permanent address"
                                autoComplete="off"
                              />{" "}
                              {props.touched.permanentAddress &&
                                props.errors.permanentAddress && (
                                  <p className="text-danger">
                                    {props.errors.permanentAddress}
                                  </p>
                                )}
                            </div>
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Residential address
                              </label>
                              <CustomInput
                                type="text"
                                name="residentialAddress"
                                placeholder="residential address"
                                autoComplete="off"
                              />{" "}
                              {props.touched.residentialAddress &&
                                props.errors.residentialAddress && (
                                  <p className="text-danger">
                                    {props.errors.residentialAddress}
                                  </p>
                                )}{" "}
                            </div>{" "}
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Contact number
                              </label>
                              <CustomInput
                                type="number"
                                placeholder="contact number"
                                name="contactNumber"
                                autoComplete="off"
                              />{" "}
                              {props.touched.contactNumber &&
                                props.errors.contactNumber && (
                                  <p className="text-danger">
                                    {props.errors.contactNumber}
                                  </p>
                                )}
                            </div>
                            <div className="g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Emergency contact number
                              </label>
                              <CustomInput
                                type="text"
                                name="emergencyContactNumber"
                                placeholder="emergency contact number "
                                autoComplete="off"
                              />{" "}
                              {props.touched.emergencyContactNumber &&
                                props.errors.emergencyContactNumber && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactNumber}
                                  </p>
                                )}
                            </div>
                            <div className="g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Emergency contact name
                              </label>
                              <CustomInput
                                type="text"
                                name="emergencyContactName"
                                placeholder="Emergency Contact Name"
                                autoComplete="off"
                              />{" "}
                              {props.touched.emergencyContactName &&
                                props.errors.emergencyContactName && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactName}
                                  </p>
                                )}
                            </div>
                            <div className="g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Emergency contact relation
                              </label>
                              <CustomSelect
                                type="text"
                                name="emergencyContactRelation"
                                autoComplete="off"
                              >
                                <option value="">Select Relation</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="spouse">Spouse</option>
                                <option value="sister">Sister</option>
                                <option value="brother">Brother</option>
                                <option value="friend">Friend</option>
                              </CustomSelect>
                              {props.touched.emergencyContactRelation &&
                                props.errors.emergencyContactRelation && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactRelation}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="my-2">
                            <h3 className="card-title mt-4">Password</h3>
                            <p className="card-subtitle">
                              You can change your password here...
                            </p>
                            <a href="/reset-password" className="btn">
                              Set new password
                            </a>
                          </div>
                        </div>
                        <div className="card-footer bg-transparent mt-auto">
                          <div className="btn-list justify-content-end">
                            <SimpleButton
                              buttonText={"Cancel"}
                              type="secondary"
                            />

                            <FormSubmitButton buttonText={"Submit"} />
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default UserAccountSetting;
