import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header/header";

const Head = () => {
  const location = useLocation();
  const endpoint = location.pathname;

  if (
    endpoint === "/account-login" ||
    endpoint === "/employee-attendence" ||
    endpoint === "/dashboard" ||
    endpoint === "/forgot-password" ||
    endpoint === "/reset-password" ||
    endpoint === "/account-login/" ||
    endpoint === "/forgot-password/" ||
    endpoint === "/reset-password/"
  ) {
    return;
  } else {
    return <Header />;
  }
};

export default Head;
