import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { capitalizeFirstLetterOfEachWord } from "../../../Components/Functions/word.fun";
import PriceWatcheLineChart from "../Price watcher components/price_watcher_linechart";
import { format } from "date-fns";

import { FcSalesPerformance } from "react-icons/fc";

const ViewPriceWatcher = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();
  const [chartDataFuture, setChartDataFu] = useState();
  let { id } = useParams();
  const [dataFrom, setDataFrom] = useState(3);

  const navigate = useNavigate();

  function calculateDiscountedPrice(originalPrice, discount) {
    const discountedPrice = originalPrice * (1 - discount);
    return discountedPrice;
  }
  const formatDate = (dateStr) => format(new Date(dateStr), "dd MMMM yyyy");

  const hadndleResearch = () => {
    navigate("/buyers-price-watcher/research");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const res = await performRequest(
          "GET",
          `/priceWatcher/getPriceWatcherById/${id}`
        );

        if (res.status === 200) {
          setData(res.data);

          const chartRes = await performRequest(
            "GET",
            `/priceWatcher/getLineChartdata?plantId=${res.data.plantId}`
          );

          if (chartRes.status === 200) {
            setChartData(chartRes.data);
            setLoading(false);
            const chartfures = await performRequest(
              "GET",
              `/priceWatcher/getFutureLineChartdata?plantId=${res.data.plantId}`
            );

            if (chartfures.status === 200) {
              setChartDataFu(chartfures.data);
            } else {
              Toast("error", "Error while getting future chart data!");
            }
          } else {
            Toast("error", "Error while getting chart data!");
          }
        } else {
          Toast("error", "Error while getting data!");
        }
      } catch (err) {
        Toast("error", "Server Error!");
        console.error(err);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }
  // console.log(chartDataFuture);
  // console.log(chartData);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcSalesPerformance className="fs-2  me-1" />
                  Price Watcher
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Track different comodities and there
                  pricing.
                </h4>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specs.</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>Plant Name</td>
                          <td>{data?.plantName || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Week</td>
                          <td>
                            {capitalizeFirstLetterOfEachWord(data?.week) ||
                              "N/A"}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Year</td>
                          <td>{data?.year || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Week start date</td>
                          <td>{formatDate(data?.weekStartDate) || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Week end date</td>
                          <td>{formatDate(data?.weekEndDate) || "N/A"}</td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Wholesale price low</td>
                          <td>
                            ₹
                            {calculateDiscountedPrice(
                              data?.weeklyPrice,
                              0.35
                            )?.toFixed(2) || "N/A"}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Wholesale price high</td>
                          <td>
                            ₹
                            {calculateDiscountedPrice(
                              data?.weeklyPrice,
                              0.15
                            )?.toFixed(2) || "N/A"}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Retail price low</td>
                          <td>
                            ₹
                            {calculateDiscountedPrice(
                              data?.weeklyPrice,
                              0.05
                            )?.toFixed(2) || "N/A"}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td>Retail price high</td>
                          <td>₹{data?.weeklyPrice || "N/A"}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 py-2">
              <div className="card">
                <div className="card-body">
                  <div className="dropdown p-1">
                    <a
                      className="dropdown-toggle text-muted"
                      href="/"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {dataFrom === 6
                        ? `Last ${dataFrom} months`
                        : `Last ${dataFrom} year${dataFrom > 1 ? "s" : ""}`}
                    </a>

                    <div className="dropdown-menu dropdown-menu-end">
                      <span
                        onClick={() => setDataFrom(6)}
                        className="dropdown-item"
                      >
                        Last 6 months
                      </span>
                      <span
                        onClick={() => setDataFrom(1)}
                        className="dropdown-item"
                      >
                        Last 1 year
                      </span>
                      <span
                        onClick={() => setDataFrom(2)}
                        className="dropdown-item"
                      >
                        Last 2 year's
                      </span>
                      <span
                        onClick={() => setDataFrom(3)}
                        className="dropdown-item"
                      >
                        Last 3 year's
                      </span>
                    </div>
                  </div>
                  <PriceWatcheLineChart
                    plantPriceData={chartData}
                    dataFrom={dataFrom}
                    chartDataFuture={chartDataFuture}
                  />
                </div>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton
                      buttonText="Research RNN"
                      onClick={hadndleResearch}
                    />
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewPriceWatcher;
