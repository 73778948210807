import React from "react";
import { extractDate } from "../../../Components/Functions/date.fun";
const VendorDetails = ({ data }) => {
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>PURCHASE ORDER / WORK ORDER</h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <p>
            Vendor Purchase Order Number: <b>{data?.poNumber || "N/A"}</b>{" "}
            <br />
            {data?.createdAt && (
              <>
                {" "}
                Date: <b>{extractDate(data?.createdAt)}</b>
              </>
            )}
            <br />
            Business / Person Name:{" "}
            <b>{data?.businessName || data?.contactPersonName || "N/A"}</b>{" "}
            <br />
            Address: {data?.address || "N/A"} GSTIN:{" "}
            <b>{data?.gstNumber || "N/A"}</b>
          </p>
        </div>
        <div>
          <p>
            Contact Person: <b>{data?.contactPersonName || "N/A"}</b> <br />
            Phone: <b>{data?.phoneNumber || "N/A"}</b> <br />
            Country of Supply: <b>{data?.countryOfSupply || "N/A"}</b> <br />
            Purchase For: <b>{data?.vendorPurchaseOrderFor || "N/A"}</b> <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
