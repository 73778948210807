import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";

import { useSelector } from "react-redux";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";

const ViewHandover = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((store) => store.login.user);
  const [data, setData] = useState();
  let { id } = useParams();
  const navigate = useNavigate();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/handOver/getHandOverById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting handover!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  useEffect(() => {
    if (data) {
      if (data?.northSouthMeasurmentPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.northSouthMeasurmentPhoto,
          "northSouthMeasurmentPhoto"
        );
      }
      if (data?.eastWestMeasurmentPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.eastWestMeasurmentPhoto,
          "eastWestMeasurmentPhoto"
        );
      }

      if (data?.descriptionVideo) {
        getUplodedImage(
          setImageUrls,
          data?.descriptionVideo,
          "descriptionVideo"
        );
      }
      if (data?.aFramePhoto) {
        getUplodedImage(setImageUrls, data?.aFramePhoto, "aFramePhoto");
      }
      if (data?.netCupPhoto) {
        getUplodedImage(setImageUrls, data?.netCupPhoto, "netCupPhoto");
      }
      if (data?.aFrameFoundationPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.aFrameFoundationPhoto,
          "aFrameFoundationPhoto"
        );
      }
      if (data?.ventOpenerPhoto) {
        getUplodedImage(setImageUrls, data?.ventOpenerPhoto, "ventOpenerPhoto");
      }
      if (data?.aluminiumShadeNetPullyPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.aluminiumShadeNetPullyPhoto,
          "aluminiumShadeNetPullyPhoto"
        );
      }
      if (data?.exhaustFanPhoto) {
        getUplodedImage(setImageUrls, data?.exhaustFanPhoto, "exhaustFanPhoto");
      }
      if (data?.cellusePadPhoto) {
        getUplodedImage(setImageUrls, data?.cellusePadPhoto, "cellusePadPhoto");
      }
      if (data?.waterPumpPhoto) {
        getUplodedImage(setImageUrls, data?.waterPumpPhoto, "waterPumpPhoto");
      }
      if (data?.waterPumpDrainagePhoto) {
        getUplodedImage(
          setImageUrls,
          data?.waterPumpDrainagePhoto,
          "waterPumpDrainagePhoto"
        );
      }
      if (data?.oxigenationPumpPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.oxigenationPumpPhoto,
          "oxigenationPumpPhoto"
        );
      }
      if (data?.uvLightPhoto) {
        getUplodedImage(setImageUrls, data?.uvLightPhoto, "uvLightPhoto");
      }
      if (data?.diskFilterPhoto) {
        getUplodedImage(setImageUrls, data?.diskFilterPhoto, "diskFilterPhoto");
      }
      if (data?.sandFilterPhoto) {
        getUplodedImage(setImageUrls, data?.sandFilterPhoto, "sandFilterPhoto");
      }
      if (data?.foggerAssemblyPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.foggerAssemblyPhoto,
          "foggerAssemblyPhoto"
        );
      }
      if (data?.customerFeedbackVideo) {
        getUplodedImage(
          setImageUrls,
          data?.customerFeedbackVideo,
          "customerFeedbackVideo"
        );
      }
      if (data?.customerPhotoWithSetUp) {
        getUplodedImage(
          setImageUrls,
          data?.customerPhotoWithSetUp,
          "customerPhotoWithSetUp"
        );
      }

      if (data?.handOverCertificate) {
        getUplodedImage(
          setImageUrls,
          data?.handOverCertificate,
          "handOverCertificate"
        );
      }
    }
  }, [data]);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Project Handover</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">View Handover</h2>
            </div>

            <div className="card-body">
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Project</label>
                  <div className="w-100 col">
                    {(data?.clientName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}

              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Handover date</label>
                  <div className="w-100 col">
                    {(data?.handOverDate || "N/A").toUpperCase()}
                  </div>
                </div>
              )}

              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Site Inspection
                  </label>
                  <div className="w-100 col">
                    {(data?.siteManagerName || "N/A").toUpperCase()}
                  </div>
                </div>
              )}

              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    North south measurment in meter
                  </label>
                  <div className="w-100 col">
                    {(data?.northSouthMeasurmentInMeter || "N/A").toUpperCase()}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    North south measurment photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.northSouthMeasurmentPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.northSouthMeasurmentPhoto}
                        alt={"north South Measurment Photo"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    East west measurment in meter
                  </label>
                  <div className="w-100 col">
                    {(data?.eastWestMeasurmentInMeter || "N/A").toUpperCase()}
                  </div>
                </div>
              )}

              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    East west measurment photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.eastWestMeasurmentPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.eastWestMeasurmentPhoto}
                        alt={"east West Measurment Photo"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}

              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Description video
                  </label>
                  <div className="w-100 col">
                    {imageUrls.descriptionVideo ? (
                      <video width="70%" controls>
                        <source
                          src={imageUrls.descriptionVideo}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Notes</label>
                  <div className="w-100 col">
                    {(data?.notes || "N/A").toUpperCase()}
                  </div>
                </div>
              )}

              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Tank photo</label>
                  <div className="w-100 col">
                    {imageUrls.overheadTankPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.overheadTankPhoto}
                        alt={"over head Tank Photo"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">A-Frame photo</label>
                  <div className="w-100 col">
                    {imageUrls.aFramePhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.aFramePhoto}
                        alt={"aFramePhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">Net-Cup photo</label>
                  <div className="w-100 col">
                    {imageUrls.netCupPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.netCupPhoto}
                        alt={"netCupPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    A-Frame foundation photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.aFrameFoundationPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.aFrameFoundationPhoto}
                        alt={"aFrameFoundationPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Vent opener photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.ventOpenerPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.ventOpenerPhoto}
                        alt={"ventOpenerPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Aluminium shadenet pully photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.aluminiumShadeNetPullyPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.aluminiumShadeNetPullyPhoto}
                        alt={"aluminiumShadeNetPullyPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Exhaust fan photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.exhaustFanPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.exhaustFanPhoto}
                        alt={"exhaustFanPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Celluse pad photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.cellusePadPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.cellusePadPhoto}
                        alt={"cellusePadPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water pump photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.waterPumpPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.waterPumpPhoto}
                        alt={"waterPumpPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Water pump drainage photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.waterPumpDrainagePhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.waterPumpDrainagePhoto}
                        alt={"waterPumpDrainagePhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Oxigenation pump photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.oxigenationPumpPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.oxigenationPumpPhoto}
                        alt={"oxigenationPumpPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    UV light photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.uvLightPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.uvLightPhoto}
                        alt={"uvLightPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Disk filter photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.diskFilterPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.diskFilterPhoto}
                        alt={"diskFilterPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Sand filter photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.sandFilterPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.sandFilterPhoto}
                        alt={"sandFilterPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Fogger assembly photo
                  </label>
                  <div className="w-100 col">
                    {imageUrls.foggerAssemblyPhoto ? (
                      <img
                        width="70%"
                        src={imageUrls.foggerAssemblyPhoto}
                        alt={"foggerAssemblyPhoto"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Customer photo with setUp
                  </label>
                  <div className="w-100 col">
                    {imageUrls.customerPhotoWithSetUp ? (
                      <img
                        width="70%"
                        src={imageUrls.customerPhotoWithSetUp}
                        alt={"customerPhotoWithSetUp"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Handover certificate
                  </label>
                  <div className="w-100 col">
                    {imageUrls.handOverCertificate ? (
                      <img
                        width="70%"
                        src={imageUrls.handOverCertificate}
                        alt={"handOverCertificate"}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div className="mb-3 row">
                  <label className="w-25 form-label col-3">
                    Customer feedback video
                  </label>
                  <div className="w-100 col">
                    {imageUrls.customerFeedbackVideo ? (
                      <video width="70%" controls>
                        <source
                          src={imageUrls.customerFeedbackVideo}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <SimpleButton
                  buttonText="Edit Handover"
                  href={`/client-project-handover/edit/${id}`}
                />
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewHandover;
