import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import Pagination from "../../Components/layouts/pagination";
import LoadingPage from "../../Components/layouts/loading";
import AllEmployeeTable from "./Employee Managemennt/all_employee_table";
import { BsFillPersonPlusFill } from "react-icons/bs";
import Dummy_Offer_Letter from "../../certificates/Dummy_Offer_Letter.docx";
import Relieving_Letter from "../../certificates/Relieving_Letter.docx";
import Asset_Handover_Form from "../../certificates/Asset_Handover_Form.docx";
import Asset_D_Form from "../../certificates/Asset_D_Form.docx";

import { BsArrowRightShort } from "react-icons/bs";

import { FcBusinessman } from "react-icons/fc";
const EmployeeManagement = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [leadOwner, setLeadOwner] = useState([]);

  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [empData, setEmployeeData] = useState();

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      `/employeeManagement/getEmployeeManagement?page=${page}`
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.employeeCount / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  useEffect(() => {
    performRequest("GET", "/employeeManagement/getEmployeeData")
      .then((res) => {
        if (res.status === 200) {
          setEmployeeData(res.data);
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <h2 className="page-title mb-1">
                    <FcBusinessman className="fs-2  me-1" /> Employee management
                  </h2>
                  <h4 className="text-muted">
                    {/* <StepRoutes /> */} This will help you manage all
                    employees working at organization.
                  </h4>
                </div>
                <div className="col-md-4">
                  <div className="btn-list justify-content-end">
                    <a href={"/employee-management/new-employee"}>
                      <button className="btn btn-primary">
                        <BsFillPersonPlusFill className="me-2 fs-2" /> Add New
                        Employee
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-body">
            <div className="container-xl">
              <div className="row">
                <div className=" col-sm-12 col-md-3">
                  <div className="card p-2 mb-3">
                    <h3 className="m-0 p-0">Organization Details:</h3>
                    <hr className="m-1" />
                    <div className="p-1">
                      <p className="p-0 m-0 fw-bold">Kryzen Biotech Pvt Ltd</p>
                      <p className="p-0 mb-2 fw-bold">
                        #98, At. Post Degaon, Wai, Satara, Maharashtra, India
                        412803
                      </p>
                      <p className="p-0 m-0">GST: 27AAHCK7659R1ZF</p>
                      <p className="p-0 m-0">PAN:AAHCK7659R</p>
                      <p className="p-0 m-0">CIN: U01100PN2019PTC186207</p>
                      <hr className="p-0 my-2" />
                      <p className="p-0 m-0">
                        Total Staff: {empData?.totalStaff}
                      </p>
                      <p className="p-0 m-0">
                        Active Position: {empData?.activePositionCount}
                      </p>
                      <p className="p-0 m-0">
                        On Probation: {empData?.onProbationCount}
                      </p>
                    </div>
                  </div>
                  <div className="card p-2 mb-1">
                    <h3 className="m-0 p-0">Sample Documents:</h3>
                    <hr className="m-1" />
                    <a href={Dummy_Offer_Letter} download>
                      <p className="p-0 my-1">
                        <BsArrowRightShort className="me-1" /> Download Offer
                        Letter
                      </p>
                    </a>
                    <a href={Relieving_Letter} download>
                      <p className="p-0 my-1">
                        <BsArrowRightShort className="me-1" /> Download
                        Relieving Letter
                      </p>
                    </a>
                    <a href={Asset_D_Form} download>
                      <p className="p-0 my-1">
                        <BsArrowRightShort className="me-1" /> Download Asset
                        Declaration
                      </p>
                    </a>
                    <a href={Asset_Handover_Form} download>
                      <p className="p-0 my-1">
                        <BsArrowRightShort className="me-1" /> Download Asset
                        Handover
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-md-9  col-sm-12">
                  <div className="card">
                    {data && (
                      <AllEmployeeTable
                        employees={data?.employeeManagement}
                        currentPage={page}
                        itemsPerPage={10}
                      />
                    )}
                    <Pagination
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      pageDatalength={data?.employeeManagement?.length}
                      showPage={showPage}
                      setShowPage={setShowPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EmployeeManagement;
