import React, { useEffect, useState } from "react";
import {
  CamelCaseToFirstUpper,
  capitalizeFirstLetterOfEachWord,
} from "../../../Components/Functions/word.fun";
import ClimateGraphs from "./climate_graphs";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";

const SIDetails = ({ data, allQData }) => {
  let serial = 0;
  const degreeToFahrenheit = (value) => {
    value = ((value - 32) * 5) / 9;
    value = Math.round(value);
    return `${value} °C`;
  };
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    if (data) {
      if (data?.water?.waterTankLocationPhoto) {
        getUplodedImage(
          setImageUrls,
          data.water.waterTankLocationPhoto,
          "waterTankLocationPhoto"
        );
      }
      if (data?.water?.waterReportPhoto) {
        getUplodedImage(
          setImageUrls,
          data.water.waterReportPhoto,
          "waterReportPhoto"
        );
      }
      if (data?.sunlight?.entryTofarmPhoto) {
        getUplodedImage(
          setImageUrls,
          data.sunlight.entryTofarmPhoto,
          "entryTofarmPhoto"
        );
      }
      if (data?.land?.obstaclesTowardsSkyPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.land?.obstaclesTowardsSkyPhoto,
          "obstaclesTowardsSkyPhoto"
        );
      }
      if (data?.sunlight?.luxReadingPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.sunlight?.luxReadingPhoto,
          "luxReadingPhoto"
        );
      }
      if (data?.sunlight?.luxDeffusionSheetPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.sunlight?.luxDeffusionSheetPhoto,
          "luxDeffusionSheetPhoto"
        );
      }
      if (data?.sunlight?.luxWovenPolyPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.sunlight?.luxWovenPolyPhoto,
          "luxWovenPolyPhoto"
        );
      }
      if (data?.sunlight?.luxUvOpenPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.sunlight?.luxUvOpenPhoto,
          "luxUvOpenPhoto"
        );
      }
      if (data?.climate?.temperatureReadingPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.climate?.temperatureReadingPhoto,
          "temperatureReadingPhoto"
        );
      }
      if (data?.climate?.humidityReadingPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.climate?.humidityReadingPhoto,
          "humidityReadingPhoto"
        );
      }
      if (data?.dimensions?.northSouthMeasurmentPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.dimensions?.northSouthMeasurmentPhoto,
          "northSouthMeasurmentPhoto"
        );
      }
      if (data?.dimensions?.eastWestMeasurmentPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.dimensions?.eastWestMeasurmentPhoto,
          "eastWestMeasurmentPhoto"
        );
      }
      if (data?.dimensions?.layoutPhoto) {
        getUplodedImage(
          setImageUrls,
          data?.dimensions?.layoutPhoto,
          "layoutPhoto"
        );
      }
      if (data?.additionDetails?.descriptionVideo) {
        getUplodedImage(
          setImageUrls,
          data?.additionDetails?.descriptionVideo,
          "descriptionVideo"
        );
      }
    }
  }, [data]);

  return (
    <div>
      <h3>Personal / Promoter Details:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{++serial}</td> <td> Business Name</td>
            <td>{data?.clientDetails?.businessName || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td> <td> Client Name</td>
            <td>{data?.clientDetails?.clientName || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td> <td> Email</td>
            <td>{data?.clientDetails?.clientEmail || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td> <td> Phone</td>
            <td>{data?.clientDetails?.clientContactNumber || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td> <td> GST Number</td>
            <td>{data?.clientDetails?.businessGstNumber || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td> <td> PAN</td>
            <td>{data?.clientDetails?.panNumber || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td> <td> Billing Address</td>
            <td>{data?.clientDetails?.billingAddress || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td> <td> Farm Address</td>
            <td>{data?.newSiteInspection?.siteAddress || "N/A"}</td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
      <h3>Inspection & Project Details:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Inspection Date</td>
            <td>{data?.newSiteInspection?.inspectionDate || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Site Address</td>
            <td> {data?.newSiteInspection?.siteAddress || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Pin Code</td>
            <td> {data?.newSiteInspection?.sitePinCode || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Total Available Area</td>
            <td>
              {data?.newSiteInspection?.totalAvailableAreaNumber || "N/A"}
              {data?.newSiteInspection?.totalAvailableAreaUnit || "N/A"}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Area</td>
            <td> {allQData?.area_in?.area_in_sqft || "N/A"} sqft</td>
          </tr>
        </tbody>
      </table>

      <h3>Project Site - Staff Details:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Site Manager</td>
            <td> {data?.newSiteInspection?.siteManagerName || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Manager Phone Number</td>
            <td> {data?.newSiteInspection?.siteManagerPhone || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Staff on Site</td>
            <td> {data?.newSiteInspection?.numberOfStaff || "N/A"}</td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
      <h3>Land Details:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Result</th>
            <th>Ideal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> {(serial = 1)}</td>
            <td> Type of Site</td>
            <td>
              {(data?.newSiteInspection?.typeOfSite &&
                CamelCaseToFirstUpper(data?.newSiteInspection?.typeOfSite)) ||
                "N/A"}
            </td>
            <td> Any</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Soil Type</td>
            <td>
              {(data?.newSiteInspection?.soilType &&
                CamelCaseToFirstUpper(data?.newSiteInspection?.soilType)) ||
                "N/A"}
            </td>
            <td>Any</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Land Ownership</td>
            <td>
              {(data?.newSiteInspection?.landOwnership &&
                CamelCaseToFirstUpper(
                  data?.newSiteInspection?.landOwnership
                )) ||
                "N/A"}
            </td>
            <td>Any</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td>Land Fencing Height In Feet</td>
            <td>
              {(data?.land?.landFencingHeightInFeet &&
                CamelCaseToFirstUpper(data?.land?.landFencingHeightInFeet)) ||
                "N/A"}
            </td>
            <td>0</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td>Land Fencing Type</td>
            <td>
              {(data?.land?.landFencingType &&
                CamelCaseToFirstUpper(data?.land?.landFencingType)) ||
                "N/A"}
            </td>
            <td>Any Fencing</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Land Levelling</td>
            <td>
              {(data?.newSiteInspection?.landLeveling &&
                CamelCaseToFirstUpper(data?.newSiteInspection?.landLeveling)) ||
                "N/A"}
            </td>
            <td>Required</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Natural Slope of Land</td>
            <td>
              {(data?.newSiteInspection?.naturalSlopeOfLand &&
                CamelCaseToFirstUpper(
                  data?.newSiteInspection?.naturalSlopeOfLand
                )) ||
                "N/A"}
            </td>

            <td>Required</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td>Farm Entry</td>

            <td>
              {(data?.sunlight?.entryToFarmSite &&
                CamelCaseToFirstUpper(data?.sunlight?.entryToFarmSite)) ||
                "N/A"}
            </td>
            <td>Required</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Lux Reading</td>
            <td>{data?.sunlight?.luxReading || "N/A"} </td>
            <td>Above 2000</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Lux Deffusion Sheet</td>
            <td>{data?.sunlight?.luxDeffusionSheet || "N/A"} </td>
            <td>Above 2000</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td>Lux Uv Open</td>
            <td>{data?.sunlight?.luxUvOpen || "N/A"} </td>
            <td>Above 2000</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Lux Woven Poly</td>
            <td>{data?.sunlight?.luxWovenPoly || "N/A"} </td>
            <td>Above 2000</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> North South Measurement</td>
            <td> {data?.dimensions?.northSouthMeasurmentInMeter || "N/A"} </td>
            <td>Any</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> East West Measurement</td>
            <td>{data?.dimensions?.eastWestMeasurmentInMeter || "N/A"}</td>
            <td> Any</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td>Land Has Hil Lock</td>
            <td> {data?.land?.landHasHillock ? "Yes" : "No"}</td>
            <td>No</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td>Land Has Mud Patch</td>
            <td> {data?.land?.landHasMudPatch ? "Yes" : "No"}</td>
            <td>No</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td>Land Has Pond</td>
            <td> {data?.land?.landHasPond ? "Yes" : "No"}</td>
            <td>No</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td>Land Has Stones</td>
            <td> {data?.land?.landHasStones ? "Yes" : "No"}</td>
            <td>No</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Land Has Tree</td>
            <td> {data?.land?.landHasTree ? "Yes" : "No"}</td>
            <td>No</td>
          </tr>
          <tr>
            <td> {++serial}</td>
            <td> Obstacles Towards Sky</td>
            <td> {data?.land?.obstaclesTowardsSky ? "Yes" : "No"}</td>
            <td>No</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table style={{ width: "100%" }} className="images-table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Entry to Farm</td>
            <td style={{ width: "50%" }}>Layout</td>
          </tr>
          <tr>
            <td>
              {imageUrls.entryTofarmPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.entryTofarmPhoto}
                  alt={"entry to farm photo"}
                />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {imageUrls?.layoutPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.layoutPhoto}
                  alt={"layoutPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: "100%" }} className="images-table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>North South Measurement</td>
            <td style={{ width: "50%" }}>East West Measurement</td>
          </tr>
          <tr>
            <td>
              {imageUrls?.northSouthMeasurmentPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.northSouthMeasurmentPhoto}
                  alt={"northSouthMeasurmentPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {imageUrls?.eastWestMeasurmentPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.eastWestMeasurmentPhoto}
                  alt={"eastWestMeasurmentPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: "100%" }} className="images-table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Lux Reading</td>
            <td style={{ width: "50%" }}>Obstacles Towards Sky</td>
          </tr>
          <tr>
            <td>
              {imageUrls?.luxReadingPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.luxReadingPhoto}
                  alt={"luxReadingPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {imageUrls?.obstaclesTowardsSkyPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.obstaclesTowardsSkyPhoto}
                  alt={"obstaclesTowardsSkyPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: "100%" }} className="images-table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Lux Uv Open</td>
            <td style={{ width: "50%" }}>Lux Woven Poly</td>
          </tr>
          <tr>
            <td>
              {imageUrls?.luxUvOpenPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.luxUvOpenPhoto}
                  alt={"luxUvOpenPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {imageUrls?.luxWovenPolyPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.luxWovenPolyPhoto}
                  alt={"luxWovenPolyPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: "100%" }} className="images-table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Entry to Farm</td>
            <td style={{ width: "50%" }}>Layout</td>
          </tr>
          <tr>
            <td>
              {imageUrls?.entryTofarmPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.entryTofarmPhoto}
                  alt={"entryTofarmPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {imageUrls?.layoutPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.layoutPhoto}
                  alt={"layoutPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: "100%" }} className="images-table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Lux Deffusion Sheet Photo</td>
            <td style={{ width: "50%" }}></td>
          </tr>
          <tr>
            <td>
              {imageUrls?.luxDeffusionSheetPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={imageUrls.luxDeffusionSheetPhoto}
                  alt={"luxDeffusionSheetPhoto"}
                />
              ) : (
                "N/A"
              )}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
      <h3>Water Details:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Result</th>
            <th>Ideal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Water Source</td>
            <td>
              {(data?.water?.waterSourceType &&
                CamelCaseToFirstUpper(data?.water?.waterSourceType)) ||
                "N/A"}
            </td>
            <td>Any</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Tank / Motor Capacity</td>
            <td>
              {data?.water?.waterTankCapacityInLitre
                ? `${data?.water?.waterTankCapacityInLitre}`
                : "N/A"}
            </td>
            <td> Above 12000 Ltr / 1 Hp</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Availability Frequency</td>
            <td>
              {(data?.water?.waterAvailabilityFrequency &&
                CamelCaseToFirstUpper(
                  data?.water?.waterAvailabilityFrequency
                )) ||
                "N/A"}
            </td>
            <td>On Demand</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Water Tank Position </td>
            <td>
              {(data?.water?.positionOfWaterTank &&
                CamelCaseToFirstUpper(data?.water?.positionOfWaterTank)) ||
                "N/A"}
            </td>
            <td>Any</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>pH Reading</td>
            <td>{data?.water?.waterPhReading || "N/A"}</td>
            <td>4.5 - 8.0</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>EC Reading</td>
            <td>{data?.water?.waterEcReading || "N/A"}</td>
            <td>400 - 1600</td>
          </tr>
        </tbody>
      </table>
      <br />
      <table style={{ width: "100%" }} className="images-table">
        <tbody>
          <tr>
            <td style={{ width: "50%" }}>Water Tank Location</td>
            <td style={{ width: "50%" }}>Water Report</td>
          </tr>
          <tr>
            <td>
              {data?.water?.waterTankLocationPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={data?.water?.waterTankLocationPhoto}
                  alt="waterTankLocationPhoto"
                />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {data?.water?.waterReportPhoto ? (
                <img
                  style={{ margin: "5px", width: "95%" }}
                  src={data?.water?.waterReportPhoto}
                  alt="waterReportPhoto"
                />
              ) : (
                "N/A"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
      <h3>Climate Details:</h3>

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Result</th>
            <th>Date Time Stamp</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Climate Coldest Month</td>
            <td>
              {data?.climate?.climateColdestMonth
                ? capitalizeFirstLetterOfEachWord(
                    data?.climate?.climateColdestMonth
                  )
                : "N/A"}
            </td>
            <td> ----- </td>
          </tr>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Climate Hottest Month</td>
            <td>
              {data?.climate?.climateHottestMonth
                ? capitalizeFirstLetterOfEachWord(
                    data?.climate?.climateHottestMonth
                  )
                : "N/A"}
            </td>
            <td> ----- </td>
          </tr>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Temperature</td>
            <td>
              {data?.climate?.temperatureReading
                ? degreeToFahrenheit(data?.climate?.temperatureReading)
                : "N/A"}
            </td>
            <td>{data?.createdAt || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Humidity</td>
            <td>{data?.climate?.humidityReading || "N/A"}</td>
            <td>{data?.createdAt || "N/A"}</td>
          </tr>
        </tbody>
      </table>
      {data && <ClimateGraphs SIData={data} />}
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
      <h3>Facilities on Site</h3>

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Item Storage</td>
            <td> {data?.facilities?.facilityToStoreItems ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Labour Stay</td>
            <td>{data?.facilities?.facilityToStay ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Bathroom</td>
            <td> {data?.facilities?.facilityHasBathroom ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Drinking Water</td>
            <td>{data?.facilities?.facilityHasDrinkingWater ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Kitchen Space</td>
            <td>{data?.facilities?.facilityHasDrinkingWater ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Fuel for Cooking</td>
            <td>
              {data?.facilities?.facilityHasFuelForCooking ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Internet Connectivity</td>
            <td>
              {(data?.facilities?.internetConnectivity &&
                CamelCaseToFirstUpper(
                  data?.facilities?.internetConnectivity
                )) ||
                "N/A"}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Transport Option</td>
            <td>
              {(data?.facilities?.transportOption &&
                CamelCaseToFirstUpper(data?.facilities?.transportOption)) ||
                "N/A"}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Nearest Tar Road</td>
            <td>{data?.facilities?.nearestTarRoad || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Nearest Bus Stand</td>
            <td>{data?.facilities?.nearestBusStand || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Nearest Railway Station</td>
            <td>{data?.facilities?.nearestRailwayStation || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Nearest Market</td>
            <td>{data?.facilities?.nearestMarket || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Nearest Medical Facility</td>
            <td>{data?.facilities?.nearestMedicalFacility || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Nearest Airport</td>
            <td>{data?.facilities?.nearestAirport || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Nearest Stay Facility</td>
            <td>{data?.facilities?.nearestStayFacility || "N/A"}</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Reguler Mode Of Transportation</td>
            <td>
              {(data?.facilities?.regulerModeOfTransportation &&
                CamelCaseToFirstUpper(
                  data?.facilities?.regulerModeOfTransportation
                )) ||
                "N/A"}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Vehicle Availability for Emergency</td>
            <td>
              {(data?.facilities?.vehicleAvilableForEmergency &&
                CamelCaseToFirstUpper(
                  data?.facilities?.vehicleAvilableForEmergency
                )) ||
                "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Boundaries</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td> Adjoining North</td>
            <td>
              {(data?.land?.whatsOnNorth &&
                CamelCaseToFirstUpper(data?.land?.whatsOnNorth)) ||
                "N/A"}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td> Adjoining East</td>
            <td>
              {(data?.land?.whatsOnEast &&
                CamelCaseToFirstUpper(data?.land?.whatsOnEast)) ||
                "N/A"}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td> Adjoining South</td>
            <td>
              {(data?.land?.whatsOnSouth &&
                CamelCaseToFirstUpper(data?.land?.whatsOnSouth)) ||
                "N/A"}
            </td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td> Adjoining West</td>
            <td>
              {(data?.land?.whatsOnWest &&
                CamelCaseToFirstUpper(data?.land?.whatsOnWest)) ||
                "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Electricity</h3>

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Name</th>
            <th>Result</th>
            <th>Ideal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{(serial = 1)}</td>
            <td>Connection Available</td>
            <td>
              {data?.facilities?.electricityConnectionAvailable ? "Yes" : "No"}
            </td>
            <td> Yes</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Meter Type</td>
            <td>
              {(data?.facilities?.electricityMeterType &&
                CamelCaseToFirstUpper(
                  data?.facilities?.electricityMeterType
                )) ||
                "N/A"}
            </td>
            <td>Agriculture</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Meter Phase Type</td>
            <td>
              {(data?.facilities?.electricityMeterPhaseType &&
                CamelCaseToFirstUpper(
                  data?.facilities?.electricityMeterPhaseType
                )) ||
                "N/A"}
            </td>
            <td> Both</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Outage Frequency</td>
            <td>
              {(data?.facilities?.electricityOutageFrequency &&
                CamelCaseToFirstUpper(
                  data?.facilities?.electricityOutageFrequency
                )) ||
                "N/A"}
            </td>
            <td> Never</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Usage</td>
            <td>
              {(data?.facilities?.electricityUsage &&
                CamelCaseToFirstUpper(data?.facilities?.electricityUsage)) ||
                "N/A"}
            </td>
            <td> Hydroponics Only</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Pricing Model</td>
            <td>
              {(data?.facilities?.electricityPricingModel &&
                CamelCaseToFirstUpper(
                  data?.facilities?.electricityPricingModel
                )) ||
                "N/A"}
            </td>
            <td>Uniform</td>
          </tr>
          <tr>
            <td>{++serial}</td>
            <td>Unit Price</td>

            <td>{data?.facilities?.electricityUnitPrice || "N/A"}</td>
            <td> Below ₹8</td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
      {/* <%= render partial: "pdf_cl_quotations/possible_dimensions" %>
    <% if si.cl_project.cl_quotation&.calculator&.has_any_project_size_polyplan_image? %>
    <%= render partial: "pdf_project_report/polyplan_design", locals: { si: si, calculator: si.cl_project.cl_quotation.calculator } %>
  <% end %> */}

      {/* <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div> */}

      {/* <%= render partial: "pdf_project_report/products", locals: { si: si } %> */}

      {/* <% if si.cl_project.cl_quotation.present? %>
    <hr style="margin: 20px 0"/>
    <%= render partial: "pdf_cl_quotations/project_financials",
      locals: { record: si.cl_project.cl_quotation } %>
  <% end %>
   */}

      {/* <%# hidden for now %>
  <% if false && si.cl_project.cl_quotation.present? && si.tentative_start_date.present? %>
    <hr style="margin: 20px 0"/>
    <%= render partial: "pdf_project_report/month_on_month_breakdown", locals: { si: si } %>
  <% end %> */}
    </div>
  );
};

export default SIDetails;
