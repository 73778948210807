import React from "react";

const PaymentDetails = ({ finalQuotation = false }) => {
  return (
    <div>
      <p>
        <b>Terms & conditions:</b>
        <br />
      </p>
      <ol style={{ margin: "0px" }}>
        <li>
          This quote is subject to the following conditions of Sale. No
          variations or alterations or additions to these conditions will be
          binding on KBPL, notwithstanding that such Variations, alterations,
          additions are contained in your acceptance of this proposal, except
          when they are specifically accepted in writing by KBPL.
        </li>
        <li>
          Each project is customised to your specific needs and constructed as
          per custom specifications, any additional work after handover will
          require a separate work order. It is your responsibility to get
          desired changes done before handover.
        </li>
        <li>
          KBPL may also send some extra material to avoid short shortages, and
          all such material will remain at site up to completion of the project
          and must be returned to KBPL or its authorized representative without
          any consideration.
        </li>
        <li>
          Site should be clean, clear and to be maintained at zero level to
          carry-forward immediate construction.
        </li>
        <li>
          Once work-order is received and work has started, the amount received
          is non-refundable.
        </li>
        <li>Advance will be considered as work order.</li>
        <li>
          In case of deep foundation, provision of drilling/ auger machine/other
          needed means to be facilitated by you if required. Soil knowledge
          should be provided by you,
        </li>
        <li>
          Seeds, nutrients and other operational agronomy tools to be procured
          by you.
        </li>
        <li>
          For labors: accommodation facility, transfer, groceries and drinking
          water on field needs to be facilitated by you.
        </li>
        <li>
          For supervisor or office representatives / Agronomist as and when they
          visit, accommodation with food in resident/ guest house/ hotel
          facility to be provided by you to nearby site.
        </li>
        <li>
          For site development requirement of electricity or generator and water
          on field to be provided by you.
        </li>
        <li>
          Civil materials & civil work tools required for the project is your
          responsibility.
        </li>
        {/* <li>
          Free accommodation, transfer, and groceries on the site for labours is
          your responsibility.
        </li> */}
        <li>
          KBPL is not responsible for missing, damaged, loss and theft of
          materials after delivery.
        </li>
        <li>
          Any delay in work/ work stopped due to on-site related issues, you
          will have to bear the additonal expenses.
        </li>
        <li>
          Quotation rates are according to current rates of steel and pvc raw
          materials.
        </li>
        <li>
          Transportation of goods & labours ex factory to the site location
          charges are extra. Any extra services will be charged additionally.
        </li>
        <li>
          Insurance of project during and after construction is as per your
          preference. We strongly suggest Insurance is important to safeguard
          project form natural damages.
        </li>
        <li>
          Estimated installation time is within 45 to 90 days from receipt of
          material at site based on the size of the project, subjected to
          completion of your scope preparation and naturally conditions.
        </li>
        <li>
          On Subsidy approval, subsidy amount will be directly credited into
          your bank account. KBPL has no control over any process once your file
          is processed from our end.
        </li>
        <li>
          Offers given by KBPL are subject to terms and conditions. KBPL
          reserves all rights to make changes in offers.
        </li>
        <li>
          KBPL is not responsible for disapproval of subsidy or loan on any
          grounds.
        </li>
        <li>
          Any contention to Law will be subject to Pune Jurisdiction only.
        </li>

        <li>Above quote is valid for 20 days from the date of generation.</li>
      </ol>
      <table style={{ width: "100%", padding: "0px" }}>
        <tbody>
          <tr>
            <td style={{ width: "33.33%" }}>
              <p style={{ textAlign: "center" }}>
                Learn about hydroponics and commercials of hydroponics from
                experts. <br /> Visit us at <br />
                <a
                  href="https://www.hydroponicmasterclass.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>HYDROPONIC MASTERCLASS</b>
                </a>
                <br /> Online
              </p>
            </td>
            <td style={{ width: "33.33%" }}>
              <p style={{ textAlign: "center" }}>
                Get complete hands on understanding of entire hydroponic system
                and learn practically. <br /> Visit us at <br />
                <a
                  href="https://maps.app.goo.gl/nBU6i9UkxPqcyMqHA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>KRYZEN EXPERIENCE CENTER</b>
                </a>
                <br /> Satara, Maharashtra
              </p>
            </td>
            <td style={{ width: "33.33%" }}>
              <p style={{ textAlign: "center" }}>
                Understand business aspects of hydroponic farming and learn
                about how our ecosystem works. <br /> Visit us at <br />
                <a
                  href="https://maps.app.goo.gl/7gJT5vAByiD4crGP9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>KRYZEN BIOTECH OFFICE</b>
                </a>
                <br /> Pune, Maharashtra
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{ width: "100%", padding: "0px" }}>
        <tbody>
          <tr>
            <td style={{ width: "33.33%" }}>
              <p style={{ textAlign: "center" }}>
                Finding this quotation overwhelming?
                <br /> Watch this detailed explainer video.
                <br />
                <a
                  href="https://youtu.be/8WscAhKo2x8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>QUOTATION EXPLAINER</b>
                </a>
                <br /> Secret of profitable farm construction.
              </p>
            </td>
            <td style={{ width: "66.67%" }}>
              <p style={{ textAlign: "center" }}>
                {!finalQuotation && (
                  <strong>
                    This is a generic quotation on higher side and actual
                    quotation will be recalculated after site inspection and
                    actual location variables. Give us a call to book your site
                    inspection today.
                  </strong>
                )}
                <br />
                Please note: Some of the details are not given and applied at
                the time of execution as part of trade secrets.
                <br /> To discuss this details give us a call at
                <b> +91-9870-424-425 </b>
                or write to us at
                <b> sales@kryzen.com</b>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentDetails;
