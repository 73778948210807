import React, { useState, useEffect } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form, ErrorMessage } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";

import StepRoutes from "../../../Routes/steps_routes";

import performRequest from "../../../api/request";
import { EmployeeLeavesInfo } from "../../../SchemaInfo/info";
import { EmployeeLeaveSchema } from "../../../Schemas/employee/employee_leave_schema";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import CustomRadioButton from "../../../CustomComponent/custom_radio";
import { useSelector } from "react-redux";
import { Toast } from "../../../CustomComponent/toast";

import { BsCalendar2XFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";

const getLeavesTaken = (obj) => {
  if (obj.leaveType === "half day") {
    return 0.5;
  }
  if (obj.leaveType === "full day") {
    return 1;
  } else {
    const start = new Date(obj.leaveStartDate);
    const end = new Date(obj.leaveEndDate);
    return (end - start) / (1000 * 60 * 60 * 24) + 1;
  }
};

const checkLeaveOverlap = (leavesDetails, values) => {
  const start = new Date(values.leaveStartDate || values.leaveDate);
  const end = new Date(values.leaveEndDate || values.leaveDate);

  return leavesDetails.some((leave) => {
    const leaveStart = new Date(leave.leaveStartDate || leave.leaveDate);
    const leaveEnd = new Date(leave.leaveEndDate || leave.leaveDate);

    return (
      (start >= leaveStart && start <= leaveEnd) ||
      (end >= leaveStart && end <= leaveEnd) ||
      (start <= leaveStart && end >= leaveEnd)
    );
  });
};

const getQuarter = (date) => {
  const month = date.getMonth() + 1;
  return Math.ceil(month / 3);
};

const AddEmployeeLeves = () => {
  const employee = useSelector((store) => store.login.user);
  const [leaveCount, setLeaveCount] = useState({ paid: 0, unpaid: 0 });
  const [quarterlyLeaveCount, setQuarterlyLeaveCount] = useState({});
  const [userLeaveData, setUserLeaveData] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const patchId = employee.UserId;

  const fetchLeaveData = async (empId) => {
    try {
      const res = await performRequest("GET", `/leave/getleavesById/${empId}`);
      if (!res?.data?.error) {
        setUserLeaveData(res.data);
        const paidLeaves = res.data.leavesDetails
          .filter((leave) => leave.leaveReasonType === "sick")
          .reduce((total, leave) => total + getLeavesTaken(leave), 0);
        const unpaidLeaves = res.data.leavesDetails
          .filter((leave) => leave.leaveReasonType === "casual")
          .reduce((total, leave) => total + getLeavesTaken(leave), 0);

        setLeaveCount({ paid: paidLeaves, unpaid: unpaidLeaves });

        const quarterlyCount = res.data.leavesDetails.reduce((acc, leave) => {
          const quarter = getQuarter(
            new Date(leave.leaveDate || leave.leaveStartDate)
          );
          acc[quarter] = (acc[quarter] || 0) + getLeavesTaken(leave);
          return acc;
        }, {});
        setQuarterlyLeaveCount(quarterlyCount);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchEmployeeDetails = async (empId) => {
    try {
      const res = await performRequest("GET", `/login/getUserById/${empId}`);
      setEmployeeDetails(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (employee.UserId) {
      fetchLeaveData(employee.UserId);
      fetchEmployeeDetails(employee.UserId);
    }
  }, [employee.UserId]);

  const handleSubmit = async (values, actions) => {
    if (
      userLeaveData &&
      checkLeaveOverlap(userLeaveData.leavesDetails, values)
    ) {
      actions.setFieldError(
        "leaveDate",
        "You have already taken a leave on this day."
      );
      return;
    }

    const takenLeaves = getLeavesTaken(values);
    const leaveQuarter = getQuarter(
      new Date(values.leaveDate || values.leaveStartDate)
    );
    const currentQuarterLeaves =
      (quarterlyLeaveCount[leaveQuarter] || 0) + takenLeaves;

    if (
      currentQuarterLeaves > 4 &&
      employeeDetails?.employeementStatus === "activePosition"
    ) {
      Toast("error", "You have exceeded the quarterly leave limit.");
    }

    let updatedLeaveCount = { ...leaveCount };
    let updatedQuarterlyLeaveCount = {
      ...quarterlyLeaveCount,
      [leaveQuarter]: currentQuarterLeaves,
    };

    if (values.leaveReasonType === "sick") {
      updatedLeaveCount.paid += takenLeaves;
    } else if (values.leaveReasonType === "casual") {
      updatedLeaveCount.unpaid += takenLeaves;
    }

    const leaveData = {
      userId: employee?.UserId,
      employeeName: employee?.Name,
      employeeRole: employee?.Role,
      employeeStatus: employeeDetails?.employeementStatus,
      takenLeaves: updatedLeaveCount.paid + updatedLeaveCount.unpaid,
      totalLeaves:
        employeeDetails?.employeementStatus === "activePosition" ? 16 : 0,
      sickLeaves:
        employeeDetails?.employeementStatus === "activePosition" ? 8 : 0,
      casualLeaves:
        employeeDetails?.employeementStatus === "activePosition" ? 8 : 0,
      pendingLeaves:
        employeeDetails?.employeementStatus === "activePosition"
          ? 16 - (updatedLeaveCount.paid + updatedLeaveCount.unpaid)
          : 0,
      leavesDetails: userLeaveData
        ? [...userLeaveData.leavesDetails, values]
        : [values],
    };

    try {
      if (userLeaveData && userLeaveData.id) {
        const res = await performRequest(
          "PATCH",
          `/leave/updateleaves/${patchId}`,
          leaveData
        );
        // console.log(leaveData);
        if (res.status === 200) {
          setLeaveCount(updatedLeaveCount);
          setQuarterlyLeaveCount(updatedQuarterlyLeaveCount);
          Toast("success", "Leave created successfully!");
          fetchLeaveData(employee.UserId);
        } else {
          Toast("error", "Error while creating leave");
        }
      } else {
        const res = await performRequest(
          "POST",
          "/leave/createleaves/",
          leaveData
        );
        // console.log(leaveData);
        if (res.status === 201) {
          setLeaveCount(updatedLeaveCount);
          setQuarterlyLeaveCount(updatedQuarterlyLeaveCount);
          Toast("success", "Leave created successfully!");
          fetchLeaveData(employee.UserId);
        } else {
          Toast("error", "Error while creating leave");
        }
      }
    } catch (err) {
      console.error("Error submitting form", err);
      Toast("error", "Error while creating leave");
    }
  };

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <h2 className="page-title mb-1">
                    <FcCalendar className="fs-2  me-1" /> Apply Leaves
                  </h2>
                  <h4 className="text-muted">
                    {/* <StepRoutes /> */} Apply leaves.
                  </h4>
                </div>
                <div className="col-md-4">
                  <div className="btn-list justify-content-end">
                    <a href={"/employee-leave-management"}>
                      <button className="btn btn-primary">
                        <BsCalendar2XFill className="me-2 fs-2" /> View All
                        Leaves
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Occasion</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          15th August 2024 <br />
                          <span class="text-secondary">Independence Day</span>
                        </td>
                        <td class="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          7th September 2024
                          <br />
                          <span class="text-secondary">Ganesh Chaturthi</span>
                        </td>
                        <td class="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          2nd October 2024
                          <br />
                          <span class="text-secondary">
                            Mahatma Gandhi Jayanti
                          </span>
                        </td>
                        <td class="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          12th October 2024
                          <br />
                          <span class="text-secondary">Dashehra</span>
                        </td>
                        <td class="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          1st November 2024
                          <br />
                          <span class="text-secondary">
                            Diwali (Laxmi Pujan)
                          </span>
                        </td>
                        <td class="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          3rd November 2024
                          <br />
                          <span class="text-secondary">Bhaubij</span>
                        </td>
                        <td class="text-secondary">Full Day</td>
                      </tr>
                      <tr>
                        <td>
                          25th December 2024
                          <br />
                          <span class="text-secondary">Christmas</span>
                        </td>
                        <td class="text-secondary">Full Day</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="card">
                <Formik
                  initialValues={EmployeeLeavesInfo}
                  validationSchema={EmployeeLeaveSchema}
                  onSubmit={handleSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body row">
                        <h4>
                          <span class="badge bg-purple-lt me-1">
                            Total leaves:{" "}
                            {employeeDetails?.employeementStatus ===
                            "activePosition"
                              ? 16
                              : 0}
                          </span>
                          <span class="badge bg-indigo-lt me-1">
                            Sick leaves taken: {leaveCount.paid}
                          </span>
                          <span class="badge bg-green-lt me-1">
                            Casual leaves taken: {leaveCount.unpaid}
                          </span>
                          <span class="badge bg-yellow-lt me-1">
                            Pending leaves:{" "}
                            {employeeDetails?.employeementStatus ===
                            "activePosition"
                              ? Math.max(
                                  16 - (leaveCount.paid + leaveCount.unpaid),
                                  0
                                )
                              : 0}
                          </span>
                        </h4>
                        <hr />
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Select Leave duration
                          </label>
                          <div className="w-100 col">
                            <div>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveType"
                                  value="half day"
                                />
                                <span className="form-check-label">
                                  Half day
                                </span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveType"
                                  value="full day"
                                />
                                <span className="form-check-label">
                                  Full day
                                </span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveType"
                                  value="multiple days"
                                />
                                <span className="form-check-label">
                                  Multiple days
                                </span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="leaveType"
                              component="p"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        {props.values.leaveType !== "multiple days" && (
                          <div className="mb-3 row">
                            <label className="w-25 form-label required col-3">
                              Leave Date
                            </label>
                            <div className="w-100 col">
                              <CustomInput name="leaveDate" type="date" />
                              <ErrorMessage
                                name="leaveDate"
                                component="p"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        )}
                        {props.values.leaveType === "multiple days" && (
                          <>
                            <div className="mb-3 row">
                              <label className="w-25 form-label required col-3">
                                Start date
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  name="leaveStartDate"
                                  type="date"
                                />
                                <ErrorMessage
                                  name="leaveStartDate"
                                  component="p"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="w-25 form-label required col-3">
                                End date
                              </label>
                              <div className="w-100 col">
                                <CustomInput name="leaveEndDate" type="date" />
                                <ErrorMessage
                                  name="leaveEndDate"
                                  component="p"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Leave Type
                          </label>
                          <div className="w-100 col">
                            <CustomTextArea name="leaveReason" type="text" />
                            <ErrorMessage
                              name="leaveReason"
                              component="p"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="w-25 form-label required col-3">
                            Reason type
                          </label>
                          <div className="w-100 col">
                            <div>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveReasonType"
                                  value="sick"
                                />
                                <span className="form-check-label">Sick</span>
                              </label>
                              <label className="form-check form-check-inline">
                                <CustomRadioButton
                                  name="leaveReasonType"
                                  value="casual"
                                />
                                <span className="form-check-label">
                                  Casual / Comp off
                                </span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="leaveReasonType"
                              component="p"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="btn-list justify-content-end">
                          <FormSubmitButton buttonText="Add Leaves" />
                          <SimpleButton buttonText="Cancel" type="secondary" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="card p-3 my-2">
                <p className="m-0 p-0">
                  When you are in probation or in notice period; all leaves will
                  be unpaid.
                </p>
                <p className="m-0 p-0">
                  In a month you can not avail more then 3 comp-off leaves.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddEmployeeLeves;
