import React, { useEffect, useState } from "react";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
const generateDateLabels = (days) => {
  const dates = [];
  const today = new Date();
  for (let i = days; i >= 0; i--) {
    const date = new Date(today);

    date.setDate(today.getDate() - i);

    dates.push(date);
  }

  return {
    dates,
  };
};
const countLeadsByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates?.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    const count = leads.filter((lead) => {
      const createdAt = new Date(lead.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        lead.lead_alloted_to === owner
      );
    }).length;

    allCount += count;
  });
  return allCount;
};
const countFilteredLeadsByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    const count = leads.filter((lead) => {
      const updatedAt = new Date(lead.updatedAt);
      return (
        updatedAt >= startOfDay &&
        updatedAt <= endOfDay &&
        lead.reAssignTo === owner &&
        lead.reAssignTo !== lead.lead_alloted_to
      );
    }).length;
    allCount += count;
  });
  return allCount;
};
const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const countFollowupTakenLeadsByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates.map((date) => {
    let dt = formatDate(date);
    let count = leads.filter((lead) => {
      if (lead.followUp && lead.followUp.length > 0) {
        return lead.followUp.some(
          (followUp) =>
            followUp.followUpDate === dt &&
            followUp.followupTaken &&
            lead.reAssignTo === owner
        );
      }
    }).length;
    allCount += count;
  });
  return allCount;
};
const countFollowupCreatedByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates.map((date) => {
    let dt = formatDate(date);
    let count = leads.filter((lead) => {
      if (lead.followUp && lead.followUp.length > 0) {
        return lead.followUp.some(
          (followUp) => followUp.createdAt === dt && lead.reAssignTo === owner
        );
      }
    }).length;
    allCount += count;
  });
  return allCount;
};
const countFollowupPendingLeadsByDate = (leads, dates, owner) => {
  let allCount = 0;
  dates.map((date) => {
    let dt = formatDate(date);
    let count = leads.filter((lead) => {
      if (lead.followUp && lead.followUp.length > 0) {
        return lead.followUp.some(
          (followUp) =>
            followUp.followUpDate === dt &&
            !followUp.followupTaken &&
            lead.reAssignTo === owner
        );
      }
    }).length;
    allCount += count;
  });
  return allCount;
};

const countSIByDate = (SI, dates, owner) => {
  let allCount = 0;
  dates?.map((date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    const count = SI.filter((si) => {
      const createdAt = new Date(si.createdAt);
      return (
        createdAt >= startOfDay &&
        createdAt <= endOfDay &&
        si.salesRelationshipManager === owner
      );
    }).length;

    allCount += count;
  });
  return allCount;
};

const LeaderBoard = () => {
  const [dates, setDates] = useState();
  const [list, setList] = useState();

  const [siData, setSIData] = useState();
  const [leadsData, setLeadsData] = useState();
  const [leadOwner, setLeadOwner] = useState();
  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    performRequest("GET", `/allTablesCount/leaderBoardData`)
      .then((res) => {
        if (res.status === 200) {
          setLeadsData(res?.data?.allLeads);
          setSIData(res?.data?.allSI);
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const date = new Date();
    const { dates } = generateDateLabels(date.getDate() - 1);
    setDates(dates);
  }, []);
  const getPointsCount = (leadsData, dates, userName) => {
    let leadCount = countLeadsByDate(leadsData, dates, userName);
    let leadFilteredCount = countFilteredLeadsByDate(
      leadsData,
      dates,
      userName
    );
    let followtaken = countFollowupTakenLeadsByDate(leadsData, dates, userName);
    let followCreated = countFollowupCreatedByDate(leadsData, dates, userName);
    let followPending = countFollowupPendingLeadsByDate(
      leadsData,
      dates,
      userName
    );

    return (
      leadCount * 10 +
      leadFilteredCount * 10 +
      followtaken * 10 +
      followCreated * 10 -
      followPending * 10
    );
  };

  useEffect(() => {
    if (dates && leadOwner && leadsData && siData) {
      let highpt = 0;

      let dataMap = leadOwner?.map((item) => {
        let points = getPointsCount(leadsData, dates, item?.userName);
        let SIcount = countSIByDate(siData, dates, item?.userName);
        highpt = Math.max(points, highpt);
        return {
          ...item,
          points,
          SIcount,
        };
      });
      let data = dataMap?.sort((pro1, pro2) => {
        return pro2.points - pro1.points;
      });
      let siSort = data?.sort((pro1, pro2) => {
        return pro2.SIcount - pro1.SIcount;
      });
      setList(siSort);
    }
  }, [dates, leadOwner, leadsData, siData]);

  useEffect(() => {
    const interval = setInterval(() => {
      performRequest("GET", `/allTablesCount/leaderBoardData`)
        .then((res) => {
          if (res.status === 200) {
            setLeadsData(res?.data?.allLeads);
            setSIData(res?.data?.allSI);
          } else {
            Toast("error", "Error while getting Leads!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        });
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const getFontSize = (index) => {
    if (index === 0) {
      return { fontSize: "1.3em" };
    } else if (index === 1) {
      return { fontSize: "1.2em" };
    } else if (index === 2) {
      return { fontSize: "1.1em" };
    }
  };
  return (
    <div className="card-table table-responsive" style={{ maxHeight: "470px" }}>
      <table
        className="table card-table table-vcenter fw-bold"
        style={{ maxHeight: "470px", fontSize: "1.5em" }}
      >
        <thead>
          <tr>
            <th style={{ fontSize: "1em" }}>Name</th>
            <th className="text-end" style={{ fontSize: "1em" }} colSpan={2}>
              score (SI)
            </th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list?.map((data, index) => {
              return (
                (data?.points !== 0 || data?.SIcount > 0) && (
                  <tr key={index}>
                    <td style={getFontSize(index)}>
                      {data?.userName || "N/A"}
                    </td>
                    <td>
                      {data?.points} ({data?.SIcount})
                    </td>
                  </tr>
                )
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default LeaderBoard;
