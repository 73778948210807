import React, { useEffect, useState } from "react";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import performRequest from "../../../api/request";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";
import { Toast } from "../../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import { BsExclamationTriangleFill } from "react-icons/bs";

import { FcBusinessman } from "react-icons/fc";

const ViewEmployee = () => {
  const [imageInfo, setImageInfo] = useState({
    name: "",
    url: "",
  });
  const getName = (fname = "", lname = "") => {
    let bag = "";
    if (fname) {
      bag += fname;
    }
    if (lname) {
      bag += ` ${lname}`;
    }
    if (!bag) {
      bag = "N/A";
    }
    return bag;
  };
  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);
  const handelSubmit = (info) => {
    setModalData(info);
    document.getElementById("open-modal").click();
  };
  const [refresh, setRefresh] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState();
  const { id } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (employeeInfo) {
      if (employeeInfo?.aadharcardPhotoFront) {
        getUplodedImage(
          setImageUrls,
          employeeInfo?.aadharcardPhotoFront,
          "aadharcardPhotoFront"
        );
      }
      if (employeeInfo?.aadharcardPhotoBack) {
        getUplodedImage(
          setImageUrls,
          employeeInfo?.aadharcardPhotoBack,
          "aadharcardPhotoBack"
        );
      }
      if (employeeInfo?.pancardPhoto) {
        getUplodedImage(
          setImageUrls,
          employeeInfo?.pancardPhoto,
          "pancardPhoto"
        );
      }
      if (employeeInfo?.offerLetterAcceptance) {
        getUplodedImage(
          setImageUrls,
          employeeInfo?.offerLetterAcceptance,
          "offerLetterAcceptance"
        );
      }
      if (employeeInfo?.joiningLetterScan) {
        getUplodedImage(
          setImageUrls,
          employeeInfo?.joiningLetterScan,
          "joiningLetterScan"
        );
      }
      if (employeeInfo?.bankpassbookPhoto) {
        getUplodedImage(
          setImageUrls,
          employeeInfo?.bankpassbookPhoto,
          "bankpassbookPhoto"
        );
      }
      if (employeeInfo?.residentialProofPhoto) {
        getUplodedImage(
          setImageUrls,
          employeeInfo?.residentialProofPhoto,
          "residentialProofPhoto"
        );
      }
      if (employeeInfo?.photograph) {
        getUplodedImage(setImageUrls, employeeInfo?.photograph, "photograph");
      }
    }
  }, [employeeInfo]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/employeeManagement/getEmployeeManagementById/${id}`)
      .then((res) => setEmployeeInfo(res.data))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [id, refresh]);

  const disableCred = (info) => {
    setLoading(true);
    performRequest(
      "PATCH",
      `/employeeManagement/updateEmployeeManagement/${info?.id}`,
      { ...info, employeeStatus: "leftOrganization" }
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Employee credentials blocked!");
          setRefresh(!refresh);
        } else {
          Toast("error", "Error while uploading data");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  if (loading) {
    <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">
                  <FcBusinessman className="fs-2  me-1" /> Employee details
                </h2>
                <h4 className="text-muted">
                  <p>
                    View employment details of{" "}
                    {employeeInfo &&
                      getName(employeeInfo.firstName, employeeInfo.lastName)}
                  </p>
                  {/* <StepRoutes /> */}
                </h4>
              </div>
            </div>
          </div>

          <div className="card my-4">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="card">
                    <table className="table table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <div className="progressbg">Full name</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo &&
                              getName(
                                employeeInfo.firstName,
                                employeeInfo.lastName
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Date of birth</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.dateOfBirth
                              ? employeeInfo?.dateOfBirth
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Contact number</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.contactNumber
                              ? employeeInfo?.contactNumber
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Aadhar card number</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.aadharcardNumber
                              ? employeeInfo?.aadharcardNumber
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Pan card number</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.pancardNumber
                              ? employeeInfo?.pancardNumber
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Permanant address</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.permanantAddress
                              ? employeeInfo?.permanantAddress
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Residential address
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.residentialAddress
                              ? employeeInfo?.residentialAddress
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Qualification</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.qualification
                              ? employeeInfo?.qualification
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Emergency contact name
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.emergencyContactName
                              ? employeeInfo?.emergencyContactName
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Emergency contact number
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.emergencyContactNumber
                              ? employeeInfo?.emergencyContactNumber
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Emergency contact relation
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.emergencyContactRelation
                              ? employeeInfo?.emergencyContactRelation
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Salary per month</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.salaryPerMonth
                              ? employeeInfo?.salaryPerMonth
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">PF/PT deduction</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.pfptDeduction
                              ? employeeInfo?.pfptDeduction
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Mode of salary</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.modeOfSalary
                              ? employeeInfo?.modeOfSalary
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Hire via agency or direct
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.hireViaAgencyOrDirect
                              ? employeeInfo?.hireViaAgencyOrDirect
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Agency expences (exl GST)
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.agencyExpences_exlGST
                              ? employeeInfo?.agencyExpences_exlGST
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Laptop given by organisation
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.laptopGivenByOrganisation
                              ? employeeInfo?.laptopGivenByOrganisation
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Mobile given by organisation
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.mobileGivenByOrganisation
                              ? employeeInfo?.mobileGivenByOrganisation
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Sim card given by organisation
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.simCardGivenByOrganisation
                              ? employeeInfo?.simCardGivenByOrganisation
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Total years of experience
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.totalYearsOfExperience
                              ? employeeInfo?.totalYearsOfExperience
                              : "N/A"}
                          </td>
                        </tr>
                        {/* <tr>
                      <td>
                        <div className="progressbg">Working place</div>
                      </td>
                      <td className=" fw-bold text-start">
                        {employeeInfo?.workingPlace
                          ? employeeInfo?.workingPlace
                          : "N/A"}
                      </td>
                    </tr> */}
                        <tr>
                          <td>
                            <div className="progressbg">Package joined at</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.packageJoinedAt
                              ? employeeInfo?.packageJoinedAt
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Contract of employment
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.contractOfEmployment
                              ? employeeInfo?.contractOfEmployment
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">Reporting to</div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.reportingTo
                              ? employeeInfo?.reportingTo
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Role in organisation
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.roleInOrganisation
                              ? employeeInfo?.roleInOrganisation
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Previous organization name
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.previousOrganizationName
                              ? employeeInfo?.previousOrganizationName
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="progressbg">
                              Previous organization location
                            </div>
                          </td>
                          <td className=" fw-bold text-start">
                            {employeeInfo?.previousOrganizationLocation
                              ? employeeInfo?.previousOrganizationLocation
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-8 col-xs-12">
                  <div className="row row-deck row-cards">
                    <div className="col-sm-12 col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Aadhar card front: </h3>
                        </div>
                        <div className="card-body">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-scrollable"
                          >
                            <div
                              onClick={() =>
                                setImageInfo({
                                  name: "Aadhar card front",
                                  url: `${imageUrls?.aadharcardPhotoFront}`,
                                })
                              }
                              className="img-responsive img-responsive-21x9 card-img-bottom"
                              style={{
                                backgroundImage: `url(${imageUrls?.aadharcardPhotoFront})`,
                              }}
                            >
                              {imageUrls?.aadharcardPhotoFront == ""
                                ? "Aadharcard Photo Front Not Uploded"
                                : ""}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Aadhar card back:</h3>
                        </div>

                        <div className="card-body">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-scrollable"
                          >
                            <div
                              onClick={() =>
                                setImageInfo({
                                  name: "Aadhar card back",
                                  url: `${imageUrls?.aadharcardPhotoBack}`,
                                })
                              }
                              className="img-responsive img-responsive-21x9 card-img-bottom"
                              style={{
                                backgroundImage: `url(${imageUrls?.aadharcardPhotoBack})`,
                              }}
                            >
                              {" "}
                              {imageUrls?.aadharcardPhotoBack == ""
                                ? "Aadharcard Photo Back Not Uploded"
                                : ""}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Pan card:</h3>
                        </div>

                        <div className="card-body">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-scrollable"
                          >
                            <div
                              onClick={() =>
                                setImageInfo({
                                  name: "Pan card",
                                  url: `${imageUrls?.pancardPhoto}`,
                                })
                              }
                              className="img-responsive img-responsive-21x9 card-img-bottom"
                              style={{
                                backgroundImage: `url(${imageUrls?.pancardPhoto})`,
                              }}
                            >
                              {imageUrls?.pancardPhoto == ""
                                ? "Pancard Photo Not Uploded"
                                : ""}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">
                            Offer letter acceptance:
                          </h3>
                        </div>

                        <div className="card-body">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-scrollable"
                          >
                            <div
                              onClick={() =>
                                setImageInfo({
                                  name: "Offer letter acceptance",
                                  url: `${imageUrls?.offerLetterAcceptance}`,
                                })
                              }
                              className="img-responsive img-responsive-21x9 card-img-bottom"
                              style={{
                                backgroundImage: `url(${imageUrls?.offerLetterAcceptance})`,
                              }}
                            >
                              {imageUrls?.offerLetterAcceptance == ""
                                ? "Offer Letter Not Uploded"
                                : ""}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Joining letter scan:</h3>
                        </div>

                        <div className="card-body">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-scrollable"
                          >
                            <div
                              onClick={() =>
                                setImageInfo({
                                  name: "Joining letter scan",
                                  url: `${imageUrls?.joiningLetterScan}`,
                                })
                              }
                              className="img-responsive img-responsive-21x9 card-img-bottom"
                              style={{
                                backgroundImage: `url(${imageUrls?.joiningLetterScan})`,
                              }}
                            >
                              {imageUrls?.joiningLetterScan == ""
                                ? "Joining Letter Not Uploded"
                                : ""}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Bank passbook:</h3>
                        </div>

                        <div className="card-body">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-scrollable"
                          >
                            <div
                              onClick={() =>
                                setImageInfo({
                                  name: "Bank passbook",
                                  url: `${imageUrls?.bankpassbookPhoto}`,
                                })
                              }
                              className="img-responsive img-responsive-21x9 card-img-bottom"
                              style={{
                                backgroundImage: `url(${imageUrls?.bankpassbookPhoto})`,
                              }}
                            >
                              {imageUrls?.bankpassbookPhoto == ""
                                ? "Bank Passbook Not Uploded"
                                : ""}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Residential proof:</h3>
                        </div>

                        <div className="card-body">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-scrollable"
                          >
                            <div
                              onClick={() =>
                                setImageInfo({
                                  name: "Residential proof",
                                  url: `${imageUrls?.residentialProofPhoto}`,
                                })
                              }
                              className="img-responsive img-responsive-21x9 card-img-bottom"
                              style={{
                                backgroundImage: `url(${imageUrls?.residentialProofPhoto})`,
                              }}
                            >
                              {imageUrls?.residentialProofPhoto == ""
                                ? "Residential Proof Not Uploded"
                                : ""}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="btn-list justify-content-end">
                <button
                  className="btn btn-danger btn mx-1 mt-2"
                  onClick={() => handelSubmit(employeeInfo)}
                >
                  <BsExclamationTriangleFill className="fs-2 me-2" />
                  Disable credentials
                </button>
                <SimpleButton
                  href={`/employee-management/edit-employee/${id}`}
                  buttonText="Edit Information"
                  type="primary"
                />
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
            <div
              className="modal modal-blur fade"
              id="modal-scrollable"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{imageInfo.name}</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <img src={imageInfo.url} alt="not uploaded" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal modal-blur fade"
          id="modal-danger"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-sm modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <span
                id="open-modal"
                data-bs-toggle="modal"
                data-bs-target="#modal-danger"
              ></span>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-status bg-warning"></div>
              <div className="modal-body text-center py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon mb-2 text-warning icon-lg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <h3>Are you sure about disabled credentials?</h3>
                <div className="text-muted">
                  Do you really want to disabled credentials? This will not
                  allowed{" "}
                  <span className="fs-4 fw-bolder">
                    {getName(modalData?.firstName, modalData?.lastName)}
                  </span>{" "}
                  to login our portal.
                </div>
              </div>
              <div className="modal-footer">
                <div className="w-100">
                  <div className="row">
                    <div className="col">
                      <a href="#" className="btn w-100" data-bs-dismiss="modal">
                        Cancel
                      </a>
                    </div>
                    <div className="col">
                      <a
                        href="#"
                        className="btn btn-danger w-100"
                        data-bs-dismiss="modal"
                        onClick={() => disableCred(modalData)}
                      >
                        Disable Credentials
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewEmployee;
