import React, { useEffect, useState } from "react";
import InvoiceHeader from "../Client invoice PDF tables/invoice_header";
import SupplierRecipientInfo from "../Client invoice PDF tables/supplier_info";
import InvoiceDetails from "../Client invoice PDF tables/invoice_details";
import PaymentInfo from "../Client invoice PDF tables/payment_info";
import NotesFooter from "../Client invoice PDF tables/invoice_footer";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { useParams } from "react-router";

const PreviewClientInvoice = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const printPDF = () => {
    let name = "invoice-pdf";
    document.title = name;
    window.print();
  };
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/invoice/getInvoiceById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div
            className="page-header d-print-none"
            style={{ borderBottom: "1px solid #181818", marginBottom: "20px" }}
          >
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">Client Invoice Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
              <div className="col-auto ms-auto d-print-none">
                <div className="d-flex">
                  <SimpleButton buttonText={"Save Pdf"} onClick={printPDF} />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body" style={{ color: "black" }}>
            <InvoiceHeader />
            <SupplierRecipientInfo data={data} />
            <InvoiceDetails data={data} />
            <PaymentInfo />
            <NotesFooter />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default PreviewClientInvoice;
