import React, { useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { Formik, Form } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { VendorInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { VendorSchema } from "../../../Schemas/purchase/vendor.schema";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import { uploadImage } from "../../../CustomComponent/upload_Image";

const AddVendor = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handelSubmit = (values, actions) => {
    values = {
      ...values,
      generatedBy: userData?.Name,
    };
    let formData = new FormData();
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        if (Array.isArray(values[key])) {
          for (let item of values[key]) {
            formData.append(key, item);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
    }
    // loading true
    setLoading(true);

    const headers = { "Content-Type": "multipart/form-data" };
    performRequest("POST", "/vendor/createVendor", formData, headers)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Vendor created successfully!");
          navigate(`/purchase-vendor-management`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Vendor Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Vendor</h2>
            </div>

            <Formik
              initialValues={VendorInfo}
              validationSchema={VendorSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Business name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="businessName" type="text" />
                        {props.touched.businessName &&
                          props.errors.businessName && (
                            <p className="text-danger">
                              {props.errors.businessName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required  col-3">
                        Contact person name
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="contactPersonName" type="text" />
                        {props.touched.contactPersonName &&
                          props.errors.contactPersonName && (
                            <p className="text-danger">
                              {props.errors.contactPersonName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Phone number
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="contactPersonPhone" type="text" />
                        {props.touched.contactPersonPhone &&
                          props.errors.contactPersonPhone && (
                            <p className="text-danger">
                              {props.errors.contactPersonPhone}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Alternative phone number
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="alternateContactPersonPhone"
                          type="text"
                        />
                        {props.touched.alternateContactPersonPhone &&
                          props.errors.alternateContactPersonPhone && (
                            <p className="text-danger">
                              {props.errors.alternateContactPersonPhone}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Business email
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="contactPersonEmail" type="text" />
                        {props.touched.contactPersonEmail &&
                          props.errors.contactPersonEmail && (
                            <p className="text-danger">
                              {props.errors.contactPersonEmail}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Reffered by
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="referredBy" type="text" />
                        {props.touched.referredBy &&
                          props.errors.referredBy && (
                            <p className="text-danger">
                              {props.errors.referredBy}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label required col-3">
                        Business address
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="businessAddress" type="text" />
                        {props.touched.businessAddress &&
                          props.errors.businessAddress && (
                            <p className="text-danger">
                              {props.errors.businessAddress}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        State
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="state" type="text" />
                        {props.touched.state && props.errors.state && (
                          <p className="text-danger">{props.errors.state}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Gst number
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="gstNumber" />

                        {props.touched.gstNumber && props.errors.gstNumber && (
                          <p className="text-danger">
                            {props.errors.gstNumber}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Pan number
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="panNumber" />

                        {props.touched.panNumber && props.errors.panNumber && (
                          <p className="text-danger">
                            {props.errors.panNumber}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Bank name</label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="bankName" />

                        {props.touched.bankName && props.errors.bankName && (
                          <p className="text-danger">{props.errors.bankName}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Bank account name
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="bankAccountName" />

                        {props.touched.bankAccountName &&
                          props.errors.bankAccountName && (
                            <p className="text-danger">
                              {props.errors.bankAccountName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Bank IFSC</label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="bankIFSC" />

                        {props.touched.bankIFSC && props.errors.bankIFSC && (
                          <p className="text-danger">{props.errors.bankIFSC}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Payment term
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="paymentTerm">
                          <option value="">Select</option>
                          <option value="to pay">To Pay</option>
                          <option value="100% advance">100% Advance</option>
                          <option value="50% advance">50% Advance</option>
                          <option value="30 days">30 Days</option>
                          <option value="45 days">45 Days</option>
                          <option value="60 days">60 Days</option>
                        </CustomSelect>

                        {props.touched.paymentTerm &&
                          props.errors.paymentTerm && (
                            <p className="text-danger">
                              {props.errors.paymentTerm}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Business nature
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="businessNature">
                          <option value="">Select</option>
                          <option value="manufacturer">Manufacturer</option>
                          <option value="wholseller">Wholseller</option>
                          <option value="distributor">Distributor</option>
                          <option value="services">Services</option>
                          <option value="retailer">Retailer</option>
                          <option value="broker">Broker</option>
                        </CustomSelect>

                        {props.touched.businessNature &&
                          props.errors.businessNature && (
                            <p className="text-danger">
                              {props.errors.businessNature}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Legal constitution
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="legalConstitution">
                          <option value="">Select</option>
                          <option value="individual">Individual</option>
                          <option value="huf">HUF</option>
                          <option value="partnership">Partnership</option>
                          <option value="pvtLtd">PVT Ltd</option>
                          <option value="other">Other</option>
                        </CustomSelect>

                        {props.touched.legalConstitution &&
                          props.errors.legalConstitution && (
                            <p className="text-danger">
                              {props.errors.legalConstitution}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Accepted payment mode
                      </label>
                      <div className="w-100 col">
                        <div>
                          <label className="form-check form-check-inline">
                            <CustomCheckbox
                              type="checkbox"
                              width={"25px"}
                              name="acceptedPaymentMode"
                              value="NEFT"
                            />
                            <span className="form-check-label">NEFT</span>
                          </label>

                          <label className="form-check form-check-inline">
                            <CustomCheckbox
                              name="acceptedPaymentMode"
                              value="RTGS"
                              type="checkbox"
                              width={"25px"}
                            />
                            <span className="form-check-label">RTGS</span>
                          </label>
                          <label className="form-check form-check-inline">
                            <CustomCheckbox
                              type="checkbox"
                              width={"25px"}
                              name="acceptedPaymentMode"
                              value="cheque"
                            />
                            <span className="form-check-label">Cheque</span>
                          </label>
                          <label className="form-check form-check-inline">
                            <CustomCheckbox
                              type="checkbox"
                              width={"25px"}
                              name="acceptedPaymentMode"
                              value="cash"
                            />
                            <span className="form-check-label">Cash</span>
                          </label>
                        </div>

                        {props.touched.acceptedPaymentMode &&
                          props.errors.acceptedPaymentMode && (
                            <p className="text-danger">
                              {props.errors.acceptedPaymentMode}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>

                      <div className="w-100 col">
                        <CustomTextArea type="text" name="notes" />

                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        GST certificate
                      </label>
                      <div className="w-100 col">
                        {" "}
                        <CustomInput
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "gstCertificate",
                              e.target.files[0]
                            )
                          }
                          name="gstCertificate"
                          type="file"
                        />
                        {props.touched.gstCertificate &&
                          props.errors.gstCertificate && (
                            <p className="text-danger">
                              {props.errors.gstCertificate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Vendor registration
                      </label>
                      <div className="w-100 col">
                        {" "}
                        <CustomInput
                          onChange={(e) =>
                            uploadImage(props, "vendorReg", e.target.files[0])
                          }
                          name="vendorReg"
                          type="file"
                        />
                        {props.touched.vendorReg && props.errors.vendorReg && (
                          <p className="text-danger">
                            {props.errors.vendorReg}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Vendor sign
                      </label>
                      <div className="w-100 col">
                        {" "}
                        <CustomInput
                          onChange={(e) =>
                            uploadImage(props, "vendorSign", e.target.files[0])
                          }
                          name="vendorSign"
                          type="file"
                        />
                        {props.touched.vendorSign &&
                          props.errors.vendorSign && (
                            <p className="text-danger">
                              {props.errors.vendorSign}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3">
                        Vendor bank passbook
                      </label>
                      <div className="w-100 col">
                        {" "}
                        <CustomInput
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "vendorBankPassbook",
                              e.target.files[0]
                            )
                          }
                          name="vendorBankPassbook"
                          type="file"
                        />
                        {props.touched.vendorBankPassbook &&
                          props.errors.vendorBankPassbook && (
                            <p className="text-danger">
                              {props.errors.vendorBankPassbook}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Vendor" />

                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default AddVendor;
