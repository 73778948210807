import React from "react";

const EmployeeTrainingProgramme = () => {
  return (
    <div>
      <h3 className="table-heading">Employee Training Programme:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td>Hydroponic masterclass & farm visit</td>
            <td>
              After obtaining farm development service from KBPL, you get 3 user
              access codes for your employees to learn about hydroponics. Also
              you can send your labours to learn about hydroponic at our farm.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTrainingProgramme;
