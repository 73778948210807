import React, { useEffect, useState } from "react";
import Footer from "../../../Kryzen Alpha App/Components//Footer/footer";
import StepRoutes from "../../../Kryzen Alpha App/Routes/steps_routes";
import ButtonWithSVG from "../../../Kryzen Alpha App/Components/layouts/button_with_svg";
import performRequest from "../../../Kryzen Alpha App/api/request";
import { Toast } from "../../../Kryzen Alpha App/CustomComponent/toast";
import { useSelector } from "react-redux";
import DynamicFilterInput from "../../../Kryzen Alpha App/Components/layouts/filter.inpit";
import getFilteredURL from "../../../Kryzen Alpha App/Components/Functions/filters.getUrl";
import DynamicFilterSelect from "../../Components/layouts/filter.select";
import Pagination from "../../Components/layouts/pagination";
import FarmTrainingTable from "./Farm Training/farm_traing_table";

const FarmTraining = () => {
  const user = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    name: "",
    generatedBy: "",
  });
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [generatedBy, setGeneratedBy] = useState([]);

  useEffect(() => {
    setLoading(true);
    // console.log(
    //   getFilteredURL("/farmTraining/getFarmTraining/", page, filters)
    // );

    performRequest(
      "GET",
      getFilteredURL("/farmTraining/getFarmTraining/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.farmTrainingCount / 50));
        } else {
          Toast("error", "Error while getting Data!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setGeneratedBy(res?.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Farm Training</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>
                <div className="col-auto ms-auto d-print-none">
                  <div className="d-flex">
                    <ButtonWithSVG
                      href={"/farm-training/new"}
                      buttonText="New Farm Training"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 5l0 14" />
                          <path d="M5 12l14 0" />
                        </svg>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            {/* <div className="container-xl">
              <div className="card">
                {
                  <div className="row g-3 m-2 mb-0 me-3">
                    
                    
                    
                    
                    <div className="col-md">
                      <div className="d-flex">
                        <button
                          onClick={() => setRefresh(!refresh)}
                          style={{
                            height: "28px",
                            marginRight: "5px",
                          }}
                          className="btn btn-primary mt-3"
                        >
                          Search
                        </button>

                        <button
                          onClick={() => {
                            setFilters({
                              startdate: "",
                              enddate: "",
                              name: "",
                              generatedBy: "",
                            });
                            setRefresh(!refresh);
                          }}
                          style={{ height: "28px" }}
                          className="btn btn-primary mt-3"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                }
                {
                  <FarmTrainingTable
                    data={data?.farmTraining}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                }
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.farmTraining?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div> */}
            <div className="container-xxl">
              <div className="row">
                <div className="col-md-3 col-sm-12 py-2">
                  <div className="card p-1">
                    <div
                      id="faq-1"
                      className="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div>
                        <div className="accordion-header m-0" role="tab">
                          <button
                            className="accordion-button fs-3 d-md-none"
                            data-bs-toggle="collapse"
                            data-bs-target="#faq-1-1"
                          >
                            <h3>Filter result</h3>
                          </button>
                        </div>

                        <div
                          id="faq-1-1"
                          className="accordion-collapse collapse d-md-none"
                          role="tabpanel"
                          data-bs-parent="#faq-1"
                        >
                          <div className="accordion-body pt-0">
                            <div className="row">
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="Start Date:"
                                  value={filters.startdate}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      startdate: e.target.value,
                                    })
                                  }
                                  type="date"
                                />
                              </div>
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="End Date:"
                                  value={filters.enddate}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      enddate: e.target.value,
                                    })
                                  }
                                  type="date"
                                />
                              </div>
                              <div className="col-12">
                                <DynamicFilterInput
                                  label="Search name/phone:"
                                  value={filters.name}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      name: e.target.value,
                                    })
                                  }
                                  placeholder="search name..."
                                />
                              </div>

                              <div className="col-12">
                                <div className="col-12">
                                  <DynamicFilterSelect
                                    label="Generated By:"
                                    onChange={(e) => {
                                      setFilters({
                                        ...filters,
                                        generatedBy: e.target.value,
                                      });
                                    }}
                                    value={filters.generatedBy}
                                  >
                                    <option value="">select all</option>
                                    {generatedBy &&
                                      generatedBy?.map((lead, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={lead?.userName}
                                          >
                                            {lead?.userName}
                                          </option>
                                        );
                                      })}
                                  </DynamicFilterSelect>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="row mt-2">
                                  <div className="col-6">
                                    <button
                                      onClick={() => setRefresh(!refresh)}
                                      className="btn btn-success w-100"
                                    >
                                      Apply Filter
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      onClick={() => {
                                        setFilters({
                                          startdate: "",
                                          enddate: "",
                                          name: "",
                                          generatedBy: "",
                                        });
                                        setRefresh(!refresh);
                                      }}
                                      className="btn btn-secondary w-100"
                                    >
                                      Reset Filter
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-body d-none d-md-block">
                          <div className="row">
                            <h3>Filter result</h3>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="Start Date:"
                                value={filters.startdate}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    startdate: e.target.value,
                                  })
                                }
                                type="date"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="End Date:"
                                value={filters.enddate}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    enddate: e.target.value,
                                  })
                                }
                                type="date"
                              />
                            </div>
                            <div className="col-12">
                              <DynamicFilterInput
                                label="Search name/phone:"
                                value={filters.name}
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    name: e.target.value,
                                  })
                                }
                                placeholder="search name..."
                              />
                            </div>

                            <div className="col-12">
                              <div className="col-12">
                                <DynamicFilterSelect
                                  label="Generated By:"
                                  onChange={(e) => {
                                    setFilters({
                                      ...filters,
                                      generatedBy: e.target.value,
                                    });
                                  }}
                                  value={filters.generatedBy}
                                >
                                  <option value="">select all</option>
                                  {generatedBy &&
                                    generatedBy?.map((lead, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={lead?.userName}
                                        >
                                          {lead?.userName}
                                        </option>
                                      );
                                    })}
                                </DynamicFilterSelect>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="row mt-2">
                                <div className="col-6">
                                  <button
                                    onClick={() => setRefresh(!refresh)}
                                    className="btn btn-success w-100"
                                  >
                                    Apply Filter
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    onClick={() => {
                                      setFilters({
                                        startdate: "",
                                        enddate: "",
                                        name: "",
                                        generatedBy: "",
                                      });
                                      setRefresh(!refresh);
                                    }}
                                    className="btn btn-secondary w-100"
                                  >
                                    Reset Filter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9 col-sm-12 py-2">
                  <div className="card">
                    <FarmTrainingTable
                      data={data?.farmTraining}
                      currentPage={page}
                      itemsPerPage={50}
                    />
                    <Pagination
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      pageDatalength={data?.leave?.length}
                      showPage={showPage}
                      setShowPage={setShowPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default FarmTraining;
