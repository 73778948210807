import React from "react";

const NetHouseSpecifications = () => {
  return (
    <div>
      <h3>Net-House Specifications:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td>Type of system</td>
            <td>Natural ventilated poly house</td>
          </tr>
          <tr>
            <td>02.</td>
            <td>Design style</td>
            <td>Flat top design</td>
          </tr>
          <tr>
            <td>03.</td>
            <td>Construction area</td>
            <td>On Land / Terrace / Hard surface / Concrete foundation</td>
          </tr>
          <tr>
            <td>04.</td>
            <td>Bay size</td>
            <td>
              8m X 4m (Width of Nethouse should be 35% of the desired length.)
            </td>
          </tr>
          <tr>
            <td>05.</td>
            <td>Ridge height</td>
            <td>4m</td>
          </tr>
          <tr>
            <td>06.</td>
            <td>Structure material</td>
            <td>GI material (Galvanised Iron)</td>
          </tr>
          <tr>
            <td>07.</td>
            <td>Structure</td>
            <td>
              Complete structure made of galvanized steel tubular pipes of
              equivalent section conforming Indian Standards having wall
              thickness 2mm.
            </td>
          </tr>
          <tr>
            <td>08.</td>
            <td>Trusses</td>
            <td>Bottom &amp; top cord 60mm OD, 2mm thickness</td>
          </tr>
          <tr>
            <td>09.</td>
            <td>Trusses member &amp; purlin</td>
            <td>48mm/42mm OD, 2mm thickness</td>
          </tr>
          <tr>
            <td>10.</td>
            <td>Purlin member &amp; others</td>
            <td>33mm/25mm, 2mm thickness</td>
          </tr>
          <tr>
            <td>11.</td>
            <td>Foundations</td>
            <td>
              GI pipes of minimum 60mm OD 2mm thickness or more to foundation
              depth of 75cm or more depending upon soil type and prevailing wind
              condition, grouted with cement concrete mixture of 1:2:4 using
              telescopic insertion of column.
            </td>
          </tr>
          <tr>
            <td>12.</td>
            <td>Foundation accessories</td>
            <td>
              All nuts &amp; bolts will be of high tensile strength and
              galvanized.
            </td>
          </tr>
          <tr>
            <td>12.</td>
            <td>Entrance room &amp; double door</td>
            <td>
              One entrance room with double door will be provided and covered
              with net film. Doors of size 2m width &amp; 2.5m height double
              leaf made in plastic/FRP sheets mounted in a suitable strong
              frame.
            </td>
          </tr>
          <tr>
            <td>13.</td>
            <td>Fixing of cladding material</td>
            <td>
              All ends/joints of plastic film will be fixed with a two way
              aluminium/GI profile with suitable locking arrangement along with
              curtain top.
            </td>
          </tr>
          <tr>
            <td>14.</td>
            <td>Spring insert</td>
            <td>
              Zigzag high carbon steel with spring action wire of 2-3 mm
              diameter must be inserted to fix the sheet into Aluminium
              profile/GI profile.
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default NetHouseSpecifications;
