import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { capitalizeFirstLetterOfEachWord } from "../../../Components/Functions/word.fun";
import { useSelector } from "react-redux";
import { BsCheckCircleFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";
import { GoIssueReopened } from "react-icons/go";
import { MdOutlineFeedback } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";

const ViewTasks = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [showReply, setShowReply] = useState(false);
  const [reply, setReply] = useState("");
  const user = useSelector((store) => store.login.user);
  let { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/tasks/getTaskManagementById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Tasks!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const markasCompleted = () => {
    setLoading(true);
    const currentDate = new Date();
    const completedTime = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

    performRequest("PATCH", `/tasks/updateTaskManagement/${id}`, {
      status: "completed",
      completedTime: completedTime.toISOString(),
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Task marked as completed!");
          navigate(`/task-management`);
        } else {
          Toast("error", "Error while Creating");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  const reopenTask = () => {
    performRequest("PATCH", `/tasks/updateTaskManagement/${id}`, {
      status: "pending",
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Task reopen successfuly!");
          navigate(`/task-management`);
        } else {
          Toast("error", "Error while Creating");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  const handleAddResponse = () => {
    const updatedReplies = [
      ...(data.feedbackReplies || []),
      {
        userId: user.UserId,
        name: user.Name,
        message: reply,
        createdAt: new Date(),
      },
    ];
    const values = { feedbackReplies: updatedReplies };

    setLoading(true);
    performRequest("PATCH", `/tasks/updateTaskManagement/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Feedback added successfully!");
          setData((prevData) => ({
            ...prevData,
            feedbackReplies: updatedReplies,
          }));
          setReply("");
          setShowReply(false);
        } else {
          Toast("error", "Error while adding response");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcCalendar className="fs-2  me-1" /> View Task
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Update & Track Task.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  {data?.status === "completed" && (
                    <button onClick={reopenTask} className="btn btn-primary">
                      <GoIssueReopened className="me-2" /> REOPEN TASK
                    </button>
                  )}
                  {data?.status === "pending" ? (
                    <button
                      onClick={markasCompleted}
                      className="btn btn-primary"
                    >
                      <BsCheckCircleFill className="me-2" /> MARK AS COMPLETED
                    </button>
                  ) : (
                    <button
                      onClick={markasCompleted}
                      className="btn btn-primary"
                      disabled
                    >
                      <BsCheckCircleFill className="me-2" /> TASK COMPLETED
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <p>
                  Give appropriate feedback on the task once task is completed.
                </p>
                <p>
                  You can reopen the task by giving the feedback if the output
                  is not as per desire.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Task Spec</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td class="text-secondary">Task name</td>
                          <td>
                            {capitalizeFirstLetterOfEachWord(data?.name) ||
                              "N/A"}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td class="text-secondary">Task description</td>
                          <td>
                            {capitalizeFirstLetterOfEachWord(
                              data?.description
                            ) || "N/A"}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td class="text-secondary">Task assigned to</td>
                          <td>
                            {capitalizeFirstLetterOfEachWord(data?.assignTo) ||
                              "N/A"}
                          </td>
                        </tr>
                      )}
                      {data?.taskType != "" && (
                        <tr>
                          <td class="text-secondary">Task Type</td>
                          <td>
                            {capitalizeFirstLetterOfEachWord(data?.taskType) ||
                              "N/A"}
                          </td>
                        </tr>
                      )}
                      {data?.deadline != "" && (
                        <tr>
                          <td class="text-secondary">Task Deadline</td>
                          <td>{data?.deadline || "N/A"}</td>
                        </tr>
                      )}
                      {data?.startDate != "" && (
                        <tr>
                          <td class="text-secondary">Task Start Date</td>
                          <td>{data?.startDate || "N/A"}</td>
                        </tr>
                      )}
                      {data?.frequency != "" && (
                        <tr>
                          <td class="text-secondary">Task Frequency</td>
                          <td>
                            {data?.frequency}{" "}
                            {capitalizeFirstLetterOfEachWord(
                              data?.frequencyType
                            )}
                          </td>
                        </tr>
                      )}
                      {data && (
                        <tr>
                          <td class="text-secondary">Task Status</td>
                          <td>
                            {capitalizeFirstLetterOfEachWord(data?.status) ||
                              "N/A"}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {data?.status == "completed" && (
                  <>
                    <div className="row p-3">
                      <label className="w-25 form-label col-3">Feedback</label>
                      <div className="w-100 col">
                        {data?.feedbackReplies &&
                        data.feedbackReplies.length > 0
                          ? data.feedbackReplies.map(
                              (feedbackReplies, index) => (
                                <div key={index} className="card my-2">
                                  <div className="card-body">
                                    {feedbackReplies?.name && (
                                      <div className="d-flex">
                                        <FaUserCircle
                                          className="me-2"
                                          style={{ fontSize: "25px" }}
                                        />
                                        <h5 className="card-title">
                                          {feedbackReplies?.name}
                                        </h5>
                                      </div>
                                    )}
                                    <p
                                      className="card-text"
                                      style={{ whiteSpace: "pre-wrap" }}
                                    >
                                      {feedbackReplies?.message}
                                    </p>
                                    <p className="card-text">
                                      <small className="text-muted">
                                        {new Date(
                                          feedbackReplies.createdAt
                                        ).toLocaleString()}
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              )
                            )
                          : "No feedback yet"}
                      </div>
                    </div>

                    {showReply ? (
                      <div className="row p-3">
                        <label className="w-25 form-label col-3">
                          Your Feedback
                        </label>
                        <div className="w-100 col">
                          <textarea
                            className="form-control"
                            value={reply}
                            onChange={(e) => setReply(e.target.value)}
                            rows="3"
                          ></textarea>
                          <button
                            onClick={handleAddResponse}
                            className="btn btn-primary mt-2"
                          >
                            Submit
                          </button>
                          <button
                            onClick={() => setShowReply(false)}
                            className="btn btn-secondary mt-2 ms-2"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="row p-3">
                        <div className="w-100 col">
                          <button
                            onClick={() => setShowReply(true)}
                            className="btn btn-primary"
                          >
                            <MdOutlineFeedback className="me-2" />
                            Give Feedback
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    {user.Role === process.env.REACT_APP_ROLE_ADMIN_S ||
                    user.Role === process.env.REACT_APP_ROLE_ADMIN ? (
                      <SimpleButton
                        buttonText="Edit Task"
                        href={`/task-management/edit/${id}`}
                      />
                    ) : null}
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewTasks;
