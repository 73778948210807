import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { getUplodedImage } from "../../../CustomComponent/upload_Image";
import { FcPackage } from "react-icons/fc";
import { BsSave2 } from "react-icons/bs";

const ViewInventory = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (data) {
      if (data?.uploadBill) {
        getUplodedImage(setImageUrls, data?.uploadBill, "uploadBill");
      }
    }
  }, [data]);
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/inventory/getInventoryById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcPackage className="fs-2  me-1" /> Track your Inventory
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Track & manage your inventory.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/resources-inventory"} className="btn btn-primary">
                    {" "}
                    <BsSave2 className="fs-3 me-2" /> Inventory Item List
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specifications</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>Client Name</td>
                          <td>{(data?.farmName || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Client Address</td>
                          <td>
                            {(data?.projectAddress || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Client Contact</td>
                          <td>
                            {(data?.clientPhoneNumber || "N/A").toUpperCase()}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <div class="table-responsive">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>Specifications</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && (
                        <tr>
                          <td>Resource / Inventory Name</td>
                          <td>{(data?.assetName || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Inventory Company Name</td>
                          <td>{(data?.companyName || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Inventory Vendor Name</td>
                          <td>{(data?.vendor || "N/A").toUpperCase()}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Refill frequency</td>
                          <td>{data?.frequencyCycle || "N/A"}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Payment amount</td>
                          <td>{data?.paymentAmount || "N/A"}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Payment mode</td>
                          <td>{data?.paymentMode || "N/A"}</td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Uploaded bill</td>
                          <td>
                            {imageUrls?.uploadBill ? (
                              <img
                                width="60%"
                                src={imageUrls?.uploadBill}
                                alt={data?.uploadBill}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      )}

                      {data && (
                        <tr>
                          <td>Notes / Ref.</td>
                          <td>{data?.notes || "N/A"}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton
                      buttonText="Edit Inventory"
                      href={`/resources-inventory/edit/${id}`}
                    />
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewInventory;
