import { PiPhoneIncomingFill } from "react-icons/pi";
import { PiPhoneOutgoingFill } from "react-icons/pi";
import { HiPhoneMissedCall } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
import SalesWorkData from "./sales_workdata";
import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import StepRoutes from "../../../Routes/steps_routes";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const SalesDashboard = () => {
  const store = useSelector((store) => store.login.user);
  const [currentUser, setCurrentUser] = useState(store.Name);
  const [employee, setEmployee] = useState(
    store && store.Role === process.env.REACT_APP_ROLE_ADMIN_S
      ? "All"
      : store.Name
  );
  const [employeeName, setEmployeeName] = useState(
    (store && store.Role == process.env.REACT_APP_ROLE_ADMIN_S) ||
      store.Role == process.env.REACT_APP_ROLE_ADMIN
      ? "All"
      : store.Name
  );
  const [leadOwner, setLeadOwner] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setTableCount] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setLoading(true);

    performRequest("GET", "/allTablesCount/getCount")
      .then((res) => {
        if (res.status === 200) {
          setTableCount(res.data);
        } else {
          Toast("error", "Error while getting project Details!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  console.log(count);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="page">
        <div className="page-wrapper">
          <div style={{ minHeight: "80vh" }}>
            <div className="page-body">
              <div className="container-xxl">
                <div className="row row-deck row-cards my-3">
                  <div className="col-12">
                    <div className="row row-cards">
                      <div className="col-sm-6 col-lg-3">
                        <div className="card card-sm">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <span className="bg-primary text-white avatar">
                                  <PiPhoneOutgoingFill />
                                </span>
                              </div>
                              <div className="col">
                                <div className="font-weight-medium">
                                  {count?.OutgoingCalls} Outgoing Calls
                                </div>
                                <div className="text-muted">
                                  {count?.OutgoingCallsToday} today
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="card card-sm">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <span className="bg-green text-white avatar">
                                  <PiPhoneIncomingFill />
                                </span>
                              </div>
                              <div className="col">
                                <div className="font-weight-medium">
                                  {count?.IncomingCalls} Incoming Calls
                                </div>
                                <div className="text-muted">
                                  {count?.IncomingCallsToday} today
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="card card-sm">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <span className="bg-red text-white avatar">
                                  <HiPhoneMissedCall />
                                </span>
                              </div>
                              <div className="col">
                                <div className="font-weight-medium">
                                  {count?.MissedCalls} Missed Calls
                                </div>
                                <div className="text-muted">
                                  {count?.MissedCallsToday} today
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="card card-sm">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <span className="bg-green text-white avatar">
                                  <IoLogoWhatsapp />
                                </span>
                              </div>
                              <div className="col">
                                <div className="font-weight-medium">
                                  {count?.whatsappLogs} Messages
                                </div>
                                <div className="text-muted">
                                  {count?.whatsappLogsToday} today
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    <div className="col-12">
                      {leadOwner && store && (
                        <SalesWorkData leadOwner={leadOwner} userData={store} />
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SalesDashboard;
