import React from "react";
import { extractDate } from "../../../Components/Functions/date.fun";

const DeliveryDetails = ({ data }) => {
  return (
    <div>
      <h3
        style={{ marginTop: "14px", marginBottom: "2px", textAlign: "center" }}
      >
        DELIVERY CHALLAN / WORK ORDER
      </h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <p>
            Business Firm / Person Name:
            <b>{data?.businessName || data?.contactPersonName || "N/A"}</b>
            <br />
            Contact Person: <b>{data?.contactPersonName || "N/A"}</b> <br />
            Project Size <b>{data?.projectSize || "N/A"}</b> <br />
            Phone: <b>{data?.phoneNumber || "N/A"}</b> <br />
            Address: <b>{data?.address || "N/A"}</b> <br />
            GSTIN: <b>{data?.gstNumber || "N/A"}</b> <br />
            Pan: <b>{data?.panNumber || "N/A"}</b> <br />
          </p>
        </div>
        <div>
          <p>
            DC Number: <b>{data?.dcNumber || "N/A"}</b> <br />
            {data?.createdAt && (
              <>
                Date: <b>{extractDate(data?.createdAt) || "N/A"}</b>
              </>
            )}
            <br />
            LR: <b>{data?.lrNumber || "N/A"}</b> <br />
            E-Way: <b>{data?.ewayBillNumber || "N/A"}</b> <br />
            Transport Name: <b>{data?.transportName || "N/A"}</b> <br />
            Vehicle Number: <b>{data?.vehicleNumber || "N/A"}</b> <br />
            Driver: <b>{data?.driverName || "N/A"}</b> <br />
            Driver Phone: <b>{data?.driverPhoneNumber || "N/A"}</b> <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetails;
