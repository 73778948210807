import React from "react";
const getQuotationFor = (allQData) => {
  let bag = "";

  if (allQData?.input_info?.quotationType == "fanpad") {
    bag += "FANPAD";
  } else if (allQData?.input_info?.quotationType == "nvph") {
    bag += "NVPH";
  } else if (allQData?.input_info?.quotationType == "nethouse") {
    bag += "NETHOUSE";
  } else if (allQData?.input_info?.quotationType == "fanpad with hydroponics") {
    bag += "FANPAD";
    let next = "";
    if (allQData?.input_info?.a_FramePercent > 0) {
      next += `+ ${allQData?.input_info?.a_FramePercent}% A-FRAME`;
    }
    if (allQData?.input_info?.flatBedPercent > 0) {
      next += `+ ${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
    }
    if (allQData?.input_info?.growBagPercent > 0) {
      next += `+ ${allQData?.input_info?.growBagPercent}% GROW-BAG`;
    }
    if (allQData?.input_info?.dutchBucketPercent > 0) {
      next += `+ ${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
    }
    bag += ` ${next}`;
  } else if (
    allQData?.input_info?.quotationType == "nethouse with hydroponics"
  ) {
    bag += "NETHOUSE";
    let next = "";
    if (allQData?.input_info?.a_FramePercent > 0) {
      next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
    }
    if (allQData?.input_info?.flatBedPercent > 0) {
      next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
    }
    if (allQData?.input_info?.growBagPercent > 0) {
      next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
    }
    if (allQData?.input_info?.dutchBucketPercent > 0) {
      next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
    }
    bag += ` ${next}`;
  } else if (allQData?.input_info?.quotationType == "nvph with hydroponics") {
    bag += "NVPH";
    let next = "";
    if (allQData?.input_info?.a_FramePercent > 0) {
      next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
    }
    if (allQData?.input_info?.flatBedPercent > 0) {
      next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
    }
    if (allQData?.input_info?.growBagPercent > 0) {
      next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
    }
    if (allQData?.input_info?.dutchBucketPercent > 0) {
      next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
    }
    bag += ` ${next}`;
  } else if (allQData?.input_info?.quotationType == "hydroponics only") {
    bag += "WITHOUT-CEA";
    let next = "";
    if (allQData?.input_info?.a_FramePercent > 0) {
      next += ` +${allQData?.input_info?.a_FramePercent}% A-FRAME`;
    }
    if (allQData?.input_info?.flatBedPercent > 0) {
      next += ` +${allQData?.input_info?.flatBedPercent}% FLAT-BED`;
    }
    if (allQData?.input_info?.growBagPercent > 0) {
      next += ` +${allQData?.input_info?.growBagPercent}% GROW-BAG`;
    }
    if (allQData?.input_info?.dutchBucketPercent > 0) {
      next += ` +${allQData?.input_info?.dutchBucketPercent}% DUTCH-BUCKET`;
    }
    bag += ` ${next}`;
  }

  return bag;
};
const SIReport = ({ allQData, data }) => {
  return (
    <div style={{ marginTop: "10px" }}>
      <p>
        Dear Sir / Madam,
        <br />
      </p>
      <p>
        I/We find immense pleasure to introduce to myself, I/We am{" "}
        {data?.clientDetails?.businessName || data?.clientDetails.clientName}.
        We are planning to enter into vertical hydroponic business where we see
        higher returns on investment can be managed with help of modern
        technology.
        <br />
        We are planning to construct
        <b> {allQData && getQuotationFor(allQData)} project.</b>
        We will be taking help to construct this project from Kryzen Biotech
        Private Limited (KBPL). As per our discussions, please find the detailed
        project report below along with the project financials.
      </p>

      {/* <h2 class="table-heading" style="text-align: center">Site Inspection Report</h2>

<p style="text-align: center">
  <%= image_tag(wicked_pdf_asset_base64(si.score_image), style: "height: 200px") %>
</p> */}

      <h3>Executive Summary:</h3>

      <p>
        In the pursuit of sustainable and innovative agricultural practices, the
        integration of modern technologies has become paramount. Hydroponic
        farming, a method of cultivating plants without soil, stands at the
        forefront of this agricultural revolution. This project report unfolds
        the journey of establishing a state-of-the-art hydroponic farm, an
        endeavor aimed at revolutionizing traditional farming methods and
        ensuring a more efficient, resource-conscious, and high-yielding
        approach to agriculture.
      </p>

      <p>
        <u>Background:</u> Traditional soil-based agriculture faces numerous
        challenges, including land degradation, water scarcity, and
        unpredictable climatic conditions. Hydroponic farming addresses these
        challenges by providing a controlled environment where crops receive
        precisely calibrated nutrients, water, and light. This not only enhances
        crop yield but also reduces water consumption and minimizes the need for
        chemical inputs.
      </p>

      <p>
        <u>Rationale:</u> The decision to embark on this hydroponic farm setup
        arises from a commitment to sustainable and economically viable
        agriculture. By harnessing advanced hydroponic techniques, we aim to
        create an ecosystem that fosters optimal plant growth, minimizes
        environmental impact, and ensures the production of high-quality,
        nutrient-rich crops. This project aligns with the global movement
        towards precision agriculture, promoting a more resilient and productive
        food system for the future.
      </p>

      <p>
        <u>Idea:</u> After research of years and working expereince on multiplae
        locations. We have decided to boil down to Hydroponic farm construction
        to fulfil our objectives. Hydroponics crop farming is a line of farming
        that is classified under horty culture; it is a technique of cultivating
        crops by making use of mineral nutrient solutions, in water, without the
        support of soil. As a matter of fact, crops that are basically
        cultivated on terrestrial soil can successfully be grown with their
        roots in the mineral solution only, or in a torpid medium, such as
        perlite or gravel. The company is a combination of cutting-edge,
        high-quality, efficient food technology and production. It is committed
        to the improvement of taste in vegetables and excellent quality and
        nutritional value.
      </p>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>

      <h3>Objectives</h3>

      <p>The primary objectives of this hydroponic farm setup include:</p>

      <ol>
        <li>
          To establish an intensive farm, producing high-quality products for
          both the local and national markets year-round.
        </li>
        <li>
          Increasing crop yield and quality through precise control of
          environmental variables.
        </li>
        <li>
          Conserving water resources by implementing closed-loop hydroponic
          systems.
        </li>
        <li>Reducing the reliance on chemical fertilizers and pesticides.</li>
        <li>
          Establishing a model for sustainable and economically viable
          agriculture.
        </li>
      </ol>

      <p>
        As we delve into the details of the project, this report will provide a
        comprehensive overview of the chosen hydroponic system, the selection of
        crops, the infrastructure setup, and the expected outcomes. It is our
        sincere belief that this hydroponic farm will not only serve as a
        blueprint for modern farming practices but will also contribute to the
        broader conversation on sustainable food production.
        <br />
        With innovation as our compass and sustainability as our guide, we
        invite you to explore the intricacies of this hydroponic farm setup and
        envision a future where agriculture meets the demands of a growing
        population while preserving the delicate balance of our planet's
        resources.
      </p>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>

      <h3>Why did we choose Hydroponics?</h3>

      <p>
        Hydroponics uses less water than soil growing - in some cases up to 90%
        less water than traditional soil-based agriculture. You can plant 4
        times the amount of crops in the same space as soil farming. Grow all
        year long. Hydroponics allows for an optimally controlled environment
        and growth.
      </p>

      <h3>Why did we choose Polyhouse?</h3>

      <p>
        Polyhouse farming is a way of protected cultivation in agriculture.
        Polyethene plastic is used to cover the structure. It enables the
        cultivation of high-value crops(horticulture) in the structure. Some are
        naturally ventilated poly houses and some are under the total climate
        control system having motorised screens and ventilators.
      </p>
      <p>
        Protected cultivation under Polyhouse is gaining importance these days.
        It proves to be beneficial to the farmers since it enables Season
        cultivation of vegetables/fruits which enables the farmer to have a
        better price realisation. It extends the life cycle of the crops and
        provides a controlled environment for the crops.
      </p>

      <h3>Keys to our success</h3>

      <ol>
        <li>Efficient production utilizing polyhouse.</li>
        <li>No existing projects of this magnitude in the region.</li>
        <li>Experience in the vegetable industry.</li>
        <li>Location of the farm.</li>
        <li>Technical background of founders.</li>
      </ol>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default SIReport;
