import React from "react";
import kryzenLogo from "../../certificates/kryzenLogo.png";
import ProjectTrackingTable from "./Kryzen Dashboard Components/project_tracking_table";
import KryzenDetails from "./Kryzen Dashboard Components/kryzen_details";
import LeaderBoard from "./Kryzen Dashboard Components/leaderboard";

const KryzenDashBoard = () => {
  return (
    <>
      <div className="page layout-fluid">
        <div className="page-wrapper">
          <div className="page-body">
            <div className="container-xl">
              <div className="row row-deck row-cards">
                <div
                  className="col-lg-3 text-center"
                  style={{
                    justifyContent: "center",
                    display: "flex",

                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <div className="text-center">
                    <img
                      src={kryzenLogo}
                      width={"80%"}
                      className="align-center"
                      alt="kryzenLogo"
                    />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="row row-deck row-cards">
                    <div className="col-6 col-lg">
                      <div className="card card-sm">
                        <div
                          className="card-body text-white"
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                            backgroundImage:
                              "url(https://t4.ftcdn.net/jpg/02/09/79/37/360_F_209793746_2dQuBLsUO9bYZ0sDJxvlp6KclqdrS2RL.webp)",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col">
                              <div
                                className="text-center fw-bold"
                                style={{
                                  fontSize: "3.4em",
                                }}
                              >
                                19500{" "}
                              </div>
                              <div className="fs-1 text-center fw-bold">
                                INSTAGRAM
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg">
                      <div className="card card-sm">
                        <div
                          className="card-body text-white"
                          style={{
                            backgroundColor: "#0773E2",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col">
                              <div
                                className="text-center fw-bold"
                                style={{
                                  fontSize: "3.4em",
                                }}
                              >
                                19000
                              </div>
                              <div className="fs-1 text-center fw-bold">
                                FACEBOOK
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg">
                      <div className="card card-sm">
                        <div
                          className="card-body text-white"
                          style={{
                            backgroundColor: "#15487D",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col">
                              <div
                                className="text-center fw-bold"
                                style={{
                                  fontSize: "3.4em",
                                }}
                              >
                                3540
                              </div>
                              <div className="fs-1 text-center fw-bold">
                                LINKEDIN
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg">
                      <div className="card card-sm">
                        <div
                          className="card-body text-white"
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                            backgroundImage:
                              "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYQh_7ACo0pEaS63mnzaxqTtfw6L_5KmZJ5w&usqp=CAU)",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col">
                              <div
                                className="text-center fw-bold"
                                style={{
                                  fontSize: "3.4em",
                                }}
                              >
                                17600
                              </div>
                              <div className="fs-1 text-center fw-bold">
                                YOUTUBE
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg">
                      <div className="card card-sm">
                        <div
                          className="card-body text-white"
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                            backgroundImage:
                              "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5f5HNedkeA0U8jol-VQ8vq-jA4E0zA2tneA&usqp=CAU)",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col">
                              <div
                                className="text-center fw-bold"
                                style={{
                                  fontSize: "3.4em",
                                }}
                              >
                                4.7/5
                              </div>
                              <div className="fs-1 text-center fw-bold">
                                GOOGLE
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <KryzenDetails />

                <div className="col-md-12 col-lg-3">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        justifyContent: "center",
                        padding: "10px 0px",
                      }}
                    >
                      <h3
                        className="fw-semibold"
                        style={{
                          fontSize: "2.4em",
                        }}
                      >
                        ONGOING PROJECTS
                      </h3>
                    </div>
                    <ProjectTrackingTable />
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        justifyContent: "center",
                        padding: "10px 0px",
                      }}
                    >
                      <h3
                        className="fw-semibold"
                        style={{
                          fontSize: "2.4em",
                        }}
                      >
                        SALES LEADERBOARD
                      </h3>
                    </div>
                    <LeaderBoard />
                  </div>
                </div>

                <div className="col-md-12 col-lg-6">
                  <div className="card">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/V1L5jqP6azE?autoplay=1&loop=1&playlist=V1L5jqP6azE&showinfo=0&controls=0&mute=1"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div
                  className="col-12 text-center text-uppercase fw-bold"
                  style={{
                    fontSize: "3.2em",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  We are India's number one hydroponic construction company.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KryzenDashBoard;
