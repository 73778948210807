import * as yup from "yup";

export const ClientExpenseSchema = yup.object().shape({
  projectSelection: yup.string().required("Required"),
  siteSelection: yup.string(),
  vendor: yup.string().required("Required"),
  expenseDate: yup.date().required("Required"),
  expenseType: yup.string().required("Required"),
  uploadBill: yup.mixed(),
  expenseAmount: yup.number().required("Required"),
  gstAmount: yup.number().required("Required"),
  paidBy: yup.string().required("Required"),
  paymentMode: yup.string().required("Required"),
  notes: yup.string().required("Required"),
});
