import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import Pagination from "../../Components/layouts/pagination";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import LoadingPage from "../../Components/layouts/loading";
import EnquiryTable from "./Enquiry/enquiry_table";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setReduxFilters,
} from "../../redux/Filtered leads/enquiriesReducer";
const Enquiry = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    leadname: "",
    leadowner: "",
  });
  const store = useSelector((store) => store.enquiriesFilters);

  const dispatch = useDispatch();
  const [page, setCurrPage] = useState(store.currentPage);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/enquiryWebhook/getEnquiry", store.currentPage, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.enquiriesCount / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, store.currentPage]);
  useEffect(() => {
    setCurrPage(store.currentPage);
    setShowPage(Math.floor((store.currentPage - 1) / 5) * 5 + 1);
  }, [store.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Enquiry</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="page-body">
            <div className="container-xl">
              <div className="card">
                {data && <EnquiryTable leads={data?.enquiriesData} />}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.enquiriesData?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Enquiry;
