import React, { useState, useEffect } from "react";
import performRequest from "../../../api/request";
import GaugeChart from "./gauge_chart";
import getFilteredURL from "../../../Components/Functions/filters.getUrl";
import { useNavigate } from "react-router";
import { getId } from "../../../Components/Functions/word.fun";

const getDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

function getTimeDifference(startTimeStr) {
  // Parse the start time string to a Date object
  const [time, modifier] = startTimeStr.split(" ");
  let [hours, minutes, seconds] = time.split(":").map(Number);

  // Convert to 24-hour format
  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  // Get the current date and time
  const now = new Date();

  // Create a Date object for the start time on the current date
  const startTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes,
    seconds
  );

  // Calculate the difference in milliseconds
  let delta = now - startTime;

  // Convert milliseconds to seconds
  delta = Math.floor(delta / 1000);

  // Calculate hours, minutes, and seconds
  const diffHours = Math.floor(delta / 3600);
  delta %= 3600;
  const diffMinutes = Math.floor(delta / 60);
  const diffSeconds = delta % 60;

  // Return the time difference as a string
  return `${String(diffHours).padStart(2, "0")}:${String(diffMinutes).padStart(
    2,
    "0"
  )}:${String(diffSeconds).padStart(2, "0")}`;
}
const EmployeeWorkData = ({ leadOwner, userData }) => {
  const [employeeName, setEmployeeName] = useState(
    userData && userData.Role === process.env.REACT_APP_ROLE_ADMIN_S
      ? "All"
      : userData.Name
  );
  const [todaysData, setTodaysData] = useState();
  const navigate = useNavigate();

  const getLoginTimeValue = (loginTime) => {
    let time = getTimeDifference(loginTime);
    let [hr, mt, sc] = time.trim().split(":");

    return +hr;
  };

  useEffect(() => {
    if (employeeName) {
      performRequest(
        "GET",
        getFilteredURL("/allTablesCount/getTableCountByName/", 1, {
          generatedby: employeeName,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            setTodaysData(res?.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [employeeName]);

  const goToViewQuotation = (id) => {
    navigate(`/custom-quotation/${getId(id)}`);
  };

  const goToViewLeads = (id) => {
    navigate(`/leads/view-lead/${id}`);
  };

  const goToViewAssignLead = (id) => {
    navigate(`/leads/view-lead/${id}`);
  };

  const goToViewPendingFollwup = (id) => {
    navigate(`/leads-followup/view/${id}`);
  };

  function convertToTwoDecimalPlaces(amount) {
    return parseFloat(amount?.toFixed(2));
  }

  return (
    <>
      <div className="col-12">
        <div className="card-body">
          {todaysData && (
            <div className="col-12">
              <div className="row row-deck row-cards">
                <div className="col-6 col-md-4 col-lg">
                  <div className="card card-sm">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "130px",
                              height: "130px",
                              marginBottom: "-30px",
                            }}
                          >
                            <GaugeChart
                              value={Math.min(todaysData?.quotationsToday, 50)}
                              max={50}
                            />
                          </div>
                          <strong className="fs-3 text-uppercase">
                            {todaysData?.quotationsToday} Quotations
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg">
                  <div className="card card-sm">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "130px",
                              height: "130px",
                              marginBottom: "-30px",
                            }}
                          >
                            <GaugeChart
                              value={Math.min(todaysData?.leadsToday, 50)}
                              max={50}
                            />
                          </div>
                          <strong className="fs-3 text-uppercase">
                            {todaysData?.leadsToday} leads created
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg">
                  <div className="card card-sm">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "130px",
                              height: "130px",
                              marginBottom: "-30px",
                            }}
                          >
                            <GaugeChart
                              value={Math.min(
                                todaysData?.pendingFollowupToday,
                                50
                              )}
                              max={50}
                            />
                          </div>
                          <strong className="fs-3 text-uppercase">
                            {todaysData?.pendingFollowupToday} pending followup
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-4 col-lg">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "-30px",
                              }}
                            >
                              <GaugeChart value={0} max={50} />
                            </div>
                            <strong className="fs-5 text-uppercase">
                              0 outgoing calls
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "-30px",
                              }}
                            >
                              <GaugeChart value={0} max={50} />
                            </div>
                            <strong className="fs-5 text-uppercase">
                              0 incoming calls
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="col-6 col-md-4 col-lg">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "-30px",
                              }}
                            >
                              <GaugeChart
                                value={Math.min(
                                  todaysData?.todaysCreatedFollowUp,
                                  50
                                )}
                                max={50}
                              />
                            </div>
                            <strong className="fs-5 text-uppercase">
                              {todaysData?.todaysCreatedFollowUp} FollowUp
                              Created
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                {todaysData?.userLoginTime !== 0 &&
                  todaysData?.userLoginTime && (
                    <div className="col-6 col-md-4 col-lg">
                      {" "}
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "130px",
                                  height: "130px",
                                  marginBottom: "-30px",
                                }}
                              >
                                <GaugeChart
                                  value={
                                    getLoginTimeValue(
                                      todaysData?.userLoginTime
                                    ) || 0
                                  }
                                  max={9}
                                />
                              </div>
                              <strong className="fs-5 text-uppercase">
                                Login time{" "}
                                {getTimeDifference(todaysData?.userLoginTime)}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="col-lg-6">
        <div className="card card-sm">
          <div className="card-header">
            <h3 className="card-title">Quotations Created</h3>
          </div>

          <div
            className="card-table table-responsive"
            style={{ maxHeight: "470px" }}
          >
            <table className="table table-vcenter">
              <thead>
                <tr>
                  <th>Contact Name</th>
                  <th>Area</th>
                  <th>Amount</th>
                  <th>Generated By</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {todaysData?.quotationsTodayData?.map((quotation, index) => (
                  <tr key={index}>
                    <td>{quotation?.input_info?.contactPersonName}</td>
                    <td>{quotation?.area_in?.area_in_sqmeter}</td>
                    <td>
                      ₹
                      {convertToTwoDecimalPlaces(
                        quotation?.quotationMatrixCost?.total_cost
                      )}
                    </td>
                    <td>{quotation?.input_info?.genrated_by}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        style={{
                          width: "50px",
                          height: "25px",
                        }}
                        onClick={() =>
                          goToViewQuotation(quotation?.quotationNumber)
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-sm">
          <div className="card-header">
            <h3 className="card-title">Leads Created</h3>
          </div>
          <div
            className="card-table table-responsive"
            style={{ maxHeight: "470px" }}
          >
            <table className="table table-vcenter">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Contact Number</th>
                  <th>Lead Owner</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {todaysData?.leadsTodayData?.map((lead, index) => (
                  <tr key={lead.id}>
                    <td>{lead?.full_name}</td>
                    <td>{lead?.phone_number}</td>
                    <td>{lead?.reAssignTo}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        style={{
                          width: "50px",
                          height: "25px",
                        }}
                        onClick={() => goToViewLeads(lead?.id)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-sm">
          <div className="card-header">
            <h3 className="card-title">Followup Created</h3>
          </div>
          <div
            className="card-table table-responsive"
            style={{ maxHeight: "470px" }}
          >
            <table className="table table-vcenter">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>FILTERED BY</th>
                  <th>Lead Owner</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {todaysData?.todaysCreatedFollowUpData?.map((enquiry) => (
                  <tr key={enquiry.id}>
                    <td>{enquiry?.full_name}</td>
                    <td>{enquiry?.lead_alloted_to}</td>
                    <td>{enquiry?.reAssignTo}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        style={{
                          width: "50px",
                          height: "25px",
                        }}
                        onClick={() => goToViewAssignLead(enquiry?.id)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-sm">
          <div className="card-header">
            <h3 className="card-title">Pending Followups</h3>
          </div>
          <div
            className="card-table table-responsive"
            style={{ maxHeight: "470px" }}
          >
            <table className="table table-vcenter">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>FILTERED BY</th>
                  <th>Lead Owner</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {todaysData?.pendingFollowupTodayData?.map((followup) => (
                  <tr key={followup.id}>
                    <td>{followup?.full_name}</td>
                    <td>{followup?.lead_alloted_to}</td>
                    <td>{followup?.reAssignTo}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        style={{
                          width: "50px",
                          height: "25px",
                        }}
                        onClick={() => goToViewPendingFollwup(followup?.id)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="col-sm-6 col-lg-6 mt-4">
          <div className="card-header">
            <h3 className="card-title">Line Chart</h3>

            <div className="ms-auto lh-1">
              <div className="dropdown">
                <a
                  className="dropdown-toggle text-muted"
                  href="/dashboard"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Last {30} days
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <span className="dropdown-item">Last 7 days</span>
                  <span className="dropdown-item">Last 30 days</span>
                  <span className="dropdown-item">Last 45 days</span>
                  <span className="dropdown-item">Last 2 months</span>
                  <span className="dropdown-item">Last 3 months</span>
                </div>
              </div>
            </div>
          </div>
          <LineChart
            data1={3}
            data2={5}
            data3={8}
            data4={8}
            labels={labels}
            title={title}
            xLabel={xLabel}
            yLabel={yLabel}
          />
        </div> */}
      </div>
    </>
  );
};

export default EmployeeWorkData;
