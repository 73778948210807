import React, { useState, useEffect } from "react";
import performRequest from "../../../api/request";
import getFilteredURL from "../../../Components/Functions/filters.getUrl";
import { useNavigate } from "react-router";
import { getId } from "../../../Components/Functions/word.fun";
import GaugeChart from "../../Dashbourd/Dashbourd Components/gauge_chart";

const getDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const SalesWorkData = ({ leadOwner, userData }) => {
  const [employeeName, setEmployeeName] = useState("All");
  const [todaysData, setTodaysData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (employeeName) {
      // console.log(
      //   getFilteredURL("/allTablesCount/getTableCountByName/", 1, {
      //     generatedby: employeeName,
      //   })
      // );
      performRequest(
        "GET",
        getFilteredURL("/allTablesCount/getTableCountByName/", 1, {
          generatedby: employeeName,
        })
      )
        .then((res) => {
          if (res.status === 200) {
            setTodaysData(res?.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [employeeName]);

  const goToViewQuotation = (id) => {
    navigate(`/custom-quotation/${getId(id)}`);
  };

  const goToViewLeads = (id) => {
    navigate(`/leads/view-lead/${id}`);
  };

  const goToViewAssignLead = (id) => {
    navigate(`/leads/view-lead/${id}`);
  };

  const goToViewPendingFollwup = (id) => {
    navigate(`/leads-followup/view/${id}`);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          Sales Analysis :{"  "}
          {getDate()}
        </h3>
        {userData.Role !== process.env.REACT_APP_ROLE_SALLE &&
          userData.Role !== process.env.REACT_APP_ROLE_SALESAGENCY && (
            <div className="ms-auto lh-1">
              <div className="dropdown">
                <a
                  className="dropdown-toggle text-muted"
                  href="/dashboard"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {employeeName}
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <span
                    onClick={() => setEmployeeName("All")}
                    className="dropdown-item"
                  >
                    All
                  </span>
                  {leadOwner &&
                    leadOwner.map((data, index) => {
                      return (
                        <span
                          key={index}
                          onClick={() => setEmployeeName(data.userName)}
                          className="dropdown-item"
                        >
                          {data?.userName}
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
      </div>
      {todaysData && (
        <div className="col-12 p-3">
          <div className="row row-cards">
            <div className="col-sm-6 col-lg-3">
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          marginBottom: "-30px",
                        }}
                      >
                        <GaugeChart
                          value={Math.min(todaysData?.quotationsToday, 50)}
                          max={50}
                        />
                      </div>
                      <strong className="fs-3 text-uppercase">
                        {todaysData?.quotationsToday} Quotation
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-sm-6 col-lg-3">
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          marginBottom: "-30px",
                        }}
                      >
                        <GaugeChart
                          value={Math.min(todaysData?.leadsToday, 50)}
                          max={50}
                        />
                      </div>
                      <strong className="fs-3 text-uppercase">
                        {todaysData?.leadsToday} leads created
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-sm-6 col-lg-3">
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          marginBottom: "-30px",
                        }}
                      >
                        <GaugeChart
                          value={Math.min(todaysData?.enquiryAssignToday, 50)}
                          max={50}
                        />
                      </div>
                      <strong className="fs-3 text-uppercase">
                        {todaysData?.enquiryAssignToday} assigned leads
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-sm-6 col-lg-3">
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          marginBottom: "-30px",
                        }}
                      >
                        <GaugeChart
                          value={Math.min(todaysData?.pendingFollowupToday, 50)}
                          max={50}
                        />
                      </div>
                      <strong className="fs-3 text-uppercase">
                        {todaysData?.pendingFollowupToday} pending followup
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          marginBottom: "-30px",
                        }}
                      >
                        <GaugeChart
                          value={Math.min(
                            todaysData?.todaysCreatedFollowUp,
                            50
                          )}
                          max={50}
                        />
                      </div>
                      <strong className="fs-3 text-uppercase">
                        {todaysData?.todaysCreatedFollowUp} FollowUp Created
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          marginBottom: "-30px",
                        }}
                      >
                        <GaugeChart value={0} max={50} />
                      </div>
                      <strong className="fs-3 text-uppercase">
                        0 outgoing calls
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-sm-6 col-lg-3">
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          marginBottom: "-30px",
                        }}
                      >
                        <GaugeChart value={0} max={50} />
                      </div>
                      <strong className="fs-3 text-uppercase">
                        0 incoming calls
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-sm-6 col-lg-3">
              <div className="card card-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          marginBottom: "-30px",
                        }}
                      >
                        <GaugeChart
                          value={Math.min(
                            todaysData?.todaysCreatedFollowUp,
                            50
                          )}
                          max={50}
                        />
                      </div>
                      <strong className="fs-3 text-uppercase">
                        0 Missed Calls
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row row-deck row-cards mt-4 p-3">
        <div className="col-sm-6 col-lg-6">
          <div className="card card-sm" style={{ maxHeight: "470px" }}>
            <div className="card-header">
              <h3 className="card-title">Quotations Created</h3>
            </div>
            <div className="card-table table-responsive">
              <table className="table table-vcenter">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Quotation Type</th>
                    <th>Contact Name</th>
                    <th>Amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {todaysData?.quotationsTodayData.map((quotation, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{quotation?.input_info?.quotationType}</td>
                      <td>{quotation?.input_info?.contactPersonName}</td>
                      <td>
                        ₹
                        {quotation?.quotationMatrixCost?.total_cost?.toFixed(2)}
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          style={{ width: "50px", height: "25px" }}
                          onClick={() =>
                            goToViewQuotation(quotation?.quotationNumber)
                          }
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-6" style={{ maxHeight: "470px" }}>
          <div className="card card-sm">
            <div className="card-header">
              <h3 className="card-title">Leads Created</h3>
            </div>
            <div className="card-table table-responsive">
              <table className="table table-vcenter">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Full Name</th>
                    <th>Contact Number</th>
                    <th>Lead Owner</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {todaysData?.leadsTodayData.map((lead, index) => (
                    <tr key={lead.id}>
                      <td>{index + 1}</td>
                      <td>{lead?.full_name}</td>
                      <td>{lead?.phone_number}</td>
                      <td>{lead?.lead_alloted_to}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          style={{ width: "50px", height: "25px" }}
                          onClick={() => goToViewLeads(lead?.id)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-6 mt-4" style={{ maxHeight: "470px" }}>
          <div className="card card-sm">
            <div className="card-header">
              <h3 className="card-title">Followup Created</h3>
            </div>
            <div className="card-table table-responsive">
              <table className="table table-vcenter">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Full Name</th>
                    <th>Contact Number</th>
                    <th>Lead Owner</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {todaysData?.todaysCreatedFollowUpData.map(
                    (enquiry, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{enquiry?.full_name}</td>
                        <td>{enquiry?.phone_number}</td>
                        <td>{enquiry?.lead_alloted_to}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            style={{ width: "50px", height: "25px" }}
                            onClick={() => goToViewAssignLead(enquiry?.id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-6 mt-4" style={{ maxHeight: "470px" }}>
          <div className="card card-sm">
            <div className="card-header">
              <h3 className="card-title">Pending Followups</h3>
            </div>
            <div className="card-table table-responsive">
              <table className="table table-vcenter">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Full Name</th>
                    <th>Contact Number</th>
                    <th>Lead Owner</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {todaysData?.pendingFollowupTodayData.map(
                    (followup, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{followup?.full_name}</td>
                        <td>{followup?.phone_number}</td>
                        <td>{followup?.lead_alloted_to}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            style={{ width: "50px", height: "25px" }}
                            onClick={() => goToViewPendingFollwup(followup?.id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesWorkData;
