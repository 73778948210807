import React, { useEffect, useState } from "react";
import Footer from "../../../Kryzen Alpha App/Components//Footer/footer";
import StepRoutes from "../../../Kryzen Alpha App/Routes/steps_routes";
import performRequest from "../../../Kryzen Alpha App/api/request";
import { Toast } from "../../../Kryzen Alpha App/CustomComponent/toast";
import SiteInspectionFeedbackTable from "./si_feedback_table";
import Pagination from "../../../Kryzen Alpha App/Components/layouts/pagination";
import getFilteredURL from "../../../Kryzen Alpha App/Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import { useSelector } from "react-redux";
import DynamicFilterSelect from "../../Components/layouts/filter.select";

const SiteInspectionAll = () => {
  const user = useSelector((store) => store.login.user);
  // console.log(user);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    schedulingExperience: "",
  });
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log(
    //   getFilteredURL("/siteInspectionFeedback/getFeedback/", page, filters)
    // );
    performRequest(
      "GET",
      getFilteredURL("/siteInspectionFeedback/getFeedback/", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.siteInspectionFeedbackCount / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  }, [page, refresh]);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Site Inspection Feedback</h2>
                  <h4 className="text-muted">
                    <StepRoutes />
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="container-xxl">
          
<div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-1">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3>Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                          <DynamicFilterInput
                          label="Start Date:"
                          value={filters.startdate}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              startdate: e.target.value,
                            })
                          }
                          type="date"
                        />
                          </div>
                          <div className="col-12">
                          <DynamicFilterInput
                          label="End Date:"
                          value={filters.enddate}
                          onChange={(e) =>
                            setFilters({ ...filters, enddate: e.target.value })
                          }
                          type="date"
                        />
                          </div>
                          <div className="col-12">
                          <DynamicFilterSelect
                          label="Search by ratings:"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              schedulingExperience: e.target.value,
                            })
                          }
                          value={filters.schedulingExperience}
                        >
                          <option value="">select all</option>
                          <option value="1">1 Star</option>
                          <option value="2">2 Star</option>
                          <option value="3">3 Star</option>
                          <option value="4">4 Star</option>
                          <option value="5">5 Star</option>
                        </DynamicFilterSelect>
                          </div>
                         

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                              <button
                              onClick={() => setRefresh(!refresh)}
                              className="btn btn-success w-100"
                            >
                              Apply Filter
                            </button>
                              </div>
                              <div className="col-6">
                              <button
                              onClick={() => {
                                setFilters({
                                  startdate: "",
                                  enddate: "",
                                  schedulingExperience: "",
                                });
                                setRefresh(!refresh);
                              }}
                              className="btn btn-secondary w-100"
                            >
                              Reset Filter
                            </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                        <DynamicFilterInput
                          label="Start Date:"
                          value={filters.startdate}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              startdate: e.target.value,
                            })
                          }
                          type="date"
                        />
                        </div>
                        <div className="col-12">
                        <DynamicFilterInput
                          label="End Date:"
                          value={filters.enddate}
                          onChange={(e) =>
                            setFilters({ ...filters, enddate: e.target.value })
                          }
                          type="date"
                        />
                        </div>
                        <div className="col-12">
                        <DynamicFilterSelect
                          label="Search by ratings:"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              schedulingExperience: e.target.value,
                            })
                          }
                          value={filters.schedulingExperience}
                        >
                          <option value="">select all</option>
                          <option value="1">1 Star</option>
                          <option value="2">2 Star</option>
                          <option value="3">3 Star</option>
                          <option value="4">4 Star</option>
                          <option value="5">5 Star</option>
                        </DynamicFilterSelect>
                        </div>
                        

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                            <button
                              onClick={() => setRefresh(!refresh)}
                              className="btn btn-success w-100"
                            >
                              Apply Filter
                            </button>
                            </div>
                            <div className="col-6">
                            <button
                              onClick={() => {
                                setFilters({
                                  startdate: "",
                                  enddate: "",
                                  schedulingExperience: "",
                                });
                                setRefresh(!refresh);
                              }}
                              className="btn btn-secondary w-100"
                            >
                              Reset Filter
                            </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 col-sm-12 py-2">
            <div className="card">
                    <SiteInspectionFeedbackTable
                      data={data?.siteInspectionFeedback}
                      currentPage={page}
                      itemsPerPage={50}
                    />
                    <Pagination
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      pageDatalength={data?.leave?.length}
                      showPage={showPage}
                      setShowPage={setShowPage}
                    />
                  </div>
            </div>
          </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SiteInspectionAll;
