import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaStar,
  FaStarHalfAlt,
  FaRegStar,
  FaHeart,
  FaRegHeart,
  FaPhoneAlt,
} from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { Toast } from "../../../CustomComponent/toast";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import performRequest from "../../../api/request";
import { BuyersReportInfo } from "../../../SchemaInfo/info";
import { ReportBuyerSchema } from "../../../Schemas/buyers/report_schema";
import { Form, Formik } from "formik";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import Stars from "react-stars";
import { useSelector } from "react-redux";
import { FcApproval } from "react-icons/fc";
import {
  BsBookmarkHeart,
  BsTelephoneOutboundFill,
  BsStarHalf,
  BsBookmarkHeartFill,
  BsArrowRightCircleFill,
} from "react-icons/bs";

const PhoneNumberLink = ({ phoneNumber }) => {
  const formatPhoneNumber = (number) => {
    const cleanedNumber = number.replace(/\D/g, "");
    let formattedNumber = cleanedNumber;
    let icon = null;

    if (cleanedNumber.length === 11 && cleanedNumber.startsWith("91")) {
      formattedNumber = `+${-cleanedNumber}`;
    } else if (cleanedNumber.length === 11 && cleanedNumber.startsWith("0")) {
      formattedNumber = `+91-${cleanedNumber.slice(1)}`;
    } else if (cleanedNumber.length === 10) {
      formattedNumber = `+91-${cleanedNumber}`;
    }

    return { formattedNumber, icon };
  };

  const { formattedNumber, icon } = formatPhoneNumber(phoneNumber);

  return (
    <button className="btn btn-success" href={`tel:${formattedNumber}`}>
      <BsTelephoneOutboundFill className="me-1 fs-5" />
      {/* {formattedNumber} */}
      Call Buyer
    </button>
  );
};

const getType = (item) => {
  let bag = [];
  if (item?.isExporter) {
    bag.push("Exporter");
  }
  if (item?.isFoodProcessor) {
    bag.push("Food processor");
  }
  if (item?.isRetailer) {
    bag.push("Retailer");
  }
  if (item?.isTrader) {
    bag.push("Trader");
  }
  if (item?.isWholesaler) {
    bag.push("Wholesaler");
  }
  return bag.join(",");
};

const getHeartButtonWithUserId = (
  filters,
  favBuyer,
  userId,
  buyer,
  addToFavorite,
  removeFromFavorite
) => {
  let checkFav = [];
  if (!buyer.favouriteOnly) {
    checkFav = favBuyer.filter((item) => {
      if (item.favId == buyer.id && item.userId == userId) {
        return true;
      }
    });
  }
  if (buyer.userId && buyer.userId == userId) {
    checkFav.push(buyer);
  }

  if (checkFav && checkFav.length > 0) {
    return (
      <BsBookmarkHeartFill
        onClick={(e) => removeFromFavorite(e, buyer, favBuyer, userId)}
        className="fs-1"
      />
    );
  } else {
    return (
      <BsBookmarkHeart
        onClick={(e) => addToFavorite(e, buyer, userId)}
        className="fs-1"
      />
    );
  }
};
const clampStyle = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const BuyersTable = ({
  buyers,
  favBuyer,
  userId,
  refresh,
  setRefresh,
  filters,
}) => {
  const employee = useSelector((store) => store.login.user);
  const [modalData, setModalData] = useState("");
  const [buyersInfo, setBuyersInfo] = useState(BuyersReportInfo);
  const navigate = useNavigate();
  const [id, setId] = useState();

  const handelSubmit = (values, actions) => {
    let closeModel = document.getElementById("close-model");
    performRequest(
      "PATCH",
      `/buyerProfiles/updatebuyerProfiles/${modalData?.id}`,
      values
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Your response recorded successfully!");
          closeModel.click();
          actions.resetForm();
        } else {
          Toast("error", "Error while updating");
          closeModel.click();
          actions.resetForm();
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
        closeModel.click();
        actions.resetForm();
      });
  };

  const handleWhatsAppClick = (phoneNumber, message) => {
    if (phoneNumber.startsWith("0") && phoneNumber.length === 11) {
      phoneNumber = phoneNumber.substring(1);
    }
    const encodedMessage = encodeURIComponent(message);
    console.log(phoneNumber);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  const addToFavorite = async (event, buyer, userId) => {
    event.stopPropagation();
    const values = {
      ...buyer,
      userId: userId,
      favId: buyer.id,
    };
    try {
      const res = await performRequest(
        "POST",
        `/favoriteBuyer/createFavoriteBuyer`,
        values
      );
      if (res.status === 201) {
        Toast("success", "Favorite status updated!");
        setRefresh(!refresh);
      } else {
        Toast("error", "Error while updating favorite status");
      }
    } catch (err) {
      Toast("error", "Server Error!");
      console.error(err);
    }
  };
  const removeFromFavorite = async (event, buyer, favBuyer, userId) => {
    event.stopPropagation();

    let favId = "";
    if (!buyer.favouriteOnly) {
      favBuyer.filter((item) => {
        if (item.favId == buyer.id && item.userId == userId) {
          favId = item.id;
        }
      });
    }
    if (buyer.userId && buyer.userId == userId) {
      favId = buyer.id;
    }

    if (favId) {
      try {
        const res = await performRequest(
          "DELETE",
          `/favoriteBuyer/removeFavouriteBuyer/${favId}`
        );

        if (res.status === 200) {
          Toast("success", "Removed from favorites!");
          setRefresh(!refresh);
        } else {
          Toast("error", "Error while updating favorite status");
        }
      } catch (err) {
        Toast("error", "Server Error!");
        console.error(err);
      }
    }
  };
  const handleCardClick = (buyer) => {
    if (buyer.favouriteOnly == false) {
      navigate(`/buyers-management/view/${buyer.id}`);
    } else {
      navigate(`/buyers-management/view/${buyer.id}/?favorite=true`);
    }
  };

  const renderStars = (rating) => {
    const stars = [];
    rating = rating / 2;
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="text-warning" />);
      } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
        stars.push(
          <FaStarHalfAlt
            key={i}
            className="text-warning"
            style={{ width: "1rem" }}
          />
        );
      } else {
        stars.push(
          <FaRegStar
            key={i}
            className="text-warning"
            style={{ width: "1rem" }}
          />
        );
      }
    }
    return stars;
  };

  const [paymentRating, setPaymentRating] = useState(0);
  const [availabilityRating, setAvailabilityRating] = useState(0);
  const [repetitionRating, setRepetitionRating] = useState(0);

  const roundToOneDecimalPlace = (value) => {
    return parseFloat(value.toFixed(1));
  };

  const handleSaveChanges = (existingRating) => {
    if (+paymentRating && +availabilityRating && +repetitionRating > 0) {
      const averageRating =
        (+existingRating +
          +paymentRating * 2 +
          +availabilityRating * 2 +
          +repetitionRating * 2) /
        4;
      const roundedAverageRating = roundToOneDecimalPlace(averageRating);
      saveBuyerRating(roundedAverageRating);
    } else {
      navigate("/buyers-management");
    }
  };

  const saveBuyerRating = (averageRating) => {
    let values = {
      buyerRating: averageRating,
    };
    performRequest("PATCH", `/buyerProfiles/updatebuyerProfiles/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Rating submitted successfully!");
          setRefresh(!refresh);
        } else {
          Toast("error", "Error while updating");
          setRefresh(!refresh);
        }
        resetRatings();
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  const resetRatings = () => {
    setPaymentRating(0);
    setAvailabilityRating(0);
    setRepetitionRating(0);
  };

  return (
    <div class="table-responsive">
      <table class="table table-vcenter card-table table-striped">
        <thead>
          <tr>
            <th>Fav</th>
            <th className="w-33">Buyer Name</th>
            <th className="d-none d-md-table-cell">Buyer Details</th>
            <th>Contact</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {buyers.map((buyer) => (
            <tr key={buyer?.id}>
              <td>
                {getHeartButtonWithUserId(
                  filters,
                  favBuyer,
                  userId,
                  buyer,
                  addToFavorite,
                  removeFromFavorite
                )}{" "}
              </td>
              <td>
                <strong>
                  {buyer.name.toUpperCase()}{" "}
                  {buyer.buyerRating ? (
                    <span
                      className={`badge bg-blue ms-1`}
                      style={{ color: "white" }}
                    >
                      {Math.floor(buyer?.buyerRating * 10) / 10 / 2}{" "}
                    </span>
                  ) : (
                    <></>
                  )}{" "}
                </strong>
                {buyer.varifiedBadge ? <FcApproval className="ms-1" /> : <></>}{" "}
                <br />
                <spn className="badge">{getType(buyer) || "N/A"}</spn>
                <br /> {/* {renderStars(buyer?.buyerRating)}  */}
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-simple"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalData(buyer);
                    setId(buyer.id);
                  }}
                >
                  {/* <BsStarHalf />  */}
                  Rate Now
                </a>{" "}
              </td>
              <td className="d-none d-md-table-cell">
                {buyer?.addressLine1}
                {/* {buyer?.addressLine2} */}
              </td>

              <td>
                <PhoneNumberLink phoneNumber={buyer?.phoneNumber1} />{" "}
                {/* <button
                  className="btn p-1"
                  style={{
                    fontSize: "14px",
                    padding: "5px",
                    marginTop: "5px",
                  }}
                  onClick={() =>
                    handleWhatsAppClick(
                      buyer.phoneNumber1,
                      "Hello! I would like to chat with you."
                    )
                  }
                >
                  Chat
                </button> */}
              </td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleCardClick(buyer);
                  }}
                >
                  <BsArrowRightCircleFill />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="row row-deck">
        {buyers.map((buyer) => (
          <div className="col-lg-6">
            <div
              className="modal modal-blur fade"
              id="modal-simple"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Rate a buyer</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div
                        className="mb-3 d-flex"
                        style={{ alignItems: "center", gap: "1rem" }}
                      >
                        <div style={{ width: "130px" }}>
                          <label
                            className="form-label"
                            style={{ display: "block" }}
                          >
                            Payment on time
                          </label>
                        </div>
                        <div>
                          <Stars
                            count={5}
                            size={30}
                            value={paymentRating}
                            onChange={(newValue) => setPaymentRating(newValue)}
                          />
                        </div>
                      </div>
                      <div
                        className="mb-3 d-flex"
                        style={{ alignItems: "center", gap: "1rem" }}
                      >
                        <div style={{ width: "130px" }}>
                          <label
                            className="form-label"
                            style={{ display: "block" }}
                          >
                            Buyer availability
                          </label>
                        </div>
                        <div>
                          <Stars
                            count={5}
                            size={30}
                            value={availabilityRating}
                            onChange={(newValue) =>
                              setAvailabilityRating(newValue)
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="mb-3 d-flex"
                        style={{ alignItems: "center", gap: "1rem" }}
                      >
                        <div style={{ width: "130px" }}>
                          <label
                            className="form-label"
                            style={{ display: "block" }}
                          >
                            Repetition of orders
                          </label>
                        </div>
                        <div>
                          <Stars
                            count={5}
                            size={30}
                            value={repetitionRating}
                            onChange={(newValue) =>
                              setRepetitionRating(newValue)
                            }
                          />
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="modal-footer justify-content-between">
                    <div>
                      <b>Finding issues:</b>{" "}
                      <button
                        className="btn btn-danger p-1 ms-1"
                        data-bs-toggle="modal"
                        data-bs-target="#report-buyer"
                        style={{ width: "70px" }}
                      >
                        Report
                      </button>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary p-1"
                      onClick={() => handleSaveChanges(buyer?.buyerRating)}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{ width: "70px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal modal-blur fade"
              id="report-buyer"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Report Problem: {modalData ? modalData.name : ""}
                    </h5>
                    <button
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <Formik
                      initialValues={BuyersReportInfo}
                      validationSchema={ReportBuyerSchema}
                      onSubmit={handelSubmit}
                    >
                      {(props) => (
                        <Form>
                          <div className="card-body">
                            <div className="mb-3 w-100 col">
                              <div className="d-flex">
                                <label className=" form-check-inline  ">
                                  <CustomCheckbox
                                    type="checkbox"
                                    width={"18px"}
                                    height={"18px"}
                                    name="report"
                                    value="call not connected"
                                  />
                                </label>
                                <p className="form-check-label">
                                  Call Not Connected
                                </p>
                              </div>
                            </div>{" "}
                            <div className="mb-3 w-100 col">
                              <div className="d-flex">
                                <label className=" form-check-inline  ">
                                  <CustomCheckbox
                                    type="checkbox"
                                    width={"18px"}
                                    height={"18px"}
                                    name="report"
                                    value="wrong number"
                                  />
                                </label>
                                <p className="form-check-label">Wrong Number</p>
                              </div>
                            </div>{" "}
                            <div className="mb-3 w-100 col">
                              <div className="d-flex">
                                <label className=" form-check-inline  ">
                                  <CustomCheckbox
                                    type="checkbox"
                                    width={"18px"}
                                    height={"18px"}
                                    name="report"
                                    value="wrong buyer"
                                  />
                                </label>
                                <p className="form-check-label">Wrong Buyer</p>
                              </div>
                            </div>
                            <div className="mb-3 w-100 col">
                              <div className="d-flex">
                                <label className=" form-check-inline  ">
                                  <CustomCheckbox
                                    type="checkbox"
                                    width={"18px"}
                                    height={"18px"}
                                    name="report"
                                    value="not buying anymore"
                                  />
                                </label>
                                <p className="form-check-label">
                                  Not Buying Anymore
                                </p>
                              </div>

                              {props.touched.report && props.errors.report && (
                                <p className="text-danger">
                                  {props.errors.report}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="modal-footer d-flex justify-content-between">
                            <div>
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-simple"
                                className="btn btn-primary m-2 p-1"
                                style={{ width: "70px" }}
                              >
                                Back
                              </a>
                            </div>
                            <div>
                              <button
                                type="submit"
                                className={`btn btn-danger m-2 p-1`}
                                buttonText="Report"
                                data-bs-dismiss="modal"
                                style={{ width: "70px" }}
                              >
                                Report
                              </button>

                              <button
                                type="button"
                                id="close-model"
                                className={`btn btn-secondary p-1`}
                                buttonText="Cancel"
                                href=""
                                data-bs-dismiss="modal"
                                style={{ width: "70px" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuyersTable;
