import React from "react";

const ScopeOfWork = ({ quotationType = null }) => {
  let serial = 1;
  return (
    <div>
      <h3 className="table-heading">Scope of Work:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serial++}.</td>
            <td>Procurement</td>
            <td>
              KBPL will procure all raw material to KBPL factory as per the
              requirement of modification and structure.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Modifications</td>
            <td>
              KBPL will modify the raw materials as per requirement of the
              project at our factory location.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Transportation of raw material</td>
            <td>
              KBPL will transport all raw material at its own expense before the
              modification and for in work transit.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Quality analysis</td>
            <td>
              KBPL will conduct all Quality Analysis at factory location and
              modify / redo the work if required.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Foundation installation</td>
            <td>Installation of water tank will be in scope of KBPL.</td>
          </tr>

          {quotationType === "fanpad" && (
            <tr>
              <td>{serial++}.</td>
              <td>Installation of fanpad</td>
              <td>
                Installation of Fanpad is from GI skeleton to Plastic sheet and
                shade net is in scope of KBPL.
              </td>
            </tr>
          )}

          {quotationType !== "fanpad" &&
            quotationType !== "nvph" &&
            quotationType !== "nethouse" && (
              <>
                <tr>
                  <td>{serial++}.</td>
                  <td>Frame installation &amp; NFT pipes</td>
                  <td>
                    Installation of frames and NFT pipes is in scope of KBPL.
                  </td>
                </tr>
                <tr>
                  <td>{serial++}.</td>
                  <td>Plumbing work</td>
                  <td>
                    Complete plumbing work, to all NFT pipes and from NFT pipes
                    to reservoir is in scope of KBPL.
                  </td>
                </tr>
              </>
            )}
          <tr>
            <td>{serial++}.</td>
            <td>Installation of water tank</td>
            <td>
              Installation of the water tank from foundation above is in scope
              of KBPL.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Final on-site QA</td>
            <td>
              Final on-site QA will be done by KBPL team. We insist you to be
              present at QA process to take the complete overview of operations
              and understanding of project.
            </td>
          </tr>
          <tr>
            <td>{serial++}.</td>
            <td>Handover</td>
            <td>
              Delivery of working conditions as per quotation description will
              be considered as project ready for handover.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ScopeOfWork;
