import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { FieldArray, Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import LoadingPage from "../../../Components/layouts/loading";
import { ProjectTrackingSchema } from "../../../Schemas/clients/client_project_tracking_schema";
import { getId } from "../../../Components/Functions/word.fun";
import { LockPermission } from "./add_project_tracking";
import {
  getUplodedImage,
  uploadImage,
} from "../../../CustomComponent/upload_Image";

function getDateDiffernce(compDate, SchedDate) {
  const x = new Date(compDate);
  const y = new Date(SchedDate);

  if (x <= y) {
    return "Hooray! We are on time!";
  } else {
    return "Ohhh! We missed schedule date!";
  }
}

const EditProjectTracking = () => {
  const [projectTrackingInfo, setProjectTrackingInfo] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [resStaff, setResStaff] = useState([]);
  let { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [siteInspections, setSiteInspections] = useState([]);
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    if (projectTrackingInfo) {
      if (projectTrackingInfo?.workOrderUpload) {
        getUplodedImage(
          setImageUrls,
          projectTrackingInfo?.workOrderUpload,
          "workOrderUpload"
        );
      }
    }
  }, [projectTrackingInfo]);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/projectTracking/getProjectTrackingById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setProjectTrackingInfo(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id, refresh]);

  // console.log(projectTrackingInfo);

  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    performRequest("GET", "/employeeManagement/getAllEmployeeManagement")
      .then((res) => setResStaff(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    performRequest("GET", "/siteInspection/getallSiteInspection")
      .then((res) => setSiteInspections(res.data))
      .catch((err) => console.error(err));
  }, []);
  const handelSubmit = (values, actions) => {
    setLoading(true);
    performRequest(
      "PATCH",
      `/projectTracking/updateProjectTracking/${id}`,
      values
    )
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Project tracking updated successfully!");
          navigate(`/client-project-tracking/view/${id}`);
        } else {
          Toast("error", "Error while updating Project tracking!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  const sendUpdateToClient = (task, nextTask) => {
    let sendData = {
      clientName: projectTrackingInfo?.projectClientName,
      mobileNumber: projectTrackingInfo?.projectclientContactNumber,
      taskName: task?.taskName,
      nextTaskName: nextTask ? nextTask.taskName : "Project Completed",
      projectResponsibleStaff: task?.responsibleStaff,
      taskCompliationDate: task?.completionDate,
      taskScheduleDate: task?.scheduleDate,
      scheduleStatus: getDateDiffernce(
        task?.completionDate,
        task?.scheduleDate
      ),
    };
    performRequest("POST", "/projectTracking/sendProjectUpdate/", sendData)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Update Sent To Client Successfully!");
        } else {
          Toast("error", "Error while sending Details!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };
  const sendUpdate = (task, props, index) => {
    if (!task.completionDate && !task.scheduleDate && !task.responsibleStaff) {
      Toast("error", `Please complete all details of "${task.taskName}"`);
    } else if (!task.completionDate) {
      Toast("error", `Please fill completion date of "${task.taskName}"`);
    } else if (!task.scheduleDate) {
      Toast("error", `Please fill schedule date of "${task.taskName}"`);
    } else if (!task.responsibleStaff) {
      Toast("error", `Please add responsible staff to "${task.taskName}"`);
    } else {
      props.setFieldValue(`taskList[${index}].isLocked`, true);
      let tList = props?.values?.taskList;
      let reload = false;

      if (index + 1 >= tList.length) {
        reload = true;
        props.setFieldValue(`taskList[${index}].isUpdated`, true);
        tList = tList?.map((task, i) => {
          return { ...task, isProjectFinal: true };
        });
      } else {
        props.setFieldValue(`taskList[${index}].isUpdated`, true);
      }

      let dt = props.values;
      let filteredTasks = tList?.map((task, i) => {
        if (i === index) {
          return { ...task, isUpdated: true };
        } else {
          return task;
        }
      });

      dt = { ...dt, taskList: filteredTasks };

      performRequest(
        "PATCH",
        `/projectTracking/updateProjectTracking/${id}`,
        dt
      )
        .then((res) => {
          if (res.status === 200) {
            Toast("success", "Task updated successfully!");
            sendUpdateToClient(task, filteredTasks[index + 1]);
            if (reload) setRefresh(true);
          } else {
            Toast("error", "Error while updating task!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        });
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Project Tracking</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Project Tracking</h2>
            </div>
            <Formik
              initialValues={projectTrackingInfo}
              validationSchema={ProjectTrackingSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Project
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="projectId">
                          <option value="">Select</option>
                          {projects &&
                            projects?.map((pro) => {
                              return (
                                <option
                                  key={pro.cpNumber}
                                  value={getId(pro.cpNumber)}
                                >
                                  {pro.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>

                        {props.touched.projectId && props.errors.projectId && (
                          <p className="text-danger">
                            {props.errors.projectId}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Site inspection
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="siteSelectionId">
                          <option value="">Select</option>
                          {siteInspections &&
                            siteInspections?.map((si) => {
                              return (
                                <option
                                  key={si.siNumber}
                                  value={getId(si.siNumber)}
                                >
                                  {si.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>

                        {props.touched.siteSelectionId &&
                          props.errors.siteSelectionId && (
                            <p className="text-danger">
                              {props.errors.siteSelectionId}
                            </p>
                          )}
                      </div>
                    </div>
                    {projectTrackingInfo && (
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Preview work order
                        </label>
                        <div className="w-100 col">
                          {imageUrls?.workOrderUpload ? (
                            projectTrackingInfo?.workOrderUpload.endsWith(
                              ".pdf"
                            ) ? (
                              <embed
                                src={imageUrls?.workOrderUpload}
                                width="70%"
                                height="375"
                                type="application/pdf"
                              />
                            ) : (
                              <img
                                width="70%"
                                src={imageUrls?.workOrderUpload}
                                alt={projectTrackingInfo?.workOrderUpload}
                              />
                            )
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </div>
                    )}
                    {/* {projectTrackingInfo && props.values.workOrderUpload && (
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          Preview work order
                        </label>
                        <div className="w-100 col">
                          <img
                            src={props.values.workOrderUpload}
                            alt="work order.jpg"
                          />
                        </div>
                      </div>
                    )} */}
                    {projectTrackingInfo && (
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3">
                          {props.values.workOrderUpload
                            ? "Preview work order"
                            : "Work order upload"}
                        </label>
                        <div className="w-100 col">
                          <CustomInput
                            name="workOrderUpload"
                            type="file"
                            onChange={(e) =>
                              uploadImage(
                                props,
                                "workOrderUpload",
                                e.target.files[0]
                              )
                            }
                          />

                          {props.touched.workOrderUpload &&
                            props.errors.workOrderUpload && (
                              <p className="text-danger">
                                {props.errors.workOrderUpload}
                              </p>
                            )}
                        </div>
                      </div>
                    )}

                    <hr />
                    <FieldArray name="taskList">
                      {({ push, remove }) =>
                        props.values?.taskList?.map((task, index) => (
                          <div
                            key={task.taskName}
                            className="row g-3 align-items-center"
                          >
                            <div className="col-lg">
                              <label className=" form-label input-group-link">
                                {task.taskName} schedule on
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  disabled={
                                    task.isLocked ||
                                    task.isProjectFinal ||
                                    task.isUpdated
                                  }
                                  type="date"
                                  name={`taskList[${index}].scheduleDate`}
                                />
                              </div>
                            </div>
                            <div className="col-lg">
                              <label className=" form-label input-group-link">
                                {task.taskName} completed on
                              </label>
                              <div className="w-100 col">
                                <CustomInput
                                  disabled={
                                    task.isLocked ||
                                    task.isProjectFinal ||
                                    task.isUpdated
                                  }
                                  type="date"
                                  name={`taskList[${index}].completionDate`}
                                />
                              </div>
                            </div>
                            <div className="col-lg">
                              <label className=" form-label input-group-link">
                                Responsible staff
                              </label>

                              <div className="w-100 col">
                                <CustomSelect
                                  type="text"
                                  disabled={
                                    task.isLocked ||
                                    task.isProjectFinal ||
                                    task.isUpdated
                                  }
                                  name={`taskList[${index}].responsibleStaff`}
                                >
                                  <option value="">Select</option>

                                  {resStaff &&
                                    resStaff?.map((staff) => {
                                      return (
                                        <option
                                          key={staff.id}
                                          value={`${staff?.firstName} ${staff?.lastName}`}
                                        >
                                          {`${staff?.firstName} ${staff?.lastName}`}
                                        </option>
                                      );
                                    })}
                                </CustomSelect>
                              </div>
                            </div>
                            <div className="col-lg mt-5">
                              <button
                                className="col-3 btn btn-primary mx-2"
                                disabled={
                                  task.isProjectFinal ||
                                  task.isUpdated ||
                                  task.isNotApplicable
                                }
                                style={{
                                  width: "100px",
                                  height: "30px",
                                }}
                                type="button"
                                onClick={() => {
                                  sendUpdate(task, props, index);
                                }}
                              >
                                Work Done
                              </button>
                              <button
                                className="col-3 btn btn-primary mx-2"
                                disabled={task.isUpdated || task.isProjectFinal}
                                style={{
                                  width: "50px",
                                  height: "30px",
                                }}
                                type="button"
                                onClick={() => {
                                  props.setFieldValue(
                                    `taskList[${index}].isNotApplicable`,
                                    !task.isNotApplicable
                                  );
                                }}
                              >
                                N/A
                              </button>
                            </div>

                            <hr />
                          </div>
                        ))
                      }
                    </FieldArray>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>

                      <div className="w-100 col">
                        <CustomTextArea name="notes" rows="10" />
                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Project Tracking" />

                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default EditProjectTracking;
