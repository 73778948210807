import React, { useEffect, useState } from "react";
import { useDrag, DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import performRequest from "../../api/request";
import LoadingPage from "../../Components/layouts/loading";
import { Toast } from "../../CustomComponent/toast";
import Footer from "../../Components/Footer/footer";
// updation
const LeadsTracking = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/leads/getAllFilteredLeads/`)
      .then((res) => {
        if (res.status === 201) {
          setData(res.data.leads);
        } else {
          Toast("error", "Error while getting Lead!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const moveLead = (leadId, newStatus) => {
    setLoading(true);
    performRequest("PATCH", `/leads/updateleads/${leadId}`, {
      lead_status: newStatus,
    })
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Lead updated successfully!");
          setData((prevData) => {
            const leadIndex = prevData.findIndex((lead) => lead.id === leadId);
            if (leadIndex !== -1) {
              const newData = [...prevData];
              newData[leadIndex].lead_status = newStatus;
              return newData;
            }
            return prevData;
          });
        } else {
          Toast("error", "Error while updating lead status");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }

  const statuses = [
    "hot",
    "cold",
    "warm",
    "hm done",
    "farm visit",
    "site inspection",
    "closed",
    "not interested",
  ];

  return (
    <div className="page">
      <div className="page-wrapper">
        <div style={{ minHeight: "80vh" }}>
          <div className="page-body">
            <DndProvider backend={HTML5Backend}>
              <div className="card">
                <div className="card-body">
                  <div
                    className="grid"
                    style={{
                      maxWidth: "100%",
                      height: "100%",
                      overflowY: "scroll",
                    }}
                  >
                    {statuses.map((status, index) => (
                      <div
                        key={index}
                        className="listName flex"
                        style={{
                          padding: "0em",
                          border: "1px solid #c4c4c4",
                        }}
                      >
                        <Section
                          status={status}
                          data={data}
                          moveLead={moveLead}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </DndProvider>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

const Section = ({ status, data, moveLead }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "lead",
    drop: (item) => moveLead(item.id, status),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const filteredData = data.filter(
    (lead) => lead.lead_status.toLowerCase() === status
  );

  return (
    <div
      className={` sort-name ${isOver ? "bg-slate-200" : ""}`}
      ref={drop}
      style={{
        cursor: "pointer",
        borderRadius: "0.2em",
        height: "100%",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.4)",
        width: "15em",
      }}
    >
      <Header status={status} count={filteredData.length} />

      <div style={{ padding: "0.5rem", minHeight: "100%" }}>
        {filteredData.map((lead) => (
          <Lead key={lead.id} lead={lead} />
        ))}
      </div>
    </div>
  );
};

const Header = ({ status, count }) => {
  const head = status.toUpperCase();
  return (
    <div>
      <h3
        style={{
          margin: "0em",
          paddingLeft: "0.5rem",
          background: "#000",
          color: "#fff",
        }}
      >
        {head} [{count}]
      </h3>
    </div>
  );
};

const Lead = ({ lead }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "lead",
    item: { id: lead.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const formattedDate = new Date(lead.createdAt).toLocaleString();

  return (
    <div ref={drag} className={`lead ${isDragging ? "dragging" : ""}`}>
      <div className="card" style={{ marginTop: "10px" }}>
        <div
          className="list-group list-group-flush overflow-auto"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "3px",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              padding: "0.5em",
              background: "#deeadc",
            }}
          >
            <div className="row">
              <div className="col-auto">
                <div
                  className="text-body d-block"
                  style={{ fontWeight: "bold" }}
                >
                  {lead.first_name} {lead.last_name}
                </div>
                <div className="text-body d-block">{lead.lead_alloted_to}</div>
                <div className="text-body d-block">{formattedDate}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsTracking;
