import React, { useState } from "react";
const Images = {
  aadharcardPhotoFront:
    "https://cdn.pixabay.com/photo/2022/11/09/00/44/aadhaar-card-7579588_1280.png",
  aadharcardPhotoBack:
    "https://i.pinimg.com/736x/30/7b/63/307b637a77b6bf02dd8b83768cfc2996.jpg",
  bankPassbook:
    "https://5.imimg.com/data5/ZX/DV/WM/GLADMIN-85845681/sbi-blank-passbook-500x500.jpg",
  residentialProof:
    "https://www.shutterstock.com/image-vector/indian-voter-id-card-vector-260nw-1754108780.jpg",

  offerLetterAcceptance:
    "https://www.ncertbooks.guru/wp-content/uploads/2021/02/Offer-Letter-Template.png",
  joiningLetterScan:
    "https://www.ncertbooks.guru/wp-content/uploads/2021/02/Letter-of-Appointment.png",

  pancardPhoto:
    "https://cdn.pixabay.com/photo/2022/11/09/00/45/pan-card-7579594_1280.png",
};
const InternalDocuments = () => {
  const [document, setDocument] = useState({
    name: "",
    url: "",
  });
  return (
    <div className="col-9">
      <div className="row row-deck row-cards">
        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              data-bs-toggle="modal"
              data-bs-target="#modal-scrollable"
              onClick={() =>
                setDocument({
                  name: "Offer Letter",
                  url: `${Images.offerLetterAcceptance}`,
                })
              }
              height={"150px"}
              src={Images.offerLetterAcceptance}
              className="card-img-top cursor-pointer"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Offer Letter</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              data-bs-toggle="modal"
              data-bs-target="#modal-scrollable"
              onClick={() =>
                setDocument({
                  name: "Pan Card",
                  url: `${Images.pancardPhoto}`,
                })
              }
              height={"150px"}
              src={Images.pancardPhoto}
              className="card-img-top cursor-pointer"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Pan Card</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              data-bs-toggle="modal"
              data-bs-target="#modal-scrollable"
              onClick={() =>
                setDocument({
                  name: "Bank Passbook",
                  url: `${Images.bankPassbook}`,
                })
              }
              height={"150px"}
              src={Images.bankPassbook}
              className="card-img-top cursor-pointer"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Bank Passbook</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              data-bs-toggle="modal"
              data-bs-target="#modal-scrollable"
              onClick={() =>
                setDocument({
                  name: "Joining Letter",
                  url: `${Images.joiningLetterScan}`,
                })
              }
              height={"150px"}
              src={Images.joiningLetterScan}
              className="card-img-top cursor-pointer"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Joining Letter</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <div className="card ">
            <img
              data-bs-toggle="modal"
              data-bs-target="#modal-scrollable"
              onClick={() =>
                setDocument({
                  name: "Residential proof",
                  url: `${Images.residentialProof}`,
                })
              }
              height={"150px"}
              src={Images.residentialProof}
              className="card-img-top cursor-pointer"
            />

            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <div>Residential Proof</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal modal-blur fade"
        id="modal-scrollable"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{document.name}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img src={document.url} alt="not uploaded" />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn  btn-secondary me-auto"
                data-bs-dismiss="modal"
              >
                CLOSE
              </button>

              <div className="btn-list justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalDocuments;
