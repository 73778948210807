import React, { useEffect, useState } from "react";
import LoadingPage from "../../../Components/layouts/loading";
import StepRoutes from "../../../Routes/steps_routes";
import { FieldArray, Form, Formik } from "formik";
import CustomInput from "../../../CustomComponent/custom_input";
import CustomSelect from "../../../CustomComponent/custom_select";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import { roundOffCost } from "../../../Components/Functions/amt.fun";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import { PurchaseOrderSchema } from "../../../Schemas/purchase/purchase_order.schema";
import { useNavigate, useParams } from "react-router-dom";
import { lineItemInfo } from "../../../SchemaInfo/info";
import { useSelector } from "react-redux";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { getId } from "../../../Components/Functions/word.fun";
const EditPurchaseOrder = () => {
  let userData = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);
  const { id } = useParams();
  const [data, setData] = useState();
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    performRequest("GET", "/siteInspection/getAllProjects")
      .then((res) => setProjects(res.data))
      .catch((err) => console.error(err));
  }, []);
  const handelSubmit = (values, actions) => {
    values = { ...values, generatedBy: userData.Name };
    let CompData = values?.lineItems?.map((item, index) => {
      return {
        ...item,
        totalWithGst:
          item.itemQuantity * item.itemPrice -
          (item.itemQuantity * item.itemPrice * item.discount) / 100 +
          ((item.itemQuantity * item.itemPrice -
            (item.itemQuantity * item.itemPrice * item.discount) / 100) *
            item.gstRate) /
            100,
        totalWithoutGst:
          item.itemQuantity * item.itemPrice -
          (item.itemQuantity * item.itemPrice * item.discount) / 100,
      };
    });
    values = { ...values, lineItems: CompData };
    setLoading(true);
    performRequest("PATCH", `/poDcPi/updatePoDcPi/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "PO updated successfully!");
          navigate(`/purchase-order/view/${id}`);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/poDcPi/getPoDcPiById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        } else {
          Toast("error", "Error while getting PO!");
        }
        setLoading(false);
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);
  useEffect(() => {
    performRequest("GET", "/poDcPi/getAllVendorList")
      .then((res) => setVendors(res.data))
      .catch((err) => console.error(err));
  }, []);
  const checkTouched = (obj) => {
    if (
      obj.deliveryDate == "" &&
      obj.gstRate == "" &&
      obj.itemPrice == "" &&
      obj.itemQuantity == "" &&
      obj.itemUnit == "" &&
      obj.notes == "" &&
      obj.particulars == ""
    ) {
      return false;
    } else {
      return true;
    }
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Purchase Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Edit Purchase Order</h2>
            </div>
            <Formik
              initialValues={data}
              validationSchema={PurchaseOrderSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Vendor purchase order for
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          type="text"
                          name="vendorPurchaseOrderFor"
                        />
                        {props.touched.vendorPurchaseOrderFor &&
                          props.errors.vendorPurchaseOrderFor && (
                            <p className="text-danger">
                              {props.errors.vendorPurchaseOrderFor}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Vendor purchase order date
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          type="date"
                          name="vendorPurchaseOrderDate"
                        />
                        {props.touched.vendorPurchaseOrderDate &&
                          props.errors.vendorPurchaseOrderDate && (
                            <p className="text-danger">
                              {props.errors.vendorPurchaseOrderDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Country of supply
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="countryOfSupply">
                          <option value="">Select Country</option>
                          <option value="india">India</option>
                        </CustomSelect>
                        {props.touched.countryOfSupply &&
                          props.errors.countryOfSupply && (
                            <p className="text-danger">
                              {props.errors.countryOfSupply}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Place of supply
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="text" name="placeOfSupply" />
                        {props.touched.placeOfSupply &&
                          props.errors.placeOfSupply && (
                            <p className="text-danger">
                              {props.errors.placeOfSupply}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Vendor
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="vendor">
                          <option value="">Select</option>
                          {vendors?.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.businessName}
                              </option>
                            );
                          })}
                        </CustomSelect>

                        {props.touched.vendor && props.errors.vendor && (
                          <p className="text-danger">{props.errors.vendor}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Project
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="project">
                          <option value="">Select</option>
                          {projects &&
                            projects?.map((pro) => {
                              return (
                                <option
                                  key={pro.cpNumber}
                                  value={getId(pro.cpNumber)}
                                >
                                  {pro.clientName}
                                </option>
                              );
                            })}
                        </CustomSelect>
                        {props.touched.project && props.errors.project && (
                          <p className="text-danger">{props.errors.project}</p>
                        )}
                      </div>
                    </div>
                    <hr />
                    <h3>Line Items :</h3>
                    <hr />
                    <FieldArray name="lineItems">
                      {({ push, remove }) => (
                        <div>
                          {props.values?.lineItems?.map((lineItem, index) => (
                            <div key={index}>
                              <div className="row g-3 align-items-center">
                                <div className="col-md">
                                  <label className=" form-label">
                                    Particulars:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type="text"
                                      name={`lineItems[${index}].particulars`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">Units</label>
                                  <div className="w-100 col">
                                    <CustomSelect
                                      type="text"
                                      name={`lineItems[${index}].itemUnit`}
                                    >
                                      <option value="">Select</option>
                                      <option value="nos">Nos</option>
                                      <option value="kg">Kg</option>
                                      <option value="grams">Grams</option>
                                      <option value="ltr">Litter</option>
                                      <option value="ml">ML</option>
                                      <option value="packs">Packs</option>
                                      <option value="sqm">SqM</option>
                                      <option value="sqft">SqFt</option>
                                    </CustomSelect>
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Quantity
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type={"text"}
                                      name={`lineItems[${index}].itemQuantity`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Item price
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type="text"
                                      name={`lineItems[${index}].itemPrice`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    GST (%)
                                  </label>
                                  <div className="w-100 col">
                                    <CustomSelect
                                      type="text"
                                      name={`lineItems[${index}].gstRate`}
                                    >
                                      <option value="">Select</option>
                                      <option value="0">0 %</option>
                                      <option value="5">5%</option>
                                      <option value="12">12%</option>
                                      <option value="18">18%</option>
                                      <option value="28">28%</option>
                                      <option value="100">100%</option>
                                    </CustomSelect>
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Delivery date
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      name={`lineItems[${index}].deliveryDate`}
                                      type="date"
                                    ></CustomInput>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-3 align-items-center">
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Discount
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      type={"text"}
                                      name={`lineItems[${index}].discount`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">Note</label>
                                  <div className="w-100 col">
                                    <CustomTextArea
                                      type={"text"}
                                      name={`lineItems[${index}].notes`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Amount (excl GST)
                                  </label>
                                  <div className="w-100 col">
                                    {roundOffCost(
                                      lineItem?.itemQuantity *
                                        lineItem?.itemPrice -
                                        (lineItem?.itemQuantity *
                                          lineItem?.itemPrice *
                                          lineItem?.discount) /
                                          100
                                    )}
                                  </div>
                                </div>
                                <div className="col-md">
                                  <label className=" form-label ">
                                    Amount (Inc GST):
                                  </label>
                                  <div className="w-100 col">
                                    {roundOffCost(
                                      lineItem?.itemQuantity *
                                        lineItem?.itemPrice -
                                        (lineItem?.itemQuantity *
                                          lineItem?.itemPrice *
                                          lineItem?.discount) /
                                          100 +
                                        ((lineItem?.itemQuantity *
                                          lineItem?.itemPrice -
                                          (lineItem?.itemQuantity *
                                            lineItem?.itemPrice *
                                            lineItem?.discount) /
                                            100) *
                                          lineItem?.gstRate) /
                                          100
                                    )}
                                  </div>
                                </div>
                                <div className="col-md">
                                  <button
                                    className="col-3 btn btn-primary"
                                    style={{
                                      width: "70px",
                                      height: "30px",
                                    }}
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))}

                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              if (props.values.lineItems.length == 0) {
                                push(lineItemInfo);
                              } else if (
                                props.values.lineItems.length > 0 &&
                                checkTouched(
                                  props.values.lineItems[
                                    props.values.lineItems.length - 1
                                  ]
                                )
                              ) {
                                push(lineItemInfo);
                              } else {
                                Toast(
                                  "error",
                                  "Please fill at least one line item field."
                                );
                              }
                            }}
                          >
                            Add Line Item
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Purchase Order" />

                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditPurchaseOrder;
