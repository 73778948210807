import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import StepRoutes from "../../Routes/steps_routes";
import { useSelector } from "react-redux";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import axios from "axios";

function generateUniqueFilename(file) {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 15);
  const extension = file.name.split(".").pop();
  return `${timestamp}_${randomString}.${extension}`;
}

const CallRecords = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const store = useSelector((store) => store.login.user);
  const [input, setInput] = useState({
    url: "",
    language: "",
  });

  useEffect(() => {
    const fetchRecordsWithUrls = async () => {
      setLoading(true);
      try {
        const res = await performRequest("GET", "/callRecord/getRecord/");
        if (res.status === 200) {
          const updatedData = await Promise.all(
            res.data.map(async (item) => {
              if (item.url) {
                const urlRes = await performRequest(
                  "GET",
                  `/fileUploads/getUploadedFile?filename=${item.url}`
                );
                if (urlRes.status === 200 && urlRes.data?.url) {
                  return { ...item, url: urlRes.data.url };
                }
              }
              return item;
            })
          );
          setData(updatedData);
        } else {
          Toast("error", "Error while getting records!");
        }
      } catch (err) {
        Toast("error", "Server Error!");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRecordsWithUrls();
  }, [refresh]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await performRequest(
        "POST",
        "/callRecord/createCallRecord/",
        input
      );
      if (res.status === 201) {
        Toast("success", "Call uploaded successfully!");
        setRefresh(!refresh);
        setInput({ url: "", language: "" });
      } else {
        Toast("error", "Error while uploading");
      }
    } catch (err) {
      Toast("error", "Server Error");
      console.error("Error", err);
    } finally {
      setLoading(false);
    }
  };

  const uploadImage = async (file) => {
    try {
      const uniqueFilename = generateUniqueFilename(file);

      const blobToFile = (blob, filename) => {
        return new File([blob], filename, {
          type: blob.type,
          lastModified: Date.now(),
        });
      };

      file = blobToFile(file, uniqueFilename);
      const res = await performRequest("POST", "/fileUploads/fileUpload", {
        fileName: file.name,
      });
      if (res?.data?.url) {
        const uploadRes = await axios.put(res?.data.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        if (uploadRes.status === 200) {
          setInput({ ...input, url: file.name });
        } else {
          setInput({ ...input, url: "" });
        }
      } else {
        setInput({ ...input, url: "" });
      }
    } catch (error) {
      console.error("Error fetching signed URL:", error);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl" style={{ minHeight: "80vh" }}>
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Demo Call Records</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Call Records</h3>
            </div>

            <div className="card-body">
              {store.Role === process.env.REACT_APP_ROLE_ADMIN_S && (
                <div className="mb-4 p-2">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Language of call recording
                      </label>

                      <div className="w-100 col">
                        <select
                          value={input.language}
                          onChange={(e) =>
                            setInput({ ...input, language: e.target.value })
                          }
                          className="form-select"
                          required
                        >
                          <option value="">Select</option>
                          <option value="english">English</option>
                          <option value="marathi">Marathi</option>
                          <option value="hindi">Hindi</option>
                        </select>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Upload call recording
                      </label>

                      <div className="w-100 col">
                        <input
                          required
                          className="form-control"
                          type="file"
                          onChange={(e) => uploadImage(e.target.files[0])}
                        />
                      </div>
                    </div>

                    <button
                      disabled={!input.url && !input.language}
                      className="btn btn-primary"
                      type="submit"
                    >
                      Upload Recording
                    </button>
                  </form>
                </div>
              )}
              <div className="grid">
                <div className="card g-col-lg-4 g-col-md-6 g-col-12">
                  <div className="card-status-top bg-red"></div>
                  <div className="card-header">
                    <h3 className="card-title">English</h3>
                  </div>
                  <div className="card-body p-0">
                    {data?.map((item) => {
                      if (item.language === "english" && item.url) {
                        return (
                          <div className="text-center w-100 my-2" key={item.id}>
                            <audio controls>
                              <source src={item.url} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="card g-col-lg-4 g-col-md-6 g-col-12">
                  <div className="card-status-top bg-green"></div>
                  <div className="card-header">
                    <h3 className="card-title">Hindi</h3>
                  </div>
                  <div className="card-body p-0">
                    {data?.map((item) => {
                      if (item.language === "hindi" && item.url) {
                        return (
                          <div className="text-center w-100 my-2" key={item.id}>
                            <audio controls>
                              <source src={item.url} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="card g-col-lg-4 g-col-md-6 g-col-12">
                  <div className="card-status-top bg-blue"></div>
                  <div className="card-header">
                    <h3 className="card-title">Marathi</h3>
                  </div>
                  <div className="card-body p-0">
                    {data?.map((item) => {
                      if (item.language === "marathi" && item.url) {
                        return (
                          <div className="text-center w-100 my-2" key={item.id}>
                            <audio controls>
                              <source src={item.url} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CallRecords;
