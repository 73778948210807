import React, { useEffect, useState } from "react";
import Footer from "../../../Kryzen Alpha App/Components/Footer/footer";
import StepRoutes from "../../../Kryzen Alpha App/Routes/steps_routes";
import ButtonWithSVG from "../../../Kryzen Alpha App/Components/layouts/button_with_svg";
import QutationMatrixTable from "./Quotation Matrix/quotation_matrix_table";
import performRequest from "../../../Kryzen Alpha App/api/request";
import { Toast } from "../../../Kryzen Alpha App/CustomComponent/toast";
import { FcPuzzle } from "react-icons/fc";

const QuotationMatrixStand = () => {
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", "/quotationMatrix/getQuotationMatrix/")
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data?.quotationMatrixCount / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcPuzzle className="fs-2  me-1" /> Quotation Matrix
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Base values that affect quotation
                  pricing. (All values in SQM)
                </h4>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                {<QutationMatrixTable data={data?.quotationMatrix} />}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default QuotationMatrixStand;
