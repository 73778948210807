import React from "react";
import HeaderMenu from "./header_menu";
import HeaderNavBar from "./header_nav_bar";
import Scrollbar from "../../Pages/scroll";

const Header = () => {
  return (
    <>
      <div className="sticky-top">
        <Scrollbar />
        <HeaderMenu />
        <HeaderNavBar />
      </div>
    </>
  );
};

export default Header;
