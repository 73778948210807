import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import { InsentiveInfo } from "../../../SchemaInfo/info";
import LoadingPage from "../../../Components/layouts/loading";
import { InsentiveSchema } from "../../../Schemas/sales/incentive_schema";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
const EditIncentive = () => {
  const [isDropdown, setIsDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [insentiveInfo, setInsentiveInfo] = useState(InsentiveInfo);

  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/incentive/getIncentiveById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setInsentiveInfo(res.data);
        } else {
          Toast("error", "Error while getting Incentive Information!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const handelSubmit = (values) => {
    values = {
      ...values,
    };
    setLoading(true);
    performRequest("PATCH", `/incentive/updateIncentive/${id}`, values)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Incentive updated successfully!");
          navigate(`/revenue-management/`);
        } else {
          Toast("error", "Error while updating incentive");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  const handleInputChange = (e, props) => {
    let { value } = e.target;
    value = value.trim();
    performRequest("GET", `/leads/getallleads/`)
      .then((res) => {
        if (res.status === 201) {
          let leads = res.data?.leads?.filter(
            (lead) =>
              lead.full_name.includes(value) || lead.first_name.includes(value)
          );
          setIsDropdown(leads);
          setShow(true);
        } else {
          Toast("error", "Error while getting Quotations!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      });
  };
  let searchTimer;
  function startSearch(e, props) {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => handleInputChange(e, props), 1000);
  }

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Revenue Management</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">Edit Sell</h2>
            </div>
            <Formik
              initialValues={insentiveInfo}
              validationSchema={InsentiveSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Select lead
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          type={"text"}
                          className="form-control"
                          onChange={(e) => {
                            startSearch(e, props);
                            props.setFieldValue("leadName", e.target.value);
                          }}
                          name="leadName"
                        ></CustomInput>
                        {show && (
                          <div
                            style={{ maxHeight: "300px", overflow: "scroll" }}
                          >
                            {isDropdown && isDropdown.length > 0
                              ? isDropdown?.map((lead) => {
                                  return (
                                    <span
                                      key={lead.id}
                                      onClick={() => {
                                        props.setFieldValue(
                                          "leadName",
                                          lead.full_name
                                        );
                                        setIsDropdown([]);
                                        setShow(false);
                                      }}
                                      className="card p-1 cursor-pointer"
                                    >
                                      {lead.full_name}
                                    </span>
                                  );
                                })
                              : show && (
                                  <span>No lead found with this name</span>
                                )}
                          </div>
                        )}

                        {props.touched.leadName && props.errors.leadName && (
                          <p className="text-danger">{props.errors.leadName}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Select product
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="product">
                          <option value="">select</option>
                          <option value="onlycea">
                            Any Only CEA (NVPH, Nethouse, Fanpad) / Only
                            Hydroponics sale
                          </option>
                          <option value="hydro1860">
                            Any CEA + Any Hydroponics - 1SqM to 1860SqM
                          </option>
                          <option value="hydro4046">
                            Any CEA + Any Hydroponics - 1861SqM to 4046SqM
                          </option>
                          <option value="hydro202304">
                            Any CEA + Any Hydroponics - 4046SqM to 20234SqM
                          </option>
                          <option value="hydro20234+">
                            Any CEA + Any Hydroponics - 20234SqM +
                          </option>
                          <option value="allother">
                            All other sales excluding CEA and Hydroponics
                          </option>
                        </CustomSelect>
                        {props.touched.product && props.errors.product && (
                          <p className="text-danger">{props.errors.product}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Pre gst amount
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="preGstAmount" />
                        {props.touched.preGstAmount &&
                          props.errors.preGstAmount && (
                            <p className="text-danger">
                              {props.errors.preGstAmount}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Sell description
                      </label>
                      <div className="w-100 col">
                        <CustomTextArea name="incentiveDescription" rows={5} />
                        {props.touched.incentiveDescription &&
                          props.errors.incentiveDescription && (
                            <p className="text-danger">
                              {props.errors.incentiveDescription}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Save Sell" />
                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default EditIncentive;
