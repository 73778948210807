import * as yup from "yup";

export const AgronomyWikiSchema = yup.object().shape({
  // image: yup.string(),
  // name: yup.string().required("Required"),
  // articleType: yup.string(),
  // classification: yup.string(),
  // scientificName: yup.string(),
  // description: yup.string(),
  // seedUrl: yup.string(),
  // daysToPipeTransfer: yup.number(),
  // nurseryWaterIntervalDays: yup.string(),
  // nurseryFertilizerIntervalDays: yup.string(),
  // minGrowingTemperature: yup.number(),
  // maxGrowingTemperature: yup.number(),
  // minGrowingHumidity: yup.number(),
  // maxGrowingHumidity: yup.number(),
  // waterPhRequirement: yup.string(),
  // waterTdsRequirement: yup.string(),
  // germinationDays: yup.string(),
  // seedlingDays: yup.string(),
  // vegetativeDays: yup.string(),
  // floweringDays: yup.string(),
  // canPrune: yup.boolean(),
  // possiblePestAttacks: yup.string(),
  // possiblePestControls: yup.string(),
  // possibleDiseases: yup.string(),
  // possibleDiseaseControls: yup.string(),
  name: yup.string().required("Required"),
  articleType: yup.string(),
  classification: yup.string(),
  subCategory: yup.string(),
  scientificName: yup.string(),
  description: yup.string(),
  seedUrl: yup.string(),
  seedlingDuration: yup.string(),
  minGrowingTemperature: yup.string(),
  maxGrowingTemperature: yup.string(),
  minGrowingHumidity: yup.string(),
  maxGrowingHumidity: yup.string(),
  minPh: yup.string(),
  maxPh: yup.string(),
  minTds: yup.string(),
  maxTds: yup.string(),
  seedGerminationDays: yup.string(),
  twoLeavesDays: yup.string(),
  canPrune: yup.boolean(),
  generalServingSize: yup.string(),
  image: yup.string(),
  proneToPowderyMildew: yup.boolean(),
  proneToDownyMildew: yup.boolean(),
  proneToFusariumWilt: yup.boolean(),
  proneToAnthracnose: yup.boolean(),
  proneToRootRot: yup.boolean(),
  proneToLeafSpot: yup.boolean(),
  proneToBotrytisRot: yup.boolean(),
  proneToBacterialLeafSpot: yup.boolean(),
  proneToMosaicVirus: yup.boolean(),
  proneToLeafCurleVirus: yup.boolean(),
  proneToClubRoot: yup.boolean(),
  productionPerPlantPerCycle: yup.boolean(),
  difficultyToGrowInWinter: yup.string(),
  difficultyToGrowInSpring: yup.string(),
  difficultyToGrowInSummer: yup.string(),
  difficultyToGrowInMonsoon: yup.string(),
  difficultyToGrowInAutumn: yup.string(),
  difficultyToGrowInPrewinter: yup.string(),
  favouriteOnly: yup.boolean(),
});
