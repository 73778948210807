import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import StepRoutes from "../../../Routes/steps_routes";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../../../Components/layouts/loading";
import { Toast } from "../../../CustomComponent/toast";
import performRequest from "../../../api/request";
import { FcPuzzle } from "react-icons/fc";

const ViewQuotationMatrix = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let { id } = useParams();
  useEffect(() => {
    setLoading(true);
    performRequest("GET", `/quotationMatrix/getQuotationMatrixById/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          console.log(data);
        } else {
          Toast("error", "Error while getting Expense!");
        }
        setLoading(false);
      })

      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcPuzzle className="fs-2  me-1" /> Quotation Matrix
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Base values that affect quotation
                  pricing.
                </h4>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-3">
                <p>
                  The pricing you enter will affect quotation pricing in
                  realtime.
                </p>
                <p>
                  Take backup of pricing before making any changes to quotation.
                </p>
                <p>
                  Only Super admins and admins can make changes to quotation
                  matrix.
                </p>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                <table class="table table-vcenter card-table table-striped">
                  <thead>
                    <tr>
                      <th>Specs</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && (
                      <tr>
                        <td class="text-secondary">Type of the System</td>
                        <td>{data?.type || "N/A".toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">
                          Protected Cultivation Factor
                        </td>
                        <td>{data?.factor || "N/A".toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">0 - 190 SqM Base Price</td>
                        <td>{data?.basePrice1to190 || "N/A".toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">191 - 440 SqM Base Price</td>
                        <td>
                          {data?.basePrice191to450 || "N/A".toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">441 - 940 SqM Base Price</td>
                        <td>
                          {data?.basePrice451to950 || "N/A".toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">
                          941 - 1940 SqM Base Price
                        </td>
                        <td>
                          {data?.basePrice951to1860 || "N/A".toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">
                          1941 - 3940 SqM Base Price
                        </td>
                        <td>
                          {data?.basePrice1861to3720 || "N/A".toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">
                          3941 - 5940 SqM Base Price
                        </td>
                        <td>
                          {data?.basePrice3721to5999 || "N/A".toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">
                          5941 - 7940 SqM Base Price
                        </td>
                        <td>
                          {data?.basePrice6000to7999 || "N/A".toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">7941+ SqM Base Price</td>
                        <td>
                          {data?.basePrice8000Plus || "N/A".toUpperCase()}
                        </td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">Weedmat cost per SqM</td>
                        <td>{data?.sqMtrWeed || "N/A".toUpperCase()}</td>
                      </tr>
                    )}
                    {data && (
                      <tr>
                        <td class="text-secondary">Additional Cost (Markup)</td>
                        <td>{data?.markupPercentGP || "N/A".toUpperCase()}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="card-footer">
                  <div className="btn-list justify-content-end">
                    <SimpleButton
                      buttonText="Edit Quotation Matrix"
                      href={`/standard-quotation/edit/${id}`}
                    />
                    <SimpleButton buttonText="Cancel" type="secondary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ViewQuotationMatrix;
