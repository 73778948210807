import * as yup from "yup";

export const timeLinesWaterManagementSchema = yup.object().shape({
  phReading: yup.string().required("PH reading is required"),
  ecReading: yup.string().required("EC reading is required"),
  scheduleDate: yup.string(),
});

export const WaterManagementCreateSchema = yup.object().shape({
  cycleName: yup.string().required("Required"),
  typeOfSystem: yup.string().required("Required"),
  waterTankCapacity: yup.string().required("Required"),
  startingPh: yup.string().required("Required"),
  startingEc: yup.string().required("Required"),
  waterSource: yup.string().required("Required"),
  timeLines: yup.array().of(timeLinesWaterManagementSchema),
});
