import * as yup from "yup";
export const QuotationSchema = yup.object().shape({
  genrated_by: yup.string(),
  quotationDate: yup
    .date()
    .min(new Date(1950, 0, 1), "quotationDate must be after January 1, 1950")

    .required("Required"),
  costMarkupPercent: yup.number().min(0, "must be greter than or equal 0"),
  overrideUnitCost: yup.number().min(0, "must be greter than or equal 0"),
  maxDiscount: yup.number().min(0, "must be greater than or equal 0"),
  bay_size: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf(
      [
        8,
        12,
        16,
        20,
        24,
        28,
        32,
        36,
        40,
        44,
        48,
        52,
        56,
        60,
        64,
        64,
        68,
        72,
        76,
        80,
        84,
        88,
        92,
        96,
        100,
        104,
        108,
        112,
        116,
        120,
        124,
        128,
        132,
        136,
        140,
        144,
        null,
      ],
      "Invalid Type"
    )
    .required("Required"),
  span_size: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf(
      [
        8,
        12,
        20,
        28,
        36,
        44,
        52,
        60,
        68,
        76,
        84,
        92,
        100,
        108,
        116,
        124,
        132,
        140,
        148,
        156,
        164,
        172,
        180,
        188,
        196,
        204,
        212,
        220,
        228,
        236,
        244,
        252,
        260,
        268,
        276,
        null,
      ],
      "Invalid Type"
    )
    .required("Required"),
  quotationType: yup
    .string()
    .required("Required")
    .oneOf(
      [
        "fanpad",
        "nvph",
        "nethouse",
        "fanpad with hydroponics",
        "nvph with hydroponics",
        "nethouse with hydroponics",
        "hydroponics only",
        "hydrobuild xs",
        "hydrobuild mini",
        "hydrobuild pro",
        null,
      ],
      "Invalid Type"
    ),
  a_FramePercent: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf([0, 25, 50, 75, 100, null], "Invalid Type"),

  flatBedPercent: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf([0, 25, 50, 75, 100, null], "Invalid Type"),

  growBagPercent: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf([0, 25, 50, 75, 100, null], "Invalid Type"),

  dutchBucketPercent: yup
    .number()
    .min(0, "must be greter than or equal 0")
    .oneOf([0, 25, 50, 75, 100, null], "Invalid Type"),

  farmType: yup.string().oneOf(["indoor", "outdoor", null], "Invalid Type"),

  businessName: yup.string(),
  contactPersonName: yup.string().required("Required"),
  phoneNumber: yup.string().required("Required"),
  whatsAppNumber: yup.string(),
  contactPersonEmail: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
  gstNumber: yup.string(),

  // .matches(
  //   /^([0-9]{2})([A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9]{1})$/,
  //   "GST number must be in the format 00AAAAA1234A0Z0"
  // ),
  address: yup.string().required("Required"),
  currencyCode: yup.string().oneOf(["INR", null], "Invalid Type"),
  showProjectFinancials: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  finalQuotation: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  notes: yup.string().min(10, "must be at least 10 characters"),
});
