import React from "react";

import hm2 from "../../../certificates/hm2.png";
const TrackingOfProgress = () => {
  return (
    <div>
      <h3 className="table-heading">Tracking of Progress:</h3>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "30%" }}>Item</th>
            <th>Description / Specification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td> alpha.kryzen.com</td>
            <td>
              Once KBPL receives work order from you. Your client portal access
              is created by us. At client portal you get access to timeline of
              development, team working on your project, site inspection
              details, delivery of goods, track of progress, information of
              agronomist, etc.
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          display: "flex",

          justifyContent: "center",
          width: "100%",
          marginTop: "12px",
        }}
      >
        <img src={hm2} width={"80%"} alt="kryzenCertificates" />
      </div>
      <div
        className="html2pdf__page-break"
        style={{ pageBreakAfter: "always", display: "block", clear: "both" }}
      ></div>
    </div>
  );
};

export default TrackingOfProgress;
