import * as yup from "yup";
export const deliveryLineItemSchema = yup.object().shape({
  particulars: yup.string(),
  itemUnit: yup.string(),
  itemQuantity: yup.string(),
});

export const DeliveryChallanSchema = yup.object().shape({
  project: yup.string().required("Required"),
  pdfStatus: yup.boolean(),
  countryOfSupply: yup.string(),
  placeOfSupply: yup.string(),
  lrNumber: yup.string(),
  ewayBillNumber: yup.string(),
  transportName: yup.string(),
  vehicleNumber: yup.string(),
  driverName: yup.string(),
  driverPhoneNumber: yup.string(),
  generatedBy: yup.string(),
  challanDate: yup.date(),
  lineItems: yup.array().of(deliveryLineItemSchema),
});
