import React, { useEffect, useRef, useState } from "react";
import UAParser from "ua-parser-js";
import { WebcamCapture } from "./webcam";
import SimpleButton from "../../Components/layouts/simple_button";
import { useDispatch, useSelector } from "react-redux";
import performRequest from "../../api/request";
import { Toast } from "../../CustomComponent/toast";
import { useNavigate } from "react-router";
import axios from "axios";
import { finalOfficeLogout } from "../../redux/user/loginReducer";
import Cookies from "js-cookie";
import { getUplodedImage } from "../../CustomComponent/upload_Image";
import ButtonWithSVG from "../../Components/layouts/button_with_svg";
const cookielog = Cookies.get("userlog") || null;
let userDatalog = null;
if (cookielog) {
  userDatalog = JSON.parse(cookielog);
}
function parseUserAgent(userAgentString) {
  const parser = new UAParser();
  const userAgentData = parser.setUA(userAgentString).getResult();

  const browser = userAgentData.browser.name;
  const browserVersion = userAgentData.browser.version;
  const operatingSystem = userAgentData.os.name;
  const renderingEngine = userAgentData.engine.name;
  const compatibility =
    userAgentData.engine.name + "/" + userAgentData.engine.version;

  return {
    browser: browser,
    version: browserVersion,
    operatingSystem: operatingSystem,
    renderingEngine: renderingEngine,
    compatibility: compatibility,
  };
}
function timeDifference(time1, time2) {
  // Parse the times using the Date object
  const parseTime = (time) => {
    const [timePart, period] = time.split(" ");
    const [hours, minutes, seconds] = timePart.split(":");
    let hrs = parseInt(hours, 10);
    const mins = parseInt(minutes, 10);
    const secs = parseInt(seconds, 10);

    if (period === "PM" && hrs !== 12) {
      hrs += 12;
    } else if (period === "AM" && hrs === 12) {
      hrs = 0;
    }

    return hrs * 3600 + mins * 60 + secs;
  };

  // Convert the times to seconds since start of the day
  const seconds1 = parseTime(time1);
  const seconds2 = parseTime(time2);

  // Calculate the difference in seconds
  let diffSeconds = seconds1 - seconds2;

  // If the difference is negative, adjust by adding 24 hours
  if (diffSeconds < 0) {
    diffSeconds += 24 * 3600;
  }

  // Convert the difference back to hours, minutes, and seconds
  const hours = Math.floor(diffSeconds / 3600);
  const minutes = Math.floor((diffSeconds % 3600) / 60);
  const seconds = diffSeconds % 60;

  // Format the result as hh:mm:ss
  return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}
const getTime = () => {
  const date = new Date();
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
  });
  return formattedTime;
};
const getDate = () => {
  const date = new Date();
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
  return formattedDate;
};
const AttendanceLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store) => store?.login?.user);
  const [userLocation, setUserLocation] = useState(null);
  const [img, setImg] = useState(null);
  const [os, setOS] = useState(navigator.platform);
  const [ip, setIp] = useState("");
  const [userAgent, setUserAgent] = useState(navigator.userAgent);
  const [loginTime, setLoginTime] = useState();
  const [employeeData, setEmployeeData] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState({
    date: getDate(),
    time: getTime(),
  });

  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    if (img) {
      getUplodedImage(setImageUrls, img, "img");
    }
  }, [img]);

  useEffect(() => {
    if (user?.UserId) {
      performRequest("GET", `/login/getUserById/${user?.UserId}`)
        .then((res) => {
          setEmployeeData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);
  useEffect(() => {
    if (user.UserId) {
      performRequest("POST", `/attendance/getUserAttendance/`, {
        userId: user.UserId,
        date: new Date().toLocaleDateString(),
      })
        .then((res) => {
          if (res.status === 201) {
            if (res?.data?.attendance?.loginType === "logout") {
              Toast("success", `Your Attendance has already been taken!`);

              dispatch(finalOfficeLogout());
              navigate("/account-login");
            } else {
              setLoginTime(res.data?.attendance?.time);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setUserLocation({ latitude, longitude });
    });
  }, []);

  useEffect(() => {
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        const ip = data.ip;
        setIp(ip);
      });
  }, []);

  const downloadImage = async (image) => {
    try {
      const response = await axios.get(image, { responseType: "blob" });
      return response.data;
    } catch (error) {
      console.error("Error downloading the image:", error);
      return null;
    }
  };

  const uploadImage = async (image) => {
    const file0 = await downloadImage(image);

    // Function to convert Blob to File
    function blobToFile(blob, filename) {
      return new File([blob], filename, {
        type: blob.type,
        lastModified: Date.now(),
      });
    }

    // Function to generate a unique filename
    function generateUniqueFilename(baseName, extension) {
      const timestamp = Date.now();
      const randomString = Math.random().toString(36).substring(2, 15);
      return `${baseName}_${timestamp}_${randomString}.${extension}`;
    }
    // Convert the Blob to a File with a unique name
    const uniqueFilename = generateUniqueFilename("example", "jpg");
    const file = blobToFile(file0, uniqueFilename);

    try {
      const res = await performRequest("POST", "/fileUploads/fileUpload", {
        fileName: file.name,
      });
      if (res?.data?.url) {
        let data = await axios.put(res?.data.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        if (data.status === 200) {
          setImg(file.name);
        } else {
          // props.setFieldValue(key, "");
        }
      } else {
        // props.setFieldValue(key, "");
      }
    } catch (error) {
      console.error("Error fetching signed URL:", error);
    }
  };

  const handelSubmit = () => {
    let data = {
      photo: img,
      time: currentDateTime.time,
      date: currentDateTime.date,
      operatingSystem: os,
      firstLogin: "true",
      ipAddress: ip,
      loginType: "logout",
      browser: parseUserAgent(userAgent).browser,
      userLocation: userLocation,
      userId: user.UserId,
      userName: user.Name,
      loginStatus: "",
    };
    performRequest("POST", `/attendance/createLogoutAttendance/`, data)
      .then((res) => {
        if (res.data.msg && res.data.msg === "Attendance already taken") {
          Toast("success", `Your Attendance has already been taken!`);

          dispatch(finalOfficeLogout());
          navigate("/account-login");
        } else {
          dispatch(finalOfficeLogout());
          Toast("success", `Your Attendance has been created!`);
          navigate("/account-login");
        }
      })
      .catch((err) => {
        Toast("error", `Server error!`);
        console.error(err);
      });
  };

  const getDisabledStatus = () => {
    if (img) {
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   if (!store?.isLoggedIn) {
  //     navigate("/account-login");
  //   } else if (store.isLoggedIn && store.userlog && store.userlog.firstLogin) {
  //     navigate("/dashboard");
  //   }
  // }, [store]);

  return (
    <div className="page page-center">
      <div className="container py-4 ">
        <div className="card">
          <h2 className="h2 text-center mt-4">Attendance Dashboard</h2>
          <div className="card-body">
            <div className="container-tight">
              {img ? (
                <div className="text-center">
                  <div className=" text-center">
                    <img src={imageUrls?.img} alt={"User Img"} />
                  </div>
                  <SimpleButton
                    onClick={() => setImg(null)}
                    buttonText={img ? "Retake" : "Capture photo"}
                  />
                </div>
              ) : (
                <WebcamCapture img={img} uploadImage={uploadImage} />
              )}

              <div className="mt-3 text-center">
                <h3 className="text-muted">
                  LoggedOut Date & Time:{" "}
                  <span className="text-dark ms-2">
                    {currentDateTime.date} , {currentDateTime.time}
                  </span>
                </h3>
              </div>
              <div>
                <h3 className="text-muted text-center">
                  Ip Address & Operating System:{" "}
                  <span className="text-dark ms-2">
                    {ip} , {os}
                  </span>
                </h3>
              </div>

              {currentDateTime?.time && loginTime && (
                <div className="text-center">
                  <h3 className="text-muted">
                    Total Time Duration
                    <span className="text-dark ms-2">
                      {timeDifference(currentDateTime.time, loginTime)} Hours
                    </span>
                  </h3>
                </div>
              )}
            </div>
            <div className="text-center">
              <span className="me-4">
                <SimpleButton buttonText={"cancel"} type="secondary" />
              </span>

              <button
                disabled={getDisabledStatus()}
                onClick={handelSubmit}
                className="btn btn-primary mx-1 mt-2 initialism"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceLogout;
