import * as yup from "yup";

export const SiteInspectionSchema = yup.object().shape({
  projectId: yup.number(),
  inspectionDate: yup.date(),
  tentativeStartDate: yup.date(),
  generatedBy: yup.string(),
  siteAddress: yup.string().required("Required"),
  sitePinCode: yup.string().required("Required"),
  siteManagerName: yup.string(),
  siteManagerPhone: yup.string(),
  quotationNumber: yup.string(),
  numberOfStaff: yup.number(),
  typeOfSite: yup
    .string()
    .oneOf(
      [
        "farmLand",
        "naPlot",
        "indoor",
        "underConstructionSite",
        "commercialSite",
        "governmentPlot",

        null,
      ],
      "invalid type"
    ),
  soilType: yup
    .string()
    .oneOf(
      [
        "blackSoil",
        "redSoil",
        "dusty",
        "concrete",
        "sand",
        "vertisol",
        "stoned",
        "hardToFind",

        null,
      ],
      "invalid type"
    ),
  totalAvailableAreaUnit: yup
    .string()
    .oneOf(
      [
        "acre",
        "hectare",
        "guntha",
        "cent",
        "sqft",
        "sqmt",
        "yard",
        "bigha",
        null,
      ],
      "Invalid type"
    ),
  totalAvailableAreaNumber: yup.number(),
  landOwnership: yup
    .string()
    .oneOf(
      [
        "own",
        "rent",
        "leased",
        "partnership",
        "planning to purchase",
        "planning to lease",
      ],
      "Invalid type"
    ),

  landLeveling: yup
    .string()
    .oneOf(["required", "alreadyDone", "hardToFindOut"], "Invalid type"),

  naturalSlopeOfLand: yup
    .string()
    .oneOf(
      [
        "north",
        "south",
        "east",
        "west",
        "northEast",
        "northWest",
        "southEast",
        "southWest",
        "unclear",
        "hardToFind",

        null,
      ],
      "invalid type"
    ),
  //land
  landHasTree: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  landHasStones: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  landHasPond: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  landHasMudPatch: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  landHasHillock: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  landFencingType: yup
    .string()
    .oneOf(
      [
        "noFencing",
        "stoned",
        "cementPoll",
        "cementWall",
        "preFab",
        "wired",
        "makeShift",
        null,
      ],
      "Invalid type"
    ),

  landFencingHeightInFeet: yup.number(),
  whatsOnNorth: yup
    .string()
    .oneOf(
      [
        "ownLand",
        "landBelongsToSomeoneElse",
        "waterBody",
        "road",
        "fencing",
        "wall",
        "slopeLand",
        "disputedLand",
        "governmentLand",

        "other",
        null,
      ],
      "invalid type"
    ),
  whatsOnEast: yup
    .string()
    .oneOf(
      [
        "ownLand",
        "landBelongsToSomeoneElse",
        "waterBody",
        "road",
        "fencing",
        "wall",
        "slopeLand",
        "disputedLand",
        "governmentLand",
        "other",

        null,
      ],
      "invalid type"
    ),
  whatsOnSouth: yup
    .string()
    .oneOf(
      [
        "ownLand",
        "landBelongsToSomeoneElse",
        "waterBody",
        "road",
        "fencing",
        "wall",
        "slopeLand",
        "disputedLand",
        "governmentLand",
        "other",

        null,
      ],
      "invalid type"
    ),
  whatsOnWest: yup
    .string()
    .oneOf(
      [
        "ownLand",
        "landBelongsToSomeoneElse",
        "waterBody",
        "road",
        "fencing",
        "wall",
        "slopeLand",
        "disputedLand",
        "governmentLand",
        "other",

        null,
      ],
      "invalid type"
    ),
  obstaclesTowardsSky: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  //photoFunction
  obstaclesTowardsSkyPhoto: yup.mixed(),
  googleMapsLocationLink: yup.string().required("Required"),

  // water
  waterSourceType: yup
    .string()
    .oneOf(
      [
        "borewell",
        "well",
        "municipalWater",
        "pond",
        "rainHarvesting",
        "tanker",

        null,
      ],
      "invalid type"
    ),
  waterTankCapacityInLitre: yup.string(),
  waterAvailabilityFrequency: yup
    .string()
    .oneOf(
      [
        "uninterrupted",
        "onDemand",
        "onAvailabilityOfElectricity",
        "onceAday",
        "onceAweek",
        "onceAmonth",

        null,
      ],
      "invalid type"
    ),
  positionOfWaterTank: yup
    .string()
    .oneOf(
      [
        "north",
        "south",
        "east",
        "west",
        "northEast",
        "northWest",
        "southEast",
        "southWest",
        "unclear",

        null,
      ],
      "invalid type"
    ),
  //photoFunction
  waterTankLocationPhoto: yup.mixed(),
  waterPhReading: yup.string(),
  waterEcReading: yup.string(),
  //photoFunction
  waterReportPhoto: yup.mixed(),

  // sunlight
  entryToFarmSite: yup
    .string()
    .oneOf(
      [
        "north",
        "south",
        "east",
        "west",
        "northEast",
        "northWest",
        "southEast",
        "southWest",
        "unclear",

        null,
      ],
      "invalid type"
    ),
  //photoFunction
  entryTofarmPhoto: yup.mixed(),

  luxReading: yup.string(),
  luxReadingPhoto: yup.mixed(),
  luxDeffusionSheet: yup.string(),
  luxDeffusionSheetPhoto: yup.mixed(),
  luxWovenPoly: yup.string(),
  luxWovenPolyPhoto: yup.mixed(),
  luxUvOpen: yup.string(),
  luxUvOpenPhoto: yup.mixed(),

  // climate
  temperatureReading: yup.string(),
  temperatureReadingPhoto: yup.mixed(),
  humidityReading: yup.string(),
  humidityReadingPhoto: yup.mixed(),
  climateHottestMonth: yup
    .string()
    .oneOf(
      [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",

        null,
      ],
      "invalid type"
    ),
  climateColdestMonth: yup
    .string()
    .oneOf(
      [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
        null,
      ],
      "invalid type"
    ),

  // dimensions
  northSouthMeasurmentInMeter: yup.string(),
  northSouthMeasurmentPhoto: yup.mixed(),
  eastWestMeasurmentInMeter: yup.string(),
  eastWestMeasurmentPhoto: yup.mixed(),
  layoutPhoto: yup.mixed(),

  // Facilities
  facilityToStoreItems: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  facilityToStay: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  facilityHasBathroom: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  facilityHasDrinkingWater: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  facilityHasKitchenSpace: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  facilityHasFuelForCooking: yup
    .boolean()

    .oneOf([true, false], "Invalid type"),
  transportOption: yup.string().oneOf(["byRoad", "byWater"], "Invalid type"),

  nearestTarRoad: yup
    .string()
    .oneOf(
      [
        "0km To 1km",
        "1km To 3km",
        "3km To 5km",
        "5km To 20km",
        "20km To 50km",
        null,
      ],
      "Invalid type"
    ),

  nearestMarket: yup
    .string()
    .oneOf(
      [
        "0km To 1km",
        "1km To 3km",
        "3km To 5km",
        "5km To 20km",
        "20km To 50km",
        null,
      ],
      "Invalid type"
    ),

  nearestMedicalFacility: yup
    .string()
    .oneOf(
      [
        "0km To 1km",
        "1km To 3km",
        "3km To 5km",
        "5km To 20km",
        "20km To 50km",
        null,
      ],
      "Invalid type"
    ),

  nearestStayFacility: yup
    .string()
    .oneOf(
      [
        "0km To 1km",
        "1km To 3km",
        "3km To 5km",
        "5km To 20km",
        "20km To 50km",
        null,
      ],
      "Invalid type"
    ),

  nearestBusStand: yup.string(),
  nearestRailwayStation: yup.string(),
  nearestAirport: yup.string(),
  regulerModeOfTransportation: yup
    .string()
    .oneOf(
      ["ownVehicle", "privateVehicle", "governmentVehicle", "train", null],
      "Invalid type"
    ),

  vehicleAvilableForEmergency: yup
    .string()
    .oneOf(
      ["unavailable", "2 wheeler", "3 wheeler", "4 wheeler"],
      "Invalid type"
    ),

  electricityConnectionAvailable: yup
    .boolean()
    .oneOf([true, false], "Invalid type"),
  electricityMeterType: yup
    .string()
    .oneOf(
      [
        "residential",
        "commercial",
        "agricultural",
        "solar",
        "industrial",
        "freeUse",

        null,
      ],
      "invalid type"
    ),
  electricityMeterPhaseType: yup
    .string()
    .oneOf(["singlePhase", "threePhase", "both"], "Invalid type"),

  electricityOutageFrequency: yup
    .string()
    .oneOf(
      [
        "never",
        "sometimes",
        "occasionally",
        "lowFrequency",
        "highFrequency",
        null,
      ],
      "Invalid type"
    ),
  electricityUnitPrice: yup.number(),
  electricityPricingModel: yup
    .string()
    .oneOf(["uniform", "bracketed", "subsidised"], "Invalid type"),

  electricityUsage: yup
    .string()
    .oneOf(
      [
        "hydroponicsOnly",
        "sharedWithFactory",
        "sharedWithHome",
        "sharedWithFarming",

        null,
      ],
      "invalid type"
    ),
  internetConnectivity: yup
    .string()
    .oneOf(["noInternet", "broadband", "portableHotspot"], "Invalid type"),

  descriptionVideo: yup.string(),
  notes: yup.string(),
  salesRelationshipManager: yup.string().required("Required"),
  lookingForGovernmentSupport: yup.string(),
  projectOwnerGender: yup.string(),
  location: yup.string(),
  cast: yup.string(),
});
