import React from "react";
import FooterCopyright from "./footer_copyright";

const Footer = () => {
  return (
    <div className="mt-4">
      <FooterCopyright />
    </div>
  );
};

export default Footer;
