// import React, { useEffect, useState } from "react";
// import Footer from "../../Components/Footer/footer";
// import ButtonWithSVG from "../../Components/layouts/button_with_svg";
// import StepRoutes from "../../Routes/steps_routes";
// import performRequest from "../../api/request";
// import { RotatingLines } from "react-loader-spinner";
// import { Toast } from "../../CustomComponent/toast";
// import Pagination from "../../Components/layouts/pagination";
// import getFilteredURL from "../../Components/Functions/filters.getUrl";
// import DynamicFilterInput from "../../Components/layouts/filter.inpit";
// import DynamicFilterSelect from "../../Components/layouts/filter.select";
// import LoadingPage from "../../Components/layouts/loading";
// const EmployeeLeaveManagement = () => {
//   const [loading, setLoading] = useState(false);
//   const [refresh, setRefresh] = useState(false);
//   const [leadOwner, setLeadOwner] = useState([]);
//   const [filters, setFilters] = useState({
//     startdate: "",
//     enddate: "",
//     leadname: "",
//     leadowner: "",
//   });
//   const [page, setPage] = useState(1);
//   const [showPage, setShowPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     setLoading(true);
//     // /employeeManagement/getEmployeeManagement
//     performRequest("GET", getFilteredURL("/leads/getleads", page, filters))
//       .then((res) => {
//         if (res.status === 200) {
//           setData(res.data);
//           setTotalPages(Math.ceil(res.data?.leadCount / 50));
//         } else {
//           Toast("error", "Error while getting Leads!");
//         }
//       })
//       .catch((err) => {
//         Toast("error", "Server Error!");
//         console.error(err);
//       })
//       .finally(() => setLoading(false));
//   }, [refresh, page]);
//   useEffect(() => {
//     performRequest("GET", "/leads/getAllLeadsOwners")
//       .then((res) => setLeadOwner(res.data))
//       .catch((err) => console.error(err));
//   }, []);

//   if (loading) {
//     return <LoadingPage />;
//   }

//   return (
//     <div className="page">
//       <div className="page-wrapper">
//         <div style={{ minHeight: "80vh" }}>
//           <div className="page-header d-print-none">
//             <div className="container-xl">
//               <div className="row g-2 align-items-center">
//                 <div className="col">
//                   <h2 className="page-title mb-1">Employee leave management</h2>
//                   <h4 className="text-muted">
//                     <StepRoutes />
//                   </h4>
//                 </div>
//                 <div className="col-auto ms-auto d-print-none">
//                   <div className="d-flex">
//                     <ButtonWithSVG
//                       href={"employee-leave-management/new-leave"}
//                       buttonText="Get Leaves"
//                       svg={
//                         <svg
//                           xmlns="http://www.w3.org/2000/svg"
//                           className="icon"
//                           width="24"
//                           height="24"
//                           viewBox="0 0 24 24"
//                           strokeWidth="2"
//                           stroke="currentColor"
//                           fill="none"
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                         >
//                           <path stroke="none" d="M0 0h24v24H0z" fill="none" />
//                           <path d="M12 5l0 14" />
//                           <path d="M5 12l14 0" />
//                         </svg>
//                       }
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="page-body">
//             <div className="container-xl">
//               <div className="card">
//                 <div className="row g-3 m-2 mb-0">
//                   <DynamicFilterInput
//                     label="Start Date:"
//                     value={filters.startdate}
//                     onChange={(e) =>
//                       setFilters({
//                         ...filters,
//                         startdate: e.target.value,
//                       })
//                     }
//                     type="date"
//                   />
//                   <DynamicFilterInput
//                     label="End Date:"
//                     value={filters.enddate}
//                     onChange={(e) =>
//                       setFilters({ ...filters, enddate: e.target.value })
//                     }
//                     type="date"
//                   />
//                   <DynamicFilterInput
//                     label="Search name:"
//                     value={filters.leadname}
//                     onChange={(e) =>
//                       setFilters({ ...filters, leadname: e.target.value })
//                     }
//                     placeholder="search name..."
//                   />
//                   <DynamicFilterSelect
//                     label="Search lead owner:"
//                     onChange={(e) =>
//                       setFilters({
//                         ...filters,
//                         leadowner: e.target.value,
//                       })
//                     }
//                     value={filters.leadowner}
//                   >
//                     <option value="">select lead</option>
//                     {leadOwner &&
//                       leadOwner?.map((lead) => {
//                         return (
//                           <option key={lead?.userName} value={lead?.userName}>
//                             {lead?.userName}
//                           </option>
//                         );
//                       })}
//                   </DynamicFilterSelect>
//                   <div className="col-md">
//                     <div className="d-flex">
//                       <button
//                         onClick={() => setRefresh(!refresh)}
//                         style={{
//                           height: "28px",
//                           marginRight: "5px",
//                         }}
//                         className="btn btn-primary mt-3"
//                       >
//                         Search
//                       </button>
//                       <button
//                         onClick={() => {
//                           setFilters({
//                             startdate: "",
//                             enddate: "",
//                             leadname: "",
//                             leadowner: "",
//                           });
//                           setRefresh(!refresh);
//                         }}
//                         style={{ height: "28px" }}
//                         className="btn btn-primary mt-3"
//                       >
//                         Reset
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="card-body border-bottom py-3">
//                   <div className="d-flex">
//                     <div></div>
//                   </div>
//                 </div>
//                 {/* {data && <AllLeadsTable leads={data?.leads} />} */}
//                 <Pagination
//                   page={page}
//                   setPage={setPage}
//                   totalPages={totalPages}
//                   pageDatalength={data?.leads?.length}
//                   showPage={showPage}
//                   setShowPage={setShowPage}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default EmployeeLeaveManagement;
import React, { useEffect, useState } from "react";
import Footer from "../../../Kryzen Alpha App/Components/Footer/footer";
import ButtonWithSVG from "../../../Kryzen Alpha App/Components/layouts/button_with_svg";
import StepRoutes from "../../../Kryzen Alpha App/Routes/steps_routes";
import performRequest from "../../../Kryzen Alpha App/api/request";
import { Toast } from "../../../Kryzen Alpha App/CustomComponent/toast";
import Pagination from "../../../Kryzen Alpha App/Components/layouts/pagination";
import getFilteredURL from "../../../Kryzen Alpha App/Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../../Kryzen Alpha App/Components/layouts/filter.inpit";
import DynamicFilterSelect from "../../../Kryzen Alpha App/Components/layouts/filter.select";
import LoadingPage from "../../../Kryzen Alpha App/Components/layouts/loading";
import LeavesTrackingTable from "./Employee Leave Management/leaves_table";
import { useSelector } from "react-redux";
import { BsCalendar2XFill } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";

const EmployeeLeaveManagement = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [leadOwner, setLeadOwner] = useState();
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    employee: "",
  });
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const store = useSelector((store) => store.login.user);

  useEffect(() => {
    setLoading(true);
    performRequest("GET", getFilteredURL("/leave/getleaves/", page, filters))
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);

          setTotalPages(Math.ceil(res.data?.leaveApplied / 50));
        } else {
          Toast("error", "Error while getting Leads!");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error!");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);

  const filteredData =
    store.Role == process.env.REACT_APP_ROLE_ADMIN_S ||
    store.Role == process.env.REACT_APP_ROLE_ADMIN
      ? data?.leave
      : data?.leave?.filter((item) => item.userId == store.UserId);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcCalendar className="fs-2  me-1" /> Leaves Tracking
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Apply, modify and track your leaves.
                </h4>
              </div>
              <div className="col-md-4">
                <div className="btn-list justify-content-end">
                  <a href={"/employee-leave-management/new-leave"}>
                    <button className="btn btn-primary">
                      <BsCalendar2XFill className="me-2 fs-2" /> Add New Leave
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-1">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>
                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3>Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Start Date:"
                              value={filters.startdate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  startdate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="End Date:"
                              value={filters.enddate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  enddate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterSelect
                              label="Search employee:"
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  leadOwner: e.target.value,
                                })
                              }
                              value={filters.leadOwner}
                            >
                              <option value="">select all</option>

                              {leadOwner &&
                                leadOwner?.map((state, index) => (
                                  <option key={index} value={state?.userName}>
                                    {state?.userName}
                                  </option>
                                ))}
                            </DynamicFilterSelect>
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      startdate: "",
                                      enddate: "",
                                      employee: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Start Date:"
                            value={filters.startdate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                startdate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="End Date:"
                            value={filters.enddate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                enddate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterSelect
                            label="Search employee:"
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                leadOwner: e.target.value,
                              })
                            }
                            value={filters.leadOwner}
                          >
                            <option value="">select all</option>

                            {leadOwner &&
                              leadOwner?.map((state, index) => (
                                <option key={index} value={state?.userName}>
                                  {state?.userName}
                                </option>
                              ))}
                          </DynamicFilterSelect>
                        </div>

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    startdate: "",
                                    enddate: "",
                                    employee: "",
                                  });
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {filteredData && (
                  <LeavesTrackingTable
                    EX={filteredData}
                    currentPage={page}
                    itemsPerPage={50}
                  />
                )}

                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.leave?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EmployeeLeaveManagement;
