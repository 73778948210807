import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import performRequest from "../../../api/request";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import SimpleButton from "../../../Components/layouts/simple_button";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { useSelector } from "react-redux";
const getTitle = (pathSegments) => {
  if (pathSegments && pathSegments.includes("followup")) {
    return "Leads Followup";
  } else if (pathSegments && pathSegments.includes("filtered")) {
    return "Leads Filtered";
  } else {
    return "Leads";
  }
};
const ViewLeads = () => {
  const user = useSelector((store) => store?.login?.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const location = useLocation();
  const pathSegments = location.pathname;

  const navigate = useNavigate();
  const { id } = useParams();
  const [facilities, setFacilities] = useState();
  const goToEdit = (id) => {
    if (pathSegments && pathSegments.includes("followup")) {
      navigate(`/leads-followup/edit/${id}`);
    } else if (pathSegments && pathSegments.includes("filtered")) {
      navigate(`/leads-filtered/edit/${id}`);
    } else {
      navigate(`/leads/edit-lead/${id}`);
    }
  };

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await performRequest("GET", `/leads/getleadsById/${id}`);
        if (res.status === 200) {
          setData(res.data);
          if (res.data.facilities !== "") {
            setFacilities(JSON.parse(res.data.facilities));
          } else {
            setFacilities("");
          }
        } else {
          Toast("error", "Error while getting Lead!");
        }
      } catch (err) {
        Toast("error", "Server Error!");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);
  const ReassignLead = (values) => {
    let leadData = {
      reAssignTo: user?.Name,
      leadsFiltered: true,
    };

    setLoading(true);
    performRequest("PATCH", `/leads/updateleads/${values.id}`, leadData)
      .then((res) => {
        if (res.status === 200) {
          Toast(
            "success",
            `The lead has been successfully assigned to ${user?.Name}!`
          );
          goToEdit(values.id);
        } else {
          Toast("error", "Error while uploading");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };
  const getEditButton = (data) => {
    if (
      !data?.leadsFiltered ||
      user?.Role === process.env.REACT_APP_ROLE_ADMIN_S ||
      (data?.leadsFiltered &&
        data?.reAssignTo &&
        data?.reAssignTo === user?.Name)
    ) {
      return (
        <SimpleButton
          buttonText="Edit Lead"
          onClick={() => goToEdit(data?.id)}
        />
      );
    } else if (
      data?.leadsFiltered &&
      data?.reAssignTo &&
      data?.reAssignTo !== user?.Name
    ) {
      return (
        <button disabled className="btn mx-1 mt-2 btn-primary">
          Edit Lead
        </button>
      );
    } else {
      return (
        <SimpleButton
          buttonText="ReAssign & Edit"
          onClick={() => ReassignLead(data)}
        />
      );
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">{getTitle(pathSegments)}</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header row g-2 align-items-center">
              <div className="col">
                <h2>View Lead</h2>
              </div>
              <div className="col-auto ms-auto d-print-none">
                <div className="btn-list justify-content-end">
                  <SimpleButton
                    buttonText="All Enquiry"
                    onClick={() => navigate(`/enquiry`)}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              {data && (
                <>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">First name</label>
                    <div className="w-100 col">
                      {(data?.first_name || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Last name</label>
                    <div className="w-100 col">
                      {(data?.last_name || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Full name</label>
                    <div className="w-100 col">
                      {(data?.full_name || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Address</label>
                    <div className="w-100 col">
                      {(data?.address || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Company Name
                    </label>
                    <div className="w-100 col">
                      {(data?.company_name || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Email Address
                    </label>
                    <div className="w-100 col">{data?.email || "N/A"}</div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Phone Number
                    </label>
                    <div className="w-100 col">
                      {(data?.phone_number || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Planned Area
                    </label>
                    <div className="w-100 col">
                      {(`${data?.planned_area}` || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Interested in
                    </label>
                    <div className="w-100 col">
                      {(data?.interestedIn &&
                        data.interestedIn.join(", ").toUpperCase()) ||
                        "N/A"}
                    </div>
                  </div>
                  {facilities && (
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Facilities
                      </label>
                      <div className="w-100 col">
                        {(facilities.join(", ") || "N/A").toUpperCase()}
                      </div>
                    </div>
                  )}
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">GST number</label>
                    <div className="w-100 col">
                      {(data?.gst_number || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Lead Generated From
                    </label>
                    <div className="w-100 col">
                      {(data?.lead_genrated_from || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Location Type
                    </label>
                    <div className="w-100 col">
                      {(data?.location_type || "N/A").toUpperCase()}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Lead Status</label>
                    <div className="w-100 col">
                      {(data?.lead_status || "N/A").toUpperCase()}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">
                      Call Recording
                    </label>
                    <div className="w-100 col">
                      {data?.callRecordingUpload ? (
                        <audio controls>
                          <source
                            src={data?.callRecordingUpload}
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-25 form-label col-3">Notes</label>
                    <div className="w-100 col">
                      {(data?.notes || "N/A").toUpperCase()}
                    </div>
                  </div>
                </>
              )}
              <hr />
              <h2>Follow Up</h2>
              <hr />
              {data && data.followUp && (
                <div className="table-responsive">
                  <table className="table table-vcenter">
                    <thead>
                      <tr>
                        <th>Message</th>
                        <th>Follow up date</th>
                        <th>Video call arranged</th>
                        <th>Followup Done</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.followUp.map((lineItem, index) => (
                        <tr key={index}>
                          <td>{(lineItem?.followUp || "N/A").toUpperCase()}</td>
                          <td>
                            {(lineItem?.followUpDate || "N/A").toUpperCase()}
                          </td>
                          <td>{lineItem?.videoCall ? "Yes" : "No"}</td>
                          <td>{lineItem?.followupTaken ? "Yes" : "No"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className="card-footer">
              <div className="btn-list justify-content-end">
                {user?.Role !== process.env.REACT_APP_ROLE_SALESAGENCY && (
                  <>
                    {" "}
                    {user?.Role === process.env.REACT_APP_ROLE_ADMIN_S ||
                    (data?.reAssignTo && data?.reAssignTo === user?.Name) ? (
                      <SimpleButton
                        buttonText="Create Quotation"
                        onClick={() =>
                          navigate(
                            `/custom-quotation/new-quotation?fromlead=${id}`
                          )
                        }
                      />
                    ) : (
                      <button disabled className="btn mx-1 mt-2 btn-primary">
                        CREATE QUOTATION
                      </button>
                    )}
                  </>
                )}
                {data && getEditButton(data)}
                <SimpleButton buttonText="Cancel" type="secondary" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewLeads;
