import * as yup from "yup";
export const scheduleSchema = yup.object().shape({
  date: yup.date(),
  schedule: yup.string(),
  actions: yup.string(),
  scheduleDone: yup.boolean(),
  scheduleDoneDate: yup.date(),
});
export const nurseryBatchSchema = yup.object().shape({
  batchName: yup.string().required("Required"),
  cropName: yup.string().required("Required"),
  numberOfplants: yup.number().required("Required"),
  schedule: yup.array().of(scheduleSchema),
  batchStartDate: yup.date().required("Required"),
  batchEndDate: yup.date(),
  notes: yup.string(),
  generatedBy: yup.string(),
});
