import React from "react";
import { useNavigate } from "react-router-dom";
import { getId } from "../../../Components/Functions/word.fun";
const tableHead = [
  "Sr.",
  "Project",
  "Vendor",
  "VENDOR PURCHASE ORDER DATE",
  "Business Name",
  "Actions",
];
const AllPurcahseOrderTable = ({ PO = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    id = getId(id);
    navigate(`/purchase-order/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {PO?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.poNumber}>
                <td>{Index}</td>
                {/* <td>{getId(item?.poNumber)}</td> */}
                <td>{item?.project || "N/A"}</td>
                <td>{item?.contactPersonName || "N/A"}</td>
                <td>{item?.vendorPurchaseOrderDate || "N/A"}</td>
                <td>{item?.businessName || "N/A"}</td>
                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.poNumber)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default AllPurcahseOrderTable;
