import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../CustomComponent/toast";
import StepRoutes from "../../../Routes/steps_routes";
import CustomInput from "../../../CustomComponent/custom_input";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import performRequest from "../../../api/request";
import { Form, Formik } from "formik";
import Footer from "../../../Components/Footer/footer";
import CustomSelect from "../../../CustomComponent/custom_select";
import { useSelector } from "react-redux";
import CustomTextArea from "../../../CustomComponent/custom_textarea";
import LoadingPage from "../../../Components/layouts/loading";
import CustomCheckbox from "../../../CustomComponent/custom_checkbox";
import { SiteInspectionSchema } from "../../../Schemas/clients/client_site_inspection_schema";
import { ClientSiteInspenctionInfo } from "../../../SchemaInfo/info";
import { getId } from "../../../Components/Functions/word.fun";
import { uploadImage } from "../../../CustomComponent/upload_Image";

const AddSiteInspection = () => {
  const role = useSelector((store) => store?.login?.user?.Role);
  const store = useSelector((store) => store.login.user);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [leadOwner, setLeadOwner] = useState();
  useEffect(() => {
    performRequest("GET", "/leads/getAllLeadsOwners")
      .then((res) => setLeadOwner(res?.data))
      .catch((err) => console.error(err));
  }, []);
  const [isDropdown, setIsDropdown] = useState("");
  const [defaultProjectId, setDefaultProjectId] = useState("");
  const [defaultProject, setDefaultProject] = useState("");

  const handleInputChange = (e, props) => {
    let { value } = e.target;
    if (value && value.length == 4) {
      performRequest("GET", `/quotations/getQuotationsById/${value}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.error) {
              setIsDropdown("Quotations Not Found");
              props.setFieldValue("quotationNumber", "");
            } else {
              setIsDropdown(res.data.quotationNumber);
            }
          } else {
            Toast("error", "Error while getting Quotations!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error!");
          console.error(err);
        });
      setIsDropdown(!isDropdown);
    }
    // Toggle the dropdown state
  };
  const navigate = useNavigate();

  const CheckhandelSubmit = (values, actions) => {
    if (role !== process.env.REACT_APP_ROLE_CLIENT) {
      values = { ...values, generatedBy: store.Name };
      const headers = { "Content-Type": "multipart/form-data" };
      setLoading(true);
      performRequest("POST", "/siteInspection/createSiteInspection", values)
        .then((res) => {
          if (res.status === 201) {
            Toast("success", "SI created successfully!");
            navigate(`/client-site-inspection`);
          } else {
            Toast("error", "Error while creating SI!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    } else {
      values = {
        ...values,
        generatedBy: store.Name,
        projectId: defaultProjectId,
      };
      const headers = { "Content-Type": "multipart/form-data" };
      setLoading(true);
      performRequest("POST", "/siteInspection/createSiteInspection", values)
        .then((res) => {
          if (res.status === 201) {
            Toast("success", "SI created successfully!");
            navigate(`/client-site-inspection`);
          } else {
            Toast("error", "Error while creating SI!");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (role !== process.env.REACT_APP_ROLE_CLIENT) {
      performRequest("GET", "/siteInspection/getAllProjects")
        .then((res) => setProjects(res.data))
        .catch((err) => console.error(err));
    }
  }, []);

  useEffect(() => {
    if (role === process.env.REACT_APP_ROLE_CLIENT) {
      performRequest("GET", "/siteInspection/getAllProjects")
        .then((res) => {
          setProjects(res.data);
          const matchingProject = res?.data?.find(
            (project) => project?.clientName === store.Name
          );
          if (matchingProject) {
            const projectId = getId(matchingProject?.cpNumber);
            setDefaultProject(matchingProject?.clientName);
            setDefaultProjectId(projectId);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [store.name]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">Site Inspection</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card my-4">
            <div className="card-header">
              <h2 className="page-title">New Site Inspection</h2>
            </div>
            <Formik
              initialValues={ClientSiteInspenctionInfo}
              validationSchema={SiteInspectionSchema}
              onSubmit={CheckhandelSubmit}
            >
              {(props) => (
                <Form>
                  <div className="card-body">
                    {role === process.env.REACT_APP_ROLE_CLIENT &&
                      defaultProject && (
                        <div className="mb-3 row">
                          <label className="w-25 form-label col-3">
                            Project
                          </label>
                          <div className="w-100 col">{defaultProject}</div>
                        </div>
                      )}
                    {role !== process.env.REACT_APP_ROLE_CLIENT && (
                      <div className="mb-3 row">
                        <label className="w-25 form-label required col-3">
                          Project
                        </label>

                        <div className="w-100 col">
                          <CustomSelect name="projectId" type="number">
                            <option value="">Select project</option>
                            {projects &&
                              projects?.map((pro) => {
                                return (
                                  <option
                                    key={pro.cpNumber}
                                    value={getId(pro.cpNumber)}
                                  >
                                    {pro.clientName}
                                  </option>
                                );
                              })}
                          </CustomSelect>
                          {props.touched.projectId &&
                            props.errors.projectId && (
                              <p className="text-danger">
                                {props.errors.projectId}
                              </p>
                            )}
                        </div>
                      </div>
                    )}
                    <div className="mb-3 row">
                      <label className="w-25 form-label ">
                        Inspection date
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="inspectionDate" type="date" />
                        {props.touched.inspectionDate &&
                          props.errors.inspectionDate && (
                            <p className="text-danger">
                              {props.errors.inspectionDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Tentative start date
                      </label>
                      <div className="w-100 col">
                        <CustomInput type="date" name="tentativeStartDate" />
                        {props.touched.tentativeStartDate &&
                          props.errors.tentativeStartDate && (
                            <p className="text-danger">
                              {props.errors.tentativeStartDate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Sales relationship manager
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="salesRelationshipManager">
                          <option value="">Select project</option>
                          {leadOwner &&
                            leadOwner?.map((pro, index) => {
                              return (
                                <option key={index} value={pro.userName}>
                                  {pro.userName}
                                </option>
                              );
                            })}
                        </CustomSelect>

                        {props.touched.salesRelationshipManager &&
                          props.errors.salesRelationshipManager && (
                            <p className="text-danger">
                              {props.errors.salesRelationshipManager}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label  col-3 required">
                        Site address
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="siteAddress" type="text" />
                        {props.touched.siteAddress &&
                          props.errors.siteAddress && (
                            <p className="text-danger">
                              {props.errors.siteAddress}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Site pincode
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="sitePinCode" />

                        {props.touched.sitePinCode &&
                          props.errors.sitePinCode && (
                            <p className="text-danger">
                              {props.errors.sitePinCode}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Site manager name
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="siteManagerName" />

                        {props.touched.siteManagerName &&
                          props.errors.siteManagerName && (
                            <p className="text-danger">
                              {props.errors.siteManagerName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Site manager phone
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="siteManagerPhone" />
                        {props.touched.siteManagerPhone &&
                          props.errors.siteManagerPhone && (
                            <p className="text-danger">
                              {props.errors.siteManagerPhone}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Looking For Government Support
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="lookingForGovernmentSupport"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.lookingForGovernmentSupport &&
                          props.errors.lookingForGovernmentSupport && (
                            <p className="text-danger">
                              {props.errors.lookingForGovernmentSupport}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3  ">
                        Project Owner Gender
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="projectOwnerGender">
                          <option value="">select </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </CustomSelect>

                        {props.touched.projectOwnerGender &&
                          props.errors.projectOwnerGender && (
                            <p className="text-danger">
                              {props.errors.projectOwnerGender}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Select location
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="location">
                          <option value="">select </option>
                          <option value="urban">Urban</option>
                          <option value="semi urban">Semi Urban</option>
                          <option value="rural">Rural</option>
                        </CustomSelect>

                        {props.touched.location && props.errors.location && (
                          <p className="text-danger">{props.errors.location}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Select cast
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="cast">
                          <option value="">select </option>
                          <option value="general">General</option>
                          <option value="sc,st">SC,ST</option>
                          <option value="obc">OBC</option>
                          <option value="minorities">Minorities</option>
                          <option value="minorities">Minorities</option>
                          <option value="vt,vj">VT,VJ</option>
                          <option value="protected tribes">
                            Protected Tribes
                          </option>
                        </CustomSelect>

                        {props.touched.cast && props.errors.cast && (
                          <p className="text-danger">{props.errors.cast}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Number of staff
                      </label>

                      <div className="w-100 col">
                        <CustomInput type="text" name="numberOfStaff" />
                        {props.touched.numberOfStaff &&
                          props.errors.numberOfStaff && (
                            <p className="text-danger">
                              {props.errors.numberOfStaff}
                            </p>
                          )}
                      </div>
                    </div>
                    {role === process.env.REACT_APP_ROLE_CLIENT && (
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3  ">
                          Quotation
                        </label>

                        <div className="w-100 col dropdown">
                          {/* <datalist id="datalistOptions">
                        <option value="Aruba">No Data Found</option>
                        <option value="Afghanistan" />
                        <option value="Angola" />
                        <option value="Anguilla" />
                        <option value="Albania" />
                        <option value="Andorra" />
                        <option value="United Arab Emirates" />
                        <option value="Argentina" />
                        <option value="Armenia" />
                        <option value="American Samoa" />
                      </datalist> */}

                          <input type="text" className="form-control"></input>
                          <p className="text-muted">
                            To get quotation contact on 9870424425
                          </p>
                        </div>
                      </div>
                    )}
                    {role !== process.env.REACT_APP_ROLE_CLIENT && (
                      <div className="mb-3 row">
                        <label className="w-25 form-label col-3  ">
                          Quotation
                        </label>

                        <div className="w-100 col dropdown">
                          {/* <datalist id="datalistOptions">
                          <option value="Aruba">No Data Found</option>
                          <option value="Afghanistan" />
                          <option value="Angola" />
                          <option value="Anguilla" />
                          <option value="Albania" />
                          <option value="Andorra" />
                          <option value="United Arab Emirates" />
                          <option value="Argentina" />
                          <option value="Armenia" />
                          <option value="American Samoa" />
                        </datalist> */}
                          <CustomInput
                            type={"text"}
                            className="form-control"
                            onChange={(e) => {
                              props.setFieldValue(
                                "quotationNumber",
                                e.target.value
                              );
                              handleInputChange(e, props);
                            }}
                            name="quotationNumber"
                          ></CustomInput>

                          {isDropdown && (
                            <span
                              onClick={() => {
                                if (
                                  isDropdown !== "Quotations Not Found" &&
                                  isDropdown !== ""
                                ) {
                                  props.setFieldValue(
                                    "quotationNumber",
                                    isDropdown
                                  );
                                  setIsDropdown("");
                                }
                              }}
                              className="card cursor-pointer"
                            >
                              {isDropdown}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3  ">
                        Type of Site
                      </label>

                      <div className="w-100 col">
                        <CustomSelect type="text" name="typeOfSite">
                          <option value="">select </option>
                          <option value="farmLand">Farm Land</option>
                          <option value="naPlot">NA Plot</option>
                          <option value="indoor">Indoor</option>
                          <option value="underConstructionSite">
                            Under Construction Site
                          </option>
                          <option value="commercialSite">
                            Commercial Site
                          </option>
                          <option value="governmentPlot">
                            Government Plot
                          </option>
                        </CustomSelect>

                        {props.touched.typeOfSite &&
                          props.errors.typeOfSite && (
                            <p className="text-danger">
                              {props.errors.typeOfSite}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Soil type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="soilType">
                          <option value="">select </option>
                          <option value="blackSoil">Black Soil</option>
                          <option value="redSoil">Red Soil</option>
                          <option value="dusty">Dusty</option>
                          <option value="concrete">Concrete</option>
                          <option value="sand">Sand</option>
                          <option value="vertisol">Vertisol</option>
                          <option value="stoned">Stoned</option>
                          <option value="hardToFind">Hard To Find</option>
                        </CustomSelect>

                        {props.touched.soilType && props.errors.soilType && (
                          <p className="text-danger">{props.errors.soilType}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Total available area unit
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="totalAvailableAreaUnit">
                          <option value="">select </option>
                          <option value="acre">Acre</option>
                          <option value="hectare">Hectare</option>
                          <option value="guntha">Guntha</option>
                          <option value="cent">Cent</option>
                          <option value="sqft">Square Feet (sqft)</option>
                          <option value="sqmt">Square Meter (sqmt)</option>
                          <option value="yard">Yard</option>
                          <option value="bigha">Bigha</option>
                        </CustomSelect>

                        {props.touched.totalAvailableAreaUnit &&
                          props.errors.totalAvailableAreaUnit && (
                            <p className="text-danger">
                              {props.errors.totalAvailableAreaUnit}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Total available area number
                      </label>

                      <div className="w-100 col">
                        <CustomInput
                          name="totalAvailableAreaNumber"
                          type={"text"}
                        />
                        {props.touched.totalAvailableAreaNumber &&
                          props.errors.totalAvailableAreaNumber && (
                            <p className="text-danger">
                              {props.errors.totalAvailableAreaNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Land ownership
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="landOwnership">
                          <option value="">select </option>
                          <option value="own">Own</option>
                          <option value="rent">Rent</option>
                          <option value="leased">Leased</option>
                          <option value="partnership">Partnership</option>
                          <option value="planning to purchase">
                            Planning To Purchase
                          </option>
                          <option value="planning to lease">
                            Planning To Lease
                          </option>
                        </CustomSelect>

                        {props.touched.landOwnership &&
                          props.errors.landOwnership && (
                            <p className="text-danger">
                              {props.errors.landOwnership}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Land leveling
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="landLeveling">
                          <option value="">select </option>
                          <option value="required">Required</option>
                          <option value="alreadyDone">Already Done</option>
                          <option value="hardToFindOut">
                            Hard to Find Out
                          </option>
                        </CustomSelect>

                        {props.touched.landLeveling &&
                          props.errors.landLeveling && (
                            <p className="text-danger">
                              {props.errors.landLeveling}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 ">
                        Natural slope of land
                      </label>
                      <div className="w-100 col">
                        <CustomSelect type="text" name="naturalSlopeOfLand">
                          <option value="">select </option>
                          <option value="north">North</option>
                          <option value="south">South</option>
                          <option value="east">East</option>
                          <option value="west">West</option>
                          <option value="northEast">North-East</option>
                          <option value="northWest">North-West</option>
                          <option value="southEast">South-East</option>
                          <option value="southWest">South-West</option>
                          <option value="hardToFind">Hard To Find</option>
                        </CustomSelect>

                        {props.touched.naturalSlopeOfLand &&
                          props.errors.naturalSlopeOfLand && (
                            <p className="text-danger">
                              {props.errors.naturalSlopeOfLand}
                            </p>
                          )}
                      </div>
                    </div>
                    <hr />
                    <h2>Land</h2>
                    <hr />
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Land has tree
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="landHasTree"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.landHasTree &&
                          props.errors.landHasTree && (
                            <p className="text-danger">
                              {props.errors.landHasTree}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Land has stones
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="landHasStones"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.landHasStones &&
                          props.errors.landHasStones && (
                            <p className="text-danger">
                              {props.errors.landHasStones}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Land has pond
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="landHasPond"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.landHasPond &&
                          props.errors.landHasPond && (
                            <p className="text-danger">
                              {props.errors.landHasPond}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Land has mud patch
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="landHasMudPatch"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.landHasMudPatch &&
                          props.errors.landHasMudPatch && (
                            <p className="text-danger">
                              {props.errors.landHasMudPatch}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Land has hill lock
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="landHasHillock"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.landHasHillock &&
                          props.errors.landHasHillock && (
                            <p className="text-danger">
                              {props.errors.landHasHillock}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Land fencing type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="landFencingType" type="text">
                          <option value="">select</option>
                          <option value="noFencing">No Fencing</option>
                          <option value="stoned">Stoned</option>
                          <option value="cementPoll">Cement Poll</option>
                          <option value="cementWall">Cement Wall</option>
                          <option value="preFab">Prefabricated</option>
                          <option value="wired">Wired</option>
                          <option value="makeShift">Make Shift</option>
                        </CustomSelect>
                        {props.touched.landFencingType &&
                          props.errors.landFencingType && (
                            <p className="text-danger">
                              {props.errors.landFencingType}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Land fencing height in feet
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="landFencingHeightInFeet"
                          type="text"
                        />
                        {props.touched.landFencingHeightInFeet &&
                          props.errors.landFencingHeightInFeet && (
                            <p className="text-danger">
                              {props.errors.landFencingHeightInFeet}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Whats on north
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="whatsOnNorth" type="text">
                          <option value="">select</option>
                          <option value="ownLand">Own Land</option>
                          <option value="landBelongsToSomeoneElse">
                            Land Belongs to Someone Else
                          </option>
                          <option value="waterBody">Water Body</option>
                          <option value="road">Road</option>
                          <option value="fencing">Fencing</option>
                          <option value="wall">Wall</option>

                          <option value="slopeLand">Slope Land</option>
                          <option value="disputedLand">Disputed Land</option>
                          <option value="governmentLand">
                            Government Land
                          </option>
                          <option value="other">Other</option>
                        </CustomSelect>
                        {props.touched.whatsOnNorth &&
                          props.errors.whatsOnNorth && (
                            <p className="text-danger">
                              {props.errors.whatsOnNorth}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Whats on east
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="whatsOnEast" type="text">
                          <option value="">select</option>
                          <option value="ownLand">Own Land</option>
                          <option value="landBelongsToSomeoneElse">
                            Land Belongs to Someone Else
                          </option>
                          <option value="waterBody">Water Body</option>
                          <option value="road">Road</option>
                          <option value="fencing">Fencing</option>
                          <option value="wall">Wall</option>
                          <option value="slopeLand">Slope Land</option>
                          <option value="disputedLand">Disputed Land</option>
                          <option value="governmentLand">
                            Government Land
                          </option>
                          <option value="other">Other</option>
                        </CustomSelect>
                        {props.touched.whatsOnEast &&
                          props.errors.whatsOnEast && (
                            <p className="text-danger">
                              {props.errors.whatsOnEast}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Whats on south
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="whatsOnSouth" type="text">
                          <option value="">select</option>
                          <option value="ownLand">Own Land</option>
                          <option value="landBelongsToSomeoneElse">
                            Land Belongs to Someone Else
                          </option>
                          <option value="waterBody">Water Body</option>
                          <option value="road">Road</option>
                          <option value="fencing">Fencing</option>
                          <option value="wall">Wall</option>
                          <option value="slopeLand">Slope Land</option>
                          <option value="disputedLand">Disputed Land</option>
                          <option value="governmentLand">
                            Government Land
                          </option>
                          <option value="other">Other</option>
                        </CustomSelect>
                        {props.touched.whatsOnSouth &&
                          props.errors.whatsOnSouth && (
                            <p className="text-danger">
                              {props.errors.whatsOnSouth}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Whats on west
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="whatsOnWest" type="text">
                          <option value="">select</option>
                          <option value="ownLand">Own Land</option>
                          <option value="landBelongsToSomeoneElse">
                            Land Belongs to Someone Else
                          </option>
                          <option value="waterBody">Water Body</option>
                          <option value="road">Road</option>
                          <option value="fencing">Fencing</option>
                          <option value="wall">Wall</option>
                          <option value="slopeLand">Slope Land</option>
                          <option value="disputedLand">Disputed Land</option>
                          <option value="governmentLand">
                            Government Land
                          </option>
                          <option value="other">Other</option>
                        </CustomSelect>
                        {props.touched.whatsOnWest &&
                          props.errors.whatsOnWest && (
                            <p className="text-danger">
                              {props.errors.whatsOnWest}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Obstacles towards sky
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="obstaclesTowardsSky"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.obstaclesTowardsSky &&
                          props.errors.obstaclesTowardsSky && (
                            <p className="text-danger">
                              {props.errors.obstaclesTowardsSky}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Obstacles towards sky photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="obstaclesTowardsSkyPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "obstaclesTowardsSkyPhoto",
                              e.target.files[0]
                            )
                          }
                        />

                        {props.touched.obstaclesTowardsSkyPhoto &&
                          props.errors.obstaclesTowardsSkyPhoto && (
                            <p className="text-danger">
                              {props.errors.obstaclesTowardsSkyPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3 required">
                        Latitude & Longitude
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="googleMapsLocationLink"
                          type="text"
                          placeholder="Ex: 18.5463722,54.384699"
                        />
                        {props.touched.googleMapsLocationLink &&
                          props.errors.googleMapsLocationLink && (
                            <p className="text-danger">
                              {props.errors.googleMapsLocationLink}
                            </p>
                          )}
                      </div>
                    </div>
                    <hr />
                    <h2>Water</h2>
                    <hr />
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water source type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="waterSourceType" type="text">
                          <option value="">select</option>
                          <option value="borewell">Borewell</option>
                          <option value="well">Well</option>
                          <option value="municipalWater">
                            Municipal Water
                          </option>
                          <option value="pond">Pond</option>
                          <option value="rainHarvesting">
                            Rain Harvesting
                          </option>
                          <option value="tanker">Tanker</option>
                        </CustomSelect>
                        {props.touched.waterSourceType &&
                          props.errors.waterSourceType && (
                            <p className="text-danger">
                              {props.errors.waterSourceType}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water tank capacity in litre
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="waterTankCapacityInLitre"
                          type="text"
                        />
                        {props.touched.waterTankCapacityInLitre &&
                          props.errors.waterTankCapacityInLitre && (
                            <p className="text-danger">
                              {props.errors.waterTankCapacityInLitre}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water availability frequency
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="waterAvailabilityFrequency"
                          type="text"
                        >
                          <option value="">select</option>
                          <option value="uninterrupted">Uninterrupted</option>
                          <option value="onDemand">On Demand</option>
                          <option value="onAvailabilityOfElectricity">
                            On Availability of Electricity
                          </option>
                          <option value="onceAday">Once a Day</option>
                          <option value="onceAweek">Once a Week</option>
                          <option value="onceAmonth">Once a Month</option>
                        </CustomSelect>
                        {props.touched.waterAvailabilityFrequency &&
                          props.errors.waterAvailabilityFrequency && (
                            <p className="text-danger">
                              {props.errors.waterAvailabilityFrequency}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Position of water tank
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="positionOfWaterTank" type="text">
                          <option value="">select</option>
                          <option value="north">North</option>
                          <option value="south">South</option>
                          <option value="east">East</option>
                          <option value="west">West</option>
                          <option value="northEast">North-East</option>
                          <option value="northWest">North-West</option>
                          <option value="southEast">South-East</option>
                          <option value="southWest">South-West</option>
                          <option value="unclear">Unclear</option>
                        </CustomSelect>
                        {props.touched.positionOfWaterTank &&
                          props.errors.positionOfWaterTank && (
                            <p className="text-danger">
                              {props.errors.positionOfWaterTank}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water tank location photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="waterTankLocationPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "waterTankLocationPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.waterTankLocationPhoto &&
                          props.errors.waterTankLocationPhoto && (
                            <p className="text-danger">
                              {props.errors.waterTankLocationPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water Ph reading
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="waterPhReading" type="text" />
                        {props.touched.waterPhReading &&
                          props.errors.waterPhReading && (
                            <p className="text-danger">
                              {props.errors.waterPhReading}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water Ec reading (in ppm)
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="waterEcReading" type="text" />
                        {props.touched.waterEcReading &&
                          props.errors.waterEcReading && (
                            <p className="text-danger">
                              {props.errors.waterEcReading}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Water report photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="waterReportPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "waterReportPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.waterReportPhoto &&
                          props.errors.waterReportPhoto && (
                            <p className="text-danger">
                              {props.errors.waterReportPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <hr />
                    <h2>Sunlight</h2>
                    <hr />
                    {/* have to check */}
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Entry to farm site
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="entryToFarmSite" type="text">
                          <option value="">select</option>
                          <option value="north">North</option>
                          <option value="south">South</option>
                          <option value="east">East</option>
                          <option value="west">West</option>
                          <option value="northEast">North-East</option>
                          <option value="northWest">North-West</option>
                          <option value="southEast">South-East</option>
                          <option value="southWest">South-West</option>
                          <option value="unclear">Unclear</option>
                        </CustomSelect>
                        {props.touched.entryToFarmSite &&
                          props.errors.entryToFarmSite && (
                            <p className="text-danger">
                              {props.errors.entryToFarmSite}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Entry to farm photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="entryTofarmPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "entryTofarmPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.entryTofarmPhoto &&
                          props.errors.entryTofarmPhoto && (
                            <p className="text-danger">
                              {props.errors.entryTofarmPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lux reading (200 micron)
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="luxReading" type="text" />
                        {props.touched.luxReading &&
                          props.errors.luxReading && (
                            <p className="text-danger">
                              {props.errors.luxReading}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lux reading photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="luxReadingPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "luxReadingPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.luxReadingPhoto &&
                          props.errors.luxReadingPhoto && (
                            <p className="text-danger">
                              {props.errors.luxReadingPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lux deffusion sheet
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="luxDeffusionSheet" type="text" />
                        {props.touched.luxDeffusionSheet &&
                          props.errors.luxDeffusionSheet && (
                            <p className="text-danger">
                              {props.errors.luxDeffusionSheet}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lux deffusion sheet photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="luxDeffusionSheetPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "luxDeffusionSheetPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.luxDeffusionSheetPhoto &&
                          props.errors.luxDeffusionSheetPhoto && (
                            <p className="text-danger">
                              {props.errors.luxDeffusionSheetPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lux woven poly
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="luxWovenPoly" type="text" />
                        {props.touched.luxWovenPoly &&
                          props.errors.luxWovenPoly && (
                            <p className="text-danger">
                              {props.errors.luxWovenPoly}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lux woven poly photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="luxWovenPolyPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "luxWovenPolyPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.luxWovenPolyPhoto &&
                          props.errors.luxWovenPolyPhoto && (
                            <p className="text-danger">
                              {props.errors.luxWovenPolyPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lux Uv open
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="luxUvOpen" type="text" />
                        {props.touched.luxUvOpen && props.errors.luxUvOpen && (
                          <p className="text-danger">
                            {props.errors.luxUvOpen}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Lux Uv open photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="luxUvOpenPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "luxUvOpenPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.luxUvOpenPhoto &&
                          props.errors.luxUvOpenPhoto && (
                            <p className="text-danger">
                              {props.errors.luxUvOpenPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <hr />
                    <h2>Climate</h2>
                    <hr />
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Temperature reading (in °F)
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="temperatureReading" type="text" />
                        {props.touched.temperatureReading &&
                          props.errors.temperatureReading && (
                            <p className="text-danger">
                              {props.errors.temperatureReading}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Temperature reading photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="temperatureReadingPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "temperatureReadingPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.temperatureReadingPhoto &&
                          props.errors.temperatureReadingPhoto && (
                            <p className="text-danger">
                              {props.errors.temperatureReadingPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Humidity reading
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="humidityReading" type="text" />
                        {props.touched.humidityReading &&
                          props.errors.humidityReading && (
                            <p className="text-danger">
                              {props.errors.humidityReading}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Humidity reading photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="humidityReadingPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "humidityReadingPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.humidityReadingPhoto &&
                          props.errors.humidityReadingPhoto && (
                            <p className="text-danger">
                              {props.errors.humidityReadingPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Climate hottest month
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="climateHottestMonth" type="text">
                          <option value="">select</option>
                          <option value="january">January</option>
                          <option value="february">February</option>
                          <option value="march">March</option>
                          <option value="april">April</option>
                          <option value="may">May</option>
                          <option value="june">June</option>
                          <option value="july">July</option>
                          <option value="august">August</option>
                          <option value="september">September</option>
                          <option value="october">October</option>
                          <option value="november">November</option>
                          <option value="december">December</option>
                        </CustomSelect>
                        {props.touched.climateHottestMonth &&
                          props.errors.climateHottestMonth && (
                            <p className="text-danger">
                              {props.errors.climateHottestMonth}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Climate coldest month
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="climateColdestMonth" type="text">
                          <option value="">select</option>
                          <option value="january">January</option>
                          <option value="february">February</option>
                          <option value="march">March</option>
                          <option value="april">April</option>
                          <option value="may">May</option>
                          <option value="june">June</option>
                          <option value="july">July</option>
                          <option value="august">August</option>
                          <option value="september">September</option>
                          <option value="october">October</option>
                          <option value="november">November</option>
                          <option value="december">December</option>
                        </CustomSelect>
                        {props.touched.climateColdestMonth &&
                          props.errors.climateColdestMonth && (
                            <p className="text-danger">
                              {props.errors.climateColdestMonth}
                            </p>
                          )}
                      </div>
                    </div>
                    <hr />
                    <h2>Dimensions</h2>
                    <hr />
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        North south measurment in meter
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="northSouthMeasurmentInMeter"
                          type="text"
                        />
                        {props.touched.northSouthMeasurmentInMeter &&
                          props.errors.northSouthMeasurmentInMeter && (
                            <p className="text-danger">
                              {props.errors.northSouthMeasurmentInMeter}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        North south measurment photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="northSouthMeasurmentPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "northSouthMeasurmentPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.northSouthMeasurmentPhoto &&
                          props.errors.northSouthMeasurmentPhoto && (
                            <p className="text-danger">
                              {props.errors.northSouthMeasurmentPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        East west measurment in meter
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="eastWestMeasurmentInMeter"
                          type="text"
                        />
                        {props.touched.eastWestMeasurmentInMeter &&
                          props.errors.eastWestMeasurmentInMeter && (
                            <p className="text-danger">
                              {props.errors.eastWestMeasurmentInMeter}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        East west measurment photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="eastWestMeasurmentPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "eastWestMeasurmentPhoto",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.eastWestMeasurmentPhoto &&
                          props.errors.eastWestMeasurmentPhoto && (
                            <p className="text-danger">
                              {props.errors.eastWestMeasurmentPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Layout photo
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          name="layoutPhoto"
                          type="file"
                          onChange={(e) =>
                            uploadImage(props, "layoutPhoto", e.target.files[0])
                          }
                        />
                        {props.touched.layoutPhoto &&
                          props.errors.layoutPhoto && (
                            <p className="text-danger">
                              {props.errors.layoutPhoto}
                            </p>
                          )}
                      </div>
                    </div>
                    <hr />
                    <h2>Facilities</h2>
                    <hr />
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Facility to store items
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="facilityToStoreItems"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.facilityToStoreItems &&
                          props.errors.facilityToStoreItems && (
                            <p className="text-danger">
                              {props.errors.facilityToStoreItems}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Facility to stay
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="facilityToStay"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.facilityToStay &&
                          props.errors.facilityToStay && (
                            <p className="text-danger">
                              {props.errors.facilityToStay}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Facility has bathroom
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="facilityHasBathroom"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.facilityHasBathroom &&
                          props.errors.facilityHasBathroom && (
                            <p className="text-danger">
                              {props.errors.facilityHasBathroom}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Facility has drinking water
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="facilityHasDrinkingWater"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.facilityHasDrinkingWater &&
                          props.errors.facilityHasDrinkingWater && (
                            <p className="text-danger">
                              {props.errors.facilityHasDrinkingWater}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Facility has kitchen space
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="facilityHasKitchenSpace"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.facilityHasKitchenSpace &&
                          props.errors.facilityHasKitchenSpace && (
                            <p className="text-danger">
                              {props.errors.facilityHasKitchenSpace}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Facility has fuel for cooking
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="facilityHasFuelForCooking"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.facilityHasFuelForCooking &&
                          props.errors.facilityHasFuelForCooking && (
                            <p className="text-danger">
                              {props.errors.facilityHasFuelForCooking}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Transport option
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="transportOption" type="text">
                          <option value="">select</option>
                          <option value="byRoad">By Road</option>
                          <option value="byWater">By Water</option>
                        </CustomSelect>
                        {props.touched.transportOption &&
                          props.errors.transportOption && (
                            <p className="text-danger">
                              {props.errors.transportOption}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nearest tar road
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="nearestTarRoad" type="text">
                          <option value="">select</option>
                          <option value="0km To 1km">0km - 1km</option>
                          <option value="1km To 3km">1km - 3km</option>
                          <option value="3km To 5km">3km - 5km</option>
                          <option value="5km To 20km">5km - 20km</option>
                          <option value="20km To 50km">20km - 50km</option>
                        </CustomSelect>
                        {props.touched.nearestTarRoad &&
                          props.errors.nearestTarRoad && (
                            <p className="text-danger">
                              {props.errors.nearestTarRoad}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nearest market
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="nearestMarket" type="text">
                          <option value="">select</option>
                          <option value="0km To 1km">0km - 1km</option>
                          <option value="1km To 3km">1km - 3km</option>
                          <option value="3km To 5km">3km - 5km</option>
                          <option value="5km To 20km">5km - 20km</option>
                          <option value="20km To 50km">20km - 50km</option>
                        </CustomSelect>
                        {props.touched.nearestMarket &&
                          props.errors.nearestMarket && (
                            <p className="text-danger">
                              {props.errors.nearestMarket}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nearest medical facility
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="nearestMedicalFacility" type="text">
                          <option value="">select</option>
                          <option value="0km To 1km">0km - 1km</option>
                          <option value="1km To 3km">1km - 3km</option>
                          <option value="3km To 5km">3km - 5km</option>
                          <option value="5km To 20km">5km - 20km</option>
                          <option value="20km To 50km">20km - 50km</option>
                        </CustomSelect>
                        {props.touched.nearestMedicalFacility &&
                          props.errors.nearestMedicalFacility && (
                            <p className="text-danger">
                              {props.errors.nearestMedicalFacility}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nearest stay facility
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="nearestStayFacility" type="text">
                          <option value="">select</option>
                          <option value="0km To 1km">0km - 1km</option>
                          <option value="1km To 3km">1km - 3km</option>
                          <option value="3km To 5km">3km - 5km</option>
                          <option value="5km To 20km">5km - 20km</option>
                          <option value="20km To 50km">20km - 50km</option>
                        </CustomSelect>
                        {props.touched.nearestStayFacility &&
                          props.errors.nearestStayFacility && (
                            <p className="text-danger">
                              {props.errors.nearestStayFacility}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nearest bus stand
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="nearestBusStand" type="text" />
                        {props.touched.nearestBusStand &&
                          props.errors.nearestBusStand && (
                            <p className="text-danger">
                              {props.errors.nearestBusStand}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nearest railway station
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="nearestRailwayStation" type="text" />
                        {props.touched.nearestRailwayStation &&
                          props.errors.nearestRailwayStation && (
                            <p className="text-danger">
                              {props.errors.nearestRailwayStation}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Nearest airport
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="nearestAirport" type="text" />
                        {props.touched.nearestAirport &&
                          props.errors.nearestAirport && (
                            <p className="text-danger">
                              {props.errors.nearestAirport}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Reguler mode of transportation
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="regulerModeOfTransportation"
                          type="text"
                        >
                          <option value="">select</option>
                          <option value="ownVehicle">Own Vehicle</option>
                          <option value="privateVehicle">
                            Private Vehicle
                          </option>
                          <option value="governmentVehicle">
                            Government Vehicle
                          </option>
                          <option value="train">Train</option>
                        </CustomSelect>
                        {props.touched.regulerModeOfTransportation &&
                          props.errors.regulerModeOfTransportation && (
                            <p className="text-danger">
                              {props.errors.regulerModeOfTransportation}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Vehicle avilable for emergency
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="vehicleAvilableForEmergency"
                          type="text"
                        >
                          <option value="">select</option>
                          <option value="unavailable">Unavailable</option>
                          <option value="2 wheeler">2 Wheeler</option>
                          <option value="3 wheeler">3 Wheeler</option>
                          <option value="4 wheeler">4 Wheeler</option>
                        </CustomSelect>
                        {props.touched.vehicleAvilableForEmergency &&
                          props.errors.vehicleAvilableForEmergency && (
                            <p className="text-danger">
                              {props.errors.vehicleAvilableForEmergency}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Electricity connection available
                      </label>
                      <div className="w-100 col">
                        <CustomCheckbox
                          name="electricityConnectionAvailable"
                          type="checkbox"
                          width={"17px"}
                          height={"17px"}
                        />
                        {props.touched.electricityConnectionAvailable &&
                          props.errors.electricityConnectionAvailable && (
                            <p className="text-danger">
                              {props.errors.electricityConnectionAvailable}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Electricity meter type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="electricityMeterType" type="text">
                          <option value="">select</option>
                          <option value="residential">Residential</option>
                          <option value="commercial">Commercial</option>
                          <option value="agricultural">Agricultural</option>
                          <option value="solar">Solar</option>
                          <option value="industrial">Industrial</option>
                          <option value="freeUse">Free Use</option>
                        </CustomSelect>
                        {props.touched.electricityMeterType &&
                          props.errors.electricityMeterType && (
                            <p className="text-danger">
                              {props.errors.electricityMeterType}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Electricity meter phase type
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="electricityMeterPhaseType"
                          type="text"
                        >
                          <option value="">select</option>
                          <option value="singlePhase">Single Phase</option>
                          <option value="threePhase">Three Phase</option>
                          <option value="both">Both</option>
                        </CustomSelect>
                        {props.touched.electricityMeterPhaseType &&
                          props.errors.electricityMeterPhaseType && (
                            <p className="text-danger">
                              {props.errors.electricityMeterPhaseType}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Electricity outage frequency
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="electricityOutageFrequency"
                          type="text"
                        >
                          <option value="">select</option>
                          <option value="never">Never</option>
                          <option value="sometimes">Sometimes</option>
                          <option value="occasionally">Occasionally</option>
                          <option value="lowFrequency">Low Frequency</option>
                          <option value="highFrequency">High Frequency</option>
                        </CustomSelect>
                        {props.touched.electricityOutageFrequency &&
                          props.errors.electricityOutageFrequency && (
                            <p className="text-danger">
                              {props.errors.electricityOutageFrequency}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Electricity unit price
                      </label>
                      <div className="w-100 col">
                        <CustomInput name="electricityUnitPrice" type="text" />
                        {props.touched.electricityUnitPrice &&
                          props.errors.electricityUnitPrice && (
                            <p className="text-danger">
                              {props.errors.electricityUnitPrice}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Electricity pricing model
                      </label>
                      <div className="w-100 col">
                        <CustomSelect
                          name="electricityPricingModel"
                          type="text"
                        >
                          <option value="">select</option>
                          <option value="uniform">Uniform</option>
                          <option value="bracketed">Bracketed</option>
                          <option value="subsidised">Subsidised</option>
                        </CustomSelect>
                        {props.touched.electricityPricingModel &&
                          props.errors.electricityPricingModel && (
                            <p className="text-danger">
                              {props.errors.electricityPricingModel}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Electricity usage
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="electricityUsage" type="text">
                          <option value="">select</option>
                          <option value="hydroponicsOnly">
                            Hydroponics Only
                          </option>
                          <option value="sharedWithFactory">
                            Shared with Factory
                          </option>
                          <option value="sharedWithHome">
                            Shared with Home
                          </option>
                          <option value="sharedWithFarming">
                            Shared with Farming
                          </option>
                        </CustomSelect>
                        {props.touched.electricityUsage &&
                          props.errors.electricityUsage && (
                            <p className="text-danger">
                              {props.errors.electricityUsage}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Internet connectivity
                      </label>
                      <div className="w-100 col">
                        <CustomSelect name="internetConnectivity" type="text">
                          <option value="">select</option>
                          <option value="noInternet">No Internet</option>
                          <option value="broadband">Broadband</option>
                          <option value="portableHotspot">
                            Portable Hotspot
                          </option>
                        </CustomSelect>
                        {props.touched.internetConnectivity &&
                          props.errors.internetConnectivity && (
                            <p className="text-danger">
                              {props.errors.internetConnectivity}
                            </p>
                          )}
                      </div>
                    </div>
                    <hr />
                    <h2>Additional Details</h2>
                    <hr />
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">
                        Description video
                      </label>
                      <div className="w-100 col">
                        <CustomInput
                          type={"file"}
                          name="descriptionVideo"
                          onChange={(e) =>
                            uploadImage(
                              props,
                              "descriptionVideo",
                              e.target.files[0]
                            )
                          }
                        />
                        {props.touched.descriptionVideo &&
                          props.errors.descriptionVideo && (
                            <p className="text-danger">
                              {props.errors.descriptionVideo}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="w-25 form-label col-3">Notes</label>
                      <div className="w-100 col">
                        <CustomTextArea name="notes" type="textarea">
                          <option value="">select</option>
                        </CustomTextArea>
                        {props.touched.notes && props.errors.notes && (
                          <p className="text-danger">{props.errors.notes}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="btn-list justify-content-end">
                      <FormSubmitButton buttonText="Create Site Inspection" />

                      <SimpleButton buttonText="Cancel" type="secondary" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default AddSiteInspection;
