import axios from "axios";

export async function getCountries() {
  try {
    const response = await axios.get(
      "https://api.countrystatecity.in/v1/countries/",
      {
        headers: {
          "X-CSCAPI-KEY":
            "clBHNXFZeDZXQWp2UzA0YzJBSEF6VE00aXVUU2NwdG9DbTlRa0dhcQ==",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function getCitiesByCountry(code = "IN") {
  try {
    const response = await axios.get(
      `https://api.countrystatecity.in/v1/countries/${code}/cities`,
      {
        headers: {
          "X-CSCAPI-KEY":
            "clBHNXFZeDZXQWp2UzA0YzJBSEF6VE00aXVUU2NwdG9DbTlRa0dhcQ==",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function getStatesByCountry(code = "IN") {
  try {
    const response = await axios.get(
      `https://api.countrystatecity.in/v1/countries/${code}/states`,
      {
        headers: {
          "X-CSCAPI-KEY":
            "clBHNXFZeDZXQWp2UzA0YzJBSEF6VE00aXVUU2NwdG9DbTlRa0dhcQ==",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function getCitiesByStateAndCountry(
  statecode = "MH",
  countyCode = "IN"
) {
  try {
    const response = await axios.get(
      `https://api.countrystatecity.in/v1/countries/${countyCode}/states/${statecode}/cities`,
      {
        headers: {
          "X-CSCAPI-KEY":
            "clBHNXFZeDZXQWp2UzA0YzJBSEF6VE00aXVUU2NwdG9DbTlRa0dhcQ==",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
