import * as yup from "yup";

export const ReimbursementSchema = yup.object().shape({
  amount: yup.number().required("Required"),
  addRecipt_bill: yup.mixed(),
  selectProject: yup.string().required("Required"),
  reimbursementsDescription: yup.string().required("Required"),
  generatedBy: yup.string(),
  paidStatus: yup.string(),
  modeOfPayment: yup.string(),
});
