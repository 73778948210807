import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

export default function PrivateRoutes({ children }) {
  const loginState = useSelector((state) => state.login.isLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loginState) {
      navigate("/account-login");
    }
  }, [loginState]);

  if (!loginState) {
    return <Navigate to="/account-login" />;
  }

  return children;
}
