import React from "react";

const DeliveryList = ({ data }) => {
  const style = {
    border: "1px solid #181818",
    textAlign: "left",
    margin: "0px",
    padding: "0px",
    paddingLeft: "3px",
    fontSize: "12px",
    borderCollapse: "collapse",
  };
  return (
    <table style={{ ...style, width: "100%" }}>
      <thead>
        <tr>
          <th style={{ ...style, width: "5%" }}>Sr.</th>
          <th style={{ ...style, width: "65%" }}>Particulars</th>
          <th style={{ ...style, width: "15%" }}>Quantity</th>
          <th style={{ ...style, width: "15%" }}>Unit</th>
        </tr>
      </thead>

      <tbody>
        {data?.lineItems?.map((lineItem, index) => {
          return (
            <tr>
              <td style={{ ...style, textAlign: "center" }}>{++index}</td>
              <td style={style}>{lineItem.particulars}</td>
              <td style={style}>{lineItem.itemQuantity}</td>
              <td style={style}>{lineItem.itemUnit}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DeliveryList;
