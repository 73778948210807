import { current } from "@reduxjs/toolkit";
import React from "react";
import { useNavigate } from "react-router-dom";
const tableHead = [
  "Sr.",
  "Project",
  "Vendor",
  "Expense Amount",
  "Expense Date",
  "Actions",
];
const ExpenseTrackingTable = ({ EX = [], currentPage, itemsPerPage }) => {
  const navigate = useNavigate();
  const goToView = (id) => {
    navigate(`/client-expense-tracking/view/${id}`);
  };
  return (
    <div className="table-responsive">
      <table className="table table-vcenter">
        <thead>
          <tr>
            {tableHead?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {EX?.map((item, index) => {
            const Index = (currentPage - 1) * itemsPerPage + index + 1;
            return (
              <tr key={item?.id}>
                <td>{Index}</td>
                <td>{item?.projectClientName || "N/A"}</td>
                <td>{item?.businessName || "N/A"}</td>
                <td>{item?.expenseAmount || "N/A"}</td>

                <td>{item?.expenseDate || "N/A"}</td>

                <td>
                  <button
                    style={{ width: "60px", height: "30px" }}
                    className="btn btn-primary"
                    onClick={() => goToView(item.id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ExpenseTrackingTable;
