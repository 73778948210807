import React from "react";

const WarrentyOnMaterial = () => {
  return (
    <div>
      <h3 className="table-heading">Warrenty on Material:</h3>

      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Sr.</th>
            <th style={{ width: "25%" }}>Item</th>
            <th style={{ width: "20%" }}>Warrenty</th>
            <th style={{ width: "50%" }}>Condition</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.</td>
            <td>GI Pipe Structure (CEA Only)</td>
            <td>15 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty covers manufacturing defects or
              general defects
            </td>
          </tr>
          <tr>
            <td>02.</td>
            <td>CEA Construction</td>
            <td>15 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty covers manufacturing defects or
              general defects
            </td>
          </tr>
          <tr>
            <td>03.</td>
            <td>Polyfilm</td>
            <td>3.5 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning. Warrenty covers manufacturing defects,
              tearing, patching, decoloration, or general defects.
            </td>
          </tr>
          <tr>
            <td>04.</td>
            <td>GI Gutter</td>
            <td>6 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning or water blockage.
            </td>
          </tr>
          <tr>
            <td>05.</td>
            <td>Shadenet</td>
            <td>3 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning. Warrenty covers manufacturing defects,
              tearing, patching, decoloration, or general defects.
            </td>
          </tr>
          <tr>
            <td>06.</td>
            <td>Insect Net</td>
            <td>5 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning. Warrenty covers manufacturing defects,
              tearing, patching, decoloration, or general defects.
            </td>
          </tr>
          <tr>
            <td>07.</td>
            <td>Aluminium Net</td>
            <td>6 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning. Warrenty covers manufacturing defects,
              tearing, patching, decoloration, or general defects.
            </td>
          </tr>
          <tr>
            <td>08.</td>
            <td>Water tank</td>
            <td>10 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning. Warrenty covers manufacturing defects,
              tearing, patching, decoloration, or general defects.
            </td>
          </tr>
          <tr>
            <td>09.</td>
            <td>Plumbing Material</td>
            <td>1 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning. Warrenty covers manufacturing defects,
              tearing, patching, decoloration, or general defects.
            </td>
          </tr>
          <tr>
            <td>10.</td>
            <td>Fogger Motor</td>
            <td>1 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning. Warrenty covers manufacturing defects,
              tearing, patching, decoloration, or general defects.
            </td>
          </tr>
          <tr>
            <td>11.</td>
            <td>Reservoir Motor</td>
            <td>1 Years</td>
            <td>
              Warrenty is void in conditions like cyclone, heavy wind damage,
              heavy rain and storms. Warrenty is void on harm done by cutting or
              chopping or burning. Warrenty covers manufacturing defects,
              tearing, patching, decoloration, or general defects.
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        <strong>Notes: </strong>
        KBPL procures material from various manufacturer and vendors in order to
        provide you the best products. All warrenties on said items are provided
        by direct manufacturers. KBPL team will co-ordinate with manufacturer
        and local service supplier to avail best warrenty benefit to you. On
        replacement of product, KBPL ground team will get re-installation or
        re-commissioning done at extra cost.
      </p>
    </div>
  );
};

export default WarrentyOnMaterial;
