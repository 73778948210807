import React from "react";

const DeliveyChallanCondition = () => {
  return (
    <div>
      <b>Terms & Conditions:</b>
      <ol style={{ fontSize: "12px" }}>
        <li>Subject to Pune jurisdiction.</li>
        <li>
          Our responsibility ceases as soon as the goods leave our premises.
        </li>
        <li>Goods once sold will not be taken back.</li>
        <li>Delivery ex-premises.</li>
      </ol>
    </div>
  );
};

export default DeliveyChallanCondition;
